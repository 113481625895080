<ng-container *ngIf="!!value">
  <form #form="ngForm" class="filter-range-option-form">
    <div class="option">
      <label for="from">{{ COMMON_STRINGS.filter.optionTypes.from }}</label>

      <input
        appInput
        variant="filled"
        id="from"
        [(ngModel)]="value.from"
        #from="ngModel"
        type="number"
        name="from"
        [step]="step"
        [min]="min"
        [max]="to.value || max"
        placeholder="-"
        autocomplete="off" />
    </div>

    <div class="option">
      <label for="to">{{ COMMON_STRINGS.filter.optionTypes.to }}</label>

      <input
        appInput
        variant="filled"
        id="to"
        [(ngModel)]="value.to"
        #to="ngModel"
        type="number"
        name="to"
        [step]="step"
        [min]="from.value || min"
        [max]="max"
        placeholder="-"
        autocomplete="off" />
    </div>
  </form>

  <div *ngIf="form.invalid" class="warning-container">
    <mat-icon svgIcon="warning-rounded" class="icon"></mat-icon>

    <span>
      <p
        *ngIf="fromInvalidMin || toInvalidMin"
        class="text-warning"
        i18n="validations invalid min">
        The value must be greater than or equals to {{ min }}
      </p>

      <p
        *ngIf="fromInvalidMax || toInvalidMax"
        class="text-warning"
        i18n="validations invalid max">
        The value must be lower than or equals to {{ max }}
      </p>

      <p
        *ngIf="invalidRange"
        class="text-warning"
        i18n="validations invalid range">
        The start value must be lower than or equals to the end value
      </p>
    </span>
  </div>
</ng-container>
