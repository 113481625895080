import { DialogRef } from '@angular/cdk/dialog';
import { LinkSupplierProductData } from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import { AddProductSupplierDialogComponent } from '../add-product-supplier-dialog.component';

export class InitializeAddProductSuppliers {
  static readonly type = '[Add Product Suppliers Dialog] Initialize';

  constructor(public payload: LinkSupplierProductData) {}
}

export class UpdateSelectedSupplier {
  static readonly type =
    '[Add Product Suppliers Dialog] Update Selected Supplier';

  constructor(public selectedSupplierId: number | null) {}
}

export class UpdateSKU {
  static readonly type = '[Add Product Suppliers Dialog] Update SKU';

  constructor(public sku: string) {}
}

export class UpdateEAN {
  static readonly type = '[Add Product Suppliers Dialog] Update EAN';

  constructor(public ean: string) {}
}

export class UpdateWeight {
  static readonly type = '[Add Product Suppliers Dialog] Update Weight';

  constructor(public weight: number) {}
}

export class UpdateVolume {
  static readonly type = '[Add Product Suppliers Dialog] Update Volume';

  constructor(public volume: number) {}
}

export class UpdatePrice {
  static readonly type = '[Add Product Suppliers Dialog] Update Price';

  constructor(public price: number) {}
}

export class UpdateDeliveryTime {
  static readonly type = '[Add Product Suppliers Dialog] Update Delivery Time';

  constructor(public deliveryTime: number) {}
}

export class UpdateLotSize {
  static readonly type = '[Add Product Suppliers Dialog] Update Lot Size';

  constructor(public lotSize: number) {}
}

export class UpdateMOQ {
  static readonly type = '[Add Product Suppliers Dialog] Update MOQ';

  constructor(public moq: number) {}
}

export class UpdateAvailability {
  static readonly type = '[Add Product Suppliers Dialog] Update Availability';

  constructor(public availability: boolean) {}
}

export class UpdateAvailabilityDate {
  static readonly type =
    '[Add Product Suppliers Dialog] Update Availability Date';

  constructor(public availabilityDate: Date) {}
}

export class UpdatePreferred {
  static readonly type = '[Add Product Suppliers Dialog] Update Preferred';

  constructor(public preferred: boolean) {}
}

export class SaveAddedSupplier {
  static readonly type = '[Add Product Suppliers Dialog] Save';

  constructor(
    public closeDialog: boolean = true,
    public dialogRef: DialogRef<AddProductSupplierDialogComponent>
  ) {}
}

export class ResetConnectProduct {
  static readonly type = '[Add Product Suppliers Dialog] Reset';
}

export class ResetAddProductSuppliersState {
  static readonly type = '[Add Product Suppliers Dialog] Reset State';
}
