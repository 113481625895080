import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { DataChanges } from 'src/app/shared/utils/data-changes.state';
import {
  ResetAllInitialData,
  SetDataTableInitialData,
  SetInitialData,
  SetPendingChanges,
  SetPendingDataTableChanges,
  UnsetAllPendingChanges,
  UnsetInitialData,
  UnsetPendingChanges,
} from '../actions/pending-changes.action';

export interface PendingChangesStateModel {
  pendingChanges: string[];
  validChanges: boolean;
}

@State<PendingChangesStateModel>({
  name: 'pendingChangesState',
  defaults: {
    pendingChanges: [],
    validChanges: true,
  },
})
@Injectable()
export class PendingChangesState extends DataChanges {
  constructor() {
    super();
  }

  @Selector()
  static hasValidPendingChanges(state: PendingChangesStateModel): boolean {
    return state.pendingChanges.length > 0 && state.validChanges;
  }

  @Selector()
  static hasPendingChanges(state: PendingChangesStateModel): boolean {
    return state.pendingChanges.length > 0;
  }

  @Selector()
  static hasMultiplePendingChanges(state: PendingChangesStateModel): boolean {
    if (state.pendingChanges.length <= 1) {
      return false;
    }

    /**
     * Determines if all changes are from the same component (OVERLAYS can have multiple changes due to its tabs and sections)
     * or different components (OVERLAY + ORDERS)
     */
    const extractedKeys = state.pendingChanges.map(
      change => change.split('_')[0]
    );

    const reference = extractedKeys[0];

    return !extractedKeys.every(change => change === reference);
  }

  @Selector()
  static changesAreValid(state: PendingChangesStateModel): boolean {
    return state.validChanges;
  }

  @Selector()
  static currentPendingChanges(state: PendingChangesStateModel): string {
    return state.pendingChanges.length === 1 && state.pendingChanges[0];
  }

  @Selector()
  static hasChangesFrom(state: PendingChangesStateModel) {
    return (from: string): boolean => {
      return state.pendingChanges.includes(from);
    };
  }

  @Action(SetInitialData, { cancelUncompleted: true })
  setInitialData(
    ctx: StateContext<PendingChangesStateModel>,
    payload: SetInitialData
  ) {
    this.addInitialData(payload.component, payload.state);
  }

  @Action(SetDataTableInitialData, { cancelUncompleted: true })
  setDatatableInitialData(
    ctx: StateContext<PendingChangesStateModel>,
    payload: SetInitialData
  ) {
    this.addDataTableChange(payload.component, payload.state);
  }

  @Action(UnsetInitialData, { cancelUncompleted: true })
  unsetInitialData(
    ctx: StateContext<PendingChangesStateModel>,
    payload: UnsetInitialData
  ) {
    if (payload.component) {
      this.deleteInitialData(payload.component);
      this.deleteChange(payload.component);
      return;
    }

    this.deleteAllInitialData();
    this.deleteAllChanges();
  }

  @Action(ResetAllInitialData, { cancelUncompleted: true })
  resetAllInitialData(_ctx: StateContext<PendingChangesStateModel>) {
    this.deleteAllInitialData();
    this.deleteAllChanges();
  }

  @Action(SetPendingChanges, { cancelUncompleted: true })
  setPendingChanges(
    ctx: StateContext<PendingChangesStateModel>,
    payload: SetPendingChanges
  ) {
    if (payload.changes) {
      this.buildSimpleChanges(payload);
      this._handleChanges(ctx, payload);
      return;
    }

    this._setChanges(ctx, payload);
  }

  @Action(SetPendingDataTableChanges, { cancelUncompleted: true })
  setPendingDataTableChanges(
    ctx: StateContext<PendingChangesStateModel>,
    payload: SetPendingDataTableChanges
  ) {
    this.buildDataTableChanges(payload);

    this._handleChanges(ctx, payload);
  }

  @Action(UnsetPendingChanges, { cancelUncompleted: true })
  unsetPendingChanges(
    ctx: StateContext<PendingChangesStateModel>,
    payload: UnsetPendingChanges
  ) {
    this._unsetChanges(ctx, payload);

    // Dispatch unset initial data to prevent to call it in every component
    ctx.dispatch(new UnsetInitialData(payload.component));
  }

  @Action(UnsetAllPendingChanges, { cancelUncompleted: true })
  unsetAllPendingChanges(ctx: StateContext<PendingChangesStateModel>) {
    this._unsetAllChanges(ctx);

    // Dispatch unset initial data to prevent to call it in every component
    ctx.dispatch(new ResetAllInitialData());
  }

  /**
   * Handles changes for when there's no changes (changes state), unsets pending changes.
   *
   * If there's changes for its relative hash, sets pending changes.
   *
   * @param ctx StateContext (PendingChangesStateModel)
   * @param payload Action payload
   * @returns void
   */
  private _handleChanges(
    ctx: StateContext<PendingChangesStateModel>,
    payload: any
  ): void {
    if (!this.getChange(payload.component)) {
      return this._unsetChanges(ctx, payload);
    }

    return this._setChanges(ctx, payload);
  }

  /**
   * Builds a set of changes component key and pathes the state.
   * @param ctx StateContext (PendingChangesStateModel)
   * @param payload Action payload
   */
  private _setChanges(
    ctx: StateContext<PendingChangesStateModel>,
    payload: SetPendingChanges
  ): void {
    const state = ctx.getState();

    const newChanges = [...state.pendingChanges, payload.component];

    const changesSet = new Set(newChanges);

    ctx.patchState({
      ...state,
      pendingChanges: [...changesSet],
      validChanges: payload.validChanges,
    });
  }

  /**
   * Removes the component key from the changes set and patches the state.
   * @param ctx StateContext (PendingChangesStateModel)
   * @param payload Action payload
   * @returns void
   */
  private _unsetChanges(
    ctx: StateContext<PendingChangesStateModel>,
    payload: UnsetPendingChanges
  ): void {
    const state = ctx.getState();

    const changeToUnsetIdx = state.pendingChanges.findIndex(
      change => change === payload.component
    );

    if (changeToUnsetIdx < 0) {
      return;
    }

    const newChanges = [...state.pendingChanges];
    newChanges.splice(changeToUnsetIdx, 1);

    ctx.patchState({ ...state, pendingChanges: newChanges });
  }

  /**
   * Unsets all changes
   * @param ctx StateContext (PendingChangesStateModel)
   */
  private _unsetAllChanges(ctx: StateContext<PendingChangesStateModel>): void {
    const state = ctx.getState();

    ctx.patchState({ ...state, pendingChanges: [] });
  }
}
