<div class="container">
  <span class="header">
    <span class="title">
      <ng-content select="[header]"></ng-content>
    </span>

    <span class="actions">
      <ng-content select="[actions]"></ng-content>
    </span>
  </span>

  <ng-content></ng-content>
</div>
