import { DialogRef } from '@angular/cdk/dialog';
import { PurchaseConfirmationDataV2 } from '../../purchase-confirmation-dialog-v2/model/purchase-confirmation-v2.model';

export class CheckoutOrder {
  static readonly type = '[Checkout Confirmation] checkout order';

  constructor(
    public order: PurchaseConfirmationDataV2,
    public dialogRef: DialogRef<any>
  ) {}
}

export class ResetCheckout {
  static readonly type = '[Checkout Confirmation] Reset';
}

export class SkipCheckout {
  static readonly type = '[Checkout Confirmation] Skip Checkout';

  constructor(public skipCheckout: boolean) {}
}
