<div
  [ngClass]="baseClasses()"
  [class.tab-group-wrapper-transparent]="transparent()">
  <div [ngClass]="baseTabsContainerClasses()">
    @for (tab of tabs(); track tab.label; let i = $index) {
      @if (tab.isGroup) {
        <button
          [ngClass]="baseDropdownClasses()"
          [class.tab-active]="currentTab().parentIndex === i"
          [disabled]="tab.disabled">
          <div class="tab-dropdown-header">
            <span>{{ tab.label }}</span>
            <mat-icon svgIcon="angle-down" />
          </div>

          <div class="tab-dropdown-content">
            @for (subTab of tab.tabs(); track subTab.label; let j = $index) {
              <button
                class="tab-dropdown-tab"
                [class.tab-dropdown-tab-active]="
                  currentTab().index === j && currentTab().parentIndex === i
                "
                (click)="selectCurrentTab(subTab, j, i)"
                [disabled]="subTab.disabled">
                {{ subTab.label }}
              </button>
            }
          </div>
        </button>
      } @else {
        <button
          [ngClass]="baseButtonClasses()"
          [class.tab-active]="
            currentTab().index === i && currentTab().parentIndex === null
          "
          (click)="selectCurrentTab(tab, i)"
          [disabled]="tab.disabled">
          {{ tab.label }}
        </button>
      }
    }
  </div>

  <ng-content select="[headerExtras]"></ng-content>
</div>

<div [ngClass]="baseContentClasses()">
  <ng-container [ngTemplateOutlet]="currentTab().template"></ng-container>
</div>
