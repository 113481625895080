import { CurrencyPipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LocaleService } from 'src/app/core/services/locale.service';
import { LocaleState } from 'src/app/core/states/locale.state';
import { Locale } from '../models/locale.model';

/**
 * Custom currency pipe that takes the webshop currency code into consideration.
 * Use this pipe instead of the CurrencyPipe.
 */
@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe
  extends CurrencyPipe
  implements PipeTransform, OnDestroy
{
  currentLocaleState: Locale;

  private currencyCode: string = null;

  private currencySymbol: string = null;

  private destroy$ = new Subject<void>();

  constructor(
    public localeService: LocaleService,
    public currencyService: CurrencyService,
    private store: Store
  ) {
    super(localeService.locale, currencyService.currencyCode());

    this.currencyCode = currencyService.currencyCode();
    this.currencySymbol = currencyService.currencySymbol();

    this.store
      .select(LocaleState.currentLocale)
      .pipe(takeUntil(this.destroy$))
      .subscribe(locale => {
        this.currentLocaleState = locale;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  transform(value: any, digitsInfo?: string, manualCurrencyCode?: string): any {
    if (value === null) return;

    if (manualCurrencyCode) {
      this.currencyCode = manualCurrencyCode;
      this.currencySymbol =
        this.currencyService.manualCurrencySymbol(manualCurrencyCode);
    }

    const transformedCurrencyValue = super.transform(
      value,
      this.currencyCode,
      this.currencySymbol,
      digitsInfo,
      this.localeService.locale
    );

    if (
      this.currentLocaleState &&
      this.currentLocaleState?.decimalSeparator === 'comma'
    ) {
      return transformedCurrencyValue.replace(/\./g, ' ').replace('US', '');
    }

    if (this.currencyCode === 'SEK') {
      return transformedCurrencyValue
        .replace(/\,/g, ' ')
        .replace(/\./g, ',')
        .replace('US', '');
    } else {
      return transformedCurrencyValue.replace(/\,/g, ' ').replace('US', '');
    }
  }
}
