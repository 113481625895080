<app-dialog-container>
  <div class="confirmation-dialog">
    <div appDialogHeader>
      <h1 appDialogTitle i18n="add products to promotion dialog title">
        Add product(s) to promotion
      </h1>
      <button
        aria-label="Add products to promotion dialog close button"
        (click)="close()"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>

    <div appDialogBody>
      <div class="content">
        <app-add-products-to-promotion-data-table></app-add-products-to-promotion-data-table>
      </div>
    </div>

    <div appDialogActions>
      <div class="action-buttons-container">
        <div class="footer-actions">
          <button
            aria-label="Add products to promotion dialog cancel button"
            appButton
            variant="tertiary"
            (click)="close()"
            i18n="dialog cancel button">
            Cancel
          </button>

          <button
            appButton
            variant="primary"
            [disabled]="
              ((areSomeSelected$ | async) === false &&
                (areAllSelected$ | async) === false) ||
              (loading$ | async) ||
              (saving$ | async)
            "
            (click)="save()"
            i18n="add products to promotion dialog confirm button title">
            Add to promotion
            <app-progress-spinner
              *ngIf="saving$ | async"></app-progress-spinner>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-dialog-container>
