import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  input,
} from '@angular/core';
import { ColumnToggled } from '../../model/column-view-v3.model';
import { STRINGS } from '../../model/column-view-v3.strings';
import { DatatableColumnV2ColumnGroup } from '../../../../model/data-table-v2.model';

@Component({
  selector: 'app-column-view-v3-option',
  templateUrl: './column-view-v3-option.component.html',
  styleUrl: './column-view-v3-option.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnViewV3OptionComponent {
  column = input<DatatableColumnV2ColumnGroup>();

  @Output()
  toggled: EventEmitter<ColumnToggled> = new EventEmitter<ColumnToggled>();

  readonly COLUMN_VIEW_STRINGS = STRINGS.metadata;

  toggleColumn(checked: boolean) {
    this.toggled.emit({
      groupKey: this.column().groupKey,
      columnKey: this.column().name,
      checked,
    });
  }
}
