<form #factorForm="ngForm" class="order-enlarger">
  <div class="order-enlarger-header">
    @if (factorForm.invalid) {
      <mat-icon
        svgIcon="warning-rounded"
        class="error-icon"
        [appTooltip]="TOOLTIPS.invalidMin"
        aria-hidden="false">
      </mat-icon>
    }
  </div>

  <div class="fields-input-container">
    <input
      appInput
      variant="filled"
      name="factor"
      [ngModel]="factor"
      #factorInput="ngModel"
      type="number"
      step="0.1"
      min="0.1"
      placeholder="1.0"
      (input)="checkFactor($event.target.value)"
      required />
  </div>

  <button
    appButton
    variant="secondary"
    size="sm"
    [disabled]="disabled || factorForm.invalid"
    [appTooltip]="TOOLTIPS.beforeChange"
    [tooltipDisabled]="disabled || factorForm.invalid"
    (click)="enlargeOrder(factorInput.value)">
    {{ STRINGS.apply }}

    @if (loading) {
      <app-progress-spinner diameter="18"></app-progress-spinner>
    }
  </button>
</form>
