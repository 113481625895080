import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngxs/store';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { ComposedWebshopProduct } from 'src/app/shared/models/products/v3/products.model';
import {
  AddFilterParam,
  AddSearchParam,
  ColumnsSelected,
  HideColumn,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  Sort,
  ToggleFilter,
} from '../../actions/product-parts-v2.actions';
import { ProductPartsV2StateQueries } from '../../state/product-parts-v2.queries';
import { DataTableV2Component } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.component';
import {
  ColumnFilterParam,
  Filtering,
  Pagination,
  Search,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ProductPartsV2State } from '../../state/product-parts-v2.state';
import { GoToProduct } from 'src/app/features/products-v2/components/product-details-v2/actions/product-details-v2.actions';
import { COLUMNS_ORDER } from '../../model/data-model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

@Component({
  selector: 'app-product-parts-v2-data-table',
  templateUrl: './product-parts-v2-data-table.component.html',
  styleUrls: ['./product-parts-v2-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPartsV2DataTableComponent
  extends DataTableV2Component<ComposedWebshopProduct[]>
  implements Pagination, Search, Filtering
{
  totalElements = select(ProductPartsV2StateQueries.totalElements);
  pageSizeOptions = select(ProductPartsV2StateQueries.pageSizeOptions);
  pageSize = select(ProductPartsV2StateQueries.pageSize);
  currentPage = select(ProductPartsV2StateQueries.currentPage);
  selectableColumns = select(ProductPartsV2StateQueries.selectableColumns);
  displayedColumns = select(ProductPartsV2StateQueries.displayedColumns);
  lastDisplayedColumn = select(ProductPartsV2StateQueries.lastDisplayedColumn);
  datasource = select(ProductPartsV2StateQueries.parts);
  loading = select(ProductPartsV2StateQueries.loading);
  filters = select(ProductPartsV2StateQueries.filters);
  activeFilters = select(ProductPartsV2StateQueries.activeFilters);
  searchBy = select(ProductPartsV2StateQueries.search);
  filterOpened = select(ProductPartsV2StateQueries.filterOpened);

  canRefresh = select(ProductPartsV2State.canRefresh());

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly TABLE_COLUMNS = STRINGS.columns.compositions;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RELATED_STATE = ProductPartsV2State;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  constructor(private store: Store) {
    super();
  }

  refreshDatatable(): void {
    this.store.dispatch(new ReloadDatatable());
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onColumnHidden(columnKey: string) {
    this.store.dispatch(new HideColumn(columnKey));
  }

  onFiltered(params: ColumnFilterParam): void {
    this.store.dispatch(new AddFilterParam(params));
  }

  onSort(sort: Sorted) {
    this.store.dispatch(new Sort(sort));
  }

  toggleFilter(): void {
    this.store.dispatch(new ToggleFilter());
  }

  clearAllFilters(): void {
    this.store.dispatch(new RemoveAllFilters());
  }

  addSearchParam(param: string) {
    this.store.dispatch(new AddSearchParam(param));
  }

  removeSearchParam(param: string) {
    this.store.dispatch(new RemoveSearchParam(param));
  }

  goToProductDetails(productUuid: string): void {
    this.store.dispatch(new GoToProduct(productUuid));
  }
}
