<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <app-data-table-refresh-button
      data-intercom-target="Refresh table data button"
      aria-label="Refresh table data button"
      [loading]="loading()"
      [disabled]="loading() || !canRefresh()"
      (refreshed)="refreshDatatable()" />

    <div class="vertical-separator"></div>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="filterOpened()">
  <app-filter-group [filters]="activeFilters()" (filtered)="onFiltered($event)">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"></app-add-filter-button>
  </app-filter-group>
</app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    [disableHideColumn]="lastDisplayedColumn()"
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)"
    (filtered)="onFiltered($event)">
    <!-- Order # -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.BUY_ORDER_ID.key">
      <th
        data-intercom-target="8e5622db-49b6-46dc-bd07-952cad7b6719"
        aria-label="Product details deliveries table Order Id column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.BUY_ORDER_ID.key | filterColumn: RELATED_STATE | async
        "
        class="fit-to-value">
        {{ TABLE_COLUMNS.BUY_ORDER_ID.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="column-muted fit-to-value">
        <ng-container *ngIf="element.buyOrder?.id !== null; else notAvailable">
          <span
            (click)="goToDelivery(element.buyOrder.uuid)"
            class="deep-linking-text">
            {{ element.buyOrder.id }}
          </span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Supplier name -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_NAME.key">
      <th
        data-intercom-target="0fcbc5b1-f76f-4d61-998a-8eb2c3cee85d"
        aria-label="Product details deliveries table Supplier Name column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_NAME.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.SUPPLIER_NAME.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="restricted-column">
        <ng-container *ngIf="element.supplier !== null; else notAvailable">
          @if (suppliersPermission()) {
            <span
              (click)="goToSupplier(element.supplier.uuid)"
              class="deep-linking-text">
              <app-copy-to-clipboard
                [message]="element.supplier.name"></app-copy-to-clipboard>
            </span>
          } @else {
            <app-copy-to-clipboard
              [message]="element.supplier.name"></app-copy-to-clipboard>
          }
        </ng-container>
      </td>
    </ng-container>

    <!-- Ordered products -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.ORDERED.key">
      <th
        data-intercom-target="ff4ab888-97f6-4244-8e27-96d1cd8cbfbb"
        aria-label="Product details deliveries table Ordered Products column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.ORDERED.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.ORDERED.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container *ngIf="element.totalOrdered !== null; else notAvailable">
          <span>{{ element.totalOrdered }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Delivered products -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.DELIVERED.key">
      <th
        data-intercom-target="d953c10c-45aa-4342-89aa-15b47edcd0f2"
        aria-label="Product details deliveries table Delivered Products column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.DELIVERED.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.DELIVERED.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container
          *ngIf="element.totalDelivered !== null; else notAvailable">
          <span>{{ element.totalDelivered }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Placed date -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.DATE.key">
      <th
        data-intercom-target="c942021d-ff26-452f-b751-fb010a6067ef"
        aria-label="Product details deliveries table Planned Date column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.DATE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.DATE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="element.buyOrder?.date !== null; else notAvailable">
          <span>
            {{ element.buyOrder.date | date }}
          </span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Expected delivery date -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.EXPECTED_DELIVERY_DATE.key">
      <th
        data-intercom-target="68edcd3a-5362-4d51-b4b8-5e077c2b47f7"
        aria-label="Product details deliveries table Expected Delivery Date column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.EXPECTED_DELIVERY_DATE.key
            | filterColumn: RELATED_STATE
            | async
        ">
        {{ TABLE_COLUMNS.EXPECTED_DELIVERY_DATE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="element.expectedDeliveryDate !== null; else notAvailable">
          <span>
            {{ element.expectedDeliveryDate | date }}
          </span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Closed date -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.CLOSED_DATE.key">
      <th
        data-intercom-target="1e84f1a2-e8c9-4db5-8f99-a9eb8548b708"
        aria-label="Product details deliveries table Closed Date column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.CLOSED_DATE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.CLOSED_DATE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="element.buyOrder?.closedDate !== null; else notAvailable">
          <span>
            {{ element.buyOrder.closedDate | date }}
          </span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Status -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.STATUS.key">
      <th
        data-intercom-target="00f6ebc5-faad-42b7-b9ec-9e2804841729"
        aria-label="Product details deliveries table Status column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.STATUS.key | filterColumn: RELATED_STATE | async
        "
        hideSort>
        {{ TABLE_COLUMNS.STATUS.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container *ngIf="element.extras; else notAvailable">
          @for (
            tag of element.extras.tags.textTags;
            track idx;
            let idx = $index
          ) {
            <app-tag
              [color]="tag.color"
              rounded
              [appTooltip]="tag.tooltip"
              tooltipShouldCheckOverflow
              >{{ tag.text }}</app-tag
            >
          }
        </ng-container>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      data-intercom-target="13ad3bd2-7fc9-4687-859b-83571ac3a8fb"
      aria-label="Product details deliveries table row"
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
