import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { CloseImportsDialog } from '../import-suppliers-dialog/components/import-suppliers-update-dialog/actions/import-suppliers-dialog.action';

@Component({
  selector: 'app-usecsv-dialog',
  templateUrl: './usecsv-dialog.component.html',
  styleUrls: ['./usecsv-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UsecsvDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: DialogRef,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(ofActionDispatched(CloseImportsDialog), takeUntil(this.destroy$))
      .subscribe(() => {
        this.close();
      });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
