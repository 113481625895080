import { Injectable } from '@angular/core';
import { EditedProductPromotion } from 'src/app/shared/models/promotion-editor/product-promotions-editor.model';
import { WebshopProductsProperties } from 'src/app/shared/models/promotion/promotion.model';
import { PromotionUpliftTypes } from 'src/app/shared/models/promotion/v2/promotion-v2.model';

export interface BuildProductPromotionsUpdateFromResult {
  editedProductPromotions: WebshopProductsProperties[];
}

@Injectable({
  providedIn: 'root',
})
export class ProductPromotionsBuilderService {
  buildProductPromotionsUpdateFromResult(
    editedProductPromotions: EditedProductPromotion[]
  ): BuildProductPromotionsUpdateFromResult {
    const changedLines: WebshopProductsProperties[] =
      editedProductPromotions.map(changedLine => {
        if (changedLine.uplift.type === PromotionUpliftTypes.CLOSE_OUT) {
          return {
            uuid: changedLine.uuid,
            promotionUUID: changedLine.promotionUUID,
            specificUplift: {
              increase: 0,
              type: changedLine.uplift.type,
            },
          };
        }

        if (
          changedLine.uplift.increase &&
          [
            PromotionUpliftTypes.RELATIVE,
            PromotionUpliftTypes.ABSOLUTE,
          ].includes(changedLine.uplift.type as PromotionUpliftTypes)
        ) {
          return {
            uuid: changedLine.uuid,
            promotionUUID: changedLine.promotionUUID,
            specificUplift: {
              increase: changedLine.uplift.increase,
              type: changedLine.uplift.type,
            },
          };
        }

        return {
          uuid: changedLine.uuid,
          promotionUUID: changedLine.promotionUUID,
          specificUplift: null,
        };
      });

    return { editedProductPromotions: changedLines };
  }
}
