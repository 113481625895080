import { InjectionToken } from '@angular/core';

/* Tokens */
export const TOGGLE_OPTIONS = new InjectionToken<ToggleOptions>(
  'Toggle Options'
);

export type ToggleStatePosition = 'before' | 'left' | 'after' | 'right';

export interface ToggleOptions {
  showState?: boolean;
  statePosition?: ToggleStatePosition;
}

export const toggleDefaultOptions: ToggleOptions = {
  showState: false,
  statePosition: 'after',
};
