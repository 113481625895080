import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';
import { STRINGS } from '../column-view-v2/model/column-view-v2.strings';

@Component({
  selector: 'app-hide-column',
  templateUrl: './hide-column.component.html',
  styleUrls: ['./hide-column.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HideColumnComponent {
  @Input({ transform: booleanAttribute })
  disabled: boolean;

  @Output()
  hidden: EventEmitter<void> = new EventEmitter<void>();

  readonly COLUMN_STRINGS = STRINGS;

  hide(): void {
    this.hidden.emit();
  }
}
