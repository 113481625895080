import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { UnsetAllPendingChanges } from 'src/app/core/actions/pending-changes.action';
import { ConfirmSave } from '../actions/confirmation.actions';

interface ConfirmationStateModel {
  loading: boolean;
}

@State<ConfirmationStateModel>({
  name: 'confirmationState',
  defaults: {
    loading: false,
  },
})
@Injectable()
export class ConfirmationState {
  constructor() {
    // inteded
  }

  @Selector()
  static loading(state: ConfirmationStateModel): boolean {
    return state.loading;
  }

  @Action(ConfirmSave, { cancelUncompleted: true })
  confirmSave(ctx: StateContext<ConfirmationStateModel>) {
    ctx.setState(
      patch<ConfirmationStateModel>({
        loading: true,
      })
    );
  }

  @Action(UnsetAllPendingChanges, { cancelUncompleted: true })
  unsetAllPendingChanges(ctx: StateContext<ConfirmationStateModel>) {
    ctx.setState(
      patch<ConfirmationStateModel>({
        loading: false,
      })
    );
  }
}
