<app-product-container>
  <ng-container header>{{ METADATA.title }}</ng-container>

  <ng-container actions>
    <div class="total-sales">
      <span class="total-sales-label">
        {{ METADATA.total }}
      </span>
      <span class="total-sales-value">{{ totalSales$ | async }}</span>
    </div>

    <app-sales-v2-filter></app-sales-v2-filter>
  </ng-container>

  <div class="content">
    <ng-container *ngIf="loading$ | async; else content">
      <div class="loading-container">
        <app-progress-spinner class="sales-spinner"></app-progress-spinner>

        <p class="loading-warning">
          {{ METADATA.states.loading }}
        </p>
      </div>
    </ng-container>

    <ng-template #content>
      <div
        echarts
        [autoResize]="true"
        [theme]="'optiply-' + (colorTheme$ | async)"
        [options]="graphOptions$ | async"
        [loadingOpts]="loadingOptions$ | async"
        [loading]="loading$ | async"></div>
    </ng-template>
  </div>
</app-product-container>
