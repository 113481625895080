// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_path: '/edge/',
  redirect_uri: '/login/authenticated',
  client_id: 'sXItzB5ARfFNAME4G3hJF0cuW8t00MJY',
  domain: 'optiply-staging.eu.auth0.com',
  return_to: '/',
  allowedList: [
    'https://dashboard.acceptance.optiply.com/api/*',
    'https://api-accept.optiply.com/*',
  ],
  audience: 'https://api.optiply.com/internal',
  enableAuth: true,
  helpUrl: {
    'nl-NL': 'https://help.optiply.com',
    'en-US': 'https://help.optiply.com',
  },
  stripe: {
    accessibleKey:
      'pk_test_51I8MlYCf4pZv1aDrEZU717udcZZnTL4f44P9EFEjekG4gkY4u9zotN1LCnqRITqRT54CsORSV9mmuTdBsTz8oU0F00vsqMS6I0',
  },
};
