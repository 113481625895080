import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import {
  columnsGroupsMap,
  defaultFiltersV2,
  defaultSort,
  filtersGroupsMap,
} from '../model/suppliers-v2-data-table.model';
import {
  defaultResetState,
  defaultState,
  SelectedSupplier,
  SupplierUpdateMultiple,
  SupplierUpdateMultipleProperties,
  SupplierUpdateProperties,
} from '../model/suppiers-v2.model';
import { append, patch, removeItem } from '@ngxs/store/operators';
import {
  AddFilterParam,
  AddFilterParamNoReload,
  AddSearchParam,
  ClearSelection,
  ColumnsSelected,
  CreateSupplier,
  ExportSuppliersV2,
  FetchSupplierV2,
  HideColumn,
  IgnoreMultipleSuppliers,
  IgnoreSupplier,
  InitializeSuppliersState,
  LoadSuppliers,
  MasterToggleSelection,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetCurrentSupplier,
  ResetPagination,
  ResetPaginationAndLoadData,
  ResetSuppliers,
  SaveSupplierEmailV2,
  Sort,
  ToggleFilter,
  ToggleRowSelection,
  UnignoreSupplier,
} from '../actions/suppliers-v2.actions';
import { catchError, concatMap, EMPTY, mergeMap, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  Loading,
  LoadSucceed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { SuppliersV2Service } from 'src/app/core/api/supply/v2/suppliers-v2.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { Suppliers } from 'src/app/shared/models/suppliers/v2/suppliers-v2.model';
import { Supplier } from 'src/app/shared/models/suppliers/v2/supplier-v2.model';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import { AccountState } from 'src/app/core/states/account.state';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import { STRINGS } from '../model/suppliers-v2.strings';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import {
  ExportDataV2,
  ExportRequestDataV2,
} from 'src/app/shared/models/exports/exports-v2.model';
import { getFileName } from 'src/app/shared/utils/exports';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
  SortOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { SupplierCreateProperties } from 'src/app/core/api/supply/v2/model/suppliers-v2.model';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface SuppliersV2StateModel extends DatatableStateModel {
  selection: TableSelection<SelectedSupplier>;
  suppliers: Supplier[];
  currentSupplier: Supplier;
  page: Pageable;
  loading: boolean;
  extraFiltersGroups: DatatableColumnV2Groups<Filter>;
}

@State<SuppliersV2StateModel>({
  name: 'suppliersV2State',
  defaults: defaultState,
})
@Injectable()
export class SuppliersV2State extends DatatableState {
  readonly notExportableColumns: string[] = [
    STRINGS.columns.suppliers.SELECTION.key,
    STRINGS.columns.suppliers.ATTRIBUTES.key,
    STRINGS.columns.suppliers.ACTIONS.key,
  ];

  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [SuppliersV2State],
      (state: SuppliersV2StateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static selection(
    state: SuppliersV2StateModel
  ): TableSelection<SelectedSupplier> {
    return state.selection;
  }

  @Selector()
  static suppliers(state: SuppliersV2StateModel): Supplier[] {
    return state.suppliers;
  }

  constructor(
    private store: Store,
    private suppliersV2Service: SuppliersV2Service,
    private accountSettingsService: AccountSettingsService,
    private notificationCenter: NotificationCenterService
  ) {
    super();
  }

  @Action(InitializeSuppliersState, { cancelUncompleted: true })
  initialize(ctx: StateContext<SuppliersV2StateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.suppliersEditorTableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<SuppliersV2StateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch(new LoadSuppliers());
  }

  @Action(LoadSuppliers, { cancelUncompleted: true })
  loadSuppliers(ctx: StateContext<SuppliersV2StateModel>) {
    ctx.patchState({
      loading: true,
    });

    return this._findAllSuppliers(ctx).pipe(
      tap((suppliers: Suppliers) => {
        ctx.patchState({
          loading: false,
          suppliers: suppliers.data,
          page: { totalElements: suppliers.metadata.page.totalElements },
        });
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<SuppliersV2StateModel>) {
    return ctx.dispatch(new LoadSuppliers());
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<SuppliersV2StateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action([IgnoreSupplier, UnignoreSupplier])
  ignoreSupplier(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: IgnoreSupplier
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected).uuid;
    const properties: SupplierUpdateProperties = {
      ignored: !payload.supplier.ignored,
    };
    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this.suppliersV2Service.updateSupplierGRPC(
          webshopUUID,
          payload.supplier.uuid,
          properties
        )
      ),
      concatMap(() => ctx.dispatch([new SaveSucceed(), new LoadSuppliers()])),
      catchError(() => {
        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  @Action(IgnoreMultipleSuppliers)
  ignoreMultipleSuppliers(ctx: StateContext<SuppliersV2StateModel>) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected).uuid;
    const properties: SupplierUpdateMultiple = {
      suppliers: this._buildPhaseOutMultiUpdateProperties(ctx),
    };

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this.suppliersV2Service.updateMultipleSupplier(webshopUUID, properties)
      ),
      tap(() =>
        this._removeMultipleFromSelection(
          ctx,
          properties.suppliers.map(supplier => supplier.uuid)
        )
      ),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      mergeMap(() => ctx.dispatch(new LoadSuppliers()))
    );
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<SuppliersV2StateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadSuppliers())),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadSuppliers());
  }

  @Action(AddFilterParam, { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(AddSearchParam, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<SuppliersV2StateModel>({ search: append<string>([payload.param]) })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(RemoveSearchParam, { cancelUncompleted: true })
  removeSearchParam(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<SuppliersV2StateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<SuppliersV2StateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<SuppliersV2StateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(RemoveAllFilters, { cancelUncompleted: true })
  removeAllFilters(ctx: StateContext<SuppliersV2StateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<SuppliersV2StateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action(ToggleFilter, { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<SuppliersV2StateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(ToggleRowSelection, { cancelUncompleted: true })
  toggleRowSelection(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: ToggleRowSelection
  ) {
    const selectedCopy = { ...ctx.getState().selection };

    const selectionKey = payload.supplier.uuid;

    if (!payload.isSelected) {
      delete selectedCopy[selectionKey];

      ctx.patchState({
        selection: selectedCopy,
      });

      return;
    }

    ctx.patchState({
      selection: {
        ...ctx.getState().selection,
        [selectionKey]: {
          uuid: selectionKey,
          ignored: payload.supplier.ignored,
        },
      },
    });
  }

  @Action(MasterToggleSelection, { cancelUncompleted: true })
  masterToggleSelection(ctx: StateContext<SuppliersV2StateModel>) {
    const lines = ctx.getState().suppliers;

    const existingSelection = ctx.getState().selection;

    const linesUuid = lines.map(line => line.uuid);

    const selectedUids = new Set(Object.keys(existingSelection));

    const allSelected = linesUuid.every(line => selectedUids.has(line));

    const selectionCopy = new Map(Object.entries(existingSelection));

    if (allSelected) {
      lines.forEach(line => {
        selectionCopy.delete(line.uuid);
      });
    } else {
      lines.forEach(line => {
        selectionCopy.set(line.uuid, {
          uuid: line.uuid,
          ignored: line.ignored,
        });
      });
    }

    ctx.patchState({
      selection: {
        ...Object.fromEntries(selectionCopy.entries()),
      },
    });
  }

  @Action(ClearSelection, { cancelUncompleted: true })
  clearSelection(ctx: StateContext<SuppliersV2StateModel>) {
    ctx.patchState({
      selection: {},
    });
  }

  @Action(ResetSuppliers, { cancelUncompleted: true })
  resetBuyOrder(ctx: StateContext<SuppliersV2StateModel>) {
    ctx.patchState(defaultResetState);
  }

  @Action(AddFilterParamNoReload, { cancelUncompleted: true })
  addFilterParamNoReload(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: AddFilterParamNoReload
  ) {
    payload.param.forEach(element => {
      const groupKey = filtersGroupsMap.get(element.columnKey);

      if (!groupKey) return;

      if (element.extra) {
        this.addExtraGroupParam(ctx, groupKey, element.columnKey, {
          operator: element.optionSelected as FilterTypesOptionsV2,
          subOperator: element.subOperator,
          value: element.values,
        });

        return;
      }

      this.addGroupParam(ctx, groupKey, element.columnKey, {
        operator: element.optionSelected as FilterTypesOptionsV2,
        subOperator: element.subOperator,
        value: element.values,
      });
    });

    if (payload.reload) {
      return ctx.dispatch(new ResetPaginationAndLoadData());
    }
  }

  @Action(ExportSuppliersV2, { cancelUncompleted: true })
  exportSuppliers(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: ExportSuppliersV2
  ) {
    this.notificationCenter.showToast(
      MESSAGES.notifications.exports.exportSuppliersDispatched
    );

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const exportRequestInfo: ExportRequestDataV2 = this._buildExportData(
      ctx,
      payload.exportData
    );

    return this.suppliersV2Service
      .exportSuppliers(webshopUuid, exportRequestInfo)
      .pipe(
        catchError(e => {
          this.notificationCenter.showToastError(
            MESSAGES.notifications.exports.exportSuppliersFailed
          );
          throw new Error(e.message || e);
        })
      )
      .subscribe(response => {
        this.notificationCenter.showToastSuccess(
          MESSAGES.notifications.exports.exportSuppliersSuccessful
        );
        const exportData: any = {
          data: response.body,
          fileName: response.headers.get('Content-Disposition'),
          fileType: response.headers.get('content-type'),
        };
        const a = document.createElement('a');
        const blob = new Blob([exportData.data], { type: exportData.fileType });
        a.href = window.URL.createObjectURL(blob);
        a.download = getFileName(exportData.fileName);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
      });
  }

  @Action(FetchSupplierV2, { cancelUncompleted: true })
  fetchSupplierV2(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: FetchSupplierV2
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return ctx.dispatch(new Loading(null)).pipe(
      concatMap(() =>
        this._fetchSupplier(webshopUuid, payload.supplierUuid).pipe(
          tap((supplier: Supplier) => {
            ctx.patchState({
              currentSupplier: supplier,
            });
          }),
          concatMap(() => ctx.dispatch(new LoadSucceed(false))),
          catchError(() => {
            ctx.patchState({
              currentSupplier: null,
            });

            ctx.dispatch(new LoadFailed(false));

            return EMPTY;
          })
        )
      )
    );
  }
  @Action(SaveSupplierEmailV2, { cancelUncompleted: true })
  saveSupplierEmailsV2(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: SaveSupplierEmailV2
  ) {
    const state = ctx.getState();
    const emails = [...state.currentSupplier.emails, payload.email];

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const supplierUuid = payload.supplierUuid
      ? payload.supplierUuid
      : ctx.getState().currentSupplier.uuid;

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this._updateSupplierGRPC(webshopUuid, supplierUuid, {
          emails,
        })
      ),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      concatMap(() => ctx.dispatch(new FetchSupplierV2(supplierUuid))),
      catchError(() => {
        ctx.dispatch(new SaveFailed());

        return EMPTY;
      })
    );
  }

  @Action(CreateSupplier, { cancelUncompleted: true })
  createSupplier(
    ctx: StateContext<SuppliersV2StateModel>,
    payload: CreateSupplier
  ) {
    const webshop = this.store.selectSnapshot(WebshopState.selected);

    const properties: SupplierCreateProperties = {
      webshopId: webshop.id,
      name: payload.settings.name,
    };

    if (payload.settings.email) {
      properties.emails = [payload.settings.email];
    }

    return this._createSupplier(ctx, webshop.uuid, properties).pipe(
      mergeMap(() => ctx.dispatch(new SaveSucceed()))
    );
  }

  @Action(ResetCurrentSupplier, { cancelUncompleted: true })
  resetCurrentSupplier(ctx: StateContext<SuppliersV2StateModel>) {
    ctx.patchState({
      currentSupplier: null,
    });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<SuppliersV2StateModel>) {
    return ctx.dispatch([new ResetPagination(), new LoadSuppliers()]);
  }

  override addGroupParam(
    ctx: StateContext<SuppliersV2StateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<SuppliersV2StateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  addExtraGroupParam(
    ctx: StateContext<SuppliersV2StateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<SuppliersV2StateModel>({
        extraFiltersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<SuppliersV2StateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [STRINGS.columns.suppliers.IGNORED.key]: {
          field: STRINGS.columns.suppliers.IGNORED.filterKey,
          order: SortOrder.ASC,
        },
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadSuppliers());
  }

  private _removeAllFilters(ctx: StateContext<SuppliersV2StateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      extraFiltersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _findAllSuppliers(
    ctx: StateContext<SuppliersV2StateModel>
  ): Observable<void | Suppliers> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildLoadSuppliersRequestInfo(ctx.getState());

    return this.suppliersV2Service
      .findAllSuppliers(webshopUuid, requestInfo)
      .pipe(
        catchError(() => {
          ctx.patchState({
            loading: false,
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildLoadSuppliersRequestInfo(
    state: SuppliersV2StateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const extraFiltersWithoutGroups: Filter[] = Object.values(
      state.extraFiltersGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: {
          ...this.buildFiltersV2(filtersWithoutGroups),
          ...this.buildFiltersV2(extraFiltersWithoutGroups, false),
        },
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _saveTableSizePersistance(
    ctx: StateContext<SuppliersV2StateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      suppliersEditorTablePageSize: newPageSize,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<SuppliersV2StateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(() => {
          return ctx.dispatch(new SaveFailed());
        })
      );
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<SuppliersV2StateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      suppliersEditorTableExcludedColumns: excludedColumns,
      overrideSuppliersEditorTableExcludedColumns: true,
    };
  }

  private _buildPhaseOutMultiUpdateProperties(
    ctx: StateContext<SuppliersV2StateModel>
  ): SupplierUpdateMultipleProperties[] {
    return Object.values(ctx.getState().selection).map(
      (supplier: SelectedSupplier) => {
        return {
          uuid: supplier.uuid,
          ignored: !supplier.ignored,
        };
      }
    );
  }

  private _removeMultipleFromSelection(
    ctx: StateContext<SuppliersV2StateModel>,
    keys: string[]
  ) {
    const selection = { ...ctx.getState().selection };

    keys.forEach(key => delete selection[key]);

    ctx.patchState({
      selection: { ...selection },
    });
  }

  private _buildExportData(
    ctx: StateContext<SuppliersV2StateModel>,
    exportData: ExportDataV2
  ): ExportRequestDataV2 {
    const filtersWithoutGroups: Filter[] = Object.values(
      ctx.getState().filtersGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const columnsWithoutGroups: DatatableColumnV2[] = Object.values(
      ctx.getState().columnsGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const visibleColumns: string[] = columnsWithoutGroups
      .filter(
        (column: DatatableColumnV2) =>
          column.checked && !this.notExportableColumns.includes(column.name)
      )
      .map((column: DatatableColumnV2) => column.name);

    return {
      visibleColumns,
      fileName: exportData.fileName,
      fileType: exportData.fileType,
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(ctx.getState().sortBy),
      },
      include: exportData.onlySelected
        ? [
            ...Object.values(ctx.getState().selection).map(
              (selected: SelectedSupplier) => selected.uuid
            ),
          ]
        : [],
    };
  }

  private _fetchSupplier(
    webshopUuid: string,
    supplierUuid: string
  ): Observable<Supplier> {
    return this.suppliersV2Service
      .fetchSupplier(webshopUuid, supplierUuid)
      .pipe(
        catchError(e => {
          throw new Error(e.message || e);
        })
      );
  }

  private _updateSupplierGRPC(
    webshopUuid: string,
    supplierUuid: string,
    payload: SupplierUpdateProperties
  ): Observable<any> {
    return this.suppliersV2Service
      .updateSupplierGRPC(webshopUuid, supplierUuid, payload)
      .pipe(
        catchError(e => {
          throw new Error(e.message || e);
        })
      );
  }

  private _createSupplier(
    ctx: StateContext<SuppliersV2StateModel>,
    webshopUuid: string,
    settings: SupplierCreateProperties
  ): Observable<any> {
    return this.suppliersV2Service.createSupplier(webshopUuid, settings).pipe(
      catchError(e => {
        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }
}
