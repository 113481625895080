import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Locale } from 'src/app/shared/models/locale.model';

import { ChangeLocale, LocaleChanged } from '../actions/locale.action';
import { LocaleService } from '../services/locale.service';

export interface LocaleStateModel {
  locales: Locale[];
  currentLocale: Locale;
}

const defaultLocales: Locale[] = [
  {
    value: 'en-US',
    display: 'English',
    beta: false,
    flag: 'flag-en',
    decimalSeparator: 'point',
  },
  {
    value: 'nl-NL',
    display: 'Nederlands',
    beta: false,
    flag: 'flag-nl',
    decimalSeparator: 'comma',
  },
];

@State<LocaleStateModel>({
  name: 'localeState',
  defaults: {
    locales: defaultLocales,
    currentLocale: defaultLocales[0],
  },
})
@Injectable()
export class LocaleState implements NgxsOnInit {
  constructor(private localeService: LocaleService) {}

  @Selector()
  static locales(state: LocaleStateModel): Locale[] {
    return state.locales;
  }

  @Selector()
  static currentLocale(state: LocaleStateModel): Locale {
    return state.currentLocale;
  }

  @Selector()
  static localeId(state: LocaleStateModel): string {
    return state.currentLocale.value;
  }

  ngxsOnInit(ctx?: StateContext<any>) {
    const localeIdx = defaultLocales.findIndex(
      l => l.value === this.localeService.locale
    );
    ctx.patchState({
      currentLocale:
        localeIdx > -1 ? defaultLocales[localeIdx] : defaultLocales[0],
    });
  }

  @Action(LocaleChanged)
  localeChange(ctx: StateContext<LocaleStateModel>, action: LocaleChanged) {
    this.localeService.redirect(action.localeId);
  }

  @Action(ChangeLocale)
  changeLocale(ctx: StateContext<LocaleStateModel>, action: LocaleChanged) {
    const localeIdx = defaultLocales.findIndex(
      l => l.value === action.localeId
    );

    ctx.patchState({
      currentLocale: defaultLocales[localeIdx],
    });
  }
}
