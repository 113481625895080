import { DialogRef } from '@angular/cdk/dialog';
import { PurchaseConfirmationV2Overview } from '../model/purchase-confirmation-v2.model';
import { PlacedOrder } from 'src/app/shared/models/purchase/v2/purchase-v2.model';

export class InitializePurchaseConfirmation {
  static readonly type =
    '[Purchase Confirmation] Initialize Purchase Confirmation';

  constructor(public purchaseOverview: PurchaseConfirmationV2Overview) {}
}

export class EnableReplan {
  static readonly type = '[Purchase Confirmation] Enable Replan';
}

export class DisableReplan {
  static readonly type = '[Purchase Confirmation] Disabled Replan';
}

export class ChangePlanningDate {
  static readonly type = '[Purchase Confirmation] Change Planning Date';

  constructor(public planningDate: string) {}
}

export class ResetPurchaseConfirmation {
  static readonly type = '[Purchase Confirmation] Reset Purchase Confirmation';
}

export class SetUseSupplierSettings {
  static readonly type = '[Purchase Confirmation] Set Use Supplier Settings';

  constructor(public useSupplierSettings: boolean) {}
}

export class ToggleProductsDataStorage {
  static readonly type =
    '[Purchase Confirmation] Toggle Products Data Storage ';
}

export class HandlePlaceOrder {
  static readonly type = '[Purchase Confirmation] Handle Place Order';
}

export class SetOrderPlaced {
  static readonly type = '[Purchase Confirmation] Set Order Placed';

  constructor(public placedOrder: PlacedOrder) {}
}

export class SetOrderRedirect {
  static readonly type = '[Purchase Confirmation] Set Order Redirect';

  constructor(public redirectTo: string) {}
}

export class DonePurchaseConfirmation {
  static readonly type = '[Purchase Confirmation] Done Purchase Confirmation';

  constructor(public dialogRef: DialogRef) {}
}
