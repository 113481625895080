import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Actions,
  ofActionDispatched,
  ofActionSuccessful,
  select,
  Store,
} from '@ngxs/store';
import { filter, map } from 'rxjs';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';
import { UnsetAllPendingChanges } from 'src/app/core/actions/pending-changes.action';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { ConfirmActions } from 'src/app/shared/components/base-confirmation-dialog/actions/base-confirmation.actions';
import {
  BaseConfirmationDialogSize,
  ComponentConfirmActionsKeys,
} from 'src/app/shared/components/base-confirmation-dialog/model/base-confirmation-data.model';
import { ConfirmSave } from 'src/app/shared/components/confirmation-dialog/actions/confirmation.actions';
import { ExportsDialogComponent } from 'src/app/shared/components/exports-dialog/exports-dialog.component';
import { SimpleConfirmationDialogComponent } from 'src/app/shared/components/simple-confirmation-dialog/simple-confirmation-dialog.component';
import { DataTableRowForm } from 'src/app/shared/models/supplier-product/v2/editor/data-table-row-form';
import {
  LinkSupplierProductData,
  SupplierProductLink,
} from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import {
  RemoveLinkSupplierProduct,
  SaveLinkedSupplierProductsEditor,
  UpdateProductAvailability,
  UpdateProductAvailabilityDate,
  UpdateProductDeliveryTime,
  UpdateProductEAN,
  UpdateProductLotSize,
  UpdateProductMOQ,
  UpdateProductPreferred,
  UpdateProductPrice,
  UpdateProductSku,
  UpdateProductVolume,
  UpdateProductWeight,
  UpdateSupplierProductName,
} from '../../../actions/product-suppliers-v2-editor.actions';
import {
  AddFilterParam,
  AddSearchParam,
  CancelEditWebshopProductSuppliers,
  ColumnsSelected,
  EditWebshopProductSuppliers,
  ExportProductSuppliersV2,
  HideColumn,
  LoadSuppliersToLink,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  Sort,
  ToggleFilter,
} from '../../../actions/product-suppliers-v2.actions';
import { LinkedSupplierProductsEditorStateQueries } from '../../../state/product-suppliers-v2-editor.queries';
import { WebshopProductSuppliersStateQueries } from '../../../state/product-suppliers-v2.queries';

import { Dialog } from '@angular/cdk/dialog';
import { ConfirmExport } from 'src/app/shared/components/exports-dialog/actions/exports-dialog.action';
import { AddProductSupplierDialogComponent } from '../../add-product-supplier-dialog/add-product-supplier-dialog.component';
import { DataTableV2Component } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.component';
import {
  ColumnFilterParam,
  ColumnHide,
  Filtering,
  Pagination,
  Search,
  Sortable,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { WebshopProductSuppliersState } from '../../../state/product-suppliers-v2.state';
import { PurchaseOverlayStateQueries } from 'src/app/features/purchase-v3/components/purchase-overlay-v2/state/purchase-overlay.queries';
import { COLUMNS_ORDER } from '../../../model/data-table.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-product-suppliers-v2-data-table',
  templateUrl: './product-suppliers-v2-data-table.component.html',
  styleUrls: ['./product-suppliers-v2-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSuppliersV2DataTableComponent
  extends DataTableV2Component<SupplierProductLink[]>
  implements
    Pagination,
    Search,
    Filtering,
    ColumnHide,
    Sortable,
    OnInit,
    OnDestroy
{
  @Input()
  webshopProductUuid: string;

  @Input()
  dataForm: DataTableRowForm;

  isPopout = select(PurchaseOverlayStateQueries.isPopout);
  currencySymbol = select(WebshopState.webshopCurrencySymbol);
  multiSupplierEnabled = select(WebshopState.multiSupplierEnabled);
  exportsEnabled = select(WebshopState.exportsEnabled);
  saving = select(LinkedSupplierProductsEditorStateQueries.saving);
  haveEditedLinkedSupplierProducts = select(
    LinkedSupplierProductsEditorStateQueries.haveEditedLinkedSupplierProducts
  );
  loading = select(WebshopProductSuppliersStateQueries.loading);
  editing = select(WebshopProductSuppliersStateQueries.editing);
  haveLinkedSupplierProducts = select(
    WebshopProductSuppliersStateQueries.haveLinkedSupplierProducts
  );
  currentPage = select(WebshopProductSuppliersStateQueries.currentPage);
  totalElements = select(WebshopProductSuppliersStateQueries.totalElements);
  pageSizeOptions = select(WebshopProductSuppliersStateQueries.pageSizeOptions);
  pageSize = select(WebshopProductSuppliersStateQueries.pageSize);
  selectableColumns = select(
    WebshopProductSuppliersStateQueries.selectableColumns
  );
  lastDisplayedColumn = select(
    WebshopProductSuppliersStateQueries.lastDisplayedColumn
  );
  displayedColumns = select(
    WebshopProductSuppliersStateQueries.displayedColumns
  );
  datasource = select(WebshopProductSuppliersStateQueries.links);
  filterOpened = select(WebshopProductSuppliersStateQueries.filterOpened);
  activeFilters = select(WebshopProductSuppliersStateQueries.activeFilters);
  filters = select(WebshopProductSuppliersStateQueries.filters);
  searchBy = select(WebshopProductSuppliersStateQueries.search);

  canRefresh = select(WebshopProductSuppliersState.canRefresh());

  minAvailabilityDate: string = new Date().toUTCString();

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_COLUMNS = STRINGS.columns.productSuppliers;

  readonly TABLE_TOOLTIPS = STRINGS.tooltips.supplierProduct;

  readonly TABLE_METADATA = STRINGS.metadata.productSuppliers;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RELATED_STATE = WebshopProductSuppliersState;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  private _linkSupplierProductData: LinkSupplierProductData;

  constructor(
    private store: Store,
    private actions: Actions,
    private dialog: Dialog,
    private destroyRef: DestroyRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(WebshopProductSuppliersStateQueries.linkSupplierProductData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: LinkSupplierProductData) => {
        this._linkSupplierProductData = data;
      });

    this.actions
      .pipe(
        ofActionSuccessful(SaveLinkedSupplierProductsEditor),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.store.dispatch(new UnsetAllPendingChanges());
        this.dialog.closeAll();
      });

    this.actions
      .pipe(
        ofActionDispatched(ConfirmActions),
        filter(
          (action: ConfirmActions) =>
            action.actionKey ===
            ComponentConfirmActionsKeys.REMOVE_PRODUCT_SUPPLIER
        ),
        map((action: ConfirmActions) => action.actionData),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((supplierProductUUID: string) => {
        this.store.dispatch(new RemoveLinkSupplierProduct(supplierProductUUID));
      });

    this.actions
      .pipe(
        ofActionSuccessful(RemoveLinkSupplierProduct),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.dataForm.reset();

        this.dialog.closeAll();
      });

    this.actions
      .pipe(
        ofActionSuccessful(ConfirmSave),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        const snapshot = this.store.selectSnapshot(
          LinkedSupplierProductsEditorStateQueries.haveEditedLinkedSupplierProducts
        );
        if (snapshot) {
          this.save();
        } else {
          this.cancel();
        }
      });

    this.actions
      .pipe(
        ofActionSuccessful(ConfirmExport),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ exportData }) => {
        this.store.dispatch(new ExportProductSuppliersV2(exportData));
      });
  }

  refreshDatatable(): void {
    this.store.dispatch(new ReloadDatatable());
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onSort(sort: Sorted): void {
    this.store.dispatch(new Sort(sort));
  }

  onColumnHidden(columnKey: string) {
    this.store.dispatch(new HideColumn(columnKey));
  }

  addSearchParam(param: string) {
    this.store.dispatch(new AddSearchParam(param));
  }

  removeSearchParam(param: string) {
    this.store.dispatch(new RemoveSearchParam(param));
  }

  toggleFilter(): void {
    this.store.dispatch(new ToggleFilter());
  }

  clearAllFilters(): void {
    this.store.dispatch(new RemoveAllFilters());
  }

  onFiltered(params: ColumnFilterParam): void {
    this.store.dispatch(new AddFilterParam(params));
  }

  goToSupplier(supplierUuid: string) {
    this.store.dispatch(
      new NavigateToWithQueryParams(['suppliers', 'details'], {
        supplierUUID: supplierUuid,
      })
    );
  }

  removeSupplierProduct(supplierProductUuid: string) {
    this.dialog.open(SimpleConfirmationDialogComponent, {
      width: BaseConfirmationDialogSize,
      disableClose: true,
      data: {
        title: $localize`Remove supplier?`,
        body: $localize`Are you sure you want to <strong>remove this supplier</strong>?<br>This action cannot be undone.`,
        actionName: $localize`Remove supplier`,
        actionType: 'danger',
        actionKey: ComponentConfirmActionsKeys.REMOVE_PRODUCT_SUPPLIER,
        actionData: supplierProductUuid,
      },
    });
  }

  openExportsDialog(): void {
    this.dialog.open(ExportsDialogComponent, {
      width: '500px',
      data: {
        include: [],
      },
      disableClose: true,
    });
  }

  linkNewSupplierProduct() {
    this.store.dispatch(new LoadSuppliersToLink());

    this.dialog.open(AddProductSupplierDialogComponent, {
      width: '700px',
      disableClose: true,
      data: this._linkSupplierProductData,
    });
  }

  editLinkedSupplierProducts() {
    this.store.dispatch(new EditWebshopProductSuppliers());

    this.datasource().forEach(value => {
      const supplierProductLink: SupplierProductLink = value;

      this.dataForm.addShared({
        supplierUuid: supplierProductLink.supplier.uuid,
        supplierProductName: supplierProductLink.supplierProduct.name,
        sku: supplierProductLink.supplierProduct.sku,
        weight: supplierProductLink.supplierProduct.weight,
        volume: supplierProductLink.supplierProduct.volume,
        eanCode: supplierProductLink.supplierProduct.eanCode,
        price: supplierProductLink.supplierProduct.price,
        deliveryTime: supplierProductLink.supplierProduct.deliveryTime,
        purchaseInQuantitiesOf:
          supplierProductLink.supplierProduct.purchaseInQuantitiesOf,
        minimumPurchaseQuantity:
          supplierProductLink.supplierProduct.minimumPurchaseQuantity,
        availability: supplierProductLink.supplierProduct.availability,
        availabilityDate: supplierProductLink.supplierProduct.availabilityDate,
        preferred: supplierProductLink.supplierProduct.preferred,
        status: supplierProductLink.supplierProduct.status,
      });
    });
  }

  updatePreferred(supplierProduct: SupplierProductLink, value: boolean): void {
    this.store.dispatch(new UpdateProductPreferred(supplierProduct, value));

    this.dataForm.managePreferredRadioBehaviour(supplierProduct.supplier.uuid);
  }

  updateAvailability(
    supplierProduct: SupplierProductLink,
    value: boolean
  ): void {
    this.store.dispatch(new UpdateProductAvailability(supplierProduct, value));

    this.dataForm.formSource.shared
      .get(supplierProduct.supplier.uuid)
      .get('availabilityDate')
      .patchValue(null);
  }

  updateAvailabilityDate(
    supplierProduct: SupplierProductLink,
    value: string
  ): void {
    this.store.dispatch(
      new UpdateProductAvailabilityDate(supplierProduct, value)
    );
  }

  updateSupplierProductName(
    supplierProduct: SupplierProductLink,
    value: string
  ): void {
    this.store.dispatch(new UpdateSupplierProductName(supplierProduct, value));
  }

  updateSKU(supplierProduct: SupplierProductLink, value: string): void {
    this.store.dispatch(new UpdateProductSku(supplierProduct, value));
  }

  updateLotSize(supplierProduct: SupplierProductLink, value: string): void {
    this.store.dispatch(
      new UpdateProductLotSize(supplierProduct, Number(value))
    );
  }

  updateEAN(supplierProduct: SupplierProductLink, value: string): void {
    this.store.dispatch(new UpdateProductEAN(supplierProduct, value));
  }

  updateMOQ(supplierProduct: SupplierProductLink, value: string): void {
    this.store.dispatch(new UpdateProductMOQ(supplierProduct, Number(value)));
  }

  updateDeliveryTime(
    supplierProduct: SupplierProductLink,
    value: string
  ): void {
    const deliveryTime = !value ? null : Number(value);

    this.store.dispatch(
      new UpdateProductDeliveryTime(supplierProduct, deliveryTime)
    );
  }

  updateWeight(supplierProduct: SupplierProductLink, value: string): void {
    const weight = !value ? null : Number(value);

    this.store.dispatch(new UpdateProductWeight(supplierProduct, weight));
  }

  updateVolume(supplierProduct: SupplierProductLink, value: string): void {
    const volume = !value ? null : Number(value);

    this.store.dispatch(new UpdateProductVolume(supplierProduct, volume));
  }

  updatePrice(supplierProduct: SupplierProductLink, value: string): void {
    this.store.dispatch(new UpdateProductPrice(supplierProduct, Number(value)));
  }

  save() {
    this.store.dispatch(new SaveLinkedSupplierProductsEditor());
  }

  cancel() {
    this.store.dispatch(new CancelEditWebshopProductSuppliers());
    this.dataForm?.reset();
  }

  ngOnDestroy(): void {
    this.cancel();
  }
}
