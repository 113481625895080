import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LinkedSource } from 'src/app/core/api/integrations/model/integrations.model';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';

@Component({
  selector: 'app-source-credentials',
  templateUrl: './source-credentials.component.html',
  styleUrl: './source-credentials.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceCredentialsComponent {
  @Input() form: FormGroup;

  @Input() linkedSource: LinkedSource;

  @Input() fieldsWithPassword = new Map<string, string>();

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  constructor() {
    this.form = new FormGroup({});
  }

  /**
   * toggles the visibility of a password input field
   * @param key the key of the input field
   */
  public togglePasswordVisibility(key: string): void {
    if (this.fieldsWithPassword.get(key) === 'password') {
      this.fieldsWithPassword.set(key, 'text');
    } else {
      this.fieldsWithPassword.set(key, 'password');
    }
  }

  public getParameterKey(key: string, tenant: number, tap: string): string {
    if (tenant) {
      return key + '' + tap + '' + tenant;
    } else {
      return key + '' + tap;
    }
  }
}
