import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DataTableFormSourceGroup,
  DataTableFormSourceShared,
  LinkedSupplierProductsEditorFormGroup,
} from './editor.model';

export interface RowSupplierProperties {
  uuid: string;
  name: string;
}

export interface RowProperties {
  supplierUuid: string;
  supplierProductName: string;
  preferred: boolean;
  availability: boolean;
  availabilityDate: string;
  sku: string;
  purchaseInQuantitiesOf: number;
  eanCode: string;
  minimumPurchaseQuantity: number;
  deliveryTime: number | null;
  weight: number | null;
  volume: number | null;
  price: number;
  status: number;
}

export class DataTableRowFormSource {
  public group: FormGroup<DataTableFormSourceGroup>;
  public shared: FormGroup<DataTableFormSourceShared>;

  constructor(protected formBuilder: FormBuilder) {
    this.group = this.formBuilder.group({
      shared: this.formBuilder.group({}),
    });

    this.shared = this.formBuilder.group({});

    this.group.addControl('shared', this.shared);
  }
}

/**
 * Controls the forms of a delivery orderline data table.
 */
export class DataTableRowForm {
  public formSource: DataTableRowFormSource;

  constructor(
    protected formBuilder: FormBuilder,
    formSource: DataTableRowFormSource
  ) {
    this.formSource = formSource;
  }

  addShared(row: RowProperties) {
    if (this.formSource.shared.get(String(row.supplierUuid)) !== null) {
      return;
    }

    const supplierGroup =
      this.formBuilder.group<LinkedSupplierProductsEditorFormGroup>({
        preferred: this.formBuilder.control(
          { value: row.preferred, disabled: row.status === 1 },
          []
        ),
        availability: this.formBuilder.control(
          { value: row.availability, disabled: row.status === 1 },
          []
        ),
        availabilityDate: this.formBuilder.control(
          { value: row.availabilityDate, disabled: row.status === 1 },
          []
        ),
        supplierProductName: this.formBuilder.control(
          { value: row.supplierProductName, disabled: row.status === 1 },
          []
        ),
        sku: this.formBuilder.control(
          { value: row.sku, disabled: row.status === 1 },
          []
        ),
        purchaseInQuantitiesOf: this.formBuilder.control(
          { value: row.purchaseInQuantitiesOf, disabled: row.status === 1 },
          [Validators.required, Validators.min(0)]
        ),
        eanCode: this.formBuilder.control(
          { value: row.eanCode, disabled: row.status === 1 },
          []
        ),
        minimumPurchaseQuantity: this.formBuilder.control(
          { value: row.minimumPurchaseQuantity, disabled: row.status === 1 },
          [Validators.required, Validators.min(0)]
        ),
        deliveryTime: this.formBuilder.control(
          { value: row.deliveryTime, disabled: row.status === 1 },
          [Validators.min(1)]
        ),
        weight: this.formBuilder.control({
          value: row.weight,
          disabled: row.status === 1,
        }),
        volume: this.formBuilder.control({
          value: row.volume,
          disabled: row.status === 1,
        }),
        price: this.formBuilder.control(
          { value: row.price, disabled: row.status === 1 },
          [Validators.required, Validators.min(0)]
        ),
      });

    this.formSource.shared.addControl(String(row.supplierUuid), supplierGroup);
  }

  reset(): void {
    Object.keys(this.formSource.shared.controls).forEach(control => {
      this.formSource.shared.removeControl(control);
    });
  }

  removeControl(control: string): void {
    this.formSource.shared.removeControl(control);
  }

  /**
   * This replicates a radio button behaviour to the preferred checkboxes
   * since it only allows 1 supplier as *preferred*
   * @param control string
   */
  managePreferredRadioBehaviour(control: string) {
    const preferredControl = this.formSource.shared
      .get(control)
      .get('preferred');

    // Check if user is checking the box
    if (preferredControl.value) {
      Object.keys(this.formSource.shared.controls).forEach(_control => {
        if (control !== _control) {
          this.formSource.shared
            .get(_control)
            .get('preferred')
            .setValue(false, { emitEvent: false });
        }
      });
    }
  }

  managePreferredRadioBehaviourAvalilability(control: string) {
    this.formSource.shared
      .get(control)
      .get('preferred')
      .setValue(false, { emitEvent: false });
  }
}
