import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';

export interface PromotionInterval {
  start: string;
  end: string;
}

export interface PromotionUplift {
  increase: number;
  type: string;
}

export interface PromotionSettings {
  enabled: boolean;
}

export interface PromotionStatus {
  label: string;
  color: 'default' | 'orange' | 'green' | 'blue';
}

export interface Promotion {
  uuid: string;
  webshopUUID: string;
  name: string;
  interval: PromotionInterval;
  uplift: PromotionUplift;
  numberOfProducts: number;
  settings: PromotionSettings;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status?: PromotionStatus;
  id: number;
  notes?: string;
  extras?: any;
}

export interface WebshopProduct {
  uuid: string;
  promotionUUID: string;
  specificUplift: PromotionUplift;
  effectiveUplift: PromotionUplift;
  createdAt: string;
  updatedAt: string;
}

export interface WebshopProductPromotion {
  promotion: Promotion;
  webshopProduct: WebshopProduct;
}

export enum PromotionUpliftTypes {
  RELATIVE = 'RELATIVE',
  ABSOLUTE = 'ABSOLUTE',
  CLOSE_OUT = 'CLOSE_OUT',
  NO_UPLIFT = 'NO_UPLIFT',
}

export enum PromotionUpliftTypesEnum {
  ABSOLUTE,
  RELATIVE,
  CLOSE_OUT,
  NO_UPLIFT,
}

export const promotionUpliftOptions = [
  {
    key: STRINGS.metadata.promotions.uplifts.relativeTitle,
    value: PromotionUpliftTypes.RELATIVE,
  },
  {
    key: STRINGS.metadata.promotions.uplifts.absolute,
    value: PromotionUpliftTypes.ABSOLUTE,
  },
  {
    key: STRINGS.metadata.promotions.uplifts.closeOut,
    value: PromotionUpliftTypes.CLOSE_OUT,
  },
  {
    key: STRINGS.metadata.promotions.uplifts.noUplift,
    value: PromotionUpliftTypes.NO_UPLIFT,
  },
];

export const UPLIFT_TYPES = {
  [PromotionUpliftTypes.RELATIVE]: STRINGS.metadata.promotions.uplifts.relative,
  [PromotionUpliftTypes.ABSOLUTE]: STRINGS.metadata.promotions.uplifts.absolute,
  [PromotionUpliftTypes.CLOSE_OUT]:
    STRINGS.metadata.promotions.uplifts.closeOut,
  [PromotionUpliftTypes.NO_UPLIFT]:
    STRINGS.metadata.promotions.uplifts.noUplift,
};

export interface WebshopProductPromotionProperties {
  promotion: Promotion;
  specificUpliftType: string;
  specificUpliftIncrease: number;
}

export interface CreateWebshopProductPromotionProperties {
  products: WebshopProductProperties[];
}

export interface WebshopProductProperties {
  uuid: string;
  promotionUUID: string;
  specificUplift?: PromotionUplift;
}
