<span
  data-testid="checkbox-container"
  (click)="handleClick()"
  (keydown.enter)="handleClick()"
  class="container"
  [class.decorative]="decorative"
  [class.container-disabled]="disabled">
  <div
    class="checkbox"
    [class.disabled]="disabled"
    [class.show-indeterminated]="!checked && indeterminate">
    <input
      data-testid="checkbox-input"
      type="checkbox"
      [id]="checkboxId"
      [checked]="checked"
      [disabled]="disabled"
      [required]="required"
      [tabindex]="tabIndex" />

    <span class="checkbox-mark checkmark">
      <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.756 10.126L0 6.37L1.414 4.956L3.658 7.2L9.958 0L11.464 1.317L3.756 10.126Z"
          fill="currentColor" />
      </svg>
    </span>

    <span class="checkbox-mark indeterminated">
      <svg
        width="10"
        height="3"
        viewBox="0 0 10 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.000525611 0.934653L0.00048813 3.00006L10.0005 3.00013L10.0004 0.934672L0.000525611 0.934653Z"
          fill="currentColor" />
      </svg>
    </span>
  </div>

  <!-- For labels -->
  <span
    *ngIf="label"
    class="label"
    [class.label-disabled]="disabled"
    data-testid="checkbox-label">
    {{ label }}
  </span>
</span>
