<button
  appButton
  appButtonIcon
  variant="link"
  transparent
  (click)="refreshed.emit()"
  [appTooltip]="COMMON_STRINGS.refresh"
  [tooltipDisabled]="disabled()"
  [disabled]="disabled()">
  <mat-icon [class.rotating]="loading()" svgIcon="rotating" />
</button>
