import { DateTime } from 'luxon';

/**
 * Formats date to Iso
 * @param date js date
 * @returns Datetime formatted to Iso
 */
export function formatDateToIso(date: string): string {
  return DateTime.fromJSDate(new Date(date)).startOf('day').toISO();
}

/**
 * Removes unnecessary time info from Iso (':00.000+01:00')
 * @param isoDate string
 * @returns Datetime formatted (eg. 2023-03-27T00:00)
 */
export function removeUnnecessaryTimeInfoFromIso(isoDate: string) {
  return isoDate.slice(0, 16);
}

/**
 * Formats date to Iso and removes unnecessary time info
 * @param date js date
 * @returns Datetime formatted
 */
export function formatDateToIsoAndRemoveUnnecessaryInfo(date: string): string {
  return removeUnnecessaryTimeInfoFromIso(formatDateToIso(date));
}

export function formatDateWithCutoffToWebshopTimezone(
  timeZoneIdentifier: string,
  cutoff: string,
  date?: string
): Date {
  let todayWithCutoff = null;
  if (!date) {
    todayWithCutoff =
      DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd') + ' ' + cutoff;
  } else {
    todayWithCutoff =
      DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd') + ' ' + cutoff;
  }

  return new Date(
    todayWithCutoff.toLocaleString('en-US', {
      timeZone: timeZoneIdentifier,
    })
  );
}

export function formatDateToWebshopTimezone(
  timeZoneIdentifier: string,
  date: string
): string {
  const todayWithCutoff = DateTime.fromJSDate(new Date(date)).toFormat(
    'yyyy-MM-dd HH:mm:ss'
  );

  return new Date(todayWithCutoff).toLocaleString('en-US', {
    timeZone: timeZoneIdentifier,
  });
}
