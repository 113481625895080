import { Pipe, PipeTransform } from '@angular/core';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';

@Pipe({
  name: 'lastMinutesAdviceConversion',
})
export class LastMinutesAdviceConversionPipe implements PipeTransform {
  private readonly hourInMinutes: number = 60;
  private readonly dayInMinutes: number = 1440;
  private readonly monthInMinutes: number = 43829;

  transform(minutes: number): string {
    if (this._justNow(minutes)) {
      return STRINGS.metadata.purchaseEditor.adviceUpdateAt.suffix.justNow;
    }

    if (this._isMinutes(minutes)) {
      return `${minutes} ${STRINGS.metadata.purchaseEditor.adviceUpdateAt.suffix.updatedMinutes}`;
    }

    if (this._isHour(minutes)) {
      return `${this._getHours(minutes)} ${
        STRINGS.metadata.purchaseEditor.adviceUpdateAt.suffix.updatedHours
      }`;
    }

    if (this._isDay(minutes)) {
      return `${this._getDays(minutes)} ${
        STRINGS.metadata.purchaseEditor.adviceUpdateAt.suffix.updatedDays
      }`;
    }

    if (this._isMonth(minutes)) {
      return `${this._getMonths(minutes)} ${
        STRINGS.metadata.purchaseEditor.adviceUpdateAt.suffix.updatedMonths
      }`;
    }
  }

  private _justNow(minutes: number): boolean {
    return minutes < 1;
  }

  private _isMinutes(minutes: number): boolean {
    return minutes >= 1 && minutes < this.hourInMinutes;
  }

  private _isHour(minutes: number): boolean {
    return minutes >= this.hourInMinutes && minutes < this.dayInMinutes;
  }

  private _isDay(minutes: number): boolean {
    return minutes >= this.dayInMinutes && minutes < this.monthInMinutes;
  }

  private _isMonth(minutes: number): boolean {
    return minutes >= this.monthInMinutes;
  }

  private _getHours(minutes: number): number {
    return Math.round(minutes / this.hourInMinutes);
  }

  private _getDays(minutes: number): number {
    return Math.round(minutes / this.dayInMinutes);
  }

  private _getMonths(minutes: number): number {
    return Math.round(minutes / this.monthInMinutes);
  }
}
