import { ServiceRequestInfo } from 'src/app/shared/components/data-table-v2/model/pageable.model';

export interface LoadImportsRequestInfo extends ServiceRequestInfo {
  webshopUUID: string;
}

export interface ImportsRequestInfo {
  previousPage: number;
  page: number;
  limit: number;
  sortBy: string;
  order: string;
  filters: string;
}

export type ThemeType = {
  global: {
    backgroundColor: string;
    primaryTextColor: string;
    secondaryTextColor: string;
    successColor: string;
    warningColor: string;
    outerPadding?: 'true' | 'false' | undefined | null;
  };
  buttons: {
    primary: {
      backgroundColor: string;
      color: string;
      border: string;
      borderRadius: string;
      boxShadow: string;
      ':hover': {
        backgroundColor: string;
        color: string;
      };
      fontSize?: string;
      fontWeight?: string;
      fontStyle?: string;
    };
    secondary: {
      backgroundColor: string;
      color: string;
      border: string;
      borderRadius: string;
      boxShadow: string;
      ':hover': {
        backgroundColor: string;
        color: string;
      };
      fontSize?: string;
      fontWeight?: string;
      fontStyle?: string;
    };
    tertiary?:
      | {
          backgroundColor: string;
          color: string;
          border: string;
          borderRadius: string;
          boxShadow: string;
          ':hover': {
            backgroundColor: string;
            color: string;
          };
          fontSize?: string;
          fontWeight?: string;
          fontStyle?: string;
        }
      | undefined
      | null;
  };
  tables?:
    | {
        headerColor: string;
        headerBackgroundColor: string;
        borderColor: string;
        color: string;
      }
    | null
    | undefined;
  steps?:
    | {
        backgroundColor: string;
        activeBackgroundColor: string;
        completedBackgroundColor: string;
        color: string;
        activeColor: string;
        completedColor: string;
        borderColor: string;
        activeBorderColor: string;
        completedBorderColor: string;
        connectorColor: string;
        titleColor: string;
      }
    | null
    | undefined;
  modals?:
    | {
        backgroundColor: string;
        color: string;
        border: string;
        boxShadow: string;
        buttons: {
          confirm: {
            backgroundColor: string;
            color: string;
            border: string;
            borderRadius: string;
            boxShadow: string;
            ':hover': {
              backgroundColor: string;
              color: string;
            };
            fontSize?: string;
            fontWeight?: string;
            fontStyle?: string;
          };
          cancel: {
            backgroundColor: string;
            color: string;
            border: string;
            borderRadius: string;
            boxShadow: string;
            ':hover': {
              backgroundColor: string;
              color: string;
            };
            fontSize?: string;
            fontWeight?: string;
            fontStyle?: string;
          };
        };
      }
    | null
    | undefined;
  customFont?:
    | Array<{
        fontFamily: string;
        fontStyle: string | undefined;
        fontWeight: string | undefined;
        fontDisplay: string | undefined;
        srcURL: string;
        srcFormat: string;
        unicodeRange: string | undefined;
      }>
    | undefined
    | null;
};

type customText = {
  importedRows:
    | {
        text: string | undefined;
        downloadRowsButton: string | undefined;
      }
    | undefined;
  rejectedRows:
    | {
        text: string | undefined;
        downloadRowsButton: string | undefined;
      }
    | undefined;
};

export const dynamicColumnsWithStatusId = [
  {
    name: 'id',
    example: '2313123123',
    description: 'Optiply product ID',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'number' },
  },
  {
    name: 'name',
    example: 'name_product_1',
    mustBeMatched: false,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'skuCode',
    example: 'sku_product_1',
    description: 'The SKU (Stock Keeping Unit) code that your company uses.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'eanCode',
    example: 'ean_product_1',
    description: 'The EAN code used for selling this product.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'articleCode',
    example: 'ac_product_1',
    description:
      'An article code that your company uses that is not the SKU or the EAN',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'price',
    example: '46.6',
    description: 'The price used for selling this Product.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'price must be a number',
  },
  {
    name: 'stockLevel',
    example: '13',
    description:
      'The current physical level of stock minus already sold quantity. Also referred to as free stock.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'stock level must be a number',
  },
  {
    name: 'notBeingBought',
    example: 'true',
    description:
      'When true, Stock level advice for this product will be 0. Also known as Phase Out.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'nbb must be a boolean',
  },
  {
    name: 'resumingPurchase',
    example: '2099-01-01',
    description:
      'Related to notBeingBought. After this date notBeingBought will be set to FALSE',
    validationFormat: { type: 'date', format: 'YYYY-MM-DD' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'resuming must be a date',
  },
  {
    name: 'status',
    example: 'ENABLED',
    description:
      'When DISABLED the product will be deactivated, mimicking a delete.',
    validationFormat: {
      type: 'regex',
      format: '^(0|1|ENABLED|DISABLED)$',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'status must a boolean',
  },
  {
    name: 'assembled',
    example: 'true',
    description:
      'Set to true when a product is not purchased, but assembled. Add compositions if sales need to be mapped to underlying products.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'assembled must a boolean',
  },
  {
    name: 'minimumStock',
    example: '33',
    description: 'The minimum stock that should be aimed for.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'min stock must a number',
  },
  {
    name: 'ignored',
    example: 'true',
    description:
      'When true the product will be removed from all calculations and from billing, effectively deactivating it.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'ignored must a boolean',
  },
];

export const dynamicColumnsWithStatusSkuCode = [
  {
    name: 'name',
    example: 'name_product_1',
    mustBeMatched: false,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'skuCode',
    example: 'sku_product_1',
    description: 'The SKU (Stock Keeping Unit) code that your company uses.',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'eanCode',
    example: 'ean_product_1',
    description: 'The EAN code used for selling this product.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'articleCode',
    example: 'ac_product_1',
    description:
      'An article code that your company uses that is not the SKU or the EAN',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'price',
    example: '46.6',
    description: 'The price used for selling this Product.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'price must be a number',
  },
  {
    name: 'stockLevel',
    example: '13',
    description:
      'The current physical level of stock minus already sold quantity. Also referred to as free stock.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'stock level must be a number',
  },
  {
    name: 'notBeingBought',
    example: 'true',
    description:
      'When true, Stock level advice for this product will be 0. Also known as Phase Out.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'nbb must be a boolean',
  },
  {
    name: 'resumingPurchase',
    example: '2099-01-01',
    description:
      'Related to notBeingBought. After this date notBeingBought will be set to FALSE',
    validationFormat: { type: 'date', format: 'YYYY-MM-DD' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'resuming must be a date',
  },
  {
    name: 'status',
    example: 'ENABLED',
    description:
      'When DISABLED the product will be deactivated, mimicking a delete.',
    validationFormat: {
      type: 'regex',
      format: '^(0|1|ENABLED|DISABLED)$',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'status must a boolean',
  },
  {
    name: 'assembled',
    example: 'true',
    description:
      'Set to true when a product is not purchased, but assembled. Add compositions if sales need to be mapped to underlying products.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'assembled must a boolean',
  },
  {
    name: 'minimumStock',
    example: '33',
    description: 'The minimum stock that should be aimed for.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'min stock must a number',
  },
  {
    name: 'ignored',
    example: 'true',
    description:
      'When true the product will be removed from all calculations and from billing, effectively deactivating it.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'ignored must a boolean',
  },
];

export const dynamicColumnsWithStatusEanCode = [
  {
    name: 'name',
    example: 'name_product_1',
    mustBeMatched: false,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'skuCode',
    example: 'sku_product_1',
    description: 'The SKU (Stock Keeping Unit) code that your company uses.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'eanCode',
    example: 'ean_product_1',
    description: 'The EAN code used for selling this product.',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'articleCode',
    example: 'ac_product_1',
    description:
      'An article code that your company uses that is not the SKU or the EAN',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'price',
    example: '46.6',
    description: 'The price used for selling this Product.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'price must be a number',
  },
  {
    name: 'stockLevel',
    example: '13',
    description:
      'The current physical level of stock minus already sold quantity. Also referred to as free stock.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'stock level must be a number',
  },
  {
    name: 'notBeingBought',
    example: 'true',
    description:
      'When true, Stock level advice for this product will be 0. Also known as Phase Out.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'nbb must be a boolean',
  },
  {
    name: 'resumingPurchase',
    example: '2099-01-01',
    description:
      'Related to notBeingBought. After this date notBeingBought will be set to FALSE',
    validationFormat: { type: 'date', format: 'YYYY-MM-DD' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'resuming must be a date',
  },
  {
    name: 'status',
    example: 'ENABLED',
    description:
      'When DISABLED the product will be deactivated, mimicking a delete.',
    validationFormat: {
      type: 'regex',
      format: '^(0|1|ENABLED|DISABLED)$',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'status must a boolean',
  },
  {
    name: 'assembled',
    example: 'true',
    description:
      'Set to true when a product is not purchased, but assembled. Add compositions if sales need to be mapped to underlying products.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'assembled must a boolean',
  },
  {
    name: 'minimumStock',
    example: '33',
    description: 'The minimum stock that should be aimed for.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'min stock must a number',
  },
  {
    name: 'ignored',
    example: 'true',
    description:
      'When true the product will be removed from all calculations and from billing, effectively deactivating it.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'ignored must a boolean',
  },
];

export const dynamicColumnsWithStatusArticleCode = [
  {
    name: 'name',
    example: 'name_product_1',
    mustBeMatched: false,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'skuCode',
    example: 'sku_product_1',
    description: 'The SKU (Stock Keeping Unit) code that your company uses.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'eanCode',
    example: 'ean_product_1',
    description: 'The EAN code used for selling this product.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'articleCode',
    example: 'ac_product_1',
    description:
      'An article code that your company uses that is not the SKU or the EAN',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'price',
    example: '46.6',
    description: 'The price used for selling this Product.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'price must be a number',
  },
  {
    name: 'stockLevel',
    example: '13',
    description:
      'The current physical level of stock minus already sold quantity. Also referred to as free stock.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'stock level must be a number',
  },
  {
    name: 'notBeingBought',
    example: 'true',
    description:
      'When true, Stock level advice for this product will be 0. Also known as Phase Out.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'nbb must be a boolean',
  },
  {
    name: 'resumingPurchase',
    example: '2099-01-01',
    description:
      'Related to notBeingBought. After this date notBeingBought will be set to FALSE',
    validationFormat: { type: 'date', format: 'YYYY-MM-DD' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'resuming must be a date',
  },
  {
    name: 'status',
    example: 'ENABLED',
    description:
      'When DISABLED the product will be deactivated, mimicking a delete.',
    validationFormat: {
      type: 'regex',
      format: '^(0|1|ENABLED|DISABLED)$',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'status must a boolean',
  },
  {
    name: 'assembled',
    example: 'true',
    description:
      'Set to true when a product is not purchased, but assembled. Add compositions if sales need to be mapped to underlying products.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'assembled must a boolean',
  },
  {
    name: 'minimumStock',
    example: '33',
    description: 'The minimum stock that should be aimed for.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'min stock must a number',
  },
  {
    name: 'ignored',
    example: 'true',
    description:
      'When true the product will be removed from all calculations and from billing, effectively deactivating it.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'ignored must a boolean',
  },
];

export const useCSVcustomText: customText = {
  importedRows: {
    text: '{{rows_count}} rows were received. Track progress in the Imports page.',
    downloadRowsButton: undefined,
  },
  rejectedRows: undefined,
};

export const useCSVDarkTheme: ThemeType = {
  global: {
    backgroundColor: '#212736',
    primaryTextColor: '#EBEBEB',
    secondaryTextColor: '#9FB0C3',
    successColor: '#30CD84',
    warningColor: '#FE7777',
    outerPadding: 'false',
  },
  buttons: {
    primary: {
      backgroundColor: '#75B0FF',
      color: '#212736',
      border: 'none',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#8EBEFF',
        color: '#212736',
      },
      fontSize: '0.875rem',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    secondary: {
      backgroundColor: '#2D3549',
      color: '#9FB0C3',
      border: '#2D3549',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#121721',
        color: '#9FB0C3',
      },
      fontSize: '0.875rem',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    tertiary: {
      backgroundColor: '#2D3549',
      color: '#9FB0C3',
      border: '#2D3549',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#121721',
        color: '#9FB0C3',
      },
      fontSize: '0.875rem',
      fontWeight: '600',
      fontStyle: 'normal',
    },
  },
  tables: {
    headerColor: '#9FB0C3',
    headerBackgroundColor: '#181D2A',
    borderColor: '#303546',
    color: '#9FB0C3',
  },
  steps: {
    backgroundColor: '#212736',
    activeBackgroundColor: '#212736',
    completedBackgroundColor: '#75B0FF',
    color: '#6C7888',
    activeColor: '#75B0FF',
    completedColor: '#212736',
    borderColor: '#6C7888',
    activeBorderColor: '#75B0FF',
    completedBorderColor: '#75B0FF',
    connectorColor: '#6C7888',
    titleColor: '#9FB0C3',
  },
  modals: {
    backgroundColor: '#212736',
    color: '#9FB0C3',
    border: '1px solid #6C7888',
    boxShadow: 'none',
    buttons: {
      confirm: {
        backgroundColor: '#75B0FF',
        color: '#212736',
        border: 'none',
        borderRadius: '0.375rem',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: '#8EBEFF',
          color: '#212736',
        },
        fontSize: '0.875rem',
        fontWeight: '600',
        fontStyle: 'normal',
      },
      cancel: {
        backgroundColor: '#2D3549',
        color: '#9FB0C3',
        border: '#2D3549',
        borderRadius: '0.375rem',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: '#121721',
          color: '#9FB0C3',
        },
        fontSize: '0.875rem',
        fontWeight: '600',
        fontStyle: 'normal',
      },
    },
  },
};

export const useCSVLightTheme: ThemeType = {
  global: {
    backgroundColor: '#FFFFFF',
    primaryTextColor: '#222B36',
    secondaryTextColor: '#50657F',
    successColor: '#O67D5E',
    warningColor: '#C62525',
    outerPadding: 'false',
  },
  buttons: {
    primary: {
      backgroundColor: '#1860C4',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#2f679c',
        color: '#FFFFFF',
      },
      fontSize: '0.875rem',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    secondary: {
      backgroundColor: '#F1F5F9',
      color: '#50657F',
      border: '#F1F5F9',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#DEE6F2',
        color: '#50657F',
      },
      fontSize: '0.875rem',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    tertiary: {
      backgroundColor: '#F1F5F9',
      color: '#50657F',
      border: '#F1F5F9',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: '#DEE6F2',
        color: '#50657F',
      },
      fontSize: '0.875rem',
      fontWeight: '600',
      fontStyle: 'normal',
    },
  },
  tables: {
    headerColor: '#50657F',
    headerBackgroundColor: '#F5F8FB',
    borderColor: '#D7DFEC',
    color: '#222B36',
  },
  steps: {
    backgroundColor: '#FFFFFF',
    activeBackgroundColor: '#FFFFFF',
    completedBackgroundColor: '#1860C4',
    color: '#98A6B7',
    activeColor: '#1860C4',
    completedColor: '#FFFFFF',
    borderColor: '#98A6B7',
    activeBorderColor: '#1860C4',
    completedBorderColor: '#1860C4',
    connectorColor: '#98A6B7',
    titleColor: '#50657F',
  },
  modals: {
    backgroundColor: '#FFFFFF',
    color: '#50657F',
    border: '1px solid #98A6B7',
    boxShadow: 'none',
    buttons: {
      confirm: {
        backgroundColor: '#1860C4',
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '0.375rem',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: '#2f679c',
          color: '#FFFFFF',
        },
        fontSize: '0.875rem',
        fontWeight: '600',
        fontStyle: 'normal',
      },
      cancel: {
        backgroundColor: '#F1F5F9',
        color: '#50657F',
        border: '#F1F5F9',
        borderRadius: '0.375rem',
        boxShadow: 'none',
        ':hover': {
          backgroundColor: '#DEE6F2',
          color: '#50657F',
        },
        fontSize: '0.875rem',
        fontWeight: '600',
        fontStyle: 'normal',
      },
    },
  },
};
