import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appInputPrefix]',
})
export class InputPrefixDirective {
  @HostBinding('class')
  class: string = 'input-prefix';
}

@Directive({
  selector: '[appInputSuffix]',
})
export class InputSuffixDirective {
  @HostBinding('class')
  class: string = 'input-suffix';
}
