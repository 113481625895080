<form #form="ngForm" class="container">
  <h3 class="bracket-title">{{ METADATA_STRINGS.email }}</h3>
  <div class="section">
    <div class="field-title">
      <span>{{ METADATA_STRINGS.title }}</span>
    </div>

    <div>
      <app-dropdown
        #languageModel="ngModel"
        name="language"
        [ngModel]="template().language"
        (ngModelChange)="updateTemplateLanguage($event)"
        type="outline"
        [disabled]="disabled()"
        required
        forceOptionsReset>
        @for (lang of templateLanguages(); track lang.value) {
          <app-dropdown-option [value]="lang.value">{{
            lang.key
          }}</app-dropdown-option>
        }
      </app-dropdown>

      @if (allowSetDefaultTemplate()) {
        <div class="default-template-container">
          <app-toggle
            name="preferredLanguage"
            [ngModel]="template().isDefault"
            (ngModelChange)="setDefaultTemplate()"
            [showState]="false"
            [disabled]="template().isDefault || viewOnly() || disabled()" />

          <div class="slider-label">
            <div class="field-title">
              <span i18n="set default template label"
                >Set {{ template().language | appLanguageLabel }} as my default
                template language</span
              >
            </div>

            @if (defaultTemplateHelper()) {
              <mat-icon
                class="info-icon"
                svgIcon="info-rounded"
                [appTooltip]="TOOLTIPS_STRINGS.defaultLanguage" />
            }
          </div>
        </div>
      }
    </div>
  </div>

  <div class="section">
    <div class="field-title">
      <span
        >{{ METADATA_STRINGS.subject.title
        }}<span appRequiredLabel>*</span></span
      >
    </div>

    <div>
      <input
        appInput
        variant="outline"
        #subjectModel="ngModel"
        name="subject"
        [ngModel]="template().subject"
        (ngModelChange)="updateTemplateSubject($event)"
        [placeholder]="METADATA_STRINGS.subject.placeholder"
        [disabled]="(viewOnly() && !ignorePermissions()) || disabled()"
        [required]="!!bodyModel.control.value || required()" />

      <div *ngIf="subjectModel.errors?.required" class="error">
        <mat-icon svgIcon="warning-rounded" />
        <p>{{ METADATA_STRINGS.subject.error }}</p>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="field-title">
      <span
        >{{ METADATA_STRINGS.body.title }}<span appRequiredLabel>*</span></span
      >
    </div>

    <div>
      <textarea
        appInput
        variant="outline"
        #bodyModel="ngModel"
        name="body"
        [ngModel]="template().body"
        (ngModelChange)="updateTemplateBody($event)"
        [placeholder]="PLACEHOLDER_STRING"
        [maxlength]="BODY_MAX_LENGTH"
        rows="10"
        [disabled]="(viewOnly() && !ignorePermissions()) || disabled()"
        [required]="!!subjectModel.control.value || required()"></textarea>

      <div class="email-footer">
        <div *ngIf="bodyModel.errors?.required" class="error">
          <mat-icon svgIcon="warning-rounded" />
          <p>{{ METADATA_STRINGS.body.error }}</p>
        </div>

        <div class="counter-container">
          <span class="counter">{{ bodyCounter() }}</span
          ><span class="counter-max">/{{ BODY_MAX_LENGTH }}</span>
        </div>
      </div>
    </div>
  </div>
</form>
