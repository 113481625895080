import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpansionPanelComponent } from './expansion-panel.component';
import { ExpansionPanelHeaderComponent } from './components/expansion-panel-header/expansion-panel-header.component';
import { AccordionComponent } from './core/accordion/accordion.component';
import { ExpansionPanelTitleDirective } from './directives/expansion-panel-title.directive';
import { MatIconModule } from '@angular/material/icon';
import { ExpansionPanelToggleDirective } from './directives/expansion-panel-toggle.directive';

@NgModule({
  declarations: [
    ExpansionPanelComponent,
    ExpansionPanelHeaderComponent,
    AccordionComponent,
    ExpansionPanelTitleDirective,
    ExpansionPanelToggleDirective,
  ],
  imports: [CommonModule, MatIconModule],
  exports: [
    ExpansionPanelComponent,
    ExpansionPanelHeaderComponent,
    AccordionComponent,
    ExpansionPanelTitleDirective,
    ExpansionPanelToggleDirective,
  ],
})
export class ExpansionPanelModule {}
