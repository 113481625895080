import { PromotionUpliftTypes } from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { AddProductPromotionsStateModel } from '../state/add-product-promotion.state';

export const defaults: AddProductPromotionsStateModel = {
  saving: false,
  promotions: [],
  page: { totalElements: 0 },
  addPromotionProperties: {
    promotion: null,
    specificUpliftIncrease: null,
    specificUpliftType: PromotionUpliftTypes.NO_UPLIFT,
  },
};
