import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { ProposedSession } from '../model/proposed-buy-orderlines-v2.model';

export class ProposedSessionAdapter
  implements DataAdapter<ProposedSession, ProposedSession>
{
  transform(data: ProposedSession): ProposedSession {
    return {
      sessionUuid: data.sessionUuid,
    };
  }
}
