import { Selector, createSelector } from '@ngxs/store';
import { TodoListV2State, TodoListV2StateModel } from './todo-list-v2.state';
import {
  Issue,
  IssueStatusColors,
  capIssuesValues,
} from '../model/todo-list-v2.model';

export class SuppliersTodoQueries {
  @Selector([TodoListV2State])
  static issuesLoading(state: TodoListV2StateModel): boolean {
    return state.suppliersIssues.loading;
  }

  @Selector([TodoListV2State])
  static issuesData(state: TodoListV2StateModel): Issue[] {
    return state.suppliersIssues.data;
  }

  @Selector([TodoListV2State])
  static issuesDataAvailable(state: TodoListV2StateModel): boolean {
    return !!state.suppliersIssues.data.length;
  }

  static issuesDataFilteredAmount(status: IssueStatusColors) {
    return createSelector(
      [SuppliersTodoQueries.issuesData],
      (issues: Issue[]) => {
        if (!issues.length) return '0';

        const totals = issues.reduce((acc, curr) => {
          return acc + (curr.color === status ? parseInt(curr.total) : 0);
        }, 0);

        const totalsCapped = capIssuesValues(totals);

        return totalsCapped;
      }
    );
  }

  static hasIssuesDataFiltered(status: IssueStatusColors) {
    return createSelector(
      [SuppliersTodoQueries.issuesDataFilteredAmount(status)],
      (amount: string) => {
        return amount !== '0';
      }
    );
  }
}
