import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import { PurchaseExportsSettingsOptions } from './model/purchase-exports-settings.model';
import { Store } from '@ngxs/store';
import { UpdateExportsSettings } from 'src/app/core/actions/buyorder-settings.actions';

@Component({
  selector: 'app-purchase-exports-settings',
  templateUrl: './purchase-exports-settings.component.html',
  styleUrl: './purchase-exports-settings.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseExportsSettingsComponent {
  store = inject(Store);

  private _selectionModel = new SelectionModel<string>(true, []);

  @Input()
  set selectedOptions(options: string[]) {
    this._selectionModel.clear();
    this._selectionModel.setSelection(...options);
  }

  readonly SUPPLIER_EXPORT_SETTINGS_OPTIONS = [
    PurchaseExportsSettingsOptions.SP_NAME,
    PurchaseExportsSettingsOptions.SP_SKU_CODE,
    PurchaseExportsSettingsOptions.SP_EAN_CODE,
    PurchaseExportsSettingsOptions.SP_ARTICLE_CODE,
  ];

  readonly WEBSHOP_EXPORT_SETTINGS_OPTIONS = [
    PurchaseExportsSettingsOptions.WP_NAME,
    PurchaseExportsSettingsOptions.WP_SKU_CODE,
    PurchaseExportsSettingsOptions.WP_EAN_CODE,
    PurchaseExportsSettingsOptions.WP_ARTICLE_CODE,
  ];

  readonly EXPORT_SETTINGS_STRINGS =
    STRINGS.metadata.purchaseConfirmation.advancedExportsSettings;

  isSelected(option: string): boolean {
    return this._selectionModel.isSelected(option);
  }

  toggleSelection(option: string): void {
    this._selectionModel.toggle(option);

    this.store.dispatch(
      new UpdateExportsSettings(this._selectionModel.selected)
    );
  }

  get reachedMaximumSelection(): boolean {
    return this._selectionModel.selected.length >= 4;
  }
}
