import { DataAdapter } from 'src/app/shared/adapter/adapter';

import { WebshopProduct } from 'src/app/shared/models/products/v3/products.model';
import {
  PromotionProduct,
  PromotionWebshopProducts,
} from 'src/app/shared/models/promotion-products-v2/promotion-products-v2.model';
import {
  PromotionUplift,
  PromotionUpliftTypes,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { PromotionUplift as PromotionUpliftV1 } from '../../../promotions/v2/model/promotions-v2.model';
import { WebshopProduct as WebshopProductV1 } from '../model/products-v3.model';
import {
  PromotionWebshopProducts as PromotionWebshopProductsV1,
  PromotionProduct as PromotionProductV1,
} from '../model/promotions-products-v3.model';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';

export class PromotionProductsV2Adapter
  implements DataAdapter<PromotionWebshopProductsV1, PromotionWebshopProducts>
{
  transform(data: PromotionWebshopProductsV1): PromotionWebshopProducts {
    const products: PromotionProduct[] = data.products.map(
      (product: PromotionProductV1) => {
        return {
          webshopProduct: this._buildWebshopProduct(product.webshopProduct),
          promotionUuid: product.promotionUuid,
          specificUplift: this._buildSpecificUplift(product.specificUplift),
          extras: {
            formattedUpliftType: product.specificUplift?.type
              ? this._buildFormattedUpliftType(product.specificUplift.type)
              : null,
          },
        };
      }
    );

    return {
      data: products,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  private _buildWebshopProduct(
    webshopProduct: WebshopProductV1
  ): WebshopProduct {
    return {
      uuid: webshopProduct.uuid,
      id: webshopProduct.id,
      category: webshopProduct.category,
      name: webshopProduct.name,
      sku: webshopProduct.sku,
      eanCode: webshopProduct.eanCode,
      price: webshopProduct.price,
      serviceLevel: webshopProduct.serviceLevel,
      supplyTime: webshopProduct.supplyTime,
      demandPerMonth: webshopProduct.demandPerMonth,
      stockOnHand: webshopProduct.stockOnHand,
      minimumStock: webshopProduct.minimumStock,
      assembled: webshopProduct.assembled,
      ignored: webshopProduct.ignored,
      notBeingBought: webshopProduct.notBeingBought,
      resumingPurchase: webshopProduct.resumingPurchase,
      novel: webshopProduct.novel,
      deadStock: webshopProduct.deadStock,
      maximumStock: webshopProduct.maximumStock,
      articleCode: webshopProduct.articleCode,
      manualServiceLevel: webshopProduct.manualServiceLevel,
      createdAtRemote: webshopProduct.createdAtRemote,
      xyzCategory: webshopProduct.xyzCategory,
    };
  }

  private _buildSpecificUplift(
    uplift: PromotionUpliftV1 | null
  ): PromotionUplift {
    if (uplift === null) return null;

    return {
      type: uplift.type,
      increase: uplift.increase,
    };
  }

  private _buildFormattedUpliftType(upliftType: string) {
    const upliftTypes = {
      [PromotionUpliftTypes.RELATIVE]:
        STRINGS.metadata.promotions.uplifts.relative,
      [PromotionUpliftTypes.ABSOLUTE]:
        STRINGS.metadata.promotions.uplifts.absolute,
      [PromotionUpliftTypes.CLOSE_OUT]:
        STRINGS.metadata.promotions.uplifts.closeOut,
      [PromotionUpliftTypes.NO_UPLIFT]: '-',
    };

    return upliftTypes[upliftType];
  }
}
