/**
 * Authentication actions.
 * Components or services interested on any of the provided actions should
 * subscribe to it to receive notifications.
 */

/**
 * Action for when the service authenticates the requested user
 * credentials sucessfully. @see AuthenticationState
 */
export class LoginSucessful {
  static readonly type = '[Auth] LoginSucessfull';
}

/**
 * Action for when the authentication fails.
 */
export class LoginFailed {
  static readonly type = '[Auth] LoginFailed';
}

/**
 * Action for when the user triggers a logout from the component.
 */
export class Logout {
  static readonly type = '[Auth] Logout';
}

export class AuthenticationUpdated {
  static readonly type = '[Auth] Update';

  constructor(public authenticated: boolean) {}
}

export class UpdateAuthAccount {
  static readonly type = '[Auth] Update Auth Account';

  constructor(public userName: string) {}
}
