import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ColorTheme } from 'src/app/shared/models/account/account-settings.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  setColorTheme(colorTheme: ColorTheme) {
    this._removeThemeClasses();

    this._addThemeClass(colorTheme);
  }

  private _addThemeClass(colorTheme: ColorTheme): void {
    this.renderer.addClass(document.body, colorTheme.toLowerCase());
  }

  private _removeThemeClasses(): void {
    this.renderer.removeClass(document.body, 'dark');
    this.renderer.removeClass(document.body, 'light');
  }
}
