import { DateTime } from 'luxon';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  Supplier,
  SupplierProduct,
  SupplierProductLink,
  SupplierProductLinks,
  WebshopProduct,
} from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import {
  Supplier as SupplierV1,
  SupplierProduct as SupplierProductV1,
  SupplierProductLink as SupplierProductLinkV1,
  SupplierProductLinks as SupplierProductLinksV1,
  WebshopProduct as WebshopProductV1,
} from '../model/supplier-product-v2.model';

export class SupplierProductLinksV2Adapter
  implements DataAdapter<SupplierProductLinksV1, SupplierProductLinks>
{
  transform(data: SupplierProductLinksV1): SupplierProductLinks {
    const links: SupplierProductLink[] = data.links.map(
      (link: SupplierProductLinkV1) => {
        return {
          supplierProduct: this._buildSupplierProduct(link.supplierProduct),
          webshopProduct: this._buildWebshopProduct(link.webshopProduct),
          supplier: this._buildSupplier(link.supplier),
        };
      }
    );

    return {
      data: links,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  private _buildSupplierProduct(
    supplierProduct: SupplierProductV1
  ): SupplierProduct {
    if (supplierProduct === null) return null;

    return {
      id: supplierProduct.id,
      uuid: supplierProduct.uuid,
      name: supplierProduct.name,
      sku: supplierProduct.sku,
      eanCode: supplierProduct.eanCode,
      price: supplierProduct.price,
      minimumPurchaseQuantity: supplierProduct.minimumPurchaseQuantity,
      purchaseInQuantitiesOf: supplierProduct.purchaseInQuantitiesOf,
      availability: supplierProduct.availability,
      availabilityDate:
        supplierProduct.availabilityDate !== null
          ? DateTime.fromMillis(
              Number(supplierProduct.availabilityDate)
            ).toFormat('yyyy-MM-dd')
          : null,
      preferred: supplierProduct.preferred,
      active: supplierProduct.active,
      currentStockOnHand: supplierProduct.currentStockOnHand,
      weight: supplierProduct.weight,
      volume: supplierProduct.volume,
      deliveryTime: supplierProduct.deliveryTime,
      stockPosition: supplierProduct.stockPosition,
      status: supplierProduct.status,
      articleCode: supplierProduct.articleCode,
      createdAt:
        supplierProduct.createdAt !== null
          ? DateTime.fromSeconds(Number(supplierProduct.createdAt)).toFormat(
              'yyyy-MM-dd'
            )
          : null,
    };
  }

  private _buildWebshopProduct(
    webshopProduct: WebshopProductV1
  ): WebshopProduct {
    if (webshopProduct === null) return null;

    return {
      id: Number(webshopProduct.id),
      uuid: webshopProduct.uuid,
      name: webshopProduct.name,
      sku: webshopProduct.sku,
      eanCode: webshopProduct.eanCode,
      articleCode: webshopProduct.articleCode,
    };
  }

  private _buildSupplier(supplier: SupplierV1): Supplier {
    if (supplier === null) return null;

    return {
      id: Number(supplier.id),
      uuid: supplier.uuid,
      name: supplier.name,
    };
  }
}
