import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonV2Component } from './radio-button-v2.component';
import { RadioButtonGroupV2Directive } from './directives/radio-button-group-v2.directive';

@NgModule({
  declarations: [RadioButtonV2Component, RadioButtonGroupV2Directive],
  imports: [CommonModule],
  exports: [RadioButtonV2Component, RadioButtonGroupV2Directive],
})
export class RadioButtonV2Module {}
