import { SupplierProductLink } from '../product-suppliers.model';
import { LinkedSupplierProductsEditorListener } from './editor.model';

export class LinkedSupplierProductEditorListener
  implements LinkedSupplierProductsEditorListener<SupplierProductLink>
{
  webshopProductId(product: SupplierProductLink): number {
    return product.webshopProduct.id;
  }

  supplierId(product: SupplierProductLink): number {
    return product.supplier.id;
  }

  name(product: SupplierProductLink): string {
    return product.supplierProduct.name;
  }

  sku(product: SupplierProductLink): string {
    return product.supplierProduct.sku;
  }

  eanCode(product: SupplierProductLink): string {
    return product.supplierProduct.eanCode;
  }

  minimumPurchaseQuantity(product: SupplierProductLink): number {
    return product.supplierProduct.minimumPurchaseQuantity;
  }

  purchaseInQuantitiesOf(product: SupplierProductLink): number {
    return product.supplierProduct.purchaseInQuantitiesOf;
  }

  weight(product: SupplierProductLink): number {
    return product.supplierProduct.weight;
  }

  volume(product: SupplierProductLink): number {
    return product.supplierProduct.volume;
  }

  price(product: SupplierProductLink): number {
    return product.supplierProduct.price;
  }

  deliveryTime(product: SupplierProductLink): number | null {
    return product.supplierProduct.deliveryTime;
  }

  preferred(product: SupplierProductLink): boolean {
    return product.supplierProduct.preferred;
  }

  availability(product: SupplierProductLink): boolean {
    return product.supplierProduct.availability;
  }

  availabilityDate(product: SupplierProductLink): string {
    return product.supplierProduct.availabilityDate;
  }

  currentStockOnHand(product: SupplierProductLink): number {
    return product.supplierProduct.currentStockOnHand;
  }

  status(product: SupplierProductLink): number {
    return product.supplierProduct.status;
  }
}
