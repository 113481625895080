<div
  role="option"
  [tabindex]="tabIndex"
  (click)="selectViaInteraction(); $event.stopPropagation()"
  (keydown.enter)="selectViaInteraction(); $event.stopPropagation()"
  class="dropdown-option-container"
  [class.dropdown-option-disabled]="isDisabled"
  [class.dropdown-option-selected]="selected">
  <div *ngIf="multiple" class="dropdown-option-multiple">
    <app-checkbox
      [checked]="selected"
      [disabled]="disabled"
      (checkedChange)="selectOption($event)"
      [decorative]="true"></app-checkbox>
  </div>

  <span class="dropdown-option-label">
    <ng-content select="mat-icon"></ng-content>

    <span
      #labelText
      class="dropdown-option-label-text"
      [appTooltip]="label"
      tooltipShouldCheckOverflow>
      {{ label }}

      <!-- ng-content for simple usage, use label if you want tooltips to work correctly -->
      <ng-content></ng-content>
    </span>
  </span>
</div>
