import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';

@Injectable({ providedIn: 'root' })
export class ExportService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private exportOrder(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConceptsRest',
      'v1',
      'exportOrder',
      replacements
    );
    return this.http.get(endpoint, {
      params: payload,
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }
  private exportOrdersUndelivered(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConceptsRest',
      'v1',
      'exportOrderUndelivered',
      replacements
    );
    return this.http.get(endpoint, {
      params: payload,
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  exportAsXLSXdoc(
    webshopUUID: string,
    buyOrderUUID: string,
    fileType: string
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'buyOrderUUID', value: buyOrderUUID },
    ];

    return this.exportOrder({ fileType }, replacements);
  }

  exportOrderUndelivered(
    webshopUUID: string,
    buyOrderUUID: string,
    fileType: string
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'buyOrderUUID', value: buyOrderUUID },
    ];

    return this.exportOrdersUndelivered({ fileType }, replacements);
  }
}
