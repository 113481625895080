<div>
  <app-warning-banner
    type="info"
    icon="info-rounded"
    [description]="EXPORT_SETTINGS_STRINGS.warning" />

  <div class="details-container">
    <div class="details-section">
      <div>
        <p class="details-section-title">
          {{ EXPORT_SETTINGS_STRINGS.supplierReference.title }}
        </p>
        <p class="details-section-description">
          {{ EXPORT_SETTINGS_STRINGS.supplierReference.description }}
        </p>
      </div>
      <div>
        @for (
          supplierOption of SUPPLIER_EXPORT_SETTINGS_OPTIONS;
          track supplierOption
        ) {
          <app-checkbox
            [label]="EXPORT_SETTINGS_STRINGS.fields[supplierOption]"
            [checked]="isSelected(supplierOption)"
            (checkedChange)="toggleSelection(supplierOption)"
            [disabled]="
              !isSelected(supplierOption) && reachedMaximumSelection
            " />
        }
      </div>
    </div>

    <div class="details-section">
      <div>
        <p class="details-section-title">
          {{ EXPORT_SETTINGS_STRINGS.webshopReference.title }}
        </p>
        <p class="details-section-description">
          {{ EXPORT_SETTINGS_STRINGS.webshopReference.description }}
        </p>
      </div>
      <div>
        @for (
          webshopOption of WEBSHOP_EXPORT_SETTINGS_OPTIONS;
          track webshopOption
        ) {
          <app-checkbox
            [label]="EXPORT_SETTINGS_STRINGS.fields[webshopOption]"
            [checked]="isSelected(webshopOption)"
            (checkedChange)="toggleSelection(webshopOption)"
            [disabled]="
              !isSelected(webshopOption) && reachedMaximumSelection
            " />
        }
      </div>
    </div>
  </div>
</div>
