<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"
      [disabled]="editing()"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      [disabled]="editing()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <ng-container
      *ngIf="editing() === false && isPopout() === false; else editingActions">
      <button
        *ngIf="multiSupplierEnabled() || haveLinkedSupplierProducts() === false"
        data-intercom-target="e6821f65-0dc1-4844-b7f5-0cee998ce661"
        aria-label="Product details suppliers table add button"
        appButton
        variant="secondary"
        [disabled]="loading()"
        (click)="linkNewSupplierProduct()">
        {{ TABLE_METADATA.actions.add }}
      </button>

      <button
        *ngIf="haveLinkedSupplierProducts() === true"
        data-intercom-target="dad65424-f85f-454d-8a64-d33925562106"
        aria-label="Product details suppliers table edit button"
        appButton
        variant="secondary"
        [disabled]="loading()"
        (click)="editLinkedSupplierProducts()">
        {{ TABLE_METADATA.actions.edit }}
      </button>
    </ng-container>

    <ng-template #editingActions>
      <ng-container *ngIf="isPopout() === false">
        <button
          data-intercom-target="de1eddbd-c315-4ea7-a082-d53c8eea8451"
          aria-label="Product details suppliers table save button"
          appButton
          variant="secondary"
          [disabled]="
            dataForm.formSource.shared.status === 'INVALID' ||
            haveEditedLinkedSupplierProducts() === false ||
            saving()
          "
          (click)="save()">
          {{ COMMON_STRINGS.save }}
        </button>

        <button
          data-intercom-target="3094f774-1540-422c-959d-1d872d5f7016"
          aria-label="Product details suppliers table cancel button"
          appButton
          variant="tertiary"
          (click)="cancel()">
          {{ COMMON_STRINGS.cancel }}
        </button>
      </ng-container>
    </ng-template>

    <app-data-table-refresh-button
      data-intercom-target="Refresh table data button"
      aria-label="Refresh table data button"
      [loading]="loading()"
      [disabled]="loading() || !canRefresh()"
      (refreshed)="refreshDatatable()" />

    <div class="vertical-separator"></div>

    <button
      *ngIf="exportsEnabled() && isPopout() === false"
      data-intercom-target="9de776d6-2fa6-48fe-b088-c2cb1756a3ce"
      aria-label="Product details suppliers table export button"
      appButton
      appButtonIcon
      variant="link"
      transparent
      class="export-button"
      [appTooltip]="COMMON_STRINGS.exportAs"
      (click)="openExportsDialog()">
      <mat-icon svgIcon="arrow-download" class="gray"></mat-icon>
    </button>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="filterOpened()">
  <app-filter-group
    [filters]="activeFilters()"
    (filtered)="onFiltered($event)"
    [disabled]="editing()">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"
      [disabled]="editing()"></app-add-filter-button> </app-filter-group
></app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <form [formGroup]="dataForm.formSource.shared">
    <table
      cdk-table
      [dataSource]="datasource()"
      appFilterableV2
      [disableHideColumn]="lastDisplayedColumn()"
      (sorted)="onSort($event)"
      (hidden)="onColumnHidden($event)"
      (filtered)="onFiltered($event)">
      <!-- Preferred -->
      <ng-container
        [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.key">
        <th
          data-intercom-target="47145b90-3e94-4bd5-bb42-8849504db659"
          aria-label="Product details suppliers table Preferred column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.key
              | filterColumn: RELATED_STATE
              | async
          "
          [label]="TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.name">
          {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="fit-to-value">
          <div *ngIf="editing() === false">
            <mat-icon
              *ngIf="element.supplierProduct.preferred"
              svgIcon="verified-badge"
              class="verified-icon"
              [appTooltip]="TABLE_TOOLTIPS.preferred"></mat-icon>
          </div>
          <div
            data-intercom-target="935f152a-6658-484a-8d9e-cd959456f084"
            aria-label="Product details suppliers table Preferred setting"
            *ngIf="editing()"
            [formGroupName]="element.supplier.uuid">
            <app-toggle
              [formControl]="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('preferred')
              "
              [showState]="false"
              (checkedChange)="updatePreferred(element, $event)"></app-toggle>
          </div>
        </td>
      </ng-container>

      <!-- Supplier product active -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.key">
        <th
          data-intercom-target="f568cb9b-5fe9-4090-901d-8211cf527188"
          aria-label="Product details suppliers table active column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="fit-to-value"
          [label]="TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.name">
          {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <mat-icon
            *ngIf="element.supplierProduct.active"
            svgIcon="check"
            class="done-icon"
            [appTooltip]="
              TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.name
            "></mat-icon>
        </td>
      </ng-container>

      <!-- Available -->
      <ng-container
        [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.key">
        <th
          data-intercom-target="22b96145-44a2-4497-8b6c-cd54c8e4b8d1"
          aria-label="Product details suppliers table Available column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="fit-to-value"
          [label]="TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.name">
          {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <div *ngIf="editing() === false">
            <mat-icon
              *ngIf="element.supplierProduct.availability"
              svgIcon="check"
              class="done-icon"
              [appTooltip]="TABLE_TOOLTIPS.available"></mat-icon>
            <mat-icon
              *ngIf="!element.supplierProduct.availability"
              svgIcon="cross"
              class="close-icon"
              [appTooltip]="TABLE_TOOLTIPS.notAvailable"></mat-icon>
          </div>

          <div
            data-intercom-target="0086963c-8b2e-4ccf-96c1-0ceb9f59223f"
            aria-label="Product details suppliers table Available setting"
            *ngIf="editing()"
            class="input-container"
            [formGroupName]="element.supplier.uuid">
            <app-toggle
              [formControl]="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('availability')
              "
              [showState]="false"
              (checkedChange)="
                updateAvailability(element, $event)
              "></app-toggle>
          </div>
        </td>
      </ng-container>

      <!-- Availability date -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.AVAILABLE_DATE.key">
        <th
          data-intercom-target="b2362846-834c-4dbe-b59f-0728fd62db17"
          aria-label="Product details suppliers table Availability date column"
          cdk-header-cell
          *cdkHeaderCellDef>
          {{ TABLE_COLUMNS.AVAILABLE_DATE.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <div *ngIf="editing() === false">
            <ng-container
              *ngIf="
                element.supplierProduct.availabilityDate !== null;
                else notAvailable
              ">
              {{ element.supplierProduct.availabilityDate | date }}
            </ng-container>
          </div>

          <div
            data-intercom-target="71f45507-b4eb-44ee-9a70-61651be0e852"
            aria-label="Product details suppliers table Availability Date setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="availabilityDate-container">
              <ng-container
                *ngIf="
                  !dataForm.formSource.shared
                    .get(element.supplier.uuid)
                    .get('availability').value;
                  else notAvailable
                ">
                <div class="fields-date-container">
                  <div
                    class="optiply-filled-datepicker"
                    (click)="picker.open()">
                    <input
                      [id]="'availabilityDate-' + element.supplier.uuid"
                      [matDatepicker]="picker"
                      name="availabilityDate"
                      [min]="picker.opened ? minAvailabilityDate : null"
                      formControlName="availabilityDate"
                      [placeholder]="COMMON_STRINGS.selectDate"
                      (dateChange)="
                        updateAvailabilityDate(element, $event.value)
                      " />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon
                        matDatepickerToggleIcon
                        svgIcon="calendar"
                        class="datepicker-icon"></mat-icon
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker> </mat-datepicker>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Supplier name -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_NAME.key">
        <th
          data-intercom-target="3e8f39ef-c163-439b-8974-d485e748c1d4"
          aria-label="Product details suppliers table Supplier Name column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_NAME.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="restricted-column">
          {{ TABLE_COLUMNS.SUPPLIER_NAME.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <span
            (click)="goToSupplier(element.supplier.uuid)"
            class="deep-linking-text">
            <app-copy-to-clipboard
              [message]="element.supplier.name"></app-copy-to-clipboard>
          </span>
        </td>
      </ng-container>

      <!-- Product name -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_NAME.key">
        <th
          data-intercom-target="a41fc403-6af1-4bbd-baf3-45b108ef3500"
          aria-label="Product details suppliers table Product Name column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_PRODUCT_NAME.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="restricted-column">
          {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_NAME.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="restricted-column">
          <div *ngIf="editing() === false">
            <ng-container
              *ngIf="element.supplierProduct.name !== null; else notAvailable">
              <app-copy-to-clipboard
                [message]="
                  element.supplierProduct.name
                "></app-copy-to-clipboard>
            </ng-container>
          </div>

          <div
            data-intercom-target="fd38d5f1-8820-4a70-b12a-077f3f70cb04"
            aria-label="Product details suppliers table Product Name setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                [id]="'supplierProductName-' + element.supplier.uuid"
                type="text"
                name="supplierProductName"
                formControlName="supplierProductName"
                [placeholder]="COMMON_STRINGS.enterText"
                (change)="
                  updateSupplierProductName(element, $event.target.value)
                " />
            </div>
          </div>
        </td>
      </ng-container>

      <!-- SKU -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_SKU.key">
        <th
          data-intercom-target="b59af239-effa-4acc-a36d-5ea64c419e51"
          aria-label="Product details suppliers table SKU column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_PRODUCT_SKU.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="restricted-column">
          {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_SKU.name }}
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="column-muted restricted-column">
          <div *ngIf="editing() === false">
            <app-copy-to-clipboard
              [message]="element.supplierProduct.sku"></app-copy-to-clipboard>
          </div>

          <div
            data-intercom-target="fd38d5f1-8820-4a70-b12a-077f3f70cb04"
            aria-label="Product details suppliers table Product Name setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                [id]="'sku-' + element.supplier.uuid"
                type="text"
                name="sku"
                formControlName="sku"
                [placeholder]="COMMON_STRINGS.enterText"
                (change)="updateSKU(element, $event.target.value)" />
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Lot size -->
      <ng-container
        [cdkColumnDef]="TABLE_COLUMNS.PURCHASE_IN_QUANTITIES_OF.key">
        <th
          data-intercom-target="c63cee60-beed-4481-8bbb-4177dfbd6098"
          aria-label="Product details suppliers table Lot Size column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.PURCHASE_IN_QUANTITIES_OF.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.PURCHASE_IN_QUANTITIES_OF.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <div *ngIf="editing() === false">
            {{ element.supplierProduct.purchaseInQuantitiesOf }}
          </div>

          <div
            data-intercom-target="7285d795-a167-472f-b054-dd3e50bcd099"
            aria-label="Product details suppliers table Lot Size setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-number-container">
              <input
                appInput
                variant="filled"
                [id]="'purchaseInQuantitiesOf-' + element.supplier.uuid"
                type="number"
                formControlName="purchaseInQuantitiesOf"
                placeholder="-"
                min="1"
                step="1"
                (change)="updateLotSize(element, $event.target.value)" />
            </div>

            <div
              *ngIf="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('purchaseInQuantitiesOf').errors;
                let errors
              "
              class="error-container">
              <mat-icon
                svgIcon="warning-rounded"
                aria-hidden="false"
                *ngIf="errors.required"
                appTooltip="This field is required"
                i18n-appTooltip="
                  multi supplier editor data table required warning
                "
                class="warning-icon">
              </mat-icon>

              <mat-icon
                *ngIf="errors.min"
                svgIcon="warning-rounded"
                aria-hidden="false"
                appTooltip="You must have a minimum of {{
                  errors.min.min
                }}, actual: {{ errors.min.actual }}"
                i18n-appTooltip="
                  multi supplier editor data table minimum warning
                "
                class="warning-icon">
              </mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- EAN -->
      <ng-container
        [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_EAN_CODE.key">
        <th
          data-intercom-target="da783fc9-26e0-4440-b0fb-dac96914000a"
          aria-label="Product details suppliers table EAN Code column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_PRODUCT_EAN_CODE.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="column-muted restricted-column">
          {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_EAN_CODE.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <div *ngIf="editing() === false">
            <app-copy-to-clipboard
              [message]="
                element.supplierProduct.eanCode
              "></app-copy-to-clipboard>
          </div>

          <div
            data-intercom-target="27fc0a78-559f-430d-a4bd-99df5ff7be5b"
            aria-label="Product details suppliers table EAN Code setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                [id]="'eanCode-' + element.supplier.uuid"
                type="text"
                formControlName="eanCode"
                [placeholder]="COMMON_STRINGS.enterText"
                (change)="updateEAN(element, $event.target.value)" />
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Supplier product article code -->
      <ng-container
        [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_ARTICLE_CODE.key">
        <th
          data-intercom-target="21646089-e9f0-41e2-adfa-5e45042305eb"
          aria-label="Product details suppliers table Article code"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.SUPPLIER_PRODUCT_ARTICLE_CODE.key
              | filterColumn: RELATED_STATE
              | async
          ">
          {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_ARTICLE_CODE.name }}
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="column-muted restricted-column">
          <ng-container
            *ngIf="
              element.supplierProduct.articleCode !== null;
              else notAvailable
            ">
            <app-copy-to-clipboard
              [message]="
                element.supplierProduct.articleCode
              "></app-copy-to-clipboard>
          </ng-container></td
      ></ng-container>

      <!-- MOQ -->
      <ng-container
        [cdkColumnDef]="TABLE_COLUMNS.MINIMUM_PURCHASE_QUANTITY.key">
        <th
          data-intercom-target="b8a8573d-110b-4404-a10b-f4ffb3aee7db"
          aria-label="Product details suppliers table MOQ column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.MINIMUM_PURCHASE_QUANTITY.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.MINIMUM_PURCHASE_QUANTITY.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <div *ngIf="editing() === false">
            {{ element.supplierProduct.minimumPurchaseQuantity }}
          </div>

          <div
            data-intercom-target="6b407f51-d6ab-42f6-b70e-f0d68460f5ba"
            aria-label="Product details suppliers table MOQ setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-number-container">
              <input
                appInput
                variant="filled"
                [id]="'minimumPurchaseQuantity-' + element.supplier.uuid"
                type="number"
                formControlName="minimumPurchaseQuantity"
                placeholder="-"
                min="1"
                step="1"
                (change)="updateMOQ(element, $event.target.value)" />
            </div>

            <div
              *ngIf="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('minimumPurchaseQuantity').errors;
                let errors
              "
              class="error-container">
              <mat-icon
                svgIcon="warning-rounded"
                aria-hidden="false"
                *ngIf="errors.required"
                appTooltip="This field is required"
                i18n-appTooltip="
                  multi supplier editor data table required warning
                "
                class="warning-icon">
              </mat-icon>

              <mat-icon
                *ngIf="errors.min"
                svgIcon="warning-rounded"
                aria-hidden="false"
                appTooltip="You must have a minimum of {{
                  errors.min.min
                }}, actual: {{ errors.min.actual }}"
                i18n-appTooltip="
                  multi supplier editor data table minimum warning
                "
                class="warning-icon">
              </mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Lead time -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.LEAD_TIME.key">
        <th
          data-intercom-target="811b409d-4cad-4a62-b01b-fa936c3258db"
          aria-label="Product details suppliers table Lead Time column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.LEAD_TIME.key | filterColumn: RELATED_STATE | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.LEAD_TIME.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <div *ngIf="editing() === false">
            <span
              *ngIf="
                element.supplierProduct.deliveryTime &&
                  element.supplierProduct.deliveryTime !== null;
                else notAvailable
              ">
              {{ element.supplierProduct.deliveryTime }}
            </span>
          </div>

          <div
            data-intercom-target="b6f9873a-8b77-4bad-b00b-fb4abcf454a3"
            aria-label="Product details suppliers table Lead Time setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-number-container">
              <div appInputWrapper>
                <span appInputSuffix>{{ COMMON_STRINGS.daySymbol }}</span>
                <input
                  appInput
                  variant="filled"
                  [id]="'deliveryTime-' + element.supplier.uuid"
                  type="number"
                  appNumericInput
                  formControlName="deliveryTime"
                  placeholder="-"
                  min="1"
                  step="1"
                  (change)="updateDeliveryTime(element, $event.target.value)" />
              </div>
            </div>

            <div
              *ngIf="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('deliveryTime').errors;
                let errors
              "
              class="error-container">
              <mat-icon
                *ngIf="errors.min"
                svgIcon="warning-rounded"
                aria-hidden="false"
                appTooltip="You must have a minimum of {{
                  errors.min.min
                }}, actual: {{ errors.min.actual }}"
                i18n-appTooltip="
                  multi supplier editor data table minimum warning
                "
                class="warning-icon">
              </mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Weight -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.WEIGHT.key">
        <th
          data-intercom-target="a3e9c0ea-3fa0-484c-a227-b8a2970dae8f"
          aria-label="Product details suppliers table Weight column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.WEIGHT.key | filterColumn: RELATED_STATE | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.WEIGHT.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <div *ngIf="editing() === false">
            <span
              *ngIf="
                element.supplierProduct.weight &&
                  element.supplierProduct.weight !== null &&
                  element.supplierProduct.weight !== 0;
                else notAvailable
              "
              >{{ element.supplierProduct.weight | customDecimal }}
              <span class="gray">g</span></span
            >
          </div>

          <div
            data-intercom-target="2aa36fd9-8ccc-49f2-a5b1-1c22dd9a60c8"
            aria-label="Product details suppliers table Weight setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-number-container">
              <div appInputWrapper>
                <span appInputSuffix>g</span>
                <input
                  appInput
                  variant="filled"
                  [id]="'weight-' + element.supplier.uuid"
                  type="number"
                  formControlName="weight"
                  placeholder="-"
                  min="0.01"
                  step="0.01"
                  (change)="updateWeight(element, $event.target.value)" />
              </div>
            </div>
            <div
              *ngIf="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('weight').errors;
                let errors
              "
              class="error-container">
              <mat-icon
                *ngIf="errors.min"
                svgIcon="warning-rounded"
                aria-hidden="false"
                appTooltip="Weight cannot be 0."
                i18n-appTooltip="
                  multi supplier editor data table weight minimum warning
                "
                class="warning-icon">
              </mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Volume -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.VOLUME.key">
        <th
          data-intercom-target="1186a3eb-a103-47c6-a1be-555bdac18b56"
          aria-label="Product details suppliers table Volume column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.VOLUME.key | filterColumn: RELATED_STATE | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.VOLUME.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <div *ngIf="editing() === false">
            <span
              *ngIf="
                element.supplierProduct.volume &&
                  element.supplierProduct.volume !== null &&
                  element.supplierProduct.volume !== 0;
                else notAvailable
              "
              >{{ element.supplierProduct.volume | customDecimal }}
              <span class="gray">cm<sup>3</sup></span></span
            >
          </div>

          <div
            data-intercom-target="b0dbf64a-c96d-478c-93ab-af94059d1420"
            aria-label="Product details suppliers table Volume setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-number-container">
              <div appInputWrapper>
                <span appInputSuffix>cm³</span>
                <input
                  appInput
                  variant="filled"
                  [id]="'volume-' + element.supplier.uuid"
                  type="number"
                  formControlName="volume"
                  placeholder="-"
                  min="0.01"
                  step="0.01"
                  (change)="updateVolume(element, $event.target.value)" />
              </div>
            </div>

            <div
              *ngIf="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('volume').errors;
                let errors
              "
              class="error-container">
              <mat-icon
                *ngIf="errors.min"
                svgIcon="warning-rounded"
                aria-hidden="false"
                appTooltip="Volume cannot be 0."
                i18n-appTooltip="
                  multi supplier editor data table volume minimum warning
                "
                class="warning-icon">
              </mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Price -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.PRICE.key">
        <th
          data-intercom-target="61b7a04d-de70-4226-9259-dd2e571ecc2c"
          aria-label="Product details suppliers table Price column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.PRICE.key | filterColumn: RELATED_STATE | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.PRICE.name }} ({{ currencySymbol() }})
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <div *ngIf="editing() === false">
            <p class="currency">
              {{ element.supplierProduct.price | customCurrency }}
            </p>
          </div>

          <div
            data-intercom-target="900fb61f-36c7-4662-b5f4-5d1875efbfc1"
            aria-label="Product details suppliers table Price setting"
            *ngIf="editing()"
            class="input-container input-container-width"
            [formGroupName]="element.supplier.uuid">
            <div class="fields-input-number-container">
              <div appInputWrapper>
                <span appInputSuffix>{{ currencySymbol() }}</span>
                <input
                  appInput
                  variant="filled"
                  [id]="'price-' + element.supplier.uuid"
                  type="number"
                  formControlName="price"
                  placeholder="-"
                  min="0"
                  step="0.1"
                  (change)="updatePrice(element, $event.target.value)" />
              </div>
            </div>

            <div
              *ngIf="
                dataForm.formSource.shared
                  .get(element.supplier.uuid)
                  .get('price').errors;
                let errors
              "
              class="error-container">
              <mat-icon
                svgIcon="warning-rounded"
                aria-hidden="false"
                *ngIf="errors.required"
                appTooltip="This field is required"
                i18n-appTooltip="
                  multi supplier editor data table required warning
                "
                class="warning-icon">
              </mat-icon>

              <mat-icon
                *ngIf="errors.min"
                svgIcon="warning-rounded"
                aria-hidden="false"
                appTooltip="You must have a minimum of {{
                  errors.min.min
                }}, actual: {{ errors.min.actual }}"
                i18n-appTooltip="
                  multi supplier editor data table minimum warning
                "
                class="warning-icon">
              </mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Action -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.ACTIONS.key" stickyEnd>
        <th
          data-intercom-target="da3a577a-5b8c-4ee9-a80d-d2fdfd7f4b06"
          aria-label="Product details suppliers table Actions column"
          cdk-header-cell
          *cdkHeaderCellDef
          class="actions-column fit-to-value"></th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="actions-container fit-to-value"
          (click)="$event.stopPropagation()">
          <div class="actions-options">
            <button
              data-intercom-target="25bd2302-94ce-46fa-bf52-db2ae836598d"
              aria-label="Product details suppliers table action options remove button"
              appButton
              variant="danger"
              [disabled]="saving()"
              (click)="removeSupplierProduct(element.supplierProduct.uuid)">
              {{ TABLE_METADATA.actions.remove }}
            </button>
          </div>
        </td>
      </ng-container>

      <tr
        cdk-header-row
        *cdkHeaderRowDef="displayedColumns()"
        class="datatable-headers"></tr>
      <tr
        data-intercom-target="70d8a333-ebd3-4d12-a790-11b551917550"
        aria-label="Product details suppliers table row"
        cdk-row
        *cdkRowDef="let row; columns: displayedColumns()"
        class="datatable-rows"
        [class.opaque]="row.supplierProduct.active === false"></tr>
    </table>
  </form>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
