import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, concatMap, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
} from 'src/app/core/actions/app.action';
import { PromotionsService } from 'src/app/core/api/promotions/v1/promotions.service';
import { PromotionsV2Service } from 'src/app/core/api/promotions/v2/promotions-v2.service';
import { AddProductPromotionsBuilderService } from 'src/app/core/services/add-promotion-builder.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  Promotion,
  PromotionUpliftTypes,
  WebshopProductPromotionProperties,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { Promotions } from 'src/app/shared/models/promotion/v2/promotions-v2.model';
import { LoadWebshopProductPromotions } from '../../../../../../product-details-v2/components/product-promotions-v2/actions/product-promotions-v2.actions';
import {
  CreateWebshopProductPromotion,
  LoadWebshopProductUnassociatedPromotions,
  ResetAddProductPromotionDialogState,
  ResetSelectedPromotion,
  UpdateSelectedPromotion,
  UpdateSpecificUpliftIncrease,
  UpdateSpecificUpliftType,
} from '../actions/add-product-promotion.actions';
import { defaults } from '../model/add-product-promotion-dialog.model';
import { DatatableState } from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';

export interface AddProductPromotionsStateModel {
  saving: boolean;
  promotions: Promotion[];
  page: Pageable;
  addPromotionProperties: WebshopProductPromotionProperties;
}

@State<AddProductPromotionsStateModel>({
  name: 'addProductPromotionsState',
  defaults,
})
@Injectable()
export class AddProductPromotionsState extends DatatableState {
  constructor(
    private store: Store,
    private promotionsV2Service: PromotionsV2Service,
    private promotionsService: PromotionsService,
    private addProductPromotionsBuilderService: AddProductPromotionsBuilderService
  ) {
    super();
  }

  @Selector()
  static promotion(state: AddProductPromotionsStateModel): Promotion {
    return state.addPromotionProperties.promotion;
  }

  @Selector()
  static promotions(state: AddProductPromotionsStateModel): Promotion[] {
    return state.promotions;
  }

  @Action(LoadWebshopProductUnassociatedPromotions, { cancelUncompleted: true })
  loadUnassociatedPromotions(
    ctx: StateContext<AddProductPromotionsStateModel>
  ) {
    return this._fetchWebshopProductUnassociatedPromotions(ctx).pipe(
      tap((promotions: Promotions) => {
        ctx.patchState({
          promotions: promotions.data,
          page: {
            totalElements: promotions.metadata.page.totalElements,
          },
        });
      })
    );
  }

  @Action(UpdateSelectedPromotion, { cancelUncompleted: true })
  updateSelectedPromotion(
    ctx: StateContext<AddProductPromotionsStateModel>,
    payload: UpdateSelectedPromotion
  ) {
    const promotion =
      ctx
        .getState()
        .promotions.find(
          (promotion: Promotion) => promotion.uuid === payload.promotionUuid
        ) || null;

    ctx.setState(
      patch<AddProductPromotionsStateModel>({
        addPromotionProperties: patch<WebshopProductPromotionProperties>({
          promotion,
        }),
      })
    );
  }

  @Action(UpdateSpecificUpliftType, { cancelUncompleted: true })
  updateSpecificUpliftType(
    ctx: StateContext<AddProductPromotionsStateModel>,
    payload: UpdateSpecificUpliftType
  ) {
    ctx.setState(
      patch<AddProductPromotionsStateModel>({
        addPromotionProperties: patch<WebshopProductPromotionProperties>({
          specificUpliftType: payload.upliftType,
          specificUpliftIncrease: [
            PromotionUpliftTypes.NO_UPLIFT,
            PromotionUpliftTypes.CLOSE_OUT,
          ].includes(payload.upliftType as PromotionUpliftTypes)
            ? null
            : ctx.getState().addPromotionProperties.specificUpliftIncrease,
        }),
      })
    );
  }

  @Action(UpdateSpecificUpliftIncrease, { cancelUncompleted: true })
  updateSpecificUpliftIncrease(
    ctx: StateContext<AddProductPromotionsStateModel>,
    payload: UpdateSpecificUpliftIncrease
  ) {
    ctx.setState(
      patch<AddProductPromotionsStateModel>({
        addPromotionProperties: patch<WebshopProductPromotionProperties>({
          specificUpliftIncrease: Number(payload.upliftIncrease),
        }),
      })
    );
  }

  @Action(ResetSelectedPromotion, { cancelUncompleted: true })
  resetSelectedPromotion(ctx: StateContext<AddProductPromotionsStateModel>) {
    ctx.setState(
      patch<AddProductPromotionsStateModel>({
        addPromotionProperties: patch<WebshopProductPromotionProperties>({
          promotion: null,
        }),
      })
    );
  }

  @Action(CreateWebshopProductPromotion, { cancelUncompleted: true })
  createWebshopProductPromotion(
    ctx: StateContext<AddProductPromotionsStateModel>,
    payload: CreateWebshopProductPromotion
  ) {
    ctx.patchState({
      saving: true,
    });

    return this._createPromotionWebshopProducts(
      ctx,
      payload.webshopProductUuid
    ).pipe(
      tap(() => {
        ctx.patchState(defaults);
        payload.dialogRef.close();
      }),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadWebshopProductPromotions(),
          new LoadWebshopProductUnassociatedPromotions(),
        ])
      )
    );
  }

  @Action(ResetAddProductPromotionDialogState)
  reset(ctx: StateContext<AddProductPromotionsStateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      saving: false,
      addPromotionProperties: {
        promotion: null,
        specificUpliftIncrease: null,
        specificUpliftType: PromotionUpliftTypes.NO_UPLIFT,
      },
    });
  }

  private _fetchWebshopProductUnassociatedPromotions(
    ctx: StateContext<AddProductPromotionsStateModel>
  ): Observable<Promotions | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    const requestInfo =
      this._buildFetchWebshopProductUnassociatedPromotionsRequestInfo();

    return this.promotionsV2Service
      .findAllWebshopProductUnassociatedPromotions(
        webshopUuid,
        webshopProductUuid,
        requestInfo
      )
      .pipe(catchError(() => ctx.dispatch(new LoadFailed())));
  }

  private _buildFetchWebshopProductUnassociatedPromotionsRequestInfo(): ServiceRequestInfoV3 {
    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: {
          any_of: [],
          match: [],
          no_match: [],
        },
        sort_by: [
          {
            field: STRINGS.columns.webshopProductPromotions.NAME.filterKey,
            order: SortOrder.ASC,
          },
        ],
        page: {
          from: 0,
          size: 200,
        },
      },
    };

    return requestInfo;
  }

  private _createPromotionWebshopProducts(
    ctx: StateContext<AddProductPromotionsStateModel>,
    webshopProductUuid: string
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildCreatePromotionWebshopProductsRequestInfo(
      ctx,
      webshopProductUuid
    );

    return this.promotionsService
      .createPromotionWebshopProducts(requestInfo, webshopUuid)
      .pipe(
        catchError(() => {
          ctx.patchState({
            saving: false,
          });

          return ctx.dispatch(new SaveFailed());
        })
      );
  }

  private _buildCreatePromotionWebshopProductsRequestInfo(
    ctx: StateContext<AddProductPromotionsStateModel>,
    webshopProductUuid: string
  ) {
    const { products } =
      this.addProductPromotionsBuilderService.buildProductPromotionsUpdateFromResult(
        [{ ...ctx.getState().addPromotionProperties }],
        webshopProductUuid
      );

    return {
      products: [...products],
    };
  }
}
