<app-product-container>
  <span header class="product-settings-title">{{ METADATA.title }}</span>

  @if (isOverlay()) {
    <div actions class="overlay-right-section">
      <button
        data-intercom-target="79e7e328-fda0-4e50-92cf-ea70ac0ca15a"
        aria-label="Product details settings cancel button"
        appButton
        variant="link"
        class="custom-info-link-btn"
        [disabled]="
          loading() || failed() || saving() || hasPendingChanges() === false
        "
        (click)="cancelChanges()">
        {{ COMMON_STRINGS.cancel }}
      </button>

      <button
        data-intercom-target="6dc19198-7515-4d40-a272-3001b628ebfc"
        aria-label="Product details settings save button"
        appButton
        variant="primary"
        [disabled]="
          loading() ||
          failed() ||
          saving() ||
          !formValid() ||
          hasValidPendingChanges() === false
        "
        (click)="saveChanges()">
        {{ COMMON_STRINGS.saveChanges }}
      </button>
    </div>
  }

  <div class="content">
    <form
      #productSettingsForm="ngForm"
      [class.alternative-layout]="isOverlay()">
      <div
        *ngIf="isOverlay() === false"
        data-intercom-target="00ea4c5b-b584-46ec-97ae-242bdbe621ed"
        aria-label="Product details settings ignore">
        <div class="product-settings-section input-data-section">
          <div>
            <h3 class="product-settings-section-subtitle">
              {{ METADATA.ignore }}
            </h3>
            <p class="product-settings-section-description">
              {{ METADATA.ignoreDescription }}
            </p>
          </div>

          <div class="product-settings-section-description-container">
            <app-toggle
              id="ignored"
              name="ignored"
              [ngModel]="ignored()"
              (ngModelChange)="updateIgnored($event)"
              [disabled]="loading() || viewOnly() || assembled()"></app-toggle>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div
        [class.opaque]="ignoredInitially()"
        data-intercom-target="00ea4c5b-b584-46ec-97ae-242bdbe621ed"
        aria-label="Product details settings manual service level">
        <app-settings-section
          [settingsTitle]="METADATA.manualServiceLevel"
          [settingsDescription]="METADATA.manualServiceLevelDescription">
          <div class="input-field-container">
            <div appInputWrapper>
              <span appInputSuffix>%</span>
              <input
                appInput
                variant="filled"
                name="manualServiceLevel"
                type="number"
                min="0"
                step="1"
                max="99"
                [placeholder]="NOT_AVAILABLE"
                [ngModel]="manualServiceLevel()"
                (ngModelChange)="updateManualServiceLevel($event)"
                [disabled]="cantChangeSettings() || loading()" />
            </div>
          </div>
        </app-settings-section>
      </div>

      <div class="divider"></div>

      <div
        [class.opaque]="ignoredInitially()"
        data-intercom-target="0a20f7f5-1f67-4eaf-961c-6452b0642aed"
        aria-label="Product details settings free stock">
        <app-settings-section
          [settingsTitle]="METADATA.freeStockLevel"
          [settingsDescription]="METADATA.freeStockLevelDescription">
          <div class="input-double-field-container">
            <div class="input-field-container">
              <span class="field-name">{{ METADATA.minimumStockLevel }}</span>
              <input
                appInput
                [class]="!inputMinimumStockValid ? 'input-error-min-max' : ''"
                variant="filled"
                name="minimumStockLevel"
                type="number"
                min="0"
                step="1"
                appNumericInput
                [placeholder]="NOT_AVAILABLE"
                [ngModel]="minimumStock()"
                (ngModelChange)="updateMinimumStockLevel($event)"
                [disabled]="cantChangeSettings() || loading() || viewOnly()" />
            </div>
            <div class="input-field-container">
              <span class="field-name">{{ METADATA.maximumStock }}</span>
              <input
                appInput
                [class]="!inputMaximumStockValid ? 'input-error-min-max' : ''"
                variant="filled"
                name="maximumStock"
                type="number"
                [min]="minimumStock() + 1"
                step="1"
                appNumericInput
                [placeholder]="NOT_AVAILABLE"
                [ngModel]="maximumStock()"
                (ngModelChange)="updateMaximumStock($event)"
                [disabled]="
                  productMaximumStockLevelEnabled() === false ||
                  cantChangeSettings() ||
                  loading() ||
                  viewOnly()
                " />
            </div>
          </div>
        </app-settings-section>
      </div>

      <div class="divider"></div>

      <div
        [class.opaque]="ignoredInitially()"
        data-intercom-target="ca308cc6-0cd8-4fdd-b8cc-04a59db2d31d"
        aria-label="Product details settings phase out">
        <app-settings-section
          [settingsTitle]="METADATA.phaseOut"
          [settingsDescription]="METADATA.phaseOutDescription">
          <div class="input-field-container">
            <app-toggle
              name="notBeingBought"
              [ngModel]="phasedOut()"
              (ngModelChange)="updatePhaseOut($event)"
              [disabled]="
                cantChangeSettings() || failed() || loading() || viewOnly()
              "></app-toggle>
          </div>
        </app-settings-section>
      </div>

      <div
        [class.opaque]="ignoredInitially()"
        data-intercom-target="7d428a19-89ef-480c-9846-7007437a472f"
        aria-label="Product details settings phase out date">
        <div class="section input-data-section end-date-section">
          <div class="end-date-checkbox-container">
            <div>
              <app-checkbox
                name="notBeingBoughtEndDate"
                [checked]="phasedOutHasEndDate()"
                (checkedChange)="updatePhaseOutEndDate($event)"
                [disabled]="
                  phasedOut() === false ||
                  cantChangeSettings() ||
                  failed() ||
                  loading() ||
                  viewOnly()
                "></app-checkbox>
            </div>

            <div>
              <h3
                [class]="
                  phasedOut() === false
                    ? 'product-settings-section-subtitle-disabled'
                    : 'product-settings-section-subtitle'
                ">
                {{ METADATA.phaseOutEndDate }}
              </h3>
              <p
                [class]="
                  phasedOut() === false
                    ? 'product-settings-section-description-disabled'
                    : 'product-settings-section-description'
                ">
                {{ METADATA.phaseOutEndDateDescription }}
              </p>
            </div>
          </div>

          <div class="product-settings-section-description-container">
            <div class="input-field-container input-datepicker-container">
              <div
                class="optiply-outline-datepicker"
                data-intercom-target="fb66092d-b055-4d19-b1b5-1fd202411963"
                aria-label="Product details settings phase out select date">
                <input
                  name="resumePurchaseDate"
                  [matDatepicker]="picker1"
                  [min]="minResumePurchaseDate()"
                  (click)="picker1.open()"
                  [placeholder]="COMMON_STRINGS.selectDate"
                  [ngModel]="resumePurchaseDate()"
                  (ngModelChange)="updateResumePurchaseDate($event)"
                  [disabled]="
                    phasedOutHasEndDate() === false ||
                    cantChangeSettings() ||
                    failed() ||
                    loading() ||
                    viewOnly()
                  "
                  [required]="phasedOutHasEndDate()" />

                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                  [disabled]="
                    cantResumePurchase() ||
                    cantChangeSettings() ||
                    failed() ||
                    loading() ||
                    viewOnly()
                  ">
                  <mat-icon
                    matDatepickerToggleIcon
                    svgIcon="calendar"></mat-icon
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-product-container>
