import { EmailTemplates } from 'src/app/shared/models/email-templates/email-templates.model';
import { EmailTemplateLanguage } from 'src/app/shared/models/emails/v1/emails.model';

export class LoadDefaultTemplate {
  static readonly type = '[Email Templates] Load Default Template';

  constructor(public language: string) {}
}

export class SetChangesValidity {
  static readonly type = '[Email Templates] Set Changes Validity';

  constructor(public validChanges: boolean) {}
}

export class SetDefaultTemplate {
  static readonly type = '[Email Templates] Set Default Template';
}

export class UpdateTemplateLanguage {
  static readonly type = '[Email Templates] Update Template Language';

  constructor(public language: EmailTemplateLanguage) {}
}

export class UpdateTemplateSubject {
  static readonly type = '[Email Templates] Update Template Subject';

  constructor(public subject: string) {}
}

export class UpdateTemplateBody {
  static readonly type = '[Email Templates] Update Template Body';

  constructor(public body: string) {}
}

export class ResetEmailTemplates {
  static readonly type = '[Email Templates] Reset Email Templates';
}

export class SendEmailTemplateChanges {
  static readonly type = '[Email Templates] Send Email Templates Changes';

  constructor(
    public language: string,
    public body: string,
    public subject: string,
    public emailTemplates: EmailTemplates,
    public validChanges?: boolean
  ) {}
}
