import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import {
  AccountSettings,
  AccountSettingsUpdate,
} from 'src/app/shared/models/account/account-settings.model';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { AccountSettingsAdapter } from './adapters/account-settings.adapter';
import { AccountSettings as AccountSettingsV1 } from './model/account-settings.model';

@Injectable({ providedIn: 'root' })
export class AccountSettingsService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _fetch(
    replacements: UrlReplacement[]
  ): Observable<AccountSettingsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'findOne',
      replacements
    );

    return this.http.get<AccountSettingsV1>(endpoint);
  }

  private _update(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'update',
      replacements
    );

    return this.http.patch<AccountSettingsV1>(endpoint, payload);
  }

  fetchSettings(userUUID: string): Observable<AccountSettings> {
    const replacements: UrlReplacement[] = [{ name: 'uuid', value: userUUID }];

    return this._fetch(replacements).pipe(
      switchMap((data: AccountSettingsV1) => {
        return of(new AccountSettingsAdapter().transform(data));
      })
    );
  }

  updateSettings(
    userUUID: string,
    settings: AccountSettingsUpdate
  ): Observable<any> {
    const replacements: UrlReplacement[] = [{ name: 'uuid', value: userUUID }];
    return this._update(settings, replacements);
  }
}
