import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  UserAccount as UserAccountV1,
  WebshopAccounts as WebshopAccountsV1,
} from '../model/account.model';
import {
  WebshopAccounts,
  WebshopUserAccount,
} from 'src/app/shared/models/account/account.model';

export class AccountsWebshopAdapter
  implements DataAdapter<WebshopAccountsV1, WebshopAccounts>
{
  transform(data: WebshopAccountsV1): WebshopAccounts {
    const accounts: WebshopUserAccount[] = data.accounts.map(
      (account: UserAccountV1) => {
        return {
          account: {
            uuid: account.account.uuid,
            name: account.account.name,
            email: account.account.email,
            ccEmails: account.account.ccEmails,
            isIntegration: account.account.isIntegration,
          },
          owner: account.owner,
          isShopAdministrator: account.isShopAdministrator,
        };
      }
    );

    return {
      data: accounts,
      metadata: {
        page: { totalElements: data.metadata.page.totalElements ?? 0 },
      },
    };
  }
}
