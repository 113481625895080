<app-dialog-container>
  <div appDialogHeader class="sources-header">
    <h1 appDialogTitle i18n="add sources dialog title">
      Connect to remote system
    </h1>
    <button
      aria-label="Add source dialog close button"
      (click)="close()"
      class="close-button"
      [disabled]="(isSaving$ | async) || (disabledDialog$ | async)">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>
  <div appDialogBody>
    <app-stepper
      #cdkStepper
      [selectedIndex]="0"
      (selectionChange)="onStepChange($event)">
      <app-step
        label="Select a source"
        i18n-label="add sources select a source"
        [disabled]="(isSaving$ | async) || (disabledDialog$ | async)">
        @if ((loadingSupportedSources$ | async) === true) {
          <app-loading-sources></app-loading-sources>
        }

        <div class="confirmation-container">
          <mat-icon
            class="info-icon"
            svgIcon="check-rounded-filled-xs"></mat-icon>
          <p class="sub-title">
            {{ COMMON_STRINGS.connectedSources }}
          </p>
        </div>

        <div class="search-container">
          <input
            appInput
            variant="filled"
            type="text"
            name="to"
            [placeholder]="COMMON_STRINGS.searchSource"
            [ngModel]="searchBy$ | async"
            (ngModelChange)="filterSources($event)" />
        </div>

        <div class="sources-main-container" appRadioButtonGroup>
          @for (category of sourcesCategories$ | async; track category) {
            <div class="source-category">
              <span class="category-title">{{ category }}</span>
              <div class="sources-container" appRadioButtonGroup>
                @for (
                  supportedSource of supportedSources$ | async;
                  track supportedSource.tap
                ) {
                  @if (category === supportedSource.category) {
                    <button
                      aria-label="Supported source button"
                      [class]="
                        checkCheck(supportedSource.tap)
                          ? ' supported-source-button-selected'
                          : checkSelected(supportedSource.tap)
                            ? 'supported-source-button-connected'
                            : 'supported-source-button'
                      "
                      appButton
                      variant="tertiary"
                      (click)="checkRadio(supportedSource.tap)">
                      @if (checkSelected(supportedSource.tap)) {
                        <mat-icon
                          class="supported-source-selected-icon"
                          svgIcon="check-rounded-filled-xs"></mat-icon>
                      }
                      <div class="supported-source-button-content">
                        <div class="supported-source-button-title-container">
                          <img
                            class="supported-source-button-icon"
                            [src]="supportedSource.icon" />
                          <span class="supported-source-button-title">{{
                            supportedSource.label
                          }}</span>
                        </div>
                      </div>
                    </button>
                  }
                }
              </div>
            </div>
          }
        </div>
      </app-step>
      <app-step
        label="Connect to system"
        i18n-label="add sources connect to system"
        [disabled]="false">
        @if (selectedSupportedSource$ | async; as selectedSupportedSource) {
          <div [formGroup]="form" class="credentials-container">
            @if (!(selectedSupportedSource$ | async).external) {
              <h2 class="title" i18n="add source dialog credentials title">
                Credentials
              </h2>
            }

            @if (credentialsError$ | async; as credentialsError) {
              <div class="error">
                <mat-icon svgIcon="warning-rounded"></mat-icon>
                <p i18n="source validation wrong credencials">
                  An error has occurred when connecting to source
                </p>
              </div>
            }

            @if (
              (selectedSupportedSource$ | async).external ||
              (linkedSources$ | async).length >= numberOfIntegrations()
            ) {
              <div class="external-container">
                <mat-icon
                  class="external-icon"
                  svgIcon="chat-support"></mat-icon>
                <p class="external-message">
                  {{ COMMON_STRINGS.externalSourceInfo }}
                </p>
              </div>
            } @else {
              <app-add-source-credentials
                [selectedSupportedSource]="selectedSupportedSource"
                [form]="form"
                [fieldsWithPassword]="fieldsWithPassword"
                (removedWarning)="removeWarning()"></app-add-source-credentials>
            }
          </div>
        }
      </app-step>
    </app-stepper>
  </div>
  <div appDialogActions>
    <div class="action-buttons-container">
      <button
        aria-label="Add source dialog close button"
        appButton
        variant="tertiary"
        (click)="close()"
        [disabled]="(isSaving$ | async) || (disabledDialog$ | async)"
        i18n="add sources dialog cancel button">
        Cancel
      </button>
      <div class="right-action-buttons-container">
        <button
          aria-label="Add source dialog back button"
          appButton
          variant="secondary"
          [disabled]="
            (disableBackButton$ | async) ||
            (isSaving$ | async) ||
            (disabledDialog$ | async)
          "
          i18n="add sources dialog back button"
          (click)="onBack()">
          Back
        </button>
        @if (
          (((selectedSupportedSource$ | async) &&
            (selectedSupportedSource$ | async).external) ||
            (linkedSources$ | async).length >= numberOfIntegrations()) &&
          cdkStepper.selectedIndex === 1
        ) {
          <button
            aria-label="Add source dialog next button"
            appButton
            variant="primary"
            i18n="add sources dialog next button"
            (click)="openIntercom()">
            {{ LABELS[2] }}
          </button>
        } @else {
          <button
            aria-label="Add source dialog next button"
            appButton
            variant="primary"
            [disabled]="
              (disableNextButton$ | async) ||
              (isSaving$ | async) ||
              (disabledDialog$ | async)
            "
            i18n="add sources dialog next button"
            (click)="onNext()">
            {{ buttonLabel }}
          </button>
        }
      </div>
    </div>
  </div>
</app-dialog-container>
