<app-dialog-container
  ><div class="header">
    <div appDialogHeader>
      <h1 appDialogTitle i18n="add new invite title">Invite users</h1>

      <button
        aria-label="Invite users dialog close button"
        (click)="close()"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>
  </div>

  <div appDialogBody>
    <h3 class="sub-title" i18n="add new invite tip">
      Invite users to access this account. They will receive an invitation email
      with instructions on how to create their credentials and get started with
      "view" only permissions.
    </h3>

    <div class="invite-input-section">
      <div class="cc-address-container">
        <label for="cc-address" i18n="invite email cc address"
          >E-mail address</label
        >

        <input
          appInput
          variant="filled"
          [formControl]="emailControl"
          name="emailControl"
          placeholder="name@email.com" />
      </div>
    </div>

    <div appDialogActions>
      <div class="action-buttons-container">
        <button
          aria-label="Invite users dialog cancel button"
          appButton
          variant="tertiary"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          aria-label="Invite users dialog send invite button"
          appButton
          variant="primary"
          [disabled]="disableSubmitButton$ | async"
          i18n="invite confirm button"
          (click)="sendInvite()">
          Send invite
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
