<div
  class="overlay-wrapper"
  [appTooltip]="SORT_STRINGS.disabled"
  [tooltipDisabled]="!disabled()">
  <button
    aria-label="sort asc button"
    appOverlayItem
    [disabled]="disabled()"
    (click)="sortBy(SORT_OPTIONS.ASC)"
    [class.overlay-v2-item-selected]="order() === SORT_OPTIONS.ASC">
    <mat-icon svgIcon="arrow-up-xs" />

    @if (label()) {
      <span
        >{{ SORT_STRINGS.order.sort }} ({{ label() }}
        {{ SORT_STRINGS.order.first }})</span
      >
    } @else {
      <span>{{ SORT_STRINGS.order.asc }}</span>
    }
  </button>

  <button
    aria-label="sort desc button"
    appOverlayItem
    [disabled]="disabled()"
    (click)="sortBy(SORT_OPTIONS.DESC)"
    [class.overlay-v2-item-selected]="order() === SORT_OPTIONS.DESC">
    <mat-icon svgIcon="arrow-up-xs" class="arrow-reverse" />

    @if (label()) {
      <span
        >{{ SORT_STRINGS.order.sort }} ({{ label() }}
        {{ SORT_STRINGS.order.last }})</span
      >
    } @else {
      <span>{{ SORT_STRINGS.order.desc }}</span>
    }
  </button>
</div>
