import { Directive, HostBinding } from '@angular/core';
import { DROPDOWN_TRIGGER } from '../model/dropdown.model';

@Directive({
  selector: '[appDropdownTrigger], app-dropdown-trigger',
  providers: [
    { provide: DROPDOWN_TRIGGER, useExisting: DropdownTriggerDirective },
  ],
})
export class DropdownTriggerDirective {
  @HostBinding('class')
  class: string = 'dropdown-trigger';
}
