export class LoadAccount {
  static readonly type = '[Account] Load';

  constructor(public userEmail?: string) {}
}

export class UpdateAccount {
  static readonly type = '[Account] Update';
}

export class RemoveEmailFromList {
  static readonly type = '[Account] Remove Emails From List';

  constructor(public email: string) {}
}

export class AddEmailToList {
  static readonly type = '[Account] Add Email To List';

  constructor(public email: string) {}
}

export class UpdateUserName {
  static readonly type = '[Account] Update User Name';

  constructor(public userName: string) {}
}

export class LoadIntercomData {
  static readonly type = '[Account] Load Intercom Data';

  constructor(public userUUID?: string) {}
}

export class UpdateAccountCcEmails {
  static readonly type = '[Account] Update Account Cc Emails';

  constructor(public emails: string[]) {}
}
