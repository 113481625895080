import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { select, Store } from '@ngxs/store';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import { PurchaseConfirmationQueries } from '../../state/purchase-confirmation-dialog-v2.queries';
import { SetUseSupplierSettings } from '../../actions/purchase-confirmation-dialog-v2.actions';
import { AppState } from 'src/app/core/states/app.state';
import { SuppliersV2StateQueries } from 'src/app/features/suppliers-v2/state/suppliers-v2.queries';
import { SaveSupplierEmailV2 } from 'src/app/features/suppliers-v2/actions/suppliers-v2.actions';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { SetOrderEmails } from '../../../email-management/actions/email-management.actions';
import { EmailTemplatesState } from '../../../email-management/state/email-templates.state';

@Component({
  selector: 'app-purchase-supplier-settings',
  templateUrl: './purchase-supplier-settings.component.html',
  styleUrl: './purchase-supplier-settings.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseSupplierSettingsComponent {
  useSupplierSettings = select(PurchaseConfirmationQueries.useSupplierSettings);

  saving = select(AppState.isSaving);

  loadingTemplates = select(EmailTemplatesState.loadingTemplates);

  emailsAddressesList = select(SuppliersV2StateQueries.currentSupplierEmails);

  readonly STRINGS = STRINGS.metadata.purchaseConfirmation;

  readonly COMMON_STRINGS = MESSAGES.common;

  private readonly store = inject(Store);

  setUseSupplierSettings(useSupplierSettings: boolean): void {
    this.store.dispatch(new SetUseSupplierSettings(useSupplierSettings));
  }

  createEmailAddress(emailAddress: string): void {
    this.store.dispatch(new SaveSupplierEmailV2(emailAddress));
  }

  emailsSelected(emails: string[]): void {
    this.store.dispatch(new SetOrderEmails(emails));
  }
}
