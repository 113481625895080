import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import {
  UserAccountInformation,
  WebshopAccounts,
  WebshopOwner,
} from 'src/app/shared/models/account/account.model';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { AccountIntercomAdapter } from './account-intercom.adapter';
import { AccountAdapter } from './adapters/account.adapter';
import {
  UserAccount as UserAccountV1,
  UserAccountUpdate,
  UserIntercomData,
  WebshopOwner as WebshopOwnerV1,
  WebshopAccounts as WebshopAccountsV1,
  Account as AccountV1,
} from './model/account.model';
import {
  AccountRolesPayload,
  AccountRoles as AccountRolesV1,
} from './model/account-roles.model';
import { AccountRoles } from 'src/app/shared/models/account/account-permissions.model';
import { AccountRolesAdapter } from './adapters/account-roles.adapter';
import { AccountWebshopOwnerAdapter } from './adapters/account-webshop-owner.adapter';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { AccountsWebshopAdapter } from './adapters/accounts-webshop.adapter';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _fetchOne(payload: any): Observable<AccountV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'findOneByEmail',
      []
    );

    return this.http.post<AccountV1>(endpoint, payload);
  }

  private _fetchIntercomToken(
    replacements: UrlReplacement[]
  ): Observable<UserIntercomData> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'fetchIntercomData',
      replacements
    );

    return this.http.get<UserIntercomData>(endpoint);
  }

  private _update(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'updateAccount',
      replacements
    );

    return this.http.patch<UserAccountV1>(endpoint, payload);
  }

  private _fetchAccountRoles(
    replacements: UrlReplacement[]
  ): Observable<AccountRolesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'fetchAccountAccess',
      replacements
    );

    return this.http.get<AccountRolesV1>(endpoint);
  }

  private _revokeAccountRoles(
    payload: AccountRolesPayload,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'revokeAccountRoles',
      replacements
    );

    return this.http.patch(endpoint, payload);
  }

  private _assignAccountRoles(
    payload: AccountRolesPayload,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'assignAccountRoles',
      replacements
    );

    return this.http.post(endpoint, payload);
  }

  private _findWebshopOwner(
    replacements: UrlReplacement[]
  ): Observable<WebshopOwnerV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'findWebshopOwner',
      replacements
    );

    return this.http.get<WebshopOwnerV1>(endpoint);
  }

  private _findAllWebshopAccounts(
    payload: ServiceRequestInfoV3,
    replacements: UrlReplacement[]
  ): Observable<WebshopAccountsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'account',
      'v2',
      'findAllWebshopAccounts',
      replacements
    );

    return this.http.post<WebshopAccountsV1>(endpoint, payload);
  }

  fetchAccount(userEmail: string): Observable<UserAccountInformation> {
    const payload = { email: userEmail };

    return this._fetchOne(payload).pipe(
      catchError(() => {
        return of(null);
      }),
      switchMap((data: AccountV1) => {
        return of(new AccountAdapter().transform(data));
      })
    );
  }

  updateAccount(userUUID: string, payload: UserAccountUpdate): Observable<any> {
    const replacements: UrlReplacement[] = [{ name: 'uuid', value: userUUID }];
    return this._update(payload, replacements);
  }

  fetchIntercomToken(userUUID: string): Observable<UserIntercomData> {
    const replacements: UrlReplacement[] = [
      { name: 'accountUuid', value: userUUID },
    ];

    return this._fetchIntercomToken(replacements).pipe(
      catchError(() => {
        return of(null);
      }),
      switchMap((data: UserIntercomData) => {
        of(new AccountIntercomAdapter().transform(data));
        return of(new AccountIntercomAdapter().transform(data));
      })
    );
  }

  fetchAccountRoles(
    webshopUuid: string,
    userUuid: string
  ): Observable<AccountRoles> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: userUuid },
    ];

    return this._fetchAccountRoles(replacements).pipe(
      switchMap((data: AccountRolesV1) =>
        of(new AccountRolesAdapter().transform(data))
      )
    );
  }

  revokeAccountRoles(
    webshopUuid: string,
    userUuid: string,
    payload: AccountRolesPayload
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: userUuid },
    ];

    return this._revokeAccountRoles(payload, replacements);
  }

  assignAccountRoles(
    webshopUuid: string,
    userUuid: string,
    payload: AccountRolesPayload
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: userUuid },
    ];

    return this._assignAccountRoles(payload, replacements);
  }

  findWebshopOwner(webshopUuid: string): Observable<WebshopOwner> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findWebshopOwner(replacements).pipe(
      switchMap((data: WebshopOwnerV1) =>
        of(new AccountWebshopOwnerAdapter().transform(data))
      )
    );
  }

  findAllWebshopAccounts(
    webshopUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<WebshopAccounts> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findAllWebshopAccounts(payload, replacements).pipe(
      switchMap((data: WebshopAccountsV1) =>
        of(new AccountsWebshopAdapter().transform(data))
      )
    );
  }
}
