import { CdkMenuItem } from '@angular/cdk/menu';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-menu-item, [appMenuItem]',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MenuItemComponent extends CdkMenuItem implements OnInit {
  constructor(private elementRef: ElementRef<HTMLButtonElement>) {
    super();
  }

  ngOnInit(): void {
    this._setHostButtonStyles();
  }

  private _setHostButtonStyles(): void {
    this.elementRef.nativeElement.classList.add('menu-item-container');
  }
}
