import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { MultiSupplierUpdateProperties } from 'src/app/shared/models/multi-supplier-product-editor/multi-supplier-editor-update.model';
import { EditedSupplierProduct } from 'src/app/shared/models/multi-supplier-product-editor/multi-supplier-editor.model';
import { EditedLinkedSupplierProduct } from 'src/app/shared/models/supplier-product/v2/editor/editor.model';

export interface BuildMultiSupplierFromResult {
  products: MultiSupplierUpdateProperties[];
}

@Injectable({
  providedIn: 'root',
})
export class MultiSupplierBuilderService {
  buildMultiSupplierFromResult(
    supplierProducts: EditedSupplierProduct[] | EditedLinkedSupplierProduct[]
  ): BuildMultiSupplierFromResult {
    const editedSupplierProducts: MultiSupplierUpdateProperties[] =
      supplierProducts.map(product => {
        return {
          name: product.name,
          sku: product.sku,
          eanCode: product.eanCode,
          weight: product.weight,
          volume: product.volume,
          price: product.price,
          deliveryTime: product.deliveryTime,
          minimumPurchaseQuantity: product.minimumPurchaseQuantity,
          purchaseInQuantitiesOf: product.purchaseInQuantitiesOf,
          supplierId: product.supplierId,
          status: product.status,
          availability: product.availability,
          availabilityDate: product.availabilityDate
            ? DateTime.fromJSDate(new Date(product.availabilityDate)).toFormat(
                'yyyy-MM-dd'
              )
            : null,
          webshopProductId: product.webshopProductId,
          preferred: product.preferred,
          currentStockOnHand: product.currentStockOnHand,
        };
      });

    return { products: editedSupplierProducts };
  }
}
