import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableSort,
  buildGroupsMap,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { COLUMNS_GROUPS } from 'src/app/shared/models/datatable/v2/columns-groups.model';

export const defaultSort: DatatableSort = {
  [STRINGS.columns.products.CATEGORY.key]: {
    field: STRINGS.columns.products.CATEGORY.filterKey,
    order: SortOrder.ASC,
  },
};

export const COLUMNS_ORDER: ColumnOrder = {
  [STRINGS.columns.products.SELECTION.key]: 1,
  [STRINGS.columns.products.CATEGORY.key]: 2,
  [STRINGS.columns.products.NAME.key]: 3,
  [STRINGS.columns.products.ICONS.key]: 4,
  [STRINGS.columns.products.SKU.key]: 5,
  [STRINGS.columns.products.FREE_STOCK.key]: 6,
};

export const defaultColumnsV2: DatatableColumnV2Groups<DatatableColumnV2> = {
  [COLUMNS_GROUPS.FIXED_START.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_START.key,
    groupName: COLUMNS_GROUPS.FIXED_START.name,
    columns: {
      [STRINGS.columns.products.SELECTION.key]: {
        name: STRINGS.columns.products.SELECTION.key,
        displayName: STRINGS.columns.products.SELECTION.name,
        checked: true,
        type: null,
        selectable: false,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.PRODUCT.key]: {
    groupKey: COLUMNS_GROUPS.PRODUCT.key,
    groupName: COLUMNS_GROUPS.PRODUCT.name,
    columns: {
      [STRINGS.columns.products.CATEGORY.key]: {
        name: STRINGS.columns.products.CATEGORY.key,
        displayName: STRINGS.columns.products.CATEGORY.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.NAME.key]: {
        name: STRINGS.columns.products.NAME.key,
        displayName: STRINGS.columns.products.NAME.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.ICONS.key]: {
        name: STRINGS.columns.products.ICONS.key,
        displayName: STRINGS.columns.products.ICONS.name,
        checked: true,
        type: null,
        selectable: false,
        disabled: false,
      },
      [STRINGS.columns.products.SKU.key]: {
        name: STRINGS.columns.products.SKU.key,
        displayName: STRINGS.columns.products.SKU.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.products.FREE_STOCK.key]: {
        name: STRINGS.columns.products.FREE_STOCK.key,
        displayName: STRINGS.columns.products.FREE_STOCK.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
    },
  },
};

export const columnsGroupsMap: Map<string, string> =
  buildGroupsMap(defaultColumnsV2);

export const defaultFiltersV2: DatatableColumnV2Groups<Filter> = {
  [COLUMNS_GROUPS.PRODUCT.key]: {
    groupKey: COLUMNS_GROUPS.PRODUCT.key,
    groupName: COLUMNS_GROUPS.PRODUCT.name,
    columns: {
      [STRINGS.columns.products.CATEGORY.key]: {
        name: STRINGS.columns.products.CATEGORY.name,
        key: STRINGS.columns.products.CATEGORY.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: 'A',
            value: 0,
          },
          {
            key: 'B',
            value: 1,
          },
          {
            key: 'C',
            value: 2,
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.NAME.key]: {
        name: STRINGS.columns.products.NAME.name,
        key: STRINGS.columns.products.NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.SKU.key]: {
        name: STRINGS.columns.products.SKU.name,
        key: STRINGS.columns.products.SKU.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
      [STRINGS.columns.products.FREE_STOCK.key]: {
        name: STRINGS.columns.products.FREE_STOCK.name,
        key: STRINGS.columns.products.FREE_STOCK.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: true,
      },
    },
  },
};

export const filtersGroupsMap: Map<string, string> =
  buildGroupsMap(defaultFiltersV2);

export const defaultPagination: DatatablePagination = {
  page: 0,
  size: 50,
  pageSizeOptions: [10, 15, 25, 50, 75, 100, 125, 150, 200],
};
