import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ACCORDION } from '../../model/expansion-panel.model';
import { CdkAccordion } from '@angular/cdk/accordion';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ACCORDION, useExisting: AccordionComponent }],
})
export class AccordionComponent extends CdkAccordion {}
