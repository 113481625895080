import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  ConceptBuyOrder,
  ConceptBuyOrderContainer,
  ConceptBuyOrderOrderMoment,
  ConceptBuyOrders,
  ConceptBuyOrderSupplier,
} from 'src/app/shared/models/buy-orders/v2/concept-buy-orders-v2.model';
import {
  ConceptBuyOrder as ConceptBuyOrderV1,
  ConceptBuyOrderContainer as ConceptBuyOrderContainerV1,
  ConceptBuyOrderOrderMoment as ConceptBuyOrderOrderMomentV1,
  ConceptBuyOrders as ConceptBuyOrdersV1,
  ConceptBuyOrderSupplier as ConceptBuyOrderSupplierV1,
} from '../model/concept-buy-order-v2-model';
import { Store } from '@ngxs/store';
import {
  formatDateToWebshopTimezone,
  formatDateWithCutoffToWebshopTimezone,
} from 'src/app/shared/utils/dates.utils';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { formatDate } from '@angular/common';

export class ConceptBuyOrdersV2Adapter
  implements DataAdapter<ConceptBuyOrdersV1, ConceptBuyOrders>
{
  constructor(private store: Store) {}

  transform(data: ConceptBuyOrdersV1): ConceptBuyOrders {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const conceptOrders: ConceptBuyOrder[] = data.orders.map(
      (conceptOrder: ConceptBuyOrderV1) => {
        return {
          updatedAt: this._buildTimeZoneDate(conceptOrder.updatedAt),
          order: {
            uuid: conceptOrder.order.uuid,
            numberOfProducts: conceptOrder.order.numberOfProducts,
            totalValue: conceptOrder.order.totalValue,
            supplier: this._buildSupplier(conceptOrder.order.supplier),
            orderMoment: this._buildOrderMoment(conceptOrder.order.orderMoment),
            container: this._buildContainers(conceptOrder.order.container),
          },
        };
      }
    );

    return {
      data: conceptOrders,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  private _buildSupplier(
    supplier: ConceptBuyOrderSupplierV1
  ): ConceptBuyOrderSupplier | null {
    if (!supplier) return null;

    let newDate = null;

    if (supplier.cutoff) {
      newDate = this._buildTimeZoneCutoff(supplier.cutoff);
    }

    return {
      uuid: supplier.uuid,
      name: supplier.name,
      leadTimeMeanActual: supplier.leadTimeMeanActual,
      deliveryTime: supplier.deliveryTime,
      effectiveReplenishmentPeriod: supplier.effectiveReplenishmentPeriod,
      containerWeightCapacity: supplier.containerWeightCapacity,
      containerVolumeCapacity: supplier.containerVolumeCapacity,
      minimalOrderValue: supplier.minimalOrderValue,
      exportQuantityAsLots: supplier.exportQuantityAsLots,
      id: supplier.id,
      hasPlanning: supplier.hasPlanning,
      notes: supplier.notes,
      cutoff: newDate,
      ownerEmail: supplier.ownerEmail,
    };
  }

  private _buildOrderMoment(
    orderMoment: ConceptBuyOrderOrderMomentV1
  ): ConceptBuyOrderOrderMoment | null {
    if (!orderMoment) return null;

    return {
      date: orderMoment.date,
      type: orderMoment.type,
      triggeredValue: orderMoment.triggeredValue,
      uuid: orderMoment.uuid,
      id: orderMoment.id,
    };
  }

  private _buildContainers(
    container: ConceptBuyOrderContainerV1
  ): ConceptBuyOrderContainer | null {
    if (!container) return null;

    return {
      numberOfContainers: container.numberOfContainers,
      totalContainerWeightCapacity: container.totalContainerWeightCapacity,
      totalContainerVolumeCapacity: container.totalContainerVolumeCapacity,
      totalWeight: container.totalWeight,
      totalVolume: container.totalVolume,
      relativeTotalWeight: container.relativeTotalWeight,
      relativeTotalVolume: container.relativeTotalVolume,
    };
  }

  private _buildTimeZoneCutoff(cutoff: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );
    const timeZoneOffset = this.store.selectSnapshot(
      WebshopState.timeZoneOffset
    );

    const todayDateWebshopTimeZone: Date =
      formatDateWithCutoffToWebshopTimezone(timeZoneIdentifier, cutoff);

    return formatDate(
      todayDateWebshopTimeZone,
      'HH:mm',
      'en-US',
      timeZoneOffset
    );
  }

  private _buildTimeZoneDate(date: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const timeZoneOffset = this.store.selectSnapshot(
      WebshopState.timeZoneOffset
    );
    const todayDateWebshopTimeZone = formatDateToWebshopTimezone(
      timeZoneIdentifier,
      date
    );

    return formatDate(
      todayDateWebshopTimeZone,
      'yyyy-MM-dd HH:mm:ss',
      'en-US',
      timeZoneOffset
    );
  }
}
