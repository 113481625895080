import { CdkStep } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  booleanAttribute,
  forwardRef,
} from '@angular/core';
import { StepperComponent } from '../../stepper.component';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStep, useExisting: StepComponent }],
})
export class StepComponent extends CdkStep {
  @Input({ transform: booleanAttribute })
  disabled: boolean = true;

  constructor(
    @Inject(forwardRef(() => StepperComponent))
    public _stepper: StepperComponent
  ) {
    super(_stepper);
  }
}
