import { Selector } from '@ngxs/store';
import {
  DEFAULT_EMAILS_TEMPLATE_LANGUAGE,
  RESULTS_SELECTION_LIMIT_AMOUNT,
} from 'src/app/core/constants/global.constants';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import { STRINGS } from '../model/suppliers-v2.strings';
import { SuppliersV2State, SuppliersV2StateModel } from './suppliers-v2.state';
import {
  Supplier,
  SupplierCommunication,
  SupplierCommunications,
} from 'src/app/shared/models/suppliers/v2/supplier-v2.model';
import { SelectedSupplier } from '../model/suppiers-v2.model';
import {
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  buildDisplayedColumns,
  buildSelectableColumnsList,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { SuppliersTodoQueries } from '../../dashboard/components/todo-list-v2/states/suppliers-todo.queries';
import { IssueStatusColors } from '../../dashboard/components/todo-list-v2/model/todo-list-v2.model';
import { COLUMNS_ORDER } from '../model/suppliers-v2-data-table.model';

export class SuppliersV2StateQueries {
  @Selector([SuppliersV2State.suppliers])
  static suppliers(suppliers: Supplier[]): Supplier[] {
    return suppliers;
  }
  @Selector([SuppliersV2State])
  static loading(state: SuppliersV2StateModel): boolean {
    return state.loading;
  }

  @Selector([SuppliersV2State])
  static totalElements(state: SuppliersV2StateModel): number {
    return state.page.totalElements;
  }

  @Selector([
    SuppliersV2State,
    PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.SUPPLIERS),
  ])
  static featuredColumns(
    state: SuppliersV2StateModel,
    viewOnly: boolean
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    const viewOnlyExcludedColumns = [STRINGS.columns.suppliers.ACTIONS.key];

    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        return {
          ...group,
          columns: Object.values(group.columns).filter(
            (column: DatatableColumnV2) =>
              viewOnly ? !viewOnlyExcludedColumns.includes(column.name) : column
          ),
        };
      }
    );
  }

  @Selector([SuppliersV2StateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([SuppliersV2StateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([SuppliersV2StateQueries.displayedColumns])
  static lastDisplayedColumn(displayedColumns: string[]) {
    return (
      displayedColumns.filter(
        (column: string) =>
          ![
            STRINGS.columns.suppliers.SELECTION.key,
            STRINGS.columns.suppliers.ACTIONS.key,
          ].includes(column)
      ).length === 1
    );
  }

  @Selector([SuppliersV2State])
  static page(state: SuppliersV2StateModel): Pageable {
    return state.page;
  }

  @Selector([SuppliersV2State])
  static pageSizeOptions(state: SuppliersV2StateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([SuppliersV2State])
  static currentPage(state: SuppliersV2StateModel): number {
    return state.pagination.page;
  }

  @Selector([SuppliersV2State])
  static pageSize(state: SuppliersV2StateModel): number {
    return state.pagination.size;
  }

  @Selector([SuppliersV2State, WebshopState.webshopCurrencySymbol])
  static filters(
    state: SuppliersV2StateModel,
    currencySymbol: string
  ): ColumnFilterGroup[] {
    return Object.entries(state.filtersGroups).map(([groupKey, group]) => {
      return {
        groupKey,
        groupName: group.groupName,
        filters: Object.entries(group.columns)
          .map(([key, filter]) => {
            if (STRINGS.columns.suppliers[filter.key]?.currency) {
              return {
                columnKey: key,
                ...filter,
                unit: currencySymbol,
              };
            }

            return {
              columnKey: key,
              ...filter,
            };
          })
          .filter((filter: Filter) => !filter.disabled),
      };
    });
  }

  @Selector([SuppliersV2State, WebshopState.webshopCurrencySymbol])
  static activeFilters(
    state: SuppliersV2StateModel,
    currencySymbol: string
  ): ColumnFilter[] {
    const filters = Object.values(state.filtersGroups).map(
      group => group.columns
    );

    return filters
      .map(filter => {
        return (
          Object.entries(filter)
            .map(([columnKey, filter]) => {
              if (STRINGS.columns.suppliers[filter.key]?.currency) {
                return {
                  columnKey,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey,
                ...filter,
              };
            })
            .filter((filter: Filter) => filter.params.value) || []
        );
      })
      .flat();
  }

  @Selector([SuppliersV2State])
  static search(state: SuppliersV2StateModel): string[] {
    return state.search || [];
  }

  @Selector([SuppliersV2State])
  static filterOpened(state: SuppliersV2StateModel): boolean {
    return state.filterOpened;
  }

  @Selector([SuppliersV2State.selection])
  static isRowSelected(selection: TableSelection<SelectedSupplier>) {
    const selectedUuids = new Set(Object.keys(selection));

    return (uuid: string) => selectedUuids.has(uuid);
  }

  @Selector([SuppliersV2State.selection, SuppliersV2StateQueries.totalElements])
  static areAllSelected(
    selection: TableSelection<SelectedSupplier>,
    totalElements: number
  ): boolean {
    return !!totalElements && Object.keys(selection).length === totalElements;
  }

  @Selector([SuppliersV2State.selection, SuppliersV2StateQueries.totalElements])
  static areSomeSelected(
    selection: TableSelection<SelectedSupplier>,
    totalElements: number
  ): boolean {
    return (
      !!totalElements &&
      !!Object.keys(selection).length &&
      Object.keys(selection).length < totalElements
    );
  }

  @Selector([SuppliersV2State.selection])
  static selectedAmount(selection: TableSelection<SelectedSupplier>): number {
    return Object.keys(selection).length;
  }

  @Selector([SuppliersV2StateQueries.selectedAmount])
  static limitSelection(selectedAmount: number): boolean {
    return selectedAmount >= RESULTS_SELECTION_LIMIT_AMOUNT;
  }

  @Selector([SuppliersV2State.selection])
  static ignoredSuppliersAmount(
    selection: TableSelection<SelectedSupplier>
  ): number {
    return Object.values(selection).filter(
      (supplier: SelectedSupplier) => supplier.ignored
    ).length;
  }

  @Selector([SuppliersV2State.selection])
  static unignoredSuppliersAmount(
    selection: TableSelection<SelectedSupplier>
  ): number {
    return Object.values(selection).filter(
      (supplier: SelectedSupplier) => !supplier.ignored
    ).length;
  }

  @Selector([SuppliersV2State.selection])
  static haveSelectedIgnoredSuppliers(
    selection: TableSelection<SelectedSupplier>
  ): boolean {
    return (
      Object.values(selection).filter(
        (supplier: SelectedSupplier) => supplier.ignored
      ).length > 0
    );
  }

  @Selector([SuppliersV2State.selection])
  static haveSelectedUnignoredSuppliers(
    selection: TableSelection<SelectedSupplier>
  ): boolean {
    return (
      Object.values(selection).filter(
        (supplier: SelectedSupplier) => !supplier.ignored
      ).length > 0
    );
  }

  @Selector([SuppliersV2State])
  static currentSupplier(state: SuppliersV2StateModel): Supplier | null {
    return state.currentSupplier ?? null;
  }

  @Selector([SuppliersV2State.selection])
  static selectedSuppliers(
    selection: TableSelection<SelectedSupplier>
  ): string[] {
    const selectionValues = Object.values(selection);

    if (selectionValues.length <= 0) {
      return [];
    }

    return selectionValues.map(
      (selectedSupplier: SelectedSupplier) => selectedSupplier.uuid
    );
  }

  @Selector([SuppliersV2State])
  static currentSupplierEmails(state: SuppliersV2StateModel): string[] {
    return state.currentSupplier?.emails ?? [];
  }

  @Selector([SuppliersV2State])
  static hasSupplierEmails(state: SuppliersV2StateModel): boolean {
    return !!state.currentSupplier?.emails.length;
  }

  @Selector([SuppliersV2State])
  static currentSupplierAgenda(state: SuppliersV2StateModel): boolean {
    return state.currentSupplier?.agendaManagedManually ?? false;
  }

  @Selector([SuppliersV2State])
  static currentSupplierCommunicationMethod(
    state: SuppliersV2StateModel
  ): SupplierCommunication {
    return (
      (state.currentSupplier?.communicationMethod as SupplierCommunication) ??
      null
    );
  }

  @Selector([SuppliersV2State])
  static currentSupplierPreferredEmailLanguage(
    state: SuppliersV2StateModel
  ): string {
    return (
      state.currentSupplier?.preferredEmailLanguage ??
      DEFAULT_EMAILS_TEMPLATE_LANGUAGE
    );
  }

  @Selector([
    SuppliersTodoQueries.hasIssuesDataFiltered(IssueStatusColors.CRITICAL),
    SuppliersTodoQueries.hasIssuesDataFiltered(IssueStatusColors.WARNING),
    SuppliersV2StateQueries.areAllSelected,
    SuppliersV2StateQueries.areSomeSelected,
  ])
  static showVerticalSeparator(
    hasDangerIssuesAmount: boolean,
    hasWarningIssuesAmount: boolean,
    areAllSelected: boolean,
    areSomeSelected: boolean
  ): any {
    return (
      (hasDangerIssuesAmount || hasWarningIssuesAmount) &&
      (areSomeSelected || areAllSelected)
    );
  }
}
