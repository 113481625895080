import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterOptionV2 } from '../../../../model/filter-v2.model';

@Component({
  selector: 'app-filter-restricted-template-v2',
  templateUrl: './filter-restricted-template-v2.component.html',
  styleUrls: ['./filter-restricted-template-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterRestrictedTemplateV2Component {
  @Input()
  selectionModel: SelectionModel<FilterOptionV2>;

  @Input()
  options: FilterOptionV2[];

  @Input({ transform: booleanAttribute })
  isAllSelected: boolean;

  @Input({ transform: booleanAttribute })
  isSomeSelected: boolean;

  @Input()
  disabled: boolean;

  readonly COMMON_STRINGS = MESSAGES.common;

  masterToggle(): void {
    if (this.isAllSelected) {
      this.selectionModel.clear();

      return;
    }

    this.selectionModel.setSelection(...this.options);
  }

  toggleSelection(option: FilterOptionV2): void {
    if (!this.disabled) {
      this.selectionModel.toggle(option);
    }
  }

  get totalOptionsAmount(): number {
    return this.options?.length || 0;
  }
}
