import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoadConfig } from '../actions/load-config.action';
import { ConfigService } from '../api/configuration/v1/config.service';

export interface ConfigStateModel {
  /**
   * Stores the currently loaded configuration.
   */
  config: any;
}

@State<ConfigStateModel>({ name: 'configState', defaults: { config: null } })
@Injectable()
export class ConfigState {
  constructor(private configService: ConfigService) {}

  @Selector()
  static config(state: ConfigStateModel): any {
    return state.config;
  }

  @Action(LoadConfig)
  load({
    getState,
    patchState,
  }: StateContext<ConfigStateModel>): Observable<any> {
    const state: any = getState();

    /**
     * Bypass the api call if the configuration was already loaded.
     */
    if (state.config != null) {
      return of(state.config);
    }

    return this.configService.load().pipe(
      tap((config: any) => {
        patchState({ config });
      })
    );
  }
}
