import {
  Webshop,
  WebshopUpdateAdvancedCategorizationProperties,
  WebshopUpdateProperties,
  WebshopUpdateSettingsProperties,
} from 'src/app/shared/models/webshop/webshop.model';

export class WebshopSelected {
  static readonly type = '[Webshop] Webshop Selected';

  constructor(public webshop: Webshop) {}
}

export class WebshopChanged {
  static readonly type = '[Webshop] Webshop Changed';

  constructor(public webshop: Webshop) {}
}

export class LoadWebshops {
  static readonly type = '[Webshops] Load';
}

export class LoadWebshop {
  static readonly type = '[Webshop] Load';

  constructor(public webshopUuid: string) {}
}

export class SaveWebshopSettings {
  static readonly type = '[Webshop Settings] Save';

  constructor(public settings: WebshopUpdateSettingsProperties) {}
}

export class SaveWebshopAdvancedCategorization {
  static readonly type =
    '[Webshop Settings] Save Webshop Advanced Categorization';

  constructor(
    public settings: Partial<WebshopUpdateAdvancedCategorizationProperties>,
    public updateTimestamp?: number
  ) {}
}

export class SaveWebshopSettingsCurrency {
  static readonly type = '[Webshop Settings] Save Currency';

  constructor(public settings: WebshopUpdateSettingsProperties) {}
}

export class SaveWebshop {
  static readonly type = '[Webshop] Save';

  constructor(public properties: WebshopUpdateProperties) {}
}

export class PreventWebshopChange {
  static readonly type = '[App] Prevent Webshop Change';
}

export class AllowWebshopChange {
  static readonly type = '[App] Allow Webshop Change';
}
