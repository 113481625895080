<button
  aria-label="Filter button"
  class="filter-container"
  [class.filter-active]="removing() === false && !disabled"
  [class.filter-opened]="overlayOpened"
  [class.filter-removing]="removing()"
  [class.filter-disabled]="filter()?.disabled || disabled"
  [disabled]="filter()?.disabled || disabled">
  <span class="filter-wrapper">
    <span class="filter-info">
      <span class="filter-name">
        {{ filter()?.name }}
      </span>
      <span class="filter-values" [appTooltip]="valuesPreview()">{{
        valuesPreview()
      }}</span>
    </span>
  </span>

  <span class="filter-close-divider"></span>

  <span
    class="filter-reset"
    (click)="removed.emit(filter()?.columnKey); $event.stopPropagation()"
    (mouseenter)="removing.set(true)"
    (mouseleave)="removing.set(false)"
    [appTooltip]="COMMON_STRINGS.filter.removeFilter">
    <mat-icon svgIcon="cross-xs" class="filter-reset-icon"></mat-icon>
  </span>
</button>
