import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  startWith,
} from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-column-view-v3-search',
  exportAs: 'columnViewV3Search',
  templateUrl: './column-view-v3-search.component.html',
  styleUrl: './column-view-v3-search.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnViewV3SearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true, read: ElementRef })
  searchInput: ElementRef;

  @Input()
  placeholder: string = MESSAGES.common.filter.search;

  @Output()
  searched: EventEmitter<string> = new EventEmitter<string>();

  searchBy: FormControl<string> = new FormControl<string>('');

  searchedBy$: Observable<string> = this.searchBy.valueChanges.pipe(
    startWith(''),
    debounceTime(100),
    distinctUntilChanged()
  );

  searchedBy: Signal<string> = toSignal(this.searchedBy$);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.searchedBy$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((searchBy: string) => {
        this.searched.emit(searchBy);
      });
  }

  focus() {
    setTimeout(() => {
      this.searchInput?.nativeElement?.focus();
    });
  }

  clearSearch(): void {
    this.searchBy.reset('');
  }
}
