import { DataAdapter } from 'src/app/shared/adapter/adapter';

import {
  PredecessorProduct as PredecessorProductV1,
  PredecessorProducts as PredecessorProductsV1,
} from '../model/products-v3.model';
import {
  PredecessorProduct,
  PredecessorProducts,
} from 'src/app/shared/models/products/v3/products.model';

export class ProductsV3AvailablePredecessorsAdapter
  implements DataAdapter<PredecessorProductsV1, PredecessorProducts>
{
  transform(data: PredecessorProductsV1): PredecessorProducts {
    const products: PredecessorProduct[] = data.products.map(
      (product: PredecessorProductV1) => {
        return {
          uuid: product.uuid,
          name: product.name,
          notBeingBought: product.notBeingBought,
        };
      }
    );

    return {
      data: products,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }
}
