<span [id]="id" [ngClass]="tagClasses" (click)="click()">
  <span class="tag-cell" [class.tag-clickable]="clickable">
    <div class="tag-label" [ngStyle]="{ 'max-width.px': maxWidth }">
      <span class="tag-value">
        <ng-content></ng-content>
      </span>
    </div>
  </span>

  @if (removable) {
    <button
      aria-label="tag remove button"
      (click)="remove()"
      class="tag-remove-btn"
      [disabled]="disabled"
      appTooltip="Remove"
      i18n-appTooltip="tag remove tooltip"
      [tooltipShowDelay]="300">
      <mat-icon class="tag-remove-icon" svgIcon="cross"></mat-icon>
    </button>
  }
</span>
