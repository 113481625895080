import {
  LinkedSource,
  CreateLinkedSourceProperties,
  RetrieveJobsPerTapQueryParameters,
  UpdateLinkedSourcePayload,
  RetrieveExportJobsPerTapQueryParameters,
} from 'src/app/core/api/integrations/model/integrations.model';

export class RetrieveSupportedSources {
  static readonly type = '[Integrations] Retrieve supported sources';

  constructor() {}
}

export class CreateLinkedSource {
  static readonly type = '[Integrations] Create linked source';

  constructor(public properties: CreateLinkedSourceProperties) {}
}

export class RetrieveLinkedSources {
  static readonly type = '[Integrations] Retrieve linked sources';

  constructor() {}
}

export class RetrieveLinkedTargets {
  static readonly type = '[Integrations] Retrieve linked targets';

  constructor() {}
}

export class DeleteLinkedSource {
  static readonly type = '[Integrations] Delete linked source';

  constructor(public tapName: string) {}
}

export class UpdateLinkedSource {
  static readonly type = '[Integrations] Update linked source';

  constructor(public properties: UpdateLinkedSourcePayload) {}
}

export class RetrieveJobs {
  static readonly type = '[Integrations] Retrieve jobs';

  constructor() {}
}

export class RetrieveJobsPerTap {
  static readonly type = '[Integrations] Retrieve jobs per tap';

  constructor(
    public queryParameters: RetrieveJobsPerTapQueryParameters,
    public isOptiplyLinkedSource: boolean
  ) {}
}

export class RetrieveExportJobsPerTap {
  static readonly type = '[Integrations] Retrieve export jobs per tap';

  constructor(
    public queryParameters: RetrieveExportJobsPerTapQueryParameters
  ) {}
}

export class RefreshJobs {
  static readonly type = '[Integrations] Refresh jobs';

  constructor(public linkedSource: LinkedSource) {}
}

export class RefreshExportJobs {
  static readonly type = '[Integrations] Refresh Export jobs';

  constructor(public linkedSource: LinkedSource) {}
}

export class SelectSupportedSource {
  static readonly type = '[Integrations] Select supported source';

  constructor(public tap: string) {}
}

export class ResetState {
  static readonly type = '[Integrations] Reset integrations state';

  constructor() {}
}

export class CloseAddSourceDialog {
  static readonly type = '[Integrations] Close add source dialog';
}

export class CloseConfirmationDialog {
  static readonly type = '[Integrations] Close confirmation dialog';
}

export class OpenAddSourceDialog {
  static readonly type = '[Integrations] Open add source dialog';
}

export class CreateSourceOAuth {
  static readonly type = '[Integrations] Create source OAuth';

  constructor() {}
}

export class FilterSources {
  static readonly type = '[Integrations] Filter Sources';

  constructor(public filter: string | null) {}
}

export class InitializeSourceList {
  static readonly type = '[Integrations] Initialize Source List';
}
