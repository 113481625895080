import { Directive, HostBinding } from '@angular/core';
import { EXPANSION_PANEL_TITLE } from '../model/expansion-panel.model';

@Directive({
  selector: '[appExpansionPanelJobsTitle], app-expansion-panel-jobs-title',
  providers: [
    {
      provide: EXPANSION_PANEL_TITLE,
      useExisting: ExpansionPanelJobsTitleDirective,
    },
  ],
})
export class ExpansionPanelJobsTitleDirective {
  @HostBinding('class')
  class: string = 'expansion-panel-header-jobs-title';
}
