@if (
  linkedSource?.tap === 'zohobooks' || linkedSource?.tap === 'zoho-inventory'
) {
  <div class="remove-source-container">
    <div>
      <h3
        class="section-subtitle"
        i18n="settings integrations sources disconnect source">
        Disconnect source
      </h3>
      <p
        class="section-description"
        i18n="settings integrations sources disconnect source description">
        Disconnect your remote account from Optiply
      </p>
    </div>

    <div class="section-description-container">
      <button
        aria-label="Settings souces disconnect button"
        i18n="settings integrations sources disconnect"
        appButton
        variant="danger"
        (click)="deleteLinkedSource(linkedSource?.tap)">
        Disconnect
      </button>
    </div>
  </div>
}
