import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import {
  DeliveryDetails,
  DeliveryOrderlineMultipleUpdateProperties,
  DeliveryOrderlines,
  SaveDeliveryProperties,
} from 'src/app/shared/models/delivery/v2/deliveries-editor-v2.model';
import {
  DeliveriesBuyOrders,
  DeliveriesOverviewSummaries,
} from 'src/app/shared/models/delivery/v2/deliveries-v2.model';
import { DeliveryOrderDetailsV2Adapter } from './adapters/deliveries-details-v2.adapter';
import { DeliveryOrderslinesV2Adapter } from './adapters/deliveries-editor-v2.adapter';
import { DeliveriesOverviewsV2Adapter } from './adapters/deliveries-overviews-v2.adapter';
import { DeliveriesBuyOrdersV2Adapter } from './adapters/deliveries-v2.adapter';
import {
  DeliveryDetails as DeliveryDetailsV1,
  DeliveryOrderlines as DeliveryOrderlinesV1,
} from './model/deliveries-editor-v2.model';
import {
  DeliveriesBuyOrders as DeliveriesBuyOrdersV1,
  DeliveriesOverviewSummaries as DeliveriesOverviewSummariesV1,
  DeliveriesOverviews as DeliveriesOverviewsV1,
} from './model/deliveries-v2.model';
import { Issues as IssuesV1 } from '../../issues/model/issues.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { UpdateDelivery } from 'src/app/shared/models/delivery/deliveries.model';
import { DeliveriesOverviewSummaryAdapter } from './adapters/deliveries-v2-overview-summary.adapter';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class DeliveriesV2Service {
  constructor(
    private store: Store,
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _fetchBuyOrders(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<DeliveriesBuyOrdersV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'openBuyOrders',
      replacements
    );

    return this.http.post<DeliveriesBuyOrdersV1>(endpoint, payload);
  }

  private _fetchUndeliveredBuyOrderLines(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<DeliveryOrderlinesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'findUndeliveredBuyOrderLines',
      replacements
    );

    return this.http.post<DeliveryOrderlinesV1>(endpoint, payload);
  }

  private _fetchDeliveredBuyOrderLines(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<DeliveryOrderlinesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'findDeliveredBuyOrderLines',
      replacements
    );

    return this.http.post<DeliveryOrderlinesV1>(endpoint, payload);
  }

  private _fetchOrderDeliveryDetails(
    replacements: UrlReplacement[]
  ): Observable<DeliveryDetailsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'getOrderDeliveryDetails',
      replacements
    );

    return this.http.get<DeliveryDetailsV1>(endpoint);
  }

  private _fetchProductDeliveryOverviews(
    replacements: UrlReplacement[],
    payload: ServiceRequestInfoV3
  ): Observable<DeliveriesOverviewsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'findProductDeliveryOverviews',
      replacements
    );

    return this.http.post<DeliveriesOverviewsV1>(endpoint, payload);
  }

  private _saveDeliveries(
    replacements: UrlReplacement[],
    payload: SaveDeliveryProperties
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'saveDeliveries',
      replacements
    );

    return this.http.post(endpoint, payload);
  }

  private _saveAndCompleteOrder(
    replacements: UrlReplacement[],
    payload: SaveDeliveryProperties
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'saveAndCompleteOrder',
      replacements
    );

    return this.http.post(endpoint, payload);
  }

  private _findDeliveriesIssues(
    replacements: UrlReplacement[]
  ): Observable<IssuesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'findAllIssues',
      replacements
    );
    return this.http.get<IssuesV1>(endpoint);
  }

  private _update(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderGRPC',
      'v2',
      'update',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _updateMultiple(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderGRPC',
      'v2',
      'multipleBuyOrderLinesUpdate',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _findDeliveriesOverviewSummary(
    replacements: UrlReplacement[]
  ): Observable<DeliveriesOverviewSummariesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'deliveriesGRPC',
      'v2',
      'findDeliveriesOverviewSummary',
      replacements
    );

    return this.http.get<DeliveriesOverviewSummariesV1>(endpoint);
  }

  fetchBuyOrders(
    webshopUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<DeliveriesBuyOrders> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._fetchBuyOrders(payload, replacements).pipe(
      switchMap(data =>
        of(new DeliveriesBuyOrdersV2Adapter(this.store).transform(data))
      )
    );
  }

  fetchUndeliveredBuyOrderLines(
    webshopUuid: string,
    buyOrderUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<DeliveryOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._fetchUndeliveredBuyOrderLines(payload, replacements).pipe(
      switchMap(data => of(new DeliveryOrderslinesV2Adapter().transform(data)))
    );
  }

  fetchDeliveredBuyOrderLines(
    webshopUuid: string,
    buyOrderUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<DeliveryOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._fetchDeliveredBuyOrderLines(payload, replacements).pipe(
      switchMap(data => of(new DeliveryOrderslinesV2Adapter().transform(data)))
    );
  }

  fetchOrderDeliveryDetails(
    webshopUuid: string,
    buyOrderUuid: string
  ): Observable<DeliveryDetails> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._fetchOrderDeliveryDetails(replacements).pipe(
      switchMap(data =>
        of(new DeliveryOrderDetailsV2Adapter(this.store).transform(data))
      )
    );
  }

  fetchProductDeliveryOverviews(
    webshopUuid: string,
    webshopProductUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<DeliveriesBuyOrders> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'webshopProductUuid', value: webshopProductUuid },
    ];

    return this._fetchProductDeliveryOverviews(replacements, payload).pipe(
      switchMap(data =>
        of(new DeliveriesOverviewsV2Adapter(this.store).transform(data))
      )
    );
  }

  saveDeliveries(
    webshopUuid: string,
    buyOrderUuid: string,
    payload: SaveDeliveryProperties
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._saveDeliveries(replacements, payload);
  }

  saveAndCompleteOrder(
    webshopUuid: string,
    buyOrderUuid: string,
    payload?: SaveDeliveryProperties
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._saveAndCompleteOrder(replacements, payload);
  }

  updateExpectedDeliveryDate(
    webshopUUID: string,
    orderUUID: string,
    properties: UpdateDelivery
  ): Observable<void> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
      { name: 'buyOrderUuid', value: orderUUID },
    ];

    return this._update(properties, replacements);
  }

  updateExpectedDeliveryDateMultiple(
    webshopUuid: string,
    buyOrderUuid: string,
    properties: DeliveryOrderlineMultipleUpdateProperties
  ): Observable<void> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._updateMultiple(properties, replacements);
  }

  findDeliveriesOverviewSummary(
    webshopUuid: string
  ): Observable<DeliveriesOverviewSummaries> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findDeliveriesOverviewSummary(replacements).pipe(
      switchMap((data: DeliveriesOverviewSummariesV1) =>
        of(new DeliveriesOverviewSummaryAdapter().transform(data))
      )
    );
  }
}
