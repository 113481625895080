import { Pipe, type PipeTransform } from '@angular/core';
import {
  EmailTemplateContext,
  emailTemplateContextsStrings,
  EmailTemplatesContext,
} from 'src/app/shared/models/emails/v1/emails.model';

@Pipe({
  name: 'appTemplatesContextBuilder',
  standalone: true,
})
export class TemplatesContextBuilderPipe implements PipeTransform {
  transform(value: string[]): EmailTemplateContext[] {
    return value.map(context => ({
      key: emailTemplateContextsStrings[context],
      value: context,
    }));
  }
}
