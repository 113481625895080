import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { LocaleService } from 'src/app/core/services/locale.service';
import { CustomDecimalPipe } from 'src/app/shared/pipes/custom-decimal.pipe';

@Pipe({
  name: 'stockTime',
})
export class StockTimePipe implements PipeTransform {
  constructor(
    private localeService: LocaleService,
    private store: Store
  ) {}

  transform(stockTime: number): string | number {
    return stockTime > 730
      ? $localize`2+ years`
      : new CustomDecimalPipe(this.localeService, this.store).transform(
          stockTime,
          '1.0-0'
        );
  }
}
