import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector:
    'app-basic-context-nav-bar-v2-actions, [basic-context-nav-bar-v2-actions], [basicContextNavbarV2Actions]',
  templateUrl: './basic-context-nav-bar-v2-actions.component.html',
  styleUrls: ['./basic-context-nav-bar-v2-actions.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicContextNavBarV2ActionsComponent {}
