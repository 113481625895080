import { DateTime } from 'luxon';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  ProposedBuyOrder,
  ProposedBuyOrderContainer,
  ProposedBuyOrderOrderMoment,
  ProposedBuyOrders,
  ProposedBuyOrderSupplier,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';
import {
  ProposedBuyOrder as ProposedBuyOrderV1,
  ProposedBuyOrders as ProposedBuyOrdersV1,
  ProposedBuyOrderSupplier as ProposedBuyOrderSupplierV1,
  ProposedBuyOrderOrderMoment as ProposedBuyOrderOrderMomentV1,
  ProposedBuyOrderContainer as ProposedBuyOrderContainerV1,
} from '../model/proposed-buy-order-v2-model';
import {
  DynamicTags,
  TagVariantColor,
} from 'src/app/shared/components/design-system/tags/model/tag.model';
import { Store } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { formatDateWithCutoffToWebshopTimezone } from 'src/app/shared/utils/dates.utils';
import { ProposedOrdersStatus } from 'src/app/features/purchase-v3/components/proposed-orders/model/proposed-orders.model';
import { formatDate } from '@angular/common';

export class ProposedBuyOrdersV2Adapter
  implements DataAdapter<ProposedBuyOrdersV1, ProposedBuyOrders>
{
  constructor(private store: Store) {}

  transform(data: ProposedBuyOrdersV1): ProposedBuyOrders {
    const buyorders: ProposedBuyOrder[] = data.orders.map(
      (buyorder: ProposedBuyOrderV1) => {
        return {
          order: {
            uuid: buyorder.order.uuid,
            numberOfProducts: buyorder.order.numberOfProducts,
            totalValue: buyorder.order.totalValue,
            supplier: this._buildSupplier(buyorder.order.supplier),
            orderMoment: this._buildOrderMoment(
              buyorder.order.orderMoment,
              buyorder.order.supplier?.cutoff
            ),
            container: this._buildContainers(buyorder.order.container),
            planningTrigger: buyorder.order.planningTrigger,
            status: buyorder.order.status,
          },
          orderOverdue: buyorder.order.status === ProposedOrdersStatus.OVERDUE,
          orderToday: buyorder.order.status === ProposedOrdersStatus.TODAY,
          orderFuture: buyorder.order.status === ProposedOrdersStatus.FUTURE,
          extras: {
            status: this._buildStatus(
              buyorder.order.status,
              buyorder.order.orderMoment?.date
            ),
          },
        };
      }
    );

    return {
      data: buyorders,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  private _buildSupplier(
    supplier: ProposedBuyOrderSupplierV1
  ): ProposedBuyOrderSupplier | null {
    if (!supplier) return null;

    let newDate = null;

    if (supplier.cutoff) {
      newDate = this._buildTimeZoneCutoff(supplier.cutoff);
    }

    return {
      uuid: supplier.uuid,
      name: supplier.name,
      leadTimeMeanActual: supplier.leadTimeMeanActual,
      deliveryTime: supplier.deliveryTime,
      effectiveReplenishmentPeriod: supplier.effectiveReplenishmentPeriod,
      containerWeightCapacity: supplier.containerWeightCapacity,
      containerVolumeCapacity: supplier.containerVolumeCapacity,
      minimalOrderValue: supplier.minimalOrderValue,
      exportQuantityAsLots: supplier.exportQuantityAsLots,
      id: supplier.id,
      notes: supplier.notes,
      cutoff: newDate,
      ownerEmail: supplier.ownerEmail,
    };
  }

  private _buildOrderMoment(
    orderMoment: ProposedBuyOrderOrderMomentV1,
    cutoff: string
  ): ProposedBuyOrderOrderMoment | null {
    if (!orderMoment) return null;

    let newDate = orderMoment.date;

    if (orderMoment.date && cutoff) {
      newDate = this._buildTimeZoneDate(cutoff, orderMoment.date);
    }

    return {
      date: newDate,
      type: orderMoment.type,
      triggeredValue: orderMoment.triggeredValue,
      uuid: orderMoment.uuid,
      id: orderMoment.id,
    };
  }

  private _buildContainers(
    container: ProposedBuyOrderContainerV1
  ): ProposedBuyOrderContainer | null {
    if (!container) return null;

    return {
      numberOfContainers: container.numberOfContainers,
      totalContainerWeightCapacity: container.totalContainerWeightCapacity,
      totalContainerVolumeCapacity: container.totalContainerVolumeCapacity,
      totalWeight: container.totalWeight,
      totalVolume: container.totalVolume,
      relativeTotalWeight: container.relativeTotalWeight,
      relativeTotalVolume: container.relativeTotalVolume,
    };
  }

  private _buildStatus(status: string, date: string): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (!this._validPlannedDate(date)) return extras;

    if (status === ProposedOrdersStatus.OVERDUE) {
      const overdueDays = this._getDiffDays(date);

      extras.textTags.push({
        text: `${overdueDays.toFixed(0)} ${
          STRINGS.metadata.purchase.orderStatus.daysOverdue
        }`,
        color: TagVariantColor.RED,
        variant: 'subtle',
      });
    }

    if (status === ProposedOrdersStatus.TODAY) {
      extras.textTags.push({
        text: STRINGS.metadata.purchase.orderStatus.orderToday,
        color: TagVariantColor.GREEN,
        variant: 'subtle',
      });
    }

    if (status === ProposedOrdersStatus.FUTURE) {
      extras.textTags.push({
        text: STRINGS.metadata.purchase.orderStatus.futureOrder,
        color: TagVariantColor.DEFAULT,
        variant: 'subtle',
      });
    }

    return extras;
  }

  /**
   * Gets todays date in UTC
   * @returns today date utc
   */
  private _getTodayDateUTC(): DateTime {
    return DateTime.fromJSDate(new Date()).toUTC();
  }

  /**
   * Gets diff days from todays date and a planned date
   * @param plannedDate  string
   * @returns diff days
   */
  private _getDiffDays(plannedDate: string): number {
    const { days } = this._getTodayDateUTC()
      .diff(DateTime.fromFormat(plannedDate, 'yyyy-MM-dd'), 'days')
      .toObject();

    return Math.floor(days);
  }

  private _validPlannedDate(plannedDate: string): boolean {
    return plannedDate !== null && plannedDate !== undefined;
  }

  private _buildTimeZoneCutoff(cutoff: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );
    const timeZoneOffset = this.store.selectSnapshot(
      WebshopState.timeZoneOffset
    );

    const todayDateWebshopTimeZone: Date =
      formatDateWithCutoffToWebshopTimezone(timeZoneIdentifier, cutoff);

    return formatDate(
      todayDateWebshopTimeZone,
      'HH:mm',
      'en-US',
      timeZoneOffset
    );
  }

  private _buildTimeZoneDate(cutoff: string, date: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const timeZoneOffset = this.store.selectSnapshot(
      WebshopState.timeZoneOffset
    );
    const todayDateWebshopTimeZone = formatDateWithCutoffToWebshopTimezone(
      timeZoneIdentifier,
      cutoff,
      date
    );

    return formatDate(
      todayDateWebshopTimeZone,
      'yyyy-MM-dd',
      'en-US',
      timeZoneOffset
    );
  }
}
