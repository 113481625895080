import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SupportedSource } from '../../../core/api/integrations/model/integrations.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-source-credentials',
  templateUrl: './add-source-credentials.component.html',
  styleUrl: './add-source-credentials.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSourceCredentialsComponent {
  @Input() selectedSupportedSource: SupportedSource;

  @Input() form: FormGroup;

  @Input() fieldsWithPassword: Map<string, string>;

  @Output() removedWarning: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.form = new FormGroup({});
  }

  /**
   * toggles the visibility of a password input field
   * @param key the key of the input field
   */
  public togglePasswordVisibility(key: string): void {
    if (this.fieldsWithPassword.get(key) === 'password') {
      this.fieldsWithPassword.set(key, 'text');
    } else {
      this.fieldsWithPassword.set(key, 'password');
    }
  }

  /**
   * removes the credentials error
   */
  removeWarning(): void {
    this.removedWarning.emit();
  }
}
