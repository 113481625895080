import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './components/tab/tab.component';
import { TabTemplateComponent } from './components/tab-template/tab-template.component';
import { TabLazyContentDirective } from './directives/tab-lazy-content.directive';
import { RouterModule } from '@angular/router';
import { TabsGroupComponent } from './components/tabs-group/tabs-group.component';
import { TabsGroupTemplateComponent } from './components/tabs-group-template/tabs-group-template.component';
import { TabDropdownTemplateComponent } from './components/tab-dropdown-template/tab-dropdown-template.component';
import { MatIcon } from '@angular/material/icon';
import { TabDropdownComponent } from './components/tab-dropdown/tab-dropdown.component';

@NgModule({
  declarations: [
    TabComponent,
    TabTemplateComponent,
    TabLazyContentDirective,
    TabsGroupComponent,
    TabsGroupTemplateComponent,
    TabDropdownTemplateComponent,
    TabDropdownComponent,
  ],
  imports: [CommonModule, RouterModule, MatIcon],
  exports: [
    TabComponent,
    TabTemplateComponent,
    TabLazyContentDirective,
    TabsGroupComponent,
    TabsGroupTemplateComponent,
    TabDropdownTemplateComponent,
    TabDropdownComponent,
  ],
})
export class TabsModule {}
