import { DynamicTags } from 'src/app/shared/components/design-system/tags/model/tag.model';
import { MetadataV2 } from '../../datatable/v2/pageable-v2.model';
import { IssueContext } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.model';

export enum DeliveryStatuses {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface DeliveryBuyOrderMetadata {
  userSendEmailOptionSelected: boolean;
  lastSendEmailRequestTriggeredAt: Date;
}

export interface DeliveryBuyOrder {
  uuid: string;
  id: string;
  date: string;
  totalValue: number;
  closedDate: string;
  buyerEmail: string;
  metadata: DeliveryBuyOrderMetadata | null;
}

export interface DeliverySupplier {
  uuid: string;
  name: string;
  minimalOrderValue: number;
  effectiveReplenishmentPeriod: number;
  leadTimeMeanActual: number;
  deliveryTime: number;
}

export interface DeliveryOrderMoment {
  uuid: string;
  id: string;
}

export interface Delivery {
  uuid: string;
  buyOrder: DeliveryBuyOrder;
  supplier: DeliverySupplier;
  orderMoment: DeliveryOrderMoment;
  totalOrdered: number;
  totalDelivered: number;
  expectedDeliveryDate: string;
  notes?: string;
  closedDate?: string;
  extras?: {
    tags: DynamicTags;
  };
}

export interface DeliveriesBuyOrders {
  data: Delivery[];
  metadata: MetadataV2;
}

export interface DeliveriesOverviewSummary extends IssueContext {
  status: string;
  numberOfOrders: string;
  totalOrdered: string;
  totalDelivered: string;
  totalValue: number;
}

export interface DeliveriesOverviewSummaries {
  deliveriesOverviewSummaries: DeliveriesOverviewSummary[];
}
