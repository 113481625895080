import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { WebshopOwner as WebshopOwnerV1 } from '../model/account.model';
import { WebshopOwner } from 'src/app/shared/models/account/account.model';

export class AccountWebshopOwnerAdapter
  implements DataAdapter<WebshopOwnerV1, WebshopOwner>
{
  transform(data: WebshopOwnerV1): WebshopOwner {
    return {
      accountUuid: data.accountUuid,
      roles: data.roles,
    };
  }
}
