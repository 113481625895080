/**
 * Trimes the file name that comes in the response headers
 * @param contentDisposition
 * @returns
 */
export function getFileName(contentDisposition: string): string {
  return contentDisposition
    .split(';')[1]
    .trim()
    .split('=')[1]
    .replace(/"/g, '');
}
