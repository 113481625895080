import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import {
  InputPrefixDirective,
  InputSuffixDirective,
} from '../../directives/input.directive';
import { InputComponent } from '../../input.component';

@Component({
  selector: 'app-input-wrapper, [appInputWrapper]',
  templateUrl: './input-wrapper.component.html',
  styleUrl: './input-wrapper.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputWrapperComponent {
  @ContentChild(InputComponent, { read: ElementRef, static: true })
  input: ElementRef<HTMLInputElement>;

  @ContentChild(InputPrefixDirective)
  prefix: InputPrefixDirective;

  @ContentChild(InputSuffixDirective)
  suffix: InputSuffixDirective;

  @HostListener('click')
  onClick(): void {
    this.input.nativeElement.focus();
  }

  get classes(): string {
    return `input-wrapper ${this.prefix ? 'input-wrapper-prefix' : ''} ${
      this.suffix ? 'input-wrapper-suffix' : ''
    }`;
  }
}
