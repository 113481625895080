import { LIGHT_THEME_PALLETE } from 'src/app/core/constants/themes-pallete.constants';
import { ChartLoadingOptions } from 'src/app/shared/components/chart/model/chart.model';
import {
  ProductInformationSalesGraphGroups,
  ProductInformationSalesGraphHistoryLengths,
} from 'src/app/shared/models/account/account-settings.model';
import {
  SalesGraph,
  SalesOptions,
} from 'src/app/shared/models/sales/sales-v2.model';

export interface SalesV2 {
  loading: boolean;
  totalSales: number;
  graphOptions: SalesOptions;
  loadingOptions: ChartLoadingOptions;
  graph: SalesGraph;
}

export const defaultSalesV2: SalesV2 = {
  loading: true,
  totalSales: 0,
  graphOptions: {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {},
      },
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '10%',
      top: '5%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'time',
        boundaryGap: [0, 0],
        min: undefined,
      },
    ],
    yAxis: [{ type: 'value' }],
    series: [],
    legend: [],
  },
  loadingOptions: {
    text: '',
    color: LIGHT_THEME_PALLETE.blue_3, // default
    textColor: '#000',
    maskColor: 'rgba(255, 255, 255, 0)',
    zlevel: 0,

    fontSize: 12,
    showSpinner: true,
    spinnerRadius: 12,
    lineWidth: 2,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'sans-serif',
  },
  graph: {
    colorsList: [
      {
        hex: '#646a98',
        rgb: '100, 106, 152',
      },
      {
        hex: '#eb7397',
        rgb: '235, 115, 151',
      },
      {
        hex: '#ffdb70',
        rgb: '255, 219, 112',
      },
      {
        hex: '#73bf9c',
        rgb: '115, 191, 156',
      },
      {
        hex: '#5992d8',
        rgb: '89, 146, 216',
      },
      {
        hex: '#ff9c3f',
        rgb: '255, 156, 63',
      },
      {
        hex: '#9672c1',
        rgb: '150, 114, 193',
      },
      {
        hex: '#5cd1b2',
        rgb: '92, 209, 178',
      },
      {
        hex: '#58c9dd',
        rgb: '88, 201, 221',
      },
      {
        hex: '#e56262',
        rgb: '229, 98, 98',
      },
      {
        hex: '#b55984',
        rgb: '181, 89, 132',
      },
      {
        hex: '#33abcc',
        rgb: '51, 171, 204',
      },
      {
        hex: '#f8c169',
        rgb: '248, 193, 105',
      },
      {
        hex: '#43d3ad',
        rgb: '67, 211, 173',
      },
      {
        hex: '#f5996e',
        rgb: '245, 153, 110',
      },
      {
        hex: '#de64bd',
        rgb: '222, 100, 189',
      },
      {
        hex: '#eb95af',
        rgb: '235, 149, 175',
      },
      {
        hex: '#ee7366',
        rgb: '238, 116, 102',
      },
    ],
    filter: ProductInformationSalesGraphHistoryLengths.LAST_MONTH,
    groupBy: ProductInformationSalesGraphGroups.DAILY,
  },
};

export interface FetchSalesProperties {
  webshopUuid: string;
  webshopProductUuid: string;
  groupBy: string;
  filter: string;
}

export interface FetchSalesOverviewProperties {
  webshopUuid: string;
  webshopProductUuid: string;
  filter: string;
}
