import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SnoozeProduct } from '../../proposed-orderlines/actions/proposed-orderlines.actions';
import {
  OrderlineOrderType,
  OrderlineRelatedTable,
  OrderlineRelatedTables,
  OrderlineStaticInfo,
} from '../components/orderline-info-v3/model/purchase-overlay-v3.model';
import {
  OrderlineAdd,
  OrderlineInfoQuantityChanged,
  OrderlineRemove,
} from '../components/orderline-info-v3/actions/orderline-info-v3.actions';
import {
  CloseOverlay,
  ClosePopout,
  OpenOverlay,
  OpenPopout,
  ResetOverlay,
} from '../actions/purchase-overlay-v2.actions';

export interface PurchaseOverlayStateModel {
  opened: boolean;
  isPopout: boolean;
  relatedTable: OrderlineRelatedTable | null;
  orderType: OrderlineOrderType | null;
  staticInfo: OrderlineStaticInfo;
}

@State<PurchaseOverlayStateModel>({
  name: 'purchaseOverlayState',
  defaults: {
    opened: false,
    isPopout: false,
    relatedTable: null,
    orderType: null,
    staticInfo: {
      uuid: null,
      name: null,
      productUuid: null,
      proposed: false,
      stockPosition: 0,
    },
  },
})
@Injectable()
export class PurchaseOverlayState {
  @Action(OpenOverlay, { cancelUncompleted: true })
  open(ctx: StateContext<PurchaseOverlayStateModel>, payload: OpenOverlay) {
    ctx.patchState({
      opened: true,
      relatedTable: payload.orderlineRelatedTable,
      orderType: payload.orderlineOrderType,
      staticInfo: {
        uuid: payload.orderline.uuid,
        productUuid: payload.orderline.webshopProduct.uuid,
        name: payload.orderline.webshopProduct.name,
        proposed: payload.orderline.proposed,
        stockPosition: payload.orderline.stockPosition,
      },
    });
  }

  @Action(CloseOverlay, { cancelUncompleted: true })
  close(ctx: StateContext<PurchaseOverlayStateModel>) {
    ctx.patchState({
      opened: false,
    });

    return ctx.dispatch(new ResetOverlay());
  }

  @Action(ResetOverlay, { cancelUncompleted: true })
  reset(ctx: StateContext<PurchaseOverlayStateModel>) {
    ctx.patchState({
      opened: false,
      relatedTable: null,
      orderType: null,
      staticInfo: {
        uuid: null,
        name: null,
        productUuid: null,
        proposed: false,
        stockPosition: 0,
      },
    });
  }

  @Action(OrderlineInfoQuantityChanged, { cancelUncompleted: true })
  quantityChanged(
    ctx: StateContext<PurchaseOverlayStateModel>,
    payload: OrderlineInfoQuantityChanged
  ) {
    if (parseInt(payload.quantity) === 0) {
      this.changeRelatedTableToAvailable(ctx);
    }
  }

  @Action(SnoozeProduct, { cancelUncompleted: true })
  productSnoozed(ctx: StateContext<PurchaseOverlayStateModel>) {
    const relatedTable: OrderlineRelatedTable =
      ctx.getState().staticInfo.stockPosition >= 0
        ? OrderlineRelatedTables.AVAILABLE
        : OrderlineRelatedTables.PROPOSED;

    ctx.patchState({
      relatedTable,
    });
  }

  @Action(OrderlineAdd, { cancelUncompleted: true })
  changeRelatedTableToProposed(ctx: StateContext<PurchaseOverlayStateModel>) {
    ctx.patchState({
      relatedTable: OrderlineRelatedTables.PROPOSED,
    });
  }

  @Action(OrderlineRemove, { cancelUncompleted: true })
  changeRelatedTableToAvailable(ctx: StateContext<PurchaseOverlayStateModel>) {
    ctx.patchState({
      relatedTable: OrderlineRelatedTables.AVAILABLE,
    });
  }

  @Action(OpenPopout, { cancelUncompleted: true })
  openPopout(ctx: StateContext<PurchaseOverlayStateModel>) {
    ctx.patchState({
      isPopout: true,
    });
  }

  @Action(ClosePopout, { cancelUncompleted: true })
  closePopout(ctx: StateContext<PurchaseOverlayStateModel>) {
    ctx.patchState({
      isPopout: false,
    });
  }
}
