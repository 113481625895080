import {
  AccountPermissionRoles,
  PermissionsBase,
} from 'src/app/shared/models/account/account-permissions.model';

export enum RolesFeatureKeys {
  ANALYTICS = 'analytics',
  PURCHASE = 'purchase',
  DELIVERIES = 'deliveries',
  PRODUCTS = 'products',
  SUPPLIERS = 'suppliers',
  PROMOTIONS = 'promotions',
  SETTINGS = 'settings',
  BILLING = 'billing',
}

export const STAGING_ROLES: PermissionsBase = {
  rol_heI8Vyz7o24VbK5s: {
    roleId: 'rol_heI8Vyz7o24VbK5s',
    featureKey: RolesFeatureKeys.ANALYTICS,
    role: AccountPermissionRoles.VIEW,
  },
  rol_wDVwh6Jqvw7oQi9a: {
    roleId: 'rol_wDVwh6Jqvw7oQi9a',
    featureKey: RolesFeatureKeys.ANALYTICS,
    role: AccountPermissionRoles.EDIT,
  },
  rol_qbupH1d73heVyjRj: {
    roleId: 'rol_qbupH1d73heVyjRj',
    featureKey: RolesFeatureKeys.PURCHASE,
    role: AccountPermissionRoles.VIEW,
  },
  rol_fggmTidQe4yx2ijL: {
    roleId: 'rol_fggmTidQe4yx2ijL',
    featureKey: RolesFeatureKeys.PURCHASE,
    role: AccountPermissionRoles.EDIT,
  },
  rol_H3GM1AS1kPbbpeBy: {
    featureKey: RolesFeatureKeys.DELIVERIES,
    roleId: 'rol_H3GM1AS1kPbbpeBy',
    role: AccountPermissionRoles.VIEW,
  },
  rol_b6xbsnds7yqNdvXT: {
    featureKey: RolesFeatureKeys.DELIVERIES,
    roleId: 'rol_b6xbsnds7yqNdvXT',
    role: AccountPermissionRoles.EDIT,
  },
  rol_0CCequrLHarwkIw2: {
    featureKey: RolesFeatureKeys.PRODUCTS,
    roleId: 'rol_0CCequrLHarwkIw2',
    role: AccountPermissionRoles.VIEW,
  },
  rol_BoFSb53UrxSgYxqA: {
    featureKey: RolesFeatureKeys.PRODUCTS,
    roleId: 'rol_BoFSb53UrxSgYxqA',
    role: AccountPermissionRoles.EDIT,
  },
  rol_guYOODjZJXQYgBEn: {
    featureKey: RolesFeatureKeys.SUPPLIERS,
    roleId: 'rol_guYOODjZJXQYgBEn',
    role: AccountPermissionRoles.VIEW,
  },
  rol_ferq4hTAE2eqbsoi: {
    featureKey: RolesFeatureKeys.SUPPLIERS,
    roleId: 'rol_ferq4hTAE2eqbsoi',
    role: AccountPermissionRoles.EDIT,
  },
  rol_fAmTMmKGbBFY2GUA: {
    featureKey: RolesFeatureKeys.PROMOTIONS,
    roleId: 'rol_fAmTMmKGbBFY2GUA',
    role: AccountPermissionRoles.VIEW,
  },
  rol_SeQkSWrqCNjKo2cL: {
    featureKey: RolesFeatureKeys.PROMOTIONS,
    roleId: 'rol_SeQkSWrqCNjKo2cL',
    role: AccountPermissionRoles.EDIT,
  },
  rol_xLhsjtxWFC1l63XP: {
    featureKey: RolesFeatureKeys.SETTINGS,
    roleId: 'rol_xLhsjtxWFC1l63XP',
    role: AccountPermissionRoles.VIEW,
  },
  rol_1lNLhfX59yVfYR9T: {
    featureKey: RolesFeatureKeys.SETTINGS,
    roleId: 'rol_1lNLhfX59yVfYR9T',
    role: AccountPermissionRoles.EDIT,
  },
  rol_kZYFwqcmyMF1H50N: {
    featureKey: RolesFeatureKeys.BILLING,
    roleId: 'rol_kZYFwqcmyMF1H50N',
    role: AccountPermissionRoles.EDIT,
  },
};

export const PROD_ROLES: PermissionsBase = {
  rol_tO3USuqwfJ33U8tJ: {
    roleId: 'rol_tO3USuqwfJ33U8tJ',
    featureKey: RolesFeatureKeys.ANALYTICS,
    role: AccountPermissionRoles.VIEW,
  },
  rol_3bM6blhLzGCXtuzW: {
    roleId: 'rol_3bM6blhLzGCXtuzW',
    featureKey: RolesFeatureKeys.ANALYTICS,
    role: AccountPermissionRoles.EDIT,
  },
  rol_026VaB3VlNtGLBKz: {
    roleId: 'rol_026VaB3VlNtGLBKz',
    featureKey: RolesFeatureKeys.PURCHASE,
    role: AccountPermissionRoles.VIEW,
  },
  rol_cagZxbKDHuhfHm9w: {
    roleId: 'rol_cagZxbKDHuhfHm9w',
    featureKey: RolesFeatureKeys.PURCHASE,
    role: AccountPermissionRoles.EDIT,
  },
  rol_wdpTaDGD6sLAskaD: {
    featureKey: RolesFeatureKeys.DELIVERIES,
    roleId: 'rol_wdpTaDGD6sLAskaD',
    role: AccountPermissionRoles.VIEW,
  },
  rol_fsqxaZLCBybSTomn: {
    featureKey: RolesFeatureKeys.DELIVERIES,
    roleId: 'rol_fsqxaZLCBybSTomn',
    role: AccountPermissionRoles.EDIT,
  },
  rol_v6uWIJ7osNHvJ3p6: {
    featureKey: RolesFeatureKeys.PRODUCTS,
    roleId: 'rol_v6uWIJ7osNHvJ3p6',
    role: AccountPermissionRoles.VIEW,
  },
  rol_r8KaSkF4rDjTHjkZ: {
    featureKey: RolesFeatureKeys.PRODUCTS,
    roleId: 'rol_r8KaSkF4rDjTHjkZ',
    role: AccountPermissionRoles.EDIT,
  },
  rol_1nIp4R1pd64vggep: {
    featureKey: RolesFeatureKeys.SUPPLIERS,
    roleId: 'rol_1nIp4R1pd64vggep',
    role: AccountPermissionRoles.VIEW,
  },
  rol_zAvxT3J5q3mZ5cDx: {
    featureKey: RolesFeatureKeys.SUPPLIERS,
    roleId: 'rol_zAvxT3J5q3mZ5cDx',
    role: AccountPermissionRoles.EDIT,
  },
  rol_8DIk2FYPVGKaW4fG: {
    featureKey: RolesFeatureKeys.PROMOTIONS,
    roleId: 'rol_8DIk2FYPVGKaW4fG',
    role: AccountPermissionRoles.VIEW,
  },
  rol_ZXD9GRGuhJopEzCO: {
    featureKey: RolesFeatureKeys.PROMOTIONS,
    roleId: 'rol_ZXD9GRGuhJopEzCO',
    role: AccountPermissionRoles.EDIT,
  },
  rol_zjWjhb2E7EgPnfuS: {
    featureKey: RolesFeatureKeys.SETTINGS,
    roleId: 'rol_zjWjhb2E7EgPnfuS',
    role: AccountPermissionRoles.VIEW,
  },
  rol_ZFXSt4nXlvRPWm3U: {
    featureKey: RolesFeatureKeys.SETTINGS,
    roleId: 'rol_ZFXSt4nXlvRPWm3U',
    role: AccountPermissionRoles.EDIT,
  },
  rol_DZpUh8VDLDH5hzPy: {
    featureKey: RolesFeatureKeys.BILLING,
    roleId: 'rol_DZpUh8VDLDH5hzPy',
    role: AccountPermissionRoles.EDIT,
  },
};
