import { DialogRef } from '@angular/cdk/dialog';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ExportDataV2 } from 'src/app/shared/models/exports/exports-v2.model';
import { WebshopProductPromotion } from 'src/app/shared/models/promotion/v2/promotion-v2.model';

export class InitializeWebshopProductPromotionsState {
  static readonly type = '[Webshop Product Promotions] Initialize';
}

export class LoadWebshopProductPromotions {
  static readonly type =
    '[Webshop Product Promotions] Load Webshop Product Promotions';

  constructor(
    public additionalParams: { preventLoading: boolean } = {
      preventLoading: false,
    }
  ) {}
}

export class ReloadDatatable {
  static readonly type = '[Webshop Product Promotions] Reload';
}

export class EditWebshopProductPromotions {
  static readonly type =
    '[Webshop Product Promotions] Edit Webshop Product Promotions';
}

export class CancelEditWebshopProductPromotions {
  static readonly type =
    '[Webshop Product Promotions] Cancel Edit Webshop Product Promotions';
}

export class UpdateWebshopProductUpliftType {
  static readonly type =
    '[Webshop Product Promotions] Update Webshop Product Uplift Type';

  constructor(
    public data: WebshopProductPromotion,
    public value: string
  ) {}
}

export class UpdateWebshopProductUpliftIncrease {
  static readonly type =
    '[Webshop Product Promotions] Update Webshop Product Uplift Increase';

  constructor(
    public data: WebshopProductPromotion,
    public value: string
  ) {}
}

export class DeleteWebshopProductPromotion {
  static readonly type =
    '[Webshop Product Promotions] Delete Webshop Product Promotion';

  constructor(
    public promotionUUID: string,
    public webshopProductUUID: string,
    public dialogRef: DialogRef<any>
  ) {}
}

export class SaveEditedWebshopProductPromotions {
  static readonly type =
    '[Webshop Product Promotions] Save Edited Webshop Product Promotions';
}

export class AddSearchParam {
  static readonly type = '[Webshop Product Promotions] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Webshop Product Promotions] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Webshop Product Promotions] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Webshop Product Promotions] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Webshop Product Promotions] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Webshop Product Promotions] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Webshop Product Promotions] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Webshop Product Promotions] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Webshop Product Promotions] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Webshop Product Promotions] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Webshop Product Promotions] Toggle Filter';
}

export class ExportProductPromotionsV2 {
  static readonly type =
    '[Webshop Product Promotions] Export Product Promotions V2';

  constructor(public exportData: ExportDataV2) {}
}

export class LoadWebshopProductUnassociatedPromotions {
  static readonly type =
    '[Add Product Promotions Dialog] Load Webshop Product Unassociated Promotions';
}

export class ResetPaginationAndLoadData {
  static readonly type =
    '[Webshop Product Promotions] Reset Pagination And Load Data';
}

export class SetPromotionIntervalFilter {
  static readonly type =
    '[Webshop Product Promotions] Set Promotion Interval Filter';

  constructor(
    public start: string,
    public end: string
  ) {}
}

export class ResetProductPromotions {
  static readonly type =
    '[Webshop Product Promotions] Reset Product Promotions';
}
