import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { distinctUntilKeyChanged, Observable, Subject, takeUntil } from 'rxjs';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';

import { SalesOptions } from 'src/app/shared/models/sales/sales-v2.model';
import { Chart } from '../../../chart/chart.component';
import { ChartLoadingOptions } from '../../../chart/model/chart.model';

import { ProductSalesV2StateQueries } from './state/sales-v2.queries';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { ProductSalesV2SetInitialState } from './actions/sales-v2.actions';

@Component({
  selector: 'app-sales-v2',
  templateUrl: './sales-v2.component.html',
  styleUrls: ['./sales-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesV2Component
  extends Chart<SalesOptions>
  implements OnInit, OnDestroy
{
  @Select(AccountSettingsState.colorThemeLowercase)
  colorTheme$: Observable<string>;

  @Select(ProductSalesV2StateQueries.loading)
  loading$: Observable<boolean>;

  @Select(ProductSalesV2StateQueries.loadingOptions)
  loadingOptions$: Observable<ChartLoadingOptions>;

  @Select(ProductSalesV2StateQueries.graphOptions)
  graphOptions$: Observable<SalesOptions>;

  @Select(ProductSalesV2StateQueries.totalSales)
  totalSales$: Observable<number>;

  readonly METADATA = STRINGS.metadata.sales;

  private destroy$ = new Subject<void>();

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(distinctUntilKeyChanged('uuid'), takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new ProductSalesV2SetInitialState());
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
