import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
  ViewEncapsulation,
  computed,
  input,
  model,
  signal,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { OverlayDirective } from '../../../overlay-container-v2/directives/overlay.directive';
import {
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
} from '../../model/data-table-v2.model';
import {
  ColumnSelectionV3,
  ColumnToggled,
  fixSelectedTableColumnsOrder,
} from './model/column-view-v3.model';
import { STRINGS } from './model/column-view-v3.strings';

@Component({
  selector: 'app-column-view-v3',
  templateUrl: './column-view-v3.component.html',
  styleUrl: './column-view-v3.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ColumnViewV3Component {
  @ViewChild(OverlayDirective)
  overlay: OverlayDirective;

  showApplyAll = input(false, {
    transform: coerceBooleanProperty,
  });

  applyAll = model(false);

  columnsOrder = input<ColumnOrder>();

  columnsGroups = signal<DatatableColumnV2Groups<DatatableColumnV2> | null>(
    null
  );

  columnsGroupsArray: Signal<DatatableColumnV2GroupList<DatatableColumnV2>[]> =
    computed(() => {
      return Object.values(this.columnsGroups()).map(group => {
        return {
          ...group,
          columns: Object.values(group.columns).filter(
            column => column.selectable
          ),
        };
      });
    });

  columnsGroupArrayFiltered: Signal<
    DatatableColumnV2GroupList<DatatableColumnV2>[]
  > = computed(() => {
    return this.columnsGroupsArray()
      .map(group => {
        return {
          ...group,
          columns: group.columns.filter(
            column =>
              column.name
                .toLowerCase()
                .includes(this.filterRef().toLowerCase()) && column.selectable
          ),
        };
      })
      .filter(group => Object.keys(group.columns).length > 0);
  });

  @Input()
  set columns(groups: DatatableColumnV2Groups<DatatableColumnV2>) {
    this.columnsGroups.set(groups);
  }

  checkedColumns = computed(() =>
    this.columnsGroupsArray()
      .map(group => {
        return Object.values(group.columns).filter(column => column.checked);
      })
      .flat()
      .sort((a, b) => fixSelectedTableColumnsOrder(a, b, this.columnsOrder()))
  );

  checkedColumnsFiltered = computed(() =>
    this.checkedColumns().filter(column =>
      column.name.toLowerCase().includes(this.filterRef().toLowerCase())
    )
  );

  totalColumnsLength = computed(() =>
    this.columnsGroupsArray().reduce((count, group) => {
      return count + Object.values(group.columns).length;
    }, 0)
  );

  checkedColumnsLength = computed(() =>
    this.columnsGroupsArray().reduce((count, group) => {
      return (
        count +
        Object.values(group.columns).filter(column => column.checked).length
      );
    }, 0)
  );

  allChecked = computed(
    () => this.checkedColumnsLength() === this.totalColumnsLength()
  );

  someChecked = computed(
    () => this.checkedColumnsLength() > 0 && !this.allChecked()
  );

  noneChecked = computed(() => this.checkedColumnsLength() === 0);

  filterRef = signal('');

  @Output()
  columnsSelected: EventEmitter<ColumnSelectionV3> =
    new EventEmitter<ColumnSelectionV3>();

  readonly COLUMN_VIEW_STRINGS = STRINGS.metadata;

  readonly COMMON_STRINGS = MESSAGES.common;

  masterToggle() {
    if (this.allChecked()) {
      this._clearSelection();
      return;
    }

    this._checkAll();
  }

  toggleColumn(column: ColumnToggled) {
    this.columnsGroups.set({
      ...this.columnsGroups(),
      [column.groupKey]: {
        ...this.columnsGroups()[column.groupKey],
        columns: {
          ...this.columnsGroups()[column.groupKey].columns,
          [column.columnKey]: {
            ...this.columnsGroups()[column.groupKey].columns[column.columnKey],
            checked: column.checked,
          },
        },
      },
    });
  }

  cancel(): void {
    this.overlay._closeOverlay();
  }

  apply(): void {
    this.columnsSelected.emit({
      columnsGroups: this.columnsGroups(),
      applyAll: this.applyAll(),
    });

    this.overlay._closeOverlay();
  }

  private _checkAll() {
    this.columnsGroups.set(
      Object.keys(this.columnsGroups()).reduce((acc, groupKey) => {
        return {
          ...acc,
          [groupKey]: {
            ...this.columnsGroups()[groupKey],
            columns: Object.keys(this.columnsGroups()[groupKey].columns).reduce(
              (columns, columnKey) => {
                return {
                  ...columns,
                  [columnKey]: {
                    ...this.columnsGroups()[groupKey].columns[columnKey],
                    checked: this._isManagableColumn(groupKey, columnKey)
                      ? true
                      : this.columnsGroups()[groupKey].columns[columnKey]
                          .checked,
                  },
                };
              },
              {}
            ),
          },
        };
      }, {})
    );
  }

  private _clearSelection() {
    this.columnsGroups.set(
      Object.keys(this.columnsGroups()).reduce((acc, groupKey) => {
        return {
          ...acc,
          [groupKey]: {
            ...this.columnsGroups()[groupKey],
            columns: Object.keys(this.columnsGroups()[groupKey].columns).reduce(
              (columns, columnKey) => {
                return {
                  ...columns,
                  [columnKey]: {
                    ...this.columnsGroups()[groupKey].columns[columnKey],
                    checked: this._isManagableColumn(groupKey, columnKey)
                      ? false
                      : this.columnsGroups()[groupKey].columns[columnKey]
                          .checked,
                  },
                };
              },
              {}
            ),
          },
        };
      }, {})
    );
  }

  /**
   * Determines whether the column can be checked and/or unchecked programatically
   * @param groupKey
   * @param columnKey
   * @returns true if managable column
   */
  private _isManagableColumn(groupKey: string, columnKey: string): boolean {
    return (
      this.columnsGroups()[groupKey].columns[columnKey].selectable &&
      !this.columnsGroups()[groupKey].columns[columnKey].disabled
    );
  }
}
