import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  CloseOverlay,
  ClosePopout,
} from 'src/app/features/purchase-v3/components/purchase-overlay-v2/actions/purchase-overlay-v2.actions';

@Component({
  selector: 'app-popout-window',
  template: `
    <ng-container *cdkPortal>
      <ng-content></ng-content>
    </ng-container>
  `,
})
export class PopoutWindowComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CdkPortal) portal: CdkPortal;

  private externalWindow;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private store: Store
  ) {}

  ngOnInit() {
    this.externalWindow = window.open(
      '',
      '',
      'width=1280,height=600,left=200,top=200,status=0,toolbar=0'
    );

    let timer = setInterval(() => {
      if (this.isPopoutWindowOpen()) {
        clearInterval(timer);
        this.store.dispatch([new CloseOverlay(), new ClosePopout()]);
      }
    }, 1000);

    document.querySelectorAll('link, style').forEach(htmlElement => {
      this.externalWindow.document.head.appendChild(
        htmlElement.cloneNode(true)
      );
    });
  }

  isPopoutWindowOpen(): boolean {
    return this.externalWindow.closed;
  }

  ngAfterViewInit() {
    const host = new DomPortalOutlet(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    host.attach(this.portal);
  }

  ngOnDestroy() {
    this.externalWindow.close();
  }
}
