import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';

@Component({
  selector: 'app-product-details-overview',
  templateUrl: './product-details-overview.component.html',
  styleUrl: './product-details-overview.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsOverviewComponent {
  productNotesOpened: boolean = false;

  readonly METADATA = STRINGS.metadata;

  @Select(ProductDetailsV2StateQueries.settingsNotes)
  notes$: Observable<string>;
}
