import { Selector } from '@ngxs/store';

import {
  WebshopProductPromotionsEditorState,
  WebshopProductPromotionsEditorStateModel,
} from './product-promotions-v2-editor.state';

export class WebshopProductPromotionsEditorStateQueries {
  @Selector([WebshopProductPromotionsEditorState])
  static saving(state: WebshopProductPromotionsEditorStateModel): boolean {
    return state.saving;
  }

  @Selector([WebshopProductPromotionsEditorState])
  static haveEditedPromotions(
    state: WebshopProductPromotionsEditorStateModel
  ): boolean {
    return !!state.editedWebshopProductPromotions.length;
  }
}
