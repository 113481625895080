import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

export namespace SettingsUserManagementActions {
  export class InitializeSettings {
    static readonly type = '[Settings User Management] Initialize';
  }

  export class CancelSettings {
    static readonly type = '[Settings User Management] Cancel';
  }

  export class ResetSettings {
    static readonly type = '[Settings User Management] Reset';
  }

  export class SaveSettings {
    static readonly type = '[Settings User Management] Save';
  }

  export class UpdateContactEmail {
    static readonly type = '[Settings User Management] Update Contact Email';

    constructor(public value: string) {}
  }
}

export class LoadUserAccounts {
  static readonly type = '[Settings User Management] Load';
}

export class InviteUsers {
  static readonly type = '[Settings User Management] Invite Users';

  constructor(public email: string) {}
}

export class AddSearchParam {
  static readonly type = '[Settings User Management] Add Search Param';

  constructor(public param: string[] | null) {}
}

export class AddFilterParam {
  static readonly type = '[Settings User Management] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Settings User Management] Sort';

  constructor(public sort: Sorted) {}
}

export class Paginate {
  static readonly type = '[Settings User Management] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Settings User Management] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Settings User Management] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}
