<app-dialog-container
  ><div appDialogHeader>
    <h1 appDialogTitle>
      <span class="dialog-title-text">{{ STRINGS.title }}</span>
    </h1>
    <button
      aria-label="Purchase checkout dialog close button"
      class="close-button"
      (click)="close()"
      [disabled]="saving$ | async">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div appDialogBody>
    <div class="body-text-container" i18n="purchase checkout dialog subtitle">
      This will create a <span class="bold-text">draft version</span> of the
      current order and the
      <span class="bold-text">auto updates will be turned off</span>. The
      expected delivery date is
      <span class="bold-text"> {{ expectedDeliveryDate | date }}</span
      >.
    </div>
  </div>

  <div appDialogActions class="custom-actions">
    <div>
      <app-checkbox
        [disabled]="saving$ | async"
        [label]="STRINGS.doNotAskAgain"
        [checked]="skipCheckout$ | async"
        (checkedChange)="skipCheckout($event)" />
    </div>

    <div class="action-buttons">
      <button
        appButton
        variant="tertiary"
        (click)="close()"
        [disabled]="saving$ | async">
        {{ STRINGS.actions.cancel }}
      </button>

      <button
        appButton
        variant="primary"
        (click)="placeOrder()"
        [disabled]="saving$ | async">
        {{ STRINGS.actions.placeOrder }}

        @if (saving$ | async) {
          <app-progress-spinner />
        }
      </button>
    </div>
  </div>
</app-dialog-container>
