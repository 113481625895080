import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';

@Component({
  selector: 'app-datatable-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DatatableTopBarComponent {
  @Input({ required: true, transform: booleanAttribute })
  expanded: boolean;
}
