import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EmbeddedViewRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TooltipBase } from '../../core/tooltip-base';
import {
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal';

@Component({
  selector: 'app-tooltip-container',
  templateUrl: './tooltip-container.component.html',
  styleUrl: './tooltip-container.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TooltipContainerComponent
  extends TooltipBase
  implements OnDestroy
{
  @ViewChild(CdkPortalOutlet, { static: true })
  portalOutlet: CdkPortalOutlet;

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    this._checkAlreadyAttachedPortal();

    const portalRef = this.portalOutlet.attachComponentPortal(portal);

    return portalRef;
  }

  attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    this._checkAlreadyAttachedPortal();

    const portalRef = this.portalOutlet.attachTemplatePortal(portal);

    return portalRef;
  }

  private _checkAlreadyAttachedPortal() {
    if (this.portalOutlet.hasAttached()) {
      throw Error(
        'Attempting to attach tooltip content after content is already attached'
      );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
