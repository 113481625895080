import { MultiSupplierUpdateProperties } from 'src/app/shared/models/multi-supplier-product-editor/multi-supplier-editor-update.model';
import {
  MultiSupplierProduct,
  Supplier,
} from 'src/app/shared/models/multi-supplier-product/multi-supplier-product.model';

export class UpdateProductSku {
  static readonly type = '[Multi Supplier Editor] Update Product Sku';

  constructor(
    public data: MultiSupplierProduct,
    public value: string
  ) {}
}

export class UpdateProductLotSize {
  static readonly type = '[Multi Supplier Editor] Update Product Lot Size';

  constructor(
    public data: MultiSupplierProduct,
    public value: number
  ) {}
}

export class UpdateProductEAN {
  static readonly type = '[Multi Supplier Editor] Update Product EAN Code';

  constructor(
    public data: MultiSupplierProduct,
    public value: string
  ) {}
}

export class UpdateProductMOQ {
  static readonly type = '[Multi Supplier Editor] Update Product MOQ';

  constructor(
    public data: MultiSupplierProduct,
    public value: number
  ) {}
}

export class UpdateProductWeight {
  static readonly type = '[Multi Supplier Editor] Update Product Weight';

  constructor(
    public data: MultiSupplierProduct,
    public value: number
  ) {}
}

export class UpdateProductVolume {
  static readonly type = '[Multi Supplier Editor] Update Product Volume';

  constructor(
    public data: MultiSupplierProduct,
    public value: number
  ) {}
}

export class UpdateProductPrice {
  static readonly type = '[Multi Supplier Editor] Update Product Price';

  constructor(
    public data: MultiSupplierProduct,
    public value: number
  ) {}
}

export class UpdateProductDeliveryTime {
  static readonly type = '[Multi Supplier Editor] Update Product LeadTime';

  constructor(
    public data: MultiSupplierProduct,
    public value: number
  ) {}
}

export class UpdateProductPreferred {
  static readonly type = '[Multi Supplier Editor] Update Product Preferred';

  constructor(
    public data: MultiSupplierProduct,
    public value: boolean
  ) {}
}

export class UpdateAllProductPreferred {
  static readonly type = '[Multi Supplier Editor] Update All Product Preferred';

  constructor(
    public data: MultiSupplierProduct[],
    public value: boolean
  ) {}
}

export class UpdateProductAvailability {
  static readonly type = '[Multi Supplier Editor] Update Product Availability';

  constructor(
    public data: MultiSupplierProduct,
    public value: boolean
  ) {}
}

export class UpdateProductAvailabilityDate {
  static readonly type =
    '[Multi Supplier Editor] Update Product Availability Date';

  constructor(
    public data: MultiSupplierProduct,
    public value: string
  ) {}
}

export class UpdateProductSupplier {
  static readonly type = '[Multi Supplier Editor] Update Product Supplier';

  constructor(
    public data: MultiSupplierProduct,
    public value: Supplier
  ) {}
}

export class LoadMultiSupplierProductsEditor {
  static readonly type =
    '[Multi Supplier Editor] Load Multi Supplier Products Editor';

  constructor(public search: string = '') {}
}

export class AddNewMultiSupplierProduct {
  static readonly type =
    '[Multi Supplier Editor] Add New Multi Supplier Product';
}

export class RemoveAddedProductSupplier {
  static readonly type =
    '[Multi Supplier Editor] Remove Added Product Supplier';

  constructor(public supplierProductId: string) {}
}

export class ResetEditor {
  static readonly type = '[Multi Supplier Editor] Reset Editor';
}

export class SaveMultiSupplierEditor {
  static readonly type = '[Multi Supplier Editor] Save Multi Supplier Editor';
}

export class AddNewSupplier {
  static readonly type = '[Multi Supplier Editor] Add New';
}

export class CancelEditProductSuppliers {
  static readonly type =
    '[Multi Supplier Editor] Cancel Edit Products Suppliers';
}

export class CreateProductSuppliers {
  static readonly type = '[Multi Supplier Editor] Create Product Supplier';

  constructor(public properties: MultiSupplierUpdateProperties) {}
}

export class ResetAvailableSuppliers {
  static readonly type = '[Multi Supplier Editor] Reset Available Suppliers';
}

export class StartEditing {
  static readonly type = '[Multi Supplier Editor] Start Editing';
}

export class ExitEditing {
  static readonly type = '[Multi Supplier Editor] Exit Editing';
}
