import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FilterListStrategy } from '../core/filter-strategies/filter-list-strategy';
import { ServiceRequestInfoV3Operators } from '../../../../model/pageable-v2.model';
import { FILTER, FilterBase, FilterEmitted } from '../../model/filter-v2.model';
import { FilterV2Component } from '../../filter-base-v2';

@Component({
  selector: 'app-filter-restricted',
  exportAs: 'filterRestricted',
  templateUrl: './filter-restricted.component.html',
  styleUrls: ['./filter-restricted.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FILTER, useExisting: FilterRestrictedComponent }],
})
export class FilterRestrictedComponent
  extends FilterV2Component
  implements FilterBase, OnInit
{
  filterStrategy: FilterListStrategy = new FilterListStrategy();

  ngOnInit(): void {
    if (this._shouldPopulate()) {
      this._populate();
    }
  }

  clear(): void {
    this.filterStrategy.clear();
  }

  cancel(): void {
    this.canceled.emit();
  }

  apply(filtered: FilterEmitted): void {
    if (this._isAllowedToApplyFilter()) {
      this.filtered.emit({
        columnKey: this.filter().columnKey,
        optionSelected: null,
        subOperator:
          this.filterStrategy.form.selection.selected.length === 1
            ? ServiceRequestInfoV3Operators.AND
            : ServiceRequestInfoV3Operators.OR,
        values: this.filterStrategy.form.selection.selected,
        applyAll: filtered.applyAll,
      });
    }
  }

  get isAllSelected(): boolean {
    return this.filterStrategy.isAllSelected(this.filter().options);
  }

  get isSomeSelected(): boolean {
    return this.filterStrategy.isSomeSelected();
  }

  get selectedOptionsAmount(): number {
    return this.filterStrategy.selected().length;
  }

  private _shouldPopulate(): boolean {
    return !!this.filterValues()?.value?.length;
  }

  private _populate(): void {
    this.filterStrategy.populate(this.filterValues()?.value);
  }

  private _isAllowedToApplyFilter(): boolean {
    return this.filterStrategy.hasValue() && !this.filterDisabled();
  }
}
