import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { SalesGraphData } from 'src/app/shared/models/sales/sales.model';

import { SalesGraph as SalesGraphV1 } from './model/sales.model';

export class SalesAdapter implements DataAdapter<SalesGraphV1, SalesGraphData> {
  transform(data: SalesGraphV1): SalesGraphData {
    const promotions = data.promotions.map(promotion => {
      return {
        uuid: promotion.uuid,
        name: promotion.name,
        data: promotion.data,
      };
    });

    const salesGraph: SalesGraphData = {
      data: data.data,
      futureData: data.futureData,
      promotions: promotions,
    };

    return salesGraph;
  }
}
