export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortedOrder = SortOrder.ASC | SortOrder.DESC;

export interface Sortable {
  /** The column key */
  key: string;

  /** Sort order */
  order: SortOrder;
}

export interface Sorted {
  /**  The persistence key */
  persistenceKey?: string;

  /** The sorted columns key */
  key: string;

  /** Sort order */
  order: SortOrder;
}
