import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import {
  ResetImportSuppliersData,
  UpdateImportList,
} from '../actions/import-suppliers-dialog.action';
import { UploadSupplierData } from 'src/app/features/imports/components/imports-data-table/actions/imports.actions';

export interface ImportSuppliersDialogStateModel {
  importList: FormData;
  importKey: string;
}

const editorDefaults: ImportSuppliersDialogStateModel = {
  importList: new FormData(),
  importKey: '422960f7-690b-4529-8395-e7f2fe387107',
};

@State<ImportSuppliersDialogStateModel>({
  name: 'ImportSuppliersDialogState',
  defaults: editorDefaults,
})
@Injectable()
export class ImportSuppliersDialogState {
  @Selector()
  static importKey(state: ImportSuppliersDialogStateModel): string {
    return state.importKey;
  }

  @Action(UpdateImportList, { cancelUncompleted: true })
  updateImportList(
    ctx: StateContext<ImportSuppliersDialogStateModel>,
    payload: UpdateImportList
  ) {
    const state = ctx.getState();

    state.importList.append('data', JSON.stringify(payload.data));

    if (payload.data.batch.index === payload.data.batch.count) {
      ctx.dispatch(
        new UploadSupplierData(
          state.importList,
          payload.operation,
          payload.type
        )
      );
    }

    return of(true);
  }

  @Action(ResetImportSuppliersData, { cancelUncompleted: true })
  reset(ctx: StateContext<ImportSuppliersDialogStateModel>) {
    ctx.patchState({
      importList: new FormData(),
    });
  }
}
