export const STRINGS = {
  columns: {
    imports: {
      name: {
        key: 'FILE_NAME',
        name: $localize`Name`,
        filterKey: 'FILE_NAME',
        currency: false,
        containers: false,
      },
      source: {
        key: 'ENTITY_NAME',
        name: $localize`Source`,
        filterKey: 'ENTITY_NAME',
        currency: false,
        containers: false,
      },
      operation: {
        key: 'OPERATION',
        name: $localize`Operation`,
        filterKey: 'OPERATION',
        currency: false,
        containers: false,
      },
      identifier: {
        key: 'ENTITY_IDENTIFIER',
        name: $localize`Identifier`,
        filterKey: 'ENTITY_IDENTIFIER',
        currency: false,
        containers: false,
      },
      createdAt: {
        key: 'CREATED_AT',
        name: $localize`Date of Import`,
        filterKey: 'CREATED_AT',
        currency: false,
        containers: false,
      },
      records: {
        key: 'TOTAL_RECORDS',
        name: 'Records',
        filterKey: 'TOTAL_RECORDS',
        currency: false,
        containers: false,
      },
      status: {
        key: 'STATE',
        name: $localize`Status`,
        filterKey: 'STATE',
        currency: true,
        containers: false,
      },
      actions: {
        key: 'actions',
        name: '',
        currency: false,
        containers: false,
      },
    },
  },
  metadata: {
    title: $localize`Imports`,
    actions: {
      details: $localize`View details`,
    },
    states: {
      loading: $localize`Importing...`,
      inQueue: $localize`Waiting for import`,
      noData: $localize`There is no data available.`,
    },
    status: {
      completed: $localize`Import completed`,
      completedErrors: $localize`Imported excluding errors`,
      invalid: $localize`Import failed due to errors`,
      failed: $localize`Import failed unexpectedly`,
    },
    radioButtons: {
      optiplyID: $localize`Optiply ID`,
      supplierName: $localize`Supplier name`,
      optiplyIDDescription: $localize`Use Optiply ID as the identifier. (recommended)`,
      supplierNameDescription: $localize`Use product’s supplier name as the identifier.`,
    },
    useCSVCustomText: $localize` rows were received. Track progress in the Imports page.`,
    importDialogTitle: $localize`Import suppliers...`,
    updateSubLabel1: $localize`Importing will only`,
    updateSubLabel2: $localize`update`,
    updateSubLabel3: $localize`existing suppliers and won’t create new suppliers.`,
    importDialogSubTitle: $localize`Choose the identifier for the suppliers to be updated from the options below.`,
    buttons: {
      newSuppliers: $localize`Add new suppliers`,
      updateSuppliers: $localize`Update existing suppliers`,
      newSuppliersDescription: $localize`Use a .csv or .xlsx file to add one or more new suppliers at the same time`,
      updateSuppliersDescription: $localize`Use a .csv or .xlsx file to update one or more suppliers at the same time.`,
    },
  },
};
