import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from 'src/app/core/services/locale.service';

/**
 * Custom decimal pipe that takes the locale into consideration.
 * Use this pipe instead of the DecimalPipe.
 */
@Pipe({
  name: 'datetime24Format',
})
export class Datetime24FormatPipe extends DatePipe implements PipeTransform {
  constructor(public localeService: LocaleService) {
    super(localeService.locale);
  }

  transform(value: any): any {
    if (value === null) return;

    return super.transform(value, 'MMM d, y, HH:mm:ss');
  }
}
