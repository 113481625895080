import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TooltipModule } from '../tooltip/tooltip.module';
import { CopyToClipboardComponent } from './copy-to-clipboard.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CopyToClipboardComponent],
  imports: [CommonModule, ClipboardModule, TooltipModule, MatIconModule],
  exports: [CopyToClipboardComponent],
})
export class CopyToClipboardModule {}
