import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-top-overview-row',
  templateUrl: './top-overview-row.component.html',
  styleUrls: ['./top-overview-row.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopOverviewRowComponent {
  @ContentChild('customHeader')
  customHeader: TemplateRef<any>;

  @Input()
  headerTitle: string;

  @Input()
  disabled: boolean;

  @Input()
  maxWidth: number;
}
