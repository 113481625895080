import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { OrderlineSlevel } from '../../models/buy-orders/v2/proposed-buy-orderlines-v2.model';

@Component({
  selector: 'app-reliability',
  templateUrl: './reliability.component.html',
  styleUrls: ['./reliability.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReliabilityComponent implements OnInit {
  @Input()
  slevel: OrderlineSlevel;

  subScores: string[] = [];

  constructor() {
    // intentional
  }

  ngOnInit(): void {
    this.subScores = [
      $localize`Reliability: ${this.reliability}`,
      '↳ ' + $localize`Lead time: ${this.leadTime}`,
      '↳ ' + $localize`Safety stock: ${this.safetyStock}`,
      '↳ ' + $localize`Forecast: ${this.forecast}`,
    ];
  }

  get baseClasses(): string[] {
    const reliabilityColor = this.reliabilityColor();

    return ['reliability-circle', `reliability-${reliabilityColor}`];
  }

  get reliability(): number {
    return this.slevel?.reliability ?? null;
  }

  get leadTime(): number | string {
    return this.slevel?.reliabilitySubScores?.leadTime ?? NOT_AVAILABLE_VALUE;
  }

  get safetyStock(): number | string {
    return (
      this.slevel?.reliabilitySubScores?.safetyStock ?? NOT_AVAILABLE_VALUE
    );
  }

  get forecast(): number | string {
    return this.slevel?.reliabilitySubScores?.forecast ?? NOT_AVAILABLE_VALUE;
  }

  get tooltip(): string {
    if (!this.subScores.length) return '';

    return this.subScores.join('\r\n');
  }

  private reliabilityColor(): string {
    if (this.reliability >= 8) {
      return 'green';
    }

    if (this.reliability > 4 && this.reliability < 8) {
      return 'orange';
    }

    return 'red';
  }
}
