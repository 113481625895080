import { DateTime } from 'luxon';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { UPLIFT_TYPES } from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { Promotions } from 'src/app/shared/models/promotion/v2/promotions-v2.model';
import { Promotions as PromotionsV1 } from '../model/promotions-v2.model';

export class PromotionsV2Adapter
  implements DataAdapter<PromotionsV1, Promotions>
{
  transform(data: PromotionsV1): Promotions {
    const promotions = data.promotions.map(promotion => {
      return {
        uuid: promotion.uuid,
        webshopUUID: promotion.webshopUUID,
        name: promotion.name,
        interval: {
          start: this.convertDate(promotion.interval.start),
          end: this.convertDate(promotion.interval.end),
        },
        uplift: {
          increase: promotion.uplift.increase,
          type: promotion.uplift.type,
        },
        numberOfProducts: promotion.numberOfProducts,
        settings: { enabled: promotion.settings.enabled },
        createdAt: this.convertDate(promotion.createdAt),
        updatedAt: this.convertDate(promotion.updatedAt),
        deletedAt: this.convertDate(promotion.deletedAt),
        id: promotion.id,
        notes: promotion.notes,
        extras: {
          formattedUpliftType: UPLIFT_TYPES[promotion.uplift.type],
        },
      };
    });

    return {
      data: promotions,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  /**
   * Converts a string with a value in second to an actual date
   * @param date
   * @returns date
   */
  convertDate(date: string): string {
    const dateFormat = 'yyyy-MM-dd';
    return DateTime.fromSeconds(Number(date)).toFormat(dateFormat);
  }
}
