import { DataAdapter } from 'src/app/shared/adapter/adapter';

import { Suppliers } from 'src/app/shared/models/suppliers/v2/suppliers-v2.model';
import {
  Supplier,
  Suppliers as SuppliersV1,
} from '../model/suppliers-v2.model';

import { MESSAGES } from 'src/app/core/constants/strings.constants';
import {
  DynamicTags,
  TagVariantColor,
} from 'src/app/shared/components/design-system/tags/model/tag.model';
import { STRINGS } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.strings';
import { DateTime } from 'luxon';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Store } from '@ngxs/store';
import { formatDateWithCutoffToWebshopTimezone } from 'src/app/shared/utils/dates.utils';
import {
  SupplierCommunication,
  SupplierCommunications,
} from 'src/app/shared/models/suppliers/v2/supplier-v2.model';

export class SuppliersV2Adapter implements DataAdapter<SuppliersV1, Suppliers> {
  readonly STRINGS = STRINGS.tooltips;
  private readonly MESSAGES = MESSAGES;

  constructor(private store: Store) {}

  transform(data: SuppliersV1): Suppliers {
    const suppliers = data.suppliers.map(supplier => {
      let newDate = null;

      if (supplier.cutoff) {
        newDate = this._buildTimeZoneDate(supplier.cutoff);
      }

      let cutoffArray = supplier.cutoff ? newDate.split(':') : [];

      return {
        uuid: supplier.uuid,
        id: Number(supplier.id),
        name: supplier.name,
        fixedCosts: supplier.fixedCosts,
        deliveryTime: supplier.deliveryTime,
        minimumOrderValue: supplier.minimumOrderValue,
        defaultWarehouse: supplier.defaultWarehouse,
        leadTimeMeanActual: supplier.leadTimeMeanActual,
        leadTimeSdActual: supplier.leadTimeSdActual,
        leadTimeSdPct: supplier.leadTimeSdPct,
        ignored: supplier.ignored,
        backorders: supplier.backorders,
        reactingToLostSales: supplier.reactingToLostSales,
        backorderThreshold: supplier.backorderThreshold,
        lostSalesReaction: supplier.lostSalesReaction,
        backordersReaction: supplier.backordersReaction,
        agendaManagedManually: supplier.agendaManagedManually,
        agendaManagedData: supplier.agendaManagedData,
        lostSalesMovReaction: supplier.lostSalesMovReaction,
        exportQuantityAsLots: supplier.exportQuantityAsLots,
        excludePriceFromExports: supplier.excludePriceFromExports,
        effectiveReplenishmentPeriod: supplier.effectiveReplenishmentPeriod,
        replenishmentPeriod: supplier.replenishmentPeriod,
        userReplenishmentPeriod: supplier.userReplenishmentPeriod,
        maxLoadCapacity: supplier.maxLoadCapacity,
        containerVolume: supplier.containerVolume,
        globalLocationNumber: supplier.globalLocationNumber,
        emails: supplier.emails,
        issues: supplier.issues,
        notes: supplier.notes,
        communicationMethod:
          supplier.communicationMethod as SupplierCommunication,
        cutoffHH: cutoffArray[0] ? cutoffArray[0] : null,
        cutoffMM: cutoffArray[1] ? cutoffArray[1] : null,
        extras: {
          tags: this.buildTags(supplier),
        },
      };
    });

    return {
      data: suppliers,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  buildTags(base: Supplier): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (base.communicationMethod === SupplierCommunications.EMAIL) {
      extras.iconTags.push({
        icon: 'envelope-xs',
        color: TagVariantColor.DEFAULT,
        tooltip: this.MESSAGES.common.email,
      });
    }

    if (base.communicationMethod === SupplierCommunications.EDI) {
      extras.iconTags.push({
        icon: 'edi-xs',
        color: TagVariantColor.DEFAULT,
        tooltip: this.MESSAGES.common.edi,
      });
    }

    if (
      base.issues?.includes('FILL_LEAD_TIME') ||
      base.issues?.includes('FILL_REPLENISHMENT_PERIOD')
    ) {
      extras.iconTextTags.push({
        icon: 'warning-rounded-xs',
        color: TagVariantColor.RED,
        tooltip:
          base.issues?.includes('FILL_LEAD_TIME') &&
          base.issues?.includes('FILL_REPLENISHMENT_PERIOD')
            ? this.STRINGS.FILL_LEAD_TIME +
              '\n' +
              this.STRINGS.FILL_REPLENISHMENT_PERIOD
            : base.issues?.includes('FILL_REPLENISHMENT_PERIOD')
              ? this.STRINGS.FILL_REPLENISHMENT_PERIOD
              : this.STRINGS.FILL_LEAD_TIME,
        text:
          (base.userReplenishmentPeriod === null ||
            base.userReplenishmentPeriod === 0) &&
          (base.deliveryTime === null || base.deliveryTime === 0)
            ? '2'
            : '',
      });
    }

    if (base.issues?.includes('FILL_FIXED_COSTS')) {
      extras.iconTextTags.push({
        icon: 'warning-triangle',
        color: TagVariantColor.ORANGE,
        tooltip: this.STRINGS.FILL_FIXED_COSTS,
        text: '',
      });
    }

    if (base.ignored) {
      extras.iconTags.push({
        icon: 'eye-closed',
        color: TagVariantColor.DEFAULT,
        tooltip: this.MESSAGES.common.ignored,
      });
    }

    return extras;
  }

  private _buildTimeZoneDate(cutoff: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const todayDateWebshopTimeZone = formatDateWithCutoffToWebshopTimezone(
      timeZoneIdentifier,
      cutoff
    );

    return DateTime.fromJSDate(new Date(todayDateWebshopTimeZone)).toFormat(
      'HH:mm:ss'
    );
  }
}
