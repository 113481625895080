import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-supply-chain-section-row',
  templateUrl: './supply-chain-section-row.component.html',
  styleUrls: ['./supply-chain-section-row.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainSectionRowComponent {
  @Input()
  disabled: boolean;

  constructor() {
    // intentional
  }
}
