import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { ExpansionPanelJobsComponent } from './expansion-panel-jobs.component';
import { ExpansionPanelJobsHeaderComponent } from './components/expansion-panel-jobs-header/expansion-panel-jobs-header.component';
import { ExpansionPanelJobsTitleDirective } from './directives/expansion-panel-jobs-title.directive';
import { ExpansionPanelJobsToggleDirective } from './directives/expansion-panel-jobs-toggle.directive';

@NgModule({
  declarations: [
    ExpansionPanelJobsComponent,
    ExpansionPanelJobsHeaderComponent,
    ExpansionPanelJobsTitleDirective,
    ExpansionPanelJobsToggleDirective,
  ],
  imports: [CommonModule, MatIconModule],
  exports: [
    ExpansionPanelJobsComponent,
    ExpansionPanelJobsHeaderComponent,
    ExpansionPanelJobsTitleDirective,
    ExpansionPanelJobsToggleDirective,
  ],
})
export class ExpansionPanelJobsModule {}
