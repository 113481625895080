export const dynamicColumnsWithStatusId = [
  {
    name: 'id',
    example: '2313123123',
    description: 'Supplier ID',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'number' },
  },
  {
    name: 'name',
    example: 'name_supplier_1',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'email',
    example: 'email_supplier_1',
    description: 'The email for this this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'minimumOrderValue',
    example: '33',
    description: 'The minimum order value for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'minimum order value must a number',
  },
  {
    name: 'fixedCosts',
    example: '33',
    description: 'The fixed costs for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'fixed costs must a number',
  },
  {
    name: 'deliveryTime',
    example: '33',
    description: 'The delivery time for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'delivery time  must a number',
  },
  {
    name: 'userReplenishmentPeriod',
    example: '33',
    description: 'The user replenishment period for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'user replenishment period must a number',
  },
  {
    name: 'reactingToLostSales',
    example: 'true',
    description:
      'When true, additional orders will be automatically scheduled when products are about to sell out.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'reacting to lost sales must be a boolean',
  },
  {
    name: 'backorderThreshold',
    example: '33',
    description: 'The backorder threshold for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorder threshold must be a number',
  },
  {
    name: 'lostSalesReaction',
    example: '33',
    description: 'The lost sales reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'lost sales reaction must be a number',
  },
  {
    name: 'backorders',
    example: 'true',
    description:
      'When true, additional back orders will be automatically scheduled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorders must be a boolean',
  },
  {
    name: 'backordersReaction',
    example: '33',
    description: 'The backorders reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorders reaction must a number',
  },
  {
    name: 'agendaManagedManually',
    example: 'true',
    description: 'When true, agenda managed manually will be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The agenda managed manually must be a boolean',
  },
  {
    name: 'agendaManagedData',
    example: 'agenda_Managed_Data_supplier_1',
    description: 'The agenda managed data for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'lostSalesMovReaction',
    example: '33',
    description: 'The lost sales mov reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'The lost sales mov reaction must a number',
  },
  {
    name: 'exportQuantityAsLots',
    example: 'true',
    description: 'When true, export quantity as lots will be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The export quantity as lots must be a boolean',
  },

  {
    name: 'excludePriceFromExports',
    example: 'true',
    description: 'When true, exclude price from exports be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The exclude price from exports must be a boolean',
  },
  {
    name: 'ignored',
    example: 'true',
    description:
      'When true the supplier will not be considered when purchasing.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'ignored must a boolean',
  },
  {
    name: 'maxLoadCapacity',
    example: '33',
    description: 'The maximum load capacity for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'Maximum load capacity must a number',
  },
  {
    name: 'containerVolume',
    example: '33',
    description: 'The container volume for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'container volume must a number',
  },
  {
    name: 'globalLocationNumber',
    example: 'global_Location_Number_supplier_1',
    description: 'The global location number for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'cutoff',
    example: '10:10:10',
    description: 'The cutoff for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
];

export const dynamicColumnsWithStatusName = [
  {
    name: 'name',
    example: 'name_supplier_1',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'email',
    example: 'email_supplier_1',
    description: 'The email for this this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'minimumOrderValue',
    example: '33',
    description: 'The minimum order value for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'minimum order value must a number',
  },
  {
    name: 'fixedCosts',
    example: '33',
    description: 'The fixed costs for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'fixed costs must a number',
  },
  {
    name: 'deliveryTime',
    example: '33',
    description: 'The delivery time for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'delivery time  must a number',
  },
  {
    name: 'userReplenishmentPeriod',
    example: '33',
    description: 'The user replenishment period for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'user replenishment period must a number',
  },
  {
    name: 'reactingToLostSales',
    example: 'true',
    description:
      'When true, additional orders will be automatically scheduled when products are about to sell out.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'reacting to lost sales must be a boolean',
  },
  {
    name: 'backorderThreshold',
    example: '33',
    description: 'The backorder threshold for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorder threshold must be a number',
  },
  {
    name: 'lostSalesReaction',
    example: '33',
    description: 'The lost sales reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'lost sales reaction a number',
  },
  {
    name: 'backorders',
    example: 'true',
    description:
      'When true, additional back orders will be automatically scheduled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorders must be a boolean',
  },
  {
    name: 'backordersReaction',
    example: '33',
    description: 'The backorders reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorders reaction must a number',
  },
  {
    name: 'agendaManagedManually',
    example: 'true',
    description: 'When true, agenda managed manually will be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The agenda managed manually must be a boolean',
  },

  {
    name: 'agendaManagedData',
    example: 'agenda_Managed_Data_supplier_1',
    description: 'The agenda managed data for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },

  {
    name: 'lostSalesMovReaction',
    example: '33',
    description: 'The lost sales mov reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'The lost sales mov reaction must a number',
  },
  {
    name: 'exportQuantityAsLots',
    example: 'true',
    description: 'When true, export quantity as lots will be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The export quantity as lots must be a boolean',
  },

  {
    name: 'excludePriceFromExports',
    example: 'true',
    description: 'When true, exclude price from exports be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The exclude price from exports must be a boolean',
  },
  {
    name: 'ignored',
    example: 'true',
    description:
      'When true the supplier will not be considered when purchasing.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'ignored must a boolean',
  },
  {
    name: 'maxLoadCapacity',
    example: '33',
    description: 'The maximum load capacity for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'Maximum load capacity must a number',
  },
  {
    name: 'containerVolume',
    example: '33',
    description: 'The container volume for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'container volume must a number',
  },
  {
    name: 'globalLocationNumber',
    example: 'global_Location_Number_supplier_1',
    description: 'The global location number for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'cutoff',
    example: '10:10:10',
    description: 'The cutoff for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
];

export const dynamicColumnsWithStatus = [
  {
    name: 'name',
    example: 'name_supplier_1',
    mustBeMatched: false,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'email',
    example: 'email_supplier_1',
    description: 'The email for this this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
  {
    name: 'minimumOrderValue',
    example: '33',
    description: 'The minimum order value for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'minimum order value must a number',
  },
  {
    name: 'fixedCosts',
    example: '33',
    description: 'The fixed costs for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'fixed costs must a number',
  },
  {
    name: 'deliveryTime',
    example: '33',
    description: 'The delivery time for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'delivery time  must a number',
  },
  {
    name: 'userReplenishmentPeriod',
    example: '33',
    description: 'The user replenishment period for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'user replenishment period must a number',
  },
  {
    name: 'reactingToLostSales',
    example: 'true',
    description:
      'When true, additional orders will be automatically scheduled when products are about to sell out.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'reacting to lost sales must be a boolean',
  },
  {
    name: 'backorderThreshold',
    example: '33',
    description: 'The backorder threshold for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorder threshold must be a number',
  },
  {
    name: 'lostSalesReaction',
    example: '33',
    description: 'The lost sales reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'lost sales reaction a number',
  },
  {
    name: 'backorders',
    example: 'true',
    description:
      'When true, additional back orders will be automatically scheduled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorders must be a boolean',
  },
  {
    name: 'backordersReaction',
    example: '33',
    description: 'The backorders reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'backorders reaction must a number',
  },
  {
    name: 'agendaManagedManually',
    example: 'true',
    description: 'When true, agenda managed manually will be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The agenda managed manually must be a boolean',
  },

  {
    name: 'agendaManagedData',
    example: 'agenda_Managed_Data_supplier_1',
    description: 'The agenda managed data for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },

  {
    name: 'lostSalesMovReaction',
    example: '33',
    description: 'The lost sales mov reaction for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'The lost sales mov reaction must a number',
  },

  {
    name: 'exportQuantityAsLots',
    example: 'true',
    description: 'When true, export quantity as lots will be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The export quantity as lots must be a boolean',
  },
  {
    name: 'excludePriceFromExports',
    example: 'true',
    description: 'When true, exclude price from exports be enabled.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage:
      'The exclude price from exports must be a boolean',
  },

  {
    name: 'ignored',
    example: 'true',
    description:
      'When true the supplier will not be considered when purchasing.',
    validationFormat: {
      type: 'select',
      format: 'true,false,True,False,TRUE,FALSE',
    },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'ignored must a boolean',
  },
  {
    name: 'maxLoadCapacity',
    example: '33',
    description: 'The maximum load capacity for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'Maximum load capacity must a number',
  },
  {
    name: 'containerVolume',
    example: '33',
    description: 'The container volume for this supplier.',
    validationFormat: { type: 'number' },
    valueCannotBeBlank: false,
    mustBeMatched: false,
    customValidationErrorMessage: 'container volume must a number',
  },
  {
    name: 'globalLocationNumber',
    example: 'global_Location_Number_supplier_1',
    description: 'The global location number for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },

  {
    name: 'cutoff',
    example: '10:10:10',
    description: 'The cutoff for this supplier.',
    mustBeMatched: false,
    valueCannotBeBlank: false,
    validationFormat: { type: 'string' },
  },
];
