import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { MaterialModule } from '../core/modules/material/material.module';

import { AddNewSupplierDialogComponent } from './components/add-new-supplier-dialog/add-new-supplier-dialog.component';
import { BasicContextNavBarComponent } from './components/basic-context-nav-bar/basic-context-nav-bar.component';
import { BasicFilterComponent } from './components/basic-filter/basic-filter.component';
import { CreateUpdatePromotionDialogComponent } from './components/create-update-promotion-dialog/create-update-promotion-dialog.component';
import { ExportAsComponent } from './components/export-as/export-as.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { SupplierSettingsComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-settings/supplier-settings.component';
import { WebshopDataTableComponent } from './components/webshop-data-table/webshop-data-table.component';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomDecimalPipe } from './pipes/custom-decimal.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SettingsSectionComponent } from './components/settings-section/settings-section.component';
import { CurrencyStyleDirective } from './directives/currency-style.directive';
import { BaseConfirmationDialogComponent } from './components/base-confirmation-dialog/base-confirmation-dialog.component';
import { SimpleConfirmationDialogComponent } from './components/simple-confirmation-dialog/simple-confirmation-dialog.component';
import { OverlayContainerComponent } from './components/overlay-container/overlay-container.component';
import { SupplierDetailsComponent } from '../features/suppliers-v2/component/supplier-details/default/supplier-details.component';
import { RouterModule } from '@angular/router';
import { ProductCategoryComponent } from './components/product-category/product-category.component';
import { RenameExportDialogComponent } from './components/rename-export-dialog/rename-export-dialog.component';
import { SearchComponent } from './components/search/search.component';
import { PortalModule } from '@angular/cdk/portal';
import { TopOverviewRowComponent } from './components/top-overview/component/top-overview-row/top-overview-row.component';
import { TopOverviewIconComponent } from './components/top-overview-icon/top-overview-icon.component';
import { BasicContextNavBarV2Component } from './components/basic-context-nav-bar-v2/basic-context-nav-bar-v2.component';
import { BasicContextNavBarV2ActionsComponent } from './components/basic-context-nav-bar-v2/components/basic-context-nav-bar-v2-actions/basic-context-nav-bar-v2-actions.component';
import { GoBackPipe } from './components/basic-context-nav-bar-v2/pipes/go-back.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { JobStatusPipe } from './pipes/job-status.pipe';
import { OrderEnlargerV2Component } from './components/order-enlarger-v2/order-enlarger-v2.component';
import { ReliabilityComponent } from './components/reliability/reliability.component';
import { SnoozeProductDialogComponent } from './components/snooze-product-dialog/snooze-product-dialog.component';
import { ConnectProductToSupplierDialogComponent } from './components/connect-product-to-supplier-dialog/connect-product-to-supplier-dialog.component';
import { AddSourceDialogComponent } from './components/add-source-dialog/add-source-dialog.component';
import { BannerComponent } from './components/design-system/banner/banner.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { AddProductsToPromotionDialogComponent } from './components/promotion-details/components/add-products-to-promotion-dialog/add-products-to-promotion-dialog.component';
import { AddProductsToPromotionDataTableComponent } from './components/promotion-details/components/add-products-to-promotion-data-table/add-products-to-promotion-data-table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { InviteUsersDialogComponent } from './components/invite-users-dialog/invite-users-dialog.component';
import { ProductDeliveriesV2Component } from './components/product-details-v2/components/product-deliveries-v2/product-deliveries-v2.component';
import { ProductDeliveriesV2DataTableComponent } from './components/product-details-v2/components/product-deliveries-v2/components/product-deliveries-v2-data-table/default/product-deliveries-v2-data-table.component';
import { ProductPromotionsV2Component } from './components/product-details-v2/components/product-promotions-v2/product-promotions-v2.component';
import { ProductPromotionsV2DataTableComponent } from './components/product-details-v2/components/product-promotions-v2/components/product-promotions-v2-data-table/default/product-promotions-v2-data-table.component';
import { NavBarV2ContentDirective } from './components/basic-context-nav-bar-v2/directives/nav-bar-v2-content.directive';
import { ProductOverviewV2Component } from './components/product-details-v2/components/product-overview-v2/product-overview-v2.component';
import { ProductContainerComponent } from './components/product-details-v2/components/container/container.component';
import { ProductSettingsV2Component } from './components/product-details-v2/components/product-settings-v2/product-settings-v2.component';
import { SupplyChainInformationV2Component } from './components/product-details-v2/components/supply-chain-information-v2/supply-chain-information-v2.component';
import { SupplyChainActiveSupplierV2Component } from './components/product-details-v2/components/supply-chain-information-v2/components/supply-chain-active-supplier-v2/supply-chain-active-supplier-v2.component';
import { SupplyChainSectionContainerComponent } from './components/product-details-v2/components/supply-chain-information-v2/components/supply-chain-section-container/supply-chain-section-container.component';
import { SupplyChainSectionRowComponent } from './components/product-details-v2/components/supply-chain-information-v2/components/supply-chain-section-row/supply-chain-section-row.component';
import { SupplyChainPurchasingAdviceV2Component } from './components/product-details-v2/components/supply-chain-information-v2/components/supply-chain-purchasing-advice-v2/supply-chain-purchasing-advice-v2.component';
import { SupplyChainProductV2Component } from './components/product-details-v2/components/supply-chain-information-v2/components/supply-chain-product-v2/supply-chain-product-v2.component';
import { SalesV2Component } from './components/product-details-v2/components/sales-v2/sales-v2.component';
import { SalesV2FilterComponent } from './components/product-details-v2/components/sales-v2/components/sales-v2-filter/sales-v2-filter.component';
import { ProductCompositionsV2Component } from './components/product-details-v2/components/product-compositions-v2/product-compositions-v2.component';
import { ProductCompositionV2DataTableComponent } from './components/product-details-v2/components/product-compositions-v2/components/product-composition-v2-data-table/product-composition-v2-data-table.component';
import { ProductPartsV2Component } from './components/product-details-v2/components/product-parts-v2/product-parts-v2.component';
import { ProductPartsV2DataTableComponent } from './components/product-details-v2/components/product-parts-v2/components/product-parts-v2-data-table/product-parts-v2-data-table.component';
import { WarningBannerComponent } from './components/product-details-v2/components/warning-banner/warning-banner.component';
import { ProductSuppliersV2Component } from './components/product-details-v2/components/product-suppliers-v2/product-suppliers-v2.component';
import { ProductSuppliersV2DataTableComponent } from './components/product-details-v2/components/product-suppliers-v2/components/product-suppliers-v2-data-table/default/product-suppliers-v2-data-table.component';
import { StockTimePipe } from '../features/products-v2/pipes/stock-time.pipe';
import { ExitConfirmationDialogComponent } from './components/exit-confirmation-dialog/exit-confirmation-dialog.component';
import { LastMinutesAdviceConversionPipe } from './pipes/last-minutes-advice-conversion.pipe';
import { PurchaseCheckoutDialogComponent } from './components/purchase-checkout-dialog/purchase-checkout-dialog.component';
import { ToastModule } from './components/design-system/toast/toast.module';
import { CheckboxModule } from './components/design-system/checkbox/checkbox.module';
import { DropdownModule } from './components/design-system/dropdown/dropdown.module';
import { TooltipModule } from './components/design-system/tooltip/tooltip.module';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { DialogContainerModule } from './components/dialog-container/dialog-container.module';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { ExportsDialogComponent } from './components/exports-dialog/exports-dialog.component';
import { AddProductPromotionDialogComponent } from './components/product-details-v2/components/product-promotions-v2/components/add-product-promotion/add-product-promotion-dialog.component';
import { AddProductSupplierDialogComponent } from './components/product-details-v2/components/product-suppliers-v2/components/add-product-supplier-dialog/add-product-supplier-dialog.component';
import { AddMultipleProductsToAPromotionDialogComponent } from '../features/products-v2/components/add-multiple-products-to-a-promotion-dialog/add-multiple-products-to-a-promotion-dialog.component';
import { ProductLinkDialogComponent } from './components/product-details-v2/components/product-link-dialog/product-link-dialog.component';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { RadioButtonModule } from './components/design-system/radio-button/radio-button.module';
import { ExpansionPanelModule } from './components/design-system/expansion-panel/expansion-panel.module';
import { StepperModule } from './components/design-system/stepper/stepper.module';
import { ToggleModule } from './components/design-system/toggle/toggle.module';
import { TagsModule } from './components/design-system/tags/tags.module';
import { TabsModule } from './components/design-system/tabs/tabs.module';
import { CopyToClipboardModule } from './components/design-system/copy-to-clipboard/copy-to-clipboard.module';
import { OverlayContainerV2Module } from './components/design-system/overlay-container-v2/overlay-container-v2.module';
import { DataTableV2Module } from './components/design-system/data-table-v2/data-table-v2.module';
import { FilterPipe as AsyncFilterPipe } from './pipes/filter.pipe';
import { GravatarDirective } from './directives/gravatar.directive';
import { ProgressBarModule } from './components/design-system/progress-bar/progress-bar.module';
import { ProgressSpinnerModule } from './components/design-system/progress-spinner/progress-spinner.module';
import { MatIconModule } from '@angular/material/icon';
import { ImportsDialogComponent } from './components/imports-dialog/imports-dialog.component';
import { UsecsvAngularPluginModule } from '@usecsv/angular';
import { UsecsvDialogComponent } from './components/usecsv-dialog/usecsv-dialog.component';
import { ButtonModule } from './components/design-system/button/button.module';
import { InputModule } from './components/design-system/input/input.module';
import { PopoutWindowComponent } from './components/popout-window/popout-window.component';
import { LoadingSourcesComponent } from './components/loading-sources/loading-sources.component';
import { NoSourcesComponent } from './components/no-sources/no-sources.component';
import { SourceCredentialsComponent } from './components/source-credentials/source-credentials.component';
import { RemoveSourceComponent } from './components/remove-source/remove-source.component';
import { SourceJobsComponent } from './components/source-jobs/source-jobs.component';
import { AddSourceCredentialsComponent } from './components/add-source-credentials/add-source-credentials.component';
import { PurchaseAgendaComponent } from './components/purchase-confirmation-dialog-v2/components/purchase-agenda/purchase-agenda.component';
import { PurchaseExportsComponent } from './components/purchase-confirmation-dialog-v2/components/purchase-exports/purchase-exports.component';
import { PurchaseSupplierSettingsComponent } from './components/purchase-confirmation-dialog-v2/components/purchase-supplier-settings/purchase-supplier-settings.component';
import { BasicCopyToClipboardComponent } from './components/basic-copy-to-clipboard/basic-copy-to-clipboard.component';
import { TagsInputModule } from './components/tags-input/tags-input.module';
import { SupplierCalendarComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-calendar/supplier-calendar.component';
import { SupplierGeneralComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-general/supplier-general.component';
import { SupplierContainerComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-container/supplier-container.component';
import { SupplierPlanningComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-planning/supplier-planning.component';
import { SupplierOrderInformationComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-order-information/supplier-order-information.component';
import { SupplierProductsDataTableComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-products/components/supplier-products-data-table/default/supplier-products-data-table.component';
import { SupplierAdvancedSettingsComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-advanced-settings/supplier-advanced-settings.component';
import { SupplierProductsComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-products/supplier-products.component';
import { SupplierDetailsTabsComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-details-tabs/supplier-details-tabs.component';
import { RequiredLabelDirective } from './directives/required-label.directive';
import { RadioButtonV2Module } from './components/design-system/radio-button-v2/radio-button-v2.module';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS } from '../core/constants/date-formats.constants';
import { AdvancedSettingsModule } from './components/advanced-settings/advanced-settings.module';
import { PurchaseExportsSettingsComponent } from './components/purchase-confirmation-dialog-v2/components/purchase-exports-settings/purchase-exports-settings.component';
import { SupplierDetailsOverviewComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-details-overview/supplier-details-overview.component';
import { SupplierDetailsOverviewNotesComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-details-overview/components/supplier-details-overview-notes/supplier-details-overview-notes.component';
import { ProductDetailsOverviewComponent } from './components/product-details-v2/components/product-details-overview/product-details-overview.component';
import { ProductDetailsOverviewNotesComponent } from './components/product-details-v2/components/product-details-overview/components/product-details-overview-notes/product-details-overview-notes.component';
import { ProductInformationV2Component } from './components/product-details-v2/components/product-information-v2/product-information-v2.component';
import { ExpansionPanelJobsModule } from './components/expansion-panel-jobs/expansion-panel-jobs.module';
import { ImportSuppliersDialogComponent } from './components/import-suppliers-dialog/import-suppliers-dialog.component';
import { ImportSuppliersUpdateDialogComponent } from './components/import-suppliers-dialog/components/import-suppliers-update-dialog/import-suppliers-update-dialog.component';
import { SupplierDetailsViewOnlyComponent } from '../features/suppliers-v2/component/supplier-details/view-only/supplier-details-view-only.component';
import { ProductSuppliersV2DataTableViewOnlyComponent } from './components/product-details-v2/components/product-suppliers-v2/components/product-suppliers-v2-data-table/view-only/product-suppliers-v2-data-table-view-only.component';
import { ProductDeliveriesV2DataTableViewOnlyComponent } from './components/product-details-v2/components/product-deliveries-v2/components/product-deliveries-v2-data-table/view-only/product-deliveries-v2-data-table-view-only.component';
import { ProductPromotionsV2DataTableViewOnlyComponent } from './components/product-details-v2/components/product-promotions-v2/components/product-promotions-v2-data-table/view-only/product-promotions-v2-data-table-view-only.component';
import { SupplierDetailsWrapperComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-details-wrapper/supplier-details-wrapper.component';
import { SupplierEmailSettingsComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-communication-settings/components/supplier-email-settings/supplier-email-settings.component';
import { Datetime24FormatPipe } from './pipes/datetime-24-format.pipe';
import { EmailTemplatesV2Component } from './components/email-templates-v2/email-templates-v2.component';
import { PurchaseConfirmationDialogV2Component } from './components/purchase-confirmation-dialog-v2/purchase-confirmation-dialog-v2.component';
import { EmailManagementComponent } from './components/email-management/email-management.component';
import { SupplierCommunicationSettingsComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-communication-settings/supplier-communication-settings.component';
import { SupplierCommunicationOrdersComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-communication-settings/components/supplier-communication-orders/supplier-communication-orders.component';
import { SupplierGlnSettingsComponent } from '../features/suppliers-v2/component/supplier-details/components/supplier-communication-settings/components/supplier-gln-settings/supplier-gln-settings.component';

@NgModule({
  declarations: [
    BasicFilterComponent,
    WebshopDataTableComponent,
    AsyncFilterPipe,
    SearchFilterPipe,
    CustomCurrencyPipe,
    GravatarDirective,
    BasicContextNavBarComponent,
    LoadingButtonComponent,
    AddNewSupplierDialogComponent,
    CustomDecimalPipe,
    ExportAsComponent,
    CreateUpdatePromotionDialogComponent,
    HighlightSearchPipe,
    ConfirmationDialogComponent,
    CurrencyStyleDirective,
    BaseConfirmationDialogComponent,
    SimpleConfirmationDialogComponent,
    OverlayContainerComponent,
    ProductCategoryComponent,
    ExportsDialogComponent,
    RenameExportDialogComponent,
    SearchComponent,
    ConnectProductToSupplierDialogComponent,
    TopOverviewRowComponent,
    TopOverviewIconComponent,
    BasicContextNavBarV2Component,
    BasicContextNavBarV2ActionsComponent,
    GoBackPipe,
    JobStatusPipe,
    OrderEnlargerV2Component,
    ReliabilityComponent,
    SnoozeProductDialogComponent,
    AddSourceDialogComponent,
    BannerComponent,
    AddProductsToPromotionDialogComponent,
    AddProductsToPromotionDataTableComponent,
    ProductDeliveriesV2Component,
    ProductDeliveriesV2DataTableComponent,
    ProductDeliveriesV2DataTableViewOnlyComponent,
    InviteUsersDialogComponent,
    ProductPromotionsV2Component,
    ProductPromotionsV2DataTableComponent,
    ProductPromotionsV2DataTableViewOnlyComponent,
    PurchaseCheckoutDialogComponent,
    LastMinutesAdviceConversionPipe,
    ExitConfirmationDialogComponent,
    NavBarV2ContentDirective,
    ProductOverviewV2Component,
    ProductContainerComponent,
    ProductSettingsV2Component,
    SupplyChainInformationV2Component,
    SupplyChainActiveSupplierV2Component,
    SupplyChainSectionContainerComponent,
    SupplyChainSectionRowComponent,
    SupplyChainPurchasingAdviceV2Component,
    SupplyChainProductV2Component,
    SalesV2Component,
    SalesV2FilterComponent,
    ProductCompositionsV2Component,
    ProductCompositionV2DataTableComponent,
    ProductPartsV2Component,
    ProductPartsV2DataTableComponent,
    WarningBannerComponent,
    ProductSuppliersV2Component,
    ProductSuppliersV2DataTableComponent,
    ProductSuppliersV2DataTableViewOnlyComponent,
    ProductInformationV2Component,
    StockTimePipe,
    DialogContainerComponent,
    AddProductPromotionDialogComponent,
    AddProductSupplierDialogComponent,
    AddMultipleProductsToAPromotionDialogComponent,
    ChangePasswordDialogComponent,
    DialogContainerComponent,
    ProductLinkDialogComponent,
    ImportsDialogComponent,
    ImportSuppliersDialogComponent,
    ImportSuppliersUpdateDialogComponent,
    UsecsvDialogComponent,
    PopoutWindowComponent,
    LoadingSourcesComponent,
    NoSourcesComponent,
    SourceCredentialsComponent,
    RemoveSourceComponent,
    SourceJobsComponent,
    AddSourceCredentialsComponent,
    PurchaseAgendaComponent,
    PurchaseExportsComponent,
    PurchaseSupplierSettingsComponent,
    PurchaseExportsSettingsComponent,
    BasicCopyToClipboardComponent,
    ProductDetailsOverviewComponent,
    ProductDetailsOverviewNotesComponent,
    Datetime24FormatPipe,
    PurchaseConfirmationDialogV2Component,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxEchartsModule.forRoot({ echarts }),
    MatExpansionModule,
    RouterModule,
    FormsModule,
    PortalModule,
    CdkTableModule,
    ToastModule,
    CheckboxModule,
    TagsModule,
    DropdownModule,
    TooltipModule,
    DialogContainerModule,
    RadioButtonModule,
    ExpansionPanelModule,
    ExpansionPanelJobsModule,
    ToggleModule,
    TabsModule,
    CopyToClipboardModule,
    OverlayContainerV2Module,
    DataTableV2Module,
    StepperModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    UsecsvAngularPluginModule,
    ButtonModule,
    InputModule,
    TagsInputModule,
    RequiredLabelDirective,
    RadioButtonV2Module,
    AdvancedSettingsModule,
    SentenceCasePipe,
    EmailTemplatesV2Component,
    EmailManagementComponent,
    SettingsSectionComponent,
  ],
  exports: [
    BasicFilterComponent,
    WebshopDataTableComponent,
    AsyncFilterPipe,
    SearchFilterPipe,
    GravatarDirective,
    BasicContextNavBarComponent,
    CustomCurrencyPipe,
    LoadingButtonComponent,
    CustomDecimalPipe,
    ExportAsComponent,
    CurrencyStyleDirective,
    OverlayContainerComponent,
    ProductCategoryComponent,
    SearchComponent,
    BasicContextNavBarV2Component,
    BasicContextNavBarV2ActionsComponent,
    OrderEnlargerV2Component,
    JobStatusPipe,
    ReliabilityComponent,
    BannerComponent,
    LastMinutesAdviceConversionPipe,
    NavBarV2ContentDirective,
    ProductOverviewV2Component,
    ProductContainerComponent,
    ProductSettingsV2Component,
    SupplyChainInformationV2Component,
    SalesV2Component,
    ProductCompositionsV2Component,
    ProductPartsV2Component,
    WarningBannerComponent,
    ProductDeliveriesV2Component,
    ProductPromotionsV2Component,
    ProductSuppliersV2Component,
    StockTimePipe,
    HighlightSearchPipe,
    DialogContainerComponent,
    PopoutWindowComponent,
    LoadingSourcesComponent,
    NoSourcesComponent,
    SourceCredentialsComponent,
    RemoveSourceComponent,
    SourceJobsComponent,
    AddSourceCredentialsComponent,
    ProductDetailsOverviewComponent,
    ProductDetailsOverviewNotesComponent,
    ProductInformationV2Component,
    BasicCopyToClipboardComponent,
    PurchaseExportsSettingsComponent,
    Datetime24FormatPipe,
    PurchaseSupplierSettingsComponent,
  ],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class SharedModule {}
