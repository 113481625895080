import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Inject,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Actions,
  ofActionDispatched,
  ofActionErrored,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import {
  AddProductsToAPromotionData,
  Promotion,
} from 'src/app/shared/models/promotion/promotion.model';
import {
  AddMultipleProductsToPromotion,
  LoadPromotionsFiltered,
} from 'src/app/features/promotions-v2/actions/promotions-v2.actions';
import { PromotionsV2StateQueries } from 'src/app/features/promotions-v2/state/promotions-v2.queries';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-add-multiple-products-to-a-promotion-dialog',
  templateUrl: './add-multiple-products-to-a-promotion-dialog.component.html',
  styleUrls: ['./add-multiple-products-to-a-promotion-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMultipleProductsToAPromotionDialogComponent
  implements OnInit, OnDestroy
{
  private destroy$ = new Subject<void>();
  readonly PROMOTION_TYPES = ['Regular', 'Absolute'];

  addPromotionForm = new UntypedFormGroup({
    promotion: new UntypedFormControl('', [Validators.required]),
  });

  saveAddPromotionDisabled$: Subject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  savingAddPromotion$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  promotions: Promotion[] = [];

  @Select(PromotionsV2StateQueries.promotionsFiltered)
  filteredPromotions$: Observable<Promotion[]>;

  readonly COMMON_STRINGS = MESSAGES.common;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: AddProductsToAPromotionData,
    private store: Store,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(
        ofActionDispatched(AddMultipleProductsToPromotion),
        takeUntil(this.destroy$),
        tap(_ => this.savingAddPromotion$.next(true))
      )
      .subscribe();

    this.actions
      .pipe(
        ofActionSuccessful(AddMultipleProductsToPromotion),
        takeUntil(this.destroy$)
      )
      .subscribe(_ => this.savingAddPromotion$.next(false));

    this.actions
      .pipe(
        ofActionErrored(AddMultipleProductsToPromotion),
        takeUntil(this.destroy$)
      )
      .subscribe(_ => this.savingAddPromotion$.next(false));

    this.addPromotionForm.statusChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(status => {
        if (status === 'INVALID') {
          this.saveAddPromotionDisabled$.next(true);
        } else {
          this.saveAddPromotionDisabled$.next(false);
        }
      });
  }

  addProducts(): void {
    this.store.dispatch(
      new AddMultipleProductsToPromotion(
        this.selectedPromotion.uuid,
        this.data.productsToAdd
      )
    );
  }

  displayPromotionName(promotion: Promotion): string {
    return promotion?.name ? promotion?.name : '';
  }

  filterPromotions(search: string) {
    this.store.dispatch(new LoadPromotionsFiltered(search));
  }

  close() {
    this.dialogRef.close();
  }

  get selectedPromotion(): Promotion {
    return this.addPromotionForm.get('promotion').value;
  }

  ngOnDestroy(): void {
    this.saveAddPromotionDisabled$.complete();
    this.savingAddPromotion$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
