import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, delay, exhaustMap, tap } from 'rxjs/operators';
import {
  AccountSettings,
  AccountSettingsUpdate,
  ChatState,
  ChatStates,
  ColorTheme,
  ColorThemes,
  MenuState,
  MenuStates,
  ProductInformationSalesGraphGroups,
  ProductInformationSalesGraphHistoryLengths,
  ProductInformationSalesGraphSettings,
  ServiceLevelGraphCategory,
  TableSettings,
} from 'src/app/shared/models/account/account-settings.model';
import { SalesGraphSettings } from 'src/app/shared/models/sales/sales.model';
import {
  SaveFailed,
  SaveSucceed,
  Saving,
  ToggleIntercom,
} from '../actions/app.action';
import { ChangeLocale } from '../actions/locale.action';

import {
  LastSelectedWebshopUpdated,
  LoadAccountSettings,
  SaveMenuState,
  UpdateAccountLanguage,
  UpdateAccountSettings,
  UpdateColorTheme,
} from '../actions/settings.action';
import { StockLevelLegendSelectedChanged } from '../actions/stock-level.action';
import { AccountSettingsService } from '../api/account/v2/account-settings.service';
import { DEFAULT_COLOR_THEME } from '../constants/global.constants';
import { ThemeService } from '../services/theme/theme.service';
import { AccountState } from './account.state';
import { SetInitialMenuV2State } from 'src/app/features/home/actions/nav-menu-v2.actions';

export interface AccountSettingsStateModel {
  colorTheme: ColorTheme;
  lastWebshop: string | null;
  localeId: string;
  preferredEmailLanguage: string;
  chatState: ChatState;
  serviceLevelGraphExcludedCategories: ServiceLevelGraphCategory[];
  generalMenuVisibility: MenuState;
  productInformationSalesGraphSettings: ProductInformationSalesGraphSettings;
  overdueDeliveriesTableSettings: TableSettings;
  futureDeliveriesTableSettings: TableSettings;
  completedDeliveriesTableSettings: TableSettings;
  deliveryEditorUndeliveredProductsTableSettings: TableSettings;
  deliveryEditorDeliveredProductsTableSettings: TableSettings;
  productInformationDeliveriesTableSettings: TableSettings;
  promotionsEditorTableSettings: TableSettings;
  productInformationPromotionsTableSettings: TableSettings;
  plannedOrdersV2TableSettings: TableSettings;
  draftOrdersV2TableSettings: TableSettings;
  proposedOrderEditorProposedV2TableSettings: TableSettings;
  proposedOrderEditorAvailableV2TableSettings: TableSettings;
  draftOrderEditorProposedV2TableSettings: TableSettings;
  draftOrderEditorAvailableV2TableSettings: TableSettings;
  productsEditorTableSettings: TableSettings;
  productInformationCompositionsTableSettings: TableSettings;
  productInformationPartsTableSettings: TableSettings;
  productInformationSupplierProductsTableSettings: TableSettings;
  promotionWebshopProductsEditorTableSettings: TableSettings;
  suppliersEditorTableSettings: TableSettings;
  importsTableSettings: TableSettings;
  supplierInformationSupplierProductsTableSettings: TableSettings;
  proposedOrderEditorHeaderDropdownSelection: string[];
  draftOrderEditorHeaderDropdownSelection: string[];
  purchaseCheckoutDoNotAskAgain: boolean;
}

const defaultTableSettings: TableSettings = {
  excludedColumns: [],
  sortBy: null,
  orderBy: null,
  pageSize: 50,
};

@State<AccountSettingsStateModel>({
  name: 'accountSettingsState',
  defaults: {
    colorTheme: DEFAULT_COLOR_THEME,
    lastWebshop: null,
    localeId: 'en-US',
    preferredEmailLanguage: 'en',
    chatState: ChatStates.OPENED,
    serviceLevelGraphExcludedCategories: [],
    generalMenuVisibility: MenuStates.COLLAPSED,
    productInformationSalesGraphSettings: {
      historyLength: ProductInformationSalesGraphHistoryLengths.LAST_MONTH,
      grouping: ProductInformationSalesGraphGroups.DAILY,
    },
    overdueDeliveriesTableSettings: defaultTableSettings,
    futureDeliveriesTableSettings: defaultTableSettings,
    completedDeliveriesTableSettings: defaultTableSettings,
    deliveryEditorUndeliveredProductsTableSettings: defaultTableSettings,
    deliveryEditorDeliveredProductsTableSettings: defaultTableSettings,
    productInformationDeliveriesTableSettings: defaultTableSettings,
    promotionsEditorTableSettings: defaultTableSettings,
    productInformationPromotionsTableSettings: defaultTableSettings,
    plannedOrdersV2TableSettings: defaultTableSettings,
    draftOrdersV2TableSettings: defaultTableSettings,
    proposedOrderEditorProposedV2TableSettings: defaultTableSettings,
    proposedOrderEditorAvailableV2TableSettings: defaultTableSettings,
    draftOrderEditorProposedV2TableSettings: defaultTableSettings,
    draftOrderEditorAvailableV2TableSettings: defaultTableSettings,
    productsEditorTableSettings: defaultTableSettings,
    productInformationCompositionsTableSettings: defaultTableSettings,
    productInformationPartsTableSettings: defaultTableSettings,
    productInformationSupplierProductsTableSettings: defaultTableSettings,
    promotionWebshopProductsEditorTableSettings: defaultTableSettings,
    suppliersEditorTableSettings: defaultTableSettings,
    importsTableSettings: defaultTableSettings,
    supplierInformationSupplierProductsTableSettings: defaultTableSettings,
    proposedOrderEditorHeaderDropdownSelection: [],
    draftOrderEditorHeaderDropdownSelection: [],
    purchaseCheckoutDoNotAskAgain: false,
  },
})
@Injectable()
export class AccountSettingsState {
  private readonly UPDATE_DELAY = 1000;

  constructor(
    private accountSettingsService: AccountSettingsService,
    private theme: ThemeService,
    private store: Store
  ) {}

  @Selector()
  static lastWebshop(state: AccountSettingsStateModel): string | null {
    return state.lastWebshop;
  }

  @Selector()
  static localeId(state: AccountSettingsStateModel): string {
    return state.localeId;
  }

  @Selector()
  static colorTheme(state: AccountSettingsStateModel): ColorTheme {
    return state.colorTheme;
  }

  @Selector()
  static colorThemeLowercase(state: AccountSettingsStateModel): string {
    return state.colorTheme.toLowerCase();
  }

  @Selector()
  static darkModeEnabled(state: AccountSettingsStateModel): boolean {
    return state.colorTheme === ColorThemes.DARK;
  }

  @Selector()
  static preferredEmailLanguage(state: AccountSettingsStateModel): string {
    return state.preferredEmailLanguage;
  }

  @Selector()
  static chatOpened(state: AccountSettingsStateModel): boolean {
    return state.chatState === ChatStates.OPENED;
  }

  @Selector()
  static serviceLevelGraphExcludedCategories(
    state: AccountSettingsStateModel
  ): ServiceLevelGraphCategory[] {
    return state.serviceLevelGraphExcludedCategories;
  }

  @Selector()
  static productInformationSalesGraph(
    state: AccountSettingsStateModel
  ): SalesGraphSettings {
    return state.productInformationSalesGraphSettings;
  }

  @Selector()
  static overdueDeliveriesTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.overdueDeliveriesTableSettings;
  }

  @Selector()
  static futureDeliveriesTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.futureDeliveriesTableSettings;
  }

  @Selector()
  static completedDeliveriesTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.completedDeliveriesTableSettings;
  }

  @Selector()
  static deliveryEditorUndeliveredProductsTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.deliveryEditorUndeliveredProductsTableSettings;
  }

  @Selector()
  static deliveryEditorDeliveredProductsTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.deliveryEditorDeliveredProductsTableSettings;
  }

  @Selector()
  static productInformationDeliveriesTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.productInformationDeliveriesTableSettings;
  }

  @Selector()
  static promotionsEditorTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.promotionsEditorTableSettings;
  }

  @Selector()
  static productInformationPromotionsTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.productInformationPromotionsTableSettings;
  }

  @Selector()
  static plannedOrdersV2TableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.plannedOrdersV2TableSettings;
  }

  @Selector()
  static draftOrdersV2TableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.draftOrdersV2TableSettings;
  }

  @Selector()
  static proposedOrderEditorProposedV2TableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.proposedOrderEditorProposedV2TableSettings;
  }

  @Selector()
  static proposedOrderEditorAvailableV2TableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.proposedOrderEditorAvailableV2TableSettings;
  }

  @Selector()
  static draftOrderEditorProposedV2TableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.draftOrderEditorProposedV2TableSettings;
  }

  @Selector()
  static draftOrderEditorAvailableV2TableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.draftOrderEditorAvailableV2TableSettings;
  }

  @Selector()
  static productsEditorTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.productsEditorTableSettings;
  }

  @Selector()
  static productInformationCompositionsTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.productInformationCompositionsTableSettings;
  }

  @Selector()
  static productInformationPartsTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.productInformationPartsTableSettings;
  }

  @Selector()
  static productInformationSupplierProductsTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.productInformationSupplierProductsTableSettings;
  }

  @Selector()
  static promotionWebshopProductsEditorTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.promotionWebshopProductsEditorTableSettings;
  }

  @Selector()
  static suppliersEditorTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.suppliersEditorTableSettings;
  }

  @Selector()
  static supplierInformationSupplierProductsTableSettings(
    state: AccountSettingsStateModel
  ): TableSettings {
    return state.supplierInformationSupplierProductsTableSettings;
  }

  @Selector()
  static proposedOrderEditorHeaderSettings(
    state: AccountSettingsStateModel
  ): string[] {
    return state.proposedOrderEditorHeaderDropdownSelection;
  }

  @Selector()
  static draftOrderEditorHeaderSettings(
    state: AccountSettingsStateModel
  ): string[] {
    return state.draftOrderEditorHeaderDropdownSelection;
  }

  @Selector()
  static importsTableSettings(state: AccountSettingsStateModel): TableSettings {
    return state.importsTableSettings;
  }

  @Selector()
  static skipCheckout(state: AccountSettingsStateModel): boolean {
    return state.purchaseCheckoutDoNotAskAgain;
  }

  @Action(LoadAccountSettings)
  loadSettings(
    ctx: StateContext<AccountSettingsStateModel>,
    action: LoadAccountSettings
  ) {
    return this.accountSettingsService.fetchSettings(action.userUUID).pipe(
      tap(settings => {
        this._updateIntercomVisibility(
          settings.chatState === ChatStates.CLOSED
        );
        ctx.patchState({
          colorTheme: settings.colorTheme,
          lastWebshop: settings.lastWebshop,
          localeId: settings.language.localeId,
          preferredEmailLanguage: settings.preferredEmailLanguage,
          chatState: settings.chatState || ChatStates.OPENED,
          serviceLevelGraphExcludedCategories:
            settings.serviceLevelGraphExcludedCategories,
          generalMenuVisibility:
            settings.generalMenuVisibility || MenuStates.COLLAPSED,
          productInformationSalesGraphSettings:
            settings.productInformationSalesGraphSettings,
          overdueDeliveriesTableSettings:
            settings.overdueDeliveriesTableSettings,
          futureDeliveriesTableSettings: settings.futureDeliveriesTableSettings,
          completedDeliveriesTableSettings:
            settings.completedDeliveriesTableSettings,
          deliveryEditorUndeliveredProductsTableSettings:
            settings.deliveryEditorUndeliveredProductsTableSettings,
          deliveryEditorDeliveredProductsTableSettings:
            settings.deliveryEditorDeliveredProductsTableSettings,
          productInformationDeliveriesTableSettings:
            settings.productInformationDeliveriesTableSettings,
          promotionsEditorTableSettings: settings.promotionsEditorTableSettings,
          productInformationPromotionsTableSettings:
            settings.productInformationPromotionsTableSettings,
          plannedOrdersV2TableSettings: settings.plannedOrdersV2TableSettings,
          draftOrdersV2TableSettings: settings.draftOrdersV2TableSettings,
          proposedOrderEditorProposedV2TableSettings:
            settings.proposedOrderEditorProposedV2TableSettings,
          proposedOrderEditorAvailableV2TableSettings:
            settings.proposedOrderEditorAvailableV2TableSettings,
          draftOrderEditorProposedV2TableSettings:
            settings.draftOrderEditorProposedV2TableSettings,
          draftOrderEditorAvailableV2TableSettings:
            settings.draftOrderEditorAvailableV2TableSettings,
          productsEditorTableSettings: settings.productsEditorTableSettings,
          productInformationCompositionsTableSettings:
            settings.productInformationCompositionsTableSettings,
          productInformationPartsTableSettings:
            settings.productInformationPartsTableSettings,
          productInformationSupplierProductsTableSettings:
            settings.productInformationSupplierProductsTableSettings,
          promotionWebshopProductsEditorTableSettings:
            settings.promotionWebshopProductsEditorTableSettings,
          suppliersEditorTableSettings: settings.suppliersEditorTableSettings,
          draftOrderEditorHeaderDropdownSelection:
            settings.draftOrderEditorHeaderDropdownSelection,
          proposedOrderEditorHeaderDropdownSelection:
            settings.proposedOrderEditorHeaderDropdownSelection,
          purchaseCheckoutDoNotAskAgain: settings.purchaseCheckoutDoNotAskAgain,
          supplierInformationSupplierProductsTableSettings:
            settings.supplierInformationSupplierProductsTableSettings,
        });
      }),
      concatMap((settings: AccountSettings) =>
        ctx.dispatch(new SetInitialMenuV2State(settings.generalMenuVisibility))
      )
    );
  }

  @Action(LastSelectedWebshopUpdated)
  settingsUpdateLastSelectedWebshop(
    ctx: StateContext<AccountSettingsStateModel>,
    action: LastSelectedWebshopUpdated
  ) {
    return this.accountSettingsService
      .updateSettings(this.store.selectSnapshot(AccountState.userUuid), {
        lastWebshopUUID: action.uuid,
      })
      .pipe(
        tap(() => {
          ctx.patchState({ lastWebshop: action.uuid });
        })
      );
  }

  @Action(UpdateAccountSettings)
  settingsUpdate(
    ctx: StateContext<AccountSettingsStateModel>,
    action: UpdateAccountSettings
  ) {
    const state = ctx.getState();
    return this.accountSettingsService
      .updateSettings(
        this.store.selectSnapshot(AccountState.userUuid),
        action.accountSettingsUpdateModel
      )
      .pipe(
        tap(() => {
          ctx.patchState({
            lastWebshop: action.accountSettingsUpdateModel.lastWebshopUUID
              ? action.accountSettingsUpdateModel.lastWebshopUUID
              : state.lastWebshop,
            localeId: action.accountSettingsUpdateModel.localeId
              ? action.accountSettingsUpdateModel.localeId
              : state.localeId,
          });
        })
      );
  }

  @Action(UpdateAccountLanguage)
  settingsUpdateLanguage(
    ctx: StateContext<AccountSettingsStateModel>,
    action: UpdateAccountLanguage
  ) {
    const state = ctx.getState();
    return this.accountSettingsService
      .updateSettings(
        this.store.selectSnapshot(AccountState.userUuid),
        action.accountSettingsUpdateModel
      )
      .pipe(
        concatMap(() => {
          ctx.patchState({
            lastWebshop: action.accountSettingsUpdateModel.lastWebshopUUID
              ? action.accountSettingsUpdateModel.lastWebshopUUID
              : state.lastWebshop,
            localeId: action.accountSettingsUpdateModel.localeId
              ? action.accountSettingsUpdateModel.localeId
              : state.localeId,
          });
          return of(true);
        }),
        concatMap(() => {
          return ctx.dispatch(
            new ChangeLocale(action.accountSettingsUpdateModel.localeId)
          );
        })
      );
  }

  @Action(UpdateColorTheme, { cancelUncompleted: true })
  settingsUpdateColorTheme(
    ctx: StateContext<AccountSettingsStateModel>,
    action: UpdateColorTheme
  ) {
    ctx.setState(
      patch<AccountSettingsStateModel>({
        colorTheme: action.colorTheme,
      })
    );

    this.theme.setColorTheme(ctx.getState().colorTheme);

    const properties: AccountSettingsUpdate = {
      colorTheme: action.colorTheme,
    };

    return this._updateAccountSettings(ctx, properties);
  }

  @Action(ToggleIntercom)
  toggleIntercom(ctx: StateContext<AccountSettingsStateModel>) {
    const newChatState =
      ctx.getState().chatState === ChatStates.OPENED
        ? ChatStates.CLOSED
        : ChatStates.OPENED;

    this._updateIntercomVisibility(newChatState === ChatStates.CLOSED);

    ctx.patchState({
      chatState: newChatState,
    });

    const properties: AccountSettingsUpdate = {
      chatState: newChatState,
    };

    return this._updateAccountSettings(ctx, properties);
  }

  @Action(StockLevelLegendSelectedChanged, { cancelUncompleted: true })
  legendSelectedChanged(
    ctx: StateContext<AccountSettingsStateModel>,
    action: StockLevelLegendSelectedChanged
  ) {
    const excludedColumns: ServiceLevelGraphCategory[] = Object.entries(
      action.event.selected
    )
      .filter(([_, selected]) => !selected)
      .map(([legend, _]) => {
        const [_categoryTitle, categoryValue] = legend.split(' ');

        return categoryValue as ServiceLevelGraphCategory;
      });

    const properties: AccountSettingsUpdate = {
      serviceLevelGraphExcludedCategories: excludedColumns,
      overrideServiceLevelGraphExcludedCategories: true,
    };

    return this._updateAccountSettings(ctx, properties).pipe(
      tap(() => {
        ctx.setState(
          patch<AccountSettingsStateModel>({
            serviceLevelGraphExcludedCategories: excludedColumns,
          })
        );
      })
    );
  }

  @Action(SaveMenuState, { cancelUncompleted: true })
  saveMenuState(
    ctx: StateContext<AccountSettingsStateModel>,
    action: SaveMenuState
  ) {
    const properties: AccountSettingsUpdate = {
      generalMenuVisibility: action.menuState,
    };

    return this._updateAccountSettings(ctx, properties);
  }

  private _updateIntercomVisibility(hide: boolean): void {
    Intercom('update', {
      app_id: 'e8b6t2nc',
      hide_default_launcher: hide,
    });
  }

  private _updateAccountSettings(
    ctx: StateContext<AccountSettingsStateModel>,
    properties: AccountSettingsUpdate
  ): Observable<any> {
    return ctx.dispatch(new Saving(false)).pipe(
      delay(this.UPDATE_DELAY),
      exhaustMap(() =>
        this.accountSettingsService.updateSettings(
          this.store.selectSnapshot(AccountState.userUuid),
          properties
        )
      ),
      concatMap(() => ctx.dispatch(new SaveSucceed(false))),
      catchError(() => {
        return ctx.dispatch(new SaveFailed());
      })
    );
  }
}
