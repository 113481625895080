import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  signal,
} from '@angular/core';
import {
  FILTER,
  FilterBase,
  FilterEmitted,
  FilterNullGroupV2,
  FilterStrategy,
  FilterTypeOptionsV2,
  FilteringStrategies,
} from '../../model/filter-v2.model';
import { FilterStrategiesHandler } from '../core/filter-strategies-handler';
import { FilterOption, FilterTypeOptions } from '../../model/filter.model';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import {
  ServiceRequestInfoV3Operator,
  ServiceRequestInfoV3Operators,
} from '../../../../model/pageable-v2.model';
import { FilterV2Component } from '../../filter-base-v2';

@Component({
  selector: 'app-filter-nullable',
  exportAs: 'filterNullable',
  templateUrl: './filter-nullable.component.html',
  styleUrl: './filter-nullable.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: FILTER, useExisting: FilterNullableComponent }],
})
export class FilterNullableComponent
  extends FilterV2Component
  implements FilterBase, FilteringStrategies<FilterNullGroupV2>, OnInit
{
  filterStrategiesHandler = new FilterStrategiesHandler();

  filterStrategy: FilterStrategy<FilterNullGroupV2>;

  selectedOption = signal<ServiceRequestInfoV3Operator | null>(null);

  filterOptions = signal<FilterOption[]>([
    {
      key: MESSAGES.common.filter.optionTypes.isEmpty,
      value: ServiceRequestInfoV3Operators.AND,
    },
    {
      key: MESSAGES.common.filter.optionTypes.isNotEmpty,
      value: ServiceRequestInfoV3Operators.XOR,
    },
  ]);

  readonly FILTER_TYPE_OPTIONS = FilterTypeOptions;

  ngOnInit(): void {
    this.filterStrategy = this.filterStrategiesHandler.getFilterStrategy(
      FilterTypeOptionsV2.NULLABLE
    );
  }

  selectOption(option: ServiceRequestInfoV3Operator): void {
    this.selectedOption.set(option);

    this.filterStrategy?.clear();

    if (this._shouldPopulate()) {
      this._populate();
    }
  }

  clear() {
    this.filterStrategy.clear();
  }

  cancel(): void {
    this.canceled.emit();
  }

  apply(filtered: FilterEmitted): void {
    if (this._isAllowedToApplyFilter()) {
      this.filtered.emit({
        columnKey: this.filter().columnKey,
        optionSelected: null,
        subOperator: this.selectedOption(),
        values: { to: null },
        applyAll: filtered.applyAll,
      });
    }
  }

  private _isAllowedToApplyFilter(): boolean {
    return this.filterStrategy.hasValue() && !this.filterDisabled();
  }

  private _shouldPopulate(): boolean {
    return true;
  }

  private _populate(): void {
    this.filterStrategy.populate(this.filterValues()?.subOperator);
  }
}
