import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, concatMap, delay, mergeMap, of, tap } from 'rxjs';
import {
  LoadFailed,
  Loading,
  LoadSucceed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { CreateSupplierProductRequestBody } from 'src/app/shared/models/multi-supplier-product-editor/create-supplier-product.model';
import {
  DisconnectedProduct,
  DisconnectedProducts,
  SelectedDisconnectedProduct,
} from 'src/app/shared/models/suppliers/disconnected-products.model';
import {
  AvailabilityChange,
  EanChange,
  LoadDisconnectedProducts,
  LotSizeChange,
  MOQChange,
  NameChange,
  PreferredChange,
  PriceChange,
  ResetConnectProduct,
  ResetSelectedDisconnectedProduct,
  SaveSupplierProduct,
  SelectDisconnectedProduct,
  SkuChange,
} from '../actions/connect-product-to-supplier-dialog.actions';
import {
  defaultSelectedDisconnectedProduct,
  defaultState,
} from '../model/connect-product-to-supplier-dialog.model';
import { SuppliersV2Service } from 'src/app/core/api/supply/v2/suppliers-v2.service';

export interface ConnectProductToSupplierStateModel {
  disconnectedProducts: DisconnectedProduct[];
  selectedProduct: SelectedDisconnectedProduct;
  page: Pageable;
  loading: boolean;
}

@State<ConnectProductToSupplierStateModel>({
  name: 'connectProductToSupplierState',
  defaults: defaultState,
})
@Injectable()
export class ConnectProductToSupplierState {
  constructor(
    private store: Store,
    private suppliersV2Service: SuppliersV2Service
  ) {}

  @Selector()
  static products(
    state: ConnectProductToSupplierStateModel
  ): DisconnectedProduct[] {
    return state.disconnectedProducts;
  }

  @Selector()
  static isProductSelected(state: ConnectProductToSupplierStateModel): boolean {
    return state.selectedProduct.id !== null;
  }

  @Selector()
  static productName(state: ConnectProductToSupplierStateModel): string {
    return state.selectedProduct.supplierProductName;
  }

  @Selector()
  static sku(state: ConnectProductToSupplierStateModel): string {
    return state.selectedProduct.supplierProductSku;
  }

  @Selector()
  static eanCode(state: ConnectProductToSupplierStateModel): string {
    return state.selectedProduct.supplierProductEAN;
  }

  @Selector()
  static price(state: ConnectProductToSupplierStateModel): number {
    return state.selectedProduct.purchasePrice;
  }

  @Selector()
  static moq(state: ConnectProductToSupplierStateModel): number {
    return state.selectedProduct.minimumOrderQuantity;
  }

  @Selector()
  static lotSize(state: ConnectProductToSupplierStateModel): number {
    return state.selectedProduct.lotSize;
  }

  @Selector()
  static availability(state: ConnectProductToSupplierStateModel): boolean {
    return state.selectedProduct.availability;
  }

  @Selector()
  static preferred(state: ConnectProductToSupplierStateModel): boolean {
    return state.selectedProduct.preferred;
  }

  @Action(LoadDisconnectedProducts, { cancelUncompleted: true })
  loadDisconnectedProducts(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: LoadDisconnectedProducts
  ) {
    if (payload.search.length <= 0) {
      ctx.patchState({ disconnectedProducts: [] });
      return;
    }

    return ctx.dispatch(new Loading(false)).pipe(
      mergeMap(() =>
        this._findDisconnectedProducts(
          ctx,
          payload.supplierUuid,
          payload.search
        )
      ),
      tap((products: DisconnectedProducts) => {
        ctx.patchState({
          disconnectedProducts: products.webshopProducts,
        });
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(SelectDisconnectedProduct, { cancelUncompleted: true })
  selectDisconnectedProduct(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: SelectDisconnectedProduct
  ) {
    const product = this._findSelectedProductFromDisconnectedProductsList(
      ctx.getState(),
      payload.productId
    );

    if (!product) {
      this.resetSelectedDisconnectedProduct(ctx);
      return;
    }

    ctx.patchState({
      selectedProduct: {
        ...ctx.getState().selectedProduct,
        id: product.id,
        supplierProductName: product.name,
        supplierProductSku: product.sku,
        purchasePrice: product.price,
      },
    });
  }

  @Action(ResetSelectedDisconnectedProduct)
  resetSelectedDisconnectedProduct(
    ctx: StateContext<ConnectProductToSupplierStateModel>
  ) {
    ctx.patchState({
      selectedProduct: defaultSelectedDisconnectedProduct,
    });
  }

  @Action(NameChange, { cancelUncompleted: true })
  nameChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: NameChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          supplierProductName: payload.name,
        }),
      })
    );
  }

  @Action(EanChange, { cancelUncompleted: true })
  eanChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: EanChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          supplierProductEAN: payload.ean,
        }),
      })
    );
  }

  @Action(SkuChange, { cancelUncompleted: true })
  skuChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: SkuChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          supplierProductSku: payload.sku,
        }),
      })
    );
  }

  @Action(PriceChange, { cancelUncompleted: true })
  priceChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: PriceChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          purchasePrice: Number(payload.price),
        }),
      })
    );
  }

  @Action(LotSizeChange, { cancelUncompleted: true })
  lotSizeChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: LotSizeChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          lotSize: Number(payload.lotSize),
        }),
      })
    );
  }

  @Action(MOQChange, { cancelUncompleted: true })
  moqChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: MOQChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          minimumOrderQuantity: Number(payload.moq),
        }),
      })
    );
  }

  @Action(AvailabilityChange, { cancelUncompleted: true })
  availabilityChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: AvailabilityChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          availability: payload.availability,
        }),
      })
    );
  }

  @Action(PreferredChange, { cancelUncompleted: true })
  preferredChange(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    payload: PreferredChange
  ) {
    ctx.setState(
      patch<ConnectProductToSupplierStateModel>({
        selectedProduct: patch<SelectedDisconnectedProduct>({
          preferred: payload.preferred,
        }),
      })
    );
  }

  @Action(SaveSupplierProduct, { cancelUncompleted: true })
  saveSupplierProduct(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    action: SaveSupplierProduct
  ) {
    const webshopId = this.store.selectSnapshot(WebshopState.selected).id;

    const requestInfo = this._buildSaveProductRequest(
      ctx.getState(),
      webshopId,
      action.supplierId
    );

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this.suppliersV2Service.createSupplierProduct(requestInfo).pipe(
          catchError(e => {
            ctx.dispatch(new SaveFailed(true, 'Request failed'));

            throw new Error(e.message || e);
          })
        )
      ),
      delay(500),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      tap(() => {
        if (!action.closeDialog) {
          this.store.dispatch(new ResetConnectProduct());
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  @Action(ResetConnectProduct, { cancelUncompleted: true })
  reset(ctx: StateContext<ConnectProductToSupplierStateModel>) {
    ctx.patchState(defaultState);
  }

  private _buildSaveProductRequest(
    state: ConnectProductToSupplierStateModel,
    webshopId: number,
    supplierId: string
  ): CreateSupplierProductRequestBody {
    return {
      webshopId: webshopId,
      product: {
        availability: state.selectedProduct.availability,
        availabilityDate: null,
        currentStockOnHand: 0,
        eanCode: state.selectedProduct.supplierProductEAN,
        sku: state.selectedProduct.supplierProductSku,
        minimumPurchaseQuantity: state.selectedProduct.minimumOrderQuantity,
        name: state.selectedProduct.supplierProductName,
        preferred: state.selectedProduct.preferred,
        purchaseInQuantitiesOf: state.selectedProduct.lotSize,
        price: state.selectedProduct.purchasePrice,
        status: 'ENABLED',
        supplierId: supplierId,
        webshopProductId: state.selectedProduct.id,
      },
    };
  }

  private _findSelectedProductFromDisconnectedProductsList(
    state: ConnectProductToSupplierStateModel,
    productId: string
  ): DisconnectedProduct {
    return state.disconnectedProducts.find(
      (product: DisconnectedProduct) => product.id === productId
    );
  }

  private _findDisconnectedProducts(
    ctx: StateContext<ConnectProductToSupplierStateModel>,
    supplierUuid: string,
    search: string
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = { filter: search, page: 0, size: 500 };

    return this.suppliersV2Service
      .findDisconnected(webshopUuid, supplierUuid, requestInfo)
      .pipe(
        catchError(() => {
          return ctx.dispatch(new LoadFailed(false));
        })
      );
  }
}
