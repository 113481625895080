import { DialogRef } from '@angular/cdk/dialog';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { RenameExportDialogComponent } from 'src/app/shared/components/rename-export-dialog/rename-export-dialog.component';
import { RenameExportProperties } from 'src/app/shared/models/tableExports/export.model';

export class InitializeExportsState {
  static readonly type = '[Exports V2] Initialize';
}

export class LoadExports {
  static readonly type = '[Exports V2] Load';
}

export class ReloadDatatable {
  static readonly type = '[Exports V2] Reload';
}

export class Paginate {
  static readonly type = '[Exports V2] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class Sort {
  static readonly type = '[Exports V2] Sort';

  constructor(public sort: Sorted) {}
}

export class ResetPagination {
  static readonly type = '[Exports V2] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Exports V2] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Exports V2] Hide Column';

  constructor(public columnKey: string) {}
}

export class RemoveAllFilters {
  static readonly type = '[Exports V2] Remove All Filters';
}

export class RenameExport {
  static readonly type = '[Exports V2] Rename Export';

  constructor(
    public fileUUID: string,
    public properties: RenameExportProperties,
    public dialogRef: DialogRef<RenameExportDialogComponent>
  ) {}
}

export class DownloadExport {
  static readonly type = '[Exports V2] Download Export';

  constructor(public fileUUID: string) {}
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Exports V2] Reset Pagination And Load Data';
}
