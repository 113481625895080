import { DialogRef } from '@angular/cdk/dialog';
import { AddProductPromotionDialogComponent } from '../add-product-promotion-dialog.component';

export class LoadWebshopProductUnassociatedPromotions {
  static readonly type =
    '[Add Product Promotions Dialog] Load Webshop Product Unassociated Promotions';
}

export class UpdateSelectedPromotion {
  static readonly type =
    '[Add Product Promotions Dialog] Update Selected Promotion';

  constructor(public promotionUuid: string) {}
}

export class UpdateSpecificUpliftType {
  static readonly type =
    '[Add Product Promotions Dialog] Update Specific Uplift Type';

  constructor(public upliftType: string) {}
}

export class UpdateSpecificUpliftIncrease {
  static readonly type =
    '[Add Product Promotions Dialog] Update Specific Uplift Increase';

  constructor(public upliftIncrease: string) {}
}

export class ResetSelectedPromotion {
  static readonly type =
    '[Add Product Promotions Dialog] Reset Selected Promotion';
}

export class CreateWebshopProductPromotion {
  static readonly type =
    '[Add Product Promotions Dialog] Create Webshop Product Promotion';

  constructor(
    public webshopProductUuid: string,
    public dialogRef: DialogRef<AddProductPromotionDialogComponent>
  ) {}
}

export class ResetAddProductPromotionDialogState {
  static readonly type = '[Add Product Promotions Dialog] Reset Dialog State';
}
