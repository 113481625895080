<app-filter-container-template
  [headerTemplate]="headerTemplate()"
  [footerTemplate]="footerTemplate()"
  [filterName]="filterName()"
  [showApplyAll]="showApplyAll()"
  (apply)="apply($event)"
  (clear)="clear()"
  (cancel)="cancel()"
  [disableClear]="(!isAllSelected && !isSomeSelected) || filterDisabled()"
  [disableApply]="(!isAllSelected && !isSomeSelected) || filterDisabled()">
  <app-filter-restricted-template-v2
    [selectionModel]="filterStrategy.form.selection"
    [options]="filter().options"
    [isAllSelected]="isAllSelected"
    [isSomeSelected]="isSomeSelected"
    [disabled]="filterDisabled()" />
</app-filter-container-template>
