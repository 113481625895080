import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appRequiredLabel]',
  standalone: true,
})
export class RequiredLabelDirective {
  @HostBinding('class.required-label')
  requiredLabel: boolean = true;
}
