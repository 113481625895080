import { Injectable, inject } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import {
  ACCOUNT_PERMISSIONS,
  AccountPermissionRole,
  AccountPermissionRoles,
  AccountPermissions,
  AccountRoles,
  MappedPermission,
} from 'src/app/shared/models/account/account-permissions.model';
import {
  FindWebshopOwner,
  LoadAccountPermissions,
} from '../actions/account-permissions.actions';
import { AccountService } from '../api/account/v2/account.service';
import { catchError, switchMap, tap } from 'rxjs';
import { AccountState } from './account.state';
import { StateOperator, compose, patch } from '@ngxs/store/operators';
import { SetMenuV2Options } from 'src/app/features/home/actions/nav-menu-v2.actions';
import { LoadFailed } from '../actions/app.action';
import { WebshopState } from './webshop.state';
import { WebshopOwner } from 'src/app/shared/models/account/account.model';

export interface AccountPermissionsStateModel {
  isOwner: boolean;
  isShopAdministrator: boolean;
  permissions: AccountPermissions;
}

@State<AccountPermissionsStateModel>({
  name: 'accountPermissionsState',
  defaults: {
    isOwner: false,
    isShopAdministrator: false,
    permissions: ACCOUNT_PERMISSIONS,
  },
})
@Injectable()
export class AccountPermissionsState {
  private accountService = inject(AccountService);

  private store = inject(Store);

  @Selector()
  static isShopAdministrator(state: AccountPermissionsStateModel): boolean {
    return state.isShopAdministrator;
  }

  @Selector()
  static permissions(state: AccountPermissionsStateModel): AccountPermissions {
    return state.permissions;
  }

  @Selector()
  static isOwner(state: AccountPermissionsStateModel): boolean {
    return state.isOwner;
  }

  @Selector()
  static availablePermissionsKeys(
    state: AccountPermissionsStateModel
  ): string[] {
    return Object.values(state.permissions)
      .filter(
        permission => permission.role !== AccountPermissionRoles.NO_ACCESS
      )
      .map(permission => permission.featureKey);
  }

  @Selector()
  static permissionsKeys(state: AccountPermissionsStateModel): string[] {
    return Object.values(state.permissions)
      .filter(
        permission =>
          permission.role !== AccountPermissionRoles.NO_ACCESS ||
          state.isShopAdministrator
      )
      .map(permission => permission.featureKey);
  }

  static hasPermission(featureKey: string) {
    return createSelector(
      [
        AccountPermissionsState.isShopAdministrator,
        AccountPermissionsState.permissions,
      ],
      (isShopAdmin: boolean, permissions: AccountPermissions) => {
        return (
          permissions[featureKey].role !== AccountPermissionRoles.NO_ACCESS ||
          isShopAdmin
        );
      }
    );
  }

  static hasPermissionViewOnly(featureKey: string) {
    return createSelector(
      [
        AccountPermissionsState.isShopAdministrator,
        AccountPermissionsState.permissions,
      ],
      (isShopAdmin: boolean, permissions: AccountPermissions) => {
        return (
          permissions[featureKey].role === AccountPermissionRoles.VIEW &&
          !isShopAdmin
        );
      }
    );
  }

  @Action(LoadAccountPermissions)
  loadAccountPermissions(ctx: StateContext<AccountPermissionsStateModel>) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this._fetchAccountRoles(ctx, webshopUuid, userUuid).pipe(
      tap((accountRoles: AccountRoles) => {
        ctx.setState(
          compose(
            patch<AccountPermissionsStateModel>({
              isShopAdministrator: accountRoles.isShopAdministrator,
            }),
            ...accountRoles.permissions.map(permission =>
              this._defineAccountPermissions(permission)
            )
          )
        );
      }),
      switchMap(() => ctx.dispatch(new SetMenuV2Options()))
    );
  }

  @Action(FindWebshopOwner)
  findWebshopOwner(ctx: StateContext<AccountPermissionsStateModel>) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this._findWebshopOwner(ctx, webshopUuid).pipe(
      tap((webshopOwner: WebshopOwner) => {
        ctx.setState(
          patch({
            isOwner: webshopOwner.accountUuid === userUuid,
          })
        );
      })
    );
  }

  private _defineAccountPermissions(
    permission: MappedPermission
  ): StateOperator<AccountPermissionsStateModel> {
    return (state: Readonly<AccountPermissionsStateModel>) => {
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [permission.featureKey]: {
            ...state.permissions[permission.featureKey],
            role: permission.role as AccountPermissionRole,
          },
        },
      };
    };
  }

  private _fetchAccountRoles(
    ctx: StateContext<AccountPermissionsStateModel>,
    webshopUuid: string,
    userUuid: string
  ) {
    return this.accountService.fetchAccountRoles(webshopUuid, userUuid).pipe(
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _findWebshopOwner(
    ctx: StateContext<AccountPermissionsStateModel>,
    webshopUuid: string
  ) {
    return this.accountService.findWebshopOwner(webshopUuid).pipe(
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }
}
