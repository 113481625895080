import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { catchError, concatMap, delay, mergeMap, Observable, tap } from 'rxjs';
import {
  AllowExit,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import {
  ExportOrderV2,
  OrderExported,
} from 'src/app/core/actions/export-order.action';
import { ExportService } from 'src/app/core/api/export/v1/export.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { OrderMomentType } from 'src/app/shared/models/buy-orders/v2/buy-order-order-moment.model';
import { BuyOrderlinesSupplier } from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2.model';
import { ExportData } from 'src/app/shared/models/exports/exports.model';
import {
  ChangePlanningDate,
  DisableReplan,
  DonePurchaseConfirmation,
  EnableReplan,
  InitializePurchaseConfirmation,
  ResetPurchaseConfirmation,
  SetOrderPlaced,
  SetOrderRedirect,
  SetUseSupplierSettings,
  ToggleProductsDataStorage,
} from '../actions/purchase-confirmation-dialog-v2.actions';
import { defaultsPurchaseConfirmation } from '../model/purchase-confirmation-v2.model';
import { NavigateTo } from 'src/app/core/actions/navigation.action';
import { SendEmail } from '../../email-management/actions/email-management.actions';
import { EmailTemplatesState } from '../../email-management/state/email-templates.state';

export interface PurchaseConfirmationStateModel {
  loading: boolean;
  todaysDate: Date;
  orderUuid: string;
  orderId: number;
  orderMomentUUID: string;
  orderMomentType: OrderMomentType;
  orderMomentDate: string;
  supplier: BuyOrderlinesSupplier;
  planningTrigger: boolean;
  totalMaxLoadCapacity: number;
  totalContainerVolume: number;
  newOrder: boolean;
  planningDate: Date;
  planningMinDate: Date;
  replanEnabled: boolean;
  nextOrderMomentDate: string;
  daysToNextOrderMoment: number;
  useSupplierSettings: boolean;
  shouldSaveSupplierProductChanges: boolean;
  shouldSaveWebshopProductChanges: boolean;
  isOrderNotSynced: boolean;
  redirectTo: string;
}

@State<PurchaseConfirmationStateModel>({
  name: 'purchaseConfirmationState',
  defaults: defaultsPurchaseConfirmation,
})
@Injectable()
export class PurchaseConfirmationState {
  constructor(
    private store: Store,
    private exportService: ExportService
  ) {}

  @Action(InitializePurchaseConfirmation, { cancelUncompleted: true })
  initializePurchaseConfirmation(
    ctx: StateContext<PurchaseConfirmationStateModel>,
    payload: InitializePurchaseConfirmation
  ) {
    ctx.patchState({
      loading: false,
      todaysDate: DateTime.fromJSDate(new Date()).toUTC().toJSDate(),
      orderUuid: null,
      orderMomentUUID: payload.purchaseOverview.orderMomentUuid,
      orderMomentType: payload.purchaseOverview.orderMomentType,
      orderMomentDate: payload.purchaseOverview.orderMomentDate,
      supplier: payload.purchaseOverview.supplier,
      planningTrigger: payload.purchaseOverview.planningTrigger,
      totalMaxLoadCapacity: payload.purchaseOverview.containerTotalWeight,
      totalContainerVolume: payload.purchaseOverview.containerTotalVolume,
      newOrder: !(
        payload.purchaseOverview.orderMomentUuid !== null ||
        payload.purchaseOverview.planningTrigger
      ),
      planningDate: this._calculateNewPlanningDate(
        ctx.getState().todaysDate,
        payload.purchaseOverview.supplier.effectiveReplenishmentPeriod
      ),
      nextOrderMomentDate: DateTime.fromJSDate(
        this._calculateNextOrderMomentDate(
          payload.purchaseOverview.orderMomentDate,
          payload.purchaseOverview.supplier.effectiveReplenishmentPeriod
        )
      ).toFormat('MM/dd/yyyy'),
      daysToNextOrderMoment:
        payload.purchaseOverview.supplier.effectiveReplenishmentPeriod,
      shouldSaveSupplierProductChanges: false,
      shouldSaveWebshopProductChanges: true,
      isOrderNotSynced: payload.purchaseOverview.isOrderNotSynced,
    });
  }

  @Action(ResetPurchaseConfirmation)
  resetPurchaseConfirmation(ctx: StateContext<PurchaseConfirmationStateModel>) {
    ctx.patchState(defaultsPurchaseConfirmation);
  }

  @Action(EnableReplan, { cancelUncompleted: true })
  enableReplan(ctx: StateContext<PurchaseConfirmationStateModel>) {
    ctx.patchState({
      replanEnabled: true,
    });
  }

  @Action(DisableReplan, { cancelUncompleted: true })
  disabledReplan(ctx: StateContext<PurchaseConfirmationStateModel>) {
    ctx.patchState({
      replanEnabled: false,
    });
  }

  @Action(ChangePlanningDate, { cancelUncompleted: true })
  changePlanningDate(
    ctx: StateContext<PurchaseConfirmationStateModel>,
    payload: ChangePlanningDate
  ) {
    const state = ctx.getState();

    ctx.patchState({
      daysToNextOrderMoment: this._calculateDaysToNextOrderMoment(
        state.todaysDate,
        payload.planningDate
      ),
      planningDate: this._validateAndGetNewPlanningDate(
        state.todaysDate,
        payload.planningDate
      ),
    });
  }

  @Action(SetUseSupplierSettings, { cancelUncompleted: true })
  setUseSupplierSettings(
    ctx: StateContext<PurchaseConfirmationStateModel>,
    payload: SetUseSupplierSettings
  ) {
    ctx.patchState({
      useSupplierSettings: payload.useSupplierSettings,
    });
  }

  @Action(ToggleProductsDataStorage, { cancelUncompleted: true })
  toggleSaveProductChanges(ctx: StateContext<PurchaseConfirmationStateModel>) {
    ctx.patchState({
      shouldSaveWebshopProductChanges:
        !ctx.getState().shouldSaveWebshopProductChanges,
    });
  }

  @Action(ExportOrderV2, { cancelUncompleted: true })
  exportOrder(
    ctx: StateContext<PurchaseConfirmationStateModel>,
    payload: ExportOrderV2
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const orderUuid = ctx.getState().orderUuid;

    return this._exportOrder(
      webshopUuid,
      orderUuid,
      payload.fileType
    ).subscribe(response => {
      const exportData: ExportData = {
        data: response.body,
        fileName: response.headers.get('Content-Disposition'),
        fileType: response.headers.get('content-type'),
      };
      const a = document.createElement('a');

      const blob = new Blob([exportData.data], { type: exportData.fileType });

      a.href = window.URL.createObjectURL(blob);

      a.download = exportData.fileName
        .split(';')[1]
        .trim()
        .split('=')[1]
        .replace(/"/g, '');

      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(a.href);

      ctx.dispatch(new OrderExported(exportData));
    });
  }

  @Action(SetOrderRedirect, { cancelUncompleted: true })
  setOrderRedirect(
    ctx: StateContext<PurchaseConfirmationStateModel>,
    payload: SetOrderRedirect
  ) {
    ctx.patchState({
      redirectTo: payload.redirectTo,
    });
  }

  @Action(SetOrderPlaced)
  setOrderPlaced(
    ctx: StateContext<PurchaseConfirmationStateModel>,
    payload: SetOrderPlaced
  ) {
    ctx.patchState({
      orderId: payload.placedOrder.id,
      orderUuid: payload.placedOrder.uuid,
    });
  }

  @Action(DonePurchaseConfirmation)
  donePurchaseConfirmation(
    ctx: StateContext<PurchaseConfirmationStateModel>,
    payload: DonePurchaseConfirmation
  ) {
    const state = ctx.getState();

    const hasEmailTemplates = this.store.selectSnapshot(
      EmailTemplatesState.hasEmailTemplates
    );

    if (state.useSupplierSettings && hasEmailTemplates) {
      return ctx.dispatch(new SendEmail(ctx.getState().orderUuid)).pipe(
        tap(() => payload.dialogRef.close()),
        mergeMap(() => ctx.dispatch(new AllowExit())),
        concatMap(() =>
          ctx.dispatch(new NavigateTo(['purchase', state.redirectTo]))
        )
      );
    }

    return ctx.dispatch([new Saving(false), new AllowExit()]).pipe(
      delay(500),
      concatMap(() => ctx.dispatch(new SaveSucceed(false))),
      tap(() => payload.dialogRef.close()),
      concatMap(() =>
        ctx.dispatch(new NavigateTo(['purchase', state.redirectTo]))
      )
    );
  }

  private _validateAndGetNewPlanningDate(
    todaysDate: Date,
    newPlanningDate: string
  ): Date {
    const planningDate = new Date(newPlanningDate);

    if (planningDate.getTime() - todaysDate.getTime() < 0) {
      return todaysDate;
    }

    return planningDate;
  }

  private _calculateDaysToNextOrderMoment(
    todaysDate: Date,
    newPlanningDate: string
  ): number {
    const planningDate = new Date(newPlanningDate);

    const baseHours = 1000 * 60 * 60 * 24; // 24h

    const timeDifference = planningDate.getTime() - todaysDate.getTime();

    return Math.ceil(timeDifference / baseHours);
  }

  private _calculateNewPlanningDate(
    todaysDate: Date,
    replenishmentPeriod: number
  ): Date {
    const tmpPlanningDate = new Date(todaysDate);

    tmpPlanningDate.setDate(tmpPlanningDate.getDate() + replenishmentPeriod);

    return tmpPlanningDate;
  }

  private _calculateNextOrderMomentDate(
    orderMomentDate: string,
    replenishmentPeriod: number
  ): Date {
    const tmpNextOrderMomentDate = new Date(orderMomentDate);

    tmpNextOrderMomentDate.setDate(
      tmpNextOrderMomentDate.getDate() + replenishmentPeriod
    );

    return tmpNextOrderMomentDate;
  }

  private _exportOrder(
    webshopUuid: string,
    buyOrderUuid: string,
    fileType: string
  ): Observable<any> {
    return this.exportService
      .exportAsXLSXdoc(webshopUuid, buyOrderUuid, fileType)
      .pipe(
        catchError(e => {
          this.store.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }
}
