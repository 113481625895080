import { ColorThemes } from 'src/app/shared/models/account/account-settings.model';

export const BASE_PATH = 'app';

export const NOT_AVAILABLE_VALUE = '–';

export const RESULTS_SELECTION_LIMIT_AMOUNT = 200;

export const EMAIL_BODY_MAX_LENGTH = 2000;

export const GLOBAL = {
  colors: {
    light: {
      positiveValue: 'var(--green-3)',
      negativeValue: 'var(--red-3)',
      neutralValue: 'var(--ink-1)',
    },
  },
};

export const DEFAULT_EMAILS_TEMPLATE_LANGUAGE = 'en';

export const DEFAULT_BUY_ORDERS_APPLY_COLUMN_SELECTION_ALL = true;

export const DEFAULT_COLOR_THEME = ColorThemes.LIGHT;

export const DEFAULT_TIME_LIMIT_MINUTES_SERVICE_LEVEL_UPDATE = 10;

export const DEFAULT_TIME_LIMIT_TO_REFRESH_TABLE_MS = 60000;

export const DEFAULT_TIME_LIMIT_SEND_EMAIL_MS = 300000;

export const CURRENCIES = [
  { value: 'EUR', displayName: 'EUR, Euro, €', symbol: '€' },
  { value: 'GBP', displayName: 'GBP, British Pound, £', symbol: '£' },
  { value: 'USD', displayName: 'USD, Dollar, $', symbol: '$' },
  { value: 'INR', displayName: 'INR, Rupee, ₹', symbol: '₹' },
  { value: 'SEK', displayName: 'SEK, Swedish Krona, kr', symbol: 'kr' },
];

export const COUNTRIES = [
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Virgin Islands',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Hong Kong, SAR China',
  'Macao, SAR China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo (Brazzaville)',
  'Congo, (Kinshasa)',
  'Cook Islands',
  'Costa Rica',
  "Côte d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard and Mcdonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran, Islamic Republic of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea (North)',
  'Korea (South)',
  'Kuwait',
  'Kyrgyzstan',
  'Lao PDR',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia, Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Réunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint-Barthélemy',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint-Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen Islands',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic (Syria)',
  'Taiwan, Republic of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'US Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic)',
  'Viet Nam',
  'Virgin Islands, US',
  'Wallis and Futuna Islands',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const MAX_NOTES_TOOLTIP_LENGTH = 200;

export const EXCLUDED_VAT_COUNTRYS = ['Netherlands'];

//need to be set as var and global as otherwise rudderstack and hubspot wont catch it
declare global {
  export var Intercom;

  export var rudderanalytics: {
    track: (info, event) => void;
    page: (info) => void;
    identify: (info, event) => void;
    screen: (info, event) => void;
    alias: (info, event) => void;
    reset: (info, event) => void;
  };

  export var Canny;

  export var Stripe;
}
