import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  ViewEncapsulation,
} from '@angular/core';
import {
  TabsGroupOrientation,
  TabsGroupSize,
} from '../../model/tabs-group.model';

function convertToArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

@Component({
  selector: 'app-tabs-group',
  templateUrl: './tabs-group.component.html',
  styleUrls: ['./tabs-group.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TabsGroupComponent {
  orientation = input<TabsGroupOrientation>('horizontal');

  size = input<TabsGroupSize>('md');

  customContainerClasses = input([], {
    transform: convertToArray,
  });

  customContentClasses = input([], {
    transform: convertToArray,
  });

  baseClasses = computed(() => [
    'tab-group-container',
    `tab-group-container-${this.orientation()}`,
    `tab-group-container-${this.size()}`,
  ]);

  tabsContainerClasses = computed(() => [
    'tabs-container',
    `tabs-container-${this.orientation()}`,
    ...this.customContainerClasses(),
  ]);

  tabsContentClasses = computed(() => [
    'tabs-content',
    ...this.customContentClasses(),
  ]);
}
