import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
  viewChild,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { MatIcon } from '@angular/material/icon';
import { EmailTemplatesV2Component } from '../email-templates-v2/email-templates-v2.component';
import { STRINGS } from './model/email-management.strings';
import { RequiredLabelDirective } from '../../directives/required-label.directive';
import { EmailSelectionComponent } from './components/email-selection/email-selection.component';
import { EmailCreationComponent } from './components/email-creation/email-creation.component';
import { SkeletonModule } from '../design-system/skeleton/skeleton.module';
import { select, Store } from '@ngxs/store';
import { EmailTemplatesV2ContextComponent } from '../email-templates-v2/components/email-templates-v2-context/email-templates-v2-context.component';
import { EmailTemplatesState } from './state/email-templates.state';
import { TemplatesContextBuilderPipe } from '../email-templates-v2/pipes/templates-context-builder.pipe';
import { SetAndGetTemplatesByContext } from './actions/email-templates.action';
import { EmailTemplatesContext } from '../../models/emails/v1/emails.model';

@Component({
  selector: 'app-email-management',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    RequiredLabelDirective,
    EmailTemplatesV2Component,
    EmailSelectionComponent,
    EmailCreationComponent,
    SkeletonModule,
    EmailTemplatesV2ContextComponent,
    TemplatesContextBuilderPipe,
    SkeletonModule,
  ],
  templateUrl: './email-management.component.html',
  styleUrl: './email-management.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailManagementComponent {
  emailCreationPanel =
    viewChild.required<EmailCreationComponent>('emailCreationPanel');

  emailsAddressesList = input.required<string[]>();

  hasEmailAddresses = computed(() => this.emailsAddressesList().length > 0);

  isPanelOpen = computed(() => {
    if (!this.emailCreationPanel()) return false;

    return this.emailCreationPanel().panel().panelExpanded();
  });

  loading = input<boolean>();

  saving = input<boolean>();

  ignoreNotFilledLanguages = input(false, { transform: booleanAttribute });

  emailsAdresses = model<string[]>([]);

  emailAddressCreated = output<string>();

  availableTemplatesContexts = select(
    EmailTemplatesState.availableTemplatesContexts
  );

  currentTemplatesContext = computed(
    () => this.availableTemplatesContexts()[0] as EmailTemplatesContext
  );

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata;

  private readonly store = inject(Store);

  updateTemplatesContext(context: EmailTemplatesContext): void {
    this.store.dispatch(new SetAndGetTemplatesByContext(context));
  }

  createEmailAddress(emailAddress: string): void {
    this.emailAddressCreated.emit(emailAddress);

    this.emailCreationPanel().panel().close();
  }
}
