import { WebshopProductPromotionsEditorListener } from 'src/app/shared/models/promotion-editor/product-promotions-editor.model';
import { PromotionOfWebshopProduct } from 'src/app/shared/models/promotion/promotion.model';

export class ProductPromotionsEditorListener
  implements WebshopProductPromotionsEditorListener<PromotionOfWebshopProduct>
{
  promotionUUID(productPromotion: PromotionOfWebshopProduct): string {
    return productPromotion.promotion.uuid;
  }

  uuid(productPromotion: PromotionOfWebshopProduct): string {
    return productPromotion.webshopProduct.uuid;
  }

  upliftIncrease(productPromotion: PromotionOfWebshopProduct): number {
    return productPromotion.webshopProduct.specificUplift?.increase ?? null;
  }

  upliftType(productPromotion: PromotionOfWebshopProduct): string {
    return productPromotion.webshopProduct.specificUplift?.type || null;
  }
}
