export enum EmailTemplateLanguages {
  EN = 'EN',
  NL = 'NL',
  DE = 'DE',
}

export type EmailTemplateLanguage =
  | EmailTemplateLanguages.EN
  | EmailTemplateLanguages.NL
  | EmailTemplateLanguages.DE;

export enum EmailTemplateTypes {
  SUPPLIER = 'SUPPLIER',
}

export type EmailTemplateType = EmailTemplateTypes.SUPPLIER;

export interface EmailTemplateContext {
  key: string;
  value: string;
}

export enum EmailTemplateContexts {
  SUPPLIER = 'SUPPLIER',
  ACCOUNT_SUPPLIER = 'ACCOUNT_SUPPLIER',
  WEBSHOP = 'WEBSHOP',
}

export const emailTemplateContextsStrings = {
  SUPPLIER: $localize`Supplier`,
  ACCOUNT_SUPPLIER: $localize`Account`,
  WEBSHOP: $localize`Webshop`,
};

export type EmailTemplatesContext =
  | EmailTemplateContexts.SUPPLIER
  | EmailTemplateContexts.ACCOUNT_SUPPLIER
  | EmailTemplateContexts.WEBSHOP;

export interface EmailTemplatesContexts {
  [EmailTemplateContexts.SUPPLIER]: EmailTemplate[];
  [EmailTemplateContexts.ACCOUNT_SUPPLIER]: EmailTemplate[];
  [EmailTemplateContexts.WEBSHOP]: EmailTemplate[];
}

export interface FindEmailTemplateParams {
  type: EmailTemplateType;
}

export interface EmailTemplate {
  subject: string;
  body: string;
  language: EmailTemplateLanguage;
  type?: EmailTemplateType;
}

export interface EmailTemplates {
  templates: EmailTemplate[];
}

export interface UpsertTemplatePayload {
  template: UpsertTemplateProperties;
}

export interface UpsertTemplateProperties {
  subject: string;
  body: string;
  language: EmailTemplateLanguage;
  type?: EmailTemplateType;
}

export enum EmailStatusEvents {
  PROCESSED = 'processed',
  DELIVERED = 'delivered',
  OPEN = 'open',
}

export type EmailStatusEventName =
  | EmailStatusEvents.PROCESSED
  | EmailStatusEvents.DELIVERED
  | EmailStatusEvents.OPEN;

export interface EmailStatusEvent {
  eventName: EmailStatusEventName;
  processed: Date | string;
}

export interface EmailStatus {
  ccEmails: string[];
  events: EmailStatusEvent[];
}
