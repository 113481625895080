import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxlengthNumber]',
})
export class MaxlengthNumberDirective {
  @Input() maxLength: number;

  constructor(private control: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const maxLength = this.maxLength;

    if (value[0] !== '0' || value === '000') {
      if (value.length > maxLength) {
        this.control.control.setValue(value.slice(0, maxLength), {
          emitEvent: false,
        });
      }
    }
  }
}
