import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { DataTableV2Component } from './data-table-v2.component';
import { ColumnViewV2Component } from './components/column-view-v2/column-view-v2.component';
import { AddFilterButtonComponent } from './components/filter/components/add-filter-button/add-filter-button.component';
import { ClearAllFiltersButtonComponent } from './components/filter/components/clear-all-filters-button/clear-all-filters-button.component';
import { FilterBooleanComponent } from './components/filter/components/filter-boolean/filter-boolean.component';
import { FilterDateComponent } from './components/filter/components/filter-date/filter-date.component';
import { FilterRestrictedComponent } from './components/filter/components/filter-restricted/filter-restricted.component';
import { FilterTextComponent } from './components/filter/components/filter-text/filter-text.component';
import { FiltersToggleComponent } from './components/filter/components/filters-toggle/filters-toggle.component';
import { HideColumnComponent } from './components/hide-column/hide-column.component';
import { SortComponent } from './components/sort/sort.component';
import { FilterNotExistsComponent } from './components/filter/components/filter-not-exists/filter-not-exists.component';
import { FilterNumberComponent } from './components/filter/components/filter-number/filter-number.component';
import { FilterGroupComponent } from './components/filter/components/filter-group/filter-group.component';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterContainerTemplateComponent } from './components/filter/components/filter-container-template/filter-container-template.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { FilterButtonComponent } from './components/filter/components/filter-button/filter-button.component';
import { FilterHeaderComponent } from './components/filter/components/filter-header/filter-header.component';
import { FilterFooterComponent } from './components/filter/components/filter-footer/filter-footer.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OverlayContainerV2Module } from '../overlay-container-v2/overlay-container-v2.module';
import { FilterNumberEqualsV2Component } from './components/filter/components/filter-number/components/filter-number-equals-v2/filter-number-equals-v2.component';
import { FilterTypeOptionsV2Component } from './components/filter/components/filter-type-options-v2/filter-type-options-v2.component';
import { FilterNumberRangeV2Component } from './components/filter/components/filter-number/components/filter-number-range-v2/filter-number-range-v2.component';
import { FilterDateEqualsV2Component } from './components/filter/components/filter-date/components/filter-date-equals-v2/filter-date-equals-v2.component';
import { FilterDateRangeV2Component } from './components/filter/components/filter-date/components/filter-date-range-v2/filter-date-range-v2.component';
import { FilterRestrictedTemplateV2Component } from './components/filter/components/filter-restricted/template/filter-restricted-template-v2/filter-restricted-template-v2.component';
import { RadioButtonV2Module } from '../radio-button-v2/radio-button-v2.module';
import { FilterTextEqualsV2Component } from './components/filter/components/filter-text/components/filter-text-equals-v2/filter-text-equals-v2.component';
import { ButtonModule } from '../button/button.module';
import { InputModule } from '../input/input.module';
import { DatatableContainerComponent } from './components/data-table-container/data-table-container.component';
import { DataTableContainerWrapperComponent } from './components/data-table-container-wrapper/data-table-container-wrapper.component';
import { FiltersDatatableContainerComponent } from './components/filter/components/filters-datatable-container/filters-datatable-container.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { DatatableTopBarComponent } from './components/top-bar/top-bar.component';
import { ColumnFilterPipe } from 'src/app/shared/pipes/table-column-filter/column-filter.pipe';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { TagsInputModule } from '../../tags-input/tags-input.module';
import { DataTableSearchComponent } from './components/data-table-search/data-table-search.component';
import { MenuModule } from '../menu/menu.module';
import { SentenceCasePipe } from 'src/app/shared/pipes/sentence-case.pipe';
import { FilterNullableComponent } from './components/filter/components/filter-nullable/filter-nullable.component';
import { ColumnViewV3Component } from './components/column-view-v3/column-view-v3.component';
import { ColumnViewV3GroupComponent } from './components/column-view-v3/components/column-view-v3-group/column-view-v3-group.component';
import { ColumnViewV3TemplateComponent } from './components/column-view-v3/components/column-view-v3-template/column-view-v3-template.component';
import { ColumnViewV3OptionComponent } from './components/column-view-v3/components/column-view-v3-option/column-view-v3-option.component';
import { ColumnViewV3OptionSelectedComponent } from './components/column-view-v3/components/column-view-v3-option-selected/column-view-v3-option-selected.component';
import { ColumnViewV3SearchComponent } from './components/column-view-v3/components/column-view-v3-search/column-view-v3-search.component';
import { FilterableV2Directive } from './components/filter/directives/filterable-v2.directive';
import { FilterColumnHeaderV2Component } from './components/filter/components/filter-column-header-v2/filter-column-header-v2.component';
import { DataTableRefreshButtonComponent } from './components/data-table-refresh-button/data-table-refresh-button.component';
import { SkeletonModule } from '../skeleton/skeleton.module';

@NgModule({
  declarations: [
    DataTableV2Component,
    ColumnViewV2Component,
    AddFilterButtonComponent,
    ClearAllFiltersButtonComponent,
    FilterBooleanComponent,
    FilterDateComponent,
    FilterRestrictedComponent,
    FilterTextComponent,
    FilterNotExistsComponent,
    FilterNullableComponent,
    FilterNumberComponent,
    FilterColumnHeaderV2Component,
    FilterButtonComponent,
    FiltersToggleComponent,
    FilterGroupComponent,
    FilterHeaderComponent,
    FilterFooterComponent,
    HideColumnComponent,
    PaginatorComponent,
    SortComponent,
    FilterableV2Directive,
    FilterContainerTemplateComponent,
    FilterNumberEqualsV2Component,
    FilterTypeOptionsV2Component,
    FilterNumberRangeV2Component,
    FilterDateEqualsV2Component,
    FilterDateRangeV2Component,
    FilterRestrictedTemplateV2Component,
    FilterTextEqualsV2Component,
    DatatableContainerComponent,
    DataTableContainerWrapperComponent,
    DatatableTopBarComponent,
    FiltersDatatableContainerComponent,
    ColumnFilterPipe,
    DataTableSearchComponent,
    ColumnViewV3Component,
    ColumnViewV3GroupComponent,
    ColumnViewV3TemplateComponent,
    ColumnViewV3OptionComponent,
    ColumnViewV3OptionSelectedComponent,
    ColumnViewV3SearchComponent,
    DataTableRefreshButtonComponent,
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    MatIconModule,
    TooltipModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    MatDatepickerModule,
    RadioButtonV2Module,
    OverlayContainerV2Module,
    ButtonModule,
    InputModule,
    ProgressBarModule,
    TagsInputModule,
    MenuModule,
    SentenceCasePipe,
    SkeletonModule,
  ],
  exports: [
    DataTableV2Component,
    ColumnViewV2Component,
    AddFilterButtonComponent,
    ClearAllFiltersButtonComponent,
    FilterColumnHeaderV2Component,
    FilterableV2Directive,
    FilterGroupComponent,
    FiltersToggleComponent,
    DatatableContainerComponent,
    DataTableContainerWrapperComponent,
    DatatableTopBarComponent,
    FiltersDatatableContainerComponent,
    ColumnFilterPipe,
    PaginatorComponent,
    DataTableSearchComponent,
    ColumnViewV3Component,
    ColumnViewV3GroupComponent,
    DataTableRefreshButtonComponent,
  ],
})
export class DataTableV2Module {}
