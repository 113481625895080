import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewChild,
  input,
} from '@angular/core';

@Component({
  selector: 'app-column-view-v3-template',
  templateUrl: './column-view-v3-template.component.html',
  styleUrl: './column-view-v3-template.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnViewV3TemplateComponent {
  @ViewChild(TemplateRef, { static: true })
  content: TemplateRef<any>;

  label = input.required<string>();

  disabled = input<boolean>(false);
}
