import {
  AccountSettingsUpdate,
  ColorTheme,
  MenuState,
} from 'src/app/shared/models/account/account-settings.model';

/**
 * Action for when the account setting last selected webshop changes.
 */
export class LastSelectedWebshopUpdated {
  static readonly type = '[Settings] Last Selected Webshop Update';

  constructor(public uuid: string) {}
}

export class LoadAccountSettings {
  static readonly type = '[Settings] Load';

  constructor(public userUUID: string) {}
}

export class UpdateAccountSettings {
  static readonly type = '[Settings] Update';

  constructor(public accountSettingsUpdateModel: AccountSettingsUpdate) {}
}

export class UpdateAccountLanguage {
  static readonly type = '[Settings] Language Update';

  constructor(public accountSettingsUpdateModel: AccountSettingsUpdate) {}
}

export class GetHubspotToken {
  static readonly type = '[Hubspot] Get Token';

  constructor(public userUUID: string) {}
}

export class UpdateColorTheme {
  static readonly type = '[Settings] Update Color Theme';

  constructor(public colorTheme: ColorTheme) {}
}

export class SaveMenuState {
  static readonly type = '[Settings] Update Menu State';

  constructor(public menuState: MenuState) {}
}
