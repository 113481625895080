<button
  [ngClass]="baseDropdownClasses()"
  [disabled]="disabled"
  [routerLink]="navigateTo()"
  routerLinkActive="tab-dropdown-active tab-active">
  <div class="tab-dropdown-header">
    <span>{{ label }}</span>
    <mat-icon svgIcon="angle-down" />
  </div>

  <div class="tab-dropdown-content">
    <ng-content select="app-tab" />
  </div>
</button>
