import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  FetchExportsSettings,
  UpdateExportsSettings,
} from '../actions/buyorder-settings.actions';
import {
  LoadFailed,
  LoadSucceed,
  Loading,
  SaveFailed,
  SaveSucceed,
  Saving,
} from '../actions/app.action';
import { Observable, catchError, concatMap, delay, mergeMap, tap } from 'rxjs';
import { WebshopState } from './webshop.state';
import { BuyOrderV2Service } from '../api/buy-order/v2/buy-order-v2.service';
import {
  BuyOrderExportSettings,
  SaveExportSettingsProperties,
} from 'src/app/shared/models/buy-orders/v2/buy-orders-exports-settings.model';

interface BuyOrderSettingsStateModel {
  dynamicExportColumns: string[];
}

@State<BuyOrderSettingsStateModel>({
  name: 'buyorderSettingsState',
  defaults: {
    dynamicExportColumns: [],
  },
})
@Injectable()
export class BuyOrderSettingsState {
  @Selector()
  static dynamicExportColumns(state: BuyOrderSettingsStateModel) {
    return state.dynamicExportColumns;
  }

  constructor(
    private store: Store,
    private buyOrderService: BuyOrderV2Service
  ) {}

  @Action(FetchExportsSettings)
  fetchExportsSettings(ctx: StateContext<BuyOrderSettingsStateModel>) {
    return ctx.dispatch(new Loading(false)).pipe(
      mergeMap(() => this._fetchExportsSettings(ctx)),
      tap((settings: BuyOrderExportSettings) => {
        ctx.patchState({ dynamicExportColumns: settings.identifiers });
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(UpdateExportsSettings, { cancelUncompleted: true })
  updateExportsSettings(
    ctx: StateContext<BuyOrderSettingsStateModel>,
    payload: UpdateExportsSettings
  ) {
    return ctx.dispatch(new Saving(false)).pipe(
      delay(800),
      mergeMap(() => this._updateExportsSettings(ctx, payload.exportsSettings)),
      concatMap(() =>
        ctx.dispatch([new FetchExportsSettings(), new SaveSucceed(false)])
      )
    );
  }

  private _fetchExportsSettings(
    ctx: StateContext<BuyOrderSettingsStateModel>
  ): Observable<BuyOrderExportSettings> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.buyOrderService.fetchExportSettings(webshopUuid).pipe(
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _updateExportsSettings(
    ctx: StateContext<BuyOrderSettingsStateModel>,
    settings: string[]
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const properties: SaveExportSettingsProperties = {
      identifiers: settings,
    };

    return this.buyOrderService
      .saveExportSettings(webshopUuid, properties)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }
}
