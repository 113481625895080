export class LoadSupplyChainInformation {
  static readonly type =
    '[Supply Chain Information V2] Load Supply Chain Information';
}

export class ResetSupplyChainInformation {
  static readonly type =
    '[Supply Chain Information V2] Reset Supply Chain Information';
}

export class LoadProductSuppliers {
  static readonly type = '[Supply Chain Information V2] Load Product Suppliers';
}

export class LoadPurchaseAdvice {
  static readonly type = '[Supply Chain Information V2] Load Purchase Advice';
}
