import {
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import { ToastModule } from './toast.module';
import { SimpleToastComponent } from './components/simple-toast/simple-toast.component';
import {
  ComponentType,
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TOAST_DEFAULT_CONFIG, TextOnlyToast } from './model/toast.model';
import {
  TOAST_DATA,
  ToastConfig,
  ToastHorizontalPosition,
  ToastVerticalPosition,
} from './core/toast-config';
import { ToastRef } from './core/toast-ref';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';

@Injectable({ providedIn: ToastModule })
export class Toast implements OnDestroy {
  simpleToastComponent = SimpleToastComponent;

  toastContainerComponent = ToastContainerComponent;

  private _toastRefAtThisLevel: ToastRef<any> | null = null;

  constructor(
    private overlay: Overlay,
    private _injector: Injector,
    @Optional() @SkipSelf() private _parentToast: Toast,
    @Inject(TOAST_DEFAULT_CONFIG) private defaultConfig: ToastConfig
  ) {}

  openFromComponent<T, C = any>(
    component: ComponentType<T>,
    config?: ToastConfig<C>
  ): ToastRef<T> {
    return this._attach(component, config) as ToastRef<T>;
  }

  openFromTemplate(
    template: TemplateRef<any>,
    config?: ToastConfig
  ): ToastRef<EmbeddedViewRef<any>> {
    return this._attach(template, config);
  }

  open(
    message: string,
    action: string,
    config?: ToastConfig
  ): ToastRef<TextOnlyToast> {
    const toastConfig = { ...this.defaultConfig, ...config };

    toastConfig.data = {
      message,
      action,
      status: config?.status ? config.status : this.defaultConfig.status,
    };

    return this.openFromComponent(this.simpleToastComponent, toastConfig);
  }

  dismiss(): void {
    if (this._openedToastRef) {
      this._openedToastRef.dismiss();
    }
  }

  get _openedToastRef(): ToastRef<any> | null {
    const parent = this._parentToast;
    return parent ? parent._openedToastRef : this._toastRefAtThisLevel;
  }

  set _openedToastRef(value: ToastRef<any> | null) {
    if (this._parentToast) {
      this._parentToast._openedToastRef = value;
    } else {
      this._toastRefAtThisLevel = value;
    }
  }

  private _attachToastContainer(
    overlayRef: OverlayRef,
    config: ToastConfig
  ): ToastContainerComponent {
    const customInjector = config.viewContainerRef?.injector;

    const injector = Injector.create({
      parent: customInjector || this._injector,
      providers: [{ provide: ToastConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(
      this.toastContainerComponent,
      config.viewContainerRef,
      injector
    );

    const containerRef: ComponentRef<ToastContainerComponent> =
      overlayRef.attach(containerPortal);
    containerRef.instance.toastConfig = config;

    return containerRef.instance;
  }

  private _attach<T>(
    content: ComponentType<T> | TemplateRef<T>,
    config?: ToastConfig
  ): ToastRef<T | EmbeddedViewRef<any>> {
    const toastConfig = { ...this.defaultConfig, ...config };

    const overlayRef = this._createOverlay(toastConfig);

    const container = this._attachToastContainer(overlayRef, toastConfig);

    const toastRef = new ToastRef<T | EmbeddedViewRef<any>>(
      container,
      overlayRef
    );

    if (content instanceof TemplateRef) {
      const portal = new TemplatePortal(content, null, {
        $implicit: toastConfig.data,
        toastRef,
      } as any);

      toastRef.instance = container.attachTemplatePortal(portal);
    } else {
      const injector = this._createInjector(toastConfig, toastRef);
      const portal = new ComponentPortal(content, undefined, injector);
      const contentRef = container.attachComponentPortal<T>(portal);

      toastRef.instance = contentRef.instance;
    }

    this._handleToastRef(toastRef, toastConfig);

    this._openedToastRef = toastRef;

    return this._openedToastRef;
  }

  private _createOverlay(config: ToastConfig): OverlayRef {
    const overlayConfig: OverlayConfig = new OverlayConfig();

    let positionStrategy: GlobalPositionStrategy = this.overlay
      .position()
      .global();

    this._setOverlayHorizontalPosition(
      positionStrategy,
      config.horizontalPosition
    );

    this._setOverlayVerticalPosition(positionStrategy, config.verticalPosition);

    overlayConfig.positionStrategy = positionStrategy;
    overlayConfig.hasBackdrop = config.hasBackdrop;

    if (config.panelClass) {
      overlayConfig.panelClass = config.panelClass;
    }

    return this.overlay.create(overlayConfig);
  }

  private _setOverlayHorizontalPosition(
    positionStrategy: GlobalPositionStrategy,
    horizontalPosition: ToastHorizontalPosition
  ): void {
    const isLeft: boolean =
      horizontalPosition === 'start' || horizontalPosition === 'left';

    const isRight =
      !isLeft &&
      (horizontalPosition === 'end' || horizontalPosition === 'right');

    if (isLeft) {
      positionStrategy.left('0');
    } else if (isRight) {
      positionStrategy.right('0');
    } else {
      positionStrategy.centerHorizontally();
    }
  }

  private _setOverlayVerticalPosition(
    positionStrategy: GlobalPositionStrategy,
    verticalPosition: ToastVerticalPosition
  ): void {
    const isTop = verticalPosition === 'top';

    if (isTop) {
      positionStrategy.top('0');
    } else {
      positionStrategy.bottom('0');
    }
  }

  private _createInjector<T>(
    config: ToastConfig,
    toastRef: ToastRef<T>
  ): Injector {
    const userInjector = config?.viewContainerRef?.injector;

    return Injector.create({
      parent: userInjector || this._injector,
      providers: [
        { provide: ToastRef, useValue: toastRef },
        { provide: TOAST_DATA, useValue: config.data },
      ],
    });
  }

  /**
   * Handles the toast duration (if provided) and if there's a toast already in the view,
   * this one is dismissed and the new one enters the view
   * @param toastRef ToastRef
   * @param config ToastConfig
   */
  private _handleToastRef(toastRef: ToastRef<any>, config: ToastConfig): void {
    toastRef.afterDismissed().subscribe(() => {
      if (this._openedToastRef === toastRef) {
        this._openedToastRef = null;
      }
    });

    if (config?.duration > 0) {
      toastRef.afterOpened().subscribe(() => {
        toastRef.dismissAfter(config.duration);
      });
    }

    if (this._openedToastRef) {
      this._openedToastRef.afterDismissed().subscribe(() => {
        toastRef.containerInstance.enter();
      });
      this._openedToastRef.dismiss();
    } else {
      toastRef.containerInstance.enter();
    }
  }

  ngOnDestroy(): void {
    if (this._toastRefAtThisLevel) {
      this._toastRefAtThisLevel.dismiss();
    }
  }
}
