<button
  aria-label="Loading button"
  [ngClass]="stateClasses"
  class="loading-button-container"
  [disabled]="disabled || (loading$ | async)"
  (click)="eventHandler($event)">
  <span>{{ title }}</span>

  <app-progress-spinner
    *ngIf="(loading$ | async) === true"></app-progress-spinner>
</button>
