<div class="no-sources">
  <mat-icon class="info-icon" svgIcon="warning-rounded"></mat-icon>
  <p class="info" i18n="settings integrations sources no source info">
    You have no sources connected to your system.
  </p>
  <p
    (click)="openAddSourceDialog()"
    class="link"
    i18n="settings integrations sources no source add source">
    Add a source
  </p>
</div>
