export type OverlayXPosition = 'before' | 'center' | 'after';

export type OverlayYPosition = 'above' | 'center' | 'below';

export interface DefaultOverlayConfig {
  minWidth: number;
  maxWidth: number;
  minHeight?: number;
  hasBackdrop?: boolean;
  hasDarkBackdrop?: boolean;
  backdropClasses?: string | string[];
  overlayContainerClasses?: string | string[];
  overlayFlexibleDimensions?: boolean;
  disableClose: boolean;
  lockPosition: boolean;
  positionX: OverlayXPosition;
  positionY: OverlayYPosition;
}

export const DEFAULT_OVERLAY_CONFIG: DefaultOverlayConfig = {
  minWidth: 220,
  maxWidth: 250,
  hasBackdrop: false, // Always true to close it clicking outside (even in the trigger)
  hasDarkBackdrop: false,
  backdropClasses: '',
  overlayFlexibleDimensions: false,
  disableClose: true,
  lockPosition: true,
  positionX: 'after',
  positionY: 'below',
};
