export const STRINGS = {
  columns: {
    suppliers: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      ID: {
        key: 'ID',
        name: $localize`Id`,
        filterKey: 'ID',
        currency: false,
        containers: false,
      },
      NAME: {
        key: 'NAME',
        name: $localize`Supplier name`,
        filterKey: 'NAME',
        currency: false,
        containers: false,
      },
      IGNORED: {
        key: 'IGNORED',
        name: $localize`Ignored`,
        filterKey: 'IGNORED',
        currency: false,
        containers: false,
      },
      ATTRIBUTES: {
        key: 'ATTRIBUTES',
        name: $localize`Ignored`,
        currency: false,
        containers: false,
      },
      MINIMUM_ORDER_VALUE: {
        key: 'MINIMUM_ORDER_VALUE',
        name: $localize`Minimal order value`,
        filterKey: 'MINIMUM_ORDER_VALUE',
        currency: false,
        containers: false,
      },
      FIXED_COSTS: {
        key: 'FIXED_COSTS',
        name: $localize`Fixed order costs`,
        filterKey: 'FIXED_COSTS',
        currency: false,
        containers: false,
      },
      LEAD_TIME: {
        key: 'LEAD_TIME',
        name: $localize`Lead time (days)`,
        filterKey: 'LEAD_TIME',
        currency: false,
        containers: false,
      },
      LEAD_TIME_MEAN_ACTUAL: {
        key: 'LEAD_TIME_MEAN_ACTUAL',
        name: $localize`Average lead time (days)`,
        filterKey: 'LEAD_TIME_MEAN_ACTUAL',
        currency: false,
        containers: false,
      },
      LEAD_TIME_SD_PCT: {
        key: 'LEAD_TIME_SD_PCT',
        name: $localize`Lead time deviation`,
        filterKey: 'LEAD_TIME_SD_PCT',
        currency: false,
        containers: false,
      },
      REPLENISHMENT_PERIOD: {
        key: 'REPLENISHMENT_PERIOD',
        name: $localize`Advised reorder period (days)`,
        filterKey: 'REPLENISHMENT_PERIOD',
        currency: false,
        containers: false,
      },
      USER_REPLENISHMENT_PERIOD: {
        key: 'USER_REPLENISHMENT_PERIOD',
        name: $localize`Reorder period (days)`,
        filterKey: 'USER_REPLENISHMENT_PERIOD',
        currency: false,
        containers: false,
      },
      CUTOFF: {
        key: 'CUTOFF',
        name: $localize`Supplier cut-off`,
        filterKey: 'CUTOFF',
        currency: false,
        containers: false,
      },
      REACTING_TO_BACKORDERS: {
        key: 'REACTING_TO_BACKORDERS',
        name: $localize`Reacting to backorders`,
        filterKey: 'REACTING_TO_BACKORDERS',
        currency: false,
        containers: false,
      },
      REACTING_TO_LOST_SALES: {
        key: 'REACTING_TO_LOST_SALES',
        name: $localize`Reacting to lost sales`,
        filterKey: 'REACTING_TO_LOST_SALES',
        currency: false,
        containers: false,
      },
      AGENDA_MANAGED_MANUALLY: {
        key: 'AGENDA_MANAGED_MANUALLY',
        name: $localize`Agenda managed manually`,
        filterKey: 'AGENDA_MANAGED_MANUALLY',
        currency: false,
        containers: false,
      },
      VOLUME: {
        key: 'VOLUME',
        name: $localize`Container volume`,
        filterKey: 'VOLUME',
        currency: false,
        containers: false,
      },
      WEIGHT: {
        key: 'WEIGHT',
        name: $localize`Container maximum weight`,
        filterKey: 'WEIGHT',
        currency: false,
        containers: false,
      },
      NOTES: {
        key: 'NOTES',
        name: $localize`Notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      HAS_NOTES: {
        key: 'HAS_NOTES',
        name: $localize`Has notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
    supplierProducts: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_ACTIVE: {
        key: 'SUPPLIER_PRODUCT_ACTIVE',
        name: $localize`Active`,
        filterKey: 'SUPPLIER_PRODUCT_ACTIVE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_AVAILABLE: {
        key: 'SUPPLIER_PRODUCT_AVAILABLE',
        name: $localize`Availability`,
        filterKey: 'SUPPLIER_PRODUCT_AVAILABLE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_PREFERRED: {
        key: 'SUPPLIER_PRODUCT_PREFERRED',
        name: $localize`Preferred`,
        filterKey: 'SUPPLIER_PRODUCT_PREFERRED',
        currency: false,
        containers: false,
      },
      SUPPLIER_NAME: {
        key: 'SUPPLIER_NAME',
        name: $localize`Supplier name`,
        filterKey: 'SUPPLIER_NAME',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_NAME: {
        key: 'SUPPLIER_PRODUCT_NAME',
        name: $localize`Supplier product name`,
        filterKey: 'SUPPLIER_PRODUCT_NAME',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_SKU: {
        key: 'SUPPLIER_PRODUCT_SKU',
        name: $localize`Supplier product SKU`,
        filterKey: 'SUPPLIER_PRODUCT_SKU',
        currency: false,
        containers: false,
      },
      PURCHASE_IN_QUANTITIES_OF: {
        key: 'PURCHASE_IN_QUANTITIES_OF',
        name: $localize`Lot size`,
        filterKey: 'PURCHASE_IN_QUANTITIES_OF',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_EAN_CODE: {
        key: 'SUPPLIER_PRODUCT_EAN_CODE',
        name: $localize`Supplier product EAN code`,
        filterKey: 'SUPPLIER_PRODUCT_EAN_CODE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_ARTICLE_CODE: {
        key: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        name: $localize`Supplier product article code`,
        filterKey: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      MINIMUM_PURCHASE_QUANTITY: {
        key: 'MINIMUM_PURCHASE_QUANTITY',
        name: $localize`MOQ`,
        filterKey: 'MINIMUM_PURCHASE_QUANTITY',
        currency: false,
        containers: false,
      },
      PRICE: {
        key: 'PRICE',
        name: $localize`Price`,
        filterKey: 'PRICE',
        currency: false,
        containers: false,
      },
      WEIGHT: {
        key: 'WEIGHT',
        name: $localize`Weight`,
        filterKey: 'WEIGHT',
        currency: false,
        containers: false,
      },
      VOLUME: {
        key: 'VOLUME',
        name: $localize`Volume`,
        filterKey: 'VOLUME',
        currency: false,
        containers: false,
      },
      LEAD_TIME: {
        key: 'LEAD_TIME',
        name: $localize`Lead time (days)`,
        filterKey: 'LEAD_TIME',
        currency: false,
        containers: false,
      },
      AVAILABLE_DATE: {
        key: 'AVAILABLE_DATE',
        name: $localize`Availability date`,
        filterKey: 'AVAILABLE_DATE',
        currency: false,
        containers: false,
      },
      CREATED_AT: {
        key: 'CREATED_AT',
        name: $localize`Created`,
        filterKey: 'CREATED_AT',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_NAME: {
        key: 'WEBSHOP_PRODUCT_NAME',
        name: $localize`Product name`,
        filterKey: 'WEBSHOP_PRODUCT_NAME',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_SKU: {
        key: 'WEBSHOP_PRODUCT_SKU',
        name: $localize`Product SKU`,
        filterKey: 'WEBSHOP_PRODUCT_SKU',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_EAN_CODE: {
        key: 'WEBSHOP_PRODUCT_EAN_CODE',
        name: $localize`Product EAN code`,
        filterKey: 'WEBSHOP_PRODUCT_EAN_CODE',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_ARTICLE_CODE: {
        key: 'WEBSHOP_PRODUCT_ARTICLE_CODE',
        name: $localize`Product article code`,
        filterKey: 'WEBSHOP_PRODUCT_ARTICLE_CODE',
        currency: false,
        containers: false,
      },
    },
  },
  tooltips: {
    required: $localize`This field is required`,
    maximumCapacity: $localize`The manually entered value for the desired maximum capacity of the container.`,
    containerVolume: $localize`The manually entered value for the desired maximum volume of the container.`,
    fixedOrderCosts: $localize`The costs you incur for placing an order. Consists of the time it takes to prepare and place an order and the starting rate of a shipment.`,
    minimumOrderValue: $localize`The minimum purchase quantity required by the supplier per order.`,
    leadTime: $localize`The delivery time agreeded by you with the supplier.`,
    reorderPeriod: $localize`The manually entered value for the desired number of days between orders per supplier.`,
    supplierProduct: {
      preferred: $localize`Preferred`,
      available: $localize`Available`,
      notAvailable: $localize`Not available`,
    },
  },
  notifications: {},
  metadata: {
    title: $localize`Suppliers`,
    suppliers: {
      actions: {
        create: $localize`Create supplier`,
        edit: $localize`Edit`,
        ignore: $localize`Ignore`,
        unignore: $localize`Unignore`,
        delete: $localize`Delete`,
        remove: $localize`Remove`,
        addNew: $localize`Add new...`,
        saveChanges: $localize`Save changes`,
      },
      warningIgnored: $localize`This supplier is marked as ignored and will not be considered when
      purchasing.`,
    },
    states: {
      loading: $localize`Loading contents...`,
      noData: $localize`There is no data available.`,
      changesNotAvailable: $localize`Your changes are not valid`,
      neverAdjustAgenda: $localize`We never adjust the agenda for the supplier.`,
      maximumCapacity: $localize`Maximum capacity must be greater than 0 kg`,
      containerVolume: $localize`Container volume must be greater than 0 m`,
      markedIgnored: $localize`This supplier is marked as ignored and will not be considered when
          purchasing.`,
      loadingSupplierSettings: $localize`Loading supplier settings...`,
      leadTime: $localize`Lead time must be longer than 0 days`,
      reorderPeriod: $localize`Reorder period must be longer than 0 days`,
    },
    tabs: {
      settings: $localize`Supply chain information`,
      supplierSettings: $localize`Information`,
      supplierProducts: $localize`Products`,
      communication: $localize`Communication`,
      emailSettings: $localize`Email settings`,
      agenda: $localize`Agenda`,
    },
    addSupplierDialog: {
      title: $localize`Add new supplier`,
      subtitle: $localize`Add a new supplier by filling the options below.`,
      supplierName: $localize`Supplier name`,
      email: $localize`Email`,
      invalidEmail: $localize`Invalid email`,
      addAnother: $localize`Add another`,
    },
  },
  details: {
    fixedOrderCosts: $localize`Fixed order costs`,
    minimumOrderValue: $localize`Minimum order value`,
    backorders: $localize`Backorders`,
    backordersDescription: $localize`If you enable this, additional back orders will be automatically scheduled.`,
    orderValue: $localize`Order value`,
    orderValueDescription: $localize`Backorders will be planned if the purchase value of the order is greater than:`,
    emergencyOrders: $localize`Avoid lost sales`,
    emergencyOrdersDescription: $localize`If you enable this, additional orders will be automatically scheduled when products are about to sell out.`,
    orderValueEmergencyDescription: $localize`Emergency orders will be scheduled if the purchase value of the order is higher than:`,
    reschedule: $localize`Reschedule`,
    rescheduleDescription: $localize`Reschedule the agenda for this supplier. Choose the first next order moment for this supplier.`,
    maximumCapacity: $localize`Maximum capacity`,
    containerVolume: $localize`Container volume`,
    ignoreSupplier: $localize`Ignore supplier`,
    ignoreSupplierDescription: $localize`Ignore this supplier and it will not be considered when purchasing.`,
    globalLocationNumber: $localize`Global Location Number (GLN)`,
    globalLocationNumberDescription: $localize`The GLN is a unique 13-digit identifier used to identify the specific physical or operational location of your warehouse. This number is used when placing orders through EDI.`,
    orderinLots: $localize`Order in lots`,
    orderLotsDescription: $localize`Generate the Excel/CSV/PDF of a purchase order with order quantities in lots.`,
    excludePurchasePrices: $localize`Exclude purchase prices`,
    excludePurchasePricesDescription: $localize`Generate the Excel/CSV/PDF of a purchase order excluding purchase prices.`,
    emailSettings: $localize`Email settings`,
    emailSettingsDescription: $localize`Email addresses used to send to recipients. Use a semicolon (';') to separate e-mail addresses.`,
    emailAddress: $localize`Email address(es)`,
    preferredEmailTemplate: $localize`Preferred email template`,
    collapse: $localize`Collapse`,
    expand: $localize`Expand`,
    leadTime: $localize`Lead time`,
    reorderPeriod: $localize`Reorder period`,
    supplierCutoffTime: $localize`Supplier cut-off time`,
    supplierCutoffTimeDescription: $localize`Orders placed after this specified time may not be accepted by the supplier. No purchase orders will be suggested beyond this point.`,
    supplierManager: $localize`Supplier manager`,
    supplierManagerDescription: $localize`User managing this supplier.`,
    placeholder: {
      globalLocationNumber: 'Global Location Number',
      supplierManager: $localize`Select a manager...`,
    },
    titles: {
      planning: $localize`Planning`,
      emergencyOrders: $localize`Emergency Orders`,
      container: $localize`Container`,
      general: $localize`General`,
      other: $localize`Other`,
      orderInformation: $localize`Order information`,
      emailSettings: $localize`Email settings`,
      insights: $localize`Additional info`,
      orderCycle: $localize`Order cycle`,
    },
    clearSupplierManager: $localize`Clear`,
    averageLeadTime: $localize`Average lead time`,
    leadTimeDeviation: $localize`Lead time deviation`,
    advisedReorderPeriod: $localize`Advised reorder period`,
    agendaManagedManually: $localize`Agenda managed manually`,
    communication: {
      order: {
        title: $localize`Order Placement Method`,
        description: $localize`Preferred communication method for placing orders with this supplier.`,
        email: $localize`Email`,
        edi: $localize`EDI`,
        none: $localize`None`,
      },
      emailSettings: {
        title: $localize`Email settings`,
        description: $localize`Email addresses used to send to recipients. Use a semicolon (';') to separate e-mail addresses.`,
        emailTemplates: {
          title: $localize`Email templates`,
        },
        emailAddress: $localize`Email address(es)`,
        placeholder: $localize`Ex. name@company.com`,
        preferredEmailTemplate: $localize`Preferred email template`,
      },
      gln: {
        title: $localize`Global Location Number (GLN)`,
        description: $localize`The GLN is a unique 13-digit identifier used to identify the specific physical or operational location for this supplier. This number is used when placing orders through EDI.`,
        tooltip: $localize`Click here for more information about EDI service`,
        placeholder: $localize`Global Location Number`,
      },
    },
  },
};
