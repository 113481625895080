import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSettingsComponent {
  @Input()
  label: string = MESSAGES.common.advancedSettings;
}
