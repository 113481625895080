import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';
import { TabLazyContentDirective } from '../../directives/tab-lazy-content.directive';
import { TabBase } from '../../core/tab-base';
import { TAB_TEMPLATE } from '../../model/tab.model';

@Component({
  selector: 'app-tab-template',
  templateUrl: './tab-template.component.html',
  styleUrls: ['../tab/tab.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: TAB_TEMPLATE, useExisting: TabTemplateComponent }],
})
export class TabTemplateComponent extends TabBase {
  @ViewChild(TemplateRef, { static: true })
  private _implicitContent: TemplateRef<any>;

  @ContentChild(TabLazyContentDirective, { read: TemplateRef, static: true })
  _lazyContent: TemplateRef<any> = undefined;

  @Input({ required: true })
  override label: string;

  @Input({ transform: booleanAttribute })
  override disabled: boolean;

  isGroup: boolean = false;

  get content(): TemplateRef<any> | undefined {
    return this._lazyContent || this._implicitContent;
  }
}
