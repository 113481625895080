export const MESSAGES = {
  notifications: {
    dismiss: $localize`Dismiss`,
    supplierDetails: {
      unknownSetting: $localize`Unknown setting`,
      UnknownSettingDescription: $localize`Unknown setting description`,
      settingsSaveFailed: $localize`Could not save supplier settings at this time.`,
      settingsSaved: $localize`Supplier settings saved.`,
      rescheduleSupplier: $localize`Rescheduled sucessfully`,
      cannotReplanRecently: $localize`This supplier has already been replanned too recently`,
      cannotReplanEffectivePeriod: $localize`This supplier has no effective replenishment period`,
    },
    supplierEditor: {
      loadMultiSupplierProductsFailed: $localize`Could not retrieve the supplier products to edit at this time`,
      savingMultipleSupplierProducts: $localize`Saving changes...`,
      savedMultipleSupplierProductsSuccess: $localize`Your changes have been saved successfully`,
      savedMultipleSupplierProductsFailed: $localize`Could not save the changes due to an error`,
    },
    supplierProduct: {
      deleteSupplierProductSuccess: $localize`Your changes have been saved successfully`,
      deleteSupplierProductFailed: $localize`Could not save the changes due to an error`,
      addingSupplierProduct: $localize`Adding the supplier...`,
    },
    authentication: {
      failed: $localize`An error occurred while performing user authentication, please contact support if the problem persists.`,
    },
    webshopSelector: { switchingWebshops: $localize`Switching webshops` },
    settings: {
      saving: $localize`Saving all settings...`,
      emailTemplatesSaveFailed: $localize`Could not save emails settings at this time.`,
      webshopSaveFailed: $localize`Could not save webshop information at this time.`,
      webshopSettingsSaveFailed: $localize`Could not save webshop settings at this time.`,
      allSaved: $localize`All settings were saved sucessfully.`,
    },
    conceptActions: {
      conceptSave: {
        savedConcept: $localize`Concept order saved`,
        savedConceptFailed: $localize`The order concept could not be saved due to an error`,
        conceptDeleted: $localize`Concept order deleted`,
        conceptDeletedFailed: $localize`The concept order could not be deleted due to an error`,
      },
      failedConceptRetrieval: $localize`Could not retrieve the concepts list at this time`,
    },
    manualOrderActions: {
      loadAvailableOrderlines: $localize`Could not retrieve the manual order lines list at this time`,
      loadAddedOrderlines: $localize`Could not retrieve the manual order lines list at this time`,
    },
    placedOrder: {
      orderPlaced: $localize`Your order has been placed successfully`,
      orderPlacedFailed: $localize`Could not place your order this time`,
    },
    skipOrder: {
      ignored: $localize`Order has been ignored`,
      ignoredFailed: $localize`The order could not be ignored due to an error`,
      skipped: $localize`Order has been skipped`,
      skippedFailed: $localize`The order could not be skipped due to an error`,
    },
    emailTemplates: {
      emailTemplateUpdated: $localize`Email template updated successfully`,
      emailTemplateUpdateFailed: $localize`Email template update failed`,
      emailTemplateSave: $localize`Email template saved successfully`,
      emailTemplateSaveFailed: $localize`Email template save failed`,
    },
    suppliers: {
      createSupplier: $localize`Supplier created successfully`,
      createSupplierFailed: $localize`Supplier already exists`,
      loadFailed: $localize`Could not retrieve the supplier list at this time`,
      saveEmailSuccessful: $localize`The email was created successfully`,
      saveEmailFailed: $localize`Could not save the email due to an error`,
      updateEmailsFailed: $localize`Could not update the email's list due to an error. The email might already exists.`,
    },
    products: {
      loadFailed: $localize`Could not retrieve the product list at this time`,
    },
    presentOrders: {
      loadFailed: $localize`Could not retrieve the buy orders list at this time`,
    },
    futureOrders: {
      loadFailed: $localize`Could not retrieve the buy orders list at this time`,
    },
    productDetails: {
      loadFailed: $localize`Could not retrieve complete product data due to an error`,
      saving: $localize`Saving the product settings...`,
      saveSuccess: $localize`Your product settings have been saved successfully`,
      saveFailed: $localize`Could not save the product settings due to an error`,
    },
    buyOrderEditor: {
      loadFailed: $localize`Could not retrieve the orderlines list at this time`,
    },
    conceptOrderEditor: {
      loadFailed: $localize`Could not retrieve the concept lines list at this time`,
    },
    deliveriesOrders: {
      savingOrder: $localize`Saving the order...`,
      saveSuccess: $localize`Your order has been saved successfully`,
      saveFailed: $localize`Could not save the order due to an error`,
      closingOrder: $localize`Closing the order...`,
      closeOrderSuccess: $localize`Your order has been saved and closed successfully`,
      closeOrderFailed: $localize`Could neither save nor complete the order due to an error`,
      orderUpdateSuccess: $localize`Your order has been updated successfully`,
      orderUpdateFailed: $localize`Could not update your order due to an error`,
    },
    overdueDeliveriesOrderEditor: {
      loadFailed: $localize`Could not retrieve the overdue orders lines list at this time`,
    },
    expectedDeliveriesOrderEditor: {
      loadFailed: $localize`Could not retrieve the expected order lines list at this time`,
    },
    promotions: {
      saving: $localize`Saving the promotion...`,
      saveSuccess: $localize`Your promotion has been saved successfully`,
      saveFailed: $localize`Could not save the promotion due to an error`,
      updating: $localize`Updating the promotion...`,
      updateSuccess: $localize`Your promotion has been updated successfully`,
      updateFailed: $localize`Could not update the promotion due to an error`,
      loadFailed: $localize`Could not retrieve the promotion list at this time`,
      createPromotion: $localize`Promotion created successfully`,
      createPromotionFailed: $localize`Could not create promotion`,
      editPromotion: $localize`Promotion edited successfully`,
      editPromotionFailed: $localize`Could not edit promotion`,
      deletePromotion: $localize`Promotion deleted successfully`,
      deletePromotionFailed: $localize`Could not delete promotion`,
      loadWebshopProductPromotionsFailed: $localize`Could not retrieve the promotions for this product at this time`,
      savingWebshopProduct: $localize`Saving the promotion's webshop product...`,
      saveWebshopProductSuccess: $localize`Your promotion's webshop product has been saved successfully`,
      saveWebshopProductFailed: $localize`Could not save the promotion's webshop product due to an error`,
      updatingWebshopProduct: $localize`Updating the promotion's webshop product ...`,
      updateWebshopProductSuccess: $localize`Your promotion's webshop product has been updated successfully`,
      updateWebshopProductFailed: $localize`Could not update the promotion's webshop product due to an error`,
      addingPromotion: $localize`Adding the promotion...`,
      addPromotionSuccess: $localize`The promotion has been added successfully`,
      addPromotionFailed: $localize`Could not add the promotion due to an error`,
    },
    compositions: {
      loadCompositions: $localize`Could not retrieve the composition list at this time`,
      loadParts: $localize`Could not retrieve the parts list at this time`,
    },
    deliveries: {
      loadDeliveries: $localize`Could not retrieve the deliveries list at this time`,
    },
    salesGraph: {
      loadFailed: $localize`Could not load the sales history due to an error`,
      noFilteredData: $localize`No data is available for the selected time frame.`,
      filtered: $localize`The graph data has been refreshed`,
      filtering: $localize`The graph data is being reloaded`,
    },
    app: {
      loading: $localize`Loading...`,
      loadSuccess: $localize`Loaded successfully`,
      loadFailed: $localize`Could not load due to an error`,
      saving: $localize`Saving...`,
      saveSuccess: $localize`Saved successfully`,
      saveFailed: $localize`Could not save due to an error`,
    },
    integrations: {
      refreshSuccess: $localize`Jobs refreshed successfully`,
      refreshFailed: $localize`Could not refresh jobs due to an error`,
    },
    preferences: {
      saveThemeFailed: $localize`Could not save your theme preferences due to an error`,
      changingLanguage: $localize`Changing language`,
    },
    stockLevelHistoryGraph: {
      loadFailed: $localize`Could not load the service level history due to an error`,
    },
    exports: {
      renameExportSuccessful: $localize`Renamed export successfully`,
      renameExportFailed: $localize`Could not rename the export due to an error`,
      downloadExportSuccessful: $localize`Downloaded export successfully`,
      downloadExportFailed: $localize`Could not download the export due to an error`,
      exportPromotionsDispatched: $localize`Exporting promotions...`,
      exportPromotionsSuccessful: $localize`Exported promotions successfully`,
      exportPromotionsFailed: $localize`Could not export promotions due to an error`,
      exportProductsDispatched: $localize`Exporting products...`,
      exportProductsSuccessful: $localize`Exported products successfully`,
      exportProductsFailed: $localize`Could not export products due to an error`,
      exportSuppliersDispatched: $localize`Exporting suppliers...`,
      exportSuppliersSuccessful: $localize`Exported suppliers successfully`,
      exportSuppliersFailed: $localize`Could not export suppliers due to an error`,
      exportPromotionProductsDispatched: $localize`Exporting promotion products...`,
      exportPromotionProductsSuccessful: $localize`Exported promotion products successfully`,
      exportPromotionProductsFailed: $localize`Could not export promotion products due to an error`,
      exportProductPromotionsDispatched: $localize`Exporting product promotions...`,
      exportProductPromotionsSuccessful: $localize`Exported product promotions successfully`,
      exportProductPromotionsFailed: $localize`Could not export product promotions due to an error`,
      exportProductSuppliersDispatched: $localize`Exporting product suppliers...`,
      exportProductSuppliersSuccessful: $localize`Exported product suppliers successfully`,
      exportProductSuppliersFailed: $localize`Could not export product suppliers due to an error`,
      exportSupplierProductsDispatched: $localize`Exporting supplier products...`,
      exportSupplierProductsSuccessful: $localize`Exported supplier products successfully`,
      exportSupplierProductsFailed: $localize`Could not export supplier products due to an error`,
    },
  },
  common: {
    advancedSettings: $localize`Advanced settings`,
    other: $localize`other`,
    copiedToClipboard: $localize`copied to clipboard`,
    copy: {
      copyToClipboard: $localize`Copy to clipboard`,
      copiedToClipboard: $localize`Copied to clipboard`,
    },
    edit: $localize`Edit`,
    view: $localize`View`,
    permissionOptions: {
      edit: $localize`Edit`,
      view: $localize`View`,
    },
    remove: $localize`Remove`,
    noAccess: $localize`No access`,
    true: $localize`True`,
    false: $localize`False`,
    apply: $localize`Apply`,
    assembled: $localize`Assembled`,
    phasedOut: $localize`Phased Out`,
    phasedOutUntil: $localize`Phased Out until`,
    ignored: $localize`Ignored`,
    selected: $localize`selected`,
    selectedTitle: $localize`Selected`,
    clearSelection: $localize`Clear selection`,
    selectedItems: $localize`item(s) selected`,
    defaultEmailTemplate: $localize`This is the prefilled email template`,
    enterText: $localize`Enter text`,
    addAnother: $localize`Add another`,
    exportAs: $localize`Export as...`,
    importFile: $localize`Import to Optiply...`,
    selectDate: $localize`Select a date`,
    salesGraph: {
      salesHistory: $localize`Sales history`,
      futureSales: $localize`Future sales`,
      refresh: $localize`Please refresh this chart.`,
      today: $localize`Today`,
    },
    multiSupplierEditor: {
      availabilityStatus: $localize`Product should be available to be preferred`,
    },
    stockLevelHistory: {
      categoryA: $localize`Category A`,
      categoryB: $localize`Category B`,
      categoryC: $localize`Category C`,
    },
    supplyChainPurchaseAdvice: {
      tdspOn: $localize`Free stock plus expected deliveries in cycle`,
      tdspOff: $localize`Free stock plus all expected deliveries`,
    },
    open: $localize`Open`,
    closed: $localize`Closed`,
    options: $localize`Options`,
    syncDataOn: $localize`Auto-updates: On`,
    syncDataRefreshing: $localize`Refreshing data...`,
    syncDataOff: $localize`Auto-updates: Off`,
    syncDataTooltip: $localize`Updates all data in this order without changing manual changes. Advised before placing an order, making sure data is updated.`,
    save: $localize`Save`,
    saving: $localize`Saving`,
    cancel: $localize`Cancel`,
    dismiss: $localize`Dismiss`,
    done: $localize`Done`,
    confirm: $localize`Confirm`,
    delete: $localize`Delete`,
    help: $localize`Help`,
    selection: {
      warningPrefix: $localize`You can\'t select more than `,
      warningSuffix: $localize` result(s) at a time to perform actions`,
    },
    filter: {
      applyAllTables: $localize`Apply to all tables`,
      filterBy: $localize`Filter`,
      toggleTitle: $localize`Filters`,
      defaultFiltersPlaceholder: $localize`Select`,
      columnFiltersPlaceholder: $localize`Select a column`,
      search: $localize`Search`,
      defaultFilterName: $localize`Filter`,
      clear: $localize`Clear`,
      add: $localize`Add filter`,
      cancel: $localize`Cancel`,
      apply: $localize`Apply`,
      all: $localize`All`,
      show: $localize`Show`,
      clearAll: $localize`Clear filters`,
      remove: $localize`Remove`,
      removeFilter: $localize`Remove filter`,
      warningFilterTooltip: $localize`warning(s). Address these warnings for improved performance.`,
      dangerFilterTooltip: $localize`critical issue(s). Solve these issues to allow purchasing.`,
      optionTypes: {
        search: $localize`Search`,
        equals: $localize`Equals`,
        range: $localize`Between (range)`,
        from: $localize`From`,
        to: $localize`To`,
        optionTrue: $localize`True`,
        optionFalse: $localize`False`,
        notExists: $localize`Does not exist`,
        isEmpty: $localize`Is empty`,
        isNotEmpty: $localize`Is not empty`,
      },
      options: $localize`Click for options`,
      disabledOptions: $localize`This column has no available options.`,
      disabledFilterColumn: $localize`This column cannot be filtered.`,
    },
    pagination: {
      page: $localize`Page`,
      of: $localize`of`,
      itemsPerPage: $localize`Items per page`,
      firstPage: $localize`First page`,
      previousPage: $localize`Previous page`,
      nextPage: $localize`Next page`,
      lastPage: $localize`Last page`,
      sort: {
        order: {
          sort: $localize`Sort`,
          asc: $localize`Sort (Ascending)`,
          desc: $localize`Sort (Descending)`,
          first: $localize`first`,
          last: $localize`last`,
        },
        disabled: $localize`This column cannot be sorted.`,
      },
    },
    snooze: {
      indefiniteDate: $localize`Indefinite Date.`,
      selectDate: $localize`Please select a date.`,
    },
    overview: {
      hide: $localize`Hide`,
      show: $localize`Show more information`,
    },
    daySymbol: $localize`day(s)`,
    newLabel: {
      label: $localize`New`,
      options: {
        newProducts: $localize`New products`,
        regularProducts: $localize`Regular products`,
      },
    },
    exports: {
      title: $localize`Export order details`,
      deliveriesTitle: $localize`Export details`,
      exportOptions: $localize`Export options`,
      advancedExportSettings: $localize`Advanced export settings`,
      exportCSV: $localize`Export as .csv`,
      exportXLSX: $localize`Export as .xlsx`,
      exportPDF: $localize`Export as .pdf`,
    },
    otherOptions: $localize`Other options`,
    emailAddresses: $localize`Email address(es)`,
    enterEmail: $localize`Enter the email`,
    notes: $localize`Maximum 500 characters allowed`,
    plusMore: $localize`+ more`,
    noDataAvailable: $localize`There is no data available.`,
    areYouSureProceed: $localize`Are you sure you want to proceed?`,
    statusPage: {
      title: $localize`Open Incidents`,
      goToButton: $localize`Go to status page`,
      systemOperational: $localize`All systems operational`,
      disabledTooltip: $localize`Service unavailable`,
    },
    actionCannotBeUndone: $localize`This action cannot be undone.`,
    next: $localize`Next`,
    goTo: $localize`Go to`,
    roles: {
      isOwner: $localize`Owner`,
      isShopAdministrator: $localize`Shop administrator`,
    },
    externalSourceInfo: $localize`Please contact us for more information on connecting to this source.`,
    connectedSources: $localize`Connected sources`,
    searchSource: $localize`Search for a source...`,
    sendEmail: $localize`Send email`,
    resendEmail: $localize`Resend email`,
    emailSent: $localize`Email sent`,
    mins: $localize`min(s)`,
    seconds: $localize`second(s)`,
    saveChanges: $localize`Save`,
    edi: $localize`EDI`,
    email: $localize`Email communication`,
    loading: $localize`Loading...`,
    refresh: $localize`Refresh`,
    preventChangesWarning: $localize`Changes can be made once every`,
  },
};
