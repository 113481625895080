import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FilterOption, FilterTypeOptions } from '../../../model/filter.model';

export interface SelectedFilter {
  option: string;
}

export const defaultFilterOptions: FilterOption[] = [
  {
    key: MESSAGES.common.filter.optionTypes.equals,
    value: FilterTypeOptions.EQUALS,
  },
  {
    key: MESSAGES.common.filter.optionTypes.range,
    value: FilterTypeOptions.RANGED,
  },
];

export const filterOptionsWithEmpty: FilterOption[] = [
  ...defaultFilterOptions,
  {
    key: MESSAGES.common.filter.optionTypes.isEmpty,
    value: FilterTypeOptions.IS_NULL,
  },
];
