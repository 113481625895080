import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { SortedOrder, SortOrder } from '../../../sort/model/sort.model';
import { ColumnFilter, FilterTypes } from '../../model/filter.model';
import { OverlayDirective } from 'src/app/shared/components/design-system/overlay-container-v2/directives/overlay.directive';
import { FilterableV2Directive } from '../../directives/filterable-v2.directive';
import {
  ColumnFilterData,
  ColumnFilterParam,
} from '../../../../model/data-table-v2.model';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FILTER_SHELL, FilterShell } from '../../model/filter-v2.model';

@Component({
  selector:
    'app-filter-column-header-v2, [app-filter-column-header-v2], [appFilterColumnHeaderV2]',
  templateUrl: './filter-column-header-v2.component.html',
  styleUrl: './filter-column-header-v2.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: FILTER_SHELL, useExisting: FilterColumnHeaderV2Component },
  ],
})
export class FilterColumnHeaderV2Component
  implements FilterShell, OnInit, OnDestroy
{
  overlay = viewChild.required(OverlayDirective);

  data = input.required<ColumnFilterData>({
    alias: 'appFilterColumnHeaderV2',
  });

  key = computed(() => this.data()?.columnKey);

  filter = computed<ColumnFilter>(() => this.data()?.filter);

  filtered = computed(() => this.data()?.filtered);

  sort = computed<SortedOrder>(() => this.data()?.sorted);

  label = input<string>();

  disableSort = input(this.filterable.disableSort(), {
    transform: booleanAttribute,
  });

  hideSort = input(this.filterable.hideSort(), {
    transform: booleanAttribute,
  });

  disableHideColumn = input(this.filterable.disableHideColumn(), {
    transform: booleanAttribute,
  });

  hideHideColumn = input(this.filterable.hideHideColumn(), {
    transform: booleanAttribute,
  });

  hideFilter = input(this.filterable.hideFilter(), {
    transform: booleanAttribute,
  });

  allOptionsAreDisabled = computed(
    () =>
      this.disableSort() && this.disableHideColumn() && this.filter().disabled
  );

  filterColumnIdx = computed(() => {
    if (this.key()) {
      const keysArray = Array.from(this.filterable.columns.keys());

      return keysArray.indexOf(this.key());
    }

    return 0;
  });

  filterAlignment = computed(() =>
    this.filter().uiModel === this.FILTER_TYPES.FILTER_NUMBER &&
    this.filterColumnIdx() !== 0
      ? 'end'
      : 'start'
  );

  active = signal(false);

  readonly FILTER_TYPES = FilterTypes;

  readonly SORTED_ORDER = SortOrder;

  readonly FILTER_STRINGS = MESSAGES.common.filter;

  constructor(
    private el: ElementRef,
    private filterable: FilterableV2Directive
  ) {
    this._removeHostHeaderPadding();
  }

  ngOnInit(): void {
    this.filterable.register(this);
  }

  _handleClick(): void {
    if (!this.allOptionsAreDisabled()) {
      this.active.set(true);
    }
  }

  _closeOverlay(): void {
    this.active.set(false);

    this.overlay()._closeOverlay();
  }

  /**
   * Emits the sorted order along with the filter's key
   * @param order asc/desc
   */
  onSort(order: SortedOrder): void {
    this.filterable.sort({
      persistenceKey: this.key(),
      key: this.filter().key,
      order,
    });
    this._closeOverlay();
  }

  /**
   * Emits the hide column.
   * Uses the column's key instead the filter's key
   */
  onHide(): void {
    this.filterable.hide(this.key());
    this._closeOverlay();
  }

  /**
   * Emits the filtered params.
   * @param param {key, optionSelected, value}
   */
  onFilter(param: ColumnFilterParam): void {
    this.filterable.filter({
      ...param,
      columnKey: this.key(),
    });
    this._closeOverlay();
  }

  /**
   * Emits to remove the filter. All filter params should be null.
   */
  onRemove(): void {
    this.filterable.filter({
      columnKey: this.key(),
      optionSelected: null,
      subOperator: null,
      values: null,
      applyAll: false,
    });
    this._closeOverlay();
  }

  /**
   * Only closes the overlay.
   */
  onCancel(): void {
    this._closeOverlay();
  }

  /**
   * Updates overlay position when on user input using the forms.
   *
   * Use if the overlay's height/width changes on user input (eg. conditional divs).
   */
  updateOverlayPosition() {
    this.overlay()._updatePosition();
  }

  private _removeHostHeaderPadding(): void {
    this.el.nativeElement.style.padding = '0';
  }

  ngOnDestroy(): void {
    this.active.set(false);

    this.filterable.deregister(this);
  }
}
