export class InitializeNotes {
  static readonly type = '[Purchase Notes] Initialize Notes';
}

export class UpdateNotes {
  static readonly type = '[Purchase Notes] Update Notes';

  constructor(public change: string) {}
}

export class SaveNotes {
  static readonly type = '[Purchase Notes] Save Notes';
}

export class CancelNotes {
  static readonly type = '[Purchase Notes] Cancel Notes';
}

export class ResetNotes {
  static readonly type = '[Purchase Notes] Reset Notes';
}
