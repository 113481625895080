export interface NavigationData {
  path: string;
}

export class NavigationChanged {
  static readonly type = '[Navigation] Changed';

  constructor(public data: NavigationData) {}
}

export class NavigationStarted {
  static readonly type = '[Navigation] Started';
}

export class NavigationEnded {
  static readonly type = '[Navigation] Ended';
}

export class NavigateTo {
  static readonly type = '[Navigation] Navigate To';

  constructor(public path: string[]) {}
}

export class NavigationBackClicked {
  static readonly type = '[Navigation] Back Clicked';
}

export class NavigateToWithQueryParams {
  static readonly type = '[Navigation] Navigate To';

  constructor(
    public path: string[],
    public queryParams?: Object
  ) {}
}

export class RefreshNavigation {
  static readonly type = '[Navigation] Refresh Navigation';

  constructor(public webshopUuid?: string) {}
}
