import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'goBack',
})
export class GoBackPipe implements PipeTransform {
  private titlePreffix = $localize`Go back to`;

  transform(pageTitle: string): string {
    if (!pageTitle) return;

    return `${this.titlePreffix} ${pageTitle.toLocaleLowerCase()}`;
  }
}
