import { ExportDataV2 } from 'src/app/shared/models/exports/exports-v2.model';
import { Promotion } from 'src/app/shared/models/promotion/promotion.model';
import { SelectedWebshopProduct } from '../../products-v2/model/products-v2.model';
import { DialogRef } from '@angular/cdk/dialog';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  CreatePromotionFormProperties,
  UpdatePromotionFormProperties,
} from '../model/promotions-v2.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PromotionUpliftTypes } from 'src/app/shared/models/promotion/v2/promotion-v2.model';

export class InitializePromotionsState {
  static readonly type = '[Promotions V2] Initialize';
}

export class LoadPromotions {
  static readonly type = '[Promotions V2] Load Promotions';
}

export class ReloadDatatable {
  static readonly type = '[Promotions V2] Reload';
}

export class LoadPromotion {
  static readonly type = '[Promotions V2] Load Promotion';

  constructor(public promotionUUID: string) {}
}

export class LoadPromotionsFiltered {
  static readonly type = '[Promotions V2] Load Promotions Filtered';

  constructor(public search: string) {}
}

export class ResetCurrentPromotion {
  static readonly type = '[Promotions] Reset Current Promotion';
}

export class Paginate {
  static readonly type = '[Promotions V2] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Promotions V2] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class AddSearchParam {
  static readonly type = '[Promotions V2] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Promotions V2] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Promotions V2] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Promotions V2] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Promotions V2] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Promotions V2] Remove All Filters';
}

export class ColumnsSelected {
  static readonly type = '[Promotions V2] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Promotions V2] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Promotions V2] Toggle Filter';
}

export class ToggleRowSelection {
  static readonly type = '[Promotions V2] Toggle Row Selection';

  constructor(
    public promotion: Promotion,
    public isSelected: boolean
  ) {}
}

export class ClearSelection {
  static readonly type = '[Promotions V2] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type = '[Promotions V2] Master Toggle Selection';
}

export class DeletePromotionFromTable {
  static readonly type = '[Promotions] Delete Promotion From Table';

  constructor(public promotionUuid: string) {}
}

export class DeletePromotionsFromTable {
  static readonly type = '[Promotions] Delete Promotions From Table';
}

export class DeletePromotion {
  static readonly type = '[Promotions V2] Delete Promotion';

  constructor(public dialogRef?: DialogRef<any>) {}
}

export class EnablePromotionFromTable {
  static readonly type = '[Promotions] Enable Promotion From Table';

  constructor(
    public promotionUuid: string,
    public promotionName: string
  ) {}
}

export class EnablePromotionsFromTable {
  static readonly type = '[Promotions] Enable Promotions From Table';
}

export class EnablePromotion {
  static readonly type = '[Promotions] Enable Promotion';
}

export class DisablePromotionFromTable {
  static readonly type = '[Promotions] Disable Promotion From Table';

  constructor(
    public promotionUuid: string,
    public promotionName: string
  ) {}
}

export class DisablePromotionsFromTable {
  static readonly type = '[Promotions] Disable Promotions From Table';
}

export class DisablePromotion {
  static readonly type = '[Promotions] Disable Promotion';
}

export class CreatePromotion {
  static readonly type = '[Promotions V2] Create Promotion';

  constructor(
    public settings: CreatePromotionFormProperties,
    public dialogRef: DialogRef<any>
  ) {}
}

export class EditedPromotionFromTable {
  static readonly type = '[Promotions] Edited From Table';
}

export class EditPromotion {
  static readonly type = '[Promotions V2] Edit';

  constructor(
    public settings: UpdatePromotionFormProperties,
    public dialogRef?: DialogRef<any>
  ) {}
}

export class EditedPromotion {
  static readonly type = '[Promotion Products Editor V2] Edited Promotion';

  constructor(public settings: UpdatePromotionFormProperties) {}
}

export class ExportPromotionsV2 {
  static readonly type = '[Promotions V2] Export';

  constructor(public exportData: ExportDataV2) {}
}

export class AddMultipleProductsToPromotion {
  static readonly type = '[Promotions V2] Add Multiple Product To Promotion';

  constructor(
    public promotionUuid: string,
    public products: SelectedWebshopProduct[]
  ) {}
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Promotions V2] Reset Pagination And Load Data';
}

export class UpdateFormChanges {
  static readonly type = '[Promotion Products Editor V2] Update Form Changes';

  constructor(public valid: boolean) {}
}

export class UpdateFormValidation {
  static readonly type =
    '[Promotion Products Editor V2] Update Form Validation';
}

export class UpdatePromotionName {
  static readonly type = '[Promotion Products Editor V2] Update Promotion Name';

  constructor(public change: string) {}
}
export class UpdateStartDate {
  static readonly type = '[Promotion Products Editor V2] Update Start Date';

  constructor(public change: string) {}
}
export class UpdateEndDate {
  static readonly type = '[Promotion Products Editor V2] Update End Date';

  constructor(public change: string) {}
}
export class UpdateUpliftType {
  static readonly type = '[Promotion Products Editor V2] Update Uplift Type';

  constructor(public change: PromotionUpliftTypes) {}
}
export class UpdateIncrease {
  static readonly type = '[Promotion Products Editor V2] Update Increase';

  constructor(public change: number) {}
}
export class UpdateEnabled {
  static readonly type = '[Promotion Products Editor V2] Update Enabled';

  constructor(public change: boolean) {}
}
