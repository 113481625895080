<app-dialog-container>
  <div appDialogHeader>
    <mat-icon svgIcon="arrow-upload" class="menu-icon"></mat-icon>
    <h1 appDialogTitle>{{ STRINGS.importDialogTitle }}</h1>
    <button
      aria-label="Imports dialog close button"
      (click)="close()"
      class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div appDialogBody>
    <div class="confirmation-container">
      <mat-icon class="info-icon" svgIcon="info-rounded-xs"></mat-icon>
      <p class="sub-title">
        {{ STRINGS.updateSubLabel1 }}
      </p>
      <p class="sub-title-bold">
        {{ STRINGS.updateSubLabel2 }}
      </p>
      <p class="sub-title">
        {{ STRINGS.updateSubLabel3 }}
      </p>
    </div>

    <div class="imports-container">
      <div class="imports-input-section">
        <label for="format" class="label">{{
          STRINGS.importDialogSubTitle
        }}</label>
        <div
          class="input-data-container"
          [formGroup]="parentForm"
          appRadioButtonGroup>
          <div class="import-row">
            <div
              [class]="
                radioButtons.id
                  ? 'import-radio-selected'
                  : 'import-radio-container'
              "
              (click)="checkRadioType('ID')">
              <app-radio-button
                name="type"
                value="ID"
                [(checked)]="radioButtons.id"
                [label]="STRINGS.radioButtons.optiplyID"></app-radio-button>
              <label class="sub-label">{{
                STRINGS.radioButtons.optiplyIDDescription
              }}</label>
            </div>
            <div
              [class]="
                radioButtons.name
                  ? 'import-radio-selected'
                  : 'import-radio-container'
              "
              (click)="checkRadioType('NAME')">
              <app-radio-button
                name="type"
                value="SUPPLIER_NAME"
                [(checked)]="radioButtons.name"
                (radioChange)="checkRadioType($event)"
                [label]="STRINGS.radioButtons.supplierName"></app-radio-button>
              <label class="sub-label">{{
                STRINGS.radioButtons.supplierNameDescription
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div appDialogActions>
    <div class="action-buttons-container">
      <button
        aria-label="Imports dialog cancel button"
        appButton
        variant="tertiary"
        class="optiply-tertiary-btn"
        (click)="close()">
        {{ COMMON_STRINGS.cancel }}
      </button>

      <usecsv-button
        [importerKey]="importKey()"
        [dynamicColumns]="dynamicColumnsWithStatus"
        [onData]="importData"
        [acceptedFileFormats]="['csv']"
        [batchSize]="2000"
        [theme]="colorTheme() === 'dark' ? useCSVDarkTheme : useCSVLightTheme"
        [customText]="{
          importedRows: {
            text: '{{rows_count}} ' + STRINGS.useCSVCustomText
          }
        }"
        [defaultLanguage]="language() === 'en-US' ? 'en' : 'nl'"
        [onClose]="onClose"
        importerDisplay="inline">
        <button
          customUsecsvButton
          aria-label="Imports dialog import button"
          appButton
          variant="primary"
          (click)="openUsecsvDialog()">
          {{ COMMON_STRINGS.next }}
        </button></usecsv-button
      >
    </div>
  </div>
</app-dialog-container>
