import { Inject, Injectable, LOCALE_ID, Signal } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { select } from '@ngxs/store';
import { ConfigState } from 'src/app/core/states/config.state';
import { environment } from 'src/environments/environment';

/**
 * Configuration service store that contains all the front-end configurations
 * and operations.
 */
@Injectable({ providedIn: 'root' })
export class ConfigStoreService {
  private config: Signal<any> = select(ConfigState.config);

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private authConfig: AuthClientConfig
  ) {
    this.authConfig.set({
      domain: environment.domain,
      clientId: environment.client_id,
      authorizationParams: {
        redirect_uri:
          window.location.origin +
          environment.base_path +
          this.localeId +
          environment.redirect_uri,
        audience: environment.audience,
      },
      useRefreshTokens: true,
      httpInterceptor: { allowedList: environment.allowedList },
    });
  }

  /**
   * \
   * Returns the metadata for a specific service and version.
   *
   * @param service service the service name, e.g: auth.
   * @param version version the api version.
   * @param resource resource the resource name that is part of the metadata.
   * @returns the resource name or null if it does not exist.
   */
  getMetadata(service: string, version: string, resource: string): any {
    if (
      this.config()?.api.hasOwnProperty(service) &&
      this.config()?.api[service].hasOwnProperty(version) &&
      this.config()?.api[service][version].metadata.hasOwnProperty(resource)
    ) {
      return this.config().api[service][version].metadata[resource];
    }
    return null;
  }

  /**
   * Returns a full formed endpoint uri from the current configuration.
   * Keywords can be replaced for supported endpoints.
   *
   * @param service service the service name, e.g: auth.
   * @param version version the api version.
   * @param resource resource the endpoint unique resource name, e.g: token.
   * @param replace replace the data that will be inserted in the uri.
   * @returns a completely formed uri.
   */
  getEndpointForResource(
    service: string,
    version: string,
    resource: string,
    replace: any[]
  ): string {
    if (
      this.config()?.api.hasOwnProperty(service) &&
      this.config()?.api[service].hasOwnProperty(version) &&
      this.config()?.api[service][version].endpoints.hasOwnProperty(resource)
    ) {
      const apiResource = this.config().api[service][version];
      let uri =
        apiResource.metadata.baseUrl +
        apiResource.metadata.basePath +
        apiResource.endpoints[resource];

      replace.forEach(value => {
        uri = uri.replace('{' + value.name + '}', value.value);
      });

      return uri;
    }

    throw new Error(
      `Api resource has not been found with service: ${service} version: ${version} resource: ${resource}`
    );
  }
}
