import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-datatable-container-wrapper',
  templateUrl: './data-table-container-wrapper.component.html',
  styleUrl: './data-table-container-wrapper.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DataTableContainerWrapperComponent {
  title = input<string>();

  subTitle = input<string>();
}
