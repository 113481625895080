import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { select, Store } from '@ngxs/store';
import { PurchaseConfirmationDataV2 } from './model/purchase-confirmation-v2.model';
import { AppState } from 'src/app/core/states/app.state';
import { PurchaseConfirmationQueries } from './state/purchase-confirmation-dialog-v2.queries';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  DonePurchaseConfirmation,
  HandlePlaceOrder,
  SetUseSupplierSettings,
} from './actions/purchase-confirmation-dialog-v2.actions';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { SuppliersV2StateQueries } from 'src/app/features/suppliers-v2/state/suppliers-v2.queries';
import { BuyOrderSettingsState } from 'src/app/core/states/buyorder-settings.state';
import { ConceptOrderlinesStateQueries } from 'src/app/features/purchase-v3/components/concept-lines/state/concept-orderlines.queries';
import { EmailTemplatesState } from '../email-management/state/email-templates.state';
import { EmailManagementStateQueries } from '../email-management/state/email-management.queries';
import { SupplierCommunications } from '../../models/suppliers/v2/supplier-v2.model';
import { distinctUntilKeyChanged, filter, first, map, switchMap } from 'rxjs';
import {
  EmailTemplateLanguage,
  EmailTemplateTypes,
} from '../../models/emails/v1/emails.model';
import {
  LoadAccountEmailTemplates,
  LoadSupplierEmailTemplates,
  LoadWebshopEmailTemplates,
} from '../email-management/actions/email-templates.action';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-purchase-confirmation-dialog-v2',
  templateUrl: './purchase-confirmation-dialog-v2.component.html',
  styleUrls: ['./purchase-confirmation-dialog-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseConfirmationDialogV2Component implements OnInit {
  saving = select(AppState.isSaving);

  loading = select(PurchaseConfirmationQueries.loading);

  orderPlaced = select(PurchaseConfirmationQueries.orderPlaced);

  shouldReplan = select(PurchaseConfirmationQueries.shouldReplan);

  storeProductChanges = select(
    PurchaseConfirmationQueries.shouldSaveWebshopProductChanges
  );

  isOrderNotSynced = select(PurchaseConfirmationQueries.isOrderNotSynced);

  useSupplierSettings = select(PurchaseConfirmationQueries.useSupplierSettings);

  supplierName = select(PurchaseConfirmationQueries.supplierName);

  buyOrderId = select(PurchaseConfirmationQueries.buyOrderId);

  orderEmails = select(EmailManagementStateQueries.orderEmails);

  hasSupplierEmails = select(SuppliersV2StateQueries.hasSupplierEmails);

  supplierAgendaManagedManually = select(
    SuppliersV2StateQueries.currentSupplierAgenda
  );

  preferredLanguage = select(
    SuppliersV2StateQueries.currentSupplierPreferredEmailLanguage
  );

  communicationMethod = select(
    SuppliersV2StateQueries.currentSupplierCommunicationMethod
  );

  isValidTemplate = select(EmailTemplatesState.isValidTemplate);

  hasEmailTemplates = select(EmailTemplatesState.hasEmailTemplates);

  dynamicExportColumns = select(BuyOrderSettingsState.dynamicExportColumns);

  expectedDeliveryDate = select(
    ConceptOrderlinesStateQueries.expectedDeliveryDateCalc
  );

  hasAgenda = computed(
    () => this.shouldReplan() && !this.supplierAgendaManagedManually()
  );

  disabledPurchase = computed(() => {
    return (
      (this.useSupplierSettings() &&
        this.hasEmailTemplates() &&
        (!this.hasSupplierEmails() ||
          !this.orderEmails().length ||
          !this.isValidTemplate())) ||
      this.saving()
    );
  });

  readonly PURCHASE_STRINGS = STRINGS.metadata.purchaseConfirmation;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly COMMUNICATION_METHODS = SupplierCommunications;

  readonly dialogData = inject<PurchaseConfirmationDataV2>(DIALOG_DATA);

  readonly dialogRef = inject<DialogRef>(DialogRef);

  private readonly store = inject(Store);

  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store
      .select(SuppliersV2StateQueries.currentSupplier)
      .pipe(
        filter(
          currentSupplier =>
            !!currentSupplier && !!currentSupplier?.preferredEmailLanguage
        ),
        distinctUntilKeyChanged('uuid'),
        first(),
        switchMap(currentSupplier =>
          this.store
            .dispatch(
              new SetUseSupplierSettings(
                currentSupplier.communicationMethod ===
                  SupplierCommunications.EMAIL
              )
            )
            .pipe(map(() => currentSupplier))
        ),
        switchMap(currentSupplier => {
          const preferredEmailLanguage =
            currentSupplier.preferredEmailLanguage.toUpperCase() as EmailTemplateLanguage;

          return this.store.dispatch([
            new LoadSupplierEmailTemplates(
              currentSupplier.uuid,
              preferredEmailLanguage
            ),
            new LoadAccountEmailTemplates(
              EmailTemplateTypes.SUPPLIER,
              preferredEmailLanguage
            ),
            new LoadWebshopEmailTemplates(
              EmailTemplateTypes.SUPPLIER,
              preferredEmailLanguage
            ),
          ]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  placeOrder() {
    this.store.dispatch(new HandlePlaceOrder());
  }

  close() {
    this.dialogRef.close();
  }

  done() {
    this.store.dispatch(new DonePurchaseConfirmation(this.dialogRef));
  }
}
