import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  booleanAttribute,
  computed,
  signal,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { OverlayDirective } from 'src/app/shared/components/design-system/overlay-container-v2/directives/overlay.directive';
import { ColumnSelectionV2 } from '../../model/select-all-columns-v2.model';
import {
  ColumnViewOptionsV2,
  SelectableColumns,
} from './model/column-view-v2.model';
import { STRINGS } from './model/column-view-v2.strings';

function convertColumnsToArray(
  columns: SelectableColumns
): ColumnViewOptionsV2[] {
  if (columns === null || columns === undefined) {
    return [];
  }

  return Object.values(columns);
}

@Component({
  selector: 'app-column-view-v2',
  templateUrl: './column-view-v2.component.html',
  styleUrls: ['./column-view-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnViewV2Component {
  @ViewChild(OverlayDirective) overlay: OverlayDirective;

  columnsList = signal<SelectableColumns | null>(null);

  columnsListArray = computed<ColumnViewOptionsV2[] | null>(() =>
    convertColumnsToArray(this.columnsList())
  );

  checkedColumns = computed(
    () =>
      this.columnsListArray().filter(
        (column: ColumnViewOptionsV2) => column.checked
      ).length ?? 0
  );

  allChecked = computed(
    () => this.checkedColumns() === this.columnsListArray().length
  );

  someChecked = computed(
    () => this.checkedColumns() < this.columnsListArray().length
  );

  noneChecked = computed(() => !this.checkedColumns());

  @Input()
  set columns(columns: SelectableColumns) {
    this.columnsList.set(columns);
  }

  @Input({ transform: booleanAttribute })
  showApplyAll: boolean = false;

  @Input({ transform: booleanAttribute })
  applyAll: boolean = false;

  @Output()
  columnsSelected: EventEmitter<ColumnSelectionV2> =
    new EventEmitter<ColumnSelectionV2>();

  readonly COLUMN_VIEW_STRINGS = STRINGS;

  readonly COMMON_STRINGS = MESSAGES.common;

  toggleSelection(columnKey: string, checked: boolean): void {
    this.columnsList.set({
      ...this.columnsList(),
      [columnKey]: {
        ...this.columnsList()[columnKey],
        checked,
      },
    });
  }

  checkAll(): void {
    if (this.allChecked()) {
      this._clearSelection();
      return;
    }

    this._selectAll();
  }

  cancel(): void {
    this.overlay._closeOverlay();
  }

  apply(): void {
    this.columnsSelected.emit({
      columns: this.columnsList(),
      applyAll: this.applyAll,
    });

    this.overlay._closeOverlay();
  }

  private _clearSelection(): void {
    Object.keys(this.columnsList()).forEach((columnKey: string) => {
      this.columnsList.set({
        ...this.columnsList(),
        [columnKey]: {
          ...this.columnsList()[columnKey],
          checked: false,
        },
      });
    });
  }

  private _selectAll(): void {
    Object.keys(this.columnsList()).forEach((columnKey: string) => {
      this.columnsList.set({
        ...this.columnsList(),
        [columnKey]: {
          ...this.columnsList()[columnKey],
          checked: true,
        },
      });
    });
  }
}
