import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, distinctUntilKeyChanged, filter, first, skip } from 'rxjs';
import { ProductDetails } from 'src/app/core/api/products/v2/model/product.model';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import {
  DataTableRowForm,
  DataTableRowFormSource,
} from 'src/app/shared/models/supplier-product/v2/editor/data-table-row-form';
import {
  InitializeWebshopProductSuppliersState,
  RemoveAllFilters,
} from './actions/product-suppliers-v2.actions';
import { getRedirectedProductUuid } from 'src/app/features/products-v2/model/products-v2.model';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';

@Component({
  selector: 'app-product-suppliers-v2',
  templateUrl: './product-suppliers-v2.component.html',
  styleUrls: ['./product-suppliers-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSuppliersV2Component implements OnInit {
  @Input()
  productDetails: ProductDetails;

  @Select(PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.SUPPLIERS))
  viewOnly$: Observable<boolean>;

  redirectedProductUuid$: Observable<string> = getRedirectedProductUuid();

  dataForm: DataTableRowForm = null;

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private destroyRef: DestroyRef
  ) {
    this.dataForm = new DataTableRowForm(
      this.formBuilder,
      new DataTableRowFormSource(formBuilder)
    );
  }

  ngOnInit(): void {
    this.store
      .select(ProductDetailsV2StateQueries.productOverviewSucceed)
      .pipe(filter(Boolean), first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.store.dispatch(new InitializeWebshopProductSuppliersState())
      );

    this.store
      .select(WebshopState.selected)
      .pipe(
        skip(1),
        distinctUntilKeyChanged('uuid'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.store.dispatch(new RemoveAllFilters()));

    this.redirectedProductUuid$.subscribe(() =>
      this.store.dispatch(new InitializeWebshopProductSuppliersState())
    );
  }
}
