import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  booleanAttribute,
  input,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { SortedOrder, SortOrder } from './model/sort.model';
import { FilterTypes } from '../filter/model/filter.model';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortComponent {
  order = input<SortedOrder | null>(null);

  label = input<string>();

  disabled = input(false, {
    transform: booleanAttribute,
  });

  @Output()
  sorted: EventEmitter<SortedOrder | null> =
    new EventEmitter<SortedOrder | null>();

  readonly FILTER_TYPE = FilterTypes;

  readonly SORT_OPTIONS = SortOrder;

  readonly SORT_STRINGS = MESSAGES.common.pagination.sort;

  /**
   * Sorts by asc or desc.
   * If sorting with the same current order, it resets the order with null.
   * @param by Sort
   */
  sortBy(by: SortedOrder): void {
    if (by === this.order()) {
      this.sorted.emit(null);
      return;
    }

    this.sorted.emit(by);
  }
}
