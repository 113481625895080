import { MESSAGES } from 'src/app/core/constants/strings.constants';

export type StatesTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger';

export interface BaseConfirmationDialogData {
  icon?: string;
  iconType?: StatesTypes;
  title: string;
  hideCloseButton?: boolean;
  body: string;
  actionName: string;
  actionType?: StatesTypes;
  actionKey?: string;
  actionData?: any;
  helpLink?: string;
  cancelName: string;
  cancelAction?: any;
}

export const BaseConfirmationDialogDefaults = {
  icon: 'warning-rounded',
  iconType: 'primary' as StatesTypes,
  title: 'Provide a title',
  hideCloseButton: false,
  body: 'Provide a body message',
  actionName: 'Provide an action name',
  actionType: 'primary' as StatesTypes,
  actionKey: null,
  actionData: null,
  helpLink: null,
  cancelName: MESSAGES.common.cancel,
};

export const BaseConfirmationDialogSize = '600px';

export enum ComponentConfirmActionsKeys {
  SNOOZE_PRODUCT = 'SNOOZE_PRODUCT',
  OVERLAY_SNOOZE_PRODUCT = 'OVERLAY_SNOOZE_PRODUCT',
  REMOVE_PRODUCT_SUPPLIER = 'REMOVE_PRODUCT_SUPPLIER',
  REMOVE_PRODUCT_PROMOTION = 'REMOVE_PRODUCT_PROMOTION',
  REMOVE_PROMOTION_PRODUCT = 'REMOVE_PROMOTION_PRODUCT',
  REMOVE_PROMOTION_SELECTED_PRODUCTS = 'REMOVE_PROMOTION_SELECTED_PRODUCTS',
  IGNORE_SUPPLIER = 'IGNORE_SUPPLIER',
  SKIP_ORDER_MOMENT = 'SKIP_ORDER_MOMENT',
  DELETE_CONCEPT = 'DELETE_CONCEPT',
  DELETE_PROMOTION = 'DELETE_PROMOTION',
}
