@if (viewOnly$ | async) {
  <app-product-suppliers-v2-data-table-view-only
    [webshopProductUuid]="
      productDetails?.uuid
    "></app-product-suppliers-v2-data-table-view-only>
} @else {
  <app-product-suppliers-v2-data-table
    [webshopProductUuid]="productDetails?.uuid"
    [dataForm]="dataForm"></app-product-suppliers-v2-data-table>
}
