<app-dropdown
  name="context"
  type="outline"
  [value]="context()"
  (valueChange)="contextChange.emit($event)"
  [disabled]="disabled()"
  required
  forceOptionsReset
  [displayWith]="displayWithKey">
  @for (ctx of contextOptions(); track ctx.key) {
    <app-dropdown-option [value]="ctx.value">{{ ctx.key }}</app-dropdown-option>
  }
</app-dropdown>
