<div class="header">
  <span class="header__name">{{ filterName() }}</span>

  <div>
    @if (allowRemove()) {
      <button
        aria-label="Filter header button"
        appButton
        variant="danger"
        size="sm"
        class="custom-link-btn"
        (click)="remove.emit()"
        [disabled]="disableRemove()">
        {{ COMMON_STRINGS.filter.remove }}
      </button>
    }
  </div>
</div>
