<div [class]="buttonFirst ? 'copy-container-reverse' : 'copy-container'">
  <span
    class="text-overflow"
    [appTooltip]="message"
    tooltipShowDelay="100"
    tooltipShouldCheckOverflow
    [class.copied]="highlighted$ | async">
    {{ message }}
  </span>

  <div class="button-container" *ngIf="!!message">
    <ng-container [ngSwitch]="copied$ | async">
      <button
        *ngSwitchCase="false"
        (click)="onClick($event)"
        class="copy-button-base copy-button"
        appTooltip="Copy to clipboard"
        tooltipShowDelay="500"
        i18n-appTooltip="copy clipboard button"
        [cdkCopyToClipboard]="message">
        <mat-icon class="button-icon" svgIcon="clipboard-lines"></mat-icon>
      </button>

      <button
        *ngSwitchCase="true"
        class="copy-button-base"
        (click)="onClick($event)"
        [cdkCopyToClipboard]="message"
        appTooltip="Copied!"
        i18n-appTooltip="copied to clipboard">
        <mat-icon
          class="copied-icon"
          [class.copied]="highlighted$ | async"
          svgIcon="clipboard-check"></mat-icon>
      </button>
    </ng-container>
  </div>
</div>
