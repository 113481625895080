<div class="dialog-container">
  <div class="dialog-header">
    <h1 class="dialog-header-title">
      {{ DIALOG_STRINGS.title }}
    </h1>

    <button
      data-intercom-target="e4ce151f-e32e-4c2f-b706-f9d245e56fd0"
      aria-label="Product link dialog close button"
      class="dialog-close"
      (click)="close()">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div class="dialog-content">
    <h2 class="dialog-description">{{ DIALOG_STRINGS.description }}</h2>

    <form #form="ngForm" class="product-link-form">
      <div class="product-link-container">
        <div
          [class]="
            hasProductLinked ? 'product-linked-dropdown' : 'product-dropdown'
          ">
          <app-dropdown
            data-intercom-target="b9cd3be3-0c0f-44fe-904e-45f23693ba46"
            aria-label="Product link dialog select product button"
            name="product"
            [(ngModel)]="productLink.predecessor"
            [placeholder]="DIALOG_STRINGS.placeholder"
            [displayWith]="displayWithName"
            [loading]="loading$ | async">
            <app-dropdown-search
              (searched)="filterProducts($event)"></app-dropdown-search>

            <app-dropdown-option
              *ngFor="let predecessor of availablePredecessors$ | async"
              [value]="predecessor"
              [label]="predecessor.name" />
          </app-dropdown>
        </div>
        <button
          *ngIf="hasProductLinked"
          data-intercom-target="9a0d15df-3079-4cdc-aba5-b3d9fa2308d3"
          aria-label="Product link dialog delete product link button"
          appButton
          variant="danger"
          class="delete-product-link-btn"
          (click)="deletePredecessor()">
          <mat-icon svgIcon="trash"></mat-icon>
        </button>
      </div>

      <div class="phase-out-container">
        <div class="phase-out-title-container">
          <mat-icon svgIcon="arrow-decrease-down"></mat-icon>

          <span class="phase-out-title">{{
            DIALOG_STRINGS.phaseOutLinked
          }}</span>

          <span
            class="info-tooltip"
            [appTooltip]="TOOLTIPS_STRINGS.phaseOutLinked">
            <mat-icon svgIcon="info-rounded-xs"></mat-icon>
          </span>
        </div>

        <app-toggle
          data-intercom-target="c39a7899-ec3d-4f1d-a458-f3cbba9ebcec"
          aria-label="Product link dialog phase out button"
          [(ngModel)]="productLink.notBeingBought"
          name="phase-out"
          statePosition="end"></app-toggle>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button
      data-intercom-target="d0dbe97f-e8b0-4026-9c6f-ad747ca4fb34"
      aria-label="Product link dialog cancel button"
      appButton
      variant="tertiary"
      (click)="close()">
      {{ COMMON_STRINGS.cancel }}
    </button>

    <button
      data-intercom-target="8b822ce5-98f6-42d2-b2df-08de494969b9"
      aria-label="Product link dialog save button"
      appButton
      variant="primary"
      [disabled]="form.invalid || (saving$ | async)"
      (click)="save()">
      {{ COMMON_STRINGS.save }}
    </button>
  </div>
</div>
