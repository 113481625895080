<app-filter-container-template
  [headerTemplate]="headerTemplate()"
  [footerTemplate]="footerTemplate()"
  [filterName]="filterName()"
  [disableClear]="!filterStrategy?.hasValue() || filterDisabled()"
  [disableApply]="
    filterStrategy === null ||
    !filterStrategy?.hasValue() ||
    filterStrategy?.hasSameValue(filterValues()?.value) ||
    filterDisabled()
  "
  [showApplyAll]="showApplyAll()"
  (apply)="apply($event)"
  (clear)="clear()"
  (cancel)="cancel()">
  <app-filter-type-options-v2
    [options]="filterOptions()"
    [preSelectedOption]="filterValues()?.operator"
    (optionSelected)="selectOption($event)" />

  @switch (selectedOption()) {
    @case (FILTER_TYPE_OPTIONS.EQUALS) {
      <app-filter-number-equals-v2
        [(ngModel)]="filterStrategy.form"
        name="equals"
        [step]="filter()?.validations?.step"
        [min]="filter()?.validations?.min"
        [max]="filter()?.validations?.max" />
    }

    @case (FILTER_TYPE_OPTIONS.RANGED) {
      <app-filter-number-range-v2
        [(ngModel)]="filterStrategy.form"
        name="range"
        [step]="filter()?.validations?.step"
        [min]="filter()?.validations?.min"
        [max]="filter()?.validations?.max" />
    }
  }
</app-filter-container-template>
