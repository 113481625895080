import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  ProposedBuyOrderlines,
  OrderlineSlevel,
  OrderlineSupplierProduct,
  OrderlineWebshopProduct,
  OrderlineReliabilitySubScores,
  OrderlineMostImpactfulPromotion,
  ProposedBuyOrderline,
  OrderlineDemandInCycleInterval,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';

import {
  OrderlineSlevel as OrderlineSlevelV1,
  OrderlineSupplierProduct as OrderlineSupplierProductV1,
  OrderlineWebshopProduct as OrderlineWebshopProductV1,
  OrderlineReliabilitySubScores as OrderlineReliabilitySubScoresV1,
  OrderlineMostImpactfulPromotion as OrderlineMostImpactfulPromotionV1,
  OrderlineDemandInCycleInterval as OrderlineDemandInCycleIntervalV1,
} from '../../../buy-order/v2/model/proposed-buy-orderlines-v2.model';
import {
  ManualBuyOrderProduct as ManualBuyOrderProductV1,
  ManualBuyOrderProducts as ManualBuyOrderProductsV1,
} from '../model/buy-order-manual-order.model';

export class ManualOrderlinesV2Adapter
  implements DataAdapter<ManualBuyOrderProductsV1, ProposedBuyOrderlines>
{
  isAvailable: boolean;
  constructor(isAvailable: boolean) {
    this.isAvailable = isAvailable;
  }

  transform(data: ManualBuyOrderProductsV1): ProposedBuyOrderlines {
    const products: ProposedBuyOrderline[] = data.products.map(
      (product: ManualBuyOrderProductV1) => {
        return {
          uuid: product.uuid,
          webshopProduct: this._buildWebshopProduct(product.webshopProduct),
          supplierProduct: this._buildSupplierProduct(product.supplierProduct),
          slevel: this._buildSLevel(product.slevel),
          quantity: this.isAvailable ? null : product.quantity,
          price: product.price,
          totalPrice: product.totalPrice,
          weight: product.weight,
          totalWeight: product.totalWeight,
          volume: product.volume,
          totalVolume: product.totalVolume,
          stockPosition: product.stockPosition,
          conceptLineUuid: product.conceptLineUuid,
          proposed: product.proposed,
          minutesSinceLastAdviceUpdate: product.minutesSinceLastAdviceUpdate,
          edited: product.edited,
          snoozed: product.snoozed,
          notes: product.notes,
          errors: {
            quantity: false,
            price: false,
            weight: false,
            volume: false,
          },
          errored: false,
        };
      }
    );

    return {
      data: products,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  private _buildWebshopProduct(
    webshopProduct: OrderlineWebshopProductV1
  ): OrderlineWebshopProduct | null {
    if (!webshopProduct) return null;

    return {
      uuid: webshopProduct.uuid,
      id: webshopProduct.id,
      sku: webshopProduct.sku,
      articleCode: webshopProduct.articleCode,
      name: webshopProduct.name,
      minimumStock: webshopProduct.minimumStock,
      stockLevel: webshopProduct.stockLevel,
      category: webshopProduct.category,
      eanCode: webshopProduct.eanCode,
      notBeingBought: webshopProduct.notBeingBought,
      novel: webshopProduct.novel,
      notes: webshopProduct.notes,
      maximumStock: webshopProduct.maximumStock,
      createdAtRemote: webshopProduct.createdAtRemote,
      xyzCategory: webshopProduct.xyzCategory,
    };
  }

  private _buildSupplierProduct(
    supplierProduct: OrderlineSupplierProductV1
  ): OrderlineSupplierProduct | null {
    if (!supplierProduct) return null;

    return {
      uuid: supplierProduct.uuid,
      sku: supplierProduct.sku,
      articleCode: supplierProduct.articleCode,
      minimumOrderQuantity: supplierProduct.minimumOrderQuantity,
      lotSize: supplierProduct.lotSize,
      active: supplierProduct.active,
      optimal: supplierProduct.optimal,
      preferred: supplierProduct.preferred,
      price: supplierProduct.price,
      freeStock: supplierProduct.freeStock,
      eanCode: supplierProduct.eanCode,
      weight: supplierProduct.weight,
      volume: supplierProduct.volume,
      deliveryTime: supplierProduct.deliveryTime,
    };
  }

  private _buildSLevel(sLevel: OrderlineSlevelV1): OrderlineSlevel | null {
    if (!sLevel) return null;

    return {
      orderUpToLevel: sLevel.orderUpToLevel,
      reliability: sLevel.reliability,
      demandInCycle: sLevel.demandInCycle,
      safetyStock: sLevel.safetyStock,
      reliabilitySubScores: this._buildSLevelReliabilitySubScores(
        sLevel.reliabilitySubScores
      ),
      demandInCycleInterval: this._buildDemandInCycleInterval(
        sLevel.demandInCycleInterval
      ),
      hasMultipleImpactfulPromotions: sLevel.hasMultipleImpactfulPromotions,
      mostImpactfulPromotion: this._buildMostImpactfulPromotion(
        sLevel.mostImpactfulPromotion
      ),
    };
  }

  private _buildSLevelReliabilitySubScores(
    reliabilitySubScores: OrderlineReliabilitySubScoresV1
  ): OrderlineReliabilitySubScores | null {
    if (!reliabilitySubScores) return null;

    return {
      leadTime: Math.round(reliabilitySubScores.leadTime),
      safetyStock: Math.round(reliabilitySubScores.safetyStock),
      forecast: Math.round(reliabilitySubScores.forecast),
    };
  }

  private _buildDemandInCycleInterval(
    demandInterval: OrderlineDemandInCycleIntervalV1
  ): OrderlineDemandInCycleInterval | null {
    if (!demandInterval) return null;

    return {
      start: demandInterval.start,
      end: demandInterval.end,
    };
  }

  private _buildMostImpactfulPromotion(
    promotion: OrderlineMostImpactfulPromotionV1
  ): OrderlineMostImpactfulPromotion | null {
    if (!promotion) return null;

    return {
      uuid: promotion.uuid,
      name: promotion.name,
      uplift: {
        type: promotion.uplift.type,
        increase: promotion.uplift.increase,
      },
    };
  }
}
