export class Saving {
  static readonly type = '[App] Saving';

  constructor(
    public emitMessage: boolean = true,
    public message?: string
  ) {}
}

export class SavingInfinite {
  static readonly type = '[App] Saving Infinite';

  constructor(
    public emitMessage: boolean = true,
    public message?: string
  ) {}
}

export class SaveSucceed {
  static readonly type = '[App] Save Succeed';

  constructor(
    public emitMessage: boolean = true,
    public message?: string
  ) {}
}

export class SaveFailed {
  static readonly type = '[App] Save Failed';

  constructor(
    public emitMessage: boolean = true,
    public message?: string
  ) {}
}

export class Loading {
  static readonly type = '[App] Loading';

  constructor(
    public emitMessage: boolean = true,
    public message?: string
  ) {}
}

export class LoadSucceed {
  static readonly type = '[App] Load Succeed';

  constructor(
    public emitMessage: boolean = true,
    public message?: string
  ) {}
}

export class LoadFailed {
  static readonly type = '[App] Load Failed';

  constructor(
    public emitMessage: boolean = true,
    public message?: string
  ) {}
}

export class CloseDialog {
  static readonly type = '[App] Close Dialog';
}

export class SetColorTheme {
  static readonly type = '[App] Set Color Theme';
}

export class PreventExit {
  static readonly type = '[App] Prevent Exit';
}

export class ConfirmExit {
  static readonly type = '[App] Confirm Exit';
}

export class AllowExit {
  static readonly type = '[App] Allow Exit';
}

export class ToggleIntercom {
  static readonly type = '[App] Toggle Intercom';
}
