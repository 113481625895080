<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"
      [disabled]="editing()"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      [disabled]="editing()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <app-data-table-refresh-button
      data-intercom-target="Refresh table data button"
      aria-label="Refresh table data button"
      [loading]="loading()"
      [disabled]="loading() || !canRefresh()"
      (refreshed)="refreshDatatable()" />

    <div class="vertical-separator"></div>

    <button
      *ngIf="exportsEnabled() && isPopout() === false"
      data-intercom-target="9de776d6-2fa6-48fe-b088-c2cb1756a3ce"
      aria-label="Product details suppliers table export button"
      appButton
      appButtonIcon
      variant="link"
      transparent
      class="export-button"
      [appTooltip]="COMMON_STRINGS.exportAs"
      (click)="openExportsDialog()">
      <mat-icon svgIcon="arrow-download" class="gray"></mat-icon>
    </button>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="filterOpened()">
  <app-filter-group
    [filters]="activeFilters()"
    (filtered)="onFiltered($event)"
    [disabled]="editing()">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"
      [disabled]="editing()"></app-add-filter-button> </app-filter-group
></app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    [disableHideColumn]="lastDisplayedColumn()"
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)"
    (filtered)="onFiltered($event)">
    <!-- Preferred -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.key">
      <th
        data-intercom-target="47145b90-3e94-4bd5-bb42-8849504db659"
        aria-label="Product details suppliers table Preferred column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.key
            | filterColumn: RELATED_STATE
            | async
        "
        [label]="TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.name">
        {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_PREFERRED.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="fit-to-value">
        <mat-icon
          *ngIf="element.supplierProduct.preferred"
          svgIcon="verified-badge"
          class="verified-icon"
          [appTooltip]="TABLE_TOOLTIPS.preferred"></mat-icon>
      </td>
    </ng-container>

    <!-- Supplier product active -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.key">
      <th
        data-intercom-target="f568cb9b-5fe9-4090-901d-8211cf527188"
        aria-label="Product details suppliers table active column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="fit-to-value"
        [label]="TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.name">
        {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <mat-icon
          *ngIf="element.supplierProduct.active"
          svgIcon="check"
          class="done-icon"
          [appTooltip]="TABLE_COLUMNS.SUPPLIER_PRODUCT_ACTIVE.name"></mat-icon>
      </td>
    </ng-container>

    <!-- Available -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.key">
      <th
        data-intercom-target="22b96145-44a2-4497-8b6c-cd54c8e4b8d1"
        aria-label="Product details suppliers table Available column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="fit-to-value"
        [label]="TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.name">
        {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_AVAILABLE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <mat-icon
          *ngIf="element.supplierProduct.availability"
          svgIcon="check"
          class="done-icon"
          [appTooltip]="TABLE_TOOLTIPS.available"></mat-icon>
      </td>
    </ng-container>

    <!-- Availability date -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.AVAILABLE_DATE.key">
      <th
        data-intercom-target="b2362846-834c-4dbe-b59f-0728fd62db17"
        aria-label="Product details suppliers table Availability date column"
        cdk-header-cell
        *cdkHeaderCellDef>
        {{ TABLE_COLUMNS.AVAILABLE_DATE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="
            element.supplierProduct.availabilityDate !== null;
            else notAvailable
          ">
          {{ element.supplierProduct.availabilityDate | date }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Supplier name -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_NAME.key">
      <th
        data-intercom-target="3e8f39ef-c163-439b-8974-d485e748c1d4"
        aria-label="Product details suppliers table Supplier Name column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_NAME.key | filterColumn: RELATED_STATE | async
        "
        class="restricted-column">
        {{ TABLE_COLUMNS.SUPPLIER_NAME.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <span
          (click)="goToSupplier(element.supplier.uuid)"
          class="deep-linking-text">
          <app-copy-to-clipboard
            [message]="element.supplier.name"></app-copy-to-clipboard>
        </span>
      </td>
    </ng-container>

    <!-- Product name -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_NAME.key">
      <th
        data-intercom-target="a41fc403-6af1-4bbd-baf3-45b108ef3500"
        aria-label="Product details suppliers table Product Name column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_PRODUCT_NAME.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="restricted-column">
        {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_NAME.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="restricted-column">
        <ng-container
          *ngIf="element.supplierProduct.name !== null; else notAvailable">
          <app-copy-to-clipboard
            [message]="element.supplierProduct.name"></app-copy-to-clipboard>
        </ng-container>
      </td>
    </ng-container>

    <!-- SKU -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_SKU.key">
      <th
        data-intercom-target="b59af239-effa-4acc-a36d-5ea64c419e51"
        aria-label="Product details suppliers table SKU column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_PRODUCT_SKU.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="restricted-column">
        {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_SKU.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-muted restricted-column">
        <app-copy-to-clipboard
          [message]="element.supplierProduct.sku"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Lot size -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.PURCHASE_IN_QUANTITIES_OF.key">
      <th
        data-intercom-target="c63cee60-beed-4481-8bbb-4177dfbd6098"
        aria-label="Product details suppliers table Lot Size column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.PURCHASE_IN_QUANTITIES_OF.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.PURCHASE_IN_QUANTITIES_OF.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        {{ element.supplierProduct.purchaseInQuantitiesOf }}
      </td>
    </ng-container>

    <!-- EAN -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_EAN_CODE.key">
      <th
        data-intercom-target="da783fc9-26e0-4440-b0fb-dac96914000a"
        aria-label="Product details suppliers table EAN Code column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_PRODUCT_EAN_CODE.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="column-muted restricted-column">
        {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_EAN_CODE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <app-copy-to-clipboard
          [message]="element.supplierProduct.eanCode"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Supplier products article code -->
    <ng-container
      [cdkColumnDef]="TABLE_COLUMNS.SUPPLIER_PRODUCT_ARTICLE_CODE.key">
      <th
        data-intercom-target="21646089-e9f0-41e2-adfa-5e45042305eb"
        aria-label="Product details suppliers table Article code"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SUPPLIER_PRODUCT_ARTICLE_CODE.key
            | filterColumn: RELATED_STATE
            | async
        ">
        {{ TABLE_COLUMNS.SUPPLIER_PRODUCT_ARTICLE_CODE.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-muted restricted-column">
        <ng-container
          *ngIf="
            element.supplierProduct.articleCode !== null;
            else notAvailable
          ">
          <app-copy-to-clipboard
            [message]="
              element.supplierProduct.articleCode
            "></app-copy-to-clipboard>
        </ng-container></td
    ></ng-container>

    <!-- MOQ -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.MINIMUM_PURCHASE_QUANTITY.key">
      <th
        data-intercom-target="b8a8573d-110b-4404-a10b-f4ffb3aee7db"
        aria-label="Product details suppliers table MOQ column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.MINIMUM_PURCHASE_QUANTITY.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.MINIMUM_PURCHASE_QUANTITY.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        {{ element.supplierProduct.minimumPurchaseQuantity }}
      </td>
    </ng-container>

    <!-- Lead time -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.LEAD_TIME.key">
      <th
        data-intercom-target="811b409d-4cad-4a62-b01b-fa936c3258db"
        aria-label="Product details suppliers table Lead Time column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.LEAD_TIME.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.LEAD_TIME.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <span
          *ngIf="
            element.supplierProduct.deliveryTime &&
              element.supplierProduct.deliveryTime !== null;
            else notAvailable
          ">
          {{ element.supplierProduct.deliveryTime }}
        </span>
      </td>
    </ng-container>

    <!-- Weight -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.WEIGHT.key">
      <th
        data-intercom-target="a3e9c0ea-3fa0-484c-a227-b8a2970dae8f"
        aria-label="Product details suppliers table Weight column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.WEIGHT.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.WEIGHT.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <span
          *ngIf="
            element.supplierProduct.weight &&
              element.supplierProduct.weight !== null &&
              element.supplierProduct.weight !== 0;
            else notAvailable
          "
          >{{ element.supplierProduct.weight | customDecimal }}
          <span class="gray">g</span></span
        >
      </td>
    </ng-container>

    <!-- Volume -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.VOLUME.key">
      <th
        data-intercom-target="1186a3eb-a103-47c6-a1be-555bdac18b56"
        aria-label="Product details suppliers table Volume column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.VOLUME.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.VOLUME.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <span
          *ngIf="
            element.supplierProduct.volume &&
              element.supplierProduct.volume !== null &&
              element.supplierProduct.volume !== 0;
            else notAvailable
          "
          >{{ element.supplierProduct.volume | customDecimal }}
          <span class="gray">cm<sup>3</sup></span></span
        >
      </td>
    </ng-container>

    <!-- Price -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.PRICE.key">
      <th
        data-intercom-target="61b7a04d-de70-4226-9259-dd2e571ecc2c"
        aria-label="Product details suppliers table Price column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.PRICE.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.PRICE.name }} ({{ currencySymbol() }})
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <p class="currency">
          {{ element.supplierProduct.price | customCurrency }}
        </p>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      data-intercom-target="70d8a333-ebd3-4d12-a790-11b551917550"
      aria-label="Product details suppliers table row"
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"
      [class.opaque]="row.supplierProduct.active === false"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
