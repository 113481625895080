<div
  #tooltip
  class="tooltip"
  [ngClass]="tooltipClass()"
  [@state]="tooltipAnimationState"
  (@state.done)="handleAnimationEnd($event)"
  [style.max-width.px]="maxWidth">
  <div class="tooltip-content">
    {{ message }}
  </div>
</div>
