import { DialogRef } from '@angular/cdk/dialog';

export class NewPasswordChanged {
  static readonly type = '[Password] New Password Changed';
  constructor(public password: string) {}
}

export class ConfirmPasswordChanged {
  static readonly type = '[Password] Confirm Password Changed';
  constructor(public password: string) {}
}

export class SavePassword {
  static readonly type = '[Password] Save Password';

  constructor(public dialogRef: DialogRef) {}
}
