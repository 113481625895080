import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  signal,
} from '@angular/core';
import { TooltipDirective } from '../design-system/tooltip/directives/tooltip.directive';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-basic-copy-to-clipboard',
  templateUrl: './basic-copy-to-clipboard.component.html',
  styleUrl: './basic-copy-to-clipboard.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicCopyToClipboardComponent {
  @ViewChild(TooltipDirective)
  tooltip: TooltipDirective;

  @Input()
  copyText: string;

  copied = signal(false);

  readonly STRINGS = MESSAGES.common.copy;

  copy() {
    this.copied.set(true);

    this.tooltip?.show();
  }
}
