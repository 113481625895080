import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
} from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { SupplierManualOrder } from 'src/app/shared/models/suppliers/supplier.model';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { SearchAutoComplete } from 'src/app/shared/components/search/model/search.model';
import { AddProductSupplierDialogState } from './state/add-product-supplier-dialog.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { DateTime } from 'luxon';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import {
  InitializeAddProductSuppliers,
  ResetAddProductSuppliersState,
  SaveAddedSupplier,
  UpdateAvailability,
  UpdateAvailabilityDate,
  UpdateDeliveryTime,
  UpdateEAN,
  UpdateLotSize,
  UpdateMOQ,
  UpdatePreferred,
  UpdatePrice,
  UpdateSelectedSupplier,
  UpdateSKU,
  UpdateVolume,
  UpdateWeight,
} from './actions/add-product-supplier-dialog.actions';
import { ResetAvailableSuppliers } from 'src/app/shared/components/multi-supplier-editor-dialog/actions/multi-supplier-editor.actions';
import { LinkSupplierProductData } from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import { WebshopProductSuppliersStateQueries } from 'src/app/shared/components/product-details-v2/components/product-suppliers-v2/state/product-suppliers-v2.queries';
import { LoadSuppliersToLink } from 'src/app/shared/components/product-details-v2/components/product-suppliers-v2/actions/product-suppliers-v2.actions';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-add-product-supplier-dialog',
  templateUrl: './add-product-supplier-dialog.component.html',
  styleUrls: ['./add-product-supplier-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductSupplierDialogComponent implements OnInit, OnDestroy {
  @Select(AppState.isSaving)
  isSaving$: Observable<boolean>;

  @Select(WebshopState.webshopCurrencySymbol)
  currencySymbol$: Observable<string>;

  @Select(AddProductSupplierDialogState.invalidSupplier)
  invalidSupplier$: Observable<boolean>;

  @Select(AddProductSupplierDialogState.sku)
  sku$: Observable<string>;

  @Select(AddProductSupplierDialogState.eanCode)
  eanCode$: Observable<string>;

  @Select(AddProductSupplierDialogState.weight)
  weight$: Observable<number>;

  @Select(AddProductSupplierDialogState.volume)
  volume$: Observable<number>;

  @Select(AddProductSupplierDialogState.price)
  price$: Observable<number>;

  @Select(AddProductSupplierDialogState.purchaseInQuantitiesOf)
  purchaseInQuantitiesOf$: Observable<number>;

  @Select(AddProductSupplierDialogState.minimumPurchaseQuantity)
  minimumPurchaseQuantity$: Observable<number>;

  @Select(AddProductSupplierDialogState.deliveryTime)
  deliveryTime$: Observable<number>;

  @Select(AddProductSupplierDialogState.availability)
  availability$: Observable<boolean>;

  @Select(AddProductSupplierDialogState.availabilityDate)
  availabilityDate$: Observable<Date>;

  @Select(AddProductSupplierDialogState.preferred)
  preferred$: Observable<boolean>;

  filteredAvailableSuppliers$: Observable<SearchAutoComplete[]>;

  searchBy$: Subject<string> = new BehaviorSubject<string>(null);

  availableSuppliers$: Observable<SupplierManualOrder[]>;

  minAvailabilityDate = DateTime.fromJSDate(new Date())
    .toUTC()
    .plus({ days: 1 })
    .toJSDate();

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly COMMON_STRINGS = MESSAGES.common;

  addAnother: boolean = false;

  constructor(
    @Inject(DIALOG_DATA) public data: LinkSupplierProductData,
    private store: Store,
    private dialogRef: DialogRef<AddProductSupplierDialogComponent>
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new InitializeAddProductSuppliers(this.data));

    this.availableSuppliers$ = this.store.select(
      WebshopProductSuppliersStateQueries.availableSuppliers
    );

    this.filteredAvailableSuppliers$ = this.availableSuppliers$.pipe(
      map((products: SupplierManualOrder[]) =>
        products.map((item: SupplierManualOrder) => ({
          id: Number(item.id),
          name: item.name,
        }))
      )
    );
  }

  updateSelectedSupplier(selectedSupplier: SearchAutoComplete) {
    this.store.dispatch(new UpdateSelectedSupplier(selectedSupplier.id));
  }

  updateSku(sku: string) {
    this.store.dispatch(new UpdateSKU(sku));
  }

  updateEan(ean: string) {
    this.store.dispatch(new UpdateEAN(ean));
  }

  updateWeight(weight: number) {
    this.store.dispatch(new UpdateWeight(weight));
  }

  updateVolume(volume: number) {
    this.store.dispatch(new UpdateVolume(volume));
  }

  updatePrice(price: number) {
    this.store.dispatch(new UpdatePrice(price));
  }

  updateDeliveryTime(leadtime: number) {
    this.store.dispatch(new UpdateDeliveryTime(leadtime));
  }

  updateLotSize(lotsize: number) {
    this.store.dispatch(new UpdateLotSize(lotsize));
  }

  updateMoq(moq: number) {
    this.store.dispatch(new UpdateMOQ(moq));
  }

  updateAvailability(availability: boolean) {
    this.store.dispatch(new UpdateAvailability(availability));
  }

  updateAvailabilityDate(availabilityDate: Date) {
    this.store.dispatch(new UpdateAvailabilityDate(availabilityDate));
  }

  updatePreferred(preferred: boolean) {
    this.store.dispatch(new UpdatePreferred(preferred));
  }

  saveAddedSupplier() {
    this.store.dispatch(
      new SaveAddedSupplier(!this.addAnother, this.dialogRef)
    );

    if (this.addAnother) {
      this.searchBy$.next(null);
    }
  }

  searchBy(param: string[] | null): void {
    if (param.length === 0) {
      this.store.dispatch([
        new ResetAvailableSuppliers(),
        new UpdateSelectedSupplier(null),
      ]);
    } else {
      this.store.dispatch(new LoadSuppliersToLink(param.join()));

      this.searchBy$.next(param.join());
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetAddProductSuppliersState());
  }
}
