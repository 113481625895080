import { PurchaseExportsSettingsOptions } from 'src/app/shared/components/purchase-confirmation-dialog-v2/components/purchase-exports-settings/model/purchase-exports-settings.model';

export const STRINGS = {
  columns: {
    purchase: {
      ORDER_MOMENT_ID: {
        key: 'ORDER_MOMENT_ID',
        name: $localize`Order moment Id`,
        filterKey: 'ORDER_MOMENT_ID',
        currency: false,
        containers: false,
      },
      TYPE: {
        key: 'TYPE',
        name: $localize`Type`,
        filterKey: 'TYPE',
        currency: false,
        containers: false,
      },
      TRIGGER_VALUE: {
        key: 'TRIGGER_VALUE',
        name: $localize`Trigger value`,
        filterKey: 'TRIGGER_VALUE',
        currency: false,
        containers: false,
      },
      SUPPLIER_NAME: {
        key: 'SUPPLIER_NAME',
        name: $localize`Supplier name`,
        filterKey: 'SUPPLIER_NAME',
        currency: false,
        containers: false,
      },
      SUPPLIER_CUTOFF: {
        key: 'SUPPLIER_CUTOFF',
        name: $localize`Supplier cut-off`,
        filterKey: 'SUPPLIER_CUTOFF',
        currency: false,
        containers: false,
      },
      SUPPLIER_OWNER: {
        key: 'SUPPLIER_OWNER',
        name: $localize`Supplier manager`,
        filterKey: 'SUPPLIER_OWNER',
        currency: false,
        containers: false,
      },
      PLANNED_DATE: {
        key: 'PLANNED_DATE',
        name: $localize`Planned date`,
        filterKey: 'PLANNED_DATE',
        currency: false,
        containers: false,
      },
      STATUS: {
        key: 'STATUS',
        name: $localize`Status`,
        filterKey: 'STATUS',
        currency: false,
        containers: false,
      },
      NUMBER_OF_PRODUCTS: {
        key: 'NUMBER_OF_PRODUCTS',
        name: $localize`Number of products`,
        filterKey: 'NUMBER_OF_PRODUCTS',
        currency: false,
        containers: false,
      },
      TOTAL_WEIGHT: {
        key: 'TOTAL_WEIGHT',
        name: $localize`Total weight`,
        unit: 'kg',
        filterKey: 'TOTAL_WEIGHT',
        currency: false,
        containers: true,
      },
      TOTAL_VOLUME: {
        key: 'TOTAL_VOLUME',
        name: $localize`Total volume`,
        unit: 'm³',
        filterKey: 'TOTAL_VOLUME',
        currency: false,
        containers: true,
      },
      TOTAL_VALUE: {
        key: 'TOTAL_VALUE',
        name: $localize`Total value`,
        unit: '€',
        filterKey: 'TOTAL_VALUE',
        currency: true,
        containers: false,
      },
      LAST_MODIFIED: {
        key: 'LAST_MODIFIED',
        name: $localize`Last modified`,
        filterKey: 'LAST_MODIFIED',
        currency: false,
        containers: false,
      },
      MINIMUM_ORDER_VALUE: {
        key: 'MINIMUM_ORDER_VALUE',
        name: $localize`Minimum order value`,
        unit: '€',
        filterKey: 'MINIMUM_ORDER_VALUE',
        currency: true,
        containers: false,
      },
      NOTES: {
        key: 'NOTES',
        name: $localize`Supplier notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
    purchaseEditor: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_ACTIVE: {
        key: 'SUPPLIER_PRODUCT_ACTIVE',
        name: $localize`Active`,
        filterKey: 'SUPPLIER_PRODUCT_ACTIVE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_PREFERRED: {
        key: 'SUPPLIER_PRODUCT_PREFERRED',
        name: $localize`Preferred`,
        filterKey: 'SUPPLIER_PRODUCT_PREFERRED',
        currency: false,
        containers: false,
      },
      NOTES: {
        key: 'NOTES',
        name: $localize`Notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      HAS_NOTES: {
        key: 'HAS_NOTES',
        name: $localize`Has notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      CATEGORY: {
        key: 'CATEGORY',
        name: $localize`Category`,
        filterKey: 'CATEGORY',
        currency: false,
        containers: false,
      },
      XYZ_CATEGORY: {
        key: 'XYZ_CATEGORY',
        name: $localize`XYZ category`,
        filterKey: 'XYZ_CATEGORY',
        currency: false,
        containers: false,
      },
      NOVEL: {
        key: 'NOVEL',
        name: $localize`New product`,
        filterKey: 'NOVEL',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_SKU: {
        key: 'WEBSHOP_PRODUCT_SKU',
        name: $localize`Product SKU`,
        filterKey: 'WEBSHOP_PRODUCT_SKU',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_SKU: {
        key: 'SUPPLIER_PRODUCT_SKU',
        name: $localize`Supplier product SKU`,
        filterKey: 'SUPPLIER_PRODUCT_SKU',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_EAN_CODE: {
        key: 'WEBSHOP_PRODUCT_EAN_CODE',
        name: $localize`Product EAN code`,
        filterKey: 'WEBSHOP_PRODUCT_EAN_CODE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_EAN_CODE: {
        key: 'SUPPLIER_PRODUCT_EAN_CODE',
        name: $localize`Supplier product EAN code`,
        filterKey: 'SUPPLIER_PRODUCT_EAN_CODE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_ARTICLE_CODE: {
        key: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        name: $localize`Supplier product article code`,
        filterKey: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_ARTICLE_CODE: {
        key: 'WEBSHOP_PRODUCT_ARTICLE_CODE',
        name: $localize`Product article code`,
        filterKey: 'WEBSHOP_PRODUCT_ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_NAME: {
        key: 'WEBSHOP_PRODUCT_NAME',
        name: $localize`Product name`,
        filterKey: 'WEBSHOP_PRODUCT_NAME',
        currency: false,
        containers: false,
      },
      MOST_IMPACTFUL_PROMOTION: {
        key: 'MOST_IMPACTFUL_PROMOTION',
        name: $localize`Promotions`,
        filterKey: 'EXISTS_MOST_IMPACTFUL_PROMOTION',
        currency: false,
        containers: false,
        promotions: true,
      },
      PROPOSED: {
        key: 'PROPOSED',
        name: $localize`Advice Type`,
        filterKey: 'PROPOSED',
        currency: false,
        containers: false,
      },
      EDITED: {
        key: 'EDITED',
        name: $localize`Advice Edited`,
        filterKey: 'EDITED',
        currency: false,
        containers: false,
      },
      STOCK_LEVEL: {
        key: 'STOCK_LEVEL',
        name: $localize`Free stock`,
        filterKey: 'STOCK_LEVEL',
        currency: false,
        containers: false,
      },
      STOCK_POSITION: {
        key: 'STOCK_POSITION',
        name: $localize`Stock position`,
        filterKey: 'STOCK_POSITION',
        currency: false,
        containers: false,
      },
      MINIMUM_STOCK: {
        key: 'MINIMUM_STOCK',
        name: $localize`Minimum stock level`,
        filterKey: 'MINIMUM_STOCK',
        currency: false,
        containers: false,
      },
      MAXIMUM_STOCK: {
        key: 'MAXIMUM_STOCK',
        name: $localize`Maximum stock level`,
        filterKey: 'MAXIMUM_STOCK',
        currency: false,
        containers: false,
        maximumStock: true,
      },
      ORDER_UP_TO_LEVEL: {
        key: 'ORDER_UP_TO_LEVEL',
        name: $localize`Order up to`,
        filterKey: 'ORDER_UP_TO_LEVEL',
        currency: false,
        containers: false,
      },
      RELIABILITY: {
        key: 'RELIABILITY',
        name: $localize`Reliability`,
        filterKey: 'RELIABILITY',
        currency: false,
        containers: false,
      },
      FREE_STOCK: {
        key: 'FREE_STOCK',
        name: $localize`Supplier product stock`,
        filterKey: 'FREE_STOCK',
        currency: false,
        containers: false,
      },
      LEAD_TIME: {
        key: 'LEAD_TIME',
        name: $localize`Lead time`,
        filterKey: 'LEAD_TIME',
        currency: false,
        containers: false,
      },
      WEIGHT: {
        key: 'WEIGHT',
        name: $localize`Weight`,
        unit: 'g',
        filterKey: 'WEIGHT',
        currency: false,
        containers: true,
      },
      VOLUME: {
        key: 'VOLUME',
        name: $localize`Volume`,
        unit: 'cm³',
        filterKey: 'VOLUME',
        currency: false,
        containers: true,
      },
      QUANTITY: {
        key: 'QUANTITY',
        name: $localize`Quantity`,
        filterKey: 'QUANTITY',
        currency: false,
        containers: false,
      },
      PRICE: {
        key: 'PRICE',
        name: $localize`Price`,
        unit: '€',
        filterKey: 'PRICE',
        currency: true,
        containers: false,
      },
      TOTAL_PRICE: {
        key: 'TOTAL_PRICE',
        name: $localize`Total Price`,
        unit: '€',
        filterKey: 'TOTAL_PRICE',
        currency: true,
        containers: false,
      },
      MINUTES_SINCE_LAST_ADVICE_UPDATE: {
        key: 'MINUTES_SINCE_LAST_ADVICE_UPDATE',
        name: $localize`Advice updated`,
        filterKey: 'MINUTES_SINCE_LAST_ADVICE_UPDATE',
        currency: false,
        containers: false,
      },
      CREATED_AT_REMOTE: {
        key: 'CREATED_AT_REMOTE',
        name: $localize`Remote creation date`,
        filterKey: 'CREATED_AT_REMOTE',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
  },
  tooltips: {
    purchaseEditor: {
      totalContainers: $localize`The container percentage is relative to all containers in use, so you have a better perspective of how much volume or weight your full order occupies`,
      invalidMinimalOrderValue: $localize`This supplier has a minimum order value of`,
      supplierProductActive: $localize`Active`,
      supplierProductInactive: $localize`Inactive`,
      supplierProductPreferred: $localize`Preferred`,
      factor: {
        beforeChange: $localize`Any manual changes will be overwritten.`,
        invalidMin: $localize`The order size value must be greater than 0`,
      },
      updateRefresh: $localize`Updates product data and quantities. Manual changes won’t be overwritten.`,
      updateRefreshDisable: $localize`Auto-updates are not available for draft orders`,
      updateRefreshIcon: $localize`Quantities will be updated. Manual edits won’t be changed.`,
      orderlineType: {
        manuallyAdded: $localize`Manually added by the user`,
        manuallyRemoved: $localize`Manually removed by the user`,
      },
      adviceEdited: $localize`Advice edited`,
      lowerFreeStock: $localize`Supplier product free stock is lower than the order quantity`,
    },
    purchaseOverlay: {
      OpenNewWindow: $localize`Open in new window`,
      DockRight: $localize`Dock to right`,
      DockBottom: $localize`Dock to bottom`,
    },
  },
  notifications: {
    purchase: {
      saved: $localize`Order saved`,
      saveFailed: $localize`The order could not be saved due to an error`,
      deleted: $localize`The order deleted`,
      deleteFailed: $localize`The order could not be deleted due to an error`,
      failedRetrieval: $localize`Could not retrieve the orders list at this time`,
    },
    purchaseEditor: {
      failedRetrieval: $localize`Could not retrieve the orderlines list at this time`,
      failedOverviewRetrieval: $localize`Could not retrieve the orderline overview at this time`,
      sessionUpdateFailed: $localize`Session could not be updated: `,
      removingProducts: $localize`Removing product(s)...`,
      removedProducts: $localize`Product(s) removed.`,
      snoozingProducts: $localize`Snoozing product(s)...`,
      snoozedProducts: $localize`Product(s) snoozed.`,
      addingProducts: $localize`Adding product(s)...`,
      addedProducts: $localize`Product(s) added.`,
    },
  },
  metadata: {
    title: $localize`Purchase`,
    draftTitle: $localize`Draft order`,
    tabs: {
      planned: $localize`Planned orders`,
      draft: $localize`Draft orders`,
    },
    manualOrder: {
      create: $localize`Create manual order`,
      placeholder: $localize`Search for a supplier…`,
    },
    purchase: {
      orderStatus: {
        daysOverdue: $localize`day(s) overdue`,
        orderToday: $localize`Order today`,
        futureOrder: $localize`Future order`,
        today: $localize`Today`,
        overdue: $localize`Overdue`,
        future: $localize`Future`,
      },
      orderMomentTypes: {
        regular: $localize`Regular`,
        regularPreponed: $localize`Preponed`,
        lostSales: $localize`Lost sales`,
        backorder: $localize`Backorder`,
      },
      actions: {
        order: $localize`Order`,
        buyNow: $localize`Buy now`,
        deleteOrder: $localize`Delete`,
        ignoreOrder: $localize`Ignore`,
        skipOrder: $localize`Skip`,
        viewOrder: $localize`View order`,
      },
    },
    purchaseEditor: {
      titles: {
        proposed: {
          title: $localize`Current order`,
          subtitle: $localize`All the products in your order. You can add more products from the section below.`,
        },
        availableProducts: {
          title: $localize`Available products`,
          subtitle: $localize`Add additional products from the same supplier to your order.`,
        },
      },
      orderlineType: {
        proposed: $localize`Proposed`,
        manuallyAdded: $localize`Added`,
        edited: $localize`Edited`,
        available: $localize`Available`,
        manuallyRemoved: $localize`Removed`,
        snoozed: $localize`Snoozed`,
      },
      adviceUpdateAt: {
        alternativeTitle: $localize`Data updated`,
        suffix: {
          justNow: $localize`< 1 min. ago`,
          updatedMinutes: $localize`min. ago`,
          updatedHours: $localize`hour(s) ago`,
          updatedDays: $localize`day(s) ago`,
          updatedMonths: $localize`month(s) ago`,
        },
      },
      banners: {
        futureOrder: $localize`This is a future order moment. Placing this order before the planned date can lead to unneeded purchasing costs.`,
      },
      actions: {
        snooze: $localize`Snooze`,
        remove: $localize`Remove`,
        add: $localize`Add`,
        placeOrder: $localize`Place order...`,
        saveOrder: $localize`Save order...`,
        saveOrderSkip: $localize`Save order`,
        checkingOut: $localize`Checking out...`,
        saveDraft: $localize`Save as draft`,
        updateConcept: $localize`Update concept`,
        saveChanges: $localize`Save changes`,
        duplicateraft: $localize`Duplicate draft order`,
        connectProductToSupplier: $localize`Connect product to supplier`,
        skipPurchaseAdvice: $localize`Skip purchase advice`,
        ignoreSupplier: $localize`Ignore supplier`,
      },
      suffix: {
        updated: $localize`min. ago`,
        justNow: $localize`< 1 min. ago`,
      },
    },
    purchaseConfirmation: {
      title: $localize`Place order`,
      subtitle: $localize`Are you sure you want to place your order?`,
      send: $localize`Send`,
      sendingEmails: $localize`Sending emails...`,
      dataNotSynced: $localize`Data has been recalculated in our system. A reload is adviced`,
      expectedDeliveryDate: $localize`Your order is expected to be delivered by`,
      agendaReplan: {
        title: $localize`Replan agenda?`,
        subtitle: $localize`You are placing an order that is not planned for today. Would you like to
        replan the agenda?`,
        replan: $localize`Replan agenda`,
        advisedTomorrow: $localize`Advised. The next order moment is tomorrow.`,
        advisedToday: $localize`Advised. The next order moment is today.`,
        noReplan: $localize`Do not replan`,
        loading: $localize`Loading agenda replan...`,
      },
      additionalOptions: $localize`Additional options`,
      info: $localize`You can email and export your order after placing`,
      supplierSettings: {
        toggle: $localize`Send order details to supplier(s) by email`,
        title: $localize`Recipients`,
        noIntendedEmail: $localize`Can't find the email you're looking for?`,
        noEmails: $localize`The supplier doesn't have any registered emails.`,
        create: $localize`Create`,
        addNew: $localize`Add new email`,
      },
      shouldSaveProductChanges: $localize`Save modified product values`,
      actions: {
        cancel: $localize`Cancel`,
        placeOrder: $localize`Place order`,
        placingOrder: $localize`Placing order...`,
      },
      emailsDropdown: {
        title: $localize`My supplier emails`,
        reset: $localize`Reset`,
        selectAll: $localize`Select all`,
      },
      advancedExportsSettings: {
        warning: $localize`You can select up to 4 fields to export.`,
        supplierReference: {
          title: $localize`Supplier product details`,
          description: $localize`These are supplier reference codes.`,
        },
        webshopReference: {
          title: $localize`Webshop product details`,
          description: $localize`These are your internal reference codes.`,
        },
        fields: {
          [PurchaseExportsSettingsOptions.SP_NAME]: $localize`Name`,
          [PurchaseExportsSettingsOptions.SP_ARTICLE_CODE]: $localize`Article code`,
          [PurchaseExportsSettingsOptions.SP_EAN_CODE]: $localize`EAN code`,
          [PurchaseExportsSettingsOptions.SP_SKU_CODE]: $localize`SKU`,
          [PurchaseExportsSettingsOptions.WP_NAME]: $localize`Name`,
          [PurchaseExportsSettingsOptions.WP_ARTICLE_CODE]: $localize`Article code`,
          [PurchaseExportsSettingsOptions.WP_EAN_CODE]: $localize`EAN code`,
          [PurchaseExportsSettingsOptions.WP_SKU_CODE]: $localize`SKU`,
        },
      },
    },
    checkoutDialog: {
      title: $localize`Save order`,
      subtitle: $localize`This will create a draft version of the current order and\nthe auto updates will be turned off.`,
      message: $localize`You can place your order after the checkout.`,
      showAgain: $localize`Don't show again`,
      doNotAskAgain: $localize`Do not ask again`,
      actions: {
        cancel: $localize`Cancel`,
        placeOrder: $localize`Proceed`,
      },
    },
    purchaseOverview: {
      containers: {
        title: $localize`Container info`,
        totalWeight: $localize`Weight`,
        totalVolume: $localize`Volume`,
        totalContainers: $localize`Containers`,
      },
      totals: {
        totalValue: $localize`Order value`,
        totalProducts: $localize`Products in order`,
      },
      supplierInfo: {
        title: $localize`Supplier info`,
        planned: $localize`Planned`,
        type: $localize`Type`,
        triggerValue: $localize`Trigger value`,
        minOrderValue: $localize`Min. order value`,
        reorderPeriod: $localize`Reorder period`,
        leadTime: $localize`Lead time`,
        measuredLeadTime: $localize`Measured lead time (avg.)`,
      },
      notes: {
        title: $localize`Supplier notes`,
      },
      factor: {
        apply: $localize`Adjust order size`,
      },
    },
    purchaseOverlay: {
      titles: {
        primary: $localize`Order details`,
        proposed: $localize`Proposed by Optiply`,
        added: $localize`Added manually`,
        removed: $localize`Removed manually`,
        available: $localize`Available product`,
      },
      labels: {
        totalPrice: $localize`Total`,
        changes: $localize`Changes are automatically applied`,
      },
      states: {
        loading: $localize`Loading product order information...`,
      },
    },
    snoozeDialog: {
      nextOrderMoment: $localize`Next order moment`,
      oneWeek: $localize`One week`,
      oneMonth: $localize`One month`,
      threeMonths: $localize`Three months`,
      customDate: $localize`Custom date`,
      indefinite: $localize`Indefinite`,
      actions: {
        snoozeProduct: $localize`Snooze product`,
      },
    },
    promotionsPopover: {
      title: $localize`Promotions`,
      name: $localize`Promotion name`,
      upliftType: $localize`Uplift type`,
      uplift: $localize`Expected uplift`,
    },
    states: {
      loading: $localize`Loading contents...`,
      noData: $localize`There is no data available.`,
    },
    product: {
      placeholder: $localize`Select a product…`,
    },
    skipOrderMomentDialog: {
      title: $localize`Skip order?`,
      actionName: $localize`Skip order`,
    },
  },
};
