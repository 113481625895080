export class UpdateImportList {
  static readonly type = '[Imports] Update Import List';

  constructor(
    public data: any,
    public type: string
  ) {}
}

export class ResetImportData {
  static readonly type = '[Imports] Reset Import Data';
}
