import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
  standalone: true,
})
export class SentenceCasePipe implements PipeTransform {
  transform(word: string): string {
    if (!word || typeof word !== 'string') return;

    const filteredWord = this._removeUnderline(word);

    const sentencedCaseWords = filteredWord.map(
      (individualWord: string, idx: number) => {
        if (idx > 0) return individualWord.toLocaleLowerCase();

        return this._applySentenceCase(individualWord);
      }
    );

    return sentencedCaseWords.join(' ');
  }

  private _removeUnderline(word: string): string[] {
    return word.split('_');
  }

  private _applySentenceCase(word: string): string {
    return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
  }
}
