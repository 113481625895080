import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Basic filter component.
 * Provides a simple layout where users can type text to filter out data.
 * The parent component is responsible for filtering the actual data.
 */
@Component({
  selector: 'app-basic-filter',
  templateUrl: './basic-filter.component.html',
  styleUrls: ['./basic-filter.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicFilterComponent implements OnInit, OnDestroy {
  @Output() filteredText = new EventEmitter<string>();

  @Input() placeholder = '';

  @Input() debounceTime = 250;

  @Output() closeComponent = new EventEmitter<void>();

  debouncer: Subject<string> = new Subject<string>();

  /** Stores the current filter text */
  currentFilteredText = '';

  ngOnInit(): void {
    this.debouncer.pipe(debounceTime(this.debounceTime)).subscribe(value => {
      this.filteredText.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.debouncer.complete();
  }

  /**
   * Triggered when the user presses any key.
   * the key Enter is ignored to avoid line breaks.
   * @param $input the DOM event
   */
  onFilterKeyInput($event): boolean {
    return $event.code !== 'Enter';
  }

  /**
   * Clears the text filter.
   */
  onClear(): void {
    this.currentFilteredText = '';
    this.debouncer.next(this.currentFilteredText);
    this.closeComponent.emit();
  }

  /**
   * Triggered when the filter text changes.
   * It has a small period of accumulation to avoid overloading
   * the parent component with events.
   * @param $event the DOM event.
   */
  onInput($event): void {
    this.currentFilteredText = $event.target.value;
    this.debouncer.next(this.currentFilteredText);
  }
}
