import {
  FilterRangeGroupV2,
  FilterRangeValueType,
  FilterStrategy,
} from '../../../model/filter-v2.model';

export class FilterRangeStrategy implements FilterStrategy<FilterRangeGroupV2> {
  form: FilterRangeGroupV2 = {
    from: null,
    to: null,
  };

  constructor(private isDate: boolean) {}

  populate(value: FilterRangeGroupV2): void {
    this.form.from = value.from;
    this.form.to = value.to;
  }

  hasValue(): boolean {
    return (
      (this.form.from !== null && this.form.from !== '') ||
      (this.form.to !== null && this.form.to !== '')
    );
  }

  hasSameValue(value: FilterRangeGroupV2 | undefined | null): boolean {
    if (value === null && this.form.from === null && this.form.to === null)
      return true;

    if (value === null && (this.form.from !== null || this.form.to !== null))
      return false;

    if (this.isDate) {
      return this._hasSameDateValue(value.from as Date, value.to as Date);
    }

    return this._hasSamePrimitiveValue(
      value.from as FilterRangeValueType,
      value.to as FilterRangeValueType
    );
  }

  clear(): void {
    this.form = {
      from: null,
      to: null,
    };
  }

  private _hasSamePrimitiveValue(
    from: FilterRangeValueType,
    to: FilterRangeValueType
  ): boolean {
    return from === this.form.from && to === this.form.to;
  }

  private _hasSameDateValue(from: Date | null, to: Date | null): boolean {
    return (
      from?.getTime() === (this.form.from as Date)?.getTime() &&
      to?.getTime() === (this.form.to as Date)?.getTime()
    );
  }
}
