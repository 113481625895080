import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  QueryList,
  signal,
} from '@angular/core';
import { ColumnViewV3TemplateComponent } from '../column-view-v3-template/column-view-v3-template.component';

@Component({
  selector: 'app-column-view-v3-group',
  templateUrl: './column-view-v3-group.component.html',
  styleUrl: './column-view-v3-group.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnViewV3GroupComponent implements AfterViewInit {
  @ContentChildren(ColumnViewV3TemplateComponent)
  columnsGroup: QueryList<ColumnViewV3TemplateComponent>;

  currentGroup = signal({
    index: 0,
    template: null,
  });

  ngAfterViewInit(): void {
    this.selectCurrentGroup(0, this.columnsGroup.first);
  }

  selectCurrentGroup(
    index: number,
    group: ColumnViewV3TemplateComponent
  ): void {
    if (!group) return;

    this.currentGroup.set({
      index,
      template: group.content,
    });
  }
}
