import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  booleanAttribute,
} from '@angular/core';
import { RADIO_GROUP, RadioChange } from './model/radio-button-v2.model';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { RadioButtonGroupV2Directive } from './directives/radio-button-group-v2.directive';

let uniqueId = 0;

@Component({
  selector: 'app-radio-button-v2',
  templateUrl: './radio-button-v2.component.html',
  styleUrls: ['./radio-button-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonV2Component implements OnInit, OnDestroy {
  @ViewChild('input')
  inputElement: ElementRef<HTMLInputElement>;

  @Input()
  id: string = `radio-${++uniqueId}`;

  @Input()
  name: string;

  @Input({ transform: booleanAttribute })
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (this._checked !== value) {
      this._checked = value;
      if (value && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      } else if (
        !value &&
        this.radioGroup &&
        this.radioGroup.value === this.value
      ) {
        // When unchecking the selected radio button, update the selected radio
        // property on the group.
        this.radioGroup.selected = null;
      }

      if (value) {
        // Notify all radio buttons with the same name to un-check.
        this._radioDispatcher.notify(this.id, this.name);
      }
      this.cdr.markForCheck();
    }
  }
  private _checked: boolean = false;

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup !== null) {
        if (!this.checked) {
          // Update checked when the value changed to match the radio group's value
          this.checked = this.radioGroup.value === value;
        }
        if (this.checked) {
          this.radioGroup.selected = this;
        }
      }
    }
  }
  private _value: any;

  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled || this.radioGroup?.disabled;
  }
  set disabled(value: boolean) {
    this.setDisabled(value);
  }
  private _disabled: boolean = false;

  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return this._required || this.radioGroup?.required;
  }
  set required(value: boolean) {
    this._required = value;
  }
  private _required: boolean = false;

  @Output()
  readonly changed: EventEmitter<RadioChange> = new EventEmitter<RadioChange>();

  radioGroup: RadioButtonGroupV2Directive;

  /** Unregister function for _radioDispatcher */
  private _removeUniqueSelectionListener: () => void = () => {};

  constructor(
    @Optional() @Inject(RADIO_GROUP) radioGroup: RadioButtonGroupV2Directive,
    private cdr: ChangeDetectorRef,
    private _radioDispatcher: UniqueSelectionDispatcher
  ) {
    this.radioGroup = radioGroup;
  }

  ngOnInit(): void {
    if (this.radioGroup) {
      this.checked = this.radioGroup.value === this._value;

      if (this.checked) {
        this.radioGroup.selected = this;
      }

      this.name = this.radioGroup.name;
    }

    this._removeUniqueSelectionListener = this._radioDispatcher.listen(
      (id, name) => {
        if (id !== this.id && name === this.name) {
          this.checked = false;
        }
      }
    );
  }

  markForCheck() {
    this.cdr.markForCheck();
  }

  _onInputClick(event: Event) {
    event.stopPropagation();
  }

  onInputInteraction(event: Event) {
    event.stopPropagation();

    if (!this.checked && !this.disabled) {
      const groupValueChanged =
        this.radioGroup && this.value !== this.radioGroup.value;
      this.checked = true;
      this._emitChangeEvent();

      if (this.radioGroup) {
        this.radioGroup.controlValueAccessorChangeFn(this.value);
        if (groupValueChanged) {
          this.radioGroup.emitChangeEvent();
        }
      }
    }
  }

  onTouchTargetClick(event: Event) {
    this.onInputInteraction(event);

    if (!this.disabled) {
      this.inputElement.nativeElement.focus();
    }
  }

  setDisabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      this.cdr.markForCheck();
    }
  }

  get inputId(): string {
    return `${this.id}-input`;
  }

  private _emitChangeEvent(): void {
    this.changed.emit(new RadioChange(this, this._value));
  }

  ngOnDestroy(): void {
    this._removeUniqueSelectionListener();
  }
}
