import {
  FilterEqualsGroupV2,
  FilterStrategy,
} from '../../../model/filter-v2.model';

export class FilterEqualsStrategy
  implements FilterStrategy<FilterEqualsGroupV2>
{
  form: FilterEqualsGroupV2 = {
    to: null,
  };

  constructor(private isDate: boolean) {}

  populate(value: FilterEqualsGroupV2): void {
    this.form.to = value.to;
  }

  hasValue(): boolean {
    return this.form.to !== null && this.form.to !== '';
  }

  hasSameValue(value: FilterEqualsGroupV2 | undefined | null): boolean {
    if ((value === null || value === undefined) && this.form.to === null)
      return true;

    if ((value === null || value === undefined) && this.form.to !== null)
      return false;

    if (this.isDate) {
      return this._hasSameDateValue(value.to as Date);
    }

    return this._hasSamePrimitiveValue(value.to as string | number | null);
  }

  clear(): void {
    this.form = {
      to: null,
    };
  }

  private _hasSamePrimitiveValue(to: string | number | null): boolean {
    return to === this.form.to;
  }

  private _hasSameDateValue(to: Date | null): boolean {
    return to.getTime() === (this.form.to as Date).getTime();
  }
}
