<app-dialog-container
  ><div class="change-password-dialog">
    <div appDialogHeader>
      <h1 appDialogTitle>
        {{ STRINGS.title }}
      </h1>
      <button
        aria-label="Change password dialog close button"
        (click)="close()"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>
    <div appDialogBody>
      <p class="section-description">
        {{ STRINGS.description }}
      </p>
      <form #addProductForm="ngForm">
        <div class="change-password-container input-field">
          <label for="newPassword"> {{ STRINGS.newPassword }}</label>

          <div class="password-container">
            <input
              appInput
              variant="filled"
              [type]="newInputTypes"
              name="newPassword"
              [placeholder]="STRINGS.newPassword"
              (input)="onNewPasswordChangedChanged($event.target.value)"
              required />

            <mat-icon
              (click)="newTogglePasswordVisibility()"
              class="pass-placeholder"
              [svgIcon]="
                newInputTypes === 'password' ? 'eye-closed' : 'eye-open'
              ">
            </mat-icon>
          </div>
        </div>

        <div class="change-password-container input-field">
          <label class="conditions">
            {{ STRINGS.passwordValidations.title }}</label
          >
          <label [class]="passwordChecks.charactersNumber.class">
            <mat-icon
              [svgIcon]="passwordChecks.charactersNumber.icon"></mat-icon>
            {{ STRINGS.passwordValidations.minimumCharacters }}</label
          >

          <label class="conditions">
            <mat-icon svgIcon="check-xs"></mat-icon>
            {{ STRINGS.passwordValidations.atLeast }}</label
          >
          <label [class]="passwordChecks.lowerCase.class">
            <mat-icon [svgIcon]="passwordChecks.lowerCase.icon"></mat-icon>
            {{ STRINGS.passwordValidations.lowerCase }}</label
          >
          <label [class]="passwordChecks.upperCase.class">
            <mat-icon [svgIcon]="passwordChecks.upperCase.icon"></mat-icon>
            {{ STRINGS.passwordValidations.upperCase }}</label
          >
          <label [class]="passwordChecks.numbers.class">
            <mat-icon [svgIcon]="passwordChecks.numbers.icon"></mat-icon>
            {{ STRINGS.passwordValidations.numbers }}</label
          >
          <label [class]="passwordChecks.special.class">
            <mat-icon [svgIcon]="passwordChecks.special.icon"></mat-icon>
            {{ STRINGS.passwordValidations.special }}</label
          >
        </div>

        <div class="change-password-container input-field">
          <label for="confirmeNewPassword">
            {{ STRINGS.confirmNewPassword }}</label
          >
          <div class="password-container">
            <input
              appInput
              variant="filled"
              [type]="confirmInputTypes"
              name="confirmeNewPassword"
              [placeholder]="STRINGS.confirmNewPassword"
              (input)="onConfirmPasswordChangedChanged($event.target.value)"
              required />
            <mat-icon
              (click)="confirmTogglePasswordVisibility()"
              class="pass-placeholder"
              [svgIcon]="
                confirmInputTypes === 'password' ? 'eye-closed' : 'eye-open'
              ">
            </mat-icon>
          </div>
          <div class="error-container" *ngIf="checkPasswordSimililarity()">
            <app-tag variant="subtle" maxWidth="530" color="red">
              <mat-icon appTagIcon svgIcon="warning-rounded"></mat-icon>
              {{ STRINGS.passwordMatchError }}
            </app-tag>
          </div>
        </div>
      </form>
    </div>
    <div appDialogActions>
      <div class="right-action-buttons-container">
        <button
          aria-label="Change password dialog cancel button"
          appButton
          variant="tertiary"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          aria-label="Change password dialog save button"
          appButton
          variant="primary"
          [disabled]="!checkPasswords() || confirmPassword === ''"
          (click)="save()">
          {{ STRINGS.changePasswordAction }}
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
