import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';
import {
  TAG_DEFAULTS,
  TagDefaults,
  TagEvent,
  TagVariant,
  TagVariantColor,
  tagDefaults,
} from './model/tag.model';
import { TagIconDirective } from './directives/tag-icon.directive';

let uid: number = 0;

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TagComponent implements OnDestroy {
  @ContentChild(TagIconDirective, { static: true })
  tagIcon: TagIconDirective;

  @Input()
  id: string = `tag-${uid++}`;

  @Input({ transform: booleanAttribute })
  disabled: boolean;

  /**
   * Tag's value if you need to include them in a form
   */
  @Input()
  value: any;

  /**
   * Indicates if the tag is removable.
   *
   * If so, it adds the button icon and emits event when removed.
   *
   * NOTE: It doesn't remove it from the DOM, it only emits the event.
   */
  @Input({ transform: booleanAttribute })
  removable: boolean;

  /**
   * Indicates if the tag is clickable.
   */
  @Input({ transform: booleanAttribute })
  clickable: boolean;

  /**
   * Indicates if the tag is rounded.
   */
  @Input({ transform: booleanAttribute })
  rounded: boolean;

  @Input({ transform: numberAttribute })
  maxWidth: number;

  @Input()
  variant: TagVariant;

  @Input()
  color: TagVariantColor;

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Output()
  readonly clicked: EventEmitter<TagEvent> = new EventEmitter<TagEvent>();

  @Output()
  readonly removed: EventEmitter<TagEvent> = new EventEmitter<TagEvent>();

  @Output()
  readonly destroyed: EventEmitter<TagEvent> = new EventEmitter<TagEvent>();

  constructor(@Optional() @Inject(TAG_DEFAULTS) private defaults: TagDefaults) {
    if (this.defaults) {
      this._applyDefaults();
    }
  }

  remove(): void {
    if (this.removable && !this.disabled) {
      this.removed.emit({ tag: this, value: this.value });
    }
  }

  click(): void {
    if (this.clickable && !this.disabled) {
      this.clicked.emit({ tag: this, value: this.value });
    }
  }

  get tagClasses(): string[] {
    const variant = this.variant ? this.variant : tagDefaults.variant;
    const color = this.color ? this.color : tagDefaults.color;
    const clickable = this.clickable ? 'tag-clickable' : '';
    const removable = this.removable ? 'tag-removable' : '';
    const rounded = this.rounded ? 'tag-rounded' : 'tag-squared';
    const disabled = this.disabled ? 'tag-disabled' : '';
    const extraPadding = !this.hasIcon ? 'tag-base-extra-padding' : '';

    return [
      'tag-base',
      `tag-${variant}-${color}`,
      `tag-${this.size}`,
      clickable,
      removable,
      rounded,
      disabled,
      extraPadding,
    ];
  }

  get hasIcon(): boolean {
    return !!this.tagIcon;
  }

  private _applyDefaults(): void {
    if (this.defaults.variant) {
      this.variant = this.defaults.variant;
    }

    if (this.defaults.color) {
      this.color = this.defaults.color;
    }

    if (this.defaults.color) {
      this.rounded = this.defaults.rounded;
    }
  }

  ngOnDestroy(): void {
    this.removed.complete();
    this.clicked.complete();

    this.destroyed.emit({ tag: this, value: null });
    this.destroyed.complete();
  }
}
