import { DynamicTags } from 'src/app/shared/components/design-system/tags/model/tag.model';
import { NULL_VALUE } from '../../core.model';

export interface Supplier {
  uuid: string;
  id: number;
  name: string;
  fixedCosts: number;
  deliveryTime: number;
  minimumOrderValue: number;
  defaultWarehouse: string;
  leadTimeMeanActual: number;
  leadTimeSdActual: number;
  leadTimeSdPct: number;
  ignored: boolean;
  backorders: boolean;
  reactingToLostSales: boolean;
  backorderThreshold: number;
  lostSalesReaction: number;
  backordersReaction: number;
  agendaManagedManually: boolean;
  agendaManagedData: string;
  lostSalesMovReaction: number;
  exportQuantityAsLots: boolean;
  excludePriceFromExports: boolean;
  effectiveReplenishmentPeriod: number;
  replenishmentPeriod: number;
  userReplenishmentPeriod: number;
  maxLoadCapacity: number;
  containerVolume: number;
  globalLocationNumber: string;
  preferredEmailLanguage?: string;
  emails: string[];
  issues?: string[];
  notes?: string;
  cutoffHH?: string;
  cutoffMM?: string;
  communicationMethod: string;
  extras?: SupplierExtras;
}
export interface SupplierExtras {
  tags: DynamicTags;
}

export interface SupplierManager {
  accountUuids: string[];
  ownerUuid: string;
}

export enum SupplierCommunications {
  EMAIL = 'EMAIL',
  EDI = 'EDI',
  NONE = null,
}

export type SupplierCommunication = 'EMAIL' | 'EDI' | null;
