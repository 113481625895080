import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  booleanAttribute,
  input,
  model,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-filter-footer',
  templateUrl: './filter-footer.component.html',
  styleUrls: ['./filter-footer.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFooterComponent {
  disableApply = input(false, { transform: booleanAttribute });

  showApplyAll = input(false, { transform: booleanAttribute });

  applyAll = model<boolean>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  apply: EventEmitter<void> = new EventEmitter<void>();

  readonly COMMON_STRINGS = MESSAGES.common;
}
