<app-product-container>
  <span header>{{ METADATA.title }}</span>

  <div class="o-content">
    @if (overview(); as overview) {
      <app-top-overview-row
        [headerTitle]="METADATA.category"
        [disabled]="overview.ignored || overview.assembled"
        data-intercom-target="11792483-445e-4e39-9d01-bf95bcde7ce5"
        aria-label="Product details category">
        <app-product-category
          [category]="overview.category"
          [advancedCategorization]="enabledAdvancedCategorization()"
          [advancedCategory]="overview.xyzCategory"
          [disabled]="overview.assembled || overview.ignored" />
      </app-top-overview-row>

      <app-top-overview-row
        [headerTitle]="METADATA.sku"
        data-intercom-target="12ec9667-0073-4093-b4a3-657840bef0dc"
        aria-label="Product details sku">
        <ng-container *ngIf="overview.sku !== null; else notAvailable">
          <app-copy-to-clipboard
            [message]="overview.sku"></app-copy-to-clipboard>
        </ng-container>
      </app-top-overview-row>

      <app-top-overview-row
        [headerTitle]="METADATA.serviceLevel"
        [disabled]="overview.assembled || overview.ignored"
        data-intercom-target="e1bb9a0f-2457-43e3-9b9f-73faedf9bdba"
        aria-label="Product details service level">
        <ng-container *ngIf="overview.serviceLevel !== null; else notAvailable">
          {{ overview.serviceLevel }}
          <span class="o-description">%</span>
        </ng-container>
      </app-top-overview-row>

      <app-top-overview-row
        [headerTitle]="METADATA.freeStock"
        [disabled]="overview.assembled || overview.ignored"
        data-intercom-target="2aa7418e-73e9-4163-ae08-2fdf9e2a2be8"
        aria-label="Product details stock on hand">
        <ng-container *ngIf="overview.stockOnHand !== null; else notAvailable">
          {{ overview.stockOnHand | customDecimal }}
        </ng-container>
      </app-top-overview-row>

      <app-top-overview-row
        [disabled]="overview.assembled || overview.ignored"
        data-intercom-target="1ad8379b-7337-4445-94f4-0094bab3aff8"
        aria-label="Product details demand per month">
        <ng-template #customHeader>
          <span class="custom-header">
            <span>{{ EXPECTED_DEMAND_COLUMN_LABEL }}</span>
            <mat-icon
              svgIcon="questionmark-rounded"
              [appTooltip]="METADATA.demandDescription"
              class="custom-header-icon"></mat-icon>
          </span>
        </ng-template>

        <ng-container
          *ngIf="overview.demandPerMonth !== null; else notAvailable">
          {{ overview.demandPerMonth }}
        </ng-container>
      </app-top-overview-row>

      <app-top-overview-row>
        <ng-template #customHeader>
          <span class="custom-header">
            <span>{{ METADATA.predecessor }}</span>
            <mat-icon class="custom-header-icon" svgIcon="chain-xs"></mat-icon>
          </span>
        </ng-template>
        @if (predecessorProduct(); as predecessor) {
          @if (isOverlay) {
            <ng-container>
              <span
                class="predecessor-restrictions"
                [appTooltip]="predecessor.name"
                tooltipShouldCheckOverflow>
                {{ predecessor.name }}
              </span>
            </ng-container>
          } @else {
            <span
              class="deep-linking-text predecessor-restrictions"
              (click)="goToPredecessor(predecessor.uuid)"
              [appTooltip]="predecessor.name"
              tooltipShouldCheckOverflow>
              {{ predecessor.name }}
            </span>
          }
        } @else {
          {{ NOT_AVAILABLE }}
        }
      </app-top-overview-row>

      <app-top-overview-row>
        <ng-template #customHeader>
          <span class="custom-header">
            <span>{{ METADATA.successor }}</span>
            <mat-icon class="custom-header-icon" svgIcon="chain-xs"></mat-icon>
          </span>
        </ng-template>
        @if (successorProduct(); as successor) {
          @if (isOverlay) {
            <ng-container>
              <span
                class="predecessor-restrictions"
                [appTooltip]="successor.name"
                tooltipShouldCheckOverflow>
                {{ successor.name }}
              </span>
            </ng-container>
          } @else {
            <span
              class="deep-linking-text predecessor-restrictions"
              (click)="goToSuccessor(successor.uuid)"
              [appTooltip]="successor.name"
              tooltipShouldCheckOverflow>
              {{ successor.name }}
            </span>
          }
        } @else {
          {{ NOT_AVAILABLE }}
        }
      </app-top-overview-row>
    }
  </div>
</app-product-container>

<ng-template #notAvailable>
  {{ NOT_AVAILABLE }}
</ng-template>
