import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ToastConfig } from '../../core/toast-config';
import { Observable, Subject, take } from 'rxjs';
import { toastAnimations } from '../../core/toast-animations';
import { AnimationEvent } from '@angular/animations';
import { ToastAnimationStates } from '../../model/toast.model';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [toastAnimations.toastSlideState],
})
export class ToastContainerComponent
  extends BasePortalOutlet
  implements OnDestroy
{
  @ViewChild(CdkPortalOutlet, { static: true })
  portalOutlet: CdkPortalOutlet;

  toastAnimationState: string = ToastAnimationStates.VOID;

  readonly _onEnter: Subject<void> = new Subject();

  readonly _onExit: Subject<void> = new Subject();

  private _destroyed: boolean = false;

  constructor(
    private ngZone: NgZone,
    private elementRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    public toastConfig: ToastConfig
  ) {
    super();
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    this._checkToastContentAlreadyAttached();

    const portalRef = this.portalOutlet.attachComponentPortal(portal);

    this._applyExtraClasses();

    return portalRef;
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    this._checkToastContentAlreadyAttached();

    const portalRef = this.portalOutlet.attachTemplatePortal(portal);

    this._applyExtraClasses();

    return portalRef;
  }

  enter(): void {
    if (!this._destroyed) {
      this.toastAnimationState =
        this.toastConfig.verticalPosition === 'top'
          ? ToastAnimationStates.VISIBLE
          : ToastAnimationStates.VISIBLE_INVERT;
      this.cdr.detectChanges();
    }
  }

  exit(): Observable<void> {
    this.ngZone.run(() => {
      this.toastAnimationState = ToastAnimationStates.HIDDEN;
      this.cdr.detectChanges();
    });

    return this._onExit;
  }

  /**
   * Handles toast animations.
   * Depending on the animation, the toast will enter / exit the view
   * @param event AnimationEvent
   */
  handleAnimation(event: AnimationEvent) {
    const { fromState, toState } = event;

    if (
      (toState === ToastAnimationStates.VOID &&
        fromState !== ToastAnimationStates.VOID) ||
      toState === ToastAnimationStates.HIDDEN
    ) {
      this._completeExit();
    }

    if (
      toState === ToastAnimationStates.VISIBLE ||
      toState === ToastAnimationStates.VISIBLE_INVERT
    ) {
      this._completeEnter();
    }
  }

  private _checkToastContentAlreadyAttached() {
    if (this.portalOutlet.hasAttached()) {
      throw Error(
        'Attempting to attach toast content after content is already attached'
      );
    }
  }

  private _completeEnter() {
    const onEnter = this._onEnter;

    this.ngZone.run(() => {
      onEnter.next();
      onEnter.complete();
    });
  }

  private _completeExit() {
    this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
      this.ngZone.run(() => {
        this._onExit.next();
        this._onExit.complete();
      });
    });
  }

  private _applyExtraClasses(): void {
    const element: HTMLElement = this.elementRef.nativeElement;
    const extraClasses = this.toastConfig.extraClasses;

    if (extraClasses) {
      if (Array.isArray(extraClasses)) {
        extraClasses.forEach((extraClass: string) =>
          element.classList.add(extraClass)
        );
      } else {
        element.classList.add(extraClasses);
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyed = true;
    this._completeExit();
  }
}
