import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableSort,
  buildGroupsMap,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { COLUMNS_GROUPS } from 'src/app/shared/models/datatable/v2/columns-groups.model';

export const defaultSort: DatatableSort = {
  [STRINGS.columns.productSuppliers.SUPPLIER_NAME.key]: {
    field: STRINGS.columns.productSuppliers.SUPPLIER_NAME.filterKey,
    order: SortOrder.ASC,
  },
};

export const COLUMNS_ORDER: ColumnOrder = {
  [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ACTIVE.key]: 1,
  [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED.key]: 2,
  [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE.key]: 3,
  [STRINGS.columns.productSuppliers.AVAILABLE_DATE.key]: 4,
  [STRINGS.columns.productSuppliers.SUPPLIER_NAME.key]: 5,
  [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.key]: 6,
  [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.key]: 7,
  [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE.key]: 8,
  [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ARTICLE_CODE.key]: 9,
  [STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF.key]: 10,
  [STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY.key]: 11,
  [STRINGS.columns.productSuppliers.LEAD_TIME.key]: 12,
  [STRINGS.columns.productSuppliers.WEIGHT.key]: 13,
  [STRINGS.columns.productSuppliers.VOLUME.key]: 14,
  [STRINGS.columns.productSuppliers.PRICE.key]: 15,
  [STRINGS.columns.productSuppliers.ACTIONS.key]: 16,
};

export const defaultColumnsV2: DatatableColumnV2Groups<DatatableColumnV2> = {
  [COLUMNS_GROUPS.SUPPLIER_PRODUCT.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER_PRODUCT.key,
    groupName: COLUMNS_GROUPS.SUPPLIER_PRODUCT.name,
    columns: {
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ACTIVE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ACTIVE.key,
        displayName:
          STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ACTIVE.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED.key,
        displayName:
          STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE.key,
        displayName:
          STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.AVAILABLE_DATE.key]: {
        name: STRINGS.columns.productSuppliers.AVAILABLE_DATE.key,
        displayName: STRINGS.columns.productSuppliers.AVAILABLE_DATE.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.key,
        displayName:
          STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.key,
        displayName: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE.key,
        displayName:
          STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ARTICLE_CODE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ARTICLE_CODE
          .key,
        displayName:
          STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ARTICLE_CODE.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF.key]: {
        name: STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF.key,
        displayName:
          STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY.key]: {
        name: STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY.key,
        displayName:
          STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.LEAD_TIME.key]: {
        name: STRINGS.columns.productSuppliers.LEAD_TIME.key,
        displayName: STRINGS.columns.productSuppliers.LEAD_TIME.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.WEIGHT.key]: {
        name: STRINGS.columns.productSuppliers.WEIGHT.key,
        displayName: STRINGS.columns.productSuppliers.WEIGHT.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.VOLUME.key]: {
        name: STRINGS.columns.productSuppliers.VOLUME.key,
        displayName: STRINGS.columns.productSuppliers.VOLUME.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.PRICE.key]: {
        name: STRINGS.columns.productSuppliers.PRICE.key,
        displayName: STRINGS.columns.productSuppliers.PRICE.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.productSuppliers.SUPPLIER_NAME.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_NAME.key,
        displayName: STRINGS.columns.productSuppliers.SUPPLIER_NAME.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.FIXED_END.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_END.key,
    groupName: COLUMNS_GROUPS.FIXED_END.name,
    columns: {
      [STRINGS.columns.productSuppliers.ACTIONS.key]: {
        name: STRINGS.columns.productSuppliers.ACTIONS.key,
        displayName: STRINGS.columns.productSuppliers.ACTIONS.name,
        checked: false,
        type: null,
        selectable: false,
        disabled: false,
      },
    },
  },
};

export const columnsGroupsMap: Map<string, string> =
  buildGroupsMap(defaultColumnsV2);

export const defaultFiltersV2: DatatableColumnV2Groups<Filter> = {
  [COLUMNS_GROUPS.SUPPLIER_PRODUCT.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER_PRODUCT.key,
    groupName: COLUMNS_GROUPS.SUPPLIER_PRODUCT.name,
    columns: {
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ACTIVE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ACTIVE.name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ACTIVE.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED.name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED
          .filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE.name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE
          .filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE.name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE
          .filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ARTICLE_CODE.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ARTICLE_CODE
          .name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_ARTICLE_CODE
          .filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF.key]: {
        name: STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF.name,
        key: STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF
          .filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY.key]: {
        name: STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY.name,
        key: STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY
          .filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.LEAD_TIME.key]: {
        name: STRINGS.columns.productSuppliers.LEAD_TIME.name,
        key: STRINGS.columns.productSuppliers.LEAD_TIME.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.WEIGHT.key]: {
        name: STRINGS.columns.productSuppliers.WEIGHT.name,
        key: STRINGS.columns.productSuppliers.WEIGHT.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.VOLUME.key]: {
        name: STRINGS.columns.productSuppliers.VOLUME.name,
        key: STRINGS.columns.productSuppliers.VOLUME.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: null,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.productSuppliers.PRICE.key]: {
        name: STRINGS.columns.productSuppliers.PRICE.name,
        key: STRINGS.columns.productSuppliers.PRICE.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.productSuppliers.SUPPLIER_NAME.key]: {
        name: STRINGS.columns.productSuppliers.SUPPLIER_NAME.name,
        key: STRINGS.columns.productSuppliers.SUPPLIER_NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
    },
  },
};

export const filtersGroupsMap: Map<string, string> =
  buildGroupsMap(defaultFiltersV2);

export const defaultPagination: DatatablePagination = {
  page: 0,
  size: 50,
  pageSizeOptions: [10, 15, 25, 50, 75, 100, 125, 150, 200],
};
