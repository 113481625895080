import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';

export class LoadManualOrderV2AvailableProducts {
  static readonly type =
    '[Manual Order V2 Available Products] Load Available Products V2';

  constructor(public supplierUuid: string) {}
}

export class ReloadDatatable {
  static readonly type = '[Manual Order V2 Available Products] Reload';
}

export class AddSearchParam {
  static readonly type =
    '[Manual Order V2 Available Products] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type =
    '[Manual Order V2 Available Products] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type =
    '[Manual Order V2 Available Products] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Manual Order V2 Available Products] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type =
    '[Manual Order V2 Available Products] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type =
    '[Manual Order V2 Available Products] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Manual Order V2 Available Products] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type =
    '[Manual Order V2 Available Products] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type =
    '[Manual Order V2 Available Products] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Manual Order V2 Available Products] Hide Column';

  constructor(public columnKey: string) {}
}

export class AddProductToOrder {
  static readonly type =
    '[Manual Order V2 Available Products] Add Product to order';

  constructor(
    public supplierProductUuid: string,
    public quantity?: number
  ) {}
}

export class AddProductsToOrder {
  static readonly type =
    '[Manual Order V2 Available Products] Add Products to order';
}

export class OrderLineAdded {
  static readonly type = '[Manual Order Line Added] order line added';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderLinesAdded {
  static readonly type = '[Manual Order Lines Bulk Added] order lines added';
}

export class ToggleFilter {
  static readonly type =
    '[Manual Order V2 Available Product Added] Toggle Filter';
}

export class PatchLineQuantity {
  static readonly type =
    '[Manual Order V2 Available Products] Patch Line Quant';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedQuantity: string
  ) {}
}

export class ToggleRowSelection {
  static readonly type =
    '[Manual Order V2 Available Products] Toggle Row Selection';

  constructor(
    public rowKey: string,
    public rowQuantity: number,
    public isSelected: boolean
  ) {}
}

export class ClearSelection {
  static readonly type = '[Manual Order V2 Available Products] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type =
    '[Manual Order V2 Available Products] Master Toggle Selection';
}

export class ResetBuyOrder {
  static readonly type = '[Manual Order V2 Available Products] ResetBuyOrder';
}

export class ResetPaginationAndLoadData {
  static readonly type =
    '[Manual Order V2 Available Products] Reset Pagination And Load Data';
}
