import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ColumnFilterData } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

@Pipe({
  name: 'filterColumn',
})
export class ColumnFilterPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value: string, state: unknown): Observable<ColumnFilterData> {
    const columnFilterInfo: Observable<ColumnFilterData> = this.store.select(
      state['filterDataByColumnKey'](value)
    );

    return columnFilterInfo;
  }
}
