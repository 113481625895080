import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductDeliveriesV2DataTableComponent } from '../default/product-deliveries-v2-data-table.component';

@Component({
  selector: 'app-product-deliveries-v2-data-table-view-only',
  templateUrl: './product-deliveries-v2-data-table-view-only.component.html',
  styleUrl: '../default/product-deliveries-v2-data-table.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDeliveriesV2DataTableViewOnlyComponent extends ProductDeliveriesV2DataTableComponent {}
