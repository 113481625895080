import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
} from '@angular/core';
import { select, Store } from '@ngxs/store';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import {
  ChangePlanningDate,
  DisableReplan,
  EnableReplan,
} from '../../actions/purchase-confirmation-dialog-v2.actions';
import { PurchaseConfirmationQueries } from '../../state/purchase-confirmation-dialog-v2.queries';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-purchase-agenda',
  templateUrl: './purchase-agenda.component.html',
  styleUrl: './purchase-agenda.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseAgendaComponent {
  loading = input(false, { transform: booleanAttribute });

  disabled = input(false, { transform: booleanAttribute });

  replanEnabled = select(PurchaseConfirmationQueries.replanEnabled);

  planningMinDate = select(PurchaseConfirmationQueries.planningMinDate);

  planningDate = select(PurchaseConfirmationQueries.planningDate);

  daysToNextOrderMoment = select(
    PurchaseConfirmationQueries.daysToNextOrderMoment
  );

  nextOrderMomentDate = select(PurchaseConfirmationQueries.nextOrderMomentDate);

  readonly STRINGS = STRINGS.metadata.purchaseConfirmation;

  constructor(private store: Store) {}

  enableReplan(): void {
    this.store.dispatch(new EnableReplan());
  }

  disableReplan(): void {
    this.store.dispatch(new DisableReplan());
  }

  setPlanningDate(event: MatDatepickerInputEvent<any>): void {
    this.store.dispatch(new ChangePlanningDate(event.value));
  }
}
