import { DateTime } from 'luxon';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { PromotionUpliftTypes } from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { WebshopProductPromotions } from 'src/app/shared/models/promotion/v2/promotions-v2.model';
import { WebshopProductPromotions as WebshopProductPromotionsV1 } from '../model/promotions-v2.model';

export class WebshopProductPromotionsV2Adapter
  implements DataAdapter<WebshopProductPromotionsV1, WebshopProductPromotions>
{
  transform(data: WebshopProductPromotionsV1): WebshopProductPromotions {
    const webshopProductPromotions = data.webshopProducts.map(
      webshopProduct => {
        return {
          promotion: {
            uuid: webshopProduct.promotion.uuid,
            webshopUUID: webshopProduct.promotion.webshopUUID,
            name: webshopProduct.promotion.name,
            interval: {
              start: this._formatDate(webshopProduct.promotion.interval.start),
              end: this._formatDate(webshopProduct.promotion.interval.end),
            },
            uplift: {
              increase: webshopProduct.promotion.uplift.increase,
              type: webshopProduct.promotion.uplift.type,
            },
            numberOfProducts: webshopProduct.promotion.numberOfProducts,
            settings: {
              enabled: webshopProduct.promotion.settings.enabled,
            },
            createdAt: this._formatDate(webshopProduct.promotion.createdAt),
            updatedAt: this._formatDate(webshopProduct.promotion.updatedAt),
            deletedAt: this._formatDate(webshopProduct.promotion.deletedAt),
            id: webshopProduct.promotion.id,
          },
          webshopProduct: {
            uuid: webshopProduct.webshopProduct.uuid,
            promotionUUID: webshopProduct.webshopProduct.promotionUUID,
            specificUplift: webshopProduct.webshopProduct.specificUplift,
            effectiveUplift: {
              increase: webshopProduct.webshopProduct.effectiveUplift.increase,
              type: webshopProduct.webshopProduct.effectiveUplift.type,
            },
            createdAt: this._formatDate(
              webshopProduct.webshopProduct.createdAt
            ),
            updatedAt: this._formatDate(
              webshopProduct.webshopProduct.updatedAt
            ),
          },
          extras: {
            formattedUpliftType: webshopProduct.webshopProduct?.specificUplift
              ?.type
              ? this._buildFormattedUpliftType(
                  webshopProduct.webshopProduct.specificUplift.type
                )
              : null,
          },
        };
      }
    );

    return {
      data: webshopProductPromotions,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  private _buildFormattedUpliftType(upliftType: string) {
    const upliftTypes = {
      [PromotionUpliftTypes.RELATIVE]:
        STRINGS.metadata.promotions.uplifts.relative,
      [PromotionUpliftTypes.ABSOLUTE]:
        STRINGS.metadata.promotions.uplifts.absolute,
      [PromotionUpliftTypes.CLOSE_OUT]:
        STRINGS.metadata.promotions.uplifts.closeOut,
      [PromotionUpliftTypes.NO_UPLIFT]: '-',
    };

    return upliftTypes[upliftType];
  }

  /**
   * Formats date from seconds to YYYY-MM-DD format
   * @param date seconds
   * @returns date in YYYY-MM-DD
   */
  private _formatDate(date: string): string {
    const dateFormat = 'yyyy-MM-dd';

    return DateTime.fromSeconds(Number(date)).toFormat(dateFormat);
  }
}
