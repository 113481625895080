import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
  SkipSelf,
  computed,
} from '@angular/core';
import { ACCORDION, EXPANSION_PANEL } from './model/expansion-panel.model';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { AccordionComponent } from './core/accordion/accordion.component';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { panelExpansion } from './animations/expansion-panel.animations';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-expansion-panel',
  exportAs: 'expansionPanel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [panelExpansion],
  providers: [
    { provide: ACCORDION, useValue: undefined },
    { provide: EXPANSION_PANEL, useExisting: ExpansionPanelComponent },
  ],
})
export class ExpansionPanelComponent extends CdkAccordionItem {
  override accordion: AccordionComponent;

  panelExpanded = toSignal(this.expandedChange);

  panelExpansionState = computed(() =>
    this.panelExpanded() ? 'expanded' : 'collapsed'
  );

  constructor(
    @Optional()
    @SkipSelf()
    @Inject(ACCORDION)
    accordion: AccordionComponent,
    public cdr: ChangeDetectorRef,
    public selectionDispatcher: UniqueSelectionDispatcher
  ) {
    super(accordion, cdr, selectionDispatcher);

    this.accordion = accordion;
  }
}
