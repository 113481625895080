import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
} from '@angular/core';

@Component({
  selector: 'app-product-information-v2',
  templateUrl: './product-information-v2.component.html',
  styleUrl: './product-information-v2.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductInformationV2Component {
  @Input({ transform: booleanAttribute })
  ignoredTemplate: boolean;
}
