import { DataAdapter } from 'src/app/shared/adapter/adapter';

import {
  ComposedWebshopProduct,
  ComposedWebshopProducts,
} from 'src/app/shared/models/products/v3/products.model';
import {
  ComposedWebshopProduct as ComposedWebshopProductV1,
  ComposedWebshopProducts as ComposedWebshopProductsV1,
} from '../model/products-v3.model';

export class ProductsV3CompositionsAdapter
  implements DataAdapter<ComposedWebshopProductsV1, ComposedWebshopProducts>
{
  transform(data: ComposedWebshopProductsV1): ComposedWebshopProducts {
    const products: ComposedWebshopProduct[] = data.products.map(
      (product: ComposedWebshopProductV1) => {
        return {
          composedProductUuid: product.composedProductUuid,
          partProductUuid: product.partProductUuid,
          composedProductId: product.composedProductId,
          partProductId: product.partProductId,
          name: product.name,
          sku: product.sku,
          eanCode: product.eanCode,
          articleCode: product.articleCode,
          partQuantity: product.partQuantity,
        };
      }
    );

    return {
      data: products,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }
}
