import { NgModule } from '@angular/core';
import { InputComponent } from './input.component';
import {
  InputPrefixDirective,
  InputSuffixDirective,
} from './directives/input.directive';
import { NgClass } from '@angular/common';
import { InputWrapperComponent } from './components/input-wrapper/input-wrapper.component';
import { MaxlengthNumberDirective } from './directives/max-length.directive';
import { NumericInputDirective } from './directives/numericInput.directive';

@NgModule({
  declarations: [
    InputComponent,
    InputWrapperComponent,
    InputPrefixDirective,
    InputSuffixDirective,
    MaxlengthNumberDirective,
    NumericInputDirective,
  ],
  imports: [NgClass],
  exports: [
    InputComponent,
    InputWrapperComponent,
    InputPrefixDirective,
    InputSuffixDirective,
    MaxlengthNumberDirective,
    NumericInputDirective,
  ],
})
export class InputModule {}
