import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';

export class Initialize {
  static readonly type = '[Add Product to Promotion Dialog] Initialize';

  constructor(public promotionUuid: string) {}
}

export class Reset {
  static readonly type = '[Add Product to Promotion Dialog] Reset';
}

export class LoadDisconnectedProducts {
  static readonly type =
    '[Add Product to Promotion Dialog] Load Disconnected Products';

  constructor(public promotionUuid: string) {}
}

export class Paginate {
  static readonly type = '[Add Product to Promotion Dialog] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Add Product to Promotion Dialog] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Add Product to Promotion Dialog] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class AddSearchParam {
  static readonly type = '[Add Product to Promotion Dialog] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type =
    '[Add Product to Promotion Dialog] Remove Search Param';

  constructor(public param: string | null) {}
}

export class Sort {
  static readonly type = '[Add Product to Promotion Dialog] Sort';

  constructor(public sort: Sorted) {}
}

export class HideColumn {
  static readonly type = '[Add Product to Promotion Dialog] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleRowSelection {
  static readonly type =
    '[Add Product to Promotion Dialog] Toggle Row Selection';

  constructor(
    public rowKey: string,
    public isSelected: boolean
  ) {}
}

export class ClearSelection {
  static readonly type = '[Add Product to Promotion Dialog] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type =
    '[Add Product to Promotion Dialog] Master Toggle Selection';
}

export class AddProductsToAPromotion {
  static readonly type =
    '[Add Product to Promotion Dialog] Add Products To A Promotion';
}

export class ResetPaginationAndLoadData {
  static readonly type =
    '[Add Product to Promotion Dialog] Reset Pagination And Load Data';
}
