import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  Observable,
  catchError,
  concatMap,
  delay,
  map,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import { SaveFailed, Saving } from 'src/app/core/actions/app.action';
import { SaveConcept as SaveConceptManual } from 'src/app/features/purchase-v3/components/manual-order/actions/manual-order-products.actions';
import { SaveConcept as SaveConceptProposed } from 'src/app/features/purchase-v3/components/proposed-orderlines/actions/proposed-orderlines.actions';
import {
  CheckoutOrder,
  ResetCheckout,
  SkipCheckout,
} from '../actions/purchase-checkout-dialog.component.actions';
import { ProposedOrderlinesStateQueries } from 'src/app/features/purchase-v3/components/proposed-orderlines/state/proposed-orderlines.queries';
import { ManualOrderV2StateQueries } from 'src/app/features/purchase-v3/components/manual-order/state/manual-order-products.queries';
import { PersistSession } from 'src/app/features/purchase-v3/components/concept-lines/actions/concept-orderlines.actions';
import { PurchaseOrderType } from 'src/app/features/purchase-v3/model/purchase-v3.model';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { AccountState } from 'src/app/core/states/account.state';
import { SetOrderRedirect } from '../../purchase-confirmation-dialog-v2/actions/purchase-confirmation-dialog-v2.actions';
import { NavigateToConceptLines } from 'src/app/features/purchase-v3/components/draft-orders/actions/draft-orders.actions';

export interface PurchaseCheckoutStateModel {
  isSaving: boolean;
  buyOrderConceptUuid: string;
  skipCheckout: boolean;
}

@State<PurchaseCheckoutStateModel>({
  name: 'PurchaseCheckoutState',
  defaults: {
    isSaving: false,
    buyOrderConceptUuid: null,
    skipCheckout: false,
  },
})
@Injectable()
export class PurchaseCheckoutState {
  constructor(
    private store: Store,
    private accountSettingsService: AccountSettingsService
  ) {}

  @Selector()
  static isSaving(state: PurchaseCheckoutStateModel): boolean {
    return state.isSaving;
  }

  @Selector()
  static skipCheckout(state: PurchaseCheckoutStateModel): boolean {
    return state.skipCheckout;
  }

  @Action(CheckoutOrder)
  checkoutOrder(
    ctx: StateContext<PurchaseCheckoutStateModel>,
    payload: CheckoutOrder
  ) {
    return ctx.dispatch(new Saving(false)).pipe(
      tap(() => {
        ctx.patchState({
          isSaving: true,
        });
      }),
      delay(200),
      concatMap(() => this._saveConceptFromOrder(ctx, payload.order.orderType)),
      mergeMap(() =>
        ctx.dispatch(new PersistSession(payload.order.sessionUuid))
      ),
      mergeMap(() => {
        if (ctx.getState().skipCheckout) {
          return this._updateSkipCheckout(ctx);
        }

        return of(null);
      }),
      delay(2000),
      tap(() => {
        payload.dialogRef?.close();

        ctx.patchState({
          isSaving: false,
        });
      }),
      map(() => this._getConceptUuidFromOrder(payload.order.orderType)),
      mergeMap((buyOrderConceptUuid: string) =>
        this._finishCheckout(ctx, payload, buyOrderConceptUuid)
      ),
      catchError(e => {
        ctx.patchState({
          isSaving: false,
        });

        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }

  @Action(ResetCheckout)
  reset(ctx: StateContext<PurchaseCheckoutStateModel>) {
    ctx.patchState({
      isSaving: false,
      buyOrderConceptUuid: null,
    });
  }

  @Action(SkipCheckout, { cancelUncompleted: true })
  skipCheckout(
    ctx: StateContext<PurchaseCheckoutStateModel>,
    payload: SkipCheckout
  ) {
    ctx.patchState({
      skipCheckout: payload.skipCheckout,
    });
  }

  private _updateSkipCheckout(
    ctx: StateContext<PurchaseCheckoutStateModel>
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, {
        purchaseCheckoutDoNotAskAgain: true,
      })
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _saveConceptFromOrder(
    ctx: StateContext<PurchaseCheckoutStateModel>,
    orderType: PurchaseOrderType
  ): Observable<any> {
    switch (orderType) {
      case PurchaseOrderType.PROPOSED:
        return ctx.dispatch(new SaveConceptProposed({ showMessage: false }));
      case PurchaseOrderType.MANUAL:
        return ctx.dispatch(new SaveConceptManual({ showMessage: false }));
    }
  }

  private _getConceptUuidFromOrder(orderType: PurchaseOrderType): string {
    switch (orderType) {
      case PurchaseOrderType.PROPOSED:
        return this.store.selectSnapshot(
          ProposedOrderlinesStateQueries.conceptUuid
        );
      case PurchaseOrderType.MANUAL:
        return this.store.selectSnapshot(ManualOrderV2StateQueries.conceptUuid);
    }
  }

  private _finishCheckout(
    ctx: StateContext<PurchaseCheckoutStateModel>,
    payload: CheckoutOrder,
    buyOrderConceptUuid: string
  ): Observable<any> {
    return ctx.dispatch([
      new SetOrderRedirect(payload.order.redirectTo),
      new NavigateToConceptLines(
        buyOrderConceptUuid,
        payload.order.supplierUuid,
        payload.order.orderMomentUuid
      ),
    ]);
  }
}
