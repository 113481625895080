import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

export type TooltipPosition =
  | 'before'
  | 'left'
  | 'top'
  | 'above'
  | 'right'
  | 'after'
  | 'below'
  | 'bottom';

export enum TooltipAnimationStates {
  VOID = 'void',
  HIDDEN = 'hidden',
  VISIBLE = 'visible',
}

export const SCROLL_THROTTLE_MS = 20;

export interface TooltipDefaultOptions {
  /** Default delay when the tooltip is shown. */
  showDelay: number;

  /** Default delay when the tooltip is hidden. */
  hideDelay: number;

  /** Default delay when hiding the tooltip on a touch device. */
  touchendHideDelay: number;

  /** Default max-width. */
  maxWidth: number;

  /** Default position for tooltips. */
  position?: TooltipPosition;

  /** Disables the ability for the user to interact with the tooltip element. */
  disableTooltipInteractivity?: boolean;

  /** Check content overflow, it will disable the tooltip if there isn't any. */
  checkOverflow?: boolean;
}

export interface TooltipOverlayOrigin {
  x: number;
  y: number;
}

export const TOOLTIP_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>(
  'tooltip-scroll-strategy'
);

export function TOOLTIP_SCROLL_STRATEGY_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () =>
    overlay.scrollStrategies.reposition({ scrollThrottle: SCROLL_THROTTLE_MS });
}

export const TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: TOOLTIP_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: TOOLTIP_SCROLL_STRATEGY_FACTORY,
};

export const tooltipDefaults = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  maxWidth: 200,
};

export function TOOLTIP_DEFAULT_OPTIONS_FACTORY(): TooltipDefaultOptions {
  return tooltipDefaults;
}

export const TOOLTIP_DEFAULT_OPTIONS =
  new InjectionToken<TooltipDefaultOptions>('tooltip-default-options', {
    providedIn: 'root',
    factory: TOOLTIP_DEFAULT_OPTIONS_FACTORY,
  });
