import { SelectedDisconnectedProduct } from 'src/app/shared/models/suppliers/disconnected-products.model';
import { ConnectProductToSupplierStateModel } from '../state/connect-product-to-supplier.state';

export interface ConnectProductToSupplierDialogData {
  dialogTitle: string;
  supplierUuid: string;
  supplierId: string;
}

export const defaultSelectedDisconnectedProduct: SelectedDisconnectedProduct = {
  id: null,
  supplierProductName: null,
  supplierProductSku: null,
  supplierProductEAN: null,
  purchasePrice: 1,
  minimumOrderQuantity: 1,
  lotSize: 1,
  availability: true,
  preferred: false,
};

export const defaultState: ConnectProductToSupplierStateModel = {
  disconnectedProducts: [],
  page: {
    totalElements: 0,
  },
  selectedProduct: defaultSelectedDisconnectedProduct,
  loading: false,
};
