import { createSelector } from '@ngxs/store';
import { AccountPermissionsState } from './account-permissions.state';
import { WebshopState } from './webshop.state';

export class PermissionQueries {
  static hasPermission(featureKey: string) {
    return createSelector(
      [
        WebshopState.hasWebshopPermission(featureKey),
        AccountPermissionsState.hasPermission(featureKey),
      ],
      (hasWebshopPermission: boolean, hasAccountPermission: boolean) => {
        return hasWebshopPermission && hasAccountPermission;
      }
    );
  }

  static hasPermissionViewOnly(featureKey: string) {
    return createSelector(
      [
        WebshopState.hasWebshopPermission(featureKey),
        AccountPermissionsState.hasPermissionViewOnly(featureKey),
      ],
      (
        hasWebshopPermission: boolean,
        hasAccountPermissionViewOnly: boolean
      ) => {
        return hasWebshopPermission && hasAccountPermissionViewOnly;
      }
    );
  }
}
