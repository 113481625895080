import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { GravatarService } from 'src/app/core/services/gravatar.service';

@Directive({ selector: '[appGravatar]' })
export class GravatarDirective implements AfterViewInit {
  @Input() appSource: string;

  @Input() appSize? = 128;

  constructor(
    private el: ElementRef,
    private service: GravatarService
  ) {}

  ngAfterViewInit(): void {
    this.generate();
  }

  async generate() {
    this.service.generate(this.appSource, this.appSize).subscribe(source => {
      this.el.nativeElement.src = source;
    });
  }
}
