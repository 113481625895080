import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  DeliveryDetails,
  DeliveryDetailsBuyOrder,
  DeliveryDetailsBuyOrderMetadata,
  DeliveryDetailsOrderMoment,
  DeliveryDetailsSupplier,
} from 'src/app/shared/models/delivery/v2/deliveries-editor-v2.model';
import {
  DeliveryDetails as DeliveryDetailsV1,
  DeliveryDetailsBuyOrder as DeliveryDetailsBuyOrderV1,
  DeliveryDetailsOrderMoment as DeliveryDetailsOrderMomentV1,
  DeliveryDetailsSupplier as DeliveryDetailsSupplierV1,
  DeliveryDetailsBuyOrderMetadata as DeliveryDetailsBuyOrderMetadataV1,
} from '../model/deliveries-editor-v2.model';
import { Store } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { DateTime } from 'luxon';
import { formatDateToWebshopTimezone } from 'src/app/shared/utils/dates.utils';
import { formatDate } from '@angular/common';

export class DeliveryOrderDetailsV2Adapter
  implements DataAdapter<DeliveryDetailsV1, DeliveryDetails>
{
  constructor(private store: Store) {}

  transform(data: DeliveryDetailsV1): DeliveryDetails {
    return {
      order: {
        uuid: data.order.uuid,
        buyOrder: this._buildBuyOrder(data.order.buyOrder),
        supplier: this._buildSupplier(data.order.supplier),
        orderMoment: this._buildOrderMoment(data.order.orderMoment),
        totalOrdered: data.order.totalOrdered,
        totalDelivered: data.order.totalDelivered,
        expectedDeliveryDate: data.order.expectedDeliveryDate,
        progress: (100 * data.order.totalDelivered) / data.order.totalOrdered,
        notes: data.order.notes,
      },
    };
  }

  private _buildBuyOrder(
    buyOrder: DeliveryDetailsBuyOrderV1
  ): DeliveryDetailsBuyOrder {
    if (!buyOrder) return null;

    return {
      uuid: buyOrder.uuid,
      id: buyOrder.id,
      date: this._buildTimeZoneDate(buyOrder.date),
      totalValue: buyOrder.totalValue,
      closedDate: this._buildTimeZoneDate(buyOrder.closedDate),
      metadata: this._buildBuyOrderMetadata(buyOrder.metadata),
    };
  }

  private _buildBuyOrderMetadata(
    metadata: DeliveryDetailsBuyOrderMetadataV1
  ): DeliveryDetailsBuyOrderMetadata {
    if (!metadata) return null;

    const timezone = this.store.selectSnapshot(WebshopState.timeZoneOffset);

    return {
      userSendEmailOptionSelected: metadata.userSendEmailOptionSelected,
      lastSendEmailRequestTriggeredAt: new Date(
        formatDate(
          metadata.lastSendEmailRequestTriggeredAt,
          'yyyy-MM-dd HH:mm:ss',
          'en-US',
          timezone
        )
      ),
    };
  }

  private _buildSupplier(
    supplier: DeliveryDetailsSupplierV1
  ): DeliveryDetailsSupplier {
    if (!supplier) return null;

    return {
      uuid: supplier.uuid,
      name: supplier.name,
      minimalOrderValue: supplier.minimalOrderValue,
      effectiveReplenishmentPeriod: supplier.effectiveReplenishmentPeriod,
      leadTimeMeanActual: supplier.leadTimeMeanActual,
      deliveryTime: supplier.deliveryTime,
    };
  }

  private _buildOrderMoment(
    orderMoment: DeliveryDetailsOrderMomentV1
  ): DeliveryDetailsOrderMoment {
    if (!orderMoment) return null;

    return {
      uuid: orderMoment.uuid,
      id: orderMoment.id,
    };
  }
  private _buildTimeZoneDate(date: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const todayDateWebshopTimeZone = formatDateToWebshopTimezone(
      timeZoneIdentifier,
      date
    );

    return DateTime.fromJSDate(new Date(todayDateWebshopTimeZone)).toFormat(
      'yyyy-MM-dd'
    );
  }
}
