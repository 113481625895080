import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  DynamicTags,
  TagVariantColor,
  TagsBuilder,
} from 'src/app/shared/components/design-system/tags/model/tag.model';
import {
  PredecessorProduct,
  SuccessorProduct,
  WebshopProduct,
} from 'src/app/shared/models/products/v3/products.model';
import {
  PredecessorProduct as PredecessorProductV1,
  SuccessorProduct as SuccessorProductV1,
  SingleWebshopProduct,
  WebshopProduct as WebshopProductV1,
} from '../model/products-v3.model';
import { STRINGS } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.strings';

export class ProductV3Adapter
  implements
    DataAdapter<SingleWebshopProduct, WebshopProduct>,
    TagsBuilder<WebshopProductV1>
{
  private readonly MESSAGES = MESSAGES;

  readonly STRINGS = STRINGS.tooltips;

  transform(data: SingleWebshopProduct): WebshopProduct {
    return {
      uuid: data.product.uuid,
      id: data.product.id,
      category: data.product.category,
      name: data.product.name,
      sku: data.product.sku,
      eanCode: data.product.eanCode,
      price: data.product.price,
      serviceLevel: data.product.serviceLevel,
      supplyTime: data.product.supplyTime,
      demandPerMonth: data.product.demandPerMonth,
      stockOnHand: data.product.stockOnHand,
      minimumStock: data.product.minimumStock,
      assembled: data.product.assembled,
      ignored: data.product.ignored,
      notBeingBought: data.product.notBeingBought,
      resumingPurchase: data.product.resumingPurchase,
      novel: data.product.novel,
      issues: data.product.issues,
      predecessor: this._buildPredecessor(data.product.predecessor),
      successor: this._buildSuccessor(data.product.successor),
      deadStock: data.product.deadStock,
      maximumStock: data.product.maximumStock,
      articleCode: data.product.articleCode,
      manualServiceLevel: data.product.manualServiceLevel,
      notes: data.product.notes,
      createdAtRemote: data.product.createdAtRemote,
      xyzCategory: data.product.xyzCategory,
      extras: {
        tags: this.buildTags(data.product),
      },
    };
  }

  buildTags(base: WebshopProductV1): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (base.issues.includes('CONNECT_TO_SUPPLIER')) {
      extras.iconTextTags.push({
        icon: 'warning-rounded-xs',
        color: TagVariantColor.RED,
        tooltip: this.STRINGS.CONNECT_TO_SUPPLIER,
        text: '',
      });
    }

    if (
      base.issues.includes('UPDATE_SELLING_PRICE') ||
      base.issues.includes('UPDATE_PURCHASE_PRICE')
    ) {
      extras.iconTextTags.push({
        icon: 'warning-triangle',
        color: TagVariantColor.ORANGE,
        tooltip:
          base.issues.includes('UPDATE_SELLING_PRICE') &&
          base.issues.includes('UPDATE_PURCHASE_PRICE')
            ? this.STRINGS.UPDATE_SELLING_PRICE +
              '\n' +
              this.STRINGS.UPDATE_PURCHASE_PRICE
            : base.issues.includes('UPDATE_SELLING_PRICE')
              ? this.STRINGS.UPDATE_SELLING_PRICE
              : this.STRINGS.UPDATE_PURCHASE_PRICE,
        text:
          base.issues.includes('UPDATE_SELLING_PRICE') &&
          base.issues.includes('UPDATE_PURCHASE_PRICE')
            ? '2'
            : '',
      });
    }

    if (base.ignored) {
      extras.iconTags.push({
        icon: 'eye-closed',
        color: TagVariantColor.DEFAULT,
        tooltip: this.MESSAGES.common.ignored,
      });
    }

    if (base.assembled) {
      extras.iconTags.push({
        icon: 'puzzle',
        color: TagVariantColor.DEFAULT,
        tooltip: this.MESSAGES.common.assembled,
      });
    }

    if (base.notBeingBought) {
      extras.iconTags.push({
        icon: 'arrow-decrease-down',
        color: TagVariantColor.DEFAULT,
        tooltip: this.MESSAGES.common.phasedOut,
      });
    }

    return extras;
  }

  private _buildPredecessor(
    predecessor: PredecessorProductV1
  ): PredecessorProduct {
    if (!predecessor) return null;

    return {
      uuid: predecessor.uuid,
      name: predecessor.name,
      notBeingBought: predecessor.notBeingBought,
    };
  }

  private _buildSuccessor(successor: SuccessorProductV1): SuccessorProduct {
    if (!successor) return null;

    return {
      uuid: successor.uuid,
      name: successor.name,
      notBeingBought: successor.notBeingBought,
    };
  }
}
