import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { UpdateImportList } from './actions/imports-dialog.action';
import { ImportsDialogState } from './state/imports-dialog.state';
import { STRINGS } from 'src/app/features/imports/model/imports.strings';
import {
  dynamicColumnsWithStatusArticleCode,
  dynamicColumnsWithStatusEanCode,
  dynamicColumnsWithStatusId,
  dynamicColumnsWithStatusSkuCode,
  useCSVDarkTheme,
  useCSVLightTheme,
  useCSVcustomText,
} from 'src/app/features/imports/model/imports.model';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { LocaleState } from 'src/app/core/states/locale.state';
import { UsecsvDialogComponent } from '../usecsv-dialog/usecsv-dialog.component';
import { CloseImportsDialog } from '../import-suppliers-dialog/components/import-suppliers-update-dialog/actions/import-suppliers-dialog.action';

@Component({
  selector: 'app-imports-dialog',
  templateUrl: './imports-dialog.component.html',
  styleUrls: ['./imports-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportsDialogComponent {
  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata;

  @Select(AccountSettingsState.colorThemeLowercase)
  colorTheme$: Observable<string>;

  @Select(LocaleState.localeId)
  language$: Observable<string>;

  parentForm = new FormGroup({
    type: new FormControl('ID'),
  });

  disableSubmitButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  open: boolean = false;

  radioButtons = {
    id: true,
    ean: false,
    sku: false,
    article: false,
  };

  dynamicColumnsWithStatus = dynamicColumnsWithStatusId;

  useCSVDarkTheme = useCSVDarkTheme;

  useCSVLightTheme = useCSVLightTheme;

  customText = useCSVcustomText;

  @Select(ImportsDialogState.importKey)
  importKey$: Observable<boolean>;

  importData = (data: any) => {
    this.store.dispatch(
      new UpdateImportList(data, this.parentForm.get('type').value)
    );
  };

  onClose = () => {
    this.store.dispatch(new CloseImportsDialog());
  };

  constructor(
    @Inject(DIALOG_DATA) public data,
    private store: Store,
    private dialogRef: DialogRef,
    private dialog: Dialog
  ) {}

  close() {
    this.dialogRef.close();
  }

  checkRadioType(value: string) {
    this.parentForm.get('type').setValue(value);
    this.updateRadioType();
  }

  updateRadioType() {
    if (this.parentForm.get('type').value === 'ID') {
      this.radioButtons = {
        id: true,
        ean: false,
        sku: false,
        article: false,
      };
      this.dynamicColumnsWithStatus = dynamicColumnsWithStatusId;
    } else if (this.parentForm.get('type').value === 'EAN_CODE') {
      this.radioButtons = {
        id: false,
        ean: true,
        sku: false,
        article: false,
      };
      this.dynamicColumnsWithStatus = dynamicColumnsWithStatusEanCode;
    } else if (this.parentForm.get('type').value === 'SKU_CODE') {
      this.radioButtons = {
        id: false,
        ean: false,
        sku: true,
        article: false,
      };
      this.dynamicColumnsWithStatus = dynamicColumnsWithStatusSkuCode;
    } else if (this.parentForm.get('type').value === 'ARTICLE_CODE') {
      this.radioButtons = {
        id: false,
        ean: false,
        sku: false,
        article: true,
      };
      this.dynamicColumnsWithStatus = dynamicColumnsWithStatusArticleCode;
    }
  }

  openUsecsvDialog(): void {
    this.dialog.open(UsecsvDialogComponent);
  }
}
