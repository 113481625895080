import { Selector } from '@ngxs/store';
import {
  NOT_AVAILABLE_VALUE,
  RESULTS_SELECTION_LIMIT_AMOUNT,
} from 'src/app/core/constants/global.constants';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  Promotion,
  PromotionStatus,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import { SelectedPromotion } from '../model/promotions-v2.model';
import { STRINGS } from '../model/promotions-v2.strings';
import {
  PromotionsV2State,
  PromotionsV2StateModel,
} from './promotions-v2.state';
import {
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  buildDisplayedColumns,
  buildSelectableColumnsList,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { COLUMNS_ORDER } from '../model/promotions-v2-data-table.model';

export class PromotionsV2StateQueries {
  @Selector([PromotionsV2State.promotions])
  static promotions(promotions: Promotion[]): Promotion[] {
    return promotions;
  }

  @Selector([PromotionsV2State])
  static totalElements(state: PromotionsV2StateModel): number {
    return state.page.totalElements;
  }

  @Selector([
    PromotionsV2State,
    PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.PROMOTIONS),
  ])
  static featuredColumns(
    state: PromotionsV2StateModel,
    viewOnly: boolean
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    const viewOnlyExcludedColumns = [STRINGS.columns.promotions.ACTIONS.key];

    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        return {
          ...group,
          columns: Object.values(group.columns).filter(
            (column: DatatableColumnV2) =>
              viewOnly ? !viewOnlyExcludedColumns.includes(column.name) : column
          ),
        };
      }
    );
  }

  @Selector([PromotionsV2StateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([PromotionsV2StateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([PromotionsV2StateQueries.displayedColumns])
  static lastDisplayedColumn(displayedColumns: string[]) {
    return (
      displayedColumns.filter(
        (column: string) =>
          ![
            STRINGS.columns.promotions.SELECTION.key,
            STRINGS.columns.promotions.ACTIONS.key,
          ].includes(column)
      ).length === 1
    );
  }

  @Selector([PromotionsV2State])
  static page(state: PromotionsV2StateModel): Pageable {
    return state.page;
  }

  @Selector([PromotionsV2State])
  static pageSizeOptions(state: PromotionsV2StateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([PromotionsV2State])
  static currentPage(state: PromotionsV2StateModel): number {
    return state.pagination.page;
  }

  @Selector([PromotionsV2State])
  static pageSize(state: PromotionsV2StateModel): number {
    return state.pagination.size;
  }

  @Selector([PromotionsV2State, WebshopState.webshopCurrencySymbol])
  static filters(
    state: PromotionsV2StateModel,
    currencySymbol: string
  ): ColumnFilterGroup[] {
    return Object.entries(state.filtersGroups).map(([groupKey, group]) => {
      return {
        groupKey,
        groupName: group.groupName,
        filters: Object.entries(group.columns)
          .map(([key, filter]) => {
            if (STRINGS.columns.promotions[filter.key]?.currency) {
              return {
                columnKey: key,
                ...filter,
                unit: currencySymbol,
              };
            }

            return {
              columnKey: key,
              ...filter,
            };
          })
          .filter((filter: Filter) => !filter.disabled),
      };
    });
  }

  @Selector([PromotionsV2State, WebshopState.webshopCurrencySymbol])
  static activeFilters(
    state: PromotionsV2StateModel,
    currencySymbol: string
  ): ColumnFilter[] {
    const filters = Object.values(state.filtersGroups).map(
      group => group.columns
    );

    return filters
      .map(filter => {
        return (
          Object.entries(filter)
            .map(([columnKey, filter]) => {
              if (STRINGS.columns.promotions[filter.key]?.currency) {
                return {
                  columnKey,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey,
                ...filter,
              };
            })
            .filter((filter: Filter) => filter.params.value) || []
        );
      })
      .flat();
  }

  @Selector([PromotionsV2State])
  static search(state: PromotionsV2StateModel): string[] {
    return state.search || [];
  }

  @Selector([PromotionsV2State])
  static filterOpened(state: PromotionsV2StateModel): boolean {
    return state.filterOpened;
  }

  @Selector([PromotionsV2State.selection])
  static isRowSelected(selection: TableSelection<SelectedPromotion>) {
    const selectedUuids = new Set(Object.keys(selection));

    return (uuid: string) => selectedUuids.has(uuid);
  }

  @Selector([
    PromotionsV2State.selection,
    PromotionsV2StateQueries.totalElements,
  ])
  static areAllSelected(
    selection: TableSelection<SelectedPromotion>,
    totalElements: number
  ): boolean {
    return !!totalElements && Object.keys(selection).length === totalElements;
  }

  @Selector([
    PromotionsV2State.selection,
    PromotionsV2StateQueries.totalElements,
  ])
  static areSomeSelected(
    selection: TableSelection<SelectedPromotion>,
    totalElements: number
  ): boolean {
    return (
      !!totalElements &&
      !!Object.keys(selection).length &&
      Object.keys(selection).length < totalElements
    );
  }

  @Selector([PromotionsV2State.selection])
  static selectedAmount(selection: TableSelection<SelectedPromotion>): number {
    return Object.keys(selection).length;
  }

  @Selector([PromotionsV2StateQueries.selectedAmount])
  static limitSelection(selectedAmount: number): boolean {
    return selectedAmount >= RESULTS_SELECTION_LIMIT_AMOUNT;
  }

  @Selector([PromotionsV2State.selection])
  static enabledPromotionsAmount(
    selection: TableSelection<SelectedPromotion>
  ): number {
    return Object.values(selection).filter(
      (promotion: SelectedPromotion) => promotion.enabled
    ).length;
  }

  @Selector([PromotionsV2State.selection])
  static disabledPromotionsAmount(
    selection: TableSelection<SelectedPromotion>
  ): number {
    return Object.values(selection).filter(
      (promotion: SelectedPromotion) => !promotion.enabled
    ).length;
  }

  @Selector([PromotionsV2State.selection])
  static haveSelectedEnabledPromotions(
    selection: TableSelection<SelectedPromotion>
  ): boolean {
    return (
      Object.values(selection).filter(
        (promotion: SelectedPromotion) => promotion.enabled
      ).length > 0
    );
  }

  @Selector([PromotionsV2State.selection])
  static haveSelectedDisabledPromotions(
    selection: TableSelection<SelectedPromotion>
  ): boolean {
    return (
      Object.values(selection).filter(
        (promotion: SelectedPromotion) => !promotion.enabled
      ).length > 0
    );
  }

  @Selector([PromotionsV2State])
  static currentPromotion(state: PromotionsV2StateModel): Promotion {
    if (!state.currentPromotion) return null;

    return {
      uuid: state.currentPromotion.uuid,
      webshopUUID: state.currentPromotion.webshopUUID,
      name: state.currentPromotion.name,
      interval: {
        start: state.currentPromotion.interval.start,
        end: state.currentPromotion.interval.end,
      },
      uplift: {
        increase: state.currentPromotion.uplift.increase,
        type: state.currentPromotion.uplift.type,
      },
      numberOfProducts: state.currentPromotion.numberOfProducts,
      settings: {
        enabled: state.currentPromotion.settings.enabled,
      },
      createdAt: state.currentPromotion.createdAt,
      updatedAt: state.currentPromotion.updatedAt,
      deletedAt: state.currentPromotion.deletedAt,
      status: {
        label:
          STRINGS.metadata.promotionEditor.overview.promotionInfo.statusOptions[
            state.currentPromotion.status.label
          ],
        color: state.currentPromotion.status.color,
      },
      extras: {
        formattedUpliftType:
          state.currentPromotion.extras?.formattedUpliftType || null,
      },
      id: state.currentPromotion.id,
      notes: state.currentPromotion.notes,
    };
  }

  @Selector([PromotionsV2State])
  static currentPromotionName(state: PromotionsV2StateModel): string {
    if (!state.currentPromotion) return null;

    return state.currentPromotion.name || NOT_AVAILABLE_VALUE;
  }

  @Selector([PromotionsV2State.selection])
  static selectedPromotions(
    selection: TableSelection<SelectedPromotion>
  ): string[] {
    const selectionValues = Object.values(selection);

    if (selectionValues.length <= 0) {
      return [];
    }

    return selectionValues.map(
      (selectedProduct: SelectedPromotion) => selectedProduct.uuid
    );
  }

  @Selector([PromotionsV2State])
  static promotionsFiltered(state: PromotionsV2StateModel): Promotion[] {
    return state.promotionsUnpaged;
  }

  @Selector([PromotionsV2State])
  static promotionName(state: PromotionsV2StateModel): string {
    if (!state.currentPromotionEdited) return null;

    return state.currentPromotionEdited.promotionName;
  }

  @Selector([PromotionsV2State])
  static startDate(state: PromotionsV2StateModel): Date {
    if (!state.currentPromotionEdited) return null;

    return state.currentPromotionEdited.startDate;
  }
  @Selector([PromotionsV2State])
  static endDate(state: PromotionsV2StateModel): Date {
    if (!state.currentPromotionEdited) return null;

    return state.currentPromotionEdited.endDate;
  }
  @Selector([PromotionsV2State])
  static upliftType(state: PromotionsV2StateModel): string {
    if (!state.currentPromotionEdited) return null;

    return state.currentPromotionEdited.upliftType;
  }
  @Selector([PromotionsV2State])
  static increase(state: PromotionsV2StateModel): number {
    if (!state.currentPromotionEdited) return null;

    return state.currentPromotionEdited.increase;
  }
  @Selector([PromotionsV2State])
  static enabled(state: PromotionsV2StateModel): boolean {
    if (!state.currentPromotionEdited) return null;

    return state.currentPromotionEdited.enabled;
  }

  @Selector([PromotionsV2State])
  static status(state: PromotionsV2StateModel): PromotionStatus {
    if (!state.currentPromotion) return null;

    return {
      label:
        STRINGS.metadata.promotionEditor.overview.promotionInfo.statusOptions[
          state.currentPromotion.status.label
        ],
      color: state.currentPromotion.status.color,
    };
  }
}
