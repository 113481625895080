import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
} from '@angular/core';

import { Router } from '@angular/router';
import {
  Actions,
  ofActionDispatched,
  ofActionErrored,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';
import {
  debounceTime,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  ReDispatchActionsChanges,
  UnsetAllPendingChanges,
  UnsetPendingChanges,
} from 'src/app/core/actions/pending-changes.action';
import { PendingChangesState } from 'src/app/core/states/pending-changes.state';
import { PendingChangesKeys } from '../../models/navigation/pending-changes.model';
import { ConfirmationClose, ConfirmSave } from './actions/confirmation.actions';
import { ConfirmationState } from './state/confirmation.state';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AllowWebshopChange } from 'src/app/core/actions/webshop.action';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  readonly PENDING_CHANGES_KEYS = PendingChangesKeys;

  @Select(ConfirmationState.loading)
  loading$: Observable<boolean>;

  @Select(PendingChangesState.changesAreValid)
  showInvalidChangesWarning$: Observable<boolean>;

  disableSubmitButton$: Observable<boolean>;

  hasMultiPendingChanges$: Observable<boolean>;

  currentPendingChange$: Observable<string>;

  readonly COMMON_STRINGS = MESSAGES.common;

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    public dialogRef: DialogRef<ConfirmationDialogComponent>,
    private router: Router,
    private store: Store,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.hasMultiPendingChanges$ = this.store
      .select(PendingChangesState.hasMultiplePendingChanges)
      .pipe(take(1));

    this.currentPendingChange$ = this.store
      .select(PendingChangesState.currentPendingChanges)
      .pipe(take(1));

    this.disableSubmitButton$ = this.showInvalidChangesWarning$.pipe(
      map((validChanges: boolean) => !validChanges),
      take(1)
    );

    this.actions
      .pipe(
        ofActionSuccessful(UnsetPendingChanges, UnsetAllPendingChanges),
        tap(() => {
          if (!this.data?.desiredPath) {
            this.store.dispatch(new ReDispatchActionsChanges());
            return;
          }

          this.router.navigateByUrl(this.data.desiredPath);
        }),
        debounceTime(333),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });

    this.actions
      .pipe(
        ofActionSuccessful(ConfirmSave),
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.data?.desiredPath) {
          return this.store.dispatch([
            new AllowWebshopChange(),
            new ReDispatchActionsChanges(),
          ]);
        }

        this.store.dispatch(new AllowWebshopChange());
      });

    this.actions
      .pipe(ofActionErrored(ConfirmSave), takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close();
      });

    this.actions
      .pipe(ofActionDispatched(ConfirmationClose), takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  /**
   * Discard all changes
   */
  discard(): void {
    this.store.dispatch([
      new UnsetAllPendingChanges(),
      new AllowWebshopChange(),
    ]);
  }

  /**
   * Dispatch a save action.
   */
  save(): void {
    this.store.dispatch(new ConfirmSave());
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
