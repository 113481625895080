import { Selector } from '@ngxs/store';
import { ChartLoadingOptions } from 'src/app/shared/components/chart/model/chart.model';
import {
  GraphFilter,
  GraphGroupBy,
  SalesOptions,
} from 'src/app/shared/models/sales/sales-v2.model';
import {
  ProductSalesV2State,
  ProductSalesV2StateModel,
} from './sales-v2.state';

export class ProductSalesV2StateQueries {
  @Selector([ProductSalesV2State])
  static loading(state: ProductSalesV2StateModel): boolean {
    return state.loading;
  }

  @Selector([ProductSalesV2State])
  static loadingOptions(state: ProductSalesV2StateModel): ChartLoadingOptions {
    return state.loadingOptions;
  }

  @Selector([ProductSalesV2State])
  static graphOptions(state: ProductSalesV2StateModel): SalesOptions {
    return state.graphOptions;
  }

  @Selector([ProductSalesV2State])
  static graphFilter(state: ProductSalesV2StateModel): GraphFilter {
    return state.graph.filter;
  }

  @Selector([ProductSalesV2State])
  static graphGroupBy(state: ProductSalesV2StateModel): GraphGroupBy {
    return state.graph.groupBy;
  }

  @Selector([ProductSalesV2State])
  static totalSales(state: ProductSalesV2StateModel): number {
    return state.totalSales;
  }
}
