import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-clear-all-filters-button',
  templateUrl: './clear-all-filters-button.component.html',
  styleUrls: ['./clear-all-filters-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearAllFiltersButtonComponent {
  readonly COMMON_STRINGS = MESSAGES.common;

  @Input()
  disabled: boolean;

  @Output()
  cleared: EventEmitter<void> = new EventEmitter<void>();
}
