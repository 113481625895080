import { Selector } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { WebshopProductPromotion } from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import {
  WebshopProductPromotionsState,
  WebshopProductPromotionsStateModel,
} from './product-promotions-v2.state';
import {
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  buildDisplayedColumns,
  buildSelectableColumnsList,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { COLUMNS_ORDER } from '../model/data-table.model';

export class WebshopProductPromotionsStateQueries {
  @Selector([WebshopProductPromotionsState])
  static loading(state: WebshopProductPromotionsStateModel): boolean {
    return state.loading;
  }

  @Selector([WebshopProductPromotionsState])
  static promotions(
    state: WebshopProductPromotionsStateModel
  ): WebshopProductPromotion[] {
    return state.promotions || [];
  }

  @Selector([WebshopProductPromotionsState])
  static havePromotions(state: WebshopProductPromotionsStateModel): boolean {
    return !!state.promotions.length;
  }

  @Selector([WebshopProductPromotionsState])
  static promotionsTotalElements(
    state: WebshopProductPromotionsStateModel
  ): number {
    return state.page.totalElements;
  }

  @Selector([WebshopProductPromotionsState])
  static featuredColumns(
    state: WebshopProductPromotionsStateModel
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    const editing = state.editing;
    const editableColumns = state.editableColumns;

    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        const groupColumns = Object.values(group.columns).map(column => {
          if (editing && editableColumns.includes(column.name)) {
            return {
              ...column,
              checked: true,
              disabled: true,
            };
          }

          return column;
        });

        return {
          ...group,
          columns: groupColumns,
        };
      }
    );
  }

  @Selector([WebshopProductPromotionsStateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([WebshopProductPromotionsStateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([WebshopProductPromotionsStateQueries.displayedColumns])
  static lastDisplayedColumn(displayedColumns: string[]) {
    return (
      displayedColumns.filter(
        (column: string) =>
          column !== STRINGS.columns.webshopProductPromotions.ACTIONS.key
      ).length === 1
    );
  }

  @Selector([WebshopProductPromotionsState])
  static promotionsPage(state: WebshopProductPromotionsStateModel): Pageable {
    return state.page;
  }

  @Selector([WebshopProductPromotionsState])
  static pageSizeOptions(state: WebshopProductPromotionsStateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([WebshopProductPromotionsState])
  static currentPage(state: WebshopProductPromotionsStateModel): number {
    return state.pagination.page;
  }

  @Selector([WebshopProductPromotionsState])
  static pageSize(state: WebshopProductPromotionsStateModel): number {
    return state.pagination.size;
  }

  @Selector([WebshopProductPromotionsState, WebshopState.webshopCurrencySymbol])
  static filters(
    state: WebshopProductPromotionsStateModel,
    currencySymbol: string
  ): ColumnFilterGroup[] {
    return Object.entries(state.filtersGroups).map(([groupKey, group]) => {
      return {
        groupKey,
        groupName: group.groupName,
        filters: Object.entries(group.columns)
          .map(([key, filter]) => {
            if (
              STRINGS.columns.webshopProductPromotions[filter.key]?.currency
            ) {
              return {
                columnKey: key,
                ...filter,
                unit: currencySymbol,
              };
            }

            return {
              columnKey: key,
              ...filter,
            };
          })
          .filter((filter: Filter) => !filter.disabled),
      };
    });
  }

  @Selector([WebshopProductPromotionsState, WebshopState.webshopCurrencySymbol])
  static activeFilters(
    state: WebshopProductPromotionsStateModel,
    currencySymbol: string
  ): ColumnFilter[] {
    const filters = Object.values(state.filtersGroups).map(
      group => group.columns
    );

    return filters
      .map(filter => {
        return (
          Object.entries(filter)
            .map(([columnKey, filter]) => {
              if (
                STRINGS.columns.webshopProductPromotions[filter.key]?.currency
              ) {
                return {
                  columnKey,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey,
                ...filter,
              };
            })
            .filter((filter: Filter) => filter.params.value) || []
        );
      })
      .flat();
  }

  @Selector([WebshopProductPromotionsState])
  static search(state: WebshopProductPromotionsStateModel): string[] {
    return state.search || [];
  }

  @Selector([WebshopProductPromotionsState])
  static filterOpened(state: WebshopProductPromotionsStateModel): boolean {
    return state.filterOpened;
  }

  @Selector([WebshopProductPromotionsStateQueries.displayedColumns])
  static exportMetadata(displayedColumns: string[]): any {
    const visibleColumns = displayedColumns.filter(
      column =>
        column !== STRINGS.columns.promotions.ACTIONS.key &&
        column !== STRINGS.columns.promotions.SELECTION.key
    );

    return {
      visibleColumns,
      sortBy: 'name',
      order: 'asc',
      filter: '',
      source: 'exportPromotions',
      include: null,
    };
  }

  @Selector([WebshopProductPromotionsState])
  static editing(state: WebshopProductPromotionsStateModel): boolean {
    return state.editing;
  }
}
