<div class="container">
  <div class="container-col container-col-bordered">
    <span class="container-primary-value">
      <textarea
        appInput
        variant="outline"
        maxlength="500"
        rows="5"
        placeholder="{{ COMMON_STRINGS.common.enterText }}"
        [ngModel]="notes$ | async"
        (ngModelChange)="updateNotes($event)"
        [disabled]="viewOnly$ | async"></textarea>
    </span>

    <div class="notes-actions-container">
      <span class="notes-info-container"
        ><mat-icon svgIcon="info-rounded-filled-xs" class="icon"></mat-icon>
        {{ COMMON_STRINGS.common.notes }}</span
      >
      <button
        aria-label="Supplier save notes button"
        appButton
        variant="secondary"
        [disabled]="(saveEnabled$ | async) || (viewOnly$ | async)"
        (click)="saveNotes()">
        {{ COMMON_STRINGS.common.save }}
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
