import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { EmailTemplates as EmailTemplatesV1 } from '../model/emails.model';
import {
  EmailTemplate,
  EmailTemplateLanguage,
  EmailTemplates,
} from 'src/app/shared/models/emails/v1/emails.model';

export class EmailTemplatesAdapter
  implements DataAdapter<EmailTemplatesV1, EmailTemplates>
{
  transform(data: EmailTemplatesV1): EmailTemplates {
    const templates: EmailTemplate[] = data.templates.map(template => {
      const emailTemplate: EmailTemplate = {
        subject: template.subject,
        body: template.body,
        language: template.language as EmailTemplateLanguage,
      };

      if (Object.prototype.hasOwnProperty.call(template, 'type')) {
        emailTemplate.type = template.type;
      }

      return emailTemplate;
    });

    return {
      templates,
    };
  }
}
