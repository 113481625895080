import { Selector } from '@ngxs/store';
import {
  LinkedSupplierProductsEditorState,
  LinkedSupplierProductsEditorStateModel,
} from './product-suppliers-v2-editor.state';

export class LinkedSupplierProductsEditorStateQueries {
  @Selector([LinkedSupplierProductsEditorState])
  static saving(state: LinkedSupplierProductsEditorStateModel): boolean {
    return state.saving;
  }

  @Selector([LinkedSupplierProductsEditorState])
  static haveEditedLinkedSupplierProducts(
    state: LinkedSupplierProductsEditorStateModel
  ): boolean {
    return !!state.editedLinks.length;
  }
}
