import { State, Action, StateContext, Store } from '@ngxs/store';
import {
  OrdersOverviewSummaries,
  OrdersOverviewSummary,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { BuyOrderV2Service } from 'src/app/core/api/buy-order/v2/buy-order-v2.service';
import { patch } from '@ngxs/store/operators';
import { ProductsV3Service } from 'src/app/core/api/products/v3/products-v3.service';
import { Issue, TodoListIssues } from '../model/todo-list-v2.model';
import {
  LoadDeliveriesIssues,
  LoadOrdersIssues,
  LoadProductsIssues,
  LoadSuppliersIssues,
  ResetIssues,
} from '../actions/todo-list-v2.actions';
import { SuppliersV2Service } from 'src/app/core/api/supply/v2/suppliers-v2.service';
import {
  DeliveriesOverviewSummaries,
  DeliveriesOverviewSummary,
} from 'src/app/shared/models/delivery/v2/deliveries-v2.model';
import { DeliveriesV2Service } from 'src/app/core/api/deliveries/v2/deliveries-v2.service';

export interface TodoListV2StateModel {
  ordersIssues: TodoListIssues<OrdersOverviewSummary[]>;
  deliveriesIssues: TodoListIssues<DeliveriesOverviewSummary[]>;
  productsIssues: TodoListIssues<Issue[]>;
  suppliersIssues: TodoListIssues<Issue[]>;
}

@State<TodoListV2StateModel>({
  name: 'todoListV2State',
  defaults: {
    ordersIssues: {
      loading: true,
      data: [],
    },
    deliveriesIssues: {
      loading: true,
      data: [],
    },
    productsIssues: {
      loading: true,
      data: [],
    },
    suppliersIssues: {
      loading: true,
      data: [],
    },
  },
})
@Injectable()
export class TodoListV2State {
  private buyOrderV2Service = inject(BuyOrderV2Service);

  private deliveriesV2Service = inject(DeliveriesV2Service);

  private productsV3Service = inject(ProductsV3Service);

  private suppliersV2Service = inject(SuppliersV2Service);

  private store = inject(Store);

  @Action(ResetIssues)
  resetIssues(ctx: StateContext<TodoListV2StateModel>) {
    ctx.setState(
      patch({
        ordersIssues: patch({
          loading: true,
          data: [],
        }),
        deliveriesIssues: patch({
          loading: true,
          data: [],
        }),
        productsIssues: patch({
          loading: true,
          data: [],
        }),
        suppliersIssues: patch({
          loading: true,
          data: [],
        }),
      })
    );
  }

  @Action(LoadOrdersIssues)
  loadOrdersIssues(ctx: StateContext<TodoListV2StateModel>) {
    ctx.setState(
      patch({
        ordersIssues: patch({
          loading: true,
        }),
      })
    );

    return this._findOrdersIssues(ctx).pipe(
      tap((summaries: OrdersOverviewSummaries) => {
        ctx.setState(
          patch({
            ordersIssues: patch({
              loading: false,
              data: summaries.ordersOverviewSummaries,
            }),
          })
        );
      })
    );
  }

  @Action(LoadDeliveriesIssues)
  loadDeliveriesIssues(ctx: StateContext<TodoListV2StateModel>) {
    ctx.setState(
      patch({
        deliveriesIssues: patch({
          loading: true,
        }),
      })
    );

    return this._findDeliveriesIssues(ctx).pipe(
      tap((summaries: DeliveriesOverviewSummaries) => {
        ctx.setState(
          patch({
            deliveriesIssues: patch({
              loading: false,
              data: summaries.deliveriesOverviewSummaries,
            }),
          })
        );
      })
    );
  }

  @Action(LoadProductsIssues)
  loadProductsIssues(ctx: StateContext<TodoListV2StateModel>) {
    ctx.setState(
      patch({
        productsIssues: patch({
          loading: true,
        }),
      })
    );

    return this._findProductIssues(ctx).pipe(
      tap((issues: Issue[]) => {
        ctx.setState(
          patch({
            productsIssues: patch({
              loading: false,
              data: issues,
            }),
          })
        );
      })
    );
  }

  @Action(LoadSuppliersIssues)
  loadSuppliersIssues(ctx: StateContext<TodoListV2StateModel>) {
    ctx.setState(
      patch({
        suppliersIssues: patch({
          loading: true,
        }),
      })
    );

    return this._findSupplierIssues(ctx).pipe(
      tap((issues: Issue[]) => {
        ctx.setState(
          patch({
            suppliersIssues: patch({
              loading: false,
              data: issues,
            }),
          })
        );
      })
    );
  }

  private _findOrdersIssues(
    ctx: StateContext<TodoListV2StateModel>
  ): Observable<OrdersOverviewSummaries> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.buyOrderV2Service.findOrdersOverviewSummary(webshopUuid).pipe(
      catchError(e => {
        ctx.setState(
          patch({
            ordersIssues: patch({
              loading: false,
              data: [],
            }),
          })
        );

        throw new Error(e.message || e);
      })
    );
  }

  private _findDeliveriesIssues(
    ctx: StateContext<TodoListV2StateModel>
  ): Observable<DeliveriesOverviewSummaries> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.deliveriesV2Service
      .findDeliveriesOverviewSummary(webshopUuid)
      .pipe(
        catchError(e => {
          ctx.setState(
            patch({
              deliveriesIssues: patch({
                loading: false,
                data: [],
              }),
            })
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _findProductIssues(
    ctx: StateContext<TodoListV2StateModel>
  ): Observable<void | Issue[]> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.productsV3Service.findProductIssues(webshopUuid).pipe(
      catchError(e => {
        ctx.setState(
          patch({
            productsIssues: patch({
              loading: false,
              data: [],
            }),
          })
        );

        throw new Error(e.message || e);
      })
    );
  }

  private _findSupplierIssues(
    ctx: StateContext<TodoListV2StateModel>
  ): Observable<void | Issue[]> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.suppliersV2Service.findSupplierIssues(webshopUuid).pipe(
      catchError(e => {
        ctx.setState(
          patch({
            suppliersIssues: patch({
              loading: false,
              data: [],
            }),
          })
        );

        throw new Error(e.message || e);
      })
    );
  }
}
