<app-dialog-container
  data-intercom-target="8eee8809-4fe5-4526-9150-decfeea627e7"
  ><div class="dialog-dialog">
    <div appDialogHeader>
      <h1 appDialogTitle i18n="snooze dialog title">
        <mat-icon svgIcon="info-rounded" class="tooltip-icon"></mat-icon> Snooze
        product?
      </h1>
      <button
        aria-label="Snooze dialog close button"
        class="close-button"
        (click)="close()">
        <mat-icon svgIcon="cross" class="close-icon"></mat-icon>
      </button>
    </div>

    <div appDialogBody>
      <div class="container-content-col input-field">
        <p i18n="snooze dialog message">
          This product will be set to <b>snooze</b>.<br />
          When would you like to purchase it again? This action cannot be
          undone.
        </p>

        <app-radio-button-group-v2
          data-intercom-target="06410af8-fb9e-42ff-ab33-1af0bdfbbe77"
          name="snooze"
          [ngModel]="snooze()"
          (ngModelChange)="snooze.set($event); customDate.set(null)">
          <app-radio-button-v2 [value]="SNOOZE_TYPES.NEXT_ORDER_MOMENT">{{
            STRINGS.nextOrderMoment
          }}</app-radio-button-v2>

          <app-radio-button-v2 [value]="SNOOZE_TYPES.ONE_WEEK">{{
            STRINGS.oneWeek
          }}</app-radio-button-v2>

          <app-radio-button-v2 [value]="SNOOZE_TYPES.ONE_MONTH">{{
            STRINGS.oneMonth
          }}</app-radio-button-v2>

          <app-radio-button-v2 [value]="SNOOZE_TYPES.THREE_MONTH">{{
            STRINGS.threeMonths
          }}</app-radio-button-v2>

          <div class="fields-date-container">
            <app-radio-button-v2 [value]="SNOOZE_TYPES.CUSTOM_DATE">{{
              STRINGS.customDate
            }}</app-radio-button-v2>

            @if (showDate()) {
              <div
                class="optiply-filled-datepicker snooze-custom-date"
                (click)="picker.open()">
                <input
                  [matDatepicker]="picker"
                  name="customDate"
                  [min]="picker.opened ? minCustomDate : null"
                  [ngModel]="customDate()"
                  (ngModelChange)="customDate.set($event)"
                  [placeholder]="COMMON_STRINGS.selectDate" />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon
                    matDatepickerToggleIcon
                    svgIcon="calendar"
                    class="datepicker-icon"></mat-icon
                ></mat-datepicker-toggle>
                <mat-datepicker #picker> </mat-datepicker>
              </div>
            }
          </div>

          <app-radio-button-v2 [value]="SNOOZE_TYPES.INDEFINITE">{{
            STRINGS.indefinite
          }}</app-radio-button-v2>
        </app-radio-button-group-v2>

        @if (snoozeUntilInDays() !== null && snoozeUntilInDays() !== -1) {
          <span
            data-intercom-target="1562f779-5cc7-437d-bb02-1dd90ef93e7a"
            class="resume-text"
            i18n="snooze dialog resume label">
            Will resume purchasing in&nbsp;
            <span class="strong">{{ snoozeUntilInDays() }}&nbsp;days</span
            >&nbsp;on
            <span class="strong">&nbsp;{{ snoozeUntil() | date }}</span
            >.
          </span>
        } @else {
          <span class="resume-text">
            {{ message() }}
          </span>
        }
      </div>
    </div>

    <div appDialogActions>
      <div class="footer-help">
        <button
          data-intercom-target="8b24a5bc-33fc-472d-a190-bf857946dca1"
          aria-label="Snooze dialog help button"
          appButton
          variant="tertiary"
          class="help-button"
          i18n="snooze dialog footer help"
          (click)="helpLink()">
          <span style="display: flex; align-items: center">
            <mat-icon
              svgIcon="questionmark-rounded"
              class="tooltip-icon"></mat-icon>
            Help
          </span>
        </button>
      </div>
      <div class="right-actions">
        <button
          data-intercom-target="b65d0681-a322-4a54-ba2d-b8f50dfb054c"
          aria-label="Snooze dialog cancel button"
          appButton
          variant="tertiary"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          data-intercom-target="477a9985-eaab-433d-b0c3-1ca04adffda2"
          aria-label="Snooze dialog snooze button"
          appButton
          variant="warning"
          [disabled]="snoozeUntilInDays() === null"
          (click)="saveSnooze()">
          {{ STRINGS.actions.snoozeProduct }}
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
