import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  booleanAttribute,
  signal,
} from '@angular/core';
import { DropdownOptionGroupComponent } from '../dropdown-option-group/dropdown-option-group.component';
import {
  DROPDOWN_PARENT,
  DropdownOptionSelected,
  OPTION_GROUP,
} from '../../model/dropdown.model';
import { DropdownComponent } from '../../dropdown.component';

@Component({
  selector: 'app-dropdown-option',
  templateUrl: './dropdown-option.component.html',
  styleUrls: ['./dropdown-option.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOptionComponent<T = any> implements OnInit {
  @ViewChild('labelText', { static: true })
  labelText: ElementRef;

  /** Wether the option is disabled individually or the whole options group */
  @Input({ transform: booleanAttribute })
  disabled: boolean;

  @Input()
  label: string;

  @Input()
  value: T;

  @Output()
  selectionChange: EventEmitter<DropdownOptionSelected> =
    new EventEmitter<DropdownOptionSelected>();

  private _selected = signal(false);

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(DROPDOWN_PARENT)
    private parent: DropdownComponent,
    @Optional()
    @Inject(OPTION_GROUP)
    private group: DropdownOptionGroupComponent
  ) {}

  ngOnInit(): void {
    this._initializeOptions();
  }

  /** Select option from the exact value and marks for check */
  selectOption(
    selected: boolean,
    options: { emitEvent: boolean } = { emitEvent: false }
  ): void {
    if (this.disabled) return;

    this._selected.set(selected);

    if (options.emitEvent) {
      this.selectionChange.emit(new DropdownOptionSelected<T>(this));
    }
  }

  /**
   * Select by simply clicking the option.
   *
   * If multiple are allowed, only the respective option gets selected/not selected (depending on the previous state)
   *
   * else it always gets selected.
   */
  selectViaInteraction(): void {
    if (this.disabled) return;

    if (this.multiple) {
      this.selectOption(!this._selected(), { emitEvent: true });
      return;
    }

    this.selectOption(true, { emitEvent: true });
  }

  select(): void {
    if (this._selected() || this.disabled) return;

    this.selectOption(true, { emitEvent: true });
  }

  deselect(): void {
    if (!this._selected() || this.disabled) return;

    this.selectOption(false, { emitEvent: true });
  }

  clearOptionSelection(): void {
    this.selectOption(false);
  }

  /** Wether multiple option can be selected setted by the parent component (dropdown) */
  get multiple(): boolean {
    return this.parent?.multiple;
  }

  get tabIndex(): string {
    return this.disabled ? '-1' : '0';
  }

  get displayedValue(): string {
    return (this.labelText?.nativeElement.textContent || this.label).trim();
  }

  get isDisabled(): boolean {
    return this.group?.disabled || this.disabled;
  }

  get selected(): boolean {
    return this._selected();
  }

  private _initializeOptions(): void {
    if (this.parent) {
      this._selected.set(this.parent.value === this.value);
    }
  }
}
