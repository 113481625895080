export enum PurchaseExportsSettingsOptions {
  SP_NAME = 'SUPPLIER_PRODUCT_NAME',
  SP_ARTICLE_CODE = 'SUPPLIER_PRODUCT_ARTICLE_CODE',
  SP_EAN_CODE = 'SUPPLIER_PRODUCT_EAN_CODE',
  SP_SKU_CODE = 'SUPPLIER_PRODUCT_SKU_CODE',
  WP_NAME = 'WEBSHOP_PRODUCT_NAME',
  WP_ARTICLE_CODE = 'WEBSHOP_PRODUCT_ARTICLE_CODE',
  WP_EAN_CODE = 'WEBSHOP_PRODUCT_EAN_CODE',
  WP_SKU_CODE = 'WEBSHOP_PRODUCT_SKU_CODE',
}
