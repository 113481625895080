import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  booleanAttribute,
  computed,
  contentChildren,
  effect,
  input,
  numberAttribute,
  output,
  signal,
} from '@angular/core';
import { TabTemplateComponent } from '../tab-template/tab-template.component';
import { TAB_TEMPLATE } from '../../model/tab.model';
import {
  TabsGroupOrientation,
  TabsGroupSize,
} from '../../model/tabs-group.model';

function convertToArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

@Component({
  selector: 'app-tabs-group-template',
  templateUrl: './tabs-group-template.component.html',
  styleUrls: ['./tabs-group-template.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class TabsGroupTemplateComponent implements AfterContentInit {
  tabs = contentChildren(TAB_TEMPLATE);

  @Output()
  selectedIndex = input(0, { transform: numberAttribute });

  orientation = input<TabsGroupOrientation>('horizontal');

  size = input<TabsGroupSize>('md');

  transparent = input(false, { transform: booleanAttribute });

  @Output()
  currentSelectedIndex: EventEmitter<number> = new EventEmitter<number>();

  customClasses = input([], {
    transform: convertToArray,
  });

  customButtonClasses = input([], {
    transform: convertToArray,
  });

  customDropdownClasses = input([], {
    transform: convertToArray,
  });

  customContentClasses = input([], {
    transform: convertToArray,
  });

  containerClasses = computed(() => [
    'tab-group-container',
    `tab-group-container-${this.orientation()}`,
    `tab-group-container-${this.size()}`,
  ]);

  baseClasses = computed(() => ['tab-group-wrapper', ...this.customClasses()]);

  baseTabsContainerClasses = computed(() => [
    'tab-group-wrapper-tabs',
    `tab-group-wrapper-tabs-${this.orientation()}`,
    `tab-group-wrapper-tabs-${this.size()}`,
  ]);

  baseButtonClasses = computed(() => ['tab', ...this.customButtonClasses()]);

  baseDropdownClasses = computed(() => [
    'tab-dropdown',
    ...this.customDropdownClasses(),
  ]);

  baseContentClasses = computed(() => [...this.customContentClasses()]);

  currentTab = signal({
    template: null,
    index: this.selectedIndex(),
    parentIndex: null,
  });

  constructor() {
    effect(
      () => {
        if (this.tabs()) {
          this.selectFirstAvailableTab();
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngAfterContentInit() {
    this.initialTabSelection();
  }

  selectCurrentTab(
    tab: TabTemplateComponent,
    index: number,
    parentIdx: number | null = null
  ): void {
    if (!tab) return;

    this.currentTab.set({
      template: tab.content,
      index: index,
      parentIndex: parentIdx,
    });

    this.currentSelectedIndex.emit(index);
  }

  initialTabSelection(): void {
    if (!this.tabs().length) return;

    const initialTab = this.tabs()[this.selectedIndex()];

    if (initialTab && initialTab.disabled) {
      this.selectFirstAvailableTab();

      return;
    }

    if (initialTab && initialTab.isGroup) {
      const initialTabIdx = initialTab.tabs().findIndex(tab => !tab.disabled);

      if (initialTabIdx === -1) {
        this.selectFirstAvailableTab();
      } else {
        this.selectCurrentTab(
          initialTab.tabs()[initialTabIdx],
          initialTabIdx,
          this.selectedIndex()
        );
      }

      return;
    }

    this.selectCurrentTab(initialTab, this.selectedIndex());
  }

  selectFirstAvailableTab(): void {
    if (!this.tabs().length) return;

    const firstAvailableTab = this.tabs()?.find(tab => !tab.disabled) ?? null;

    if (!firstAvailableTab) return;

    const firstAvailableTabIdx = this.tabs().indexOf(firstAvailableTab) ?? 0;

    if (!firstAvailableTab.isGroup) {
      this.selectCurrentTab(firstAvailableTab, firstAvailableTabIdx);

      return;
    }

    const firstAvailableTabIdxInGroup = firstAvailableTab
      .tabs()
      .findIndex(tab => !tab.disabled);

    if (!firstAvailableTabIdxInGroup) return;

    this.selectCurrentTab(
      firstAvailableTab.tabs()[firstAvailableTabIdxInGroup],
      firstAvailableTabIdxInGroup,
      firstAvailableTabIdx
    );
  }
}
