<div
  role="button"
  class="filter-header-container filterable"
  [class.filterable-active]="active()"
  [class.filterable-disabled]="allOptionsAreDisabled()"
  appOverlay
  [overlayDisabled]="allOptionsAreDisabled()"
  [alignment]="filterAlignment()"
  (click)="_handleClick()"
  (outsideClicked)="active.set(false)"
  [appTooltip]="
    allOptionsAreDisabled()
      ? FILTER_STRINGS.disabledOptions
      : FILTER_STRINGS.options
  "
  tooltipShowDelay="1000">
  <!-- Column content -->
  <div class="filter-header-content" [class.filtered]="filtered()">
    <ng-content />
  </div>

  <!-- Filtered icon -->
  @if (filtered()) {
    <mat-icon svgIcon="filter-filled-xs" class="filtered-icon" />
  }

  <!-- Sort icon -->
  @if (sort()) {
    <mat-icon
      svgIcon="arrow-up-xs"
      class="sorted-icon"
      [class.sorted-icon-reverse]="sort() === SORTED_ORDER.DESC" />
  }

  <!-- Overlay filter template -->
  <ng-template cdk-portal>
    <app-overlay-container-v2>
      @if (!hideSort()) {
        <app-sort
          [order]="sort()"
          [label]="label()"
          (sorted)="onSort($event)"
          [disabled]="disableSort()" />
      }

      @if (!hideHideColumn()) {
        <app-hide-column (hidden)="onHide()" [disabled]="disableHideColumn()" />
      }

      @if (!filter().disabled && !hideFilter()) {
        <div class="divider"></div>

        <div
          [appTooltip]="FILTER_STRINGS.disabledFilterColumn"
          [tooltipDisabled]="!filter().disabled">
          @switch (filter().uiModel) {
            @case (FILTER_TYPES.FILTER_NUMBER) {
              <app-filter-number
                [filter]="filter()"
                (filtered)="onFilter($event)"
                (canceled)="onCancel()"
                (stateChanged)="updateOverlayPosition()"
                defaultName>
                <ng-template #headerTemplate>
                  <app-filter-header
                    allowRemove
                    [disableRemove]="filter().params?.value === null"
                    (remove)="onRemove()" />
                </ng-template>
              </app-filter-number>
            }

            @case (FILTER_TYPES.FILTER_DATE) {
              <app-filter-date
                [filter]="filter()"
                (filtered)="onFilter($event)"
                (canceled)="onCancel()"
                (stateChanged)="updateOverlayPosition()"
                defaultName>
                <ng-template #headerTemplate>
                  <app-filter-header
                    allowRemove
                    [disableRemove]="filter().params?.value === null"
                    (remove)="onRemove()" />
                </ng-template>
              </app-filter-date>
            }

            @case (FILTER_TYPES.FILTER_LIST) {
              <app-filter-restricted
                [filter]="filter()"
                (filtered)="onFilter($event)"
                (canceled)="onCancel()"
                (stateChanged)="updateOverlayPosition()"
                defaultName>
                <ng-template #headerTemplate>
                  <app-filter-header
                    allowRemove
                    [disableRemove]="filter().params?.value === null"
                    (remove)="onRemove()" />
                </ng-template>
              </app-filter-restricted>
            }

            @case (FILTER_TYPES.FILTER_BOOLEAN) {
              <app-filter-boolean
                [filter]="filter()"
                (filtered)="onFilter($event)"
                (canceled)="onCancel()"
                (stateChanged)="updateOverlayPosition()"
                defaultName>
                <ng-template #headerTemplate>
                  <app-filter-header
                    allowRemove
                    [disableRemove]="filter().params?.value === null"
                    (remove)="onRemove()" />
                </ng-template>
              </app-filter-boolean>
            }

            @case (FILTER_TYPES.FILTER_NULLABLE) {
              <app-filter-nullable
                [filter]="filter()"
                (filtered)="onFilter($event)"
                (canceled)="onCancel()"
                (stateChanged)="updateOverlayPosition()"
                defaultName>
                <ng-template #headerTemplate>
                  <app-filter-header
                    allowRemove
                    [disableRemove]="filter().params?.value === null"
                    (remove)="onRemove()" />
                </ng-template>
              </app-filter-nullable>
            }

            @case (FILTER_TYPES.FILTER_NOT_EXISTS) {
              <app-filter-not-exists
                [filter]="filter()"
                (filtered)="onFilter($event)"
                (canceled)="onCancel()"
                (stateChanged)="updateOverlayPosition()"
                defaultName>
                <ng-template #headerTemplate>
                  <app-filter-header
                    allowRemove
                    [disableRemove]="filter().params?.value === null"
                    (remove)="onRemove()" />
                </ng-template>
              </app-filter-not-exists>
            }

            @default {
              <app-filter-text
                [filter]="filter()"
                (filtered)="onFilter($event)"
                (canceled)="onCancel()"
                (stateChanged)="updateOverlayPosition()"
                defaultName>
                <ng-template #headerTemplate>
                  <app-filter-header
                    allowRemove
                    [disableRemove]="filter().params?.value === null"
                    (remove)="onRemove()" />
                </ng-template>
              </app-filter-text>
            }
          }
        </div>
      }
    </app-overlay-container-v2>
  </ng-template>
</div>
