import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/imports/model/imports.strings';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { LocaleState } from 'src/app/core/states/locale.state';
import {
  CloseImportsDialog,
  UpdateImportList,
} from './components/import-suppliers-update-dialog/actions/import-suppliers-dialog.action';
import { ImportSuppliersDialogState } from './components/import-suppliers-update-dialog/state/import-suppliers-dialog.state';
import {
  useCSVDarkTheme,
  useCSVLightTheme,
  useCSVcustomText,
} from 'src/app/features/imports/model/imports.model';
import { dynamicColumnsWithStatus } from './components/import-suppliers-update-dialog/model/import-suppliers-update-dialog.model';
import { ImportSuppliersUpdateDialogComponent } from './components/import-suppliers-update-dialog/import-suppliers-update-dialog.component';
import { UsecsvDialogComponent } from '../usecsv-dialog/usecsv-dialog.component';

@Component({
  selector: 'app-import-suppliers-dialog',
  templateUrl: './import-suppliers-dialog.component.html',
  styleUrl: './import-suppliers-dialog.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportSuppliersDialogComponent {
  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata;

  @Select(AccountSettingsState.colorThemeLowercase)
  colorTheme$: Observable<string>;

  @Select(LocaleState.localeId)
  language$: Observable<string>;

  parentForm = new FormGroup({
    type: new FormControl('INSERT'),
  });

  disableSubmitButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  open: boolean = false;

  radioButtons = {
    insert: true,
    update: false,
  };

  dynamicColumnsWithStatus = dynamicColumnsWithStatus;

  useCSVDarkTheme = useCSVDarkTheme;

  useCSVLightTheme = useCSVLightTheme;

  customText = useCSVcustomText;

  @Select(ImportSuppliersDialogState.importKey)
  importKey$: Observable<boolean>;

  importData = (data: any) => {
    this.store.dispatch(new UpdateImportList(data, 'INSERT', 'NAME'));
  };

  onClose = () => {
    this.store.dispatch(new CloseImportsDialog());
  };

  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    private dialog: Dialog
  ) {}

  close() {
    this.dialogRef.close();
  }

  checkRadioType(value: string) {
    this.parentForm.get('type').setValue(value);
    this.updateRadioType();
  }

  updateRadioType() {
    if (this.parentForm.get('type').value === 'INSERT') {
      this.radioButtons = {
        insert: true,
        update: false,
      };
    } else if (this.parentForm.get('type').value === 'UPDATE') {
      this.radioButtons = {
        insert: false,
        update: true,
      };
    }
  }

  openImportsDialog(): void {
    this.close();
    this.dialog.open(ImportSuppliersUpdateDialogComponent, {
      width: '600px',
      disableClose: true,
    });
  }

  openUsecsvDialog(): void {
    this.dialog.open(UsecsvDialogComponent);
  }
}
