import { Directive, HostBinding } from '@angular/core';
import { EXPANSION_PANEL_TITLE } from '../model/expansion-panel.model';

@Directive({
  selector: '[appExpansionPanelTitle], app-expansion-panel-title',
  providers: [
    {
      provide: EXPANSION_PANEL_TITLE,
      useExisting: ExpansionPanelTitleDirective,
    },
  ],
})
export class ExpansionPanelTitleDirective {
  @HostBinding('class')
  class: string = 'expansion-panel-header-title';
}
