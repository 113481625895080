<div class="wrapper">
  <div class="ctx-nav-container">
    <div class="ctx-nav" [class.double-section]="contentTemplate">
      <!-- Header as previously used -->
      <div class="ctx-nav-header-wrapper">
        <div class="ctx-nav-header">
          <div class="ctx-nav-routing">
            <span
              [appTooltip]="titleName | goBack"
              [tooltipDisabled]="!routing"
              class="ctx-nav-title"
              [class.ctx-nav-title-routing]="!!routing"
              (click)="navigate()">
              <mat-icon svgIcon="angle-left" class="icon"></mat-icon>
              {{ titleName }}
            </span>

            <span
              class="ctx-nav-subtitle"
              [appTooltip]="subTitle"
              [tooltipShowDelay]="300"
              tooltipShouldCheckOverflow
              >{{ subTitle }}</span
            >

            <!-- Use for tags, toggles, etc. -->
            <div class="ctx-nav-extras">
              <ng-content select="[extras]"></ng-content>
            </div>
          </div>

          <!-- Use for actions (cancel, apply, options, etc.) -->
          <ng-content
            select="[basic-context-nav-bar-v2-actions], [basicContextNavbarV2Actions]"></ng-content>
        </div>
      </div>

      <ng-container *ngIf="contentTemplate">
        <div class="ctx-nav-separator"></div>

        <!-- Order details -->
        <div class="ctx-nav-content-wrapper">
          <ng-container
            [ngTemplateOutlet]="contentTemplate.templateRef"></ng-container>
        </div>
      </ng-container>

      <!-- Dynamic content (use it carefully) -->
      <ng-content></ng-content>
    </div>
  </div>
</div>
