export const ISSUE_MAX_VALUE = 1000;

export const ISSUE_CAPPED_VALUE = '1000+';

export enum IssueStatusColors {
  CRITICAL = 'red',
  WARNING = 'orange',
  SUCCESS = 'green',
  NEUTRAL = 'default',
}

export const STATUS_PRIORITY = {
  [IssueStatusColors.CRITICAL]: 1,
  [IssueStatusColors.WARNING]: 2,
  [IssueStatusColors.SUCCESS]: 3,
  [IssueStatusColors.NEUTRAL]: 4,
};

export function capIssuesValues(value: number): string {
  return value > ISSUE_MAX_VALUE ? ISSUE_CAPPED_VALUE : value.toString();
}

export function sortIssuesStatus<T extends { color: string }>(a: T, b: T) {
  return STATUS_PRIORITY[a.color] - STATUS_PRIORITY[b.color];
}

export interface IssueContext {
  label: string;
  subject: string;
  description: string;
  color: IssueStatusColors;
}

export interface Issue extends IssueContext {
  status: string;
  total: string;
}

export interface IssueInfo {
  issues: Issue[];
  warningAmount: string;
  dangerAmount: string;
}

export interface Issues {
  suppliers: IssueInfo;
  purchase: IssueInfo;
  products: IssueInfo;
  deliveries: IssueInfo;
}

export const ISSUE_STATUS = {
  OVERVIEW_SUMMARY_OVERDUE_ORDERS: IssueStatusColors.CRITICAL,
  OVERVIEW_SUMMARY_TODAYS_ORDERS: IssueStatusColors.SUCCESS,
  OVERVIEW_SUMMARY_UNPLANNED_ORDERS: IssueStatusColors.WARNING,
  OVERVIEW_SUMMARY_OVERDUE_DELIVERIES: IssueStatusColors.CRITICAL,
  OVERVIEW_SUMMARY_TODAYS_DELIVERIES: IssueStatusColors.SUCCESS,
  FILL_LEAD_TIME: IssueStatusColors.CRITICAL,
  FILL_REPLENISHMENT_PERIOD: IssueStatusColors.CRITICAL,
  FILL_FIXED_COSTS: IssueStatusColors.WARNING,
  CONNECT_TO_SUPPLIER: IssueStatusColors.CRITICAL,
  UPDATE_PURCHASE_PRICE: IssueStatusColors.WARNING,
  UPDATE_SELLING_PRICE: IssueStatusColors.WARNING,
};

export interface TodoListIssues<T> {
  loading: boolean;
  data: T;
}
