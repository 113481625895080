import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { SalesGraphData } from 'src/app/shared/models/sales/sales.model';
import {
  SalesGraph as SalesGraphV1,
  SalesGraphOverview,
} from './model/sales.model';
import { OverviewAdapter } from './overview.adapter';
import { SalesAdapter } from './sales.adapter';

export interface Parameters {
  groupBy?: string;
  filter: string;
}

@Injectable({ providedIn: 'root' })
export class SalesService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private fetch(payload: any, replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'productgraph',
      'v1',
      'fetchGraphData',
      replacements
    );

    return this.http.get<SalesGraphV1>(endpoint, { params: payload });
  }

  private fetchOverview(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'productgraph',
      'v1',
      'overview',
      replacements
    );

    return this.http.get<SalesGraphOverview>(endpoint, { params: payload });
  }

  fetchSales(
    webshopProductUUID: string,
    webshopUUID: string,
    groupBy: string,
    filter: string
  ): Observable<SalesGraphData> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopProductUUID', value: webshopProductUUID },
      { name: 'webshopUUID', value: webshopUUID },
    ];

    const payload: Parameters = {
      groupBy,
      filter,
    };

    return this.fetch(payload, replacements).pipe(
      switchMap(data => of(new SalesAdapter().transform(data)))
    );
  }

  fetchSalesOverview(
    webshopProductUUID: string,
    webshopUUID: string,
    filter: string
  ): Observable<SalesGraphOverview> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopProductUUID', value: webshopProductUUID },
      { name: 'webshopUUID', value: webshopUUID },
    ];

    const payload: Parameters = {
      filter,
    };

    return this.fetchOverview(payload, replacements).pipe(
      switchMap(data => of(new OverviewAdapter().transform(data)))
    );
  }
}
