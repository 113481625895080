import { DataAdapter } from 'src/app/shared/adapter/adapter';

import { SupplierManagers as SupplierManagersV1 } from '../model/supplier-manager.model';
import { SupplierManager } from 'src/app/shared/models/suppliers/v2/supplier-v2.model';

export class SupplierManagerAdapter
  implements DataAdapter<SupplierManagersV1, SupplierManager>
{
  transform(data: SupplierManagersV1): SupplierManager {
    let newSupplier: SupplierManager;
    if (data.accounts.length === 0) {
      newSupplier = {
        accountUuids: [],
        ownerUuid: null,
      };
    } else {
      data.accounts.map(account => {
        if (account.owner) {
          newSupplier = {
            accountUuids: account ? [account.accountUuid] : [],
            ownerUuid: account ? account.accountUuid : null,
          };
        }
      });
    }

    return newSupplier;
  }
}
