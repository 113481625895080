import { AddProductSupplierDialogStateModel } from '../state/add-product-supplier-dialog.state';

export const defaults: AddProductSupplierDialogStateModel = {
  supplierInfo: {
    name: null,
    sku: null,
    supplierId: null,
    eanCode: null,
    weight: null,
    volume: null,
    price: 0,
    deliveryTime: null,
    purchaseInQuantitiesOf: 1,
    minimumPurchaseQuantity: 1,
    availability: true,
    availabilityDate: null,
    preferred: false,
    webshopProductId: null,
    currentStockOnHand: null,
    status: 0,
  },
};
