import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TextOverflowService {
  checkOverflow(element) {
    return (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    );
  }
}
