import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  DeliveriesBuyOrders,
  Delivery,
  DeliveryBuyOrder,
  DeliveryBuyOrderMetadata,
  DeliveryOrderMoment,
  DeliverySupplier,
} from 'src/app/shared/models/delivery/v2/deliveries-v2.model';
import {
  DeliveriesBuyOrders as DeliveriesBuyOrdersV1,
  Delivery as DeliveryV1,
  DeliveryBuyOrder as DeliveryBuyOrderV1,
  DeliveryOrderMoment as DeliveryOrderMomentV1,
  DeliverySupplier as DeliverySupplierV1,
  DeliveryBuyOrderMetadata as DeliveryBuyOrderMetadataV1,
} from '../model/deliveries-v2.model';
import { Store } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { DateTime } from 'luxon';
import { formatDateToWebshopTimezone } from 'src/app/shared/utils/dates.utils';
import {
  DynamicTags,
  TagsBuilder,
  TagVariantColor,
} from 'src/app/shared/components/design-system/tags/model/tag.model';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { formatDate } from '@angular/common';

export class DeliveriesBuyOrdersV2Adapter
  implements
    DataAdapter<DeliveriesBuyOrdersV1, DeliveriesBuyOrders>,
    TagsBuilder<DeliveryV1>
{
  constructor(private store: Store) {}
  transform(data: DeliveriesBuyOrdersV1): DeliveriesBuyOrders {
    const buyorders: Delivery[] = data.orders.map((delivery: DeliveryV1) => {
      return {
        uuid: delivery.uuid,
        buyOrder: this._buildBuyOrder(delivery.buyOrder),
        supplier: this._buildSupplier(delivery.supplier),
        orderMoment: this._buildOrderMoment(delivery.orderMoment),
        totalOrdered: delivery.totalOrdered,
        totalDelivered: delivery.totalDelivered,
        expectedDeliveryDate: delivery.expectedDeliveryDate,
        notes: delivery.notes,
        closedDate: this._buildTimeZoneDate(delivery.closedDate),
        extras: {
          tags: this.buildTags(delivery),
        },
      };
    });

    return {
      data: buyorders,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  buildTags(base: DeliveryV1): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (base.buyOrder?.metadata.userSendEmailOptionSelected) {
      extras.iconTags.push({
        color: TagVariantColor.DEFAULT,
        variant: 'subtle',
        icon: 'envelope',
        tooltip: MESSAGES.common.emailSent,
      });
    }

    return extras;
  }

  private _buildBuyOrder(buyorder: DeliveryBuyOrderV1): DeliveryBuyOrder {
    if (!buyorder) return null;

    return {
      uuid: buyorder.uuid,
      id: buyorder.id,
      date: this._buildTimeZoneDate(buyorder.date),
      totalValue: buyorder.totalValue,
      closedDate: this._buildTimeZoneDate(buyorder.closedDate),
      buyerEmail: buyorder.buyerEmail === '' ? null : buyorder.buyerEmail,
      metadata: this._buildBuyOrderMetadata(buyorder.metadata),
    };
  }

  private _buildBuyOrderMetadata(
    metadata: DeliveryBuyOrderMetadataV1
  ): DeliveryBuyOrderMetadata {
    if (!metadata) return null;

    const timezone = this.store.selectSnapshot(WebshopState.timeZoneOffset);

    return {
      userSendEmailOptionSelected: metadata.userSendEmailOptionSelected,
      lastSendEmailRequestTriggeredAt: new Date(
        formatDate(
          metadata.lastSendEmailRequestTriggeredAt,
          'yyyy-MM-dd HH:mm:ss',
          'en-US',
          timezone
        )
      ),
    };
  }

  private _buildSupplier(supplier: DeliverySupplierV1): DeliverySupplier {
    if (!supplier) return null;

    return {
      uuid: supplier.uuid,
      name: supplier.name,
      minimalOrderValue: supplier.minimalOrderValue,
      effectiveReplenishmentPeriod: supplier.effectiveReplenishmentPeriod,
      leadTimeMeanActual: supplier.leadTimeMeanActual,
      deliveryTime: supplier.deliveryTime,
    };
  }

  private _buildOrderMoment(
    orderMoment: DeliveryOrderMomentV1
  ): DeliveryOrderMoment {
    if (!orderMoment) return null;

    return {
      uuid: orderMoment.uuid,
      id: orderMoment.id,
    };
  }

  private _buildTimeZoneDate(date: string): string {
    if (date === null) {
      return null;
    }
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const todayDateWebshopTimeZone = formatDateToWebshopTimezone(
      timeZoneIdentifier,
      date
    );

    return DateTime.fromJSDate(new Date(todayDateWebshopTimeZone)).toFormat(
      'yyyy-MM-dd'
    );
  }
}
