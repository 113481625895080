export const STRINGS = {
  columns: {
    deliveries: {
      BUY_ORDER_ID: {
        key: 'BUY_ORDER_ID',
        name: $localize`Order #`,
        filterKey: 'BUY_ORDER_ID',
        currency: false,
        containers: false,
      },
      SUPPLIER_NAME: {
        key: 'SUPPLIER_NAME',
        name: $localize`Supplier name`,
        filterKey: 'SUPPLIER_NAME',
        currency: false,
        containers: false,
      },
      ICONS: {
        key: 'ICONS',
        name: $localize`Attributes`,
        filterKey: 'ICONS',
        currency: false,
        containers: false,
      },
      ORDERED: {
        key: 'ORDERED',
        name: $localize`Ordered products`,
        filterKey: 'ORDERED',
        currency: false,
        containers: false,
      },
      DELIVERED: {
        key: 'DELIVERED',
        name: $localize`Delivered products`,
        filterKey: 'DELIVERED',
        currency: false,
        containers: false,
      },
      TOTAL_VALUE: {
        key: 'TOTAL_VALUE',
        name: $localize`Total value`,
        filterKey: 'TOTAL_VALUE',
        currency: true,
        containers: false,
      },
      EXPECTED_DELIVERY_DATE: {
        key: 'EXPECTED_DELIVERY_DATE',
        name: $localize`Expected delivery date`,
        filterKey: 'EXPECTED_DELIVERY_DATE',
        currency: false,
        containers: false,
      },
      CLOSED_DATE: {
        key: 'CLOSED_DATE',
        name: $localize`Closed date`,
        filterKey: 'CLOSED_DATE',
        currency: false,
        containers: false,
      },
      DATE: {
        key: 'DATE',
        name: $localize`Placed date`,
        filterKey: 'DATE',
        currency: false,
        containers: false,
      },
      BUYER_EMAIL: {
        key: 'BUYER_EMAIL',
        name: $localize`Placed by`,
        filterKey: 'BUYER_EMAIL',
        currency: false,
        containers: false,
      },
      NOTES: {
        key: 'NOTES',
        name: $localize`Notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      HAS_NOTES: {
        key: 'HAS_NOTES',
        name: $localize`Has notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
    deliveriesEditor: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_SKU: {
        key: 'WEBSHOP_PRODUCT_SKU',
        name: $localize`Product SKU`,
        filterKey: 'WEBSHOP_PRODUCT_SKU',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_EAN_CODE: {
        key: 'WEBSHOP_PRODUCT_EAN_CODE',
        name: $localize`Product EAN code`,
        filterKey: 'WEBSHOP_PRODUCT_EAN_CODE',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_NAME: {
        key: 'WEBSHOP_PRODUCT_NAME',
        name: $localize`Product name`,
        filterKey: 'WEBSHOP_PRODUCT_NAME',
        currency: false,
        containers: false,
      },
      WEBSHOP_PRODUCT_ARTICLE_CODE: {
        key: 'WEBSHOP_PRODUCT_ARTICLE_CODE',
        name: $localize`Product article code`,
        filterKey: 'WEBSHOP_PRODUCT_ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_SKU: {
        key: 'SUPPLIER_PRODUCT_SKU',
        name: $localize`Supplier product SKU`,
        filterKey: 'SUPPLIER_PRODUCT_SKU',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_EAN_CODE: {
        key: 'SUPPLIER_PRODUCT_EAN_CODE',
        name: $localize`Supplier product EAN`,
        filterKey: 'SUPPLIER_PRODUCT_EAN_CODE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_NAME: {
        key: 'SUPPLIER_PRODUCT_NAME',
        name: $localize`Supplier product name`,
        filterKey: 'SUPPLIER_PRODUCT_NAME',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_ARTICLE_CODE: {
        key: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        name: $localize`Supplier product article code`,
        filterKey: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      STOCK: {
        key: 'STOCK',
        name: $localize`Stock`,
        filterKey: 'STOCK',
        currency: false,
        containers: false,
      },
      ORDERED: {
        key: 'ORDERED',
        name: $localize`Ordered`,
        filterKey: 'ORDERED',
        currency: false,
        containers: false,
      },
      DELIVERED: {
        key: 'DELIVERED',
        name: $localize`Delivered`,
        filterKey: 'DELIVERED',
        currency: false,
        containers: false,
      },
      EXPECTED_DELIVERY_DATE: {
        key: 'EXPECTED_DELIVERY_DATE',
        name: $localize`Expected delivery date`,
        filterKey: 'EXPECTED_DELIVERY_DATE',
        currency: false,
        containers: false,
      },
      DELIVERED_ON: {
        key: 'DELIVERED_ON',
        name: $localize`Delivered on`,
        filterKey: 'DELIVERED_ON',
        currency: false,
        containers: false,
      },
      RECEIPT: {
        key: 'RECEIPT',
        name: $localize`Receipt`,
        filterKey: 'RECEIPT',
        currency: false,
        containers: false,
        completedOrders: true,
      },
    },
    deliveriesOverviews: {
      BUY_ORDER_ID: {
        key: 'BUY_ORDER_ID',
        name: $localize`Order #`,
        filterKey: 'BUY_ORDER_ID',
        currency: false,
        containers: false,
      },
      SUPPLIER_NAME: {
        key: 'SUPPLIER_NAME',
        name: $localize`Supplier name`,
        filterKey: 'SUPPLIER_NAME',
        currency: false,
        containers: false,
      },
      ORDERED: {
        key: 'ORDERED',
        name: $localize`Ordered products`,
        filterKey: 'ORDERED',
        currency: false,
        containers: false,
      },
      DELIVERED: {
        key: 'DELIVERED',
        name: $localize`Delivered products`,
        filterKey: 'DELIVERED',
        currency: false,
        containers: false,
      },
      DATE: {
        key: 'DATE',
        name: $localize`Placed`,
        filterKey: 'DATE',
        currency: false,
        containers: false,
      },
      EXPECTED_DELIVERY_DATE: {
        key: 'EXPECTED_DELIVERY_DATE',
        name: $localize`Expected delivery date`,
        filterKey: 'EXPECTED_DELIVERY_DATE',
        currency: false,
        containers: false,
      },
      CLOSED_DATE: {
        key: 'CLOSED_DATE',
        name: $localize`Closed date`,
        filterKey: 'CLOSED_DATE',
        currency: false,
        containers: false,
      },
      STATUS: {
        key: 'STATUS',
        name: $localize`Status`,
        filterKey: 'CLOSED_DATE',
        currency: false,
        containers: false,
      },
    },
  },
  tooltips: {
    deliveries: {
      changeDeliveryDate: $localize`Change expected delivery date`,
      changingDeliveryDate: $localize`An expected delivery date is being updated`,
    },
  },
  notifications: {},
  metadata: {
    title: $localize`Deliveries`,
    tabs: {
      overdue: $localize`Overdue orders`,
      expected: $localize`Expected orders`,
      complete: $localize`Completed orders`,
    },
    deliveries: {
      actions: {
        exportAs: $localize`Export as...`,
        closeOrder: $localize`Close order`,
        updatingDeliveryDate: $localize`Updating...`,
        saveChanges: $localize`Save changes`,
      },
      statuses: {
        open: $localize`Open`,
        closed: $localize`Closed`,
      },
    },
    deliveriesEditor: {
      titles: {
        undelivered: {
          title: $localize`Undelivered products`,
          subtitle: $localize`All the products that have not been delivered yet.`,
          editMultiple: $localize`Edit Expected delivery...`,
        },
        delivered: {
          title: $localize`Delivered products`,
          subtitle: $localize`All the products that have been already delivered.`,
        },
      },
    },
    deliveriesOverview: {
      orderInfo: {
        title: $localize`Order info`,
        orderId: $localize`Order #`,
        placed: $localize`Placed date`,
        expected: $localize`Expected delivery date`,
        status: $localize`Status`,
        statuses: {
          overdue: $localize`Overdue`,
          expected: $localize`Expected`,
        },
      },
      supplierInfo: {
        title: $localize`Supplier info`,
        planned: $localize`Planned`,
        type: $localize`Type`,
        triggerValue: $localize`Trigger value`,
        minOrderValue: $localize`Min. order value`,
        reorderPeriod: $localize`Reorder period`,
        leadTime: $localize`Lead time`,
        measuredLeadTime: $localize`Measured lead time (avg.)`,
      },
      notesInfo: {
        title: $localize`Order notes`,
      },
      emailStatus: {
        title: $localize`Email status`,
        events: {
          initiated: $localize`Order placed`,
          processed: $localize`Email sent to supplier`,
          received: $localize`Email received by supplier`,
        },
        emailNotSent: $localize`No email was sent ot the supplier for this order`,
        sendEmail: $localize`Send email`,
        preventWarning: $localize`You can only send an email once every`,
      },
      totals: {
        totalValue: $localize`Order value`,
        totalDeliveredOrdered: $localize`Delivered / Ordered`,
      },
    },
    exportOrder: {
      exportOrder: $localize`Export order`,
      exportDeliveries: $localize`Export deliveries`,
    },
    states: {
      loading: $localize`Loading contents...`,
      noData: $localize`There is no data available.`,
    },
  },
};
