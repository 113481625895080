import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  ViewEncapsulation,
} from '@angular/core';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
} from './model/data-table-v2.model';

@Component({
  selector: 'app-data-table-v2',
  templateUrl: './data-table-v2.component.html',
  styleUrls: ['./data-table-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DataTableV2Component<T> {
  currentPage: Signal<number>;

  totalElements: Signal<number>;

  pageSizeOptions: Signal<number[]>;

  pageSize: Signal<number>;

  selectableColumns: Signal<
    DatatableColumnV2[] | DatatableColumnV2Groups<DatatableColumnV2>
  >;

  displayedColumns: Signal<string[]>;

  datasource: Signal<T>;

  loading: Signal<boolean>;

  refreshDatatable() {
    // intentional
  }

  resetDatatable() {
    // intentional
  }
}
