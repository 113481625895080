import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Router } from '@angular/router';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ConfirmExit } from 'src/app/core/actions/app.action';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import { ExitConfirmationData } from './model/exit-confirmation-dialog.model';
import { STRINGS } from './model/exit-confirmation-dialog.strings';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-exit-confirmation-dialog',
  templateUrl: './exit-confirmation-dialog.component.html',
  styleUrls: ['./exit-confirmation-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExitConfirmationDialogComponent implements OnInit, OnDestroy {
  readonly DIALOG_STRINGS = STRINGS;

  readonly COMMON_STRINGS = MESSAGES.common;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) public data: ExitConfirmationData,
    public dialogRef: DialogRef<ExitConfirmationDialogComponent>,
    private store: Store,
    private actions: Actions,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(ofActionSuccessful(ConfirmExit), takeUntil(this._destroy$))
      .subscribe(() => {
        this.router.navigateByUrl(this.data.desiredPath);

        this.dialogRef?.close();
      });
  }

  confirmExit() {
    this.store.dispatch(new ConfirmExit());
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
