<div [formGroup]="form">
  @for (
    parameter of selectedSupportedSource?.connectedUiParams;
    track parameter.key
  ) {
    <div class="section" (click)="removeWarning()">
      <div class="label-and-tooltip-container">
        <div class="field-title">
          <span>{{ parameter.label }}</span>
        </div>

        <div
          class="label-help"
          appTooltip="{{ parameter.description }}"
          tooltipPosition="right">
          <mat-icon
            svgIcon="questionmark-rounded"
            class="tooltip-icon"></mat-icon>
        </div>
      </div>

      @switch (parameter.type) {
        @case ('boolean') {
          <app-toggle [formControlName]="parameter.key"> </app-toggle>
        }

        @case ('select') {
          <app-dropdown
            type="outline"
            class="ellipsis"
            [formControlName]="parameter.key"
            placeholder="{{ parameter.description }}">
            @for (option of parameter.options; track option.value) {
              <app-dropdown-option [value]="option.value">{{
                option.name
              }}</app-dropdown-option>
            }
          </app-dropdown>
        }

        @case ('multiselect') {
          <app-dropdown multiple [formControlName]="parameter.key">
            @for (option of parameter.options; track option.value) {
              <app-dropdown-option [value]="option.value">{{
                option.name
              }}</app-dropdown-option>
            }
          </app-dropdown>
        }

        @case ('password') {
          <div class="message-topic">
            <input
              appInput
              variant="outline"
              class="ellipsis"
              [id]="parameter.key"
              [formControlName]="parameter.key"
              [type]="fieldsWithPassword.get(parameter.key)"
              placeholder="{{ parameter.description }}" />
            <mat-icon
              (click)="togglePasswordVisibility(parameter.key)"
              class="pass-placeholder"
              [svgIcon]="
                fieldsWithPassword.get(parameter.key) === 'password'
                  ? 'eye-open'
                  : 'eye-closed'
              ">
            </mat-icon>
          </div>
        }

        @case ('text') {
          <div class="message-topic">
            <input
              appInput
              variant="outline"
              class="ellipsis"
              [formControlName]="parameter.key"
              placeholder="{{ parameter.description }}" />
          </div>
        }

        @case ('number') {
          <div class="message-topic">
            <input
              appInput
              variant="outline"
              class="ellipsis"
              type="number"
              min="0"
              [formControlName]="parameter.key"
              placeholder="{{ parameter.description }}" />
          </div>
        }

        @default {
          @if (!parameter.type && parameter.adornment) {
            <div class="message-topic">
              <div appInputWrapper>
                <span appInputPrefix>{{ parameter.adornment.start }}</span>
                <input
                  appInput
                  variant="outline"
                  class="align-center ellipsis"
                  [formControlName]="parameter.key"
                  placeholder="{{ parameter.label }}" />
                <span appInputSuffix>{{ parameter.adornment.end }}</span>
              </div>
            </div>
          } @else {
            <div class="message-topic">
              <input
                appInput
                variant="outline"
                class="ellipsis"
                [formControlName]="parameter.key"
                placeholder="{{ parameter.description }}" />
            </div>
          }
        }
      }
    </div>
  }
</div>
