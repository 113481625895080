import { InjectionToken } from '@angular/core';
import { ToastConfig } from '../core/toast-config';
import { ToastRef } from '../core/toast-ref';

export enum ToastStatus {
  DEFAULT = 'default',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum ToastAnimationStates {
  VOID = 'void',
  VISIBLE = 'visible',
  VISIBLE_INVERT = 'visible-invert',
  HIDDEN = 'hidden',
}

export interface ToastDismiss {
  dismissedByAction: boolean;
}

export interface TextOnlyToastData {
  message: string;
  action: string;
  status?: ToastStatus;
}

export interface TextOnlyToast {
  data: TextOnlyToastData;
  toastRef: ToastRef<TextOnlyToast>;
  action: () => void;
  hasAction: boolean;
}

export function TOAST_DEFAULT_CONFIG_FACTORY(): ToastConfig {
  return new ToastConfig();
}

/** Injection token that can be used to specify default snack bar. */
export const TOAST_DEFAULT_CONFIG = new InjectionToken<ToastConfig>(
  'toast-default-options',
  {
    providedIn: 'root',
    factory: TOAST_DEFAULT_CONFIG_FACTORY,
  }
);
