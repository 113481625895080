import {
  Directive,
  EventEmitter,
  Output,
  TemplateRef,
  booleanAttribute,
  computed,
  contentChild,
  input,
  signal,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { ColumnFilterParam } from '../../model/data-table-v2.model';
import { ColumnFilter } from './model/filter.model';
import { FilterEmitted } from './model/filter-v2.model';

@Directive({
  selector: '[appFilterV2]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FilterV2Component {
  headerTemplate = contentChild<TemplateRef<unknown>>('headerTemplate');

  footerTemplate = contentChild<TemplateRef<unknown>>('footerTemplate');

  defaultName = input(false, {
    transform: booleanAttribute,
  });

  disabled = input(false, {
    transform: booleanAttribute,
  });

  filter = input<ColumnFilter | null>(null);

  filterValues = computed(() => this.filter()?.params || null);

  filterName = computed(() => {
    if (this.defaultName()) {
      return this.FILTER_STRINGS.filterBy;
    }

    if (this.filter()?.unit) {
      return `${this.filter()?.name} (${this.filter()?.unit})`;
    }

    return this.filter()?.name;
  });

  filterDisabled = computed(() => this.filter()?.disabled || this.disabled());

  showApplyAll = computed(() => {
    if (this.filter()) return this.filter().sharedFilter;

    return false;
  });

  @Output()
  filtered: EventEmitter<ColumnFilterParam> =
    new EventEmitter<ColumnFilterParam>();

  @Output()
  canceled: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  stateChanged: EventEmitter<void> = new EventEmitter<void>();

  private readonly FILTER_STRINGS = MESSAGES.common.filter;

  clear(): void {
    // intended
  }

  cancel(): void {
    // intended
  }

  apply(_filtered?: FilterEmitted): void {
    // intended
  }

  resetForm(): void {
    // intended
  }

  _resetFilter(columnKey: string): void {
    this.filtered.emit({
      columnKey,
      optionSelected: null,
      subOperator: null,
      values: null,
      applyAll: false,
    });
  }
}
