import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
  ScrollDispatcher,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ComponentRef,
  DestroyRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  TemplateRef,
  ViewContainerRef,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';
import { TooltipComponent } from '../tooltip.component';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  TOOLTIP_DEFAULT_OPTIONS,
  TOOLTIP_SCROLL_STRATEGY,
  TooltipDefaultOptions,
  TooltipOverlayOrigin,
  TooltipPosition,
} from '../model/tooltip.model';
import { Directionality } from '@angular/cdk/bidi';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, fromEvent } from 'rxjs';
import {
  addOffset,
  getOrigin,
  getOverlayPosition,
} from '../core/tooltip-overlay';
import { Platform } from '@angular/cdk/platform';
import { TooltipBase } from '../core/tooltip-base';
import { TooltipContainerComponent } from '../components/tooltip-container/tooltip-container.component';

function transformTooltipMessage(
  message: string | null | undefined
): string | null {
  if (!message) return null;

  return String(message).trim();
}

@Directive({
  selector: '[appTooltip]',
  exportAs: 'tooltip',
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  _overlayRef: OverlayRef | null;

  _tooltipInstance: TooltipBase | null;

  @Input({ alias: 'tooltipPosition' })
  get position(): TooltipPosition {
    return this._position;
  }
  set position(newPosition: TooltipPosition) {
    if (newPosition === this.position) return;

    this._position = newPosition;

    if (this._overlayRef) {
      this._updatePosition(this._overlayRef);
      this._tooltipInstance?.show(0);
      this._overlayRef.updatePosition();
    }
  }
  private _position: TooltipPosition = 'above';

  @Input({ alias: 'tooltipDisabled', transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;

    if (this._disabled) {
      this.hide(0);
    }
  }
  private _disabled: boolean = false;

  @Input({ alias: 'tooltipShowDelay', transform: numberAttribute })
  get showDelay(): number {
    return this._showDelay;
  }
  set showDelay(delay: number) {
    this._showDelay = delay;
  }
  private _showDelay: number;

  @Input({ alias: 'tooltipHideDelay', transform: numberAttribute })
  get hideDelay(): number {
    return this._hideDelay;
  }
  set hideDelay(delay: number) {
    this._hideDelay = delay;

    if (this._tooltipInstance) {
      this._tooltipInstance.hideDelay = this._hideDelay;
    }
  }
  private _hideDelay: number;

  @Input()
  get tooltipClass(): string | string[] {
    return this._tooltipClass;
  }
  set tooltipClass(classes: string | string[]) {
    this._tooltipClass = classes;

    if (this._tooltipInstance) {
      this._setTooltipClass(this._tooltipClass);
    }
  }
  private _tooltipClass: string | string[];

  @Input({
    alias: 'appTooltip',
    transform: transformTooltipMessage,
  })
  get message(): string {
    return this._message;
  }
  set message(message: string | null) {
    this._message = message;

    if (!message && this.isTooltipVisible()) {
      this.hide(0);
    } else {
      this._updateTooltipMessage();
    }
  }
  private _message: string;

  @Input()
  template: any;

  @Input({ transform: numberAttribute })
  maxWidth: number;

  @Input({ transform: booleanAttribute })
  tooltipShouldCheckOverflow: boolean = false;

  private _portal: ComponentPortal<TooltipBase> | TemplatePortal<TooltipBase>;

  private readonly _tooltipComponent = TooltipComponent;

  private readonly _tooltipContainerComponent = TooltipContainerComponent;

  private _scrollStrategy: () => ScrollStrategy;

  private _viewportMargin = 8;

  private _currentPosition: TooltipPosition;

  constructor(
    private destroyRef: DestroyRef,
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef,
    private platform: Platform,
    private ngZone: NgZone,
    @Inject(TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
    protected dir: Directionality,
    @Optional()
    @Inject(TOOLTIP_DEFAULT_OPTIONS)
    private defaultOptions: TooltipDefaultOptions
  ) {
    this._scrollStrategy = scrollStrategy;

    if (this.defaultOptions) {
      this._applyDefaultOptions(this.defaultOptions);
    }

    this.dir.change
      .pipe(
        filter(() => !!this._overlayRef),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this._updatePosition(this._overlayRef);
      });
  }

  ngAfterViewInit(): void {
    fromEvent(this.elementRef?.nativeElement, 'mouseenter')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.handleMouseEnter();
      });

    fromEvent(this.elementRef?.nativeElement, 'mouseleave')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: MouseEvent) => {
        this.handleMouseLeave(event);
      });
  }

  handleMouseEnter(): void {
    if (!this._platformSupportsMouseEvents()) return;

    if (this.tooltipShouldCheckOverflow && !this.disabled) {
      this.disabled = !this._checkOverflow(this.elementRef.nativeElement);
    }

    this.show();
  }

  handleMouseLeave(event: MouseEvent): void {
    if (!this._tooltipInstance) return;

    this._tooltipInstance.handleMouseLeave(event);
  }

  show(delay: number = this.showDelay, origin?: TooltipOverlayOrigin): void {
    if (
      this.disabled ||
      (!this.message && !this.template) ||
      this.isTooltipVisible()
    ) {
      this._tooltipInstance?.clearAnimations();
      return;
    }

    this._detach();

    const instance = (this._tooltipInstance = this._attach(
      this.template || this._tooltipComponent,
      !!this.template,
      origin
    ));

    instance.triggerElement = this.elementRef.nativeElement;
    instance.hideDelay = this._hideDelay;
    instance.maxWidth = this.maxWidth;
    instance
      .afterHidden()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this._detach());

    if (!this.template) {
      this._setTooltipClass(this._tooltipClass);

      this._updateTooltipMessage();
    } else {
      this._tooltipInstance.markForCheck();
    }

    instance.show(delay);
  }

  hide(delay: number = this.hideDelay): void {
    const instance = this._tooltipInstance;

    if (instance?.isVisible()) {
      instance.hide(delay);
      instance.clearAnimations();
      this._detach();
    }
  }

  /** Shows/hides the tooltip */
  toggle(origin?: TooltipOverlayOrigin): void {
    this.isTooltipVisible() ? this.hide() : this.show(undefined, origin);
  }

  isTooltipVisible(): boolean {
    return !!this._tooltipInstance && this._tooltipInstance.isVisible();
  }

  private _attachTooltipContainer(
    overlayRef: OverlayRef
  ): TooltipContainerComponent {
    const containerPortal = new ComponentPortal(
      this._tooltipContainerComponent,
      this.viewContainerRef
    );

    const containerRef: ComponentRef<TooltipContainerComponent> =
      overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private _detach() {
    if (this._overlayRef?.hasAttached()) {
      this._overlayRef.detach();
    }

    this._tooltipInstance = null;
  }

  private _attach(
    content: ComponentType<any> | TemplateRef<any>,
    custom: boolean,
    origin?: TooltipOverlayOrigin
  ): TooltipBase {
    const overlayRef = this._createOverlay(origin);

    if (!custom) {
      this._portal =
        this._portal ||
        new ComponentPortal(this._tooltipComponent, this.viewContainerRef);

      return overlayRef.attach(this._portal).instance;
    }

    const containerInstance = this._attachTooltipContainer(overlayRef);

    if (content instanceof TemplateRef) {
      this._portal =
        this._portal || new TemplatePortal(content, this.viewContainerRef);

      const instance = containerInstance;

      containerInstance.attachTemplatePortal(
        this._portal as TemplatePortal<TooltipBase>
      );

      return instance;
    } else {
      this._portal =
        this._portal || new ComponentPortal(content, this.viewContainerRef);

      const instance = containerInstance;

      containerInstance.attachComponentPortal(
        this._portal as ComponentPortal<TooltipBase>
      );

      return instance;
    }
  }

  private _createOverlay(origin?: TooltipOverlayOrigin): OverlayRef {
    if (this._overlayRef) {
      const existingStrategy = this._overlayRef.getConfig()
        .positionStrategy as FlexibleConnectedPositionStrategy;

      if (!origin && existingStrategy._origin instanceof ElementRef) {
        return this._overlayRef;
      }

      this._detach();
    }

    const scrollableAncestors =
      this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withTransformOriginOn('.tooltip')
      .withFlexibleDimensions(false)
      .withViewportMargin(this._viewportMargin)
      .withScrollableContainers(scrollableAncestors);

    strategy.positionChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(change => {
        this._updateCurrentPositionClass(change.connectionPair);

        if (
          this._tooltipInstance &&
          change.scrollableViewProperties.isOverlayClipped &&
          this._tooltipInstance.isVisible()
        ) {
          this.ngZone.run(() => this.hide(0));
        }
      });

    this._overlayRef = this.overlay.create({
      direction: this.dir,
      positionStrategy: strategy,
      scrollStrategy: this._scrollStrategy(),
    });

    this._updatePosition(this._overlayRef);

    this._overlayRef
      .detachments()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this._detach());

    this._overlayRef
      .outsidePointerEvents()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this._tooltipInstance?.handlePageInteraction());

    this._overlayRef
      .keydownEvents()
      .pipe(
        filter(event => this.isTooltipVisible() && event.key === 'Escape'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => {
        event.preventDefault();
        event.stopPropagation();

        this.ngZone.run(() => this.hide(0));
      });

    if (this.defaultOptions?.disableTooltipInteractivity) {
      this._overlayRef.addPanelClass('tooltip-non-interactive');
    }

    return this._overlayRef;
  }

  private _updatePosition(overlayRef: OverlayRef) {
    const position = overlayRef.getConfig()
      .positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = getOrigin(this.position, this.dir);
    const overlay = getOverlayPosition(this.position, this.dir);

    position.withPositions([
      addOffset({ ...origin.main, ...overlay.main }, this.dir),
      addOffset({ ...origin.fallback, ...overlay.fallback }, this.dir),
    ]);
  }

  private _updateCurrentPositionClass(
    connectionPair: ConnectionPositionPair
  ): void {
    const { overlayY, originX, originY } = connectionPair;
    let newPosition: TooltipPosition;

    if (overlayY === 'center') {
      if (this.dir && this.dir.value === 'rtl') {
        newPosition = originX === 'end' ? 'left' : 'right';
      } else {
        newPosition = originX === 'start' ? 'left' : 'right';
      }
    } else {
      newPosition =
        overlayY === 'bottom' && originY === 'top' ? 'above' : 'below';
    }

    if (newPosition !== this._currentPosition) {
      const overlayRef = this._overlayRef;

      if (overlayRef) {
        overlayRef.removePanelClass(this._currentPosition);
        overlayRef.addPanelClass(newPosition);
      }

      this._currentPosition = newPosition;
    }
  }

  private _setTooltipClass(tooltipClass: string | string[]) {
    if (this._tooltipInstance) {
      this._tooltipInstance.tooltipClass.set(tooltipClass);
    }
  }

  private _updateTooltipMessage() {
    if (!this._tooltipInstance) return;

    this._tooltipInstance.message = this.message;
    this._tooltipInstance.markForCheck();

    this.ngZone.onMicrotaskEmpty
      .pipe(
        filter(() => !!this._tooltipInstance),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this._overlayRef.updatePosition();
      });
  }

  private _applyDefaultOptions(options: TooltipDefaultOptions): void {
    this._showDelay = options.showDelay;
    this.hideDelay = options.hideDelay;
    this.maxWidth = options.maxWidth;

    if (options.position) {
      this.position = options.position;
    }

    if (options.checkOverflow) {
      this.tooltipShouldCheckOverflow = options.checkOverflow;
    }
  }

  private _platformSupportsMouseEvents() {
    return !this.platform.IOS && !this.platform.ANDROID;
  }

  private _checkOverflow(element: HTMLElement) {
    return element.offsetWidth < element.scrollWidth;
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._tooltipInstance = null;
    }
  }
}
