<div class="nav-toolbar-container">
  <div class="nav-toolbar">
    <div class="nav-toolbar-content">
      <!-- Title and Metadata -->
      <div class="nav-routing-container">
        <div class="nav-routing-info">
          <span
            [class]="routing ? 'nav-title-routing' : 'nav-title'"
            (click)="navigate()"
            >{{ titleName }}</span
          >

          <mat-icon
            *ngIf="routing"
            svgIcon="angle-right"
            class="separator-icon"></mat-icon>

          <span
            class="nav-subtitle"
            [appTooltip]="subTitle"
            [tooltipShowDelay]="300"
            tooltipShouldCheckOverflow
            >{{ subTitle }}</span
          >

          <app-top-overview-icon *ngIf="showOverview"></app-top-overview-icon>

          <!-- Extra Info -->
          <div class="extra-info">
            <ng-content select="[extraInformation]"></ng-content>
          </div>
        </div>

        <!-- Dynamic Content (Actions) -->
        <div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
