import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DialogContainerComponent {
  @ContentChild('dialogIcon')
  icon: any;
}
