import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, distinctUntilChanged, filter, map, skip } from 'rxjs';

/** Reusable ActivatedRoute pipe to check wether a productUuid has changed.
 *
 * It checks for a NOT NULL, distinct productUuid and it's unsubscribed automatically.
 *
 * NOTE: We just want to use this when we want to perform an action in the same page but
 * with a different product.
 *
 * Currently used in:
 * - Product details page
 * - Product suppliers tab
 * - Product deliveries tab
 * - Product promotions tab
 * - Product compositions tab
 * - Product parts tab
 */
export const getRedirectedProductUuid = (): Observable<string> =>
  inject(ActivatedRoute).queryParamMap.pipe(
    map((params: ParamMap) => params.get('productUuid')),
    filter(Boolean),
    distinctUntilChanged(),
    skip(1),
    takeUntilDestroyed()
  );

export interface SelectedWebshopProduct {
  uuid: string;
  id: number;
  assembled: boolean;
  ignored: boolean;
  notBeingBought: boolean;
}

export interface WebshopProductSettings {
  notBeingBought: boolean;
  notBeingBoughtHasEndDate: boolean;
  minPurchaseDate: string;
  resumePurchaseDate: string;
  minimumStockLevel: number;
  maximumStock: number;
  ignored: boolean;
  manualServiceLevel: number;
  notes?: string;
}
