<div class="basic-filter">
  <mat-icon svgIcon="filter-outline" class="filter-icon"></mat-icon>

  <div class="basic-filter-edit-area">
    <div class="basic-filter-input-container">
      <input
        autocapitalize="none"
        autocomplete="off"
        class="basic-filter-input"
        role="textbox"
        spellcheck="false"
        [attr.placeholder]="placeholder"
        aria-label="Filter text"
        (keypress)="onFilterKeyInput($event)"
        [class.basic-filter-input-empty]="currentFilteredText.length === 0"
        [textContent]="currentFilteredText"
        (input)="onInput($event)" />
    </div>
  </div>

  <button
    aria-label="Basic filter button"
    aria-label="Clear"
    (click)="onClear()"
    appButton
    appButtonIcon
    variant="link"
    transparent>
    <mat-icon svgIcon="cross" class="clear-icon"></mat-icon>
  </button>
</div>
