import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

export class FilterSuppliers {
  static readonly type = '[Purchase] Filter Suppliers';

  constructor(public search: string) {}
}

export class ResetSuppliers {
  static readonly type = '[Purchase] Reset Suppliers';
}

export class ReconnectWebsocket {
  static readonly type = '[Purchase] Reconnect WebSocket';
}

export class HeaderInfoPersistence {
  static readonly type = '[Purchase] Header Info OpenClose';
}

export class ToggleSharedFilter {
  static readonly type = '[Purchase Overviews Shared] Toggle Shared Filter';
}

export class AddSharedSearchParam {
  static readonly type = '[Purchase Overviews Shared] Add Shared Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSharedSearchParam {
  static readonly type =
    '[Purchase Overviews Shared] Remove Shared Search Param';

  constructor(public param: string | null) {}
}

export class AddSharedFilterParam {
  static readonly type = '[Purchase Overviews Shared] Add Shared Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class RemoveAllSharedFilters {
  static readonly type =
    '[Purchase Overviews Shared] Remove All Shared Filters';
}
