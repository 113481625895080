<div class="container">
  <div *ngIf="isOverlay" class="title">
    {{ PROMOTIONS_METADATA.title }}
  </div>

  <div [class.datatable-spacing]="!isOverlay">
    @if (viewOnly$ | async) {
      <app-product-promotions-v2-data-table-view-only
        [webshopProductUuid]="productDetails?.uuid"
        [readOnly]="readOnly"></app-product-promotions-v2-data-table-view-only>
    } @else {
      <app-product-promotions-v2-data-table
        [webshopProductUuid]="productDetails?.uuid"
        [dataForm]="dataForm"
        [readOnly]="readOnly"></app-product-promotions-v2-data-table>
    }
  </div>
</div>
