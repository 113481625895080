<div
  class="action-bar-v2-container"
  [class.action-bar-v2-container-expanded]="expanded">
  <div class="left-action-container">
    <ng-content select="[left-side], [leftSide]"></ng-content>
  </div>

  <div class="right-action-container">
    <ng-content select="[right-side], [rightSide]"></ng-content>
  </div>
</div>
