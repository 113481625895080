import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import {
  CheckFactor,
  EnlargeOrderSize,
} from './actions/order-enlarger-v2.actions';

@Component({
  selector: 'app-order-enlarger-v2',
  templateUrl: './order-enlarger-v2.component.html',
  styleUrls: ['./order-enlarger-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEnlargerV2Component {
  @Input()
  factor: number;

  @Input({ transform: booleanAttribute })
  loading: boolean;

  @Input({ transform: booleanAttribute })
  disabled: boolean;

  readonly STRINGS = STRINGS.metadata.purchaseOverview.factor;

  readonly TOOLTIPS = STRINGS.tooltips.purchaseEditor.factor;

  constructor(private store: Store) {}

  checkFactor(factor: number): void {
    this.store.dispatch(new CheckFactor(factor));
  }

  enlargeOrder(factor: number): void {
    this.store.dispatch(new EnlargeOrderSize(factor));
  }
}
