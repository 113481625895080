import { Injectable } from '@angular/core';
import {
  PromotionUpliftTypes,
  WebshopProductPromotionProperties,
  WebshopProductProperties,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';

export interface BuildCreatePromotionWebshopProductFromResult {
  products: WebshopProductProperties[];
}

@Injectable({
  providedIn: 'root',
})
export class AddProductPromotionsBuilderService {
  buildProductPromotionsUpdateFromResult(
    promotions: WebshopProductPromotionProperties[],
    webshopProductUUID: string
  ): BuildCreatePromotionWebshopProductFromResult {
    const addedPromotion: WebshopProductProperties[] = promotions.map(
      promotion => {
        if (promotion.specificUpliftType === PromotionUpliftTypes.NO_UPLIFT) {
          return {
            uuid: webshopProductUUID,
            promotionUUID: promotion.promotion.uuid,
          };
        }

        return {
          uuid: webshopProductUUID,
          promotionUUID: promotion.promotion.uuid,
          specificUplift: {
            type: promotion.specificUpliftType,
            increase:
              promotion.specificUpliftType === PromotionUpliftTypes.CLOSE_OUT
                ? 0
                : Number(promotion.specificUpliftIncrease),
          },
        };
      }
    );

    return { products: addedPromotion };
  }
}
