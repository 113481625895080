<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <ng-container
      *ngIf="editing() === false && isPopout() === false; else editingActions">
      <button
        *ngIf="haveUnassociatedPromotions()"
        data-intercom-target="9c948367-4dda-48de-b74c-45f5e45fe66d"
        aria-label="Product details promotions table add new button"
        appButton
        variant="secondary"
        [disabled]="loading() || readOnly"
        (click)="addPromotion()">
        {{ TABLE_METADATA.actions.addNew }}
      </button>

      <button
        *ngIf="havePromotions()"
        data-intercom-target="3b6bcc22-8673-49e9-aaf2-9bb3a924f6ad"
        aria-label="Product details promotions table edit button"
        appButton
        variant="secondary"
        [disabled]="loading() || readOnly"
        (click)="editPromotions()">
        {{ TABLE_METADATA.actions.edit }}
      </button>
    </ng-container>

    <ng-template #editingActions>
      <ng-container *ngIf="isPopout() === false">
        <button
          data-intercom-target="597830df-8657-473b-9a14-af8a7e0028e6"
          aria-label="Product details promotions table save button"
          appButton
          variant="secondary"
          [disabled]="
            haveEditedPromotions() === false ||
            saving() ||
            loading() ||
            dataForm.formSource.shared.invalid
          "
          (click)="saveEditedPromotions()">
          {{ COMMON_STRINGS.save }}
        </button>

        <button
          aria-label="Product details promotions table cancel button"
          data-intercom-target="7326a23c-fd1d-496a-b5ba-469a9c120019"
          appButton
          variant="tertiary"
          [disabled]="saving() || loading()"
          (click)="cancelEditPromotions()">
          {{ COMMON_STRINGS.cancel }}
        </button>
      </ng-container>
    </ng-template>

    <app-data-table-refresh-button
      data-intercom-target="Refresh table data button"
      aria-label="Refresh table data button"
      [loading]="loading()"
      [disabled]="loading() || !canRefresh()"
      (refreshed)="refreshDatatable()" />

    <div class="vertical-separator"></div>

    <button
      *ngIf="exportsEnabled() && isPopout() === false"
      data-intercom-target="6d83181d-0b37-44fc-a24d-9190b98588c3"
      aria-label="Product details promotions table export button"
      appButton
      appButtonIcon
      variant="link"
      transparent
      class="export-button"
      [appTooltip]="COMMON_STRINGS.exportAs"
      (click)="openExportsDialog()">
      <mat-icon svgIcon="arrow-download" class="gray"></mat-icon>
    </button>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="filterOpened()">
  <app-filter-group [filters]="activeFilters()" (filtered)="onFiltered($event)">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"></app-add-filter-button>
  </app-filter-group>
</app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <form [formGroup]="dataForm.formSource.shared">
    <table
      cdk-table
      [dataSource]="datasource()"
      appFilterableV2
      [disableHideColumn]="lastDisplayedColumn()"
      (sorted)="onSort($event)"
      (hidden)="onColumnHidden($event)"
      (filtered)="onFiltered($event)">
      <!-- Name -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.NAME.key">
        <th
          data-intercom-target="2259e47f-7a93-4cd7-a9e5-3d53daee9f21"
          aria-label="Product details promotions table Name column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.NAME.key | filterColumn: RELATED_STATE | async
          ">
          {{ TABLE_COLUMNS.NAME.name }}
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="column-strong restricted-column">
          <ng-container
            *ngIf="element.promotion.name !== null; else notAvailable">
            <p
              class="deep-linking-text"
              (click)="goToPromotionDetails(element.promotion.uuid)"
              [appTooltip]="element.promotion.name"
              tooltipShouldCheckOverflow>
              <app-copy-to-clipboard
                [message]="element.promotion.name"></app-copy-to-clipboard>
            </p>
          </ng-container>
        </td>
      </ng-container>

      <!-- Start date -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.START_DATE.key">
        <th
          data-intercom-target="b740b2a2-8e1d-4121-8f7b-00370c5301d7"
          aria-label="Product details promotions table Start date column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.START_DATE.key | filterColumn: RELATED_STATE | async
          ">
          {{ TABLE_COLUMNS.START_DATE.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <ng-container
            *ngIf="
              element.promotion.interval.start !== null;
              else notAvailable
            ">
            <span>{{ element.promotion.interval.start | date }}</span>
          </ng-container>
        </td>
      </ng-container>

      <!-- End date -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.END_DATE.key">
        <th
          data-intercom-target="2ce8803c-6e68-4b2a-8ec0-caa13bba6a7c"
          aria-label="Product details promotions table End date column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.END_DATE.key | filterColumn: RELATED_STATE | async
          ">
          {{ TABLE_COLUMNS.END_DATE.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <ng-container
            *ngIf="element.promotion.interval.end !== null; else notAvailable">
            <span>{{ element.promotion.interval.end | date }}</span>
          </ng-container>
        </td>
      </ng-container>

      <!-- Expected uplift -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.EXPECTED_UPLIFT.key">
        <th
          data-intercom-target="543f5db9-af55-437b-84e5-b697366afe35"
          aria-label="Product details promotions table Expected uplift column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.EXPECTED_UPLIFT.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.EXPECTED_UPLIFT.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <ng-container
            *ngIf="
              element.promotion.uplift.increase !== null;
              else notAvailable
            ">
            <ng-container
              *ngIf="element.promotion.uplift.type === UPLIFT_TYPES.RELATIVE">
              <ng-container
                *ngTemplateOutlet="
                  relative;
                  context: { $implicit: element.promotion.uplift.increase }
                "></ng-container>
            </ng-container>

            <ng-container
              *ngIf="element.promotion.uplift.type === UPLIFT_TYPES.ABSOLUTE">
              <ng-container
                *ngTemplateOutlet="
                  absolute;
                  context: { $implicit: element.promotion.uplift.increase }
                "></ng-container>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- Product uplift type -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.PRODUCT_UPLIFT_TYPE.key">
        <th
          data-intercom-target="a428bc3a-b250-44aa-bfd8-928e788c1d7b"
          aria-label="Product details promotions table Product uplift type column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.PRODUCT_UPLIFT_TYPE.key
              | filterColumn: RELATED_STATE
              | async
          ">
          {{ TABLE_COLUMNS.PRODUCT_UPLIFT_TYPE.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          @if (editing() === false) {
            <ng-container
              *ngIf="
                element.webshopProduct.specificUplift !== null;
                else notAvailable
              ">
              <span>{{ element.extras.formattedUpliftType }}</span>
            </ng-container>
          } @else {
            <div
              data-intercom-target="5d4249d8-8ebf-49b6-ba4b-7806c26b68ea"
              aria-label="Product details promotions table Product uplift type setting"
              class="input-container"
              [formGroupName]="element.promotion.uuid">
              <div class="fields-input-container uplift-type">
                <app-dropdown
                  name="specificUpliftType"
                  formControlName="specificUpliftType"
                  (selectionChange)="
                    updateWebshopProductUpliftType(element, $event)
                  ">
                  <app-dropdown-option
                    *ngFor="let upliftType of UPLIFT_TYPES_OPTIONS"
                    [value]="upliftType.value"
                    >{{ upliftType.key }}</app-dropdown-option
                  >
                </app-dropdown>
              </div>
            </div>
          }
        </td>
      </ng-container>

      <!-- Product uplift increase -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.PRODUCT_UPLIFT.key">
        <th
          data-intercom-target="4c63fba7-4c01-49b0-a2fe-04f80479c4da"
          aria-label="Product details promotions table Product uplift increase column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.PRODUCT_UPLIFT.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.PRODUCT_UPLIFT.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <ng-container *ngIf="editing() === false">
            <ng-container
              *ngIf="
                element.webshopProduct.specificUplift !== null;
                else notAvailable
              ">
              <ng-container
                *ngIf="
                  element.webshopProduct.specificUplift.type ===
                  UPLIFT_TYPES.RELATIVE
                ">
                <ng-container
                  *ngTemplateOutlet="
                    relative;
                    context: {
                      $implicit: element.webshopProduct.specificUplift.increase
                    }
                  "></ng-container>
              </ng-container>

              <ng-container
                *ngIf="
                  element.webshopProduct.specificUplift.type ===
                  UPLIFT_TYPES.ABSOLUTE
                ">
                <ng-container
                  *ngTemplateOutlet="
                    absolute;
                    context: {
                      $implicit: element.webshopProduct.specificUplift.increase
                    }
                  "></ng-container>
              </ng-container>

              <ng-container
                *ngIf="
                  element.webshopProduct.specificUplift.type !==
                    UPLIFT_TYPES.RELATIVE &&
                  element.webshopProduct.specificUplift.type !==
                    UPLIFT_TYPES.ABSOLUTE
                ">
                <ng-container *ngTemplateOutlet="notAvailable"></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              editing() &&
              dataForm.formSource?.shared
                ?.get(element.promotion.uuid)
                ?.get('specificUpliftType').value !== UPLIFT_TYPES.CLOSE_OUT &&
              dataForm.formSource?.shared
                ?.get(element.promotion.uuid)
                ?.get('specificUpliftType').value !== UPLIFT_TYPES.NO_UPLIFT
            ">
            <div
              data-intercom-target="e9ef0a16-4a92-4e8e-a4f0-22458d498c4d"
              aria-label="Product details promotions table Product uplift increase setting"
              class="input-container"
              [formGroupName]="element.promotion.uuid">
              <div class="fields-input-container uplift-increase">
                <div
                  *ngIf="
                    dataForm.formSource.shared
                      .get(element.promotion.uuid)
                      .get('specificUpliftIncrease').errors;
                    let errors
                  "
                  class="error-container">
                  <mat-icon
                    svgIcon="warning-rounded"
                    aria-hidden="false"
                    *ngIf="errors.required"
                    [appTooltip]="TABLE_TOOLTIPS.required"
                    class="warning-icon">
                  </mat-icon>
                </div>

                <input
                  appInput
                  variant="filled"
                  error="oninit"
                  type="number"
                  class="align-right"
                  name="specificUpliaftIncrease"
                  formControlName="specificUpliftIncrease"
                  (change)="
                    updateWebshopProductUpliftIncrease(
                      element,
                      $event.target.value
                    )
                  "
                  placeholder="-" />
              </div>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <!--Number of products -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.NUMBER_OF_PRODUCTS.key">
        <th
          data-intercom-target="ba31ca89-88fb-4277-a090-e1d665f14573"
          aria-label="Product details promotions table Number of products column"
          cdk-header-cell
          *cdkHeaderCellDef
          [appFilterColumnHeaderV2]="
            TABLE_COLUMNS.NUMBER_OF_PRODUCTS.key
              | filterColumn: RELATED_STATE
              | async
          "
          class="align-right">
          {{ TABLE_COLUMNS.NUMBER_OF_PRODUCTS.name }}
        </th>
        <td cdk-cell *cdkCellDef="let element" class="align-right">
          <ng-container
            *ngIf="
              element.promotion.numberOfProducts !== null;
              else notAvailable
            ">
            <span>{{
              element.promotion.numberOfProducts | customDecimal
            }}</span>
          </ng-container>
        </td>
      </ng-container>

      <!-- Action -->
      <ng-container [cdkColumnDef]="TABLE_COLUMNS.ACTIONS.key" stickyEnd>
        <th
          data-intercom-target="c6b0a79d-8130-4b37-a09b-6c7e663e34b8"
          aria-label="Product details promotions table Actions column"
          cdk-header-cell
          *cdkHeaderCellDef
          class="actions-column fit-to-value"></th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="actions-container fit-to-value"
          (click)="$event.stopPropagation()">
          <div class="actions-options">
            <button
              data-intercom-target="5477c4e2-8cf8-4be6-bdd1-2666d4cdbc04"
              aria-label="Product details promotions table actions options remove button"
              appButton
              variant="danger"
              [disabled]="saving()"
              (click)="
                removePromotion(
                  element.promotion.uuid,
                  element.webshopProduct.uuid
                )
              ">
              {{ TABLE_METADATA.actions.remove }}
            </button>
          </div>
        </td>
      </ng-container>

      <tr
        cdk-header-row
        *cdkHeaderRowDef="displayedColumns()"
        class="datatable-headers"></tr>
      <tr
        data-intercom-target="fc6d5e7c-c7e0-4066-abae-d8b6a65365cb"
        aria-label="Product details promotions table row"
        cdk-row
        *cdkRowDef="let row; columns: displayedColumns()"
        class="datatable-rows"
        [class.disabled-promotion]="!row.promotion.settings.enabled"></tr>
    </table>
  </form>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>

<ng-template #absolute let-upliftIncrease>
  {{ upliftIncrease | customDecimal }}
  <span class="no-percentage">%</span>
</ng-template>

<ng-template #relative let-upliftIncrease>
  {{ upliftIncrease | customDecimal }}
  <span class="gray">%</span>
</ng-template>
