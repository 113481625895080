export default class InitialData {
  private initialDataState: Map<string, any>;

  constructor() {
    this.initialDataState = new Map();
  }

  /**
   * Sets the initial data state with its component's key
   * @param hash component key (PendingChangesKeys)
   * @param value data (form values, local values, etc.)
   */
  protected addInitialData(hash: string, value: Map<string, any>) {
    this.initialDataState.set(hash, value);
  }

  /**
   * Gets all initial data from the state
   * @returns all data
   */
  protected getAllInitialData(): any {
    return this.initialDataState.entries();
  }

  /**
   * Gets initial data from its hash
   * @param hash component key
   * @returns data relative to its hash
   */
  protected getInitialData(hash: string): any | null {
    if (hash && this.initialDataState.has(hash)) {
      return this.initialDataState.get(hash);
    }

    return null;
  }

  /**
   * Gets initial data size
   * @returns data size
   */
  protected getInitialDataSize(): number {
    return this.initialDataState.size;
  }

  /**
   * Resets hash's initial data by deleting old set of data and adding a new one
   * @param hash component key
   * @param value data (form values, local values, etc.)
   */
  protected resetInitialData(hash: string, value: any): void {
    this.deleteInitialData(hash);
    this.addInitialData(hash, value);
  }

  /**
   * Deletes initial data
   * @param hash component key
   * @returns true if hash is found and deleted successfully
   */
  protected deleteInitialData(hash: string): boolean {
    if (this.initialDataState.has(hash)) {
      return this.initialDataState.delete(hash);
    }

    return false;
  }

  /**
   * Deletes all initial data
   */
  protected deleteAllInitialData(): void {
    this.initialDataState.clear();
  }
}
