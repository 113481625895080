import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  computed,
  signal,
  viewChild,
} from '@angular/core';
import { FilterOption } from '../../model/filter.model';
import {
  FILTER,
  FILTER_FORM,
  FilterBase,
  FilterEmitted,
  FilterEqualsGroupV2,
  FilterRangeGroupV2,
  FilterStrategy,
  FilterTypeOptionsV2,
  FilteringStrategies,
} from '../../model/filter-v2.model';
import { FilterStrategiesHandler } from '../core/filter-strategies-handler';
import {
  defaultFilterOptions,
  filterOptionsWithEmpty,
} from '../filter-type-options-v2/model/filter-type-options-v2.model';
import { FilterV2Component } from '../../filter-base-v2';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-filter-date',
  exportAs: 'filterDate',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: FILTER, useExisting: FilterDateComponent }],
})
export class FilterDateComponent
  extends FilterV2Component
  implements
    FilterBase,
    FilteringStrategies<FilterEqualsGroupV2 | FilterRangeGroupV2>
{
  filterForm = viewChild<NgForm>(FILTER_FORM);

  filterStrategiesHandler = new FilterStrategiesHandler();

  filterOptions = computed<FilterOption[]>(() => {
    if (this.filter()?.nullable) return filterOptionsWithEmpty;

    return defaultFilterOptions;
  });

  selectedOption = signal<FilterTypeOptionsV2 | null>(null);

  filterStrategy: FilterStrategy<FilterEqualsGroupV2 | FilterRangeGroupV2>;

  hasSameOptionSelected = computed(
    () => this.filterValues()?.operator === this.selectedOption()
  );

  readonly FILTER_TYPE_OPTIONS = FilterTypeOptionsV2;

  selectOption(option: FilterTypeOptionsV2): void {
    this.selectedOption.set(option);

    this.filterStrategy = this.filterStrategiesHandler.getFilterStrategy(
      option,
      true
    );

    this.filterStrategy?.clear();

    if (this._shouldPopulate()) {
      this._populate();
    }
  }

  clear(): void {
    this.filterStrategy.clear();
  }

  cancel(): void {
    this.canceled.emit();
  }

  apply(filtered: FilterEmitted): void {
    if (this._isAllowedToApplyFilter()) {
      this.filtered.emit({
        columnKey: this.filter().columnKey,
        optionSelected: this.selectedOption(),
        subOperator: this.filterValues().subOperator,
        values: this.filterStrategy.form,
        applyAll: filtered.applyAll,
      });
    }
  }

  private _shouldPopulate(): boolean {
    return this.hasSameOptionSelected();
  }

  private _populate(): void {
    this.filterStrategy.populate(this.filterValues()?.value);
  }

  private _isAllowedToApplyFilter(): boolean {
    return (
      this.filterStrategy.hasValue() &&
      !(
        this.filterStrategy.hasSameValue(this.filterValues()?.value) &&
        this.hasSameOptionSelected()
      ) &&
      (!this.filterForm()?.invalid ||
        this.selectedOption() === FilterTypeOptionsV2.IS_NULL) &&
      !this.filterDisabled()
    );
  }
}
