import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { DisconnectedProducts } from 'src/app/shared/models/suppliers/disconnected-products.model';

export class ConnectProductToSupplierAdapter
  implements DataAdapter<DisconnectedProducts, DisconnectedProducts>
{
  transform(data: DisconnectedProducts): DisconnectedProducts {
    const products = data.webshopProducts.map(product => {
      return {
        id: product.id,
        name: product.name,
        sku: product.sku,
        price: product.price,
        minimumPurchaseQuantity: product.minimumPurchaseQuantity,
        eanCode: product.eanCode,
      };
    });

    return {
      webshopProducts: products,
    };
  }
}
