import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import {
  ConfirmPasswordChanged,
  NewPasswordChanged,
  SavePassword,
} from './actions/change-password-dialog.actions';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { STRINGS } from 'src/app/features/user-settings/model/user-settings.strings';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordDialogComponent {
  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata.changePassword.dialog;

  newPassword: string = '';
  confirmPassword: string = '';

  @Select(AppState.isLoading)
  loading$: Observable<boolean>;

  newInputTypes: string = 'password';

  confirmInputTypes: string = 'password';

  equalPasswords: boolean = true;

  passwordChecks = {
    charactersNumber: {
      class: 'conditions',
      icon: 'check-xs',
      check: false,
    },
    lowerCase: {
      class: 'conditions-sub',
      icon: 'check-xs',
      check: false,
    },
    upperCase: {
      class: 'conditions-sub',
      icon: 'check-xs',
      check: false,
    },
    numbers: {
      class: 'conditions-sub',
      icon: 'check-xs',
      check: false,
    },
    special: {
      class: 'conditions-sub',
      icon: 'check-xs',
      check: false,
    },
  };

  constructor(
    @Inject(DIALOG_DATA)
    public data: { dialogTitle: string },
    private store: Store,
    private dialogRef: DialogRef
  ) {}

  save(): void {
    this.store.dispatch(new SavePassword(this.dialogRef));
  }

  newTogglePasswordVisibility() {
    if (this.newInputTypes === 'password') {
      this.newInputTypes = 'text';
    } else {
      this.newInputTypes = 'password';
    }
  }
  confirmTogglePasswordVisibility() {
    if (this.confirmInputTypes === 'password') {
      this.confirmInputTypes = 'text';
    } else {
      this.confirmInputTypes = 'password';
    }
  }

  onNewPasswordChangedChanged(value: any) {
    this.newPassword = value;
    this.store.dispatch(new NewPasswordChanged(value));
    this.passwordValidations();
  }

  onConfirmPasswordChangedChanged(value: any) {
    this.confirmPassword = value;
    this.store.dispatch(new ConfirmPasswordChanged(value));
  }

  checkPasswords() {
    return (
      this.newPassword === this.confirmPassword &&
      this.passwordChecks.charactersNumber.check &&
      ((this.passwordChecks.lowerCase.check &&
        this.passwordChecks.upperCase.check &&
        this.passwordChecks.numbers.check) ||
        (this.passwordChecks.special.check &&
          this.passwordChecks.upperCase.check &&
          this.passwordChecks.numbers.check) ||
        (this.passwordChecks.lowerCase.check &&
          this.passwordChecks.special.check &&
          this.passwordChecks.numbers.check) ||
        (this.passwordChecks.lowerCase.check &&
          this.passwordChecks.upperCase.check &&
          this.passwordChecks.special.check))
    );
  }

  checkPasswordSimililarity() {
    return (
      this.newPassword !== this.confirmPassword && this.confirmPassword !== ''
    );
  }

  passwordValidations() {
    if (this.newPassword.length > 7) {
      this.passwordChecks.charactersNumber = {
        class: 'conditions-success',
        icon: 'check-xs',
        check: true,
      };
    } else {
      this.passwordChecks.charactersNumber = {
        class: 'conditions-error',
        icon: 'cross-xs',
        check: false,
      };
    }

    if (this.hasLowerCase(this.newPassword)) {
      this.passwordChecks.lowerCase = {
        class: 'conditions-sub-success',
        icon: 'check-xs',
        check: true,
      };
    } else {
      this.passwordChecks.lowerCase = {
        class: 'conditions-sub-error',
        icon: 'cross-xs',
        check: false,
      };
    }

    if (this.hasUpperCase(this.newPassword)) {
      this.passwordChecks.upperCase = {
        class: 'conditions-sub-success',
        icon: 'check-xs',
        check: true,
      };
    } else {
      this.passwordChecks.upperCase = {
        class: 'conditions-sub-error',
        icon: 'cross-xs',
        check: false,
      };
    }

    if (this.containsNumbers(this.newPassword)) {
      this.passwordChecks.numbers = {
        class: 'conditions-sub-success',
        icon: 'check-xs',
        check: true,
      };
    } else {
      this.passwordChecks.numbers = {
        class: 'conditions-sub-error',
        icon: 'cross-xs',
        check: false,
      };
    }

    if (this.containsSpecial(this.newPassword)) {
      this.passwordChecks.special = {
        class: 'conditions-sub-success',
        icon: 'check-xs',
        check: true,
      };
    } else {
      this.passwordChecks.special = {
        class: 'conditions-sub-error',
        icon: 'cross-xs',
        check: false,
      };
    }
  }

  hasLowerCase(str) {
    return str.toUpperCase() != str;
  }

  hasUpperCase(str) {
    return str.toLowerCase() != str;
  }

  containsNumbers(str) {
    return /\d/.test(str);
  }

  containsSpecial(str) {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(str);
  }

  close() {
    this.dialogRef.close();
  }
}
