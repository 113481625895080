export const STRINGS = {
  metadata: {
    columnView: $localize`Column View`,
    description: $localize`Select the columns that you wish to display in the data table.`,
    noneSelected: $localize`Please, select at least one column.`,
    hideColumn: $localize`Hide column`,
    disabledHideColumn: $localize`This column cannot be hidden.`,
    requiredColumns: $localize`This column is required.`,
    applyAll: $localize`Apply to all in this page`,
  },
};
