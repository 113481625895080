import { DialogRef } from '@angular/cdk/dialog';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  Actions,
  ofActionDispatched,
  select,
  Select,
  Store,
} from '@ngxs/store';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  first,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { SaveFailed } from 'src/app/core/actions/app.action';
import {
  LinkedSource,
  SupportedSource,
} from 'src/app/core/api/integrations/model/integrations.model';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  CreateLinkedSource,
  FilterSources,
  InitializeSourceList,
  SelectSupportedSource,
} from 'src/app/features/settings/actions/integrations.action';

import { IntegrationsState } from 'src/app/features/settings/state/integrations.state';

@Component({
  selector: 'app-add-source-dialog',
  templateUrl: './add-source-dialog.component.html',
  styleUrls: ['./add-source-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSourceDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Select(IntegrationsState.filteredSupportedSources)
  supportedSources$: Observable<SupportedSource[]>;

  @Select(IntegrationsState.sourcesCategories)
  sourcesCategories$: Observable<string[]>;

  @Select(IntegrationsState.selectedSupportedSource)
  selectedSupportedSource$: Observable<SupportedSource>;

  @Select(IntegrationsState.linkedSources)
  linkedSources$: Observable<LinkedSource[]>;

  @Select(IntegrationsState.loadingSupportedSources)
  loadingSupportedSources$: Observable<boolean>;

  @Select(AppState.isSaving)
  isSaving$: Observable<boolean>;

  @Select(IntegrationsState.disableDialog)
  disabledDialog$: Observable<boolean>;

  numberOfIntegrations = select(WebshopState.numberOfIntegrations);

  disableNextButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  disableBackButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  credentialsError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  searchBy$: Subject<string> = new BehaviorSubject<string>(null);

  selectedSource: string = '';

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly LABELS = [
    $localize`Next`,
    $localize`Connect`,
    $localize`Contact us`,
  ];

  buttonLabel: string = this.LABELS[0];

  form: FormGroup;

  fieldsWithPassword = new Map<string, string>();

  exactExtraInfo: any;

  supportedSource: SupportedSource;

  constructor(
    private dialogRef: DialogRef<AddSourceDialogComponent>,
    private store: Store,
    private actions: Actions
  ) {
    this.form = new FormGroup({});
  }

  @ViewChild('cdkStepper')
  cdkStepper: CdkStepper;

  ngOnInit(): void {
    this.actions
      .pipe(ofActionDispatched(SaveFailed), takeUntil(this.destroy$))
      .subscribe(() => {
        this.credentialsError$.next(true);
      });

    this.store
      .select(WebshopState.selected)
      .pipe(
        first(),
        switchMap(() => this.store.dispatch(new InitializeSourceList())),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.addFormControls();
    this.disableNextButton$.next(false);
    this.disabledDialog$.pipe(takeUntil(this.destroy$)).subscribe(disabled => {
      if (disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });
  }

  /**
   * navigates to the next step of the stepper
   */
  onNext(): void {
    if (this.cdkStepper.selectedIndex === 1) {
      this.store.dispatch(
        new CreateLinkedSource({
          supportedSource: this.supportedSource,
          config: this.form.value,
          exactExtraInfo: this.exactExtraInfo ? this.exactExtraInfo : null,
        })
      );
    }
    this.cdkStepper.next();
  }

  /**
   * toggles the visibility of a password input field
   * @param key the key of the input field
   */
  public togglePasswordVisibility(key: string): void {
    if (this.fieldsWithPassword.get(key) === 'password') {
      this.fieldsWithPassword.set(key, 'text');
    } else {
      this.fieldsWithPassword.set(key, 'password');
    }
  }

  /**
   * navigates to the previous step of the stepper
   */
  onBack(): void {
    this.cdkStepper.previous();
  }

  /**
   * unsubscribes from all subscriptions
   */
  ngOnDestroy(): void {
    this.disableNextButton$.complete();
    this.disableBackButton$.complete();
    this.credentialsError$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * adds form controls to the form group
   */
  addFormControls(): void {
    this.selectedSupportedSource$
      .pipe(takeUntil(this.destroy$))
      .subscribe(source => {
        if (source) {
          this.selectedSource = source.tap;
          this.supportedSource = source;
          for (let field of source.connectedUiParams) {
            if (source.tap === 'exact') {
              if (field.key === 'auth_url') {
                this.exactExtraInfo = field;
              }
            }
            if (source.tap === 'amazon-seller') {
              if (field.key === 'uri') {
                this.exactExtraInfo = field;
              }
            }
            if (field.type === 'boolean') {
              if (field.default) {
                this.form.addControl(
                  field.key,
                  new FormControl<boolean>(field.default)
                );
              } else {
                this.form.addControl(
                  field.key,
                  new FormControl<boolean>(false)
                );
              }
            } else if (field.type === 'password') {
              this.fieldsWithPassword.set(field.key, 'password');
              this.form.addControl(
                field.key,
                new FormControl('', [
                  ...this.isPasswordRequired(field.required),
                ])
              );
              this.disableNextButton$.next(true);
            } else if (field.required === false && field.type !== 'boolean') {
              this.form.addControl(field.key, new FormControl('', []));
            } else {
              this.form.addControl(
                field.key,
                new FormControl('', [Validators.required])
              );
              this.disableNextButton$.next(true);
            }
          }
        } else {
          this.supportedSources$
            .pipe(takeUntil(this.destroy$))
            .subscribe(sources => {
              this.selectedSource = sources[0].tap;
            });
        }
      });

    this.form.statusChanges
      .pipe(
        distinctUntilChanged(),
        filter(() => !!this.cdkStepper),
        takeUntil(this.destroy$)
      )
      .subscribe(status => {
        if (this.cdkStepper.selectedIndex === 1) {
          this.buttonLabel = this.LABELS[1];
          if (status === 'INVALID') {
            this.disableNextButton$.next(true);
          } else {
            this.disableNextButton$.next(false);
          }
        } else if (this.cdkStepper.selectedIndex === 0) {
          this.buttonLabel = this.LABELS[0];
          this.disableNextButton$.next(false);
        }
      });
  }

  /**
   * clears the form group
   */
  clearForm(): void {
    Object.keys(this.form.controls).forEach(controlName => {
      this.form.removeControl(controlName);
    });
  }

  /**
   * handles the step change of the stepper
   * @param event the event emitted by the stepper
   */
  onStepChange(event: any): void {
    this.clearForm();
    if (event.previouslySelectedIndex === 0) {
      this.store
        .dispatch(new SelectSupportedSource(this.selectedSource))
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.addFormControls());
      this.buttonLabel = this.LABELS[1];
      this.disableBackButton$.next(false);

      if (!this.form.valid) {
        this.disableNextButton$.next(true);
      } else {
        this.disableNextButton$.next(false);
      }
    } else {
      this.buttonLabel = this.LABELS[0];
      this.credentialsError$.next(false);
      this.disableBackButton$.next(true);
      this.disableNextButton$.next(false);
    }
  }

  /**
   * removes the credentials error
   */
  removeWarning(): void {
    if (this.credentialsError$.value) this.credentialsError$.next(false);
  }

  /**
   * checks if the password is required
   * @param required the required state of the password
   * @returns a validator function array
   */
  isPasswordRequired(required: boolean | undefined): ValidatorFn[] {
    if (required === undefined || required === true) {
      return [Validators.required];
    }
    return [];
  }

  /**
   * checks the radio button for the source with the id passed as a parameter
   * @param value the id of the source
   */
  checkRadio(value: string): void {
    this.selectedSource = value;
  }

  /**
   *
   * @param value the id of the source
   * @returns the checked state of the radio button for the source with the id passed as a parameter
   */
  checkCheck(value: string) {
    return value === this.selectedSource;
  }

  checkSelected(tap): boolean {
    let selected = false;
    this.linkedSources$.pipe(takeUntil(this.destroy$)).subscribe(sources => {
      selected = sources.some(e => e.tap === tap);
    });

    return selected;
  }

  openIntercom() {
    Intercom('showNewMessage');

    this.close();
  }

  filterSources(filter: string): void {
    this.store.dispatch(new FilterSources(filter));
  }

  /**
   * closes the dialog
   */
  close(): void {
    this.dialogRef.close();
  }
}
