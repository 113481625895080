import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  input,
} from '@angular/core';
import { STRINGS } from '../../model/column-view-v3.strings';
import { ColumnToggled } from '../../model/column-view-v3.model';
import { DatatableColumnV2ColumnGroup } from '../../../../model/data-table-v2.model';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-column-view-v3-option-selected',
  templateUrl: './column-view-v3-option-selected.component.html',
  styleUrl: './column-view-v3-option-selected.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnViewV3OptionSelectedComponent {
  column = input<DatatableColumnV2ColumnGroup>();

  readonly COLUMN_VIEW_STRINGS = STRINGS.metadata;

  readonly COMMON_STRINGS = MESSAGES.common;

  @Output()
  removed: EventEmitter<ColumnToggled> = new EventEmitter<ColumnToggled>();

  removeColumn() {
    this.removed.emit({
      groupKey: this.column().groupKey,
      columnKey: this.column().name,
      checked: false,
    });
  }
}
