import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import { RenameExport } from 'src/app/features/exports-v2/components/exports-v2-data-table/actions/exports-v2.actions';
import { STRINGS } from 'src/app/features/exports-v2/model/exports-v2.strings';

@Component({
  selector: 'app-rename-export-dialog',
  templateUrl: './rename-export-dialog.component.html',
  styleUrls: ['./rename-export-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameExportDialogComponent implements OnInit {
  @Select(AppState.isSaving)
  saving$: Observable<boolean>;

  name = signal('');

  readonly METADATA_STRINGS = STRINGS.metadata.renameExportDialog;

  readonly COMMON_STRINGS = MESSAGES.common;

  constructor(
    @Inject(DIALOG_DATA) public data,
    private store: Store,
    public dialogRef: DialogRef<RenameExportDialogComponent>
  ) {}

  ngOnInit(): void {
    this.name.set(this.data.fileName);
  }

  /**
   * Dispatches the action do rename the export
   */
  renameExport(): void {
    this.store.dispatch(
      new RenameExport(
        this.data.fileUUID,
        {
          name: this.name(),
        },
        this.dialogRef
      )
    );
  }

  close() {
    this.dialogRef.close();
  }
}
