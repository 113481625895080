import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  InitializeProductPartsV2State,
  RemoveAllFilters,
} from './actions/product-parts-v2.actions';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { Observable, distinctUntilKeyChanged, filter, first, skip } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getRedirectedProductUuid } from 'src/app/features/products-v2/model/products-v2.model';
import { WebshopState } from 'src/app/core/states/webshop.state';

@Component({
  selector: 'app-product-parts-v2',
  templateUrl: './product-parts-v2.component.html',
  styleUrls: ['./product-parts-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPartsV2Component implements OnInit {
  redirectedProductUuid$: Observable<string> = getRedirectedProductUuid();

  constructor(
    private store: Store,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(ProductDetailsV2StateQueries.productOverviewSucceed)
      .pipe(filter(Boolean), first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.store.dispatch(new InitializeProductPartsV2State())
      );

    this.store
      .select(WebshopState.selected)
      .pipe(
        skip(1),
        distinctUntilKeyChanged('uuid'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.store.dispatch(new RemoveAllFilters()));

    this.redirectedProductUuid$.subscribe(() =>
      this.store.dispatch(new InitializeProductPartsV2State())
    );
  }
}
