import {
  FilterBooleanGroupV2,
  FilterStrategy,
} from '../../../model/filter-v2.model';

export class FilterBooleanStrategy
  implements FilterStrategy<FilterBooleanGroupV2>
{
  form: FilterBooleanGroupV2 = {
    to: null,
  };

  populate(value: FilterBooleanGroupV2): void {
    this.form.to = value.to;
  }

  hasValue(): boolean {
    return this.form.to !== null;
  }

  hasSameValue(value: FilterBooleanGroupV2 | undefined | null): boolean {
    if ((value === null || value === undefined) && this.form.to === null)
      return true;

    if ((value === null || value === undefined) && this.form.to !== null)
      return false;

    return value.to === this.form.to;
  }

  clear(): void {
    this.form = {
      to: null,
    };
  }
}
