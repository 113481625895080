import { Directive, HostBinding, Input, booleanAttribute } from '@angular/core';

@Directive({
  selector: '[appButtonIcon]',
})
export class ButtonIconDirective {
  @HostBinding('class')
  class: string = 'button-icon';

  @Input({ transform: booleanAttribute })
  set transparent(isTransparent: boolean) {
    this.class = isTransparent ? 'button-icon-transparent' : 'button-icon';
  }
}
