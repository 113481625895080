import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: 'radio-button.component.html',
  styleUrls: ['radio-button.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent {
  @Input()
  name: string;

  @Input()
  label: string;

  @Input()
  value: string;

  @Input()
  get bigLabel(): boolean {
    return this._bigLabel;
  }
  set bigLabel(value: string | boolean) {
    this._bigLabel = coerceBooleanProperty(value);
  }
  private _bigLabel: boolean = false;

  @Input()
  icon: string;

  @Input()
  checked: boolean;

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Output()
  radioChange: EventEmitter<string> = new EventEmitter<string>();

  handleClick(): void {
    this.radioChange.emit(this.value);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
