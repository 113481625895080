import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appToastContainer]',
})
export class ToastContainerDirective {
  @Input()
  set toastStatus(status: string) {
    this.class = `toast-container toast-${status}`;
  }

  @HostBinding('class')
  class: string = 'toast-container';
}
