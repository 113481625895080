export enum AccountPermissionRoles {
  NO_ACCESS = 'no_access',
  VIEW = 'view',
  EDIT = 'edit',
}

export type AccountPermissionRole =
  | AccountPermissionRoles.NO_ACCESS
  | AccountPermissionRoles.VIEW
  | AccountPermissionRoles.EDIT;

export interface PermissionBase {
  roleId: string;
  featureKey: string;
  role: AccountPermissionRole;
}

export type MappedPermission = Omit<PermissionBase, 'roleId'>;

export interface AccountRoles {
  isShopAdministrator: boolean;
  permissions: MappedPermission[];
}

export interface PermissionsBase {
  [roleId: string]: PermissionBase;
}

export interface AccountPermission {
  featureKey: string;
  name: string;
  role: AccountPermissionRole;
  changeable: boolean;
}

export interface AccountPermissions {
  [key: string]: AccountPermission;
}

export interface ChangeablePermissionOption {
  value: string;
  label: string;
}

export interface ChangeablePermissionOptions {
  view: string;
  edit: string;
}

export interface ChangeablePermissionOptionsV2 {
  role: string;
  roleId: string;
}

export interface ChangeablePermission extends AccountPermission {
  roleId: string;
  options: ChangeablePermissionOptions;
  blocked: boolean;
  checked: boolean;
  value: string;
}

export interface ChangeablePermissions {
  [key: string]: ChangeablePermission;
}

export interface ToggledPermission {
  permission: ChangeablePermission;
  enabled: boolean;
}

export interface ChangedPermission {
  permission: ChangeablePermission;
  value: string;
}

export const ACCOUNT_PERMISSIONS: AccountPermissions = {
  analytics: {
    featureKey: 'analytics',
    name: $localize`Analytics`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
  purchase: {
    featureKey: 'purchase',
    name: $localize`Purchase orders`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
  deliveries: {
    featureKey: 'deliveries',
    name: $localize`Deliveries`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
  products: {
    featureKey: 'products',
    name: $localize`Products`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
  suppliers: {
    featureKey: 'suppliers',
    name: $localize`Suppliers`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
  promotions: {
    featureKey: 'promotions',
    name: $localize`Promotions`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
  settings: {
    featureKey: 'settings',
    name: $localize`Settings`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
  billing: {
    featureKey: 'billing',
    name: $localize`Billing`,
    role: AccountPermissionRoles.NO_ACCESS,
    changeable: true,
  },
};
