<div class="slide-toggle slide-toggle-on" [class.slide-toggle-on]="checked">
  <div *ngIf="label" class="slide-toggle-title">{{ label }}</div>
  <label
    class="slide-toggle-label"
    [class.slide-toggle-disabled]="disabled"
    [class.slide-toggle-reverse-state]="statePosition === 'before'">
    <div class="slide-toggle-bar" [class.slide-toggle-disabled]="disabled">
      <input
        [id]="id"
        class="slide-toggle-input"
        type="checkbox"
        [checked]="checked"
        (change)="setChecked()"
        [disabled]="disabled" />

      <div class="slide-toggle-thumb-container">
        <div
          class="slide-toggle-thumb"
          [class.slide-toggle-disabled]="disabled"></div>
      </div>
    </div>

    <ng-container *ngIf="showState">
      <span
        *ngIf="checked"
        class="slide-toggle-status"
        [class.slide-toggle-status-disabled]="disabled"
        i18n="optiply setting slider on"
        >On</span
      >
      <span
        *ngIf="!checked"
        class="slide-toggle-status"
        [class.slide-toggle-status-disabled]="disabled"
        i18n="optiply setting slider off"
        >Off</span
      >
    </ng-container>
  </label>
</div>
