import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { BuyOrderExportSettings as BuyOrderExportSettingsV1 } from '../model/buy-order-v2.model';
import { BuyOrderExportSettings } from 'src/app/shared/models/buy-orders/v2/buy-orders-exports-settings.model';

export class BuyOrdersV2ExportsSettingsAdapter
  implements DataAdapter<BuyOrderExportSettingsV1, BuyOrderExportSettings>
{
  transform(data: BuyOrderExportSettingsV1): BuyOrderExportSettings {
    return {
      identifiers: data.identifiers,
    };
  }
}
