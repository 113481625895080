import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ExportAs } from '../../models/exports/export-as.model';
import {
  ExportOnlyOrderUndelivered,
  ExportOrder,
} from './actions/export-as.actions';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { STRINGS } from 'src/app/features/deliveries-v2/model/deliveries-v2.strings';
import { BuyOrderSettingsState } from 'src/app/core/states/buyorder-settings.state';

@Component({
  selector: 'app-export-as',
  templateUrl: './export-as.component.html',
  styleUrls: ['./export-as.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportAsComponent {
  @Select(AppState.isSaving)
  saving$: Observable<boolean>;

  @Select(BuyOrderSettingsState.dynamicExportColumns)
  dynamicExportColumns$: Observable<string[]>;

  excludeReceived$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly DELIVERIES_STRINGS = STRINGS.metadata.exportOrder;

  constructor(
    @Inject(DIALOG_DATA) public data: ExportAs,
    private dialogRef: DialogRef,
    private store: Store
  ) {}

  export(format: 'CSV' | 'XLSX' | 'PDF'): Observable<any> {
    if (this.excludeReceived$.value) {
      return this.store.dispatch(
        new ExportOnlyOrderUndelivered(this.data.buyOrderUuid, format)
      );
    }

    return this.store.dispatch(new ExportOrder(this.data.buyOrderUuid, format));
  }

  updateExcludeReceived(value: boolean) {
    this.excludeReceived$.next(value);
  }

  close() {
    this.dialogRef.close();
  }
}
