<div class="search-container">
  <div class="custom-input">
    <span class="search-icon-container">
      <mat-icon svgIcon="magnifying-glass" class="search-icon"></mat-icon>
    </span>
    <input
      appInput
      [variant]="variant"
      [id]="id"
      [disabled]="disabled"
      #searchDiv
      type="text"
      name="to"
      [ngModel]="searchBy"
      [placeholder]="placeholder"
      (keyup)="showAutoComplete()"
      autocomplete="off"
      [disabled]="disabled" />

    <div class="search-result" *ngIf="showAutoCompleteList$ | async">
      <ul>
        <li
          #searchDiv
          class="search-result-text"
          *ngFor="
            let c of autocompleteList | appSearchFilter: searchBy;
            trackBy: trackById
          "
          (click)="onselectSearch(c)"
          [appTooltip]="c.name"
          [tooltipShowDelay]="300">
          {{ c.name }}
        </li>
      </ul>
    </div>
  </div>
</div>
