<app-supply-chain-section-container>
  <ng-container title>{{ METADATA.title }}</ng-container>

  <ng-container content>
    <app-supply-chain-section-row
      data-intercom-target="7fd91932-aa80-4a46-b254-6d84736cb285"
      aria-label="Product details product free stock">
      <ng-container description>{{ METADATA.freeStock }}</ng-container>
      <ng-container
        *ngIf="(product$ | async)?.stockOnHand !== null; else notAvailable"
        value
        >{{ (product$ | async).stockOnHand | customDecimal }}</ng-container
      >
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="e3ceca63-e087-46fa-9885-7e29444eb773"
      aria-label="Product details product purchase price">
      <ng-container description>{{ METADATA.purchasePrice }}</ng-container>
      <ng-container
        *ngIf="
          (activeSupplier$ | async)?.supplierProduct?.price !== null &&
            (product$ | async)?.assembled === false;
          else notAvailable
        "
        value>
        <span>{{
          (activeSupplier$ | async).supplierProduct.price | customCurrency
        }}</span></ng-container
      >
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="35291a81-3c2e-4740-96d8-c0f79e64d2c4"
      aria-label="Product details product sales price">
      <ng-container description>{{ METADATA.salesPrice }}</ng-container>
      <ng-container
        *ngIf="(product$ | async)?.price !== null; else notAvailable"
        value>
        <span>
          {{ (product$ | async).price | customCurrency }}
        </span>
      </ng-container>
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="b7921da9-ac07-4515-b2e1-67eac73fa8f2"
      aria-label="Product details product margin">
      <ng-container description>{{ METADATA.margin }}</ng-container>
      <ng-container
        *ngIf="
          (activeSupplier$ | async)?.supplierProduct.webshopProduct !== null &&
            (assembled$ | async) === false;
          else notAvailable
        "
        value>
        <span>{{
          (activeSupplier$ | async).supplierProduct.webshopProduct.priceMargin
            | customCurrency
        }}</span>
      </ng-container>
    </app-supply-chain-section-row>
  </ng-container>
</app-supply-chain-section-container>

<ng-template #notAvailable>
  <span>{{ NOT_AVAILABLE }}</span>
</ng-template>
