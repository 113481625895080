import { ControlValueAccessor } from '@angular/forms';

export class CustomAbstractControl implements ControlValueAccessor {
  disabled: boolean = false;

  value: any | null = null;

  private onChange: (value: any) => void = () => {};

  private onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onValueChange(value: any): void {
    this.value = value;

    this.onChange(value);
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
