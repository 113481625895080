<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <app-data-table-refresh-button
      data-intercom-target="Refresh table data button"
      aria-label="Refresh table data button"
      [loading]="loading()"
      [disabled]="loading() || !canRefresh()"
      (refreshed)="refreshDatatable()" />

    <div class="vertical-separator"></div>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="loading()">
  <app-filter-group [filters]="activeFilters()" (filtered)="onFiltered($event)">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"></app-add-filter-button>
  </app-filter-group>
</app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    [disableHideColumn]="lastDisplayedColumn()"
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)"
    (filtered)="onFiltered($event)">
    <!-- Name -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.NAME.key">
      <th
        data-intercom-target="01f02156-5303-4571-928f-580058f6f05a"
        aria-label="Product details compositions table Name column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.NAME.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.NAME.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <span
          (click)="goToProductDetails(element.composedProductUuid)"
          class="deep-linking-text">
          <app-copy-to-clipboard
            [message]="element.name"></app-copy-to-clipboard>
        </span>
      </td>
    </ng-container>

    <!-- SKU -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SKU.key">
      <th
        data-intercom-target="c2fad000-6a80-4934-a638-5fcfdeddd604"
        aria-label="Product details compositions table SKU column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SKU.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.SKU.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-copy-to-clipboard [message]="element.sku"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- EAN code -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.EAN_CODE.key">
      <th
        data-intercom-target="d23458d7-9bd2-409a-828e-ebfe99923987"
        aria-label="Product details compositions table EAN Code column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.EAN_CODE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.EAN_CODE.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-copy-to-clipboard
          [message]="element.eanCode"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Article code -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.ARTICLE_CODE.key">
      <th
        data-intercom-target="88a6a99f-3a11-472c-9600-c519b552e950"
        aria-label="Product details compositions table Article Code column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.ARTICLE_CODE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.ARTICLE_CODE.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-copy-to-clipboard
          [message]="element.articleCode"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Parts quantity -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.PART_QUANTITY.key">
      <th
        data-intercom-target="dbf19d00-59bf-4a9b-a036-6c7d6ff5a164"
        aria-label="Product details compositions table Parts Quantity column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.PART_QUANTITY.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.PART_QUANTITY.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container *ngIf="element.partQuantity !== null; else notAvailable">
          {{ element.partQuantity | customDecimal }}
        </ng-container>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      data-intercom-target="8416e32a-dc09-4bfd-8202-8cb325f0fa30"
      aria-label="Product details compositions table row"
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
