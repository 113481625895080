import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import {
  ProductInformationSalesGraphGroups,
  ProductInformationSalesGraphHistoryLengths,
} from 'src/app/shared/models/account/account-settings.model';
import {
  GraphFilter,
  GraphGroupBy,
} from 'src/app/shared/models/sales/sales-v2.model';
import {
  ProductSalesV2Refresh,
  ProductSalesV2SelectFilter,
  ProductSalesV2SelectGroupBy,
} from '../../actions/sales-v2.actions';
import { ProductSalesV2StateQueries } from '../../state/sales-v2.queries';

@Component({
  selector: 'app-sales-v2-filter',
  templateUrl: './sales-v2-filter.component.html',
  styleUrls: ['./sales-v2-filter.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesV2FilterComponent {
  @Select(ProductSalesV2StateQueries.loading)
  loading$: Observable<boolean>;

  @Select(ProductSalesV2StateQueries.graphFilter)
  graphFilter$: Observable<GraphFilter>;

  @Select(ProductSalesV2StateQueries.graphGroupBy)
  graphGroupBy$: Observable<GraphGroupBy>;

  readonly GRAPH_FILTER_OPTIONS = ProductInformationSalesGraphHistoryLengths;

  readonly GRAPH_GROUPBY_OPTIONS = ProductInformationSalesGraphGroups;

  readonly TOOLTIPS = STRINGS.tooltips.sales;

  constructor(private store: Store) {}

  selectFilter(filter: GraphFilter): void {
    this.store.dispatch(new ProductSalesV2SelectFilter(filter));
  }

  selectGroupBy(groupBy: GraphGroupBy): void {
    this.store.dispatch(new ProductSalesV2SelectGroupBy(groupBy));
  }

  refresh(): void {
    this.store.dispatch(new ProductSalesV2Refresh());
  }
}
