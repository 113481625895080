import { InjectionToken } from '@angular/core';
import { RadioButtonV2Component } from '../radio-button-v2.component';
import { RadioButtonGroupV2Directive } from '../directives/radio-button-group-v2.directive';

export const RADIO_GROUP = new InjectionToken<RadioButtonGroupV2Directive>(
  'Radio Group'
);

export class RadioChange {
  constructor(
    public source: RadioButtonV2Component,
    public value: any
  ) {}
}
