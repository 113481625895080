import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { Import } from 'src/app/shared/models/tableImports/imports.model';

export class InitializeImportsState {
  static readonly type = '[Imports] Initialize';
}

export class LoadImports {
  static readonly type = '[Imports] Load';
}

export class ReloadDatatable {
  static readonly type = '[Imports] Reload';
}

export class LoadImport {
  static readonly type = '[Imports] Load Import';

  constructor(public importUUID: string) {}
}

export class UploadData {
  static readonly type = '[Imports] Upload';

  constructor(
    public data: any,
    public identifier: string
  ) {}
}

export class UploadSupplierData {
  static readonly type = '[Imports] Upload Supplier Data';

  constructor(
    public data: any,
    public operation: string,
    public identifier: string
  ) {}
}

export class ResetImports {
  static readonly type = '[Imports] Reset Imports';
}

export class Paginate {
  static readonly type = '[Imports] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class Sort {
  static readonly type = '[Imports] Sort';

  constructor(public sort: Sorted) {}
}

export class ResetPagination {
  static readonly type = '[Imports] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Imports] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Imports] Hide Column';

  constructor(public columnKey: string) {}
}

export class RemoveAllFilters {
  static readonly type = '[Imports] Remove All Filters';
}

export class ViewImport {
  static readonly type = '[Imports] Download Export';

  constructor(public fileUUID: string) {}
}

export class AddSearchParam {
  static readonly type = '[Imports] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Imports] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Imports] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class RemoveFilterParam {
  static readonly type = '[Imports] Remove Filter Param';
}

export class ToggleFilter {
  static readonly type = '[Imports] Toggle Filter';
}

export class ToggleRowSelection {
  static readonly type = '[Imports] Toggle Row Selection';

  constructor(
    public rowKey: string,
    public isSelected: boolean,
    public importRow: Import
  ) {}
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Imports] Reset Pagination And Load Data';
}
