import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewChild,
  signal,
} from '@angular/core';
import { TooltipAnimationStates } from '../model/tooltip.model';
import { Observable, Subject } from 'rxjs';
import { AnimationEvent } from '@angular/animations';
import { tooltipAnimation } from '../animations/tooltip-animations';

@Component({
  selector: 'app-tooltip-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tooltipAnimation.tooltipState],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TooltipBase implements OnDestroy {
  @HostBinding('attr.aria-hidden')
  ariaHidden: boolean = true;

  @ViewChild('tooltip', { static: true })
  tooltip: ElementRef<HTMLElement>;

  message: string | null;

  tooltipClass = signal<string | string[]>(null);

  triggerElement: HTMLElement;

  hideDelay: number;

  maxWidth: number;

  tooltipAnimationState: TooltipAnimationStates = TooltipAnimationStates.VOID;

  private _showTimeoutId: ReturnType<typeof setTimeout> | undefined;

  private _hideTimeoutId: ReturnType<typeof setTimeout> | undefined;

  /* Whether interactions on the page should close the tooltip */
  private _closeOnInteraction: boolean = false;

  private _isVisible = signal<boolean>(false);

  private readonly _onHide: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    protected elementRef: ElementRef<HTMLElement>
  ) {}

  show(delay: number): void {
    this._checkAndClearHideTimeoutId();

    this._showTimeoutId = setTimeout(() => {
      this._toggleVisibility(true);
      this._showTimeoutId = undefined;
    }, delay);
  }

  hide(delay: number): void {
    this._checkAndClearShowTimeoutId();

    this._hideTimeoutId = setTimeout(() => {
      this._toggleVisibility(false);
      this._hideTimeoutId = undefined;
    }, delay);
  }

  handlePageInteraction(): void {
    if (this._closeOnInteraction) {
      this.hide(0);
    }
  }

  handleMouseLeave(event: MouseEvent) {
    const relatedTarget = event.relatedTarget;

    if (relatedTarget && this.triggerElement.contains(relatedTarget as Node))
      return;

    this.hide(this.hideDelay);
    this._endAnimation(false);
  }

  handleAnimationEnd(event: AnimationEvent) {
    const { toState: animationName } = event;

    if (
      animationName === TooltipAnimationStates.VISIBLE ||
      animationName === TooltipAnimationStates.HIDDEN
    ) {
      this._endAnimation(animationName === TooltipAnimationStates.VISIBLE);
    }
  }

  isVisible(): boolean {
    return this._isVisible();
  }

  afterHidden(): Observable<void> {
    return this._onHide;
  }

  clearAnimations(): void {
    this._checkAndClearShowTimeoutId();

    this._checkAndClearHideTimeoutId();

    this._showTimeoutId = this._hideTimeoutId = undefined;
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  private _toggleVisibility(isVisible: boolean) {
    this.tooltipAnimationState = isVisible
      ? TooltipAnimationStates.VISIBLE
      : TooltipAnimationStates.HIDDEN;

    this._isVisible.set(isVisible);

    this.markForCheck();
  }

  private _endAnimation(toVisible: boolean) {
    if (toVisible) {
      this._closeOnInteraction = true;
    } else {
      this._onHide.next();
    }
  }

  private _checkAndClearShowTimeoutId(): void {
    if (this._showTimeoutId !== null) {
      clearTimeout(this._showTimeoutId);
    }
  }

  private _checkAndClearHideTimeoutId(): void {
    if (this._hideTimeoutId !== null) {
      clearTimeout(this._hideTimeoutId);
    }
  }

  ngOnDestroy(): void {
    this.clearAnimations();

    this._onHide.complete();

    this.triggerElement = null;
  }
}
