import { SupplierProductLink } from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';

export class LoadLinkedSupplierProductsEditor {
  static readonly type =
    '[Linked Supplier Products Editor] Load Linked Supplier Products Editor';

  constructor(public search: string = '') {}
}

export class UpdateSupplierProductName {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Name';

  constructor(
    public data: SupplierProductLink,
    public value: string
  ) {}
}

export class UpdateProductSku {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Sku';

  constructor(
    public data: SupplierProductLink,
    public value: string
  ) {}
}

export class UpdateProductLotSize {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Lot Size';

  constructor(
    public data: SupplierProductLink,
    public value: number
  ) {}
}

export class UpdateProductEAN {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product EAN Code';

  constructor(
    public data: SupplierProductLink,
    public value: string
  ) {}
}

export class UpdateProductMOQ {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product MOQ';

  constructor(
    public data: SupplierProductLink,
    public value: number
  ) {}
}

export class UpdateProductWeight {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Weight';

  constructor(
    public data: SupplierProductLink,
    public value: number | null
  ) {}
}

export class UpdateProductVolume {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Volume';

  constructor(
    public data: SupplierProductLink,
    public value: number | null
  ) {}
}

export class UpdateProductPrice {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Price';

  constructor(
    public data: SupplierProductLink,
    public value: number
  ) {}
}

export class UpdateProductDeliveryTime {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product LeadTime';

  constructor(
    public data: SupplierProductLink,
    public value: number
  ) {}
}

export class UpdateProductPreferred {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Preferred';

  constructor(
    public data: SupplierProductLink,
    public value: boolean
  ) {}
}

export class UpdateAllProductPreferred {
  static readonly type =
    '[Linked Supplier Products Editor] Update All Product Preferred';

  constructor(
    public data: SupplierProductLink[],
    public value: boolean
  ) {}
}

export class UpdateProductAvailability {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Availability';

  constructor(
    public data: SupplierProductLink,
    public value: boolean
  ) {}
}

export class UpdateProductAvailabilityDate {
  static readonly type =
    '[Linked Supplier Products Editor] Update Supplier Product Availability Date';

  constructor(
    public data: SupplierProductLink,
    public value: string
  ) {}
}

export class LinkNewSupplierProduct {
  static readonly type =
    '[Linked Supplier Products Editor] Link New Supplier Product';
}

export class RemoveAddedProductSupplier {
  static readonly type =
    '[Linked Supplier Products Editor] Remove Added Product Supplier';

  constructor(public supplierProductId: string) {}
}

export class ResetEditor {
  static readonly type = '[Linked Supplier Products Editor] Reset Editor';
}

export class SaveLinkedSupplierProductsEditor {
  static readonly type =
    '[Linked Supplier Products Editor] Save Linked Supplier Products Editor';
}

export class CancelSupplierProductsEdit {
  static readonly type =
    '[Linked Supplier Products Editor] Cancel Supplier Products Edit';
}

export class ResetAvailableSuppliers {
  static readonly type =
    '[Linked Supplier Products Editor] Reset Available Suppliers';
}

export class RemoveLinkSupplierProduct {
  static readonly type =
    '[Linked Supplier Products Editor] Remove Link Supplier Product';

  constructor(public supplierProductUuid: string) {}
}
