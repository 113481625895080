import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from 'src/app/core/api/integrations/model/integrations.model';

@Pipe({
  name: 'jobStatus',
})
export class JobStatusPipe implements PipeTransform {
  transform(status: string): string {
    return JobStatus[status];
  }
}
