import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
} from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';
import { SearchAutoComplete } from 'src/app/shared/components/search/model/search.model';

import { AddPromotionWebshopProductData } from 'src/app/shared/models/promotion/promotion.model';
import {
  promotionUpliftOptions,
  PromotionUpliftTypes,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import {
  CreateWebshopProductPromotion,
  ResetAddProductPromotionDialogState,
  ResetSelectedPromotion,
  UpdateSelectedPromotion,
  UpdateSpecificUpliftIncrease,
  UpdateSpecificUpliftType,
} from './actions/add-product-promotion.actions';
import { AddProductPromotionsStateQueries } from './state/add-product-promotion.queries';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-add-product-promotions-dialog',
  templateUrl: './add-product-promotion-dialog.component.html',
  styleUrls: ['./add-product-promotion-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductPromotionDialogComponent implements OnDestroy {
  @Select(AddProductPromotionsStateQueries.saving)
  saving$: Observable<boolean>;

  @Select(AddProductPromotionsStateQueries.promotions)
  filteredPromotions$: Observable<SearchAutoComplete[]>;

  @Select(AddProductPromotionsStateQueries.promotion)
  promotion$: Observable<SearchAutoComplete[]>;

  @Select(AddProductPromotionsStateQueries.upliftType)
  upliftType$: Observable<string | null>;

  @Select(AddProductPromotionsStateQueries.upliftIncrease)
  upliftIncrease$: Observable<number | null>;

  @Select(AddProductPromotionsStateQueries.promotionEnabled)
  promotionEnabled$: Observable<boolean>;

  @Select(AddProductPromotionsStateQueries.canEditUpliftIncrease)
  canEditUpliftIncrease$: Observable<boolean>;

  searchBy$: Subject<string> = new BehaviorSubject<string>(null);

  readonly UPLIFT_TYPES_OPTIONS = promotionUpliftOptions;

  readonly UPLIFT_TYPES = PromotionUpliftTypes;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly DIALOG_STRINGS = STRINGS.metadata.addProductPromotionDialog;

  readonly COMMON_STRINGS = MESSAGES.common;

  constructor(
    @Inject(DIALOG_DATA) public data: AddPromotionWebshopProductData,
    private dialogRef: DialogRef<AddProductPromotionDialogComponent>,
    private store: Store
  ) {}

  updateSelectedPromotion(promotion: SearchAutoComplete): void {
    this.store.dispatch(new UpdateSelectedPromotion(promotion.uuid));
  }

  updateSpecificUpliftType(value: string): void {
    this.store.dispatch(new UpdateSpecificUpliftType(value));
  }

  updateSpecificUpliftIncrease(value: string): void {
    this.store.dispatch(new UpdateSpecificUpliftIncrease(value));
  }

  saveAddPromotion(): void {
    this.store.dispatch(
      new CreateWebshopProductPromotion(
        this.data.webshopProductUuid,
        this.dialogRef
      )
    );
  }

  searchBy(param: string[] | null): void {
    if (param.length === 0) {
      this.store.dispatch(new ResetSelectedPromotion());
    }

    this.searchBy$.next(param.join());
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetAddProductPromotionDialogState());
  }
}
