import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { MatIconModule } from '@angular/material/icon';
import { DialogTitleDirective } from './directives/dialog-title.directive';
import { DialogActionsDirective } from './directives/dialog-actions.directive';
import { DialogHeaderDirective } from './directives/dialog-header.directive';
import { DialogBodyDirective } from './directives/dialog-body.directive';

@NgModule({
  declarations: [
    DialogTitleDirective,
    DialogActionsDirective,
    DialogHeaderDirective,
    DialogBodyDirective,
  ],
  imports: [CommonModule, OverlayModule, MatIconModule],
  exports: [
    DialogTitleDirective,
    DialogActionsDirective,
    DialogHeaderDirective,
    DialogBodyDirective,
  ],
})
export class DialogContainerModule {}
