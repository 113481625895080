import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ExportDataV2 } from 'src/app/shared/models/exports/exports-v2.model';
import { LinkedSupplierUpdateProperties } from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';

export class InitializeWebshopProductSuppliersState {
  static readonly type = '[Webshop Product Suppliers V2] Initialize';
}

export class LoadWebshopProductSuppliers {
  static readonly type =
    '[Webshop Product Suppliers V2] Load Webshop Product Suppliers';
}

export class ReloadDatatable {
  static readonly type = '[Webshop Product Suppliers V2] Reload';
}

export class AddSearchParam {
  static readonly type = '[Webshop Product Suppliers V2] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Webshop Product Suppliers V2] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Webshop Product Suppliers V2] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Webshop Product Suppliers V2] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Webshop Product Suppliers V2] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Webshop Product Suppliers V2] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Webshop Product Suppliers V2] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Webshop Product Suppliers V2] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Webshop Product Suppliers V2] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Webshop Product Suppliers V2] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Webshop Product Suppliers V2] Toggle Filter';
}

export class EditWebshopProductSuppliers {
  static readonly type =
    '[Webshop Product Supplier] Edit Webshop Product Suppliers';
}

export class CancelEditWebshopProductSuppliers {
  static readonly type =
    '[Webshop Product Supplier] Cancel Edit Webshop Product Suppliers';
}

export class LoadSuppliersToLink {
  static readonly type = '[Webshop Product Supplier] Load Supplier To Link';

  constructor(public search: string = '') {}
}

export class LinkSupplierToAProduct {
  static readonly type =
    '[Webshop Product Supplier] Link Supplier To A Product';

  constructor(public properties: LinkedSupplierUpdateProperties) {}
}

export class ExportProductSuppliersV2 {
  static readonly type = '[Webshop Product Supplier] Export';

  constructor(public exportData: ExportDataV2) {}
}

export class ResetPaginationAndLoadData {
  static readonly type =
    '[Webshop Product Supplier] Reset Pagination And Load Data';
}
