<span class="tag-input-wrapper">
  <form
    #tagForm="ngForm"
    #tagContainer
    [ngClass]="baseContainerClasses"
    [class.multiline]="multiline"
    [class.empty]="empty"
    [class.invalid]="tagForm.status === 'INVALID' || !!duplicatedTag()"
    [class.tags-input-disabled]="disabled">
    <div #tagsWrapper class="tags-container" [class.multiline]="multiline">
      @for (tag of value; track tag) {
        <app-tag
          [value]="tag"
          (click)="$event.stopPropagation()"
          (removed)="remove($event)"
          [disabled]="disabled"
          [color]="tag === duplicatedTag() ? 'red' : tagsColor"
          [variant]="tagsVariant"
          [rounded]="tagsRounded"
          [removable]="tagsRemovable"
          >{{ tag }}</app-tag
        >
      }

      <input
        class="tag-input"
        [class.disabled]="disabled"
        [(ngModel)]="tagInput"
        name="tagInput"
        [placeholder]="placeholder"
        (input)="onInput()"
        (keydown)="onKeyDown($event)"
        (blur)="onBlur()"
        [required]="required"
        [email]="inputType === 'email'"
        [disabled]="disabled"
        (focus)="onFocus()" />
    </div>
  </form>

  @if (!!duplicatedTag()) {
    <p class="error" [class.error-float]="floatErrors">
      @if (duplicatedError) {
        {{ duplicatedError }}

        @if (showDuplicated) {
          {{ duplicatedTag() }}
        }
      } @else {
        Duplicated tag: {{ duplicatedTag() }}
      }
    </p>
  }

  @if (!duplicatedTag() && tagForm.status === 'INVALID') {
    <p class="error" [class.error-float]="floatErrors">
      @if (isTextInput) {
        This field is invalid
      } @else if (isEmailInput) {
        Enter a valid email
      } @else {
        Enter a valid value
      }
    </p>
  }
</span>
