import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextId = 0;

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  id: number;

  @Input()
  label: string;

  @Input()
  checked: boolean;

  @Input()
  indeterminate: boolean;

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  /** Whether the checkbox is only for presentation purpose without any click listener and without tabindex */
  @Input()
  decorative: boolean = false;

  @Output()
  checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onTouched = (_v: any) => {};

  onChanged = (_v: any) => {};

  constructor() {
    this.id = ++nextId;
  }

  handleClick(): void {
    if (this.disabled) return;

    this.checkedChange.emit(!this.checked);
  }

  writeValue(checked: boolean): void {
    this.value = checked;
    this.onTouched(checked);
    this.onChanged(checked);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  set value(checked: boolean) {
    this.checked = checked;
  }

  get checkboxId(): string {
    return `checkbox-input-${this.id}`;
  }

  get tabIndex(): string {
    return this.decorative || this.disabled ? '-1' : '0';
  }
}
