export const STRINGS = {
  title: $localize`Settings`,
  emailTemplate: {
    subjectError: $localize`Subject field is required`,
    bodyError: $localize`Email body field is required`,
  },
  general: {
    title: $localize`General`,
    gln: {
      title: $localize`Global Location Number (GLN)`,
      description: $localize`The GLN is a unique 13-digit identifier used to identify the specific physical or operational location of your warehouse. This number is used when placing orders through EDI.`,
      tooltip: $localize`Click here for more information about EDI service`,
      placeholder: $localize`Global Location Number`,
    },
    defaultReceiptQuantity: {
      title: $localize`Default receipt quantity`,
      description: $localize`Choose the default receipt quantity shown in the delivery editor.`,
      options: {
        remaining: $localize`Remaining quantity`,
        zero: $localize`Zero`,
      },
    },
    stockCosts: {
      title: $localize`Inventory keeping costs`,
      description: $localize`We estimate inventory keepings cost using a percentage of your
  inventory value. What yearly interest percentage of your purchase
  price would you like to use, taking into account the cost of
  interest, space and risk?`,
      validations: {
        min: $localize`Inventory cost must be greater than 0%`,
        max: $localize`Inventory cost must be less than 100%`,
        required: $localize`Inventory cost field is required`,
        mustBeNumber: $localize`Inventory cost must be an integer value`,
      },
    },
    currency: {
      title: $localize`Currency`,
      description: $localize`Preferred currency`,
    },
    timezone: {
      title: $localize`Time zone`,
      description: $localize`Preferred time zone`,
    },
  },
  abcClassification: {
    title: $localize`ABC classification`,
    inputData: {
      title: $localize`ABC settings`,
      description: $localize`Product information to determine the ABC category of your products.`,
      validations: {
        required: $localize`ABC settings is required`,
      },
    },
    serviceLevel: {
      title: $localize`Categories & Service level`,
      description: $localize`Determine how the categories are distributed over products and set their respective desired service level per category.`,
      serviceLevelMargin: {
        title: $localize`Desired service level (%)`,
        validations: {
          required: $localize`Service level fields are required`,
          range: $localize`Service level must be >= 0% and < 100%`,
        },
      },
    },
    sellOrderThreshold: {
      title: $localize`Sell order threshold`,
      description: $localize`Minimal amount of sell orders that are needed for a product to 'exit' C
      category.
      Mainly used in combination with desired service level C = 0%.`,
    },
    historyLength: {
      title: $localize`History length`,
      description: $localize`Number of days in the past that will be used to determine your product categories.`,
    },
  },
  abcXyzClassification: {
    title: $localize`ABC - XYZ classification`,
    abcSettings: {
      title: $localize`ABC settings`,
      description: $localize`Product information to determine how your products are distributed into ABC category.`,
    },
    xyzSettings: {
      title: $localize`XYZ settings`,
      description: $localize`Product information to determine how your products are distributed into XYZ category.`,
    },
    serviceLevel: {
      title: $localize`Desired service level per category`,
      description: $localize`Set respective desired service level per category.`,
      update: $localize`Update service level`,
      preventChangesWarning: $localize`Desired service level can be updated once every`,
    },
  },
  algorithm: {
    title: $localize`Algorithm`,
    yearlySeasonalityForecastAdjustment: {
      title: $localize`Yearly seasonality`,
      description: $localize`Proportion of effect of last years sales in your product forecast. The lower the value, the higher the impact from current sales trends.`,
    },
    outlierFiltering: {
      title: $localize`Outlier filtering`,
      description: $localize`Should outliers be filtered when forecasting?`,
    },
    wholeSale: {
      title: $localize`Wholesale`,
      description: $localize`This setting changes parts of the algorithm that are important to wholesalers. Think of outlier filtering and imputation of sales data.`,
    },
  },
  userManagement: {
    title: $localize`Users`,
    supportEmail: {
      title: $localize`Support email`,
      description: $localize`Email responsible for support.`,
    },
    inviteUsers: {
      description: $localize`All users with access to this webshop`,
    },
  },
  integrations: {
    title: $localize`Integrations`,
    help: $localize`Click to learn more about setting up integrations.`,
    warning: $localize`Billing will start after you set up an integration or create your API keys.`,
  },
  emails: {
    title: $localize`Email settings`,
    customTemplate: {
      title: $localize`Custom email template`,
      description: $localize`Create a custom email template, selectable when placing an order on the Purchase page or resending an email on the Deliveries page.`,
    },
    emailTemplates: {
      title: $localize`Email templates`,
      description: $localize`Customize the email template to be sent from this account. Use a semicolon (";") to separate e-mail addresses.`,
    },
    ccEmails: {
      title: $localize`CC address(es)`,
      placeholder: $localize`Enter an email`,
      validations: {
        duplicated: $localize`Duplicated email:`,
      },
    },
  },
  sourceJobs: {
    errorWarning: $localize`Any missing data from this job will be restored in the next successful sync.`,
    noData: $localize`There is no data to show.`,
    waitWarning: $localize`Please wait while the job is being processed.`,
    complete: $localize`Complete`,
    queued: $localize`Queued`,
    failed: $localize`Failed`,
    running: $localize`Running`,
    completeWithFails: $localize`Complete with fails`,
    errors: {
      failed: $localize`Failed`,
      failedDescription: $localize`View all the failed exports records along with their IDs for this particular integration sync.`,
      buyOrderId: $localize`Buy order id`,
      errorDescription: $localize`Error description`,
    },
    successes: {
      succeed: $localize`Succeeded`,
      succeedDescription: $localize`View all the succeeded exports records along with their IDs for this particular integration sync.`,
      buyOrderId: $localize`Buy order id`,
      remoteId: $localize`Remote id`,
    },
  },
  inputData: {
    margin: $localize`Margin`,
    revenue: $localize`Revenue`,
    sellOrderLines: $localize`No. of sell order lines`,
  },
  tooltips: {
    refresh: $localize`Refresh`,
  },
  menu: {
    general: $localize`General`,
    classification: $localize`ABC classification`,
    abcXyzclassification: $localize`ABC - XYZ classification`,
    algorithm: $localize`Algorithm`,
    integrations: $localize`Integrations`,
    userManagement: $localize`Users`,
    billingInvoicing: $localize`Billing & Invoicing`,
    emails: $localize`Email settings`,
  },
  additionalSettings: $localize`Additional settings`,
  infoBanner: $localize`Please contact your CSM if you need to make any changes to these settings.`,
  orderManagement: {
    title: $localize`Inventory control`,
    backorders: {
      title: $localize`Backorders`,
      description: $localize`Generate orders to replenish negative stock levels, restoring inventory to zero or the supplier's minimum stock settings.`,
    },
    lostSales: {
      title: $localize`Lost sales`,
      description: $localize`Generate orders to recover potential sales lost due to stock unavailability, based on supplier-specific settings.`,
    },
    productMaxStock: {
      title: $localize`Product maximum stock level`,
      description: $localize`Allow setting maximum stock level for products.`,
    },
    timeDependentStock: {
      title: $localize`Time dependent stock position`,
      description: $localize`Count only incoming stock within the current order cycle.`,
    },
    advancedCategorization: {
      title: $localize`Advanced categorization`,
      description: $localize`ABC + XYZ categorization adds another dimension to further optimize your assortment management.`,
    },
  },
  dataManagement: {
    title: $localize`Data management`,
    imports: {
      title: $localize`Imports`,
      description: $localize`Allow files to be imported to Optiply.`,
    },
    exports: {
      title: $localize`Exports`,
      description: $localize`Allow files to be exported from Optiply.`,
    },
  },
  miscellaneous: {
    title: $localize`Miscellaneous`,
    promotions: {
      title: $localize`Promotions`,
      description: $localize`Create and track promotional campaigns that influence Optiply’s demand forecast.`,
    },
    multiSupplier: {
      title: $localize`Multi supplier`,
      description: $localize`Allow adding more than 1 supplier per webshop product.`,
    },
    containers: {
      title: $localize`Containers`,
      description: $localize`Manage container shipments efficiently based on product weights and volumes.`,
    },
  },
  validations: {
    outOfBounds: $localize`The values must be between 0% and 100%`,
    totalSum: $localize`Total value must be 100%`,
    multipleRequired: $localize`All fields are required`,
    required: $localize`This field is required`,
    greaterThan: $localize`The value must be greater than`,
    greaterOrEqualTo: $localize`The value must be greater than or equal to`,
    lessThan: $localize`The value must be less than`,
    lessOrEqualTo: $localize`The value must be less than or equal to`,
  },
};
