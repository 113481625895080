import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

export interface PageEventV2 {
  length: number;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
  @Input({ transform: booleanAttribute })
  showFirstLastButtons: boolean = false;

  @Input({ transform: booleanAttribute })
  loading: boolean = false;

  @Input({ transform: numberAttribute })
  pageIndex: number = 0;

  @Input({ transform: numberAttribute })
  length: number = 0;

  @Input()
  pageSizeOptions: number[];

  @Input({ transform: numberAttribute })
  pageSize: number = 50;

  @Output()
  paginationChanged: EventEmitter<PageEventV2> =
    new EventEmitter<PageEventV2>();

  readonly PAGINATION_STRINGS = MESSAGES.common.pagination;

  changePageSize() {
    const startIndex = this.pageIndex * this.pageSize;

    this.pageIndex = Math.floor(startIndex / this.pageSize) || 0;
    this._paginationChanged();
  }

  firstPage(): void {
    if (!this.hasPreviousPage) {
      return;
    }

    this.pageIndex = 0;
    this._paginationChanged();
  }

  nextPage(): void {
    if (!this.hasNextPage) {
      return;
    }

    this.pageIndex = this.pageIndex + 1;
    this._paginationChanged();
  }

  previousPage(): void {
    if (!this.hasPreviousPage) {
      return;
    }

    this.pageIndex = this.pageIndex - 1;
    this._paginationChanged();
  }

  lastPage(): void {
    if (!this.hasNextPage) {
      return;
    }

    this.pageIndex = this.getNumberOfPages() - 1;
    this._paginationChanged();
  }

  getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }

    const numberOfPages = Math.ceil(this.length / this.pageSize);

    this._checkOverPagination(numberOfPages);

    return numberOfPages;
  }

  /**
   * Checks over pagination to prevent the user to be in a page (page index) without results.
   *
   * The pagination is emitted with the pageIdx - 1 (while pageIdx > 0) in order to get results from the previous page.
   * @param numberOfPages number
   */
  private _checkOverPagination(numberOfPages: number): void {
    if (this.pageIndex > 0 && this.pageIndex >= numberOfPages) {
      this.pageIndex = 0;
      this._paginationChanged();
    }
  }

  private _paginationChanged(): void {
    this.paginationChanged.emit({
      length: this.length,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
  }

  get hasData(): boolean {
    return this.length !== 0 && this.pageSize !== 0;
  }

  get startResultIndex(): number {
    const startIndex = this.pageIndex * this.pageSize;

    return startIndex + 1;
  }

  get endResultIndex(): number {
    const length = Math.max(this.length, 0);

    const startIndex = this.startResultIndex - 1;

    const endIndex =
      startIndex < length
        ? Math.min(startIndex + this.pageSize, length)
        : startIndex + this.pageSize;

    return endIndex;
  }

  get currentPage(): number {
    if (this.getNumberOfPages() === 0) {
      return 0;
    }

    return this.pageIndex + 1;
  }

  get totalPages(): number {
    return this.getNumberOfPages();
  }

  get hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  get hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }
}
