export interface PageRequestInfoV2 {
  previousPageIndex?: number;
  page: number;
  limit: number;
  sort?: SortInfoV2;
}

export interface PageableV2 {
  totalElements: number;
}

export interface SortInfoV2 {
  direction: string;
  by: string;
}

export type ServiceRequestInfoV3SortByOrder = 'ASC' | 'DESC';

export enum ServiceRequestInfoV3QueryingTypes {
  STRING = 'type.googleapis.com/google.protobuf.StringValue',
  LIST = 'type.googleapis.com/com.optiply.services.api.search.v1.QueryList',
}

export enum ServiceRequestInfoV3FilteringType {
  STRING = 'STRING',
  RANGED = 'RANGED',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  DATETIME = 'DATETIME',
  TIME = 'TIME',
  NOT_EXISTS = 'NOT_EXISTS',
  IS_NULL = 'IS_NULL',
  NULLABLE = 'NULLABLE',
}

export enum ServiceRequestInfoV3FilteringTypes {
  STRING = 'type.googleapis.com/com.optiply.services.api.search.v1.StringValueFilter',
  RANGED = 'type.googleapis.com/com.optiply.services.api.search.v1.RangedValueFilter',
  BOOLEAN = 'type.googleapis.com/com.optiply.services.api.search.v1.BooleanValueFilter',
  INTEGER = 'type.googleapis.com/com.optiply.services.api.search.v1.IntValueFilter',
  FLOAT = 'type.googleapis.com/com.optiply.services.api.search.v1.FloatValueFilter',
  DATETIME = 'type.googleapis.com/com.optiply.services.api.search.v1.DateTimeValueFilter',
  TIME = 'type.googleapis.com/com.optiply.services.api.search.v1.TimeValueFilter',
  NOT_EXISTS = 'type.googleapis.com/com.optiply.services.api.search.v1.NotExistsFilter',
  IS_NULL = 'type.googleapis.com/com.optiply.services.api.search.v1.NullFilter',
  NULLABLE = 'type.googleapis.com/com.optiply.services.api.search.v1.NullFilter',
}

export enum ServiceRequestInfoV3FilteringRangedTypes {
  INTEGER = 'type.googleapis.com/google.protobuf.Int32Value',
  FLOAT = 'type.googleapis.com/google.protobuf.FloatValue',
  DATETIME = 'type.googleapis.com/com.optiply.services.api.search.v1.DateTimeValue',
}

export enum ServiceRequestInfoV3Operators {
  OR = 'any_of',
  AND = 'match',
  XOR = 'no_match',
}

export type ServiceRequestInfoV3Operator =
  | ServiceRequestInfoV3Operators.OR
  | ServiceRequestInfoV3Operators.AND
  | ServiceRequestInfoV3Operators.XOR;

export interface ServiceRequestInfoV3RangedFilterValue {
  '@type': string;
  value: string | number;
}

export interface ServiceRequestInfoV3RangedFilter {
  [key: string]: {
    from: ServiceRequestInfoV3RangedFilterValue;
    to: ServiceRequestInfoV3RangedFilterValue;
  };
}

export interface ServiceRequestInfoV3BooleanFilter {
  [key: string]: boolean;
}

export interface ServiceRequestInfoV3StringFilter {
  [key: string]: {
    values: string[];
  };
}

export interface ServiceRequestInfoV3DateFilter
  extends ServiceRequestInfoV3StringFilter {}

export interface ServiceRequestInfoV3IntFilter {
  [key: string]: {
    values: number[];
  };
}

export interface ServiceRequestInfoV3FloatFilter
  extends ServiceRequestInfoV3IntFilter {}

export interface ServiceRequestInfoV3NullFilter {
  values: string[];
}

export interface ServiceRequestInfoV3FilteringBase {
  '@type': string;
}

export interface ServiceRequestInfoV3Filter
  extends ServiceRequestInfoV3FilteringBase {
  scale?: number;
  values?:
    | ServiceRequestInfoV3RangedFilter
    | ServiceRequestInfoV3StringFilter
    | ServiceRequestInfoV3DateFilter
    | ServiceRequestInfoV3IntFilter
    | ServiceRequestInfoV3FloatFilter
    | ServiceRequestInfoV3NullFilter;
}

export interface ServiceRequestInfoV3Query
  extends ServiceRequestInfoV3FilteringBase {
  value: string | string[];
}

export interface ServiceRequestInfoV3Filters {
  any_of: ServiceRequestInfoV3Filter[];
  match: ServiceRequestInfoV3Filter[];
  no_match: ServiceRequestInfoV3Filter[];
}

export interface ServiceRequestInfoV3SortBy {
  field: string;
  order: ServiceRequestInfoV3SortByOrder;
}

export interface ServiceRequestInfoV3Page {
  from: number;
  size: number;
  page_by_id?: boolean;
}

export interface ServiceRequestInfoV3QueryData {
  query?: ServiceRequestInfoV3Query;
  filters: ServiceRequestInfoV3Filters;
  sort_by: ServiceRequestInfoV3SortBy[];
  page: ServiceRequestInfoV3Page;
}

export interface ServiceRequestInfoV3 {
  queryData: ServiceRequestInfoV3QueryData;
}

export interface ServiceRequestInfoV2 {
  page: number;
  size: number;
  sortBy: string;
  order: string;
  filter: string;
}
