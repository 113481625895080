import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import {
  Promotions,
  WebshopProductPromotions,
} from 'src/app/shared/models/promotion/v2/promotions-v2.model';
import { PromotionsV2Adapter } from './adapters/promotions-v2.adapter';
import { WebshopProductPromotionsV2Adapter } from './adapters/webshop-product-promotions-v2.adapter';
import {
  Promotions as PromotionsV1,
  WebshopProductPromotions as WebshopProductPromotionsV1,
} from './model/promotions-v2.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import {
  CreatePromotionProperties,
  UpdatePromotionProperties,
  UpdatePromotionsProperties,
} from 'src/app/shared/models/promotion/promotion.model';

@Injectable({
  providedIn: 'root',
})
export class PromotionsV2Service {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findAllPromotions(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<PromotionsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v2',
      'findAll',
      replacements
    );
    return this.http.post<PromotionsV1>(endpoint, payload);
  }

  private _findAllWebshopProductPromotions(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<WebshopProductPromotionsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v2',
      'findAllWebshopProductPromotions',
      replacements
    );
    return this.http.post<WebshopProductPromotionsV1>(endpoint, payload);
  }

  private _findAllWebshopProductUnassociatedPromotions(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<PromotionsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v2',
      'findAllWebshopProductUnassociatedPromotions',
      replacements
    );
    return this.http.post<PromotionsV1>(endpoint, payload);
  }

  private _updatePromotion(
    payload: UpdatePromotionProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v2',
      'update',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _createPromotion(
    payload: CreatePromotionProperties
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v2',
      'create',
      []
    );
    return this.http.post(endpoint, payload);
  }

  private _updatePromotions(
    payload: UpdatePromotionsProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v2',
      'updatePromotions',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  findAllPromotions(
    webshopUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<Promotions> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findAllPromotions(payload, replacements).pipe(
      switchMap(data => of(new PromotionsV2Adapter().transform(data)))
    );
  }

  findAllWebshopProductPromotions(
    webshopUuid: string,
    webshopProductUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<WebshopProductPromotions> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: webshopProductUuid },
    ];

    return this._findAllWebshopProductPromotions(payload, replacements).pipe(
      switchMap(data =>
        of(new WebshopProductPromotionsV2Adapter().transform(data))
      )
    );
  }

  findAllWebshopProductUnassociatedPromotions(
    webshopUuid: string,
    webshopProductUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<Promotions> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: webshopProductUuid },
    ];

    return this._findAllWebshopProductUnassociatedPromotions(
      payload,
      replacements
    ).pipe(switchMap(data => of(new PromotionsV2Adapter().transform(data))));
  }

  updatePromotion(
    properties: UpdatePromotionProperties,
    promotionUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'uuid', value: promotionUUID },
    ];

    return this._updatePromotion(properties, replacements);
  }

  createPromotion(properties: CreatePromotionProperties) {
    return this._createPromotion(properties);
  }

  updatePromotions(
    properties: UpdatePromotionsProperties,
    webshopUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._updatePromotions(properties, replacements);
  }
}
