import { Injectable } from '@angular/core';
import {
  Actions,
  ofActionDispatched,
  ofActionErrored,
  ofActionSuccessful,
  Store,
} from '@ngxs/store';
import {
  ConnectWebSocket,
  WebSocketConnectionUpdated,
  WebSocketDisconnected,
  WebSocketMessageError,
} from '@ngxs/websocket-plugin';
import { debounceTime } from 'rxjs';
import { ReconnectWebsocket } from '../actions/purchase-v3.actions';

@Injectable({
  providedIn: 'root',
})
export class SocketHandlingService {
  constructor(
    private actions: Actions,
    private store: Store
  ) {
    this.actions
      .pipe(ofActionDispatched(WebSocketMessageError))
      .subscribe(() => {});

    this.actions.pipe(ofActionSuccessful(ConnectWebSocket)).subscribe(() => {});

    this.actions.pipe(ofActionErrored(ConnectWebSocket)).subscribe(() => {});

    this.actions
      .pipe(ofActionSuccessful(WebSocketDisconnected), debounceTime(1000))
      .subscribe(() => {
        this.store.dispatch(new ReconnectWebsocket());
      });

    this.actions
      .pipe(ofActionErrored(WebSocketDisconnected))
      .subscribe(() => {});

    this.actions
      .pipe(ofActionDispatched(WebSocketConnectionUpdated))
      .subscribe(() => {});
  }
}
