import { DateTime } from 'luxon';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Promotion } from '../../v2/model/promotions-v2.model';
import { PromotionV1 } from '../model/promotions.model';
import { PromotionUpliftTypes } from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';

export class PromotionAdapter implements DataAdapter<PromotionV1, Promotion> {
  transform(promotion: PromotionV1): Promotion {
    return {
      uuid: promotion.promotion.uuid,
      webshopUUID: promotion.promotion.webshopUUID,
      name: promotion.promotion.name,
      interval: {
        start: this.convertDate(promotion.promotion.interval.start),
        end: this.convertDate(promotion.promotion.interval.end),
      },
      uplift: {
        increase: promotion.promotion.uplift.increase,
        type: promotion.promotion.uplift.type,
      },
      numberOfProducts: promotion.promotion.numberOfProducts,
      settings: {
        enabled: promotion.promotion.settings.enabled,
      },
      createdAt: this.convertDate(promotion.promotion.createdAt),
      updatedAt: this.convertDate(promotion.promotion.updatedAt),
      deletedAt: this.convertDate(promotion.promotion.deletedAt),
      extras: {
        formattedUpliftType: this._buildFormattedUpliftType(
          promotion.promotion.uplift.type
        ),
      },
      id: promotion.promotion.id,
      notes: promotion.promotion.notes,
    };
  }

  /**
   * Converts a string with a value in second to an actual date
   * @param date
   * @returns date
   */
  convertDate(date: string): string {
    const dateFormat = 'yyyy-MM-dd';
    return DateTime.fromSeconds(Number(date)).toFormat(dateFormat);
  }

  private _buildFormattedUpliftType(upliftType: string) {
    const upliftTypes = {
      [PromotionUpliftTypes.RELATIVE]:
        STRINGS.metadata.promotions.uplifts.relative,
      [PromotionUpliftTypes.ABSOLUTE]:
        STRINGS.metadata.promotions.uplifts.absolute,
      [PromotionUpliftTypes.CLOSE_OUT]:
        STRINGS.metadata.promotions.uplifts.closeOut,
      [PromotionUpliftTypes.NO_UPLIFT]: '-',
    };

    return upliftTypes[upliftType] || NOT_AVAILABLE_VALUE;
  }
}
