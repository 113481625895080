import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-data-table-refresh-button',
  templateUrl: './data-table-refresh-button.component.html',
  styleUrl: './data-table-refresh-button.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableRefreshButtonComponent {
  loading = input<boolean>(false);

  disabled = input<boolean>(false);

  refreshed = output<void>();

  readonly COMMON_STRINGS = MESSAGES.common;
}
