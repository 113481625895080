<div class="search-container">
  <span class="icon-container">
    <mat-icon svgIcon="magnifying-glass"></mat-icon>
  </span>

  <input
    #searchInput
    appInput
    variant="filled"
    type="text"
    [formControl]="searchBy"
    [placeholder]="placeholder" />
</div>
