import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { NavigateTo } from 'src/app/core/actions/navigation.action';

@Component({
  selector: 'app-basic-context-nav-bar',
  templateUrl: './basic-context-nav-bar.component.html',
  styleUrls: ['./basic-context-nav-bar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicContextNavBarComponent {
  @Input() titleName: string;

  @Input() subTitle: string;

  @Input() routing: string;

  @Input() showOverview: boolean = false;

  constructor(private store: Store) {}

  /**
   * Navigates to the the previous page
   */
  navigate(): void {
    if (this.routing) {
      this.store.dispatch(new NavigateTo([this.routing]));
    }
  }

  /**
   * Returns the string that should be on the breadcrumb title's tooltip
   * @returns back string
   */
  goBackString(): string {
    const goBackTo = $localize`Go back to `;
    return goBackTo + this.titleName;
  }
}
