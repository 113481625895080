import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagComponent } from './tag.component';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from '../tooltip/tooltip.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagIconDirective } from './directives/tag-icon.directive';

@NgModule({
  declarations: [TagComponent, TagIconDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    TooltipModule,
  ],
  exports: [TagComponent, TagIconDirective],
})
export class TagsModule {}
