import { Selector } from '@ngxs/store';
import {
  PurchaseConfirmationState,
  PurchaseConfirmationStateModel,
} from './purchase-confirmation-dialog-v2.state';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

export class PurchaseConfirmationQueries {
  @Selector([PurchaseConfirmationState])
  static state(
    state: PurchaseConfirmationStateModel
  ): PurchaseConfirmationStateModel {
    return state;
  }

  @Selector([PurchaseConfirmationState])
  static loading(state: PurchaseConfirmationStateModel): boolean {
    return state.loading;
  }

  @Selector([PurchaseConfirmationState])
  static planningDate(state: PurchaseConfirmationStateModel): Date {
    return state.planningDate;
  }

  @Selector([PurchaseConfirmationState])
  static planningMinDate(state: PurchaseConfirmationStateModel): Date {
    return state.planningMinDate;
  }

  @Selector([PurchaseConfirmationState])
  static replanEnabled(state: PurchaseConfirmationStateModel): boolean {
    return state.replanEnabled;
  }

  @Selector([PurchaseConfirmationState])
  static shouldReplan(state: PurchaseConfirmationStateModel): boolean {
    return (
      (state.orderMomentType === 'REGULAR' ||
        state.orderMomentType === 'REGULAR_PREPONED') &&
      new Date(state.orderMomentDate).getDay() !== state.todaysDate.getDay() &&
      !state.planningTrigger
    );
  }

  @Selector([PurchaseConfirmationState])
  static daysToNextOrderMoment(state: PurchaseConfirmationStateModel): number {
    return state.daysToNextOrderMoment;
  }

  @Selector([PurchaseConfirmationState])
  static nextOrderMomentDate(state: PurchaseConfirmationStateModel): string {
    return state.nextOrderMomentDate;
  }

  @Selector([PurchaseConfirmationState])
  static useSupplierSettings(state: PurchaseConfirmationStateModel): boolean {
    return state.useSupplierSettings;
  }

  @Selector([PurchaseConfirmationState])
  static isNewOrder(state: PurchaseConfirmationStateModel): boolean {
    return state.newOrder;
  }

  @Selector([PurchaseConfirmationState])
  static shouldSaveWebshopProductChanges(
    state: PurchaseConfirmationStateModel
  ): boolean {
    return state.shouldSaveWebshopProductChanges;
  }

  @Selector([PurchaseConfirmationState])
  static shouldSaveSupplierProductChanges(
    state: PurchaseConfirmationStateModel
  ): boolean {
    return state.shouldSaveSupplierProductChanges;
  }

  @Selector([PurchaseConfirmationState])
  static isOrderNotSynced(state: PurchaseConfirmationStateModel): boolean {
    return state.isOrderNotSynced;
  }

  @Selector([PurchaseConfirmationState])
  static orderPlaced(state: PurchaseConfirmationStateModel): boolean {
    return state.orderUuid !== null;
  }

  @Selector([PurchaseConfirmationState])
  static supplierName(state: PurchaseConfirmationStateModel): string {
    return state.supplier.name;
  }

  @Selector([PurchaseConfirmationState])
  static buyOrderId(state: PurchaseConfirmationStateModel): number {
    return state.orderId;
  }
}
