export const STRINGS = {
  metadata: {
    promotionDetails: {
      title: $localize`Promotion details`,
      settingsTitle: $localize`Promotion settings`,
      promotionName: $localize`Name`,
      startDate: $localize`Start date`,
      startDatePlaceholder: $localize`Select start date`,
      endDate: $localize`End date`,
      endDatePlaceholder: $localize`Select end date`,
      endDateError: $localize`End date must be after start date`,
    },
    uplift: {
      title: $localize`Uplift`,
      uplift: $localize`Uplift`,
      expectedUplift: $localize`Expected uplift`,
      expectedUpliftError: $localize`Value can't be zero`,
    },
    settings: {
      title: $localize`Settings`,
      enabled: $localize`Enable promotion`,
      enabledDescription: $localize`Enabling this promotion would affect the purchasing advice.`,
      applyEntire: $localize`Apply to entire webshop`,
      applyEntireDescription: $localize`Turning this on will apply this promotion to the entire webshop.`,
    },
  },
};
