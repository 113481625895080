import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  booleanAttribute,
  input,
  signal,
} from '@angular/core';
import { FILTER_CONTAINER } from '../../model/filter.model';
import { FilterEmitted } from '../../model/filter-v2.model';

@Component({
  selector: 'app-filter-container-template',
  templateUrl: './filter-container-template.component.html',
  styleUrls: ['./filter-container-template.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FILTER_CONTAINER,
      useExisting: FilterContainerTemplateComponent,
    },
  ],
})
export class FilterContainerTemplateComponent implements OnInit {
  headerTemplate = input<TemplateRef<unknown>>();

  footerTemplate = input<TemplateRef<unknown>>();

  filterName = input<string>();

  disableClear = input(false, { transform: booleanAttribute });

  disableApply = input(false, { transform: booleanAttribute });

  showApplyAll = input(false, { transform: booleanAttribute });

  applyAll = signal(false);

  @Output()
  apply: EventEmitter<FilterEmitted> = new EventEmitter<FilterEmitted>();

  @Output()
  clear: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.applyAll.set(this.showApplyAll());
  }

  onApply(): void {
    this.apply.emit({ applyAll: this.applyAll() });
  }
}
