import { InjectionToken } from '@angular/core';

export const OVERLAY_CONFIG = new InjectionToken<DefaultOverlayV2Config>(
  'Overlay Config'
);

export type OverlayAlignment = 'start' | 'center' | 'end';

export interface DefaultOverlayV2Config {
  alignment: OverlayAlignment;
  width?: number;
  minWidth: number;
  maxWidth: number;
  height?: number;
  minHeight?: number;
  maxHeight?: number;
  hasBackdrop: boolean;
  backdropClass: string;
  panelClass?: string | string[];
}

export const DEFAULT_OVERLAY_V2_CONFIG: DefaultOverlayV2Config = {
  alignment: 'start',
  minWidth: 220,
  maxWidth: 250,
  hasBackdrop: true, // Always true (with a transparent background) to close it clicking outside
  backdropClass: 'cdk-overlay-transparent-backdrop',
};
