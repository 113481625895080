import {
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Groups,
} from '../../../model/data-table-v2.model';

export interface ColumnChanged {
  groupKey: string;
  columnKey: string;
}

export interface ColumnToggled extends ColumnChanged {
  checked: boolean;
}

export interface ColumnSelectionV3 {
  columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>;
  applyAll: boolean;
}

export const fixSelectedTableColumnsOrder = (
  a: DatatableColumnV2ColumnGroup,
  b: DatatableColumnV2ColumnGroup,
  order: ColumnOrder
): number => {
  return (order[a.name] || Infinity) - (order[b.name] || Infinity);
};
