import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Build } from 'src/app/core/api/configuration/v1/build.model';

/**
 * Configuration service that contains all the front-end configurations, like
 * for instance, the api endpoints.
 */
@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(private http: HttpClient) {}

  private getConfigUri() {
    return 'assets/configuration-build.json';
  }

  /**
   * Loads the initial configuration.
   *
   * @returns Observable<Build>
   */
  private fetchBuildInfo(): Observable<Build> {
    return this.http.get<Build>(this.getConfigUri());
  }

  /**
   * Loads the environment configuration after it was resolved.
   *
   * @param configurationUri the configuration uri where to fetch the file from.
   * @returns Observable<any> the JSON configuration file if it is fetched
   *     sucessfully.
   */
  private fetchEnvConfig(configurationUri: string): Observable<any> {
    return this.http.get<any>(configurationUri);
  }

  /**
   * Resolves the configuration build, so it knows what environment
   * configuration to get. Throws an error if no configuration file is found.
   *
   * @param config the build configuration settings.
   * @returns Observable<any> JSON with the configuration.
   */
  private resolveConfigBuild(config: Build): Observable<any> {
    if (
      config.environment &&
      config.configurationPrefix &&
      config.configurationType
    ) {
      const configurationFile = config.configurationPrefix
        .concat(config.environment)
        .concat('.')
        .concat(config.configurationType);

      return this.fetchEnvConfig(configurationFile);
    }

    return throwError(
      () =>
        new Error(
          'The configuration file retrieved from the service is invalid'
        )
    );
  }

  /**
   * Loads the initial configuration.
   * @returns Observable<any> with the JSON configuration
   */
  load(): Observable<any> {
    return this.fetchBuildInfo().pipe(
      switchMap((build: Build) => this.resolveConfigBuild(build))
    );
  }
}
