<div class="credentials-container" [formGroup]="form">
  @for (parameter of linkedSource?.connectedUiParams; track parameter.key) {
    <div>
      <div class="section">
        <div class="field-title">
          <span>{{ parameter.label }}</span>
        </div>

        @switch (parameter.type) {
          @case ('boolean') {
            <app-toggle
              [formControl]="
                form.get(
                  getParameterKey(
                    parameter.key,
                    linkedSource.tenant,
                    linkedSource.tap
                  )
                )
              " />
          }

          @case ('select') {
            <app-dropdown
              type="outline"
              [formControlName]="
                getParameterKey(
                  parameter.key,
                  linkedSource.tenant,
                  linkedSource.tap
                )
              "
              placeholder="{{ NOT_AVAILABLE }}">
              @for (option of parameter.options; track option.value) {
                <app-dropdown-option [value]="option.value">{{
                  option.name
                }}</app-dropdown-option>
              }
            </app-dropdown>
          }

          @case ('multiselect') {
            <app-dropdown
              multiple
              [formControlName]="
                getParameterKey(
                  parameter.key,
                  linkedSource.tenant,
                  linkedSource.tap
                )
              ">
              @for (option of parameter.options; track option.value) {
                <app-dropdown-option [value]="option.value">{{
                  option.name
                }}</app-dropdown-option>
              }
            </app-dropdown>
          }

          @case ('number') {
            <div class="message-topic">
              <input
                appInput
                variant="outline"
                type="number"
                min="0"
                [formControlName]="
                  getParameterKey(
                    parameter.key,
                    linkedSource.tenant,
                    linkedSource.tap
                  )
                "
                placeholder="{{ NOT_AVAILABLE }}" />
            </div>
          }

          @case ('password') {
            <div class="message-topic">
              <input
                appInput
                variant="outline"
                [formControlName]="
                  getParameterKey(
                    parameter.key,
                    linkedSource.tenant,
                    linkedSource.tap
                  )
                "
                [type]="fieldsWithPassword.get(parameter.key)"
                placeholder="{{ NOT_AVAILABLE }}" />
              <mat-icon
                (click)="togglePasswordVisibility(parameter.key)"
                class="pass-placeholder"
                [svgIcon]="
                  fieldsWithPassword.get(parameter.key) === 'password'
                    ? 'eye-open'
                    : 'eye-closed'
                ">
              </mat-icon>
            </div>
          }

          @default {
            <div class="message-topic">
              <input
                appInput
                variant="outline"
                [formControlName]="
                  getParameterKey(
                    parameter.key,
                    linkedSource.tenant,
                    linkedSource.tap
                  )
                "
                placeholder="{{ NOT_AVAILABLE }}" />
            </div>
          }
        }
      </div>
    </div>
  }
</div>
