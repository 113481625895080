import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropdownOptionSearch',
})
export class DropdownOptionSearchPipe implements PipeTransform {
  transform(
    options: any[],
    searchParam: string,
    filterObjectProp?: string
  ): any {
    if (!options.length) return [];

    if (!searchParam) return options;

    return options.filter(option => {
      if (typeof option === 'object') {
        if (!filterObjectProp) {
          throw new Error(
            'No object property name was provided to the pipe therefore the filter cannot be applied'
          );
        }

        return option[filterObjectProp]
          .toLowerCase()
          .includes(searchParam.toLowerCase());
      }

      return option.toLowerCase().includes(searchParam.toLowerCase());
    });
  }
}
