<app-filter-container-template
  [headerTemplate]="headerTemplate()"
  [footerTemplate]="footerTemplate()"
  [filterName]="filterName()"
  [disableApply]="
    !filterStrategy.hasValue() ||
    filterStrategy.hasSameValue(filterValues()?.value) ||
    filterDisabled()
  "
  [showApplyAll]="showApplyAll()"
  (apply)="apply($event)"
  (clear)="clear()"
  (cancel)="cancel()">
  <div class="container">
    <div class="wrapper">
      <app-radio-button-group-v2 [(ngModel)]="filterStrategy.form.to">
        @if (!!filter()?.options?.length) {
          @for (option of filter().options; track option.value) {
            <app-radio-button-v2
              class="filter-radio-button"
              [value]="option.value"
              >{{ option.key }}</app-radio-button-v2
            >
          }
        } @else {
          <app-radio-button-v2 class="filter-radio-button" [value]="true">{{
            COMMON_STRINGS.true
          }}</app-radio-button-v2>
          <app-radio-button-v2 class="filter-radio-button" [value]="false">{{
            COMMON_STRINGS.false
          }}</app-radio-button-v2>
        }
      </app-radio-button-group-v2>
    </div>
  </div>
</app-filter-container-template>
