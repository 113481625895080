import { Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LinkedSource } from 'src/app/core/api/integrations/model/integrations.model';
import { SimpleConfirmationDialogComponent } from '../simple-confirmation-dialog/simple-confirmation-dialog.component';
import { BaseConfirmationDialogSize } from '../base-confirmation-dialog/model/base-confirmation-data.model';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { CloseConfirmationDialog } from 'src/app/features/settings/actions/integrations.action';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-remove-source',
  templateUrl: './remove-source.component.html',
  styleUrl: './remove-source.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveSourceComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() linkedSource: LinkedSource;

  dialogRef: DialogRef<any>;

  constructor(
    private dialog: Dialog,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(
        ofActionSuccessful(CloseConfirmationDialog),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  /**
   * unsubscribes from all subscriptions
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * deletes a linked source
   * @param tapName the tap name of the linked source to delete
   */
  deleteLinkedSource(tapName: string): void {
    this.dialogRef = this.dialog.open(SimpleConfirmationDialogComponent, {
      disableClose: true,
      width: BaseConfirmationDialogSize,
      data: {
        title: $localize`Disconnect source?`,
        body: $localize`Are you sure you want to <strong>disconnect this source</strong>?<br>`,
        actionName: $localize`Disconnect`,
        actionType: 'danger',
        actionData: tapName,
      },
    });
  }
}
