import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  ViewEncapsulation,
  computed,
  forwardRef,
  input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { FilterAbstractControl } from '../../../core/filter-abstract-control';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FILTER_FORM } from '../../../../model/filter-v2.model';

@Component({
  selector: 'app-filter-text-equals-v2',
  templateUrl: './filter-text-equals-v2.component.html',
  styleUrls: ['./filter-text-equals-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterTextEqualsV2Component),
      multi: true,
    },
    {
      provide: FILTER_FORM,
      useExisting: FilterTextEqualsV2Component,
    },
  ],
})
export class FilterTextEqualsV2Component extends FilterAbstractControl {
  @ViewChild(NgForm)
  form: NgForm;

  isPartialMatch = input.required<boolean>();

  label = computed(() =>
    this.isPartialMatch()
      ? MESSAGES.common.filter.optionTypes.search
      : MESSAGES.common.filter.optionTypes.equals
  );

  readonly COMMON_STRINGS = MESSAGES.common;
}
