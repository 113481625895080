import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  contentChildren,
  Input,
} from '@angular/core';
import { TabBase } from '../../core/tab-base';
import { TAB_GROUP_TEMPLATE, TAB_TEMPLATE } from '../../model/tab.model';

@Component({
  selector: 'app-tab-dropdown-template',
  templateUrl: './tab-dropdown-template.component.html',
  styleUrl: './tab-dropdown-template.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: TAB_TEMPLATE, useExisting: TabDropdownTemplateComponent },
    { provide: TAB_GROUP_TEMPLATE, useExisting: TabDropdownTemplateComponent },
  ],
})
export class TabDropdownTemplateComponent extends TabBase {
  tabs = contentChildren(TAB_TEMPLATE);

  isGroup = true;

  @Input({ required: true })
  override label: string;

  @Input({ transform: booleanAttribute })
  override disabled: boolean;
}
