import { DataAdapter } from 'src/app/shared/adapter/adapter';

import { STRINGS } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.strings';
import {
  ISSUE_STATUS,
  capIssuesValues,
} from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.model';
import {
  DeliveriesOverviewSummaries as DeliveriesOverviewSummariesV1,
  DeliveriesOverviewSummary as DeliveriesOverviewSummaryV1,
} from '../model/deliveries-v2.model';
import {
  DeliveriesOverviewSummaries,
  DeliveriesOverviewSummary,
} from 'src/app/shared/models/delivery/v2/deliveries-v2.model';

export class DeliveriesOverviewSummaryAdapter
  implements
    DataAdapter<DeliveriesOverviewSummariesV1, DeliveriesOverviewSummaries>
{
  transform(data: DeliveriesOverviewSummariesV1): DeliveriesOverviewSummaries {
    const summary: DeliveriesOverviewSummary[] =
      data.deliveriesOverviewSummaries.map(
        (orderOverview: DeliveriesOverviewSummaryV1) => {
          return {
            status: orderOverview.status,
            label:
              STRINGS.metadata.todoList.issues.labels[orderOverview.status],
            subject: `${capIssuesValues(orderOverview.numberOfOrders)} ${
              orderOverview.numberOfOrders === 1
                ? STRINGS.metadata.todoList.issues.descriptions.subject[
                    orderOverview.status + '_SINGLE'
                  ]
                : STRINGS.metadata.todoList.issues.descriptions.subject[
                    orderOverview.status
                  ]
            }`,
            description:
              STRINGS.metadata.todoList.issues.descriptions.description[
                orderOverview.status
              ],
            color: ISSUE_STATUS[orderOverview.status],
            numberOfOrders: capIssuesValues(orderOverview.numberOfOrders),
            totalOrdered: orderOverview.totalOrdered,
            totalDelivered: orderOverview.totalDelivered,
            totalValue: orderOverview.totalValue,
          };
        }
      );

    return {
      deliveriesOverviewSummaries: summary,
    };
  }
}
