import {
  EmailTemplateLanguage,
  EmailTemplatesContext,
  EmailTemplateType,
} from 'src/app/shared/models/emails/v1/emails.model';

// Deprecated
export class LoadEmailTemplate {
  static readonly type = '[Email Templates] Load';

  constructor(
    public language: any,
    public defaultTemplate: boolean
  ) {}
}

export class LoadDefaultEmailTemplate {
  static readonly type = '[Email Templates] Load Default Template';

  constructor(public preferredLanguage?: string) {}
}

export class SaveEmailTemplate {
  static readonly type = '[Email Templates] Save';
}

// refactor from here

export class LoadWebshopEmailTemplates {
  static readonly type = '[Email Templates] Load Webshop Email Templates';

  constructor(
    public type: EmailTemplateType,
    public preferredLanguage: EmailTemplateLanguage
  ) {}
}

export class LoadAccountEmailTemplates {
  static readonly type = '[Email Templates] Load Account Email Templates';

  constructor(
    public type: EmailTemplateType,
    public preferredLanguage: EmailTemplateLanguage
  ) {}
}

export class LoadSupplierEmailTemplates {
  static readonly type = '[Email Templates] Load Supplier Email Templates';

  constructor(
    public supplierUuid: string,
    public preferredLanguage: EmailTemplateLanguage
  ) {}
}

export class SetTemplatesContext {
  static readonly type = '[Email Templates] Set Templates Context';

  constructor(public context: EmailTemplatesContext) {}
}

export class GetTemplatesByContext {
  static readonly type = '[Email Templates] Get Templates By Context';
}

export class SetAndGetTemplatesByContext {
  static readonly type = '[Email Templates] Set And Get Templates By Context';

  constructor(public context: EmailTemplatesContext) {}
}

export class SaveWebshopTemplate {
  static readonly type = '[Email Templates] Save Webshop Template';
}

export class SaveAccountTemplate {
  static readonly type = '[Email Templates] Save Account Template';
}

export class SaveSupplierTemplate {
  static readonly type = '[Email Templates] Save Supplier Template';

  constructor(public supplierUuid: string) {}
}
