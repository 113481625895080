import { InjectionToken } from '@angular/core';
import { ExpansionPanelJobsToggleDirective } from '../directives/expansion-panel-jobs-toggle.directive';
import { ExpansionPanelJobsTitleDirective } from '../directives/expansion-panel-jobs-title.directive';
import { ExpansionPanelJobsComponent } from '../expansion-panel-jobs.component';

export const EXPANSION_PANEL = new InjectionToken<ExpansionPanelJobsComponent>(
  'ExpansionJobsPanel'
);

export const EXPANSION_PANEL_TITLE =
  new InjectionToken<ExpansionPanelJobsTitleDirective>(
    'ExpansionPanelJobsTitle'
  );

export const EXPANSION_PANEL_TOGGLE =
  new InjectionToken<ExpansionPanelJobsToggleDirective>(
    'ExpansionPanelJobsToggle'
  );

export const EXPANSION_PANEL_DEFAULT_OPTIONS =
  new InjectionToken<ExpansionPanelJobsOptions>('ExpansionPanelJobsOptions');

export interface ExpansionPanelJobsOptions {
  togglePosition: TogglePosition;
}

export type TogglePosition = 'start' | 'left' | 'end' | 'right';
