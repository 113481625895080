<div class="replan-container">
  @if (loading()) {
    <p class="replan-container-text replan-container-loading">
      {{ STRINGS.agendaReplan.loading }}
    </p>
  } @else {
    <h3 class="bracket-title">{{ STRINGS.agendaReplan.title }}</h3>

    <p class="replan-container-text">
      {{ STRINGS.agendaReplan.subtitle }}
    </p>

    <div class="replan-container-options" appRadioButtonGroup>
      <div class="replan-container-option">
        <div class="replan-container-option-container">
          <app-radio-button
            name="inputData"
            value="true"
            [checked]="replanEnabled()"
            (radioChange)="enableReplan()"
            [label]="STRINGS.agendaReplan.replan"
            [disabled]="disabled()">
          </app-radio-button>

          <div class="planning-date-container">
            <div class="optiply-outline-datepicker">
              <input
                [matDatepicker]="picker1"
                [min]="planningMinDate()"
                [value]="planningDate()"
                (dateChange)="setPlanningDate($event)"
                (click)="picker1.open()"
                placeholder="-"
                [disabled]="replanEnabled() === false || disabled()"
                readonly />

              <mat-datepicker-toggle matSuffix [for]="picker1">
                <mat-icon
                  matDatepickerToggleIcon
                  svgIcon="calendar"
                  class="datepicker-icon"></mat-icon
              ></mat-datepicker-toggle>
              <mat-datepicker
                #picker1
                [disabled]="
                  replanEnabled() === false || disabled()
                "></mat-datepicker>
            </div>
          </div>
        </div>

        <p class="replan-container-option-description">
          @if (daysToNextOrderMoment(); as daysToNextOrderMoment) {
            @if (daysToNextOrderMoment > 1) {
              <span i18n="purchase confirmation advised days"
                >Advised. Next order moment in
                {{ daysToNextOrderMoment }} days.</span
              >
            } @else if (daysToNextOrderMoment === 1) {
              <span>{{ STRINGS.agendaReplan.advisedTomorrow }}</span>
            } @else {
              <span>{{ STRINGS.agendaReplan.advisedToday }}</span>
            }
          }
        </p>
      </div>

      <div class="replan-container-option">
        <app-radio-button
          name="inputData"
          value="false"
          [checked]="replanEnabled() === false"
          (radioChange)="disableReplan()"
          [label]="STRINGS.agendaReplan.noReplan"
          [disabled]="disabled()">
        </app-radio-button>

        <p
          class="replan-container-option-description"
          i18n="purchase confirmation not advised date">
          Not advised. Next order moment on
          {{ nextOrderMomentDate() | date: 'mediumDate' }}.
        </p>
      </div>
    </div>
  }
</div>
