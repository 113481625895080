import * as i0 from '@angular/core';
import { Injectable, Component, Input, NgModule } from '@angular/core';
import useCsvPlugin from '@usecsv/js';
const _c0 = [[["", "customUsecsvButton", ""]], "*"];
const _c1 = ["[customUsecsvButton]", "*"];
class UsecsvAngularPluginService {
  constructor() {}
}
UsecsvAngularPluginService.ɵfac = function UsecsvAngularPluginService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || UsecsvAngularPluginService)();
};
UsecsvAngularPluginService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UsecsvAngularPluginService,
  factory: UsecsvAngularPluginService.ɵfac,
  providedIn: "root"
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UsecsvAngularPluginService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], function () {
    return [];
  }, null);
})();
class UsecsvAngularPluginComponent {
  openModal() {
    useCsvPlugin({
      importerKey: this.importerKey,
      user: this.user,
      importerDisplay: this.importerDisplay,
      metadata: this.metadata,
      onData: this.onData,
      onRecordsInitial: this.onRecordsInitial,
      onRecordEdit: this.onRecordEdit,
      onClose: this.onClose,
      theme: this.theme,
      batchSize: this.batchSize,
      sampleFileURL: this.sampleFileURL,
      downloadExampleButton: this.downloadExampleButton,
      dynamicColumns: this.dynamicColumns,
      defaultLanguage: this.defaultLanguage,
      onError: this.onError,
      customText: this.customText,
      acceptedFileFormats: this.acceptedFileFormats,
      onFileUpload: this.onFileUpload,
      columnWidth: this.columnWidth,
      onMatchColumns: this.onMatchColumns
    });
  }
  constructor() {
    this.importerKey = "";
  }
  ngOnInit() {}
}
UsecsvAngularPluginComponent.ɵfac = function UsecsvAngularPluginComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || UsecsvAngularPluginComponent)();
};
UsecsvAngularPluginComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UsecsvAngularPluginComponent,
  selectors: [["usecsv-button"]],
  inputs: {
    importerKey: "importerKey",
    user: "user",
    metadata: "metadata",
    onData: "onData",
    onRecordsInitial: "onRecordsInitial",
    onRecordEdit: "onRecordEdit",
    importerDisplay: "importerDisplay",
    onClose: "onClose",
    theme: "theme",
    batchSize: "batchSize",
    sampleFileURL: "sampleFileURL",
    downloadExampleButton: "downloadExampleButton",
    dynamicColumns: "dynamicColumns",
    defaultLanguage: "defaultLanguage",
    onError: "onError",
    customText: "customText",
    acceptedFileFormats: "acceptedFileFormats",
    onFileUpload: "onFileUpload",
    columnWidth: "columnWidth",
    onMatchColumns: "onMatchColumns"
  },
  ngContentSelectors: _c1,
  decls: 4,
  vars: 0,
  consts: [["id", "usecsv-custom-button", 3, "click"], ["id", "usecsv-default-button", 3, "click"]],
  template: function UsecsvAngularPluginComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("click", function UsecsvAngularPluginComponent_Template_div_click_0_listener() {
        return ctx.openModal();
      });
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(2, "button", 1);
      i0.ɵɵlistener("click", function UsecsvAngularPluginComponent_Template_button_click_2_listener() {
        return ctx.openModal();
      });
      i0.ɵɵprojection(3, 1);
      i0.ɵɵelementEnd();
    }
  },
  styles: ["#usecsv-custom-button[_ngcontent-%COMP%]:not(:empty) + #usecsv-default-button[_ngcontent-%COMP%]{display:none}#usecsv-default-button[_ngcontent-%COMP%]{background-color:#fff;color:#000;border:2px solid #000;border-radius:6px;padding:10px 15px;text-align:center;font-size:16px;cursor:pointer}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UsecsvAngularPluginComponent, [{
    type: Component,
    args: [{
      selector: "usecsv-button",
      template: `<div id="usecsv-custom-button" (click)="openModal()">
      <ng-content select="[customUsecsvButton]"></ng-content>
    </div>
    <button id="usecsv-default-button" (click)="openModal()">
      <ng-content></ng-content>
    </button>`,
      styles: ["#usecsv-custom-button:not(:empty)+#usecsv-default-button{display:none}#usecsv-default-button{background-color:#fff;color:#000;border:2px solid #000;border-radius:6px;padding:10px 15px;text-align:center;font-size:16px;cursor:pointer}\n"]
    }]
  }], function () {
    return [];
  }, {
    importerKey: [{
      type: Input
    }],
    user: [{
      type: Input
    }],
    metadata: [{
      type: Input
    }],
    onData: [{
      type: Input
    }],
    onRecordsInitial: [{
      type: Input
    }],
    onRecordEdit: [{
      type: Input
    }],
    importerDisplay: [{
      type: Input
    }],
    onClose: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    batchSize: [{
      type: Input
    }],
    sampleFileURL: [{
      type: Input
    }],
    downloadExampleButton: [{
      type: Input
    }],
    dynamicColumns: [{
      type: Input
    }],
    defaultLanguage: [{
      type: Input
    }],
    onError: [{
      type: Input
    }],
    customText: [{
      type: Input
    }],
    acceptedFileFormats: [{
      type: Input
    }],
    onFileUpload: [{
      type: Input
    }],
    columnWidth: [{
      type: Input
    }],
    onMatchColumns: [{
      type: Input
    }]
  });
})();
class UsecsvAngularPluginModule {}
UsecsvAngularPluginModule.ɵfac = function UsecsvAngularPluginModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || UsecsvAngularPluginModule)();
};
UsecsvAngularPluginModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UsecsvAngularPluginModule
});
UsecsvAngularPluginModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UsecsvAngularPluginModule, [{
    type: NgModule,
    args: [{
      declarations: [UsecsvAngularPluginComponent],
      imports: [],
      exports: [UsecsvAngularPluginComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of usecsv-angular-plugin
 */

/**
 * Generated bundle index. Do not edit.
 */

export { UsecsvAngularPluginComponent, UsecsvAngularPluginModule, UsecsvAngularPluginService };
