import { getLocaleCurrencyCode } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import { WebshopState } from '../states/webshop.state';
import { LocaleService } from './locale.service';
import { CURRENCIES } from 'src/app/core/constants/global.constants';

/**
 * Service responsible for managing the application currency used by each
 * webshop. If the webshop is not present, it will return the default currency
 * code for the current locale id.
 */
@Injectable({ providedIn: 'root' })
export class CurrencyService implements OnDestroy {
  private webshop: Webshop;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private localeService: LocaleService
  ) {
    this.store
      .select(WebshopState.selected)
      .pipe(takeUntil(this.destroy$))
      .subscribe((webshop: Webshop) => {
        this.webshop = webshop;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  currencyCode() {
    if (this.webshop == null) {
      return getLocaleCurrencyCode(this.localeService.locale);
    }

    return this.webshop.settings.currency;
  }

  currencySymbol() {
    if (this.webshop == null) {
      return getLocaleCurrencyCode(this.localeService.locale);
    }

    let currency = CURRENCIES.find(
      c => c.value === this.webshop.settings.currency
    );

    return currency.symbol;
  }

  manualCurrencySymbol(currencyCode: string) {
    let currency = CURRENCIES.find(c => c.value === currencyCode);

    return currency.symbol;
  }
}
