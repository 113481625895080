import { SelectionModel } from '@angular/cdk/collections';
import {
  FilterListGroupV2,
  FilterOptionV2,
  FilterStrategy,
} from '../../../model/filter-v2.model';

const compareWith = (a: FilterOptionV2, b: FilterOptionV2): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export class FilterListStrategy implements FilterStrategy<FilterListGroupV2> {
  form: FilterListGroupV2 = {
    selection: new SelectionModel<FilterOptionV2>(true, [], true, compareWith),
  };

  populate(values: FilterOptionV2[]): void {
    this.form.selection.setSelection(...values);
  }

  hasValue(): boolean {
    return this.isSomeSelected();
  }

  hasSameValue(): boolean {
    return false;
  }

  selected(): FilterOptionV2[] {
    return this.form.selection.selected;
  }

  isAllSelected(options: FilterOptionV2[]): boolean {
    return this.selected().length === options.length;
  }

  isSomeSelected(): boolean {
    return !this.form.selection.isEmpty();
  }

  clear(): void {
    this.form.selection.clear();
  }
}
