import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { catchError, concatMap, mergeMap, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  Loading,
  LoadSucceed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { PromotionsService } from 'src/app/core/api/promotions/v1/promotions.service';
import { PromotionsV2Service } from 'src/app/core/api/promotions/v2/promotions-v2.service';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AccountState } from 'src/app/core/states/account.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import {
  AddOrUpdateProperties,
  CreatePromotionProperties,
  DeletePromotionsProperties,
  UpdatePromotionProperties,
  UpdatePromotionsProperties,
} from 'src/app/shared/models/promotion/promotion.model';
import { Promotions } from 'src/app/shared/models/promotion/promotions.model';
import {
  Promotion,
  PromotionStatus,
  PromotionUpliftTypes,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import {
  AddFilterParam,
  AddMultipleProductsToPromotion,
  AddSearchParam,
  ClearSelection,
  ColumnsSelected,
  CreatePromotion,
  DeletePromotion,
  DeletePromotionFromTable,
  DeletePromotionsFromTable,
  DisablePromotion,
  DisablePromotionFromTable,
  DisablePromotionsFromTable,
  EditPromotion,
  EditedPromotionFromTable,
  EnablePromotion,
  EnablePromotionFromTable,
  EnablePromotionsFromTable,
  ExportPromotionsV2,
  HideColumn,
  InitializePromotionsState,
  LoadPromotion,
  LoadPromotions,
  LoadPromotionsFiltered,
  MasterToggleSelection,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetCurrentPromotion,
  ResetPagination,
  ResetPaginationAndLoadData,
  Sort,
  ToggleFilter,
  ToggleRowSelection,
  UpdateEnabled,
  UpdateEndDate,
  UpdateFormChanges,
  UpdateFormValidation,
  UpdateIncrease,
  UpdatePromotionName,
  UpdateStartDate,
  UpdateUpliftType,
} from '../actions/promotions-v2.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/promotions-v2-data-table.model';
import {
  CreatePromotionFormProperties,
  EnableDisablePromotionProperties,
  SelectedPromotion,
  UpdatePromotionFormProperties,
} from '../model/promotions-v2.model';
import {
  ExportDataV2,
  ExportRequestDataV2,
} from 'src/app/shared/models/exports/exports-v2.model';
import { STRINGS } from '../model/promotions-v2.strings';
import { PromotionStatuses } from 'src/app/core/api/promotions/v2/model/promotions-v2.model';
import { DateTime } from 'luxon';
import { NavigateTo } from 'src/app/core/actions/navigation.action';
import { LoadPromotionProducts } from '../components/promotions-v2-editor/components/promotions-v2-editor-data-table/actions/promotions-v2-editor-data-table.actions';
import { SelectedWebshopProduct } from '../../products-v2/model/products-v2.model';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  defaultDatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { CreateUpdatePromotionForm } from 'src/app/shared/components/create-update-promotion-dialog/model/dialog-properties.model';
import {
  SetInitialData,
  SetPendingChanges,
} from 'src/app/core/actions/pending-changes.action';
import { PendingChangesKeys } from 'src/app/shared/models/navigation/pending-changes.model';
import { promotionEditUpliftMock } from 'src/app/core/mocks/promotions-v2.mock';

export interface PromotionsV2StateModel extends DatatableStateModel {
  selection: TableSelection<SelectedPromotion>;
  promotions: Promotion[];
  promotionsUnpaged: Promotion[];
  currentPromotion: Promotion;
  currentPromotionEdited: CreateUpdatePromotionForm;
  validChanges: boolean;
  page: Pageable;
}

@State<PromotionsV2StateModel>({
  name: 'promotionsV2State',
  defaults: {
    ...defaultDatatableStateModel,
    selection: {},
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    sortBy: defaultSort,
    search: [],
    promotions: [],
    promotionsUnpaged: [],
    currentPromotion: null,
    currentPromotionEdited: null,
    validChanges: true,
    page: { totalElements: 0 },
    filterOpened: false,
  },
})
@Injectable()
export class PromotionsV2State extends DatatableState {
  readonly notExportableColumns: string[] = [
    STRINGS.columns.promotions.SELECTION.key,
    STRINGS.columns.promotions.ACTIONS.key,
  ];

  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [PromotionsV2State],
      (state: PromotionsV2StateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static selection(
    state: PromotionsV2StateModel
  ): TableSelection<SelectedPromotion> {
    return state.selection;
  }

  @Selector()
  static promotions(state: PromotionsV2StateModel): Promotion[] {
    return state.promotions;
  }

  constructor(
    private store: Store,
    private promotionsService: PromotionsService,
    private promotionsV2Service: PromotionsV2Service,
    private accountSettingsService: AccountSettingsService,
    private notificationService: NotificationCenterService
  ) {
    super();
  }

  @Action(InitializePromotionsState, { cancelUncompleted: true })
  initialize(ctx: StateContext<PromotionsV2StateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.promotionsEditorTableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<PromotionsV2StateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch(new LoadPromotions());
  }

  @Action(LoadPromotions, { cancelUncompleted: true })
  loadPromotions(ctx: StateContext<PromotionsV2StateModel>) {
    return ctx.dispatch(new Loading(false)).pipe(
      mergeMap(() => this._findAllPromotions(ctx)),
      tap((promotions: Promotions) => {
        ctx.patchState({
          promotions: promotions.data,
          page: { totalElements: promotions.metadata.page.totalElements },
        });
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(LoadPromotionsFiltered, { cancelUncompleted: true })
  loadPromotionsFiltered(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: LoadPromotionsFiltered
  ) {
    return ctx.dispatch(new Loading(false)).pipe(
      mergeMap(() => this._findAllPromotionsFiltered(ctx, payload.search)),
      tap((promotions: Promotions) => {
        ctx.patchState({
          promotionsUnpaged: promotions.data,
        });
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<PromotionsV2StateModel>) {
    return ctx.dispatch(new LoadPromotions());
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<PromotionsV2StateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(LoadPromotion, { cancelUncompleted: true })
  loadPromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: LoadPromotion
  ) {
    return this.promotionsService.findOnePromotion(payload.promotionUUID).pipe(
      tap((promotion: Promotion) => {
        ctx.patchState({
          currentPromotion: {
            uuid: promotion.uuid,
            webshopUUID: promotion.webshopUUID,
            name: promotion.name,
            interval: {
              start: promotion.interval.start,
              end: promotion.interval.end,
            },
            uplift: {
              increase: promotion.uplift.increase,
              type: promotion.uplift.type,
            },
            numberOfProducts: promotion.numberOfProducts,
            settings: {
              enabled: promotion.settings.enabled,
            },
            createdAt: promotion.createdAt,
            updatedAt: promotion.updatedAt,
            deletedAt: promotion.deletedAt,
            status: this._buildPromotionStatus(promotion),
            extras: promotion.extras,
            id: promotion.id,
            notes: promotion.notes,
          },
          currentPromotionEdited: {
            promotionName: promotion.name,
            startDate: new Date(promotion.interval.start),
            endDate: new Date(promotion.interval.end),
            upliftType: PromotionUpliftTypes[promotion.uplift.type],
            increase: promotion.uplift.increase,
            enabled: promotion.settings.enabled,
            entireWebshop: false,
          },
        });

        ctx.dispatch(
          new SetInitialData(PendingChangesKeys.PROMOTION, {
            promotionName: promotion.name,
            startDate: new Date(promotion.interval.start),
            endDate: new Date(promotion.interval.end),
            upliftType: PromotionUpliftTypes[promotion.uplift.type],
            increase: promotion.uplift.increase,
            enabled: promotion.settings.enabled,
            entireWebshop: false,
          })
        );
      })
    );
  }

  @Action(ResetCurrentPromotion, { cancelUncompleted: true })
  resetCurrentPromotion(ctx: StateContext<PromotionsV2StateModel>) {
    const state = ctx.getState();

    let promotionData = {
      promotionName: state.currentPromotion.name,
      startDate: new Date(state.currentPromotion.interval.start),
      endDate: new Date(state.currentPromotion.interval.end),
      upliftType: PromotionUpliftTypes[state.currentPromotion.uplift.type],
      increase: state.currentPromotion.uplift.increase,
      enabled: state.currentPromotion.settings.enabled,
      entireWebshop: false,
    };

    ctx.patchState({
      currentPromotionEdited: promotionData,
    });

    return ctx.dispatch(new UpdateFormValidation());
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<PromotionsV2StateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadPromotions()))
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadPromotions());
  }

  @Action(AddFilterParam, { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(AddSearchParam, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({ search: append<string>([payload.param]) })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(RemoveSearchParam, { cancelUncompleted: true })
  removeSearchParam(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<PromotionsV2StateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<PromotionsV2StateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(RemoveAllFilters, { cancelUncompleted: true })
  removeAllFilters(ctx: StateContext<PromotionsV2StateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<PromotionsV2StateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action(ToggleFilter, { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<PromotionsV2StateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(ToggleRowSelection, { cancelUncompleted: true })
  toggleRowSelection(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: ToggleRowSelection
  ) {
    const selectedCopy = { ...ctx.getState().selection };

    const selectionKey = payload.promotion.uuid;

    if (!payload.isSelected) {
      delete selectedCopy[selectionKey];

      ctx.patchState({
        selection: selectedCopy,
      });

      return;
    }

    ctx.patchState({
      selection: {
        ...ctx.getState().selection,
        [selectionKey]: {
          uuid: selectionKey,
          enabled: payload.promotion.settings.enabled,
        },
      },
    });
  }

  @Action(MasterToggleSelection, { cancelUncompleted: true })
  masterToggleSelection(ctx: StateContext<PromotionsV2StateModel>) {
    const lines = ctx.getState().promotions;

    const existingSelection = ctx.getState().selection;

    const linesUuid = lines.map(line => line.uuid);

    const selectedUids = new Set(Object.keys(existingSelection));

    const allSelected = linesUuid.every(line => selectedUids.has(line));

    const selectionCopy = new Map(Object.entries(existingSelection));

    if (allSelected) {
      lines.forEach(line => {
        selectionCopy.delete(line.uuid);
      });
    } else {
      lines.forEach(line => {
        selectionCopy.set(line.uuid, {
          uuid: line.uuid,
          enabled: line.settings.enabled,
        });
      });
    }

    ctx.patchState({
      selection: {
        ...Object.fromEntries(selectionCopy.entries()),
      },
    });
  }

  @Action(ClearSelection, { cancelUncompleted: true })
  clearSelection(ctx: StateContext<PromotionsV2StateModel>) {
    ctx.patchState({
      selection: {},
    });
  }

  @Action(DeletePromotionFromTable)
  deletePromotionFromTable(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: DeletePromotionFromTable
  ) {
    window.rudderanalytics.track('Promotions Deleted', {
      promotion_uuid: payload.promotionUuid,
    });

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._deletePromotion(ctx, payload.promotionUuid)),
      tap(() => this._removeFromSelection(ctx, payload.promotionUuid)),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      concatMap(() => ctx.dispatch(new LoadPromotions()))
    );
  }

  @Action(DeletePromotionsFromTable)
  deletePromotionsFromTable(ctx: StateContext<PromotionsV2StateModel>) {
    const requestInfo: DeletePromotionsProperties = {
      uuids: Object.keys(ctx.getState().selection),
    };

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() => ctx.dispatch(new ClearSelection())),
      concatMap(() => this._deletePromotions(ctx, requestInfo)),
      tap(() => this._removeMultipleFromSelection(ctx, requestInfo.uuids)),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      mergeMap(() => ctx.dispatch(new LoadPromotions()))
    );
  }

  @Action(DeletePromotion)
  deletePromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: DeletePromotion
  ) {
    window.rudderanalytics.track('Promotions Deleted', {
      promotion_uuid: ctx.getState().currentPromotion.uuid,
    });

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this._deletePromotion(ctx, ctx.getState().currentPromotion.uuid)
      ),
      tap(() => {
        payload.dialogRef?.close();
      }),
      concatMap(() =>
        ctx.dispatch([new SaveSucceed(), new NavigateTo(['promotions'])])
      )
    );
  }

  @Action(EnablePromotionFromTable)
  enablePromotionFromTable(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: EnablePromotionFromTable
  ) {
    const requestInfo: UpdatePromotionProperties = {
      webshopUUID: this.store.selectSnapshot(WebshopState.selected).uuid,
      name: payload.promotionName,
      enabled: true,
    };

    window.rudderanalytics.track('Promotions Updated', {
      promotion_uuid: payload.promotionUuid,
      attributes: requestInfo,
    });

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this._updatePromotion(ctx, payload.promotionUuid, requestInfo)
      ),
      tap(() => this._removeFromSelection(ctx, payload.promotionUuid)),
      tap(() => {
        ctx.patchState({
          currentPromotion: {
            ...ctx.getState().currentPromotion,
            settings: { enabled: true },
          },
        });
      }),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      mergeMap(() => ctx.dispatch(new LoadPromotions()))
    );
  }

  @Action(EnablePromotionsFromTable)
  enablePromotionsFromTable(ctx: StateContext<PromotionsV2StateModel>) {
    const requestInfo: UpdatePromotionsProperties = {
      webshopUUID: this.store.selectSnapshot(WebshopState.selected).uuid,
      promotions: this._buildEnablePromotionsRequestInfo(ctx),
    };

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._updatePromotions(ctx, requestInfo)),
      tap(() =>
        this._removeMultipleFromSelection(
          ctx,
          requestInfo.promotions.map(promotion => promotion.uuid)
        )
      ),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      mergeMap(() => ctx.dispatch(new LoadPromotions()))
    );
  }

  @Action(EnablePromotion)
  enablePromotion(ctx: StateContext<PromotionsV2StateModel>) {
    const requestInfo: UpdatePromotionProperties = {
      webshopUUID: this.store.selectSnapshot(WebshopState.selected).uuid,
      name: ctx.getState().currentPromotion.name,
      enabled: true,
    };

    window.rudderanalytics.track('Promotions Updated', {
      promotion_uuid: ctx.getState().currentPromotion.uuid,
      attributes: requestInfo,
    });

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() =>
        this._updatePromotion(
          ctx,
          ctx.getState().currentPromotion.uuid,
          requestInfo
        )
      ),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadPromotion(ctx.getState().currentPromotion.uuid),
        ])
      )
    );
  }

  @Action(DisablePromotionFromTable)
  disablePromotionFromTable(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: DisablePromotionFromTable
  ) {
    const requestInfo: UpdatePromotionProperties = {
      webshopUUID: this.store.selectSnapshot(WebshopState.selected).uuid,
      name: payload.promotionName,
      enabled: false,
    };

    window.rudderanalytics.track('Promotions Updated', {
      promotion_uuid: payload.promotionUuid,
      attributes: requestInfo,
    });

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this._updatePromotion(ctx, payload.promotionUuid, requestInfo)
      ),
      tap(() => this._removeFromSelection(ctx, payload.promotionUuid)),
      tap(() => {
        ctx.patchState({
          currentPromotion: {
            ...ctx.getState().currentPromotion,
            settings: { enabled: true },
          },
        });
      }),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      concatMap(() => ctx.dispatch(new LoadPromotions()))
    );
  }

  @Action(DisablePromotionsFromTable)
  disablePromotionsFromTable(ctx: StateContext<PromotionsV2StateModel>) {
    const requestInfo: UpdatePromotionsProperties = {
      webshopUUID: this.store.selectSnapshot(WebshopState.selected).uuid,
      promotions: this._buildDisablePromotionsRequestInfo(ctx),
    };

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._updatePromotions(ctx, requestInfo)),
      tap(() =>
        this._removeMultipleFromSelection(
          ctx,
          requestInfo.promotions.map(promotion => promotion.uuid)
        )
      ),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      mergeMap(() => ctx.dispatch(new LoadPromotions()))
    );
  }

  @Action(DisablePromotion)
  disablePromotion(ctx: StateContext<PromotionsV2StateModel>) {
    const requestInfo: UpdatePromotionProperties = {
      webshopUUID: this.store.selectSnapshot(WebshopState.selected).uuid,
      name: ctx.getState().currentPromotion.name,
      enabled: false,
    };

    window.rudderanalytics.track('Promotions Updated', {
      promotion_uuid: ctx.getState().currentPromotion.uuid,
      attributes: requestInfo,
    });

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() =>
        this._updatePromotion(
          ctx,
          ctx.getState().currentPromotion.uuid,
          requestInfo
        )
      ),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadPromotion(ctx.getState().currentPromotion.uuid),
        ])
      )
    );
  }

  @Action(CreatePromotion, { cancelUncompleted: true })
  createPromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: CreatePromotion
  ) {
    window.rudderanalytics.track('Promotions Created', {
      attributes: payload.settings,
    });

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._createPromotion(ctx, payload.settings)),
      tap(() => {
        payload.dialogRef?.close();
      }),
      concatMap(() => ctx.dispatch([new SaveSucceed(), new LoadPromotions()]))
    );
  }

  @Action(EditedPromotionFromTable, { cancelUncompleted: true })
  editedPromotionFromTable(ctx: StateContext<PromotionsV2StateModel>) {
    return ctx.dispatch(new LoadPromotions());
  }

  @Action(EditPromotion, { cancelUncompleted: true })
  editPromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: EditPromotion
  ) {
    window.rudderanalytics.track('Promotions Updated', {
      promotion_uuid: payload.settings.promotionUuid,
      attributes: payload.settings,
    });

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() =>
        this._updatePromotion(
          ctx,
          payload.settings.promotionUuid,
          payload.settings
        )
      ),
      tap(() => {
        payload.dialogRef?.close();
      }),
      concatMap(() =>
        ctx.dispatch([
          new SaveSucceed(),
          new LoadPromotion(ctx.getState().currentPromotion.uuid),
        ])
      ),
      concatMap(() => ctx.dispatch([new ResetCurrentPromotion()]))
    );
  }

  @Action(ExportPromotionsV2, { cancelUncompleted: true })
  exportPromotions(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: ExportPromotionsV2
  ) {
    this.notificationService.showToast(
      MESSAGES.notifications.exports.exportPromotionsDispatched
    );

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const exportRequestInfo: ExportRequestDataV2 = this._buildExportData(
      ctx,
      payload.exportData
    );

    return ctx
      .dispatch(
        new Saving(
          true,
          MESSAGES.notifications.exports.exportPromotionsDispatched
        )
      )
      .pipe(
        mergeMap(() =>
          this._exportPromotions(ctx, webshopUuid, exportRequestInfo)
        )
      )
      .subscribe(response => {
        ctx.dispatch(
          new SaveSucceed(
            true,
            MESSAGES.notifications.exports.exportPromotionsSuccessful
          )
        );

        const exportData: any = {
          data: response.body,
          fileName: response.headers.get('Content-Disposition'),
          fileType: response.headers.get('content-type'),
        };
        const a = document.createElement('a');
        const blob = new Blob([exportData.data], { type: exportData.fileType });
        a.href = window.URL.createObjectURL(blob);
        a.download = this._getFileName(exportData.fileName);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
      });
  }

  @Action(AddMultipleProductsToPromotion, { cancelUncompleted: true })
  addProductsToPromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: AddMultipleProductsToPromotion
  ) {
    const productsToBeAdded: AddOrUpdateProperties[] = payload.products.map(
      (product: SelectedWebshopProduct) => {
        return {
          uuid: product.uuid,
        };
      }
    );
    return this.promotionsService
      .updateWebshopProductInPromotions(
        { addOrUpdate: productsToBeAdded, remove: [] },
        payload.promotionUuid
      )
      .pipe(
        catchError(() => {
          return ctx.dispatch(new SaveFailed());
        })
      );
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<PromotionsV2StateModel>) {
    return ctx.dispatch([new ResetPagination(), new LoadPromotions()]);
  }

  @Action(UpdatePromotionName, { cancelUncompleted: true })
  updatePromotionName(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: UpdatePromotionName
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        currentPromotionEdited: patch<CreateUpdatePromotionForm>({
          promotionName: payload.change,
        }),
      })
    );

    return ctx.dispatch(new UpdateFormValidation());
  }
  @Action(UpdateStartDate, { cancelUncompleted: true })
  updateStartDate(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: UpdateStartDate
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        currentPromotionEdited: patch<CreateUpdatePromotionForm>({
          startDate: new Date(payload.change),
        }),
      })
    );

    return ctx.dispatch(new UpdateFormValidation());
  }

  @Action(UpdateEndDate, { cancelUncompleted: true })
  updateEndDate(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: UpdateEndDate
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        currentPromotionEdited: patch<CreateUpdatePromotionForm>({
          endDate: new Date(payload.change),
        }),
      })
    );

    return ctx.dispatch(new UpdateFormValidation());
  }

  @Action(UpdateUpliftType, { cancelUncompleted: true })
  updateUpliftType(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: UpdateUpliftType
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        currentPromotionEdited: patch<CreateUpdatePromotionForm>({
          upliftType: payload.change,
        }),
      })
    );

    return ctx.dispatch(new UpdateFormValidation());
  }

  @Action(UpdateIncrease, { cancelUncompleted: true })
  updateIncrease(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: UpdateIncrease
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        currentPromotionEdited: patch<CreateUpdatePromotionForm>({
          increase: payload.change,
        }),
      })
    );

    return ctx.dispatch(new UpdateFormValidation());
  }

  @Action(UpdateEnabled, { cancelUncompleted: true })
  updateEnabled(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: UpdateEnabled
  ) {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        currentPromotionEdited: patch<CreateUpdatePromotionForm>({
          enabled: payload.change,
        }),
      })
    );

    return ctx.dispatch(new UpdateFormValidation());
  }

  @Action(UpdateFormChanges, { cancelUncompleted: true })
  updateFormChanges(
    ctx: StateContext<PromotionsV2StateModel>,
    payload: UpdateFormChanges
  ) {
    let valid = payload.valid;

    ctx.setState(
      patch<PromotionsV2StateModel>({
        validChanges: valid,
      })
    );

    return this._setPendingChanges(ctx, valid);
  }

  override addGroupParam(
    ctx: StateContext<PromotionsV2StateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<PromotionsV2StateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<PromotionsV2StateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadPromotions());
  }

  private _removeAllFilters(ctx: StateContext<PromotionsV2StateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _findAllPromotions(
    ctx: StateContext<PromotionsV2StateModel>
  ): Observable<void | Promotions> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildLoadPromotionsRequestInfo(ctx.getState());

    return this.promotionsV2Service
      .findAllPromotions(webshopUuid, requestInfo)
      .pipe(
        catchError(() => {
          ctx.patchState({
            promotions: [],
            page: { totalElements: 0 },
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildLoadPromotionsRequestInfo(
    state: PromotionsV2StateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _findAllPromotionsFiltered(
    ctx: StateContext<PromotionsV2StateModel>,
    search: string
  ): Observable<void | Promotions> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildLoadPromotionsFilteredRequestInfo(search);

    return this.promotionsV2Service
      .findAllPromotions(webshopUuid, requestInfo)
      .pipe(
        catchError(() => {
          ctx.patchState({
            promotionsUnpaged: [],
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildLoadPromotionsFilteredRequestInfo(
    search: string
  ): ServiceRequestInfoV3 {
    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        query: this.buildQuery([search]),
        filters: {
          any_of: [],
          match: [],
          no_match: [],
        },
        sort_by: [],
        page: {
          from: 0,
          size: 100,
        },
      },
    };

    return requestInfo;
  }

  private _updatePromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    promotionUuid: string,
    properties: UpdatePromotionFormProperties
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo: UpdatePromotionProperties = {
      webshopUUID: webshopUuid,
      name: properties.name,
      startDate: properties.startDate,
      endDate: properties.endDate,
      enabled: properties.enabled,
      entireShop: properties.entireShop,
    };

    if (properties.uplift) {
      requestInfo.uplift = {
        increase: properties.uplift.increase,
        type: properties.uplift.type,
      };
    }

    return this.promotionsV2Service
      .updatePromotion(requestInfo, promotionUuid)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _updatePromotions(
    ctx: StateContext<PromotionsV2StateModel>,
    properties: UpdatePromotionsProperties
  ): Observable<any> {
    return this.promotionsV2Service
      .updatePromotions(properties, properties.webshopUUID)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _deletePromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    promotionUuid: string
  ): Observable<any> {
    return this.promotionsService.deletePromotion(promotionUuid).pipe(
      catchError(() => {
        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  private _deletePromotions(
    ctx: StateContext<PromotionsV2StateModel>,
    requestInfo: DeletePromotionsProperties
  ): Observable<any> {
    return this.promotionsService.deletePromotions(requestInfo).pipe(
      catchError(() => {
        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  private _buildEnablePromotionsRequestInfo(
    ctx: StateContext<PromotionsV2StateModel>
  ): EnableDisablePromotionProperties[] {
    return Object.values(ctx.getState().selection)
      .filter((promotion: SelectedPromotion) => !promotion.enabled)
      .map((promotion: SelectedPromotion) => {
        return {
          uuid: promotion.uuid,
          enabled: true,
        };
      });
  }

  private _buildDisablePromotionsRequestInfo(
    ctx: StateContext<PromotionsV2StateModel>
  ): EnableDisablePromotionProperties[] {
    return Object.values(ctx.getState().selection)
      .filter((promotion: SelectedPromotion) => promotion.enabled)
      .map((promotion: SelectedPromotion) => {
        return {
          uuid: promotion.uuid,
          enabled: false,
        };
      });
  }

  private _createPromotion(
    ctx: StateContext<PromotionsV2StateModel>,
    properties: CreatePromotionFormProperties
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo: CreatePromotionProperties = {
      webshopUUID: webshopUuid,
      name: properties.name,
      startDate: properties.startDate,
      endDate: properties.endDate,
      settings: {
        enabled: properties.settings.enabled,
        entireShop: properties.settings.entireShop,
      },
    };

    if (properties.uplift) {
      requestInfo.uplift = {
        increase: properties.uplift.increase,
        type: properties.uplift.type,
      };
    }

    return this.promotionsV2Service.createPromotion(requestInfo).pipe(
      catchError(e => {
        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _exportPromotions(
    ctx: StateContext<PromotionsV2StateModel>,
    webshopUuid: string,
    properties: any
  ): Observable<any> {
    return this.promotionsService
      .exportPromotions(properties, webshopUuid)
      .pipe(
        catchError(e => {
          ctx.dispatch(
            new SaveFailed(
              true,
              MESSAGES.notifications.exports.exportPromotionsFailed
            )
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _removeFromSelection(
    ctx: StateContext<PromotionsV2StateModel>,
    key: string
  ) {
    const selection = { ...ctx.getState().selection };

    delete selection[key];

    ctx.patchState({
      selection: { ...selection },
    });
  }

  private _removeMultipleFromSelection(
    ctx: StateContext<PromotionsV2StateModel>,
    keys: string[]
  ) {
    const selection = { ...ctx.getState().selection };

    keys.forEach(key => delete selection[key]);

    ctx.patchState({
      selection: { ...selection },
    });
  }

  /**
   * Trimes the file name that comes in the response headers
   * @param contentDisposition
   * @returns
   */
  private _getFileName(contentDisposition: string): string {
    return contentDisposition
      .split(';')[1]
      .trim()
      .split('=')[1]
      .replace(/"/g, '');
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<PromotionsV2StateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      promotionsEditorTableExcludedColumns: excludedColumns,
      overridePromotionsEditorTableExcludedColumns: true,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<PromotionsV2StateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      promotionsEditorTablePageSize: newPageSize,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<PromotionsV2StateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(catchError(() => ctx.dispatch(new SaveFailed())));
  }

  private _buildPromotionStatus(promotion: Promotion): PromotionStatus {
    if (!promotion.settings.enabled) {
      return {
        label: PromotionStatuses.DISABLED,
        color: 'default',
      };
    }

    const todaysDate = DateTime.fromJSDate(new Date()).toSeconds();
    const startDate = DateTime.fromJSDate(
      new Date(promotion.interval.start)
    ).toSeconds();
    const endDate = DateTime.fromJSDate(
      new Date(promotion.interval.end)
    ).toSeconds();

    if (todaysDate > endDate) {
      return {
        label: PromotionStatuses.ENDED,
        color: 'orange',
      };
    }

    if (todaysDate < startDate) {
      return {
        label: PromotionStatuses.PLANNED,
        color: 'blue',
      };
    }

    return {
      label: PromotionStatuses.IN_PROGRESS,
      color: 'green',
    };
  }

  private _buildExportData(
    ctx: StateContext<PromotionsV2StateModel>,
    exportData: ExportDataV2
  ): ExportRequestDataV2 {
    const filtersWithoutGroups: Filter[] = Object.values(
      ctx.getState().filtersGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const columnsWithoutGroups: DatatableColumnV2[] = Object.values(
      ctx.getState().columnsGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const visibleColumns: string[] = columnsWithoutGroups
      .filter(
        (column: DatatableColumnV2) =>
          column.checked && !this.notExportableColumns.includes(column.name)
      )
      .map((column: DatatableColumnV2) => column.name);

    return {
      visibleColumns,
      fileName: exportData.fileName,
      fileType: exportData.fileType,
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(ctx.getState().sortBy),
      },
      include: exportData.onlySelected
        ? [
            ...Object.values(ctx.getState().selection).map(
              (selected: SelectedPromotion) => selected.uuid
            ),
          ]
        : [],
    };
  }

  private _setPendingChanges(
    ctx: StateContext<PromotionsV2StateModel>,
    validChanges: boolean
  ): Observable<any> {
    const state = ctx.getState();

    return ctx.dispatch(
      new SetPendingChanges(PendingChangesKeys.PROMOTION, validChanges, {
        promotionName: state.currentPromotionEdited.promotionName,
        startDate: new Date(state.currentPromotion.interval.start),
        endDate: new Date(state.currentPromotion.interval.end),
        upliftType: state.currentPromotionEdited.upliftType,
        increase: state.currentPromotionEdited.increase,
        enabled: state.currentPromotionEdited.enabled,
        entireWebshop: state.currentPromotionEdited.entireWebshop,
      })
    );
  }
}
