import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { select, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { PendingChangesState } from 'src/app/core/states/pending-changes.state';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  CancelProductChanges,
  SaveProductChanges,
  UpdateManualServiceLevel,
  UpdateProductIgnored,
  UpdateProductMaximumStock,
  UpdateProductMinimumStockLevel,
  UpdateProductPhaseOut,
  UpdateProductPhaseOutEndDate,
  UpdateProductResumePurchaseDate,
} from 'src/app/features/products-v2/components/product-details-v2/actions/product-details-v2.actions';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';

@Component({
  selector: 'app-product-settings-v2',
  templateUrl: './product-settings-v2.component.html',
  styleUrls: ['./product-settings-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProductSettingsV2Component {
  @ViewChild('productSettingsForm') form: NgForm;

  viewOnly = select(
    PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.PRODUCTS)
  );

  hasPendingChanges = select(PendingChangesState.hasPendingChanges);

  hasValidPendingChanges = select(PendingChangesState.hasValidPendingChanges);

  isOverlay = select(ProductDetailsV2StateQueries.isOverlay);

  loading = select(ProductDetailsV2StateQueries.overviewLoading);

  saving = select(ProductDetailsV2StateQueries.saving);

  failed = select(ProductDetailsV2StateQueries.overviewFailed);

  ignoredInitially = select(ProductDetailsV2StateQueries.productIgnored);

  ignored = select(ProductDetailsV2StateQueries.settingsIgnored);

  minimumStock = select(ProductDetailsV2StateQueries.settingsMinimumStock);

  maximumStock = select(ProductDetailsV2StateQueries.settingsMaximumStock);

  phasedOut = select(ProductDetailsV2StateQueries.settingsPhasedOut);

  assembled = select(ProductDetailsV2StateQueries.isAssembled);

  phasedOutHasEndDate = select(
    ProductDetailsV2StateQueries.settingsPhasedOutHasEndDate
  );

  resumePurchaseDate = select(
    ProductDetailsV2StateQueries.settingsResumePurchaseDate
  );

  minResumePurchaseDate = select(
    ProductDetailsV2StateQueries.settingsMinResumePurchaseDate
  );

  manualServiceLevel = select(
    ProductDetailsV2StateQueries.settingsManualServiceLevel
  );

  cantChangeSettings = select(ProductDetailsV2StateQueries.cantChangeSettings);

  cantResumePurchase = select(ProductDetailsV2StateQueries.cantResumePurchase);

  productMaximumStockLevelEnabled = select(
    WebshopState.productMaximumStockLevelEnabled
  );

  formValid = select(ProductDetailsV2StateQueries.formValid);

  inputMinimumStockValid: boolean = true;
  inputMaximumStockValid: boolean = true;

  readonly METADATA = STRINGS.metadata.settings;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  constructor(private store: Store) {}

  updateIgnored(value: boolean): void {
    this.store.dispatch(
      new UpdateProductIgnored({ value, valid: this.form.valid })
    );
  }

  updateMinimumStockLevel(value: number): void {
    let valid = this.form.valid;

    if (this.maximumStock() && value && this.maximumStock() <= value) {
      valid = false;
    } else if (!value) {
      valid = true;
    }

    this.inputMinimumStockValid = valid;

    this.store.dispatch(
      new UpdateProductMinimumStockLevel({ value, valid: valid })
    );
  }

  updateMaximumStock(value: number): void {
    let valid = this.form.valid;

    if (value && this.minimumStock() && this.minimumStock() >= value) {
      valid = false;
    } else if (!value) {
      valid = true;
    }

    this.inputMaximumStockValid = valid;

    this.store.dispatch(new UpdateProductMaximumStock({ value, valid: valid }));
  }

  updatePhaseOut(value: boolean): void {
    this.store.dispatch(
      new UpdateProductPhaseOut({ value, valid: this.form.valid })
    );
  }

  updatePhaseOutEndDate(value: boolean): void {
    this.store.dispatch(
      new UpdateProductPhaseOutEndDate({ value, valid: this.form.valid })
    );
  }

  updateResumePurchaseDate(value: string): void {
    this.store.dispatch(
      new UpdateProductResumePurchaseDate({ value, valid: this.form.valid })
    );
  }

  updateManualServiceLevel(value: number): void {
    this.store.dispatch(
      new UpdateManualServiceLevel({ value, valid: this.form.valid })
    );
  }

  cancelChanges(): void {
    this.store.dispatch(new CancelProductChanges());
  }

  saveChanges(): void {
    this.store.dispatch(new SaveProductChanges());
  }
}
