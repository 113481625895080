<div class="container">
  <div class="container-actions">
    <button
      aria-label="Promotions editor info button"
      appButton
      size="sm"
      [variant]="productNotesOpened ? 'tertiary' : 'link'"
      class="header-dropdown-button"
      [class.custom-info-link-btn]="!productNotesOpened"
      (click)="productNotesOpened = !productNotesOpened">
      <span class="notes-title-container"
        >{{ METADATA.overview.notesInfo.title }}
        <mat-icon
          *ngIf="(notes$ | async) !== null && (notes$ | async) !== ''"
          class="notes-notification-icon"
          svgIcon="notes-notification"></mat-icon
      ></span>

      <mat-icon
        svgIcon="angle-down-xs"
        class="info-icon"
        [class.info-icon-opened]="productNotesOpened"></mat-icon>
    </button>
  </div>
</div>

<div *ngIf="productNotesOpened" class="container-details">
  <app-product-details-overview-notes />
</div>
