import { Selector } from '@ngxs/store';
import { AdviceDetails } from 'src/app/shared/models/buy-orders/v2/buy-order-advice.model';
import { Supplier } from 'src/app/shared/models/suppliers/product-suppliers.model';
import { WebshopProductSuppliersState } from '../../product-suppliers-v2/state/product-suppliers-v2.state';
import {
  SupplyChainInformationV2State,
  SupplyChainInformationV2StateModel,
} from './supply-chain-information-v2.state';

export class SupplyChainInformationV2StateQueries {
  @Selector([SupplyChainInformationV2State])
  static loading(state: SupplyChainInformationV2StateModel): boolean {
    return state.loading;
  }

  @Selector([SupplyChainInformationV2State])
  static failed(state: SupplyChainInformationV2StateModel): boolean {
    return state.failed;
  }

  @Selector([SupplyChainInformationV2State])
  static activeSupplier(
    state: SupplyChainInformationV2StateModel
  ): Supplier | null {
    return state.activeSupplier;
  }

  @Selector([SupplyChainInformationV2State])
  static purchaseAdviceBySupplier(
    state: SupplyChainInformationV2StateModel
  ): AdviceDetails {
    if (state.activeSupplier === null) {
      return {
        supplierProductUuid: null,
        supplierUuid: null,
        stockPosition: null,
        orderUpToLevel: null,
        demandPerCycle: null,
        safetyStock: null,
      };
    }

    return state.purchaseAdvice.find(
      advice => advice.supplierUuid === state.activeSupplier.supplierUuid
    );
  }

  @Selector([SupplyChainInformationV2State])
  static productDeliveryTimeByPreferredSupplier(
    state: SupplyChainInformationV2StateModel
  ): number | null {
    return state.activeSupplier?.supplierProduct.deliveryTime || null;
  }
}
