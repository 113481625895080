import {
  InjectionToken,
  InputSignal,
  InputSignalWithTransform,
  Signal,
  WritableSignal,
} from '@angular/core';
import { FilterStrategiesHandler } from '../components/core/filter-strategies-handler';
import { SelectionModel } from '@angular/cdk/collections';
import { ServiceRequestInfoV3Operator } from '../../../model/pageable-v2.model';
import {
  DatatableParam,
  SelectableColumnType,
} from '../../../model/data-table-v2.model';
import { SortedOrder } from '../../sort/model/sort.model';

export const FILTER_FORM = new InjectionToken('Filter Form');

export const FILTER_SHELL = new InjectionToken('Filter Shell');

export const FILTER = new InjectionToken('Filter');

export type FilterRangeValueType = string | number | null;

/**
 * Filter validations.
 *
 * Only has a single min/max validation useful for equals and range.
 *
 * If needed, we separate them into independent validations to
 * more complex validations.
 */
export interface FilterValidations {
  min: number | string | Date | null;
  max: number | string | Date | null;
  step: number | null;
}

export interface Filter {
  name: string | null;
  key: string;
  unit?: string;
  uiModel: SelectableColumnType;
  validations: FilterValidations;
  options?: FilterOptionV2[];
  params: DatatableParam;
  disabled: boolean;
  nullable?: boolean;
  sharedFilter?: boolean;
  partialMatch?: boolean;
}

export interface ColumnFilterGroup {
  groupKey: string;
  groupName: string;
  filters: ColumnFilter[];
}

export interface FilterableV2 {
  /** Column key **/
  key: Signal<string>;

  /** Filter params **/
  filter: Signal<Filter>;

  /** Sort order **/
  sort: Signal<SortedOrder | null>;
}

export interface FilterOptionV2 {
  key: string;
  value: any;
  icon?: string;
}

export interface FilterInputsValidation<T> {
  step?: number;

  min: T;

  max: T;
}

export interface FilterEqualsGroupV2 {
  to: string | number | Date | null;
}

export interface FilterRangeGroupV2 {
  from: string | number | Date | null;
  to: string | number | Date | null;
}

export interface FilterNullGroupV2 {
  to: null;
}

export interface FilterNullableGroupV2 {
  to: ServiceRequestInfoV3Operator;
}

export interface FilterListGroupV2 {
  selection: SelectionModel<FilterOptionV2>;
}

export interface FilterBooleanGroupV2 {
  to: boolean | null;
}

export interface FilterOptionsFormV2 {
  equals: FilterEqualsGroupV2;
  ranged: FilterRangeGroupV2;
}

export interface FilterStrategy<T> {
  form: T;

  populate(...args: any): void;

  hasValue(): boolean;

  hasSameValue(...args: any): boolean;

  clear(): void;
}

export interface FilteringStrategies<T> {
  filterStrategiesHandler: FilterStrategiesHandler;

  filterStrategy: FilterStrategy<T>;
}

export enum FilterTypeOptionsV2 {
  EQUALS = 'equals',
  RANGED = 'ranged',
  NOT_EXISTS = 'not_exists',
  IS_NULL = 'is_null',
  NULLABLE = 'nullable',
}

export type FilterTypesOptionsV2 =
  | FilterTypeOptionsV2.EQUALS
  | FilterTypeOptionsV2.RANGED
  | FilterTypeOptionsV2.NOT_EXISTS
  | FilterTypeOptionsV2.IS_NULL
  | FilterTypeOptionsV2.NULLABLE;

export interface FilterEmitted {
  applyAll: boolean;
}

export interface ColumnFilter extends Filter {
  columnKey: string;
}

export interface FilterShell {
  filter: WritableSignal<ColumnFilter> | Signal<ColumnFilter>;
}

export interface FilterBase {
  defaultName: InputSignalWithTransform<boolean, unknown>;
  disabled: InputSignalWithTransform<boolean, unknown>;
  filter: InputSignal<ColumnFilter>;
  filterValues: Signal<DatatableParam>;
  filterName: Signal<string>;
  filterDisabled: Signal<boolean>;
}
