import { Directionality } from '@angular/cdk/bidi';
import {
  ConnectedPosition,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  OverlayConnectionPosition,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TooltipPosition } from '../model/tooltip.model';

/** Adds the configured offset to a position. Used as a hook for child classes. */
export function addOffset(
  position: ConnectedPosition,
  dir: Directionality
): ConnectedPosition {
  const offset = 8;
  const isLtr = !dir || dir.value == 'ltr';

  if (position.originY === 'top') {
    position.offsetY = -offset;
  } else if (position.originY === 'bottom') {
    position.offsetY = offset;
  } else if (position.originX === 'start') {
    position.offsetX = isLtr ? -offset : offset;
  } else if (position.originX === 'end') {
    position.offsetX = isLtr ? offset : -offset;
  }

  return position;
}

/**
 * Returns the origin position and a fallback position based on the user's position preference.
 * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
 */
export function getOrigin(
  position: TooltipPosition,
  dir: Directionality
): {
  main: OriginConnectionPosition;
  fallback: OriginConnectionPosition;
} {
  const isLtr = !dir || dir.value === 'ltr';
  let originPosition: OriginConnectionPosition;

  if (
    position === 'above' ||
    position === 'top' ||
    position === 'below' ||
    position === 'bottom'
  ) {
    originPosition = {
      originX: 'center',
      originY: position === 'above' ? 'top' : 'bottom',
    };
  } else if (
    position === 'before' ||
    (position === 'left' && isLtr) ||
    (position === 'right' && !isLtr)
  ) {
    originPosition = { originX: 'start', originY: 'center' };
  } else if (
    position === 'after' ||
    (position === 'right' && isLtr) ||
    (position === 'left' && !isLtr)
  ) {
    originPosition = { originX: 'end', originY: 'center' };
  } else {
    throw new Error(`Invalid tooltip position: ${position}`);
  }

  const { x, y } = invertPosition(
    position,
    originPosition!.originX,
    originPosition!.originY
  );

  return {
    main: originPosition!,
    fallback: { originX: x, originY: y },
  };
}

/** Returns the overlay position and a fallback position based on the user's preference */
export function getOverlayPosition(
  position: TooltipPosition,
  dir: Directionality
): {
  main: OverlayConnectionPosition;
  fallback: OverlayConnectionPosition;
} {
  const isLtr = !dir || dir.value == 'ltr';
  let overlayPosition: OverlayConnectionPosition;

  if (position === 'above' || position === 'top') {
    overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
  } else if (position === 'below' || position === 'bottom') {
    overlayPosition = { overlayX: 'center', overlayY: 'top' };
  } else if (
    position === 'before' ||
    (position === 'left' && isLtr) ||
    (position === 'right' && !isLtr)
  ) {
    overlayPosition = { overlayX: 'end', overlayY: 'center' };
  } else if (
    position === 'after' ||
    (position === 'right' && isLtr) ||
    (position === 'left' && !isLtr)
  ) {
    overlayPosition = { overlayX: 'start', overlayY: 'center' };
  } else {
    throw new Error(`Invalid tooltip position: ${position}`);
  }

  const { x, y } = invertPosition(
    position,
    overlayPosition!.overlayX,
    overlayPosition!.overlayY
  );

  return {
    main: overlayPosition!,
    fallback: { overlayX: x, overlayY: y },
  };
}

function invertPosition(
  position: TooltipPosition,
  x: HorizontalConnectionPos,
  y: VerticalConnectionPos
) {
  if (
    position === 'above' ||
    position === 'top' ||
    position === 'below' ||
    position === 'bottom'
  ) {
    if (y === 'top') {
      y = 'bottom';
    } else if (y === 'bottom') {
      y = 'top';
    }
  } else {
    if (x === 'end') {
      x = 'start';
    } else if (x === 'start') {
      x = 'end';
    }
  }

  return { x, y };
}
