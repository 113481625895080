import { DialogRef } from '@angular/cdk/dialog';
import { ActivatedRoute } from '@angular/router';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ConceptBuyOrderOrder } from 'src/app/shared/models/buy-orders/v2/concept-buy-orders-v2.model';

export class LoadConceptsOrdersV2 {
  static readonly type = '[Draft Orders] Load Concepts V2';
}

export class InitializeConceptOrders {
  static readonly type = '[Draft Orders] Initialize Concept Orders';
}

export class ReloadDatatable {
  static readonly type = '[Draft Orders] Reload';
}

export class Order {
  static readonly type = '[Draft Orders] Order';

  constructor(
    public order: ConceptBuyOrderOrder,
    public relativeRoute: ActivatedRoute
  ) {}
}

export class BuyNow {
  static readonly type = '[Draft Orders] Buy Now';

  constructor(
    public order: ConceptBuyOrderOrder,
    public relativeRoute: ActivatedRoute
  ) {}
}

export class ViewOrder {
  static readonly type = '[Draft Orders] View order';

  constructor(public order: ConceptBuyOrderOrder) {}
}

export class Delete {
  static readonly type = '[Draft Orders] Delete Concept';

  constructor(
    public conceptOrderUUID: string,
    public dialogRef: DialogRef<any>
  ) {}
}

export class AddSearchParam {
  static readonly type = '[Draft Orders] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Draft Orders] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Draft Orders] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Draft Orders] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Draft Orders] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Draft Orders] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Draft Orders] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Draft Orders] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Draft Orders] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Draft Orders] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Draft Orders] Toggle Filter';
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Draft Orders] Reset Pagination And Load Data';
}

export class ExportConceptOrderV2 {
  static readonly type = '[Draft Orders] Export Concept Order';

  constructor(
    public fileType: string,
    public buyOrderUuid: string
  ) {}
}

export class NavigateToConceptLines {
  static readonly type = '[Draft Orders] Navigate to Concept Lines';

  constructor(
    public conceptUuid: string,
    public supplierUuid: string,
    public orderMomentUuid?: string
  ) {}
}
