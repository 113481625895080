import { ServiceRequestInfoV3SortByOrder } from '../../components/design-system/data-table-v2/model/pageable-v2.model';

export interface AccountSettings {
  colorTheme: ColorTheme;
  lastWebshop: string | null;
  language: LanguageSettings;
  preferredEmailLanguage: string;
  chatState: ChatState;
  serviceLevelGraphExcludedCategories: ServiceLevelGraphCategory[];
  generalMenuVisibility: MenuState;
  productInformationSalesGraphSettings: ProductInformationSalesGraphSettings;
  overdueDeliveriesTableSettings: TableSettings;
  futureDeliveriesTableSettings: TableSettings;
  completedDeliveriesTableSettings: TableSettings;
  deliveryEditorUndeliveredProductsTableSettings: TableSettings;
  deliveryEditorDeliveredProductsTableSettings: TableSettings;
  productInformationDeliveriesTableSettings: TableSettings;
  promotionsEditorTableSettings: TableSettings;
  productInformationPromotionsTableSettings: TableSettings;
  plannedOrdersV2TableSettings: TableSettings;
  draftOrdersV2TableSettings: TableSettings;
  proposedOrderEditorProposedV2TableSettings: TableSettings;
  proposedOrderEditorAvailableV2TableSettings: TableSettings;
  draftOrderEditorProposedV2TableSettings: TableSettings;
  draftOrderEditorAvailableV2TableSettings: TableSettings;
  productsEditorTableSettings: TableSettings;
  productInformationCompositionsTableSettings: TableSettings;
  productInformationPartsTableSettings: TableSettings;
  productInformationSupplierProductsTableSettings: TableSettings;
  promotionWebshopProductsEditorTableSettings: TableSettings;
  suppliersEditorTableSettings: TableSettings;
  supplierInformationSupplierProductsTableSettings: TableSettings;
  draftOrderEditorHeaderDropdownSelection: string[];
  proposedOrderEditorHeaderDropdownSelection: string[];
  purchaseCheckoutDoNotAskAgain: boolean;
}

export interface LanguageSettings {
  localeId: string;
  countryCode: string;
  codeAlpha2: string;
  codeAlpha3: string;
}

export interface ProductInformationSalesGraphSettings {
  historyLength: ProductInformationSalesGraphHistoryLength;
  grouping: ProductInformationSalesGraphGrouping;
}

export interface TableSettings {
  excludedColumns: string[];
  sortBy: string;
  orderBy: ServiceRequestInfoV3SortByOrder;
  pageSize: number;
}

export interface AccountSettingUpdateProperties {
  colorTheme: ColorTheme;
  lastWebshopUUID: string | null;
  localeId: string;
  preferredEmailLanguage: string;
  chatState: ChatState;
  serviceLevelGraphExcludedCategories: ServiceLevelGraphCategory[];
  overrideServiceLevelGraphExcludedCategories: boolean;
  generalMenuVisibility: MenuState;
  productInformationSalesGraphHistoryLength: ProductInformationSalesGraphHistoryLength;
  productInformationSalesGraphGrouping: ProductInformationSalesGraphGrouping;
  overdueDeliveriesTableExcludedColumns: string[];
  overrideOverdueDeliveriesTableExcludedColumns: boolean;
  overdueDeliveriesTableSort: string;
  overdueDeliveriesTablePageSize: number;
  futureDeliveriesTableExcludedColumns: string[];
  overrideFutureDeliveriesTableExcludedColumns: boolean;
  futureDeliveriesTableSort: string;
  futureDeliveriesTablePageSize: number;
  completedDeliveriesTableExcludedColumns: string[];
  overrideCompletedDeliveriesTableExcludedColumns: boolean;
  completedDeliveriesTableSort: string;
  completedDeliveriesTablePageSize: number;
  deliveryEditorUndeliveredProductsTableExcludedColumns: string[];
  overrideDeliveryEditorUndeliveredProductsTableExcludedColumns: boolean;
  deliveryEditorUndeliveredProductsTableSort: string;
  deliveryEditorUndeliveredProductsTablePageSize: number;
  deliveryEditorDeliveredProductsTableExcludedColumns: string[];
  overrideDeliveryEditorDeliveredProductsTableExcludedColumns: boolean;
  deliveryEditorDeliveredProductsTableSort: string;
  deliveryEditorDeliveredProductsTablePageSize: number;
  productInformationDeliveriesTableExcludedColumns: string[];
  overrideProductInformationDeliveriesTableExcludedColumns: boolean;
  supplierInformationSupplierProductsTableExcludedColumns: string[];
  overrideSupplierInformationSupplierProductsTableExcludedColumns: boolean;
  productInformationDeliveriesTableSort: string;
  productInformationDeliveriesTablePageSize: number;
  promotionsEditorTableExcludedColumns: string[];
  overridePromotionsEditorTableExcludedColumns: boolean;
  promotionsEditorTableSort: string;
  promotionsEditorTablePageSize: number;
  productInformationPromotionsTableExcludedColumns: string[];
  overrideProductInformationPromotionsTableExcludedColumns: boolean;
  productInformationPromotionsTableSort: string;
  productInformationPromotionsTablePageSize: number;
  plannedOrdersV2TableExcludedColumns: string[];
  overridePlannedOrdersV2TableExcludedColumns: boolean;
  plannedOrdersV2TableSort: string;
  plannedOrdersV2TablePageSize: number;
  draftOrdersV2TableExcludedColumns: string[];
  overrideDraftOrdersV2TableExcludedColumns: boolean;
  draftOrdersV2TablePageSize: number;
  draftOrdersV2TableSort: string;
  proposedOrderEditorProposedV2TableExcludedColumns: string[];
  overrideProposedOrderEditorProposedV2TableExcludedColumns: boolean;
  proposedOrderEditorProposedV2TablePageSize: number;
  proposedOrderEditorProposedV2TableSort: string;
  proposedOrderEditorAvailableV2TableExcludedColumns: string[];
  overrideProposedOrderEditorAvailableV2TableExcludedColumns: boolean;
  proposedOrderEditorAvailableV2TablePageSize: number;
  proposedOrderEditorAvailableV2TableSort: string;
  draftOrderEditorProposedV2TableExcludedColumns: string[];
  overrideDraftOrderEditorProposedV2TableExcludedColumns: boolean;
  draftOrderEditorProposedV2TablePageSize: number;
  draftOrderEditorProposedV2TableSort: string;
  draftOrderEditorAvailableV2TableExcludedColumns: string[];
  overrideDraftOrderEditorAvailableV2TableExcludedColumns: boolean;
  draftOrderEditorAvailableV2TablePageSize: number;
  draftOrderEditorAvailableV2TableSort: string;
  productsEditorTableExcludedColumns: string[];
  overrideProductsEditorTableExcludedColumns: boolean;
  productsEditorTableSort: string;
  productsEditorTablePageSize: number;
  productInformationCompositionsTableExcludedColumns: string[];
  overrideProductInformationCompositionsTableExcludedColumns: boolean;
  productInformationCompositionsTableSort: string;
  productInformationCompositionsTablePageSize: number;
  productInformationPartsTableExcludedColumns: string[];
  overrideProductInformationPartsTableExcludedColumns: boolean;
  productInformationPartsTableSort: string;
  productInformationPartsTablePageSize: number;
  productInformationSupplierProductsTableExcludedColumns: string[];
  overrideProductInformationSupplierProductsTableExcludedColumns: boolean;
  productInformationSupplierProductsTableSort: string;
  productInformationSupplierProductsTablePageSize: number;
  promotionWebshopProductsEditorTableExcludedColumns: string[];
  overridePromotionWebshopProductsEditorTableExcludedColumns: boolean;
  promotionWebshopProductsEditorTableSort: string;
  promotionWebshopProductsEditorTablePageSize: number;
  suppliersEditorTableExcludedColumns: string[];
  overrideSuppliersEditorTableExcludedColumns: boolean;
  suppliersEditorTableSort: string;
  suppliersEditorTablePageSize: number;
  draftOrderEditorHeaderDropdownSelection: string[];
  overrideDraftOrderEditorHeaderDropdownSelection: boolean;
  proposedOrderEditorHeaderDropdownSelection: string[];
  overrideProposedOrderEditorHeaderDropdownSelection: boolean;
  importsTableExcludedColumns: string[];
  overrideImportsTableExcludedColumns: boolean;
  importsTableSort: string;
  importsTablePageSize: number;
  importDetailsTableExcludedColumns: string[];
  overrideImportDetailsTableExcludedColumns: boolean;
  importDetailsTableSort: string;
  importDetailsTablePageSize: number;
  purchaseCheckoutDoNotAskAgain: boolean;
}

export type AccountSettingsUpdate = Partial<AccountSettingUpdateProperties>;

export type ColorTheme = ColorThemes.LIGHT | ColorThemes.DARK;

export enum ColorThemes {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export type ChatState = ChatStates.OPENED | ChatStates.CLOSED;

export enum ChatStates {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

export type ServiceLevelGraphCategory =
  | ServiceLevelGraphCategories.A
  | ServiceLevelGraphCategories.B
  | ServiceLevelGraphCategories.C;

export enum ServiceLevelGraphCategories {
  A = 'A',
  B = 'B',
  C = 'C',
}

export type MenuState =
  | MenuStates.HIDDEN
  | MenuStates.COLLAPSED
  | MenuStates.EXPANDED;

export enum MenuStates {
  HIDDEN = 'HIDDEN',
  COLLAPSED = 'COLLAPSED',
  EXPANDED = 'EXPANDED',
}

export type ProductInformationSalesGraphHistoryLength =
  | ProductInformationSalesGraphHistoryLengths.ALL_TIME
  | ProductInformationSalesGraphHistoryLengths.LAST_YEAR
  | ProductInformationSalesGraphHistoryLengths.LAST_6_MONTHS
  | ProductInformationSalesGraphHistoryLengths.LAST_3_MONTHS
  | ProductInformationSalesGraphHistoryLengths.LAST_MONTH;

export enum ProductInformationSalesGraphHistoryLengths {
  ALL_TIME = 'ALL_TIME',
  LAST_YEAR = 'LAST_YEAR',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_MONTH = 'LAST_MONTH',
}

export type ProductInformationSalesGraphGrouping =
  | ProductInformationSalesGraphGroups.DAILY
  | ProductInformationSalesGraphGroups.WEEKLY
  | ProductInformationSalesGraphGroups.MONTHLY;

export enum ProductInformationSalesGraphGroups {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}
