import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductPromotionsV2DataTableComponent } from '../default/product-promotions-v2-data-table.component';

@Component({
  selector: 'app-product-promotions-v2-data-table-view-only',
  templateUrl: './product-promotions-v2-data-table-view-only.component.html',
  styleUrl: '../default/product-promotions-v2-data-table.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPromotionsV2DataTableViewOnlyComponent extends ProductPromotionsV2DataTableComponent {}
