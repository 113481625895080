import { DateTime } from 'luxon';
import {
  OrderRedirectTo,
  PurchaseOrderType,
} from 'src/app/features/purchase-v3/model/purchase-v3.model';
import { OrderMomentType } from 'src/app/shared/models/buy-orders/v2/buy-order-order-moment.model';
import { BuyOrderlinesSupplier } from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2.model';
import { PurchaseConfirmationStateModel } from '../state/purchase-confirmation-dialog-v2.state';

export interface PurchaseConfirmationDataV2 {
  buyOrderConceptUuid?: string;
  orderMomentUuid?: string;
  supplierUuid: string;
  orderType: PurchaseOrderType;
  sessionUuid: string;
  redirectTo: string;
  leadTime: number;
}

export interface PurchaseConfirmationV2Overview {
  orderUuid: string;
  orderMomentUuid: string;
  orderMomentType: OrderMomentType;
  orderMomentDate: string;
  supplier: BuyOrderlinesSupplier;
  planningTrigger: boolean;
  containerTotalWeight: number | null;
  containerTotalVolume: number | null;
  isOrderNotSynced: boolean;
}

export interface PurchaseConfirmationRudderData {
  webshopUUID: string;
  buyOrderConceptUUID: string;
  orderMomentUUID: string | null;
  orderMomentType: OrderMomentType | null;
  orderMomentDate: string | null;
  supplierUUID: string;
  planningTrigger: boolean;
  newOrder: boolean;
}

export const defaultsPurchaseConfirmation: PurchaseConfirmationStateModel = {
  loading: true,
  todaysDate: DateTime.fromJSDate(new Date()).toUTC().toJSDate(),
  orderUuid: null,
  orderId: null,
  orderMomentUUID: null,
  orderMomentType: null,
  orderMomentDate: null,
  supplier: null,
  planningTrigger: false,
  totalMaxLoadCapacity: 0,
  totalContainerVolume: 0,
  newOrder: true,
  planningDate: new Date(),
  planningMinDate: new Date(),
  replanEnabled: true,
  nextOrderMomentDate: null,
  daysToNextOrderMoment: 0,
  useSupplierSettings: false,
  shouldSaveSupplierProductChanges: false,
  shouldSaveWebshopProductChanges: true,
  isOrderNotSynced: false,
  redirectTo: OrderRedirectTo.DRAFT,
};
