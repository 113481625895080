import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  delay,
  filter,
  map,
  skip,
  takeUntil,
  tap,
} from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { CopyElement } from './actions/copy-to-clipboard.actions';

let id: number = 0;

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent implements OnInit, OnDestroy {
  @Input()
  id: string = `copy-${++id}`;

  @Input() message: string;

  @Input() buttonFirst: boolean;

  copied$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  highlighted$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly COPY_WARNING_DURATION = 2000;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private notificationCenter: NotificationCenterService,
    private store: Store,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(
        ofActionSuccessful(CopyElement),
        debounceTime(240),
        map((action: CopyElement) => action.element),
        takeUntil(this.destroy$)
      )
      .subscribe((copiedElement: string | number) => {
        this.copied$.next(this.sameCopiedElement(copiedElement));
      });

    // Adds highlight with duration when copied
    this.copied$
      .pipe(
        skip(1),
        filter((copied: boolean) => copied),
        tap(() => {
          this.highlighted$.next(true);

          this.notificationCenter.showToast(
            this.message + ` ${MESSAGES.common.copiedToClipboard}`
          );
        }),
        delay(this.COPY_WARNING_DURATION),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.highlighted$.next(false);
      });

    // Removes highlight without duration to not block the stream
    this.copied$
      .pipe(
        skip(1),
        filter((copied: boolean) => !copied),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.highlighted$.next(false);
      });
  }

  onClick(event: any): void {
    event.stopPropagation();

    this.store.dispatch(new CopyElement(this.id));
  }

  /**
   * Determines if its the same copied element
   * @param currentCopiedElement string or number
   * @returns true if copied element is the same
   */
  private sameCopiedElement(currentCopiedElement: string | number): boolean {
    return currentCopiedElement === this.id;
  }

  ngOnDestroy(): void {
    this.copied$.complete();
    this.highlighted$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }
}
