export interface ColumnsGroup {
  key: string;
  name: string | null;
}

export interface ColumnsGroups {
  FIXED_START: ColumnsGroup;
  ORDER: ColumnsGroup;
  SUPPLIER: ColumnsGroup;
  PRODUCT: ColumnsGroup;
  SUPPLIER_PRODUCT: ColumnsGroup;
  PROMOTION: ColumnsGroup;
  GENERAL: ColumnsGroup;
  FIXED_END: ColumnsGroup;
}

export const COLUMNS_GROUPS: ColumnsGroups = {
  FIXED_START: {
    key: 'FIXED_START',
    name: null,
  },
  ORDER: {
    key: 'ORDER',
    name: $localize`Order`,
  },
  SUPPLIER: {
    key: 'SUPPLIER',
    name: $localize`Supplier`,
  },
  PRODUCT: {
    key: 'PRODUCT',
    name: $localize`Product`,
  },
  SUPPLIER_PRODUCT: {
    key: 'SUPPLIER_PRODUCT',
    name: $localize`Supplier Product`,
  },
  PROMOTION: {
    key: 'PROMOTION',
    name: $localize`Promotion`,
  },
  GENERAL: {
    key: 'GENERAL',
    name: $localize`General`,
  },
  FIXED_END: {
    key: 'FIXED_END',
    name: null,
  },
};
