import {
  SetPendingChanges,
  SetPendingDataTableChanges,
} from 'src/app/core/actions/pending-changes.action';
import InitialData from './initial-data.state';

export class DataChanges extends InitialData {
  private changes: Map<string, any> = new Map();

  // Useful to keep old data to merge with new data
  private helperMap: Map<string, any>;

  constructor() {
    super();
  }

  /**
   * Gets all changes from the state
   * @returns all changes
   */
  protected getAllChanges(): any {
    return this.changes.entries();
  }

  /**
   * Gets change from its hash
   * @param hash component key
   * @returns change relative to its hash
   */
  protected getChange(hash: string): any | null {
    if (hash && this.changes.has(hash)) {
      return this.changes.get(hash);
    }

    return null;
  }

  /**
   * Gets changes size
   * @returns changes size
   */
  protected getChangesSize(): number {
    return this.changes.size;
  }

  /**
   * Deletes change
   * @param hash component key
   * @returns true if hash is found and deleted successfully
   */
  protected deleteChange(hash: string): boolean {
    if (this.changes.has(hash)) {
      return this.changes.delete(hash);
    }

    return false;
  }

  /**
   * Deletes all changes
   */
  protected deleteAllChanges(): void {
    this.changes.clear();
  }

  /**
   * Sets the datatable changes with its component's key (table key)
   * @param hash component key (PendingChangesKeys.[tableKey])
   * @param value changes (form / actions)
   */
  protected addDataTableChange(hash: string, value: Map<string, any>) {
    this.helperMap = new Map(this.getInitialData(hash));

    this.buildDataTableData(hash, value);

    this.addInitialData(hash, this.helperMap);
  }

  /**
   * Builds data table changes. Used ONLY with data tables.
   *
   * If change is different from the initial it's added to the state else it's removed.
   *
   * If there's no changes for a component key (table), the key is also removed from the state.
   * @param payload SetPendingChanges (component key, changes)
   */
  protected buildDataTableChanges(payload: SetPendingDataTableChanges): void {
    const componentInitialData = this.getInitialData(payload.component);
    const { key, value } = payload.changes;

    const existingHashValue = componentInitialData.get(key);

    if (
      JSON.stringify(existingHashValue) === JSON.stringify(value) &&
      this.changes.get(payload.component)?.has(key)
    ) {
      this.changes.get(payload.component).delete(key);

      if (this.changes.get(payload.component).size === 0) {
        this.changes.delete(payload.component);
      }
    } else {
      const newChangesMap = new Map(this.changes.get(payload.component)).set(
        key,
        value
      );

      this.changes.set(payload.component, newChangesMap);
    }
  }

  /**
   * Builds data changes. This is used for simple changes in forms (Overlays, Settings, etc.).
   *
   * If change is different from the initial it's added to the state else it's removed.
   *
   * If there's no changes for a component key (table), the key is also removed from the state.
   * @param payload SetPendingChanges (component key, changes)
   */
  protected buildSimpleChanges(payload: SetPendingChanges): void {
    const componentInitialData = this.getInitialData(payload.component);

    if (
      JSON.stringify(componentInitialData) === JSON.stringify(payload.changes)
    ) {
      this.changes.delete(payload.component);
    } else {
      this.changes.set(payload.component, payload.changes);
    }
  }

  /**
   * It add new data to the state depending if it already exists (table pagination)
   * @param hash component key (PendingChangesKeys.[tableKey])
   * @param value changes (form / actions)
   */
  private buildDataTableData(hash: string, value: Map<string, any>): void {
    [...value.keys()].forEach((lineKey: string) => {
      if (!this.valueKeyExists(hash, value.get(lineKey))) {
        this.helperMap.set(lineKey, value.get(lineKey));
      }
    });
  }

  /**
   * It checks if value already exists for a key
   * @param hash component key (PendingChangesKeys.[tableKey])
   * @param value changes
   * @returns true if key exists
   */
  private valueKeyExists(hash: string, value: any): boolean {
    return (
      this.getInitialData(hash) &&
      Object.keys(this.getInitialData(hash)).includes(value)
    );
  }
}
