import { DataAdapter } from 'src/app/shared/adapter/adapter';

import { Issues as IssuesV1 } from '../model/issues.model';
import {
  ISSUE_STATUS,
  Issue,
  sortIssuesStatus,
} from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.model';
import { STRINGS } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.strings';

export class IssuesV2Adapter implements DataAdapter<IssuesV1, Issue[]> {
  transform(data: IssuesV1): Issue[] {
    const issues: Issue[] = data.issues.map(issue => {
      return {
        status: issue.issue,
        label: STRINGS.metadata.todoList.issues.labels[issue.issue],
        subject: `${issue.total} ${
          STRINGS.metadata.todoList.issues.descriptions.subject[issue.issue]
        }`,
        description:
          STRINGS.metadata.todoList.issues.descriptions.description[
            issue.issue
          ],
        color: ISSUE_STATUS[issue.issue],
        total: issue.total,
      };
    });

    issues.sort(sortIssuesStatus);

    return issues;
  }
}
