import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';

export enum SnoozeProductTypes {
  NEXT_ORDER_MOMENT = 'NEXT-ORDER-MOMENT',
  ONE_WEEK = 'ONE-WEEK',
  ONE_MONTH = 'ONE-MONTH',
  THREE_MONTH = 'THREE-MONTH',
  CUSTOM_DATE = 'CUSTOM-DATE',
  INDEFINITE = 'INDEFINITE',
}

export interface SnoozeProductData {
  orderline: ProposedBuyOrderline;
  effectiveReplenishmentPeriod: number;
}
