import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, map, Observable } from 'rxjs';
import { AppState } from 'src/app/core/states/app.state';
import {
  AddProductsToAPromotion,
  Reset,
} from './actions/add-products-to-promotion.actions';
import { AddProductsPromotionStateQueries } from './state/add-products-to-promotion.queries';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-add-products-to-promotion-dialog',
  templateUrl: './add-products-to-promotion-dialog.component.html',
  styleUrls: ['./add-products-to-promotion-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductsToPromotionDialogComponent implements OnDestroy {
  @Select(AppState.isSaving)
  saving$: Observable<boolean>;

  @Select(AddProductsPromotionStateQueries.loading)
  loading$: Observable<boolean>;

  @Select(AddProductsPromotionStateQueries.areSomeSelected)
  areSomeSelected$: Observable<boolean>;

  @Select(AddProductsPromotionStateQueries.areAllSelected)
  areAllSelected$: Observable<boolean>;

  disabled$: Observable<boolean>;

  constructor(
    public store: Store,
    private dialogRef: DialogRef
  ) {
    this.disabled$ = combineLatest([this.saving$, this.loading$]).pipe(
      map(([loading, saving]) => loading || saving)
    );
  }

  save(): void {
    this.store.dispatch(new AddProductsToAPromotion());
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new Reset());
  }
}
