import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import { SaveConceptProperties } from '../model/proposed-orderlines.model';
import {
  ColumnFilterParam,
  DatatableColumnV2,
  DatatableColumnV2Groups,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { DialogRef } from '@angular/cdk/dialog';
import { SnoozeProductDialogComponent } from 'src/app/shared/components/snooze-product-dialog/snooze-product-dialog.component';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

export class LoadProposedOrderlinesInitialData {
  static readonly type = '[Proposed Order Lines] Load Proposed Initial Data';

  constructor(
    public supplierUuid: string,
    public orderMomentUuid: string
  ) {}
}

export class LoadProposedOrderlinesV2 {
  static readonly type = '[Proposed Order Lines] Load Proposed Order Lines V2';

  constructor(public supplierUuid: string) {}
}

export class ReloadDatatable {
  static readonly type = '[Proposed Order Lines] Reload';
}

export class LoadProposedOrderlinesOverviewV2 {
  static readonly type =
    '[Proposed Order Lines] Load Proposed Order Lines Overview';

  constructor(
    public supplierUuid: string,
    public orderMomentUuid: string
  ) {}
}

export class HeaderInfoProposedPersistence {
  static readonly type = '[Proposed Order Lines] Header Info OpenClose';

  constructor(public openClose: string[]) {}
}

export class GenerateSession {
  static readonly type = '[Proposed Order Lines] Generate Session';
}

export class UpdateSessionForFactor {
  static readonly type = '[Proposed Order Lines] Update Session For Factor';
  constructor(public factor: number) {}
}

export class AddSearchParam {
  static readonly type = '[Proposed Order Lines] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Proposed Order Lines] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Proposed Order Lines] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Proposed Order Lines] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Proposed Order Lines] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Proposed Order Lines] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Proposed Order Lines] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Proposed Order Lines] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Proposed Order Lines] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class SetColumnSelection {
  static readonly type = '[Proposed Order Lines] Set Column Selection';

  constructor(
    public columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>,
    public applyAll: boolean
  ) {}
}

export class PatchLinePrice {
  static readonly type = '[Proposed Order Lines] Patch Line Price';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedPrice: string
  ) {}
}

export class PatchLineQuantity {
  static readonly type = '[Proposed Order Lines] Patch Line Quantity';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedQuantity: string
  ) {}
}

export class PatchLineWeight {
  static readonly type = '[Proposed Order Lines] Patch Line Weight';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedWeight: string
  ) {}
}

export class PatchLineVolume {
  static readonly type = '[Proposed Order Lines] Patch Line Volume';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedVolume: string
  ) {}
}

export class RemoveProductFromOrder {
  static readonly type = '[Proposed Order Lines] Remove Product';

  constructor(public supplierProductUuid: string) {}
}

export class RemoveProductsFromOrder {
  static readonly type = '[Proposed Order Lines] Remove Products';
}

export class SnoozeProduct {
  static readonly type = '[Proposed Order Lines] Snooze Product';

  constructor(
    public supplierProductUuid: string,
    public resumingPurchase: string | null,
    public webshopProductUuid: string,
    public dialogRef?: DialogRef<SnoozeProductDialogComponent>
  ) {}
}

export class SnoozeProducts {
  static readonly type = '[Proposed Order Lines] Snooze Products';

  constructor(
    public resumingPurchase: string,
    public dialogRef?: DialogRef<SnoozeProductDialogComponent>
  ) {}
}

export class OrderLineChanged {
  static readonly type = '[Proposed Order Line Updated] order line updated';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderLineRemoved {
  static readonly type = '[Proposed Order Line Removed] order line removed';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderLinesRemoved {
  static readonly type =
    '[Proposed Order Lines Bulk Removed] order lines removed';
}

export class OrderLineSnoozed {
  static readonly type = '[Proposed Order Line Snoozed] order line snoozed';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderLinesSnoozed {
  static readonly type =
    '[Proposed Order Lines Bulk Snoozed] order lines snoozed';
}

export class OrderlineErrored {
  static readonly type = '[Proposed Order Line Error] order line error';

  constructor(
    public errorMessage: string,
    public supplierProductUuid: string
  ) {}
}

export class InitializePurchase {
  static readonly type = '[Proposed Order Lines] Initialize Purchase';

  constructor(
    public supplierUuid: string,
    public orderMomentUuid: string,
    public orderMomentType: string,
    public future: string
  ) {}
}

export class HideColumn {
  static readonly type = '[Proposed Order Lines] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Proposed Order Lines] Toggle Filter';
}

export class TriggerPurchaseConfirmation {
  static readonly type = '[Proposed Order Lines] TriggerPurchaseConfirmation';
}

export class SaveConcept {
  static readonly type = '[Proposed Order Lines] Save Concept';

  constructor(
    public properties: SaveConceptProperties = { showMessage: true }
  ) {}
}

export class CreateConcept {
  static readonly type = '[Proposed Order Lines] Create Concept';

  constructor(public properties: SaveConceptProperties) {}
}

export class UpdateConcept {
  static readonly type = '[Proposed Order Lines] Update Concept';

  constructor(public properties: SaveConceptProperties) {}
}

export class ReloadBothTables {
  static readonly type = '[Proposed Order Lines] Reload Editor Both Tables';

  constructor(
    public payload: { showLoading: boolean } = { showLoading: false }
  ) {}
}
export class ResetBuyOrder {
  static readonly type = '[Proposed Order Lines] Reset Buy Order';
}

export class ToggleRowSelection {
  static readonly type = '[Proposed Order Lines] Toggle Row Selection';

  constructor(
    public row: ProposedBuyOrderline,
    public rowQuantity: number,
    public isSelected: boolean
  ) {}
}

export class ClearSelection {
  static readonly type = '[Proposed Order Lines] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type = '[Proposed Order Lines] Master Toggle Selection';
}

export class SocketDisconnection {
  static readonly type = '[Proposed Order Lines] Socket Disconnection';
}

export class RefreshOrderlinesPage {
  static readonly type = '[Proposed Order Lines] Refresh Orderlines Page';
}

export class LoseFocus {
  static readonly type = '[Proposed Order Lines] Lose Focus';
}

export class InitializeProposedOrderlinesTable {
  static readonly type =
    '[Proposed Order Lines] Initialize Proposed Orderlines Table';

  constructor(public supplierUuid: string) {}
}

export class ResetPaginationAndLoadData {
  static readonly type =
    '[Proposed Order Lines] Reset Pagination And Load Data';
}
