import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { NavBarV2ContentDirective } from './directives/nav-bar-v2-content.directive';
import { NavigateTo } from 'src/app/core/actions/navigation.action';

@Component({
  selector: 'app-basic-context-nav-bar-v2',
  templateUrl: './basic-context-nav-bar-v2.component.html',
  styleUrls: ['./basic-context-nav-bar-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicContextNavBarV2Component {
  @Input() titleName: string;

  @Input() subTitle: string;

  @Input() routing: string;

  @ContentChild(NavBarV2ContentDirective)
  contentTemplate: NavBarV2ContentDirective;

  constructor(private store: Store) {}

  /**
   * Navigates to the the previous page
   */
  navigate(): void {
    if (this.routing) {
      this.store.dispatch(new NavigateTo([this.routing]));
    }
  }
}
