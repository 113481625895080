import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Job } from '../model/integrations.model';

export class JobsAdapter implements DataAdapter<any, Job[]> {
  transform(result: any): Job[] {
    if (result.jobs) {
      return result.jobs.map(job => {
        return {
          env_id: 'dev.hotglue.optiply.nl',
          name: job.job_name,
          status: job.status,
          startDate: job.start_time,
          lastUpdateDate: job.last_updated,
          metrics: job.metrics ? job.metrics.recordCount : null,
          totalRows: job.metrics
            ? Object.keys(job.metrics.recordCount).length / 4
            : 0,
          hotglue: true,
          tenant: job.tenant,
        };
      });
    }
  }
}
