import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { BuyOrderlinesOverview } from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2.model';
import {
  ProposedBuyOrderContainer,
  ProposedBuyOrderOrderMoment,
  ProposedBuyOrderSupplier,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';
import {
  ProposedBuyOrderContainer as ProposedBuyOrderContainerV1,
  ProposedBuyOrderOrderMoment as ProposedBuyOrderOrderMomentV1,
  ProposedBuyOrderSupplier as ProposedBuyOrderSupplierV1,
} from '../model/proposed-buy-order-v2-model';
import { ProposedBuyOrderlinesOverview as ProposedBuyOrderlinesOverviewV1 } from '../model/proposed-buy-orderlines-overview-v2.model';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { formatDateWithCutoffToWebshopTimezone } from 'src/app/shared/utils/dates.utils';

export class ProposedBuyOrderlinesOverviewV2Adapter
  implements
    DataAdapter<ProposedBuyOrderlinesOverviewV1, BuyOrderlinesOverview>
{
  constructor(private store: Store) {}

  transform(data: ProposedBuyOrderlinesOverviewV1): BuyOrderlinesOverview {
    return {
      order: {
        uuid: data.order.uuid,
        numberOfProducts: data.order.numberOfProducts,
        totalValue: data.order.totalValue,
        supplier: this._buildSupplier(data.order.supplier),
        orderMoment: this._buildOrderMoment(
          data.order.orderMoment,
          data.order.supplier.cutoff
        ),
        container: this._buildContainer(data.order.container),
        planningTrigger: data.order.planningTrigger,
        notes: data.order.notes,
      },
    };
  }

  private _buildSupplier(
    supplierProduct: ProposedBuyOrderSupplierV1
  ): ProposedBuyOrderSupplier | null {
    if (!supplierProduct) return null;

    let newDate = null;

    if (supplierProduct.cutoff) {
      newDate = this._buildTimeZoneCutoff(supplierProduct.cutoff);
    }

    return {
      uuid: supplierProduct.uuid,
      name: supplierProduct.name,
      leadTimeMeanActual: supplierProduct.leadTimeMeanActual,
      deliveryTime: supplierProduct.deliveryTime,
      effectiveReplenishmentPeriod:
        supplierProduct.effectiveReplenishmentPeriod,
      containerWeightCapacity: supplierProduct.containerWeightCapacity,
      containerVolumeCapacity: supplierProduct.containerVolumeCapacity,
      minimalOrderValue: supplierProduct.minimalOrderValue,
      exportQuantityAsLots: supplierProduct.exportQuantityAsLots,
      id: supplierProduct.id,
      notes: supplierProduct.notes,
      cutoff: newDate,
      ownerEmail: supplierProduct.ownerEmail,
    };
  }

  private _buildOrderMoment(
    orderMoment: ProposedBuyOrderOrderMomentV1,
    cutoff: string
  ): ProposedBuyOrderOrderMoment | null {
    if (!orderMoment) return null;

    let newDate = orderMoment.date;

    if (orderMoment.date && cutoff) {
      newDate = this._buildTimeZoneDate(cutoff, orderMoment.date);
    }

    return {
      date: newDate,
      type: orderMoment.type,
      triggeredValue: orderMoment.triggeredValue,
      uuid: orderMoment.uuid,
      id: orderMoment.id,
    };
  }

  private _buildContainer(
    container: ProposedBuyOrderContainerV1
  ): ProposedBuyOrderContainer | null {
    if (!container) return null;

    return {
      numberOfContainers: container.numberOfContainers,
      totalContainerWeightCapacity: container.totalContainerWeightCapacity,
      totalContainerVolumeCapacity: container.totalContainerVolumeCapacity,
      totalWeight: container.totalWeight,
      totalVolume: container.totalVolume,
      relativeTotalWeight: container.relativeTotalWeight,
      relativeTotalVolume: container.relativeTotalVolume,
    };
  }

  private _buildTimeZoneCutoff(cutoff: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const todayDateWebshopTimeZone = formatDateWithCutoffToWebshopTimezone(
      timeZoneIdentifier,
      cutoff
    );

    return DateTime.fromJSDate(new Date(todayDateWebshopTimeZone)).toFormat(
      'HH:mm'
    );
  }

  private _buildTimeZoneDate(cutoff: string, date: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );

    const todayDateWebshopTimeZone = formatDateWithCutoffToWebshopTimezone(
      timeZoneIdentifier,
      cutoff,
      date
    );

    return DateTime.fromJSDate(new Date(todayDateWebshopTimeZone)).toFormat(
      'yyyy-MM-dd'
    );
  }
}
