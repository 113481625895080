import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngxs/store';
import {
  NOT_AVAILABLE_VALUE,
  RESULTS_SELECTION_LIMIT_AMOUNT,
} from 'src/app/core/constants/global.constants';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { ProductsPromotions } from 'src/app/shared/models/promotion/promotion.model';
import {
  AddSearchParam,
  ClearSelection,
  ColumnsSelected,
  HideColumn,
  MasterToggleSelection,
  Paginate,
  RemoveSearchParam,
  Sort,
  ToggleRowSelection,
} from '../add-products-to-promotion-dialog/actions/add-products-to-promotion.actions';
import { AddProductsPromotionStateQueries } from '../add-products-to-promotion-dialog/state/add-products-to-promotion.queries';
import { DataTableV2Component } from '../../../design-system/data-table-v2/data-table-v2.component';
import {
  Pagination,
  Search,
} from '../../../design-system/data-table-v2/model/data-table-v2.model';
import { Sorted } from '../../../design-system/data-table-v2/components/sort/model/sort.model';
import { PageEventV2 } from '../../../design-system/data-table-v2/components/paginator/paginator.component';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { COLUMNS_ORDER } from './model/add-products-to-promotion-data-table.model';
import { ColumnSelectionV3 } from '../../../design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { AddProductsPromotionState } from '../add-products-to-promotion-dialog/state/add-products-to-promotion.state';

@Component({
  selector: 'app-add-products-to-promotion-data-table',
  templateUrl: './add-products-to-promotion-data-table.component.html',
  styleUrls: ['./add-products-to-promotion-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductsToPromotionDataTableComponent
  extends DataTableV2Component<ProductsPromotions[]>
  implements Pagination, Search
{
  totalElements = select(AddProductsPromotionStateQueries.totalElements);
  pageSizeOptions = select(AddProductsPromotionStateQueries.pageSizeOptions);
  pageSize = select(AddProductsPromotionStateQueries.pageSize);
  currentPage = select(AddProductsPromotionStateQueries.currentPage);
  selectableColumns = select(
    AddProductsPromotionStateQueries.selectableColumns
  );
  displayedColumns = select(AddProductsPromotionStateQueries.displayedColumns);
  lastDisplayedColumn = select(
    AddProductsPromotionStateQueries.lastDisplayedColumn
  );
  datasource = select(AddProductsPromotionStateQueries.products);
  loading = select(AddProductsPromotionStateQueries.loading);
  searchBy = select(AddProductsPromotionStateQueries.search);

  isProductSelected = select(
    AddProductsPromotionStateQueries.isProductSelected
  );
  areAllSelected = select(AddProductsPromotionStateQueries.areAllSelected);
  areSomeSelected = select(AddProductsPromotionStateQueries.areSomeSelected);
  limitSelection = select(AddProductsPromotionStateQueries.limitSelection);
  selectedAmount = select(AddProductsPromotionStateQueries.selectedAmount);

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_COLUMNS = STRINGS.columns.products;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly RESULTS_SELECTION_LIMIT = RESULTS_SELECTION_LIMIT_AMOUNT;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RELATED_STATE = AddProductsPromotionState;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  constructor(private store: Store) {
    super();
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onColumnHidden(columnKey: string) {
    this.store.dispatch(new HideColumn(columnKey));
  }

  onSort(sort: Sorted) {
    this.store.dispatch(new Sort(sort));
  }

  addSearchParam(param: string) {
    this.store.dispatch(new AddSearchParam(param));
  }

  removeSearchParam(param: string) {
    this.store.dispatch(new RemoveSearchParam(param));
  }

  masterToggle(): void {
    this.store.dispatch(new MasterToggleSelection());
  }

  toggleRowSelection(isSelected: boolean, rowKey: string): void {
    this.store.dispatch(new ToggleRowSelection(rowKey, isSelected));
  }

  clearSelection(): void {
    this.store.dispatch(new ClearSelection());
  }
}
