import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { LinkedSource, LinkedTarget } from '../model/integrations.model';

export class LinkedTargetsAdapter implements DataAdapter<any, LinkedTarget[]> {
  transform(linkedTargets: any): LinkedTarget[] {
    return linkedTargets.map(linkedSource => {
      var source: LinkedTarget = {
        target: linkedSource.target,
        tenant: linkedSource.tenant_id,
      };

      return source;
    });
  }
}
