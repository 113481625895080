<app-supply-chain-section-container>
  <ng-container title>{{ METADATA.title }} </ng-container>

  <ng-container content>
    <!-- Loading supplier -->
    <ng-container *ngIf="activeSupplier() === null && loading()">
      <ng-container *ngTemplateOutlet="notAvailable"></ng-container>
    </ng-container>

    <app-supply-chain-section-row
      data-intercom-target="eb717729-f5e2-452f-826e-3b66b70b327b"
      aria-label="Product details supply chain information lead time"
      [disabled]="productDeliveryTimeByPreferredSupplier() !== null">
      <ng-container description>{{ METADATA.deliveryTime }}</ng-container>
      <ng-container
        *ngIf="activeSupplier()?.deliveryTime; else notAvailable"
        value
        >{{ activeSupplier().deliveryTime | customDecimal }}</ng-container
      >
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="6cc82023-c58a-4877-8ed3-649e05c5cbe5"
      aria-label="Product details supply chain information product lead time"
      [disabled]="productDeliveryTimeByPreferredSupplier() === null">
      <ng-container description>{{ METADATA.productLeadTime }}</ng-container>
      <ng-container
        *ngIf="
          productDeliveryTimeByPreferredSupplier() !== null;
          else notAvailable
        "
        value
        >{{ productDeliveryTimeByPreferredSupplier() }}</ng-container
      >
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="047d9158-b77e-4d46-9c3c-3b2bbf14b536"
      aria-label="Product details supply chain information product reorder period">
      <ng-container description>{{ METADATA.reorderPeriod }}</ng-container>
      <ng-container
        *ngIf="
          activeSupplier()?.effectiveReplenishmentPeriod !== null;
          else notAvailable
        "
        value
        >{{
          activeSupplier()?.effectiveReplenishmentPeriod | customDecimal
        }}</ng-container
      >
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="aabd0052-97d8-4e8d-862c-08e0ae28fe08"
      aria-label="Product details supply chain information cycle">
      <ng-container description>{{ METADATA.cycle }}</ng-container>
      <ng-container
        *ngIf="activeSupplier()?.cycle !== null; else notAvailable"
        value
        >{{ activeSupplier()?.cycle | customDecimal }}</ng-container
      >
    </app-supply-chain-section-row>
  </ng-container>
</app-supply-chain-section-container>

<ng-template #notAvailable>
  <span class="not-available">{{ NOT_AVAILABLE }}</span>
</ng-template>
