import {
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  booleanAttribute,
  forwardRef,
  signal,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

import { TagInputType, TAG_INPUT_DEFAULTS } from './model/tag-input.model';
import {
  TagEvent,
  TagVariant,
  TagVariantColor,
} from '../design-system/tags/model/tag.model';
import { InputVariant } from '../design-system/input/model/input.model';
import { CustomAbstractControl } from '../../utils/custom-abstract-control';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsInputComponent),
      multi: true,
    },
  ],
})
export class TagsInputComponent
  extends CustomAbstractControl
  implements AfterViewInit
{
  @ViewChild('tagContainer')
  tagContainerElement: ElementRef<HTMLElement>;

  @ViewChild('tagsWrapper')
  tagsWrapper: ElementRef<HTMLElement>;

  @ViewChild('tagForm')
  tagForm: NgForm;

  /**
   * Disables the input and all tags interactivity.
   */
  @Input({ transform: booleanAttribute })
  disabled: boolean = false;

  /**
   * Determines if the input is required.
   */
  @Input({ transform: booleanAttribute })
  required: boolean = false;

  /**
   * If multiline, tags are wrapped in the input.
   *
   * If not, the input removes the overflow using preview tags (+X tags).
   */
  @Input({ transform: booleanAttribute })
  multiline: boolean = false;

  /**
   * If addOnBlur is ON, it only emits when onBlur.
   *
   * If not, it emits on keyDown.
   */
  @Input({ transform: booleanAttribute })
  addOnBlur: boolean = false;

  /**
   * Determines if we must show the duplicated tag besides the error.
   */
  @Input({ transform: booleanAttribute })
  showDuplicated: boolean = true;

  /**
   * Input keys used as tags separator.
   * Inserting this/these key(s) emits the inserted value as a tag.
   */
  @Input()
  separatorKeys: string[] = [';', 'Enter'];

  /**
   * Determines if tags are rounded or squared.
   */
  @Input({ transform: booleanAttribute })
  tagsRounded: boolean = false;

  /**
   * Receives the tags by the parent component.
   */
  @Input()
  set tags(tags: string[]) {
    this.value = tags ?? [];
  }

  @Input()
  tagsVariant: TagVariant;

  @Input()
  tagsColor: TagVariantColor;

  @Input()
  tagsRemovable: boolean;

  /**
   * Custom duplicated error.
   */
  @Input()
  duplicatedError: string;

  @Input()
  placeholder: string;

  @Input({ transform: booleanAttribute })
  floatErrors: boolean;

  @Input({ required: true })
  variant: InputVariant = 'filled';

  @Output()
  removed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  added: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  focused: EventEmitter<boolean> = new EventEmitter<boolean>();

  previewTags: string[] = [];

  tagInput: string = '';

  duplicatedTag = signal<string | null>(null);

  override value: string[] = [];

  private _scrollOffset: number = 8;

  constructor(@Attribute('type') public inputType: TagInputType) {
    super();

    this.inputType = this.inputType ? this.inputType : TAG_INPUT_DEFAULTS.type;
  }

  ngAfterViewInit(): void {
    this._scrollToInputBottom();
  }

  /**
   * Emits the tags by filtering the removed one.
   * @param tag string | number | Date
   */
  remove(tagEvent: TagEvent) {
    this.removed.emit(tagEvent.value as string);

    const newValue = this.value.filter((tag: string) => tag !== tagEvent.value);

    this.onValueChange(newValue);

    setTimeout(() => this.checkDuplicatedTag(this.tagInput));
  }

  /**
   * Listens every user input to further check for duplicated tags.
   *
   * Needs to be realtime so we listen to the (input).
   */
  onInput(): void {
    this.checkDuplicatedTag(this.tagInput);
    this.focused.emit(true);
  }

  /**
   * Listens on key down to call the emitTag.
   * @param event
   */
  onKeyDown(event: any): void {
    if (this._changesAreValid(event)) {
      this.emitTag();
      this._registerChanges();
    }

    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  /**
   * Listens on blur to call the emitTag if addOnBlur is ON.
   */
  onBlur(): void {
    if (this.addOnBlur) {
      this.checkDuplicatedTag(this.tagInput);

      if (this._changesAreValid()) {
        this.emitTag();
        this._registerChanges();
      }
    }
  }

  onFocus(): void {
    this.focused.emit(true);
  }

  /**
   * Emits tag if the input is valid, not disabled and separatorKey was used.
   * @param event
   */
  private emitTag() {
    this.added.emit(this.tagInput);

    this.clear();

    this._scrollToInputEnd();

    this.focused.emit(true);

    return;
  }

  private checkDuplicatedTag(value: string): void {
    const [duplicated] = this.value.filter(
      (tag: string) => tag.trim() === value.trim()
    );

    if (duplicated) {
      this.duplicatedTag.set(duplicated);
      return;
    }

    this.duplicatedTag.set(null);
  }

  private clear(): void {
    setTimeout(() => {
      this.tagForm.reset();
      this.tagInput = '';
    });
  }

  get empty(): boolean {
    return !this.tags?.length && !this.value?.length;
  }

  get validTagInput(): boolean {
    if (!this.tagForm) return false;

    return this.tagForm.valid && this.tagInput.length > 0;
  }

  get invalidForm(): boolean {
    return !!this.duplicatedTag() || !this.validTagInput;
  }

  get isEmailInput(): boolean {
    return this.inputType === 'email';
  }

  get isNumberInput(): boolean {
    return this.inputType === 'number';
  }

  get isTextInput(): boolean {
    return this.inputType === 'text';
  }

  get baseContainerClasses(): string {
    return `tag-input-container tag-input-container-${this.variant}`;
  }

  /**
   * Checks whether separator key is being used.
   * @param event KeyboardEvent
   * @returns true if separator key was inserted
   */
  private _isSeparatorKey(event: KeyboardEvent): boolean {
    return new Set(this.separatorKeys).has(event?.key);
  }

  /**
   * Scrolls to the bottom of the container until the input is shown.
   */
  private _scrollToInputBottom(): void {
    this.tagContainerElement.nativeElement.scrollTop =
      this.tagContainerElement.nativeElement.scrollHeight;
  }

  /**
   * Scrolls to the end of the container
   */
  private _scrollToInputEnd(): void {
    if (
      this.tagsWrapper.nativeElement.scrollWidth - this._scrollOffset >
      this.tagContainerElement.nativeElement.offsetWidth
    ) {
      setTimeout(() => {
        this.tagContainerElement.nativeElement.scrollTo({
          top: 0,
          left: this.tagContainerElement.nativeElement.scrollWidth,
          behavior: 'smooth',
        });
      });
    }
  }

  private _changesAreValid(event?: KeyboardEvent): boolean {
    return (
      !this.disabled &&
      (!event || this._isSeparatorKey(event)) &&
      !this.invalidForm
    );
  }

  private _registerChanges() {
    const newValue = [...this.value, this.tagInput];

    this.onValueChange(newValue);
  }
}
