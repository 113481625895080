import { Selector } from '@ngxs/store';
import { SearchAutoComplete } from 'src/app/shared/components/search/model/search.model';
import {
  Promotion,
  PromotionUpliftTypes,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import {
  AddProductPromotionsState,
  AddProductPromotionsStateModel,
} from './add-product-promotion.state';

export class AddProductPromotionsStateQueries {
  @Selector([AddProductPromotionsState])
  static saving(state: AddProductPromotionsStateModel): boolean {
    return state.saving;
  }

  @Selector([AddProductPromotionsState.promotions])
  static promotions(promotions: Promotion[]): SearchAutoComplete[] {
    return promotions.map((promotion: Promotion) => ({
      uuid: promotion.uuid,
      name: promotion.name,
    }));
  }

  @Selector([AddProductPromotionsState])
  static haveUnassociatedPromotions(
    state: AddProductPromotionsStateModel
  ): boolean {
    return !!state.promotions.length;
  }

  @Selector([AddProductPromotionsState.promotion])
  static promotion(promotion: Promotion): Promotion {
    return promotion;
  }

  @Selector([AddProductPromotionsState])
  static upliftIncrease(state: AddProductPromotionsStateModel): number | null {
    return state.addPromotionProperties.specificUpliftIncrease;
  }

  @Selector([AddProductPromotionsState])
  static upliftType(state: AddProductPromotionsStateModel): string | null {
    return state.addPromotionProperties.specificUpliftType;
  }

  @Selector([AddProductPromotionsState])
  static promotionEnabled(state: AddProductPromotionsStateModel): boolean {
    return state.addPromotionProperties.promotion?.settings?.enabled || false;
  }

  @Selector([AddProductPromotionsState])
  static canEditUpliftIncrease(state: AddProductPromotionsStateModel): boolean {
    return [
      PromotionUpliftTypes.RELATIVE,
      PromotionUpliftTypes.ABSOLUTE,
    ].includes(
      state.addPromotionProperties.specificUpliftType as PromotionUpliftTypes
    );
  }
}
