import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import {
  CreatePromotionWebshopProductsProperties,
  DeletePromotionProdcuctsProperties,
  DeletePromotionsProperties,
  ExportProductPromotionsBody,
  ExportPromotionsBody,
  Promotion,
  UpdatePromotionProperties,
  UpdateWebshopProductInPromotionsProperties,
} from 'src/app/shared/models/promotion/promotion.model';

import { PromotionAdapter } from './adapters/promotion.adapter';
import { PromotionV1 } from './model/promotions.model';

export interface PageableData {
  page: number;
  size: number;
  filter: string;
  sortBy: string;
  order: string;
}

export interface ProductPromotionsRequestInfo {
  webshopUUID: string;
  webshopProductUUID: string;
  previousPage: number;
  page: number;
  limit: number;
  sortBy: string;
  order: string;
  filters: string;
}

export interface PromotionsPayload {
  webshopUUID: string;
}

export interface PromotionsPaginatedPayload {
  webshopUUID: string;
  page: number;
  size: number;
  filter: string;
  sortBy: string;
  order: string;
}

@Injectable({ providedIn: 'root' })
export class PromotionsService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findOnePromotion(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v2',
      'findOne',
      replacements
    );
    return this.http.get<PromotionV1>(endpoint);
  }

  private _deletePromotion(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'delete',
      replacements
    );
    return this.http.delete(endpoint);
  }

  private _deletePromotions(
    payload: DeletePromotionsProperties
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'deletePromotions',
      []
    );
    return this.http.post(endpoint, payload);
  }

  private _updatePromotion(
    payload: UpdatePromotionProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'update',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _updateWebshopProductInPromotions(
    payload: UpdateWebshopProductInPromotionsProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'updateWebshopProducts',
      replacements
    );
    return this.http.post(endpoint, payload);
  }

  private _createPromotionWebshopProducts(
    payload: CreatePromotionWebshopProductsProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'createPromotionWebshopProducts',
      replacements
    );
    return this.http.post(endpoint, payload);
  }

  private _exportPromotions(
    payload: ExportPromotionsBody,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotions',
      'v1',
      'exportPromotions',
      replacements
    );
    return this.http.post(endpoint, payload, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  private _exportProductPromotions(
    payload: ExportProductPromotionsBody,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotions',
      'v1',
      'exportProductPromotions',
      replacements
    );
    return this.http.post(endpoint, payload, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  private _updatePromotionWebshopProducts(
    payload: CreatePromotionWebshopProductsProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'updatePromotionWebshopProducts',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _deletePromotionWebshopProduct(
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'deletePromotionWebshopProduct',
      replacements
    );
    return this.http.delete(endpoint);
  }

  private _deletePromotionSelectedWebshopProduct(
    replacements: UrlReplacement[],
    payload: DeletePromotionProdcuctsProperties
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'promotionsGRPC',
      'v1',
      'updateWebshopProducts',
      replacements
    );
    return this.http.post(endpoint, payload);
  }

  findOnePromotion(promotionUUID: string): Observable<Promotion> {
    const replacements: UrlReplacement[] = [
      { name: 'uuid', value: promotionUUID },
    ];

    return this._findOnePromotion(replacements).pipe(
      switchMap(data => of(new PromotionAdapter().transform(data)))
    );
  }

  deletePromotion(promotionUUID: string) {
    const replacements: UrlReplacement[] = [
      { name: 'uuid', value: promotionUUID },
    ];

    return this._deletePromotion(replacements);
  }

  deletePromotions(properties: DeletePromotionsProperties) {
    return this._deletePromotions(properties);
  }

  updatePromotion(
    properties: UpdatePromotionProperties,
    promotionUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'uuid', value: promotionUUID },
    ];

    return this._updatePromotion(properties, replacements);
  }

  updateWebshopProductInPromotions(
    properties: UpdateWebshopProductInPromotionsProperties,
    promotionUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'uuid', value: promotionUUID },
    ];

    return this._updateWebshopProductInPromotions(properties, replacements);
  }

  createPromotionWebshopProducts(
    properties: CreatePromotionWebshopProductsProperties,
    webshopUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._createPromotionWebshopProducts(properties, replacements);
  }

  exportPromotions(properties: ExportPromotionsBody, webshopUUID: string) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._exportPromotions(properties, replacements);
  }

  exportProductPromotions(
    properties: ExportProductPromotionsBody,
    webshopUUID: string,
    webshopProductUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'webshopProductUUID', value: webshopProductUUID },
    ];

    return this._exportProductPromotions(properties, replacements);
  }

  updatePromotionWebshopProducts(
    properties: CreatePromotionWebshopProductsProperties,
    webshopUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._updatePromotionWebshopProducts(properties, replacements);
  }

  deletePromotionWebshopProduct(
    webshopUUID: string,
    promotionUUID: string,
    webshopProductUUID: string
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'promotionUUID', value: promotionUUID },
      { name: 'webshopProductUUID', value: webshopProductUUID },
    ];

    return this._deletePromotionWebshopProduct(replacements);
  }

  deletePromotionSelectedWebshopProduct(
    promotionUUID: string,
    webshopProductUUIDs: DeletePromotionProdcuctsProperties
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'uuid', value: promotionUUID },
    ];

    return this._deletePromotionSelectedWebshopProduct(
      replacements,
      webshopProductUUIDs
    );
  }
}
