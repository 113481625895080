<app-dialog-container>
  <div class="promotions-dialog">
    <div appDialogHeader>
      <h1 appDialogTitle i18n="add products to promotion dialog title">
        Add product(s) to promotion
      </h1>
      <button
        aria-label="Add product to promotion close button"
        class="close-button"
        (click)="close()">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>

    <div appDialogBody>
      <form [formGroup]="addPromotionForm">
        <h3
          class="promotions-container-title"
          i18n="add promotions dialog promotion title">
          Select a promotion
        </h3>

        <app-dropdown
          data-intercom-target="3e08e880-4224-4e23-825f-a2b7e1c7bfe5"
          aria-label="Product add products to promotion search promotion"
          name="promotions"
          formControlName="promotion"
          [displayWith]="displayPromotionName">
          <app-dropdown-search
            (searched)="filterPromotions($event)"></app-dropdown-search>

          <app-dropdown-option
            *ngFor="let promotion of filteredPromotions$ | async"
            [value]="promotion"
            >{{ promotion.name }}</app-dropdown-option
          >
        </app-dropdown>

        <h3
          class="promotions-container-title"
          i18n="add promotions dialog product details title">
          Promotion Details
        </h3>
        <div class="container-content-col input-field container-padding">
          <label i18n="add promotions dialog product details name label"
            >Promotion Name</label
          >
          <p>
            {{
              selectedPromotion && selectedPromotion?.name
                ? selectedPromotion?.name
                : '–'
            }}
          </p>
        </div>

        <div class="container-content-row">
          <div class="container-content-col flex-50 input-field">
            <label i18n="add promotions dialog start date label"
              >Start Date</label
            >
            <p>
              {{
                selectedPromotion && selectedPromotion?.interval?.start
                  ? selectedPromotion?.interval?.start
                  : '–'
              }}
            </p>
          </div>
          <div class="container-content-col flex-50 input-field">
            <label i18n="add promotions dialog end date label">End Date</label>
            <p>
              {{
                selectedPromotion && selectedPromotion?.interval?.end
                  ? selectedPromotion?.interval?.end
                  : '–'
              }}
            </p>
          </div>
        </div>

        <h3
          class="promotions-container-title"
          i18n="add promotions dialog product details uplift title">
          Uplift
        </h3>
        <div class="container-content-row">
          <div class="container-content-col flex-50 input-field">
            <label i18n="add promotions dialog promotion uplift type label"
              >Uplift Type</label
            >
            <p>
              {{
                selectedPromotion && selectedPromotion?.uplift?.type
                  ? selectedPromotion?.uplift?.type
                  : '–'
              }}
            </p>
          </div>
          <div class="container-content-col flex-50 input-field">
            <label i18n="add promotions dialog promotion expected uplift label"
              >Expected Uplift</label
            >
            <p>
              {{
                selectedPromotion && selectedPromotion?.uplift?.increase
                  ? selectedPromotion?.uplift?.increase
                  : '–'
              }}
              <span
                *ngIf="
                  selectedPromotion &&
                  selectedPromotion?.uplift?.type === 'Relative'
                "
                >%</span
              >
            </p>
          </div>
        </div>

        <h3
          class="promotions-container-title"
          i18n="add promotions dialog settings title">
          Settings
        </h3>
        <div class="container-content-row">
          <div class="container-content-col input-field enabled">
            <app-toggle
              i18n-label="add promotions dialog enabled label"
              label="Enabled"
              [checked]="selectedPromotion?.settings?.enabled"
              [disabled]="true">
            </app-toggle>
          </div>
        </div>
      </form>
    </div>

    <div appDialogActions>
      <div class="footer-actions">
        <button
          aria-label="Add product to promotion cancel button"
          appButton
          variant="tertiary"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          aria-label="Add product to promotion add button"
          appButton
          variant="primary"
          [disabled]="
            (saveAddPromotionDisabled$ | async) === true ||
            (savingAddPromotion$ | async) === true
          "
          (click)="addProducts()">
          {{ COMMON_STRINGS.save }}
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
