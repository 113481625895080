import { Selector } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  ProductDetailsV2State,
  ProductDetailsV2StateModel,
} from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.state';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  LinkSupplierProductData,
  SupplierProductLink,
} from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import { SupplierManualOrder } from 'src/app/shared/models/suppliers/supplier.model';
import {
  WebshopProductSuppliersState,
  WebshopProductSuppliersStateModel,
} from './product-suppliers-v2.state';
import {
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  buildDisplayedColumns,
  buildSelectableColumnsList,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { COLUMNS_ORDER } from '../model/data-table.model';

export class WebshopProductSuppliersStateQueries {
  @Selector([WebshopProductSuppliersState])
  static loading(state: WebshopProductSuppliersStateModel): boolean {
    return state.loading;
  }

  @Selector([WebshopProductSuppliersState])
  static links(
    state: WebshopProductSuppliersStateModel
  ): SupplierProductLink[] {
    return state.links;
  }

  @Selector([WebshopProductSuppliersState])
  static haveLinkedSupplierProducts(
    state: WebshopProductSuppliersStateModel
  ): boolean {
    return !!state.links.length;
  }

  @Selector([WebshopProductSuppliersState])
  static totalElements(state: WebshopProductSuppliersStateModel): number {
    return state.page.totalElements;
  }

  @Selector([WebshopProductSuppliersState])
  static featuredColumns(
    state: WebshopProductSuppliersStateModel
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    const editing = state.editing;
    const editableColumns = state.editableColumns;

    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        const groupColumns = Object.values(group.columns).map(column => {
          if (editing && editableColumns.includes(column.name)) {
            return {
              ...column,
              checked: true,
              disabled: true,
            };
          }

          return column;
        });

        return {
          ...group,
          columns: groupColumns,
        };
      }
    );
  }

  @Selector([WebshopProductSuppliersStateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([WebshopProductSuppliersStateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([WebshopProductSuppliersStateQueries.displayedColumns])
  static lastDisplayedColumn(displayedColumns: string[]) {
    return (
      displayedColumns.filter(
        (column: string) =>
          column !== STRINGS.columns.productSuppliers.ACTIONS.key
      ).length === 1
    );
  }

  @Selector([WebshopProductSuppliersState])
  static promotionsPage(state: WebshopProductSuppliersStateModel): Pageable {
    return state.page;
  }

  @Selector([WebshopProductSuppliersState])
  static pageSizeOptions(state: WebshopProductSuppliersStateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([WebshopProductSuppliersState])
  static currentPage(state: WebshopProductSuppliersStateModel): number {
    return state.pagination.page;
  }

  @Selector([WebshopProductSuppliersState])
  static pageSize(state: WebshopProductSuppliersStateModel): number {
    return state.pagination.size;
  }

  @Selector([WebshopProductSuppliersState, WebshopState.webshopCurrencySymbol])
  static filters(
    state: WebshopProductSuppliersStateModel,
    currencySymbol: string
  ): ColumnFilterGroup[] {
    return Object.entries(state.filtersGroups).map(([groupKey, group]) => {
      return {
        groupKey,
        groupName: group.groupName,
        filters: Object.entries(group.columns)
          .map(([key, filter]) => {
            if (STRINGS.columns.productSuppliers[filter.key]?.currency) {
              return {
                columnKey: key,
                ...filter,
                unit: currencySymbol,
              };
            }

            return {
              columnKey: key,
              ...filter,
            };
          })
          .filter((filter: Filter) => !filter.disabled),
      };
    });
  }

  @Selector([WebshopProductSuppliersState, WebshopState.webshopCurrencySymbol])
  static activeFilters(
    state: WebshopProductSuppliersStateModel,
    currencySymbol: string
  ): ColumnFilter[] {
    const filters = Object.values(state.filtersGroups).map(
      group => group.columns
    );

    return filters
      .map(filter => {
        return (
          Object.entries(filter)
            .map(([columnKey, filter]) => {
              if (STRINGS.columns.productSuppliers[filter.key]?.currency) {
                return {
                  columnKey,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey,
                ...filter,
              };
            })
            .filter((filter: Filter) => filter.params.value) || []
        );
      })
      .flat();
  }

  @Selector([WebshopProductSuppliersState])
  static search(state: WebshopProductSuppliersStateModel): string[] {
    return state.search || [];
  }

  @Selector([WebshopProductSuppliersState])
  static filterOpened(state: WebshopProductSuppliersStateModel): boolean {
    return state.filterOpened;
  }

  @Selector([WebshopProductSuppliersState])
  static editing(state: WebshopProductSuppliersStateModel): boolean {
    return state.editing;
  }

  @Selector([
    WebshopProductSuppliersState,
    WebshopProductSuppliersStateQueries.displayedColumns,
  ])
  static exportMetadata(
    state: WebshopProductSuppliersStateModel,
    displayedColumns: string[]
  ): any {
    const visibleColumns = displayedColumns.filter(
      column => column !== STRINGS.columns.productSuppliers.ACTIONS.key
    );

    const [sort] = Object.values(state.sortBy);

    return {
      visibleColumns,
      sortBy: sort.field.toLocaleLowerCase(),
      order: sort.order.toLocaleLowerCase(),
      filter: '',
      source: 'exportSupplierProducts',
      include: [],
    };
  }

  @Selector([ProductDetailsV2State])
  static linkSupplierProductData(
    productDetailsState: ProductDetailsV2StateModel
  ): LinkSupplierProductData {
    if (productDetailsState.overview?.product?.id) {
      return {
        productDetails: {
          id: Number(productDetailsState.overview.product.id),
          name: productDetailsState.overview.product.name,
          stockOnHand: productDetailsState.overview.product.stockOnHand,
        },
      };
    }

    return {
      productDetails: null,
    };
  }

  @Selector([WebshopProductSuppliersState])
  static availableSuppliers(
    state: WebshopProductSuppliersStateModel
  ): SupplierManualOrder[] {
    return state.availableSuppliers;
  }
}
