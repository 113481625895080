import { ServiceRequestInfoV3Operator } from '../../../../../model/pageable-v2.model';
import {
  FilterNullableGroupV2,
  FilterStrategy,
} from '../../../model/filter-v2.model';

export class FilterNullableStrategy
  implements FilterStrategy<FilterNullableGroupV2>
{
  form: FilterNullableGroupV2 = {
    to: null,
  };

  populate(value: ServiceRequestInfoV3Operator): void {
    this.form.to = value;
  }

  hasValue(): boolean {
    return this.form.to !== null;
  }

  hasSameValue(value: ServiceRequestInfoV3Operator): boolean {
    return value === this.form.to;
  }

  clear(): void {
    this.form = {
      to: null,
    };
  }
}
