<div
  class="container"
  [class.unfocused]="disabled() || !hasEmailsAddressesList()">
  <label for="emails" class="emails-label"
    >{{ COMMON_STRINGS.emailAddresses }}<span appRequiredLabel>*</span></label
  >
  <app-dropdown
    aria-label="Email management email selection dropdown"
    data-intercom-target="Email management email selection dropdown"
    id="emails"
    [(value)]="emailsAdresses"
    type="outline"
    multiple
    forceOptionsReset>
    @if (emailsAdresses().length > 2) {
      <app-dropdown-trigger class="custom-select-trigger">
        <app-tag [value]="emailsAdresses()[0]" color="blue">{{
          emailsAdresses()[0]
        }}</app-tag>

        <app-tag [value]="emailsAdresses()[1]" color="blue">{{
          emailsAdresses()[1]
        }}</app-tag>

        <app-tag color="default" [appTooltip]="remainEmailsAddresses()">{{
          remainEmailsAddressesCount()
        }}</app-tag>
      </app-dropdown-trigger>
    }

    <div class="dropdown-header-container">
      <div class="dropdown-header">
        <p class="dropdown-options-title">
          {{ STRINGS.emailsDropdown.title }}
        </p>

        @if (emailsAddressesList(); as emailsAddressesList) {
          <div class="dropdown-options-actions">
            <button
              data-intercom-target="Email management email selection select all button"
              appButton
              variant="tertiary"
              size="sm"
              aria-label="Purchase confirmation select all button"
              [disabled]="
                emailsAdresses().length === emailsAddressesList.length
              "
              (click)="emailsAdresses.set(emailsAddressesList)">
              {{ STRINGS.emailsDropdown.selectAll }}
            </button>

            <button
              data-intercom-target="Email management email selection reset button"
              appButton
              size="sm"
              variant="tertiary"
              aria-label="Purchase confirmation reset button"
              [disabled]="!emailsAdresses().length"
              (click)="emailsAdresses.set([])">
              {{ STRINGS.emailsDropdown.reset }}
            </button>
          </div>
        }
      </div>

      <app-dropdown-search #dropdownSearch="dropdownSearch" />
    </div>

    @for (email of emailsAddressesList(); track email) {
      <app-dropdown-option
        [value]="email"
        appDropdownSearchableOption
        [searched]="dropdownSearch.searchedBy()"
        >{{ email }}</app-dropdown-option
      >
    }
  </app-dropdown>
</div>
