export class EnlargeOrderSize {
  static readonly type = '[Order Enlarger] Enlarge Order Size';

  constructor(public factor: number) {}
}

export class CheckFactor {
  static readonly type = '[Order Enlarger] Check Factor';

  constructor(public factor: number) {}
}
