<button
  appButton
  appButtonIcon
  variant="link"
  transparent
  aria-label="Column view"
  [appTooltip]="COLUMN_VIEW_STRINGS.metadata.columnView"
  appOverlay
  alignment="end"
  minWidth="300">
  <mat-icon svgIcon="table"></mat-icon>

  <ng-template cdk-portal>
    <app-overlay-container-v2>
      <div class="container">
        <div>
          <div class="container-title">
            {{ COLUMN_VIEW_STRINGS.metadata.columnView }}
          </div>

          <div class="container-description">
            {{ COLUMN_VIEW_STRINGS.metadata.description }}
          </div>

          <div class="checkbox-all-container">
            <app-checkbox
              [checked]="allChecked()"
              [indeterminate]="someChecked()"
              (checkedChange)="checkAll()"
              [label]="
                checkedColumns() +
                '/' +
                columnsListArray().length +
                ' ' +
                COMMON_STRINGS.selected
              "></app-checkbox>

            @if (noneChecked()) {
              <p class="checkbox-warning">
                {{ COLUMN_VIEW_STRINGS.metadata.noneSelected }}
              </p>
            }
          </div>
        </div>

        <div class="content">
          @for (column of columnsListArray(); track column.name) {
            <div
              class="column-container"
              [appTooltip]="COLUMN_VIEW_STRINGS.metadata.requiredColumns"
              [tooltipDisabled]="!column.disabled">
              <app-checkbox
                [disabled]="column.disabled || false"
                [label]="column.displayName"
                [checked]="column.checked"
                (checkedChange)="
                  toggleSelection(column.name, $event)
                "></app-checkbox>
            </div>
          }
        </div>

        @if (showApplyAll) {
          <div class="apply-all-container">
            <app-checkbox
              [checked]="applyAll"
              (checkedChange)="applyAll = $event"
              [label]="COMMON_STRINGS.filter.applyAllTables"></app-checkbox>
          </div>
        }

        <div class="card-options">
          <button
            aria-label="Column view cancel button"
            appButton
            variant="tertiary"
            (click)="cancel()">
            {{ COMMON_STRINGS.cancel }}
          </button>

          <button
            aria-label="Column view apply button"
            appButton
            variant="primary"
            [disabled]="noneChecked()"
            (click)="apply()">
            {{ COMMON_STRINGS.apply }}
          </button>
        </div>
      </div>
    </app-overlay-container-v2>
  </ng-template>
</button>
