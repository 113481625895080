<div class="container">
  <span class="header" [class.disabled]="disabled">
    <ng-container
      *ngIf="!!customHeader; else defaultTemplate"
      [ngTemplateOutlet]="customHeader">
    </ng-container>
  </span>

  <div
    class="content"
    [style.max-width.px]="maxWidth"
    [class.disabled]="disabled">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #defaultTemplate>
  {{ headerTitle }}
</ng-template>
