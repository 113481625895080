import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import { ConnectProductToSupplierState } from 'src/app/shared/components/connect-product-to-supplier-dialog/state/connect-product-to-supplier.state';
import { DisconnectedProduct } from 'src/app/shared/models/suppliers/disconnected-products.model';
import {
  AvailabilityChange,
  EanChange,
  LoadDisconnectedProducts,
  LotSizeChange,
  MOQChange,
  NameChange,
  PreferredChange,
  PriceChange,
  ResetConnectProduct,
  SaveSupplierProduct,
  SelectDisconnectedProduct,
  SkuChange,
} from './actions/connect-product-to-supplier-dialog.actions';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ConnectProductToSupplierDialogData } from './model/connect-product-to-supplier-dialog.model';

@Component({
  selector: 'app-connect-product-to-supplier-dialog',
  templateUrl: './connect-product-to-supplier-dialog.component.html',
  styleUrls: ['./connect-product-to-supplier-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectProductToSupplierDialogComponent implements OnDestroy {
  readonly PRODUCT_STRINGS = STRINGS.metadata.product;

  @Select(AppState.isLoading)
  loading$: Observable<boolean>;

  @Select(AppState.isSaving)
  saving$: Observable<boolean>;

  @Select(ConnectProductToSupplierState.products)
  products$: Observable<DisconnectedProduct[]>;

  @Select(ConnectProductToSupplierState.isProductSelected)
  isProductSelected$: Observable<boolean>;

  @Select(ConnectProductToSupplierState.productName)
  name$: Observable<string>;

  @Select(ConnectProductToSupplierState.sku)
  sku$: Observable<string>;

  @Select(ConnectProductToSupplierState.eanCode)
  eanCode$: Observable<string>;

  @Select(ConnectProductToSupplierState.price)
  price$: Observable<string>;

  @Select(ConnectProductToSupplierState.moq)
  moq$: Observable<string>;

  @Select(ConnectProductToSupplierState.lotSize)
  lotSize$: Observable<string>;

  @Select(ConnectProductToSupplierState.availability)
  availability$: Observable<boolean>;

  @Select(ConnectProductToSupplierState.preferred)
  preferred$: Observable<boolean>;

  @Select(WebshopState.webshopCurrencySymbol)
  currencySymbol$: Observable<string>;

  selectedProduct: DisconnectedProduct | null = null;

  dialogTitle = this.data.dialogTitle;

  addAnother: boolean = false;

  readonly COMMON_STRINGS = MESSAGES.common;

  constructor(
    @Inject(DIALOG_DATA)
    public data: ConnectProductToSupplierDialogData,
    private store: Store,
    private dialogRef: DialogRef
  ) {}

  save(): void {
    this.store.dispatch(
      new SaveSupplierProduct(this.data.supplierId, !this.addAnother)
    );

    if (this.addAnother) {
      this.selectedProduct = null;
    }
  }

  onProductSelected(product: DisconnectedProduct) {
    this.store.dispatch(new SelectDisconnectedProduct(String(product.id)));
  }

  onSkuChanged(value: any) {
    this.store.dispatch(new SkuChange(value));
  }

  onNameChanged(value: any) {
    this.store.dispatch(new NameChange(value));
  }

  onEanChanged(value: any) {
    this.store.dispatch(new EanChange(value));
  }

  onPriceChanged(value: any) {
    this.store.dispatch(new PriceChange(value));
  }

  onLotSizeChanged(value: any) {
    this.store.dispatch(new LotSizeChange(value));
  }

  onMoQChanged(value: any) {
    this.store.dispatch(new MOQChange(value));
  }

  onPreferredChanged(value: any) {
    this.store.dispatch(new PreferredChange(value));
  }

  onAvailabilityChanged(value: any) {
    this.store.dispatch(new AvailabilityChange(value));
  }

  filterDisconnectedProducts(param: string): void {
    this.store.dispatch(
      new LoadDisconnectedProducts(param, this.data.supplierUuid)
    );
  }

  close() {
    this.dialogRef.close();
  }

  displayWithName(product: DisconnectedProduct) {
    return product.name;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetConnectProduct());
  }
}
