<button
  appButton
  appButtonIcon
  variant="link"
  transparent
  aria-label="Column view"
  [appTooltip]="COLUMN_VIEW_STRINGS.columnView"
  appOverlay
  alignment="end"
  minWidth="400"
  maxWidth="400"
  maxHeight="400">
  <mat-icon svgIcon="table"></mat-icon>

  <ng-template cdk-portal>
    <app-overlay-container-v2 class="column-view-v3">
      <div class="column-view-v3-container-header">
        <div class="column-view-v3-container-title">
          <span>{{ COLUMN_VIEW_STRINGS.columnView }}</span>

          <app-checkbox
            [checked]="allChecked()"
            [indeterminate]="someChecked()"
            [label]="
              checkedColumnsLength() +
              '/' +
              totalColumnsLength() +
              ' ' +
              COMMON_STRINGS.selected
            "
            (checkedChange)="masterToggle()"></app-checkbox>
        </div>

        <div class="column-view-v3-container-description">
          {{ COLUMN_VIEW_STRINGS.description }}
        </div>

        @if (noneChecked()) {
          <div class="column-view-v3-checkbox-all-container">
            <p class="column-view-v3-checkbox-warning">
              {{ COLUMN_VIEW_STRINGS.noneSelected }}
            </p>
          </div>
        }
      </div>

      <app-dropdown-search (searched)="filterRef.set($event)" />

      <div class="column-view-v3-content">
        <app-column-view-v3-group>
          <app-column-view-v3-template
            [label]="
              COMMON_STRINGS.selectedTitle +
              ' (' +
              checkedColumnsFiltered().length +
              ')'
            ">
            @for (column of checkedColumnsFiltered(); track column.name) {
              <app-column-view-v3-option-selected
                [column]="column"
                (removed)="toggleColumn($event)" />
            } @empty {
              <div class="column-view-v3-content-empty">
                {{ COLUMN_VIEW_STRINGS.noneSelected }}
              </div>
            }
          </app-column-view-v3-template>

          @for (group of columnsGroupArrayFiltered(); track group.groupKey) {
            <app-column-view-v3-template
              [label]="
                filterRef()
                  ? group.groupName + ' (' + group.columns.length + ')'
                  : group.groupName
              ">
              @for (option of group.columns; track option.name) {
                <app-column-view-v3-option
                  [column]="option"
                  (toggled)="toggleColumn($event)" />
              } @empty {
                <div class="column-view-v3-content-empty">
                  {{ COLUMN_VIEW_STRINGS.noColumnsFound }}
                </div>
              }
            </app-column-view-v3-template>
          }
        </app-column-view-v3-group>
      </div>

      @if (showApplyAll()) {
        <div class="column-view-v3-apply-all-container">
          <app-checkbox
            [checked]="applyAll()"
            (checkedChange)="applyAll.set($event)"
            [label]="COMMON_STRINGS.filter.applyAllTables"></app-checkbox>
        </div>
      }

      <div class="column-view-v3-footer">
        <button
          aria-label="Column view cancel button"
          appButton
          variant="tertiary"
          (click)="cancel()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          aria-label="Column view apply button"
          appButton
          variant="primary"
          [disabled]="noneChecked()"
          (click)="apply()">
          {{ COMMON_STRINGS.apply }}
        </button>
      </div>
    </app-overlay-container-v2>
  </ng-template>
</button>
