import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RadioButtonComponent } from './radio-button.component';
import { RadioButtonGroupDirective } from './directives/radio-button-group.directive';

@NgModule({
  declarations: [RadioButtonComponent, RadioButtonGroupDirective],
  imports: [CommonModule, FormsModule],
  exports: [RadioButtonComponent, RadioButtonGroupDirective],
})
export class RadioButtonModule {}
