import { Injectable } from '@angular/core';
import { createSelector, State, StateContext } from '@ngxs/store';
import { Filter, FilterTypes } from '../components/filter/model/filter.model';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  DatatableSort,
} from '../model/data-table-v2.model';
import { Sorted } from '../components/sort/model/sort.model';
import {
  ServiceRequestInfoV3,
  ServiceRequestInfoV3Filter,
  ServiceRequestInfoV3FilteringRangedTypes,
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3FilteringTypes,
  ServiceRequestInfoV3Filters,
  ServiceRequestInfoV3Query,
  ServiceRequestInfoV3QueryingTypes,
  ServiceRequestInfoV3SortBy,
} from '../model/pageable-v2.model';
import { groupBy } from 'src/app/shared/utils/group-by';
import { formatDateToIsoAndRemoveUnnecessaryInfo } from 'src/app/shared/utils/dates.utils';
import { FilterTypeOptionsV2 } from '../components/filter/model/filter-v2.model';
import { delay, of, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { DEFAULT_TIME_LIMIT_TO_REFRESH_TABLE_MS } from 'src/app/core/constants/global.constants';

export interface DatatableStateModel {
  pagination: DatatablePagination;
  search: string[] | null;
  sortBy: DatatableSort;
  filterOpened: boolean;
  columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>;
  filtersGroups: DatatableColumnV2Groups<Filter>;
  canRefresh?: boolean;
}

@State<DatatableStateModel>({
  name: 'datatableState',
  defaults: {
    pagination: {
      page: 0,
      pageSizeOptions: [],
      size: 50,
    },
    sortBy: {},
    search: null,
    filterOpened: false,
    columnsGroups: {},
    filtersGroups: {},
    canRefresh: true,
  },
})
@Injectable()
export class DatatableState {
  protected currentFilters: ServiceRequestInfoV3;

  private requestFilters: Map<string, any> = new Map(
    Object.entries({
      any_of: [],
      match: [],
      no_match: [],
    })
  );

  static canRefresh() {
    return createSelector([this], (state: DatatableStateModel) => {
      return state.canRefresh;
    });
  }

  disableRefresh<T extends DatatableStateModel>(ctx: StateContext<T>) {
    return of(null).pipe(
      tap(() => {
        ctx.patchState({
          canRefresh: false,
        } as Partial<T>);
      }),
      delay(DEFAULT_TIME_LIMIT_TO_REFRESH_TABLE_MS),
      tap(() => {
        ctx.patchState({
          canRefresh: true,
        } as Partial<T>);
      })
    );
  }

  sort(_ctx: StateContext<any>, _sort: Sorted): void {}

  addParam(
    _ctx: StateContext<any>,
    _filterKey: string,
    _param: DatatableParam
  ): void {}

  addGroupParam(
    _ctx: StateContext<any>,
    _groupKey: string,
    _filterKey: string,
    _param: DatatableParam
  ): void {}

  buildQuery(searchBy: string[]): ServiceRequestInfoV3Query {
    if (searchBy.length > 1) {
      return this.buildQueryMultipleKeyword(searchBy);
    }

    return this.buildQuerySingleKeyword(searchBy[0]);
  }

  buildQuerySingleKeyword(keyword: string): ServiceRequestInfoV3Query {
    return {
      '@type': ServiceRequestInfoV3QueryingTypes.STRING,
      value: keyword,
    };
  }

  buildQueryMultipleKeyword(keywords: string[]): ServiceRequestInfoV3Query {
    return {
      '@type': ServiceRequestInfoV3QueryingTypes.LIST,
      value: keywords,
    };
  }

  buildFiltersV2(
    filters: Filter[],
    cleanup: boolean = true
  ): ServiceRequestInfoV3Filters {
    if (cleanup) {
      this.requestFilters = new Map(
        Object.entries({
          any_of: [],
          match: [],
          no_match: [],
        })
      );
    }

    const toFilter = filters.filter(
      (filter: Filter) => filter.params.value !== null
    );

    const filtersGroupedBySubOperator: Map<string, any> = groupBy(
      toFilter,
      (filter: Filter) => filter.params.subOperator
    );

    filtersGroupedBySubOperator.forEach((filters, subOperator) => {
      const filtersGroupedByOperator: Map<string, any> = groupBy(
        filters,
        (filter: Filter) => {
          if (filter.params.operator === FilterTypeOptionsV2.RANGED) {
            return ServiceRequestInfoV3FilteringType.RANGED;
          } else if (filter.params.operator === FilterTypeOptionsV2.IS_NULL) {
            return ServiceRequestInfoV3FilteringType.IS_NULL;
          } else {
            return filter.params.valueType;
          }
        }
      );

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.STRING)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildStringFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.STRING
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.INTEGER)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildIntFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.INTEGER
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.FLOAT)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildFloatFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.FLOAT
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.DATETIME)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildDateTimeFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.DATETIME
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.BOOLEAN)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildBooleanFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.BOOLEAN
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.RANGED)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildRangedFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.RANGED
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.TIME)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildTimeFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.TIME
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(
          ServiceRequestInfoV3FilteringType.NOT_EXISTS
        )
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildNotExistsFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.NOT_EXISTS
              )
            )
          );
      }

      if (
        filtersGroupedByOperator.has(ServiceRequestInfoV3FilteringType.IS_NULL)
      ) {
        this.requestFilters
          .get(subOperator)
          .push(
            ...this._buildNullFilters(
              filtersGroupedByOperator.get(
                ServiceRequestInfoV3FilteringType.IS_NULL
              )
            )
          );
      }
    });

    return Object.fromEntries(
      this.requestFilters.entries()
    ) as ServiceRequestInfoV3Filters;
  }

  buildSortBy(sorts: DatatableSort): ServiceRequestInfoV3SortBy[] {
    return [...Object.values(sorts)];
  }

  /**
   * Builds sorted fields (nested)
   * @param fields DatatableSort
   * @param sort Sorted
   * @returns sorted fields DatatableSort
   */
  buildSortedFields(fields: DatatableSort, sort: Sorted): DatatableSort {
    let sortedFields = { ...fields };

    if (sort.order === null && sortedFields[sort.key] !== undefined) {
      delete sortedFields[sort.key];
    } else if (sortedFields[sort.key] !== undefined) {
      sortedFields[sort.key] = {
        field: sort.key,
        order: sort.order,
      };
    } else {
      sortedFields = {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
        ...sortedFields,
      };
    }

    return sortedFields;
  }

  protected initializeColumnsFromPersistence(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>,
    excludedColumns: string[]
  ): DatatableColumnV2Groups<DatatableColumnV2> {
    const columns = JSON.parse(JSON.stringify(columnsGroups));

    for (const groupKey in columns) {
      const group = columns[groupKey];

      excludedColumns.forEach(column => {
        if (group.columns.hasOwnProperty(column)) {
          group.columns[column].checked = false;
        }
      });
    }

    return columns;
  }

  protected hideGroupColumnFromColumnKey(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>,
    groupKey: string,
    columnKey: string
  ): DatatableColumnV2Groups<DatatableColumnV2> {
    const newColumnSelection = JSON.parse(JSON.stringify(columnsGroups));

    newColumnSelection[groupKey] = {
      ...newColumnSelection[groupKey],
      columns: {
        ...newColumnSelection[groupKey].columns,
        [columnKey]: {
          ...newColumnSelection[groupKey].columns[columnKey],
          checked: false,
        },
      },
    };

    return newColumnSelection;
  }

  protected buildExcludedColumns(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): string[] {
    return Object.values(columnsGroups)
      .map(group => {
        return Object.values(group.columns).filter(column => {
          return column.selectable && !column.checked;
        });
      })
      .flat()
      .map(column => column.name);
  }

  protected buildRequestInfoFilters(
    state: DatatableStateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  /**
   * Builds string filters object to the request
   * @param filters Filter[]
   * @returns string filters
   */
  private _buildStringFilters(filters: Filter[]): ServiceRequestInfoV3Filter[] {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      let values = [];

      switch (currentFilter.uiModel) {
        case FilterTypes.FILTER_LIST:
          values = currentFilter.params.value.map(f => f.value);

          break;
        default:
          values = [currentFilter.params.value.to];

          break;
      }

      filtered.push({
        '@type': ServiceRequestInfoV3FilteringTypes.STRING,
        values: {
          [currentFilter.key]: {
            values: [...values],
          },
        },
        partial_match: currentFilter.partialMatch || false,
      });
    }

    return filtered;
  }

  /**
   * Builds string filters object to the request
   * @param filters Filter[]
   * @returns string filters
   */
  private _buildTimeFilters(filters: Filter[]): ServiceRequestInfoV3Filter[] {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      let values = [];

      switch (currentFilter.uiModel) {
        case FilterTypes.FILTER_LIST:
          values = currentFilter.params.value.map(f => f.value);

          break;
        default:
          values = [currentFilter.params.value.to];

          break;
      }

      filtered.push({
        '@type': ServiceRequestInfoV3FilteringTypes.TIME,
        values: {
          [currentFilter.key]: {
            values: [...values],
          },
        },
        partial_match: currentFilter.partialMatch || false,
      });
    }

    return filtered;
  }

  /**
   * Builds int filters object to the request.
   * @param filters Filter[]
   * @returns int filters
   */
  private _buildIntFilters(filters: Filter[]): ServiceRequestInfoV3Filter[] {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      let values = [];

      switch (currentFilter.uiModel) {
        case FilterTypes.FILTER_LIST:
          currentFilter.params.value.map(f => {
            if (Array.isArray(f.value)) {
              f.value.forEach(val => {
                values.push(val);
              });
            } else {
              values.push(f.value);
            }
          });

          break;
        default:
          values = [currentFilter.params.value.to];

          break;
      }

      filtered.push({
        '@type': ServiceRequestInfoV3FilteringTypes.INTEGER,
        values: {
          [currentFilter.key]: {
            values: [...values],
          },
        },
      });
    }

    return filtered;
  }

  /**
   * Builds float filters object to the request.
   * @param filters Filter[]
   * @returns float filters
   */
  private _buildFloatFilters(filters: Filter[]): ServiceRequestInfoV3Filter[] {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      let values = [];

      switch (currentFilter.uiModel) {
        case FilterTypes.FILTER_LIST:
          values = currentFilter.params.value.map(f => f.value);

          break;
        default:
          values = [currentFilter.params.value.to];

          break;
      }

      filtered.push({
        '@type': ServiceRequestInfoV3FilteringTypes.FLOAT,
        scale: 2,
        values: {
          [currentFilter.key]: {
            values: [...values],
          },
        },
      });
    }

    return filtered;
  }

  /**
   * Builds datetime filters object to the request.
   * @param filters Filter[]
   * @returns datetime filters
   */
  private _buildDateTimeFilters(
    filters: Filter[]
  ): ServiceRequestInfoV3Filter[] {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      let values = [];

      switch (currentFilter.uiModel) {
        case FilterTypes.FILTER_LIST:
          values = currentFilter.params.value.map(f =>
            formatDateToIsoAndRemoveUnnecessaryInfo(f.value)
          );

          break;
        default:
          values = [
            formatDateToIsoAndRemoveUnnecessaryInfo(
              currentFilter.params.value.to
            ),
          ];

          break;
      }

      filtered.push({
        '@type': ServiceRequestInfoV3FilteringTypes.DATETIME,
        values: {
          [currentFilter.key]: {
            values: [...values],
          },
        },
      });
    }

    return filtered;
  }

  /**
   * Builds boolean filters object to the request.
   * @param filters Filter[]
   * @returns boolean filters
   */
  private _buildBooleanFilters(filters: Filter[]) {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      switch (currentFilter.uiModel) {
        case FilterTypes.FILTER_LIST:
          currentFilter.params.value.forEach(f => {
            filtered.push({
              '@type': ServiceRequestInfoV3FilteringTypes.BOOLEAN,
              values: {
                [currentFilter.key]: f.value,
              },
            });
          });
          break;
        default:
          filtered.push({
            '@type': ServiceRequestInfoV3FilteringTypes.BOOLEAN,
            values: {
              [currentFilter.key]: currentFilter.params.value.to,
            },
          });
          break;
      }
    }

    return filtered;
  }

  private _buildNotExistsFilters(filters: Filter[]) {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      let values = {};

      values[currentFilter.key] = currentFilter.params.value.to;

      filtered.push({
        '@type': ServiceRequestInfoV3FilteringTypes.NOT_EXISTS,
        values,
      });
    }

    return filtered;
  }

  private _buildNullFilters(filters: Filter[]) {
    const filtered = [];
    let values: string[] = [];

    for (let i = 0; i < filters.length; i++) {
      const currentFilter = filters[i];

      values.push(currentFilter.key);
    }

    filtered.push({
      '@type': ServiceRequestInfoV3FilteringTypes.IS_NULL,
      values,
    });

    return filtered;
  }

  /**
   * Builds ranged filters object to the request.
   * @param filters Filter[]
   * @returns ranged filters
   */
  private _buildRangedFilters(filters: Filter[]): ServiceRequestInfoV3Filter[] {
    const filtered = [];

    for (let i = 0; i < filters.length; i++) {
      let values = {};
      const currentFilter = filters[i];

      switch (currentFilter.uiModel) {
        case FilterTypes.FILTER_LIST:
          currentFilter.params.value.forEach(filterValue => {
            values = {};

            values[currentFilter.key] = {
              ...this._buildRangedValues(
                filterValue.value,
                currentFilter.params.valueType
              ),
            };

            filtered.push({
              '@type': ServiceRequestInfoV3FilteringTypes.RANGED,
              values,
            });
          });
          break;
        default:
          values[currentFilter.key] = {
            ...this._buildRangedValues(
              currentFilter.params.value,
              currentFilter.params.valueType
            ),
          };

          filtered.push({
            '@type': ServiceRequestInfoV3FilteringTypes.RANGED,
            values,
          });
          break;
      }
    }

    return filtered;
  }

  private _buildRangedValues(values: any, valueType: string) {
    const valuesObj = {};

    if (values.hasOwnProperty('from') && values.from !== null) {
      valuesObj['from'] = {
        '@type': ServiceRequestInfoV3FilteringRangedTypes[valueType],
        value:
          valueType === ServiceRequestInfoV3FilteringType.DATETIME
            ? formatDateToIsoAndRemoveUnnecessaryInfo(values.from)
            : values.from,
      };
    }

    if (values.hasOwnProperty('to') && values.to !== null) {
      valuesObj['to'] = {
        '@type': ServiceRequestInfoV3FilteringRangedTypes[valueType],
        value:
          valueType === ServiceRequestInfoV3FilteringType.DATETIME
            ? formatDateToIsoAndRemoveUnnecessaryInfo(values.to)
            : values.to,
      };
    }

    return valuesObj;
  }
}
