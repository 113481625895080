import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { SupportedSourceResponse } from '../model/integrations.model';

export class SupportedSourcesAdapter
  implements DataAdapter<any, SupportedSourceResponse>
{
  transform(supportedSources: any): SupportedSourceResponse {
    const categories = [];

    const newSupportedSources = supportedSources.map(supportedSource => {
      if (supportedSource.connect_ui_params) {
        for (let i = 0; i < supportedSource.connect_ui_params.length; i++) {
          var properties = Object.values(
            supportedSource.connect_ui_params[i]
          )[0];
          supportedSource.connect_ui_params[i] = Object.assign({}, properties, {
            key: Object.keys(supportedSource.connect_ui_params[i])[0],
          });
        }
      }

      if (supportedSource.category) {
        categories.push(supportedSource.category);
      }

      return {
        tap: supportedSource.tap,
        type: supportedSource.type,
        redirectUriParams: supportedSource.redirect_uri_params
          ? supportedSource.redirect_uri_params
          : null,
        tapUrl: supportedSource.tap_url,
        label: supportedSource.label,
        icon: supportedSource.icon,
        callbackUrl: supportedSource.callback_url
          ? supportedSource.callback_url
          : null,
        connectedUiParams: supportedSource.connect_ui_params
          ? supportedSource.connect_ui_params
          : null,
        category: supportedSource.category ? supportedSource.category : 'Other',
      };
    });

    return { supportedSources: newSupportedSources, categories: categories };
  }
}
