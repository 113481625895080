import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appRadioButtonGroup]',
})
export class RadioButtonGroupDirective {
  @Input()
  set direction(direction: string) {
    this.class =
      direction === 'horizontal'
        ? 'radio-button-group radio-button-group-horizontal'
        : 'radio-button-group';
  }

  @HostBinding('class')
  class: string = 'radio-button-group';
}
