import { Directive, HostBinding } from '@angular/core';
import { EXPANSION_PANEL_TOGGLE } from '../../design-system/expansion-panel/model/expansion-panel.model';

@Directive({
  selector: '[appExpansionPanelJobsToggle], app-expansion-panel-jobs-toggle',
  providers: [
    {
      provide: EXPANSION_PANEL_TOGGLE,
      useExisting: ExpansionPanelJobsToggleDirective,
    },
  ],
})
export class ExpansionPanelJobsToggleDirective {
  @HostBinding('class')
  class: string = 'expansion-panel-jobs-header-toggle';
}
