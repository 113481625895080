<app-checkbox
  [checked]="useSupplierSettings()"
  (checkedChange)="setUseSupplierSettings($event)"
  [label]="STRINGS.supplierSettings.toggle" />

@if (useSupplierSettings()) {
  <app-email-management
    ignoreNotFilledLanguages
    [emailsAddressesList]="emailsAddressesList()"
    [saving]="saving()"
    [loading]="loadingTemplates()"
    (emailAddressCreated)="createEmailAddress($event)"
    (emailsAdressesChange)="emailsSelected($event)" />
}
