<button
  aria-label="Clear all filters button"
  appButton
  size="sm"
  variant="link"
  class="clear-all-filters-button custom-link-btn"
  [disabled]="disabled"
  (click)="cleared.emit()">
  {{ COMMON_STRINGS.filter.clearAll }}
</button>
