<app-filter-container-template
  [headerTemplate]="headerTemplate()"
  [footerTemplate]="footerTemplate()"
  [filterName]="filterName()"
  [disableClear]="filterDisabled()"
  [disableApply]="filterDisabled()"
  [showApplyAll]="showApplyAll()"
  (clear)="clear()"
  (cancel)="cancel()"
  (apply)="apply($event)">
  <app-filter-type-options-v2
    [options]="filterOptions()"
    [preSelectedOption]="filterValues()?.subOperator"
    (optionSelected)="selectOption($event)" />
</app-filter-container-template>
