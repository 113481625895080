import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AllowExit,
  ConfirmExit,
  LoadFailed,
  Loading,
  LoadSucceed,
  PreventExit,
  SaveFailed,
  SaveSucceed,
  Saving,
  SavingInfinite,
  SetColorTheme,
} from '../actions/app.action';
import { MESSAGES } from '../constants/strings.constants';
import { NotificationCenterService } from '../services/notification-center.service';
import { ThemeService } from '../services/theme/theme.service';
import { AccountSettingsState } from './account-settings.state';

export interface AppStateModel {
  saving: boolean;
  loading: boolean;
  canExit: boolean;
}

@State<AppStateModel>({
  name: 'appState',
  defaults: {
    saving: false,
    loading: false,
    canExit: true,
  },
})
@Injectable()
export class AppState {
  constructor(
    private store: Store,
    private theme: ThemeService,
    private notifications: NotificationCenterService
  ) {}

  @Selector()
  static isSaving(state: AppStateModel): boolean {
    return state.saving;
  }

  @Selector()
  static isLoading(state: AppStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static canExit(state: AppStateModel): boolean {
    return state.canExit;
  }

  @Action(Loading, { cancelUncompleted: true })
  loading(ctx: StateContext<AppStateModel>, payload: Loading) {
    ctx.patchState({
      loading: true,
    });

    if (payload.emitMessage) {
      this.notifications.showToast(
        payload.message || MESSAGES.notifications.app.loading
      );
    }
  }

  @Action(LoadSucceed, { cancelUncompleted: true })
  loadSucceed(ctx: StateContext<AppStateModel>, payload: LoadSucceed) {
    ctx.patchState({
      loading: false,
    });

    if (payload.emitMessage) {
      this.notifications.showToastSuccess(
        payload.message || MESSAGES.notifications.app.loadSuccess
      );
    }
  }

  @Action(LoadFailed, { cancelUncompleted: true })
  loadFailed(ctx: StateContext<AppStateModel>, payload: LoadFailed) {
    ctx.patchState({
      loading: false,
    });

    if (payload.emitMessage) {
      this.notifications.showToastError(
        payload.message || MESSAGES.notifications.app.loadFailed
      );
    }
    throw new Error(payload.message || MESSAGES.notifications.app.loadFailed);
  }

  @Action(Saving, { cancelUncompleted: true })
  saving(ctx: StateContext<AppStateModel>, payload: Saving) {
    ctx.patchState({
      saving: true,
    });

    if (payload.emitMessage) {
      this.notifications.showToast(
        payload.message || MESSAGES.notifications.app.saving
      );
    }
  }

  @Action(SavingInfinite, { cancelUncompleted: true })
  savingInfinite(ctx: StateContext<AppStateModel>, payload: Saving) {
    ctx.patchState({
      saving: true,
    });

    if (payload.emitMessage) {
      this.notifications.showToastWithDuration(
        payload.message || MESSAGES.notifications.app.saving
      );
    }
  }

  @Action(SaveSucceed, { cancelUncompleted: true })
  saveSucceed(ctx: StateContext<AppStateModel>, payload: SaveSucceed) {
    ctx.patchState({
      saving: false,
    });

    if (payload.emitMessage) {
      this.notifications.showToastSuccess(
        payload.message || MESSAGES.notifications.app.saveSuccess
      );
    }
  }

  @Action(SaveFailed, { cancelUncompleted: true })
  saveFailed(ctx: StateContext<AppStateModel>, payload: SaveFailed) {
    ctx.patchState({
      saving: false,
    });

    if (payload.emitMessage) {
      this.notifications.showToastError(
        payload.message || MESSAGES.notifications.app.saveFailed
      );
    }

    throw new Error(payload.message || MESSAGES.notifications.app.saveFailed);
  }

  @Action(SetColorTheme, { cancelUncompleted: true })
  setColorTheme(_ctx: StateContext<AppStateModel>) {
    const activeColorTheme = this.store.selectSnapshot(
      AccountSettingsState.colorTheme
    );

    return this.theme.setColorTheme(activeColorTheme);
  }

  @Action(PreventExit, { cancelUncompleted: true })
  preventExit(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      canExit: false,
    });
  }

  @Action([ConfirmExit, AllowExit], { cancelUncompleted: true })
  confirmExit(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      canExit: true,
    });
  }
}
