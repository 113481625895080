import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  DeliveryOrderline,
  DeliveryOrderlines,
  DeliveryOrderlineSupplierProduct,
  DeliveryOrderlineWebshopProduct,
} from 'src/app/shared/models/delivery/v2/deliveries-editor-v2.model';
import {
  DeliveryOrderline as DeliveryOrderlineV1,
  DeliveryOrderlines as DeliveryOrderlinesV1,
  DeliveryOrderlineSupplierProduct as DeliveryOrderlineSupplierProductV1,
  DeliveryOrderlineWebshopProduct as DeliveryOrderlineWebshopProductV1,
} from '../model/deliveries-editor-v2.model';

export class DeliveryOrderslinesV2Adapter
  implements DataAdapter<DeliveryOrderlinesV1, DeliveryOrderlines>
{
  transform(data: DeliveryOrderlinesV1): DeliveryOrderlines {
    const orderlines: DeliveryOrderline[] = data.orderLines.map(
      (orderline: DeliveryOrderlineV1) => {
        return {
          buyOrderLineUuid: orderline.buyOrderLineUuid,
          webshopProduct: this._buildWebshopProduct(orderline.webshopProduct),
          supplierProduct: this._buildSupplierProduct(
            orderline.supplierProduct
          ),
          ordered: orderline.ordered,
          delivered: orderline.delivered,
          undelivered: orderline.undelivered,
          expectedDeliveryDate: orderline.expectedDeliveryDate,
          deliveredOn: orderline.deliveredOn,
        };
      }
    );

    return {
      data: orderlines,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  private _buildWebshopProduct(
    webshopProduct: DeliveryOrderlineWebshopProductV1
  ): DeliveryOrderlineWebshopProduct {
    if (!webshopProduct) return null;

    return {
      uuid: webshopProduct.uuid,
      sku: webshopProduct.sku,
      articleCode: webshopProduct.articleCode,
      stockLevel: webshopProduct.stockLevel,
      stockPosition: webshopProduct.stockPosition,
      name: webshopProduct.name,
      eanCode: webshopProduct.eanCode,
      id: webshopProduct.id,
    };
  }

  private _buildSupplierProduct(
    supplierProduct: DeliveryOrderlineSupplierProductV1
  ): DeliveryOrderlineSupplierProduct {
    if (!supplierProduct) return null;

    return {
      articleCode: supplierProduct.articleCode,
      sku: supplierProduct.sku,
      name: supplierProduct.name,
      eanCode: supplierProduct.eanCode,
    };
  }
}
