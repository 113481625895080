<div
  class="search-container"
  [class.search-container-focused]="focus() || !!searchParams.length">
  <app-tags-input
    class="search"
    [variant]="variant"
    [tags]="searchParams"
    [separatorKeys]="[';', ',', 'Enter']"
    tagsVariant="subtle"
    tagsColor="blue"
    type="text"
    tagsRemovable="true"
    [placeholder]="COMMON_STRINGS.filter.search"
    floatErrors
    [disabled]="disabled"
    (added)="addedParam.emit($event)"
    (removed)="removedParam.emit($event)"
    (focused)="focus.set($event)"></app-tags-input>
</div>
