import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  viewChild,
} from '@angular/core';
import { ExpansionPanelModule } from '../../../design-system/expansion-panel/expansion-panel.module';
import { InputModule } from '../../../design-system/input/input.module';
import { ButtonModule } from '../../../design-system/button/button.module';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from '../../model/email-management.strings';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { ExpansionPanelComponent } from '../../../design-system/expansion-panel/expansion-panel.component';
import { emailRegexValidationV2 } from 'src/app/shared/utils/email-validation';

@Component({
  selector: 'app-email-creation',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ExpansionPanelModule,
    InputModule,
    ButtonModule,
    MatIcon,
  ],
  templateUrl: './email-creation.component.html',
  styleUrl: './email-creation.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'emailCreation',
})
export class EmailCreationComponent {
  panel = viewChild.required<ExpansionPanelComponent>('panel');

  hasEmailsAddresses = input.required<boolean>();

  saving = input<boolean>();

  emailAddress: string | null = null;

  createdEmailAddress = output<string>();

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata.emailManagement;

  readonly EMAIL_REGEX = emailRegexValidationV2;

  createEmailAddress() {
    this.createdEmailAddress.emit(this.emailAddress);
  }
}
