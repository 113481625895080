import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  signal,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FilterButtonBase } from '../../filter-button-base';
import {
  ColumnFilter,
  FilterOption,
  FilterTypeOptions,
  FilterTypes,
} from '../../model/filter.model';
import { DateTime } from 'luxon';
import { SelectableColumnType } from '../../../../model/data-table-v2.model';
import {
  FILTER_SHELL,
  FilterShell,
  FilterTypeOptionsV2,
  FilterTypesOptionsV2,
} from '../../model/filter-v2.model';
import {
  ServiceRequestInfoV3Operator,
  ServiceRequestInfoV3Operators,
} from '../../../../model/pageable-v2.model';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FILTER_SHELL, useExisting: FilterButtonComponent }],
})
export class FilterButtonComponent
  extends FilterButtonBase
  implements FilterShell
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ required: true, alias: 'filter' })
  set _filter(filter: ColumnFilter) {
    this.filter.set(filter);
  }

  @Input()
  disabled: boolean;

  @Output()
  removed: EventEmitter<string> = new EventEmitter<string>();

  filter = signal<ColumnFilter | null>(null);

  filterUiModel = computed<SelectableColumnType | null>(() => {
    if (!this.filter()) return null;

    return this.filter().uiModel;
  });

  filterValueUnit = computed<string>(() => {
    if (!this.filter()?.unit) return '';

    return this.filter().unit;
  });

  filterValues = computed(() => {
    if (!this.filter()) return null;

    return {
      operator: this.filter().params.operator,
      subOperator: this.filter().params.subOperator,
      value: this.filter().params.value,
    };
  });

  valuesPreview = computed<string>(() => {
    if (!this.filter()) return 'PROVIDE A FILTER';

    return this._getPreview();
  });

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly FILTER_TYPES = FilterTypeOptions;

  private _getPreview(): string {
    const {
      operator,
      subOperator,
      value: { from, to },
    } = this.filterValues();

    switch (this.filterUiModel()) {
      case FilterTypes.FILTER_NUMBER:
        return this._filterNumberPreview(operator, from, to);
      case FilterTypes.FILTER_DATE:
        return this._filterDatePreview(operator, from, to);
      case FilterTypes.FILTER_LIST:
        return this._filterListPreview();
      case FilterTypes.FILTER_BOOLEAN:
        return to;
      case FilterTypes.FILTER_NOT_EXISTS:
        return this.COMMON_STRINGS.filter.optionTypes.notExists;
      case FilterTypes.FILTER_NULLABLE:
        return this._filterNullablePreview(subOperator);
      default:
        return to;
    }
  }

  private _filterNumberPreview(
    operator: FilterTypesOptionsV2,
    from: any,
    to: any
  ): string {
    if (
      operator === FilterTypeOptionsV2.RANGED &&
      from === null &&
      to !== null
    ) {
      return `<= ${to} ${this.filterValueUnit()}`;
    }

    if (
      operator === FilterTypeOptionsV2.RANGED &&
      from !== null &&
      to === null
    ) {
      return `>= ${from} ${this.filterValueUnit()}`;
    }

    if (
      operator === FilterTypeOptionsV2.RANGED &&
      from !== null &&
      to !== null
    ) {
      return `${from} ${this.filterValueUnit()} - ${to} ${this.filterValueUnit()}`;
    }

    return `${to} ${this.filterValueUnit()}`;
  }

  private _filterDatePreview(
    operator: FilterTypesOptionsV2,
    from: any,
    to: any
  ): string {
    const format = 'LLL dd, yyyy';

    if (
      operator === FilterTypeOptionsV2.RANGED &&
      from === null &&
      to !== null
    ) {
      return `<= ${DateTime.fromJSDate(to).toFormat(format)}`;
    }

    if (
      operator === FilterTypeOptionsV2.RANGED &&
      from !== null &&
      to === null
    ) {
      return `>= ${DateTime.fromJSDate(from).toFormat(format)}`;
    }

    if (
      operator === FilterTypeOptionsV2.RANGED &&
      from !== null &&
      to !== null
    ) {
      return `${DateTime.fromJSDate(from).toFormat(
        format
      )} - ${DateTime.fromJSDate(to).toFormat(format)}`;
    }

    return `${DateTime.fromJSDate(to).toFormat(format)}`;
  }

  private _filterNullablePreview(subOperator: ServiceRequestInfoV3Operator) {
    if (subOperator === ServiceRequestInfoV3Operators.AND) {
      return this.COMMON_STRINGS.filter.optionTypes.isEmpty;
    }

    return this.COMMON_STRINGS.filter.optionTypes.isNotEmpty;
  }

  private _filterListPreview(): string {
    return this.filterValues()
      .value?.map((option: FilterOption) => option.key)
      .join(', ');
  }
}
