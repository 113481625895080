import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { BehaviorSubject, Subject, debounceTime, takeUntil } from 'rxjs';

import { WebshopState } from 'src/app/core/states/webshop.state';

import { Webshop } from '../../models/webshop/webshop.model';
import { InviteUsers } from 'src/app/features/settings/actions/settings-user-management.action';
import { DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-invite-users-dialog',
  templateUrl: './invite-users-dialog.component.html',
  styleUrls: ['./invite-users-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteUsersDialogComponent implements OnInit, OnDestroy {
  webshop: Webshop = null;

  emailControl = new UntypedFormControl('', [Validators.email]);
  emailValidations = false;

  disableSubmitButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  readonly COMMON_STRINGS = MESSAGES.common;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions: Actions,
    private dialogRef: DialogRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .subscribe((webshop: Webshop | null) => {
        if (webshop) {
          this.webshop = webshop;
        }
      });

    this.actions
      .pipe(ofActionDispatched(InviteUsers), takeUntil(this.destroy$))
      .subscribe(() => {
        this.emailControl.reset();
        this.disableSubmitButton$.next(true);
      });

    this.emailControl.valueChanges
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(() => {
        this.validateEmail(this.emailControl.value);
      });
  }

  sendInvite(): void {
    this.validateEmail(this.emailControl.value);

    if (this.emailValidations) {
      this.store.dispatch(new InviteUsers(this.emailControl.value));
    }
  }

  validateEmail(email): void {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.emailValidations = regex.test(email);
    this.disableSubmitButton$.next(!regex.test(email));
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.disableSubmitButton$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
