<app-datatable-container
  [loading]="loading | async"
  [totalElements]="(dataSourceSubject$ | async)?.length"
  withoutPagination>
  <table cdk-table [dataSource]="dataSourceSubject$ | async">
    <!-- Name Column -->
    <ng-container cdkColumnDef="Name">
      <th
        cdk-header-cell
        *cdkHeaderCellDef
        i18n="webshop data table name column">
        Name
      </th>
      <td cdk-cell *cdkCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- Handle Column -->
    <ng-container cdkColumnDef="Handle">
      <th
        cdk-header-cell
        *cdkHeaderCellDef
        i18n="webshop data table handle column">
        Handle
      </th>
      <td cdk-cell *cdkCellDef="let element">{{ element.handle }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container cdkColumnDef="ID">
      <th cdk-header-cell *cdkHeaderCellDef i18n="webshop data table id column">
        ID
      </th>
      <td cdk-cell *cdkCellDef="let element">{{ element.uuid }}</td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns; sticky: true"
      class="datatable-headers"></tr>
    <tr
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns"
      (click)="onRowSelected(row)"
      class="datatable-rows clickable-row"
      [ngClass]="{ highlighted: selection.isSelected(row) }"
      [class.current]="selectedWebshop?.uuid === row.uuid"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
