import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {
  SaveFailed,
  SaveSucceed,
  SavingInfinite,
} from 'src/app/core/actions/app.action';
import { IntegrationsService } from 'src/app/core/api/integrations/integrations.service';
import {
  Job,
  LinkedSource,
  LinkedTarget,
  SupportedSource,
  manualSources,
} from 'src/app/core/api/integrations/model/integrations.model';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  CloseAddSourceDialog,
  CreateLinkedSource,
  CreateSourceOAuth,
  RefreshJobs,
  DeleteLinkedSource,
  ResetState,
  RetrieveLinkedSources,
  RetrieveSupportedSources,
  SelectSupportedSource,
  UpdateLinkedSource,
  RetrieveJobsPerTap,
  CloseConfirmationDialog,
  FilterSources,
  InitializeSourceList,
  RetrieveExportJobsPerTap,
  RetrieveLinkedTargets,
  RefreshExportJobs,
} from '../actions/integrations.action';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { environment } from 'src/environments/environment';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { ColorThemes } from 'src/app/shared/models/account/account-settings.model';
import { patch, updateItem } from '@ngxs/store/operators';
import { state } from '@angular/animations';

export interface IntegrationsStateModel {
  supportedSources: SupportedSource[];
  filteredSupportedSources: SupportedSource[];
  selectedSupportedSource: SupportedSource;
  linkedSources: LinkedSource[];
  sourcesCategories: string[];
  loadingSupportedSources: boolean;
  loadingLinkedSources: boolean;
  loadingJobs: boolean;
  disableDialog: boolean;
  search: string[] | null;
}

@State<IntegrationsStateModel>({
  name: 'integrationsState',
  defaults: {
    supportedSources: [],
    filteredSupportedSources: [],
    selectedSupportedSource: null,
    linkedSources: [],
    sourcesCategories: [],
    loadingSupportedSources: false,
    loadingLinkedSources: false,
    loadingJobs: false,
    disableDialog: false,
    search: null,
  },
})
@Injectable()
export class IntegrationsState {
  constructor(
    private store: Store,
    private integrationsService: IntegrationsService
  ) {}

  @Selector()
  static supportedSources(state: IntegrationsStateModel): SupportedSource[] {
    return state.supportedSources;
  }

  @Selector()
  static filteredSupportedSources(
    state: IntegrationsStateModel
  ): SupportedSource[] {
    return state.filteredSupportedSources;
  }

  @Selector()
  static sourcesCategories(state: IntegrationsStateModel): string[] {
    return state.sourcesCategories;
  }

  @Selector()
  static selectedSupportedSource(
    state: IntegrationsStateModel
  ): SupportedSource {
    return state.selectedSupportedSource;
  }

  @Selector()
  static linkedSources(state: IntegrationsStateModel): LinkedSource[] {
    return state.linkedSources;
  }

  @Selector()
  static loadingLinkedSources(state: IntegrationsStateModel): boolean {
    return state.loadingLinkedSources;
  }

  @Selector()
  static loadingSupportedSources(state: IntegrationsStateModel): boolean {
    return state.loadingSupportedSources;
  }

  @Selector()
  static loadingJobs(state: IntegrationsStateModel): boolean {
    return state.loadingJobs;
  }

  @Selector()
  static hasLinkedSources(state: IntegrationsStateModel): boolean {
    return !!state.linkedSources.length;
  }

  @Selector()
  static disableDialog(state: IntegrationsStateModel): boolean {
    return state.disableDialog;
  }

  @Selector()
  static search(state: IntegrationsStateModel): string[] {
    return state.search || [];
  }

  /* Sets the supportedSources loading state to true and makes the requests to get them from Hotglue and Optiply.
  Merges all supportedSources and updates the state. In case of error, sets the loading state to false. */
  @Action(RetrieveSupportedSources, { cancelUncompleted: true })
  retrieveSupportedSources(ctx: StateContext<IntegrationsStateModel>) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;

    ctx.patchState({
      loadingSupportedSources: true,
    });

    const hotglueSupportedSources$ =
      this.integrationsService.retrieveSupportedSources(webshopUUID);
    const optiplySupportedSources$ =
      this.integrationsService.retrieveOptiplySupportedSources(webshopUUID);

    return forkJoin([hotglueSupportedSources$, optiplySupportedSources$]).pipe(
      tap(([hotglueSupportedSources, optiplySupportedSources]) => {
        const mergedSources = [
          ...optiplySupportedSources.supportedSources,
          ...hotglueSupportedSources.supportedSources,
          ...manualSources,
        ];

        const mergedCategories = [
          ...optiplySupportedSources.categories,
          ...hotglueSupportedSources.categories,
          ...['Other'],
        ];

        const filteredMergedCategories = mergedCategories.filter(
          (item, index, self) => self.indexOf(item) === index
        );

        ctx.patchState({
          supportedSources: mergedSources,
          filteredSupportedSources: mergedSources,
          sourcesCategories: filteredMergedCategories,
          loadingSupportedSources: false,
        });
      }),
      catchError(e => {
        ctx.patchState({
          loadingSupportedSources: false,
        });
        throw new Error(e.message || e);
      })
    );
  }

  /* Sets the linkedSources loading state to true and makes the requests to get them from Hotglue and Optiply.
  Merges all linkedSources, updates the state and retrieves the jobs for each linkedSource.
  When everything is finished or in case of error, sets the loading state to false */
  @Action(RetrieveLinkedSources, { cancelUncompleted: true })
  retrieveLinkedSources(ctx: StateContext<IntegrationsStateModel>) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;
    const webshopID = this.store.selectSnapshot(WebshopState.selected)?.id;

    ctx.patchState({
      loadingLinkedSources: true,
    });

    const hotglueLinkedSources$ =
      this.integrationsService.retrieveLinkedSources(webshopUUID, webshopID);
    const optiplyLinkedSources$ =
      this.integrationsService.retrieveOptiplyLinkedSources(webshopUUID);

    return forkJoin([hotglueLinkedSources$, optiplyLinkedSources$]).pipe(
      map(([hotglueLinkedSources, optiplyLinkedSources]) => {
        return [...hotglueLinkedSources, ...optiplyLinkedSources];
      }),
      mergeMap((linkedSources: LinkedSource[]) => {
        ctx.patchState({
          linkedSources: linkedSources,
        });
        return from(linkedSources).pipe(
          concatMap(linkedSource => {
            let tenetRequent = linkedSource.tenant;
            if (!linkedSource.tenant) {
              tenetRequent = webshopID;
            }

            return ctx.dispatch(
              new RetrieveJobsPerTap(
                {
                  tenant: tenetRequent,
                  taps: linkedSource.tap,
                  count: 5,
                },
                linkedSource.isOptiplyIntegration
              )
            );
          })
        );
      }),
      finalize(() => {
        return ctx.dispatch(new RetrieveLinkedTargets());
      }),
      catchError(e => {
        ctx.patchState({
          loadingLinkedSources: false,
        });
        throw new Error(e.message || e);
      })
    );
  }

  @Action(RetrieveLinkedTargets, { cancelUncompleted: true })
  retrieveLinkedTargets(ctx: StateContext<IntegrationsStateModel>) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;
    const webshopID = this.store.selectSnapshot(WebshopState.selected)?.id;

    ctx.patchState({
      loadingLinkedSources: true,
    });

    return this.integrationsService
      .retrieveLinkedTargets(webshopUUID, webshopID)
      .pipe(
        mergeMap((linkedTargets: LinkedTarget[]) => {
          return from(linkedTargets).pipe(
            concatMap(linkedTarget => {
              return ctx.dispatch(
                new RetrieveExportJobsPerTap({
                  tenant: linkedTarget.tenant,
                  targets: linkedTarget.target,
                  count: 5,
                })
              );
            })
          );
        }),
        finalize(() => {
          ctx.patchState({
            loadingLinkedSources: false,
          });
        }),
        catchError(e => {
          ctx.patchState({
            loadingLinkedSources: false,
          });
          throw new Error(e.message || e);
        })
      );
  }

  /* If it is an optiply's or regular Hotglue's integration it just links the source, otherwise it has to check if it is and OAuth integration or not.
  If it is an OAuthIntegration it opens a pop up after generating a callback URL with the necessary data, the pop up closes automatically after 3 minutes or after trying to link the source.
  After creating a new linked source the linked sources have to be retrieved again to update its state */
  @Action(CreateLinkedSource, { cancelUncompleted: true })
  createLinkedSource(
    ctx: StateContext<IntegrationsStateModel>,
    payload: CreateLinkedSource
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;
    let webshopID = this.store
      .selectSnapshot(WebshopState.selected)
      ?.id.toString();
    const isOptiplyIntegration =
      payload.properties.supportedSource.isOptiplyIntegration;

    if (!isOptiplyIntegration) {
      const hotglueLinkedSources = ctx
        .getState()
        .linkedSources.filter(
          linkedSource => !linkedSource.isOptiplyIntegration
        );

      if (hotglueLinkedSources.length > 0) {
        webshopID = webshopID + '_' + hotglueLinkedSources.length;
      }
    }

    if (isOptiplyIntegration) {
      return ctx.dispatch(new SavingInfinite()).pipe(
        concatMap(() =>
          this.integrationsService.createOptiplyLinkedSource(
            webshopUUID,
            payload.properties.supportedSource.tap,
            {
              source: {
                tap: payload.properties.supportedSource.tap,
                config: payload.properties.config,
              },
            }
          )
        ),
        concatMap(() => ctx.dispatch(new SaveSucceed())),
        concatMap(() => ctx.dispatch(new CloseAddSourceDialog())),
        concatMap(() => ctx.dispatch(new RetrieveLinkedSources())),
        catchError(() => {
          ctx.dispatch(new SaveFailed());

          return of(null);
        })
      );
    } else if (payload.properties.supportedSource.type === 'oauth') {
      ctx.patchState({
        disableDialog: true,
      });

      const popupWindow = window.open(
        this.generateCallbackURLString(webshopID, payload),
        'popup',
        'width=600,height=600,position: absolute'
      );

      const checkWindowClosedInterval = setInterval(() => {
        if (popupWindow?.closed) {
          clearInterval(checkWindowClosedInterval);
          return ctx.dispatch(new CreateSourceOAuth());
        }
      }, 1000);

      setTimeout(() => {
        if (popupWindow && !popupWindow.closed) {
          popupWindow.close();
          ctx.patchState({
            disableDialog: false,
          });
        }
      }, 180000);
    } else {
      return ctx.dispatch(new SavingInfinite()).pipe(
        concatMap(() =>
          this.integrationsService.createLinkedSource(webshopUUID, webshopID, {
            source: {
              tap: payload.properties.supportedSource.tap,
              config: payload.properties.config,
            },
          })
        ),
        concatMap(() => ctx.dispatch(new SaveSucceed())),
        concatMap(() => ctx.dispatch(new CloseAddSourceDialog())),
        concatMap(() => ctx.dispatch(new RetrieveLinkedSources())),
        catchError(() => {
          ctx.dispatch(new SaveFailed());

          return of(null);
        })
      );
    }
  }

  /* Updates the coupling with new data */
  @Action(UpdateLinkedSource, { cancelUncompleted: true })
  updateLinkedSource(
    ctx: StateContext<IntegrationsStateModel>,
    payload: UpdateLinkedSource
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;
    const webshopID = this.store.selectSnapshot(WebshopState.selected)?.id;

    return ctx.dispatch(new SavingInfinite()).pipe(
      concatMap(() => {
        return this.integrationsService
          .updateLinkedSource(webshopUUID, webshopID, payload.properties)
          .pipe(
            concatMap(() => ctx.dispatch(new SaveSucceed())),
            concatMap(() => ctx.dispatch(new CloseAddSourceDialog())),
            concatMap(() => ctx.dispatch(new RetrieveLinkedSources()))
          );
      })
    );
  }

  /* Deletes the coupling for the given tap */
  @Action(DeleteLinkedSource, { cancelUncompleted: true })
  deleteLinkedSource(
    ctx: StateContext<IntegrationsStateModel>,
    payload: DeleteLinkedSource
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;
    const webshopID = this.store.selectSnapshot(WebshopState.selected)?.id;

    return ctx.dispatch(new SavingInfinite()).pipe(
      concatMap(() => {
        return this.integrationsService
          .deleteLinkedSource(webshopUUID, webshopID, payload.tapName)
          .pipe(
            concatMap(() => ctx.dispatch(new SaveSucceed())),
            concatMap(() => ctx.dispatch(new CloseConfirmationDialog())),
            concatMap(() => ctx.dispatch(new RetrieveLinkedSources()))
          );
      })
    );
  }

  /* Get the state of the linkedSource that corresponds to the tap and sets its loading state to true.
  Retrieves the last 5 jobs for the given tap and updates the state of the correspondent linkedSource with the new jobs, setting the loading state to false.
  In case of error it sets the loading state of the linkedSource to false. */
  @Action(RetrieveJobsPerTap)
  retrieveJobsPerTap(
    ctx: StateContext<IntegrationsStateModel>,
    payload: RetrieveJobsPerTap
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;

    let linkedSourceToPatch = ctx
      .getState()
      .linkedSources.find(linkedSource =>
        linkedSource.tenant
          ? linkedSource.tenant === payload.queryParameters.tenant &&
            linkedSource.tap === payload.queryParameters.taps
          : linkedSource.tap === payload.queryParameters.taps
      );

    ctx.setState(
      patch<IntegrationsStateModel>({
        linkedSources: updateItem<LinkedSource>(
          linkedSource =>
            linkedSource.tenant
              ? linkedSource.tenant === payload.queryParameters.tenant &&
                linkedSource.tap === payload.queryParameters.taps
              : linkedSource.tap === payload.queryParameters.taps,
          {
            ...linkedSourceToPatch,
            loadingJobs: true,
          }
        ),
      })
    );

    if (payload.isOptiplyLinkedSource) {
      return this.integrationsService.retrieveOptiplyJobs(webshopUUID).pipe(
        tap((jobs: Job[]) => {
          ctx.setState(
            patch<IntegrationsStateModel>({
              linkedSources: updateItem<LinkedSource>(
                linkedSource =>
                  linkedSource.tenant
                    ? linkedSource.tenant === payload.queryParameters.tenant &&
                      linkedSource.tap === payload.queryParameters.taps
                    : linkedSource.tap === payload.queryParameters.taps,
                {
                  ...linkedSourceToPatch,
                  jobs,
                  loadingJobs: false,
                }
              ),
            })
          );
        }),
        catchError(e => {
          ctx.setState(
            patch<IntegrationsStateModel>({
              linkedSources: updateItem<LinkedSource>(
                linkedSource =>
                  linkedSource.tenant
                    ? linkedSource.tenant === payload.queryParameters.tenant &&
                      linkedSource.tap === payload.queryParameters.taps
                    : linkedSource.tap === payload.queryParameters.taps,
                {
                  ...linkedSourceToPatch,
                  loadingJobs: false,
                }
              ),
            })
          );
          throw new Error(e.message || e);
        })
      );
    } else {
      return this.integrationsService
        .retrieveJobsPerTap(webshopUUID, payload.queryParameters)
        .pipe(
          tap((jobs: Job[]) => {
            ctx.setState(
              patch<IntegrationsStateModel>({
                linkedSources: updateItem<LinkedSource>(
                  linkedSource =>
                    linkedSource.tenant
                      ? linkedSource.tenant ===
                          payload.queryParameters.tenant &&
                        linkedSource.tap === payload.queryParameters.taps
                      : linkedSource.tap === payload.queryParameters.taps,
                  {
                    ...linkedSourceToPatch,
                    jobs: jobs,
                    loadingJobs: false,
                  }
                ),
              })
            );
          }),
          catchError(e => {
            ctx.setState(
              patch<IntegrationsStateModel>({
                linkedSources: updateItem<LinkedSource>(
                  linkedSource =>
                    linkedSource.tenant
                      ? linkedSource.tenant ===
                          payload.queryParameters.tenant &&
                        linkedSource.tap === payload.queryParameters.taps
                      : linkedSource.tap === payload.queryParameters.taps,
                  {
                    ...linkedSourceToPatch,
                    loadingJobs: false,
                  }
                ),
              })
            );
            throw new Error(e.message || e);
          })
        );
    }
  }

  @Action(RetrieveExportJobsPerTap)
  retrieveExportJobsPerTap(
    ctx: StateContext<IntegrationsStateModel>,
    payload: RetrieveExportJobsPerTap
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;

    let target = payload.queryParameters.targets;

    const regexIfen = /-v\d/;
    const regexVersion = /v\d/;

    if (regexIfen.test(target)) {
      target = target.slice(0, target.length - 3);
    } else if (regexVersion.test(target)) {
      target = target.slice(0, target.length - 2);
    }

    let linkedSourceToPatch = ctx
      .getState()
      .linkedSources.find(linkedSource =>
        linkedSource.tenant
          ? linkedSource.tenant === payload.queryParameters.tenant &&
            linkedSource.tap === target
          : linkedSource.tap === target
      );

    ctx.setState(
      patch<IntegrationsStateModel>({
        linkedSources: updateItem<LinkedSource>(
          linkedSource =>
            linkedSource.tenant
              ? linkedSource.tenant === payload.queryParameters.tenant &&
                linkedSource.tap === target
              : linkedSource.tap === target,
          {
            ...linkedSourceToPatch,
            loadingJobs: true,
          }
        ),
      })
    );

    return this.integrationsService
      .retrieveExportJobsPerTap(webshopUUID, payload.queryParameters)
      .pipe(
        tap((exportJobs: Job[]) => {
          ctx.setState(
            patch<IntegrationsStateModel>({
              linkedSources: updateItem<LinkedSource>(
                linkedSource =>
                  linkedSource.tenant
                    ? linkedSource.tenant === payload.queryParameters.tenant &&
                      linkedSource.tap === target
                    : linkedSource.tap === target,
                {
                  ...linkedSourceToPatch,
                  exportJobs,
                  loadingJobs: false,
                }
              ),
            })
          );
        }),
        catchError(e => {
          throw new Error(e.message || e);
        })
      );
  }

  /* Retrieves the linked sources, anf if there is a new one shows a success message and closes the dialog */
  @Action(CreateSourceOAuth, { cancelUncompleted: true })
  createSourceOAuth(ctx: StateContext<IntegrationsStateModel>) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;
    const webshopID = this.store.selectSnapshot(WebshopState.selected)?.id;

    const currentLinkedSourcesLength = ctx.getState().linkedSources.length;

    return this.integrationsService
      .retrieveLinkedSources(webshopUUID, webshopID)
      .pipe(
        concatMap((linkedSources: LinkedSource[]) => {
          ctx.patchState({
            disableDialog: false,
          });
          if (linkedSources.length !== currentLinkedSourcesLength) {
            ctx.dispatch(new SaveSucceed());
            ctx.dispatch(new CloseAddSourceDialog());
            ctx.dispatch(new RetrieveLinkedSources());
          }
          return of(null);
        }),
        catchError(e => {
          ctx.dispatch(new SaveFailed());
          ctx.patchState({
            loadingLinkedSources: false,
          });
          throw new Error(e.message || e);
        })
      );
  }

  /* Selects a supported source to be linked. Resets the state with the default value.
  Retrieves all jobs for the given tap and shows a success or insuccess message */
  @Action(SelectSupportedSource, { cancelUncompleted: true })
  selectSupportedSource(
    ctx: StateContext<IntegrationsStateModel>,
    payload: SelectSupportedSource
  ) {
    const state = ctx.getState();

    ctx.patchState({
      selectedSupportedSource: state.supportedSources.find(
        source => source.tap === payload.tap
      ),
    });
  }

  /* Resets the state with the default values */
  @Action(ResetState, { cancelUncompleted: true })
  resetState(ctx: StateContext<IntegrationsStateModel>) {
    ctx.patchState({
      supportedSources: [],
      filteredSupportedSources: [],
      selectedSupportedSource: null,
      linkedSources: [],
      loadingSupportedSources: false,
      loadingLinkedSources: false,
      loadingJobs: false,
      disableDialog: false,
    });
  }

  /* Retrieves all jobs for the given tap and shows a success or insuccess message */
  @Action(RefreshJobs, { cancelUncompleted: true })
  refreshJobs(ctx: StateContext<IntegrationsStateModel>, payload: RefreshJobs) {
    const webshopID = this.store.selectSnapshot(WebshopState.selected)?.id;

    let tenetRequent = payload.linkedSource.tenant;
    if (!payload.linkedSource.tenant) {
      tenetRequent = webshopID;
    }

    return ctx
      .dispatch(
        new RetrieveJobsPerTap(
          {
            tenant: tenetRequent,
            taps: payload.linkedSource.tap,
            count: 5,
          },
          payload.linkedSource.isOptiplyIntegration
        )
      )
      .pipe(
        concatMap(() => {
          return ctx.dispatch(
            new SaveSucceed(
              true,
              MESSAGES.notifications.integrations.refreshSuccess
            )
          );
        }),
        catchError(() => {
          return ctx.dispatch(
            new SaveFailed(
              true,
              MESSAGES.notifications.integrations.refreshFailed
            )
          );
        })
      );
  }

  /* Retrieves all jobs for the given tap and shows a success or insuccess message */
  @Action(RefreshExportJobs, { cancelUncompleted: true })
  refreshExportJobs(
    ctx: StateContext<IntegrationsStateModel>,
    payload: RefreshExportJobs
  ) {
    const webshopID = this.store.selectSnapshot(WebshopState.selected)?.id;

    let tenetRequent = payload.linkedSource.tenant;
    if (!payload.linkedSource.tenant) {
      tenetRequent = webshopID;
    }

    return ctx
      .dispatch(
        new RetrieveExportJobsPerTap({
          tenant: tenetRequent,
          targets: payload.linkedSource.tap,
          count: 5,
        })
      )
      .pipe(
        concatMap(() => {
          return ctx.dispatch(
            new SaveSucceed(
              true,
              MESSAGES.notifications.integrations.refreshSuccess
            )
          );
        }),
        catchError(() => {
          return ctx.dispatch(
            new SaveFailed(
              true,
              MESSAGES.notifications.integrations.refreshFailed
            )
          );
        })
      );
  }

  @Action(FilterSources, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<IntegrationsStateModel>,
    payload: FilterSources
  ) {
    const state = ctx.getState();

    if (payload.filter && payload.filter.length > 2) {
      ctx.patchState({
        filteredSupportedSources: state.supportedSources.filter(e =>
          e.label.toLowerCase().includes(payload.filter.toLowerCase())
        ),
      });
    } else {
      ctx.patchState({
        filteredSupportedSources: state.supportedSources,
      });
    }
  }

  /* Sets the supportedSources loading state to true and makes the requests to get them from Hotglue and Optiply.
  Merges all supportedSources and updates the state. In case of error, sets the loading state to false. */
  @Action(InitializeSourceList, { cancelUncompleted: true })
  initializeSourceList(ctx: StateContext<IntegrationsStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      filteredSupportedSources: state.supportedSources,
    });
  }

  /**
   *
   * @param webshopID
   * @param tapName
   * @param tapUrl
   * @param parameters
   * @returns the callback string used on OAuth integrations to send all data to hotglue trough the authentication popup
   */
  generateCallbackURLString(
    webshopID: string,
    payload: CreateLinkedSource
  ): string {
    let env = 'dev.hotglue.optiply.nl';
    let logoUrl =
      'https://dashboard.acceptance.optiply.com/edge/en-US/assets/images/optiply.svg';

    if (environment.production) {
      env = 'production.hotglue.optiply.nl';
      logoUrl = 'https://app.optiply.com/v2/en-US/assets/images/optiply.svg';
    }

    let callbackUrl = payload.properties.supportedSource.callbackUrl
      ? payload.properties.supportedSource.callbackUrl
      : 'https://callback.oauth.optiply.com';

    const tapName: string = payload.properties.supportedSource.tap;

    let tapUrl: string;

    if (payload.properties.supportedSource.tap === 'exact') {
      tapUrl = this.constructExactTapUrl(
        payload.properties.config,
        payload.properties.exactExtraInfo.options
      );
    } else if (payload.properties.supportedSource.tap === 'amazon-seller') {
      tapUrl = payload.properties.supportedSource.tapUrl.replace(
        '{uri}',
        payload.properties.config.uri
      );
    } else {
      tapUrl = payload.properties.supportedSource.tapUrl;
    }

    const color =
      this.store.selectSnapshot(AccountSettingsState.colorTheme) ===
      ColorThemes.LIGHT
        ? '#FFFFFF'
        : '#212736';

    const configParameters = this.extractConfigParameters(
      payload.properties.config,
      payload.properties.exactExtraInfo?.options,
      payload.properties.supportedSource.tap
    );

    const customSettings = `"domain":"${logoUrl}","bgColor":"${color}"`;

    const state = `&state={"user_id":"${webshopID}","env_id":"${env}","flow":"p6BFCREDz","tap_name":"${tapName}","api_url":"https://client-api.hotglue.xyz/tap/auth","api_key":"WXcD9EYhmr6XdfPLBDPzt7u5G38Imd7rawChw9eo",${configParameters},${customSettings}}`;

    const redirectURI = `&redirect_uri=${callbackUrl}`;

    if (payload.properties.supportedSource.redirectUriParams) {
      return this.customEncodeUri(
        encodeURI(
          tapUrl.concat(
            state,
            redirectURI,
            payload.properties.supportedSource.redirectUriParams
          )
        )
      );
    }
    return this.customEncodeUri(encodeURI(tapUrl.concat(state, redirectURI)));
  }

  /**
   *
   * @param params
   * @param exactExtraInfo
   * @returns the exact tap url
   */
  constructExactTapUrl(params: any, exactExtraInfo: any): string {
    for (let option of exactExtraInfo) {
      if (option.value === params.auth_url) {
        return params.auth_url
          .concat('/auth?response_type=code&client_id=')
          .concat(option.client_id);
      }
    }
  }

  /**
   *
   * @param params
   * @returns a formatted string with all the connect parameters which were in a object previously
   */
  extractConfigParameters(
    params: any,
    exactExtraInfo: any,
    tap: string
  ): string {
    if (exactExtraInfo && tap === 'exact') {
      for (let option of exactExtraInfo) {
        if (option.value === params.auth_url) {
          params.client_id = option.client_id;
          params.client_secret = option.client_secret;
          params.auth_url = params.auth_url.concat('/token');
        }
      }
    }

    return Object.entries(params)
      .map(
        ([key, value]) =>
          `"${key}": ${
            key === 'marketplaces'
              ? `"${value}"`
              : typeof value === 'string'
                ? `"${value}"`
                : value
          }`
      )
      .join(',');
  }

  /**
   *
   * @param uri
   * @returns this function replace the "#" special character with its hexadecimal value correspondent to its utf-8
   */
  customEncodeUri(uri: string): string {
    return uri.replace(/#/g, '%23');
  }
}
