<div class="container">
  <div class="header">
    <div class="options-all">
      <app-checkbox
        (checkedChange)="masterToggle()"
        [checked]="isAllSelected"
        [indeterminate]="isSomeSelected"
        [label]="
          selectionModel.selected.length +
          '/' +
          totalOptionsAmount +
          ' ' +
          COMMON_STRINGS.selected
        "
        [disabled]="disabled"></app-checkbox>
    </div>
  </div>

  <div class="wrapper">
    @for (option of options; track option.value) {
      <div class="options-item" [class.options-item-disabled]="disabled">
        <app-checkbox
          class="checkbox"
          [checked]="selectionModel.isSelected(option)"
          [disabled]="disabled"
          [label]="option.key"
          (checkedChange)="toggleSelection(option)"></app-checkbox>
      </div>
    }
  </div>
</div>
