import { DataAdapter } from 'src/app/shared/adapter/adapter';

import {
  OrdersOverviewSummaries,
  OrdersOverviewSummary,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';
import {
  OrdersOverviewSummaries as OrdersOverviewSummariesV1,
  OrdersOverviewSummary as OrdersOverviewSummaryV1,
} from '../model/buy-order-v2.model';
import { STRINGS } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.strings';
import {
  ISSUE_STATUS,
  capIssuesValues,
} from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.model';

export class BuyOrdersOverviewSummaryAdapter
  implements DataAdapter<OrdersOverviewSummariesV1, OrdersOverviewSummaries>
{
  transform(data: OrdersOverviewSummariesV1): OrdersOverviewSummaries {
    const summary: OrdersOverviewSummary[] = data.ordersOverviewSummaries.map(
      (orderOverview: OrdersOverviewSummaryV1) => {
        return {
          status: orderOverview.status,
          label: STRINGS.metadata.todoList.issues.labels[orderOverview.status],
          subject: `${capIssuesValues(orderOverview.numberOfOrders)} ${
            STRINGS.metadata.todoList.issues.descriptions.subject[
              orderOverview.status
            ]
          }`,
          description:
            STRINGS.metadata.todoList.issues.descriptions.description[
              orderOverview.status
            ],
          color: ISSUE_STATUS[orderOverview.status],
          numberOfOrders: capIssuesValues(orderOverview.numberOfOrders),
          numberOfProducts: orderOverview.numberOfProducts,
          totalValue: orderOverview.totalValue,
        };
      }
    );

    return {
      ordersOverviewSummaries: summary,
    };
  }
}
