import { inject, Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  Webshop,
  WebshopPermissions,
  WebshopPermissionsOptions,
} from 'src/app/shared/models/webshop/webshop.model';
import { Webshops } from 'src/app/shared/models/webshop/webshops.model';
import { LoadFailed, Loading, SaveFailed } from '../actions/app.action';

import { RefreshNavigation } from '../actions/navigation.action';
import { LastSelectedWebshopUpdated } from '../actions/settings.action';
import {
  AllowWebshopChange,
  LoadWebshop,
  LoadWebshops,
  PreventWebshopChange,
  SaveWebshop,
  SaveWebshopAdvancedCategorization,
  SaveWebshopSettings,
  SaveWebshopSettingsCurrency,
  WebshopChanged,
  WebshopSelected,
} from '../actions/webshop.action';
import { WebshopsService } from '../api/webshops/v1/webshops.service';
import { CURRENCIES } from '../constants/global.constants';
import { MESSAGES } from '../constants/strings.constants';
import { GetAccessTokens } from 'src/app/features/dashboard/components/vizzly-dashboard/actions/vizzly-dashboard.actions';
import { formatDate } from '@angular/common';

export interface WebshopStateModel {
  webshops: Webshop[];
  selectedWebshop: Webshop;
  allowWebshopChange: boolean;
}

@State<WebshopStateModel>({
  name: 'webshopState',
  defaults: { webshops: [], selectedWebshop: null, allowWebshopChange: true },
})
@Injectable()
export class WebshopState {
  private readonly webshopService = inject(WebshopsService);

  private readonly store = inject(Store);

  @Selector()
  static webshops(state: WebshopStateModel): Webshop[] {
    return state.webshops;
  }

  @Selector()
  static selected(state: WebshopStateModel): Webshop | null {
    return state.selectedWebshop ?? null;
  }

  @Selector()
  static promotionsEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enablePromotions;
  }

  @Selector()
  static containersEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enableContainers;
  }

  @Selector()
  static multiSupplierEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enableMultiSupplier;
  }

  @Selector()
  static exportsEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enableExports;
  }

  @Selector()
  static importsEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enableImports;
  }

  @Selector()
  static productMaximumStockLevelEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enableProductMaximumStockLevel;
  }

  @Selector()
  static timeDependentStockPositionEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enableTimeDependentStockPosition;
  }

  @Selector()
  static analyticsPageEnabled(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.enableAnalyticsPage;
  }

  @Selector()
  static numberOfIntegrations(state: WebshopStateModel): number {
    return state.selectedWebshop.settings.numberOfIntegrations;
  }

  @Selector()
  static webshopCurrencySymbol(state: WebshopStateModel): string {
    const defaultCurrency = CURRENCIES[0].symbol; // EUR
    return (
      CURRENCIES.find(
        (currency: { value: string; displayName: string; symbol: string }) =>
          currency.value === state.selectedWebshop.settings.currency
      ).symbol || defaultCurrency
    );
  }

  static hasPermission(permission: string) {
    return createSelector([WebshopState], (state: WebshopStateModel) => {
      return state.selectedWebshop.settings[permission];
    });
  }

  @Selector()
  static webshopPermissions(state: WebshopStateModel): WebshopPermissions {
    return state.selectedWebshop.permissions;
  }

  @Selector()
  static webshopPermissionsOptions(
    state: WebshopStateModel
  ): WebshopPermissionsOptions {
    return state.selectedWebshop.permissionsOptions;
  }

  @Selector()
  static webshopReactingToLostSales(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.reactingToLostSales;
  }

  @Selector()
  static webshopBackorders(state: WebshopStateModel): boolean {
    return state.selectedWebshop.settings.backorders;
  }

  static hasWebshopPermission(featureKey: string) {
    return createSelector([WebshopState], (state: WebshopStateModel) => {
      if (!state.selectedWebshop.permissions.hasOwnProperty(featureKey))
        return true;

      return state.selectedWebshop.permissions[featureKey];
    });
  }

  @Selector()
  static timeZoneOffset(state: WebshopStateModel): string {
    return state.selectedWebshop.settings.timeZoneOffset;
  }

  @Selector()
  static timeZoneIdentifier(state: WebshopStateModel): string {
    return state.selectedWebshop.settings.timeZoneIdentifier;
  }

  @Selector()
  static allowWebshopChange(state: WebshopStateModel): boolean {
    return state.allowWebshopChange;
  }

  @Selector()
  static enabledAdvancedCategorization(state: WebshopStateModel): boolean {
    if (!state.selectedWebshop) return false;

    return state.selectedWebshop.settings.enableAdvancedCategorization;
  }

  @Selector()
  static serviceLevelChangesTimestampWithTimezone(
    state: WebshopStateModel
  ): Date | null {
    if (
      !state.selectedWebshop?.metadata
        .lastAdvancedCategorizationServiceLevelsPatch
    )
      return null;

    return new Date(
      formatDate(
        new Date(
          state.selectedWebshop.metadata.lastAdvancedCategorizationServiceLevelsPatch
        ),
        'yyyy-MM-dd HH:mm:ss',
        'en-US',
        state.selectedWebshop.settings.timeZoneOffset
      )
    );
  }

  @Action(LoadWebshops)
  loadWebshops(ctx: StateContext<WebshopStateModel>) {
    return this.webshopService.fetchWebshops().pipe(
      catchError(() => {
        return of({ webshops: [] });
      }),
      tap((webshops: Webshops) => {
        ctx.patchState({ webshops: webshops.webshops });
      })
    );
  }

  @Action(LoadWebshop)
  loadWebshop(ctx: StateContext<WebshopStateModel>, payload: LoadWebshop) {
    return this.webshopService.fetchWebshop(payload.webshopUuid).pipe(
      tap((webshop: Webshop) => {
        ctx.setState(
          patch({
            webshops: updateItem<Webshop>(
              _webshop => _webshop.uuid === webshop.uuid,
              webshop
            ),
            selectedWebshop: webshop,
          })
        );
      }),
      catchError(e => {
        ctx.patchState({
          selectedWebshop: null,
        });

        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  @Action(WebshopSelected)
  webshopSelected(
    ctx: StateContext<WebshopStateModel>,
    selection: WebshopSelected
  ) {
    ctx.patchState({ selectedWebshop: selection.webshop });
    return ctx.dispatch(new GetAccessTokens());
  }

  @Action(WebshopChanged)
  webshopChanged(
    ctx: StateContext<WebshopStateModel>,
    payload: WebshopChanged
  ) {
    return ctx
      .dispatch(
        new Loading(
          true,
          MESSAGES.notifications.webshopSelector.switchingWebshops
        )
      )
      .pipe(
        switchMap(() =>
          ctx.dispatch(new RefreshNavigation(payload.webshop.uuid))
        ),
        mergeMap(() => this.store.select(WebshopState.allowWebshopChange)),
        distinctUntilChanged(),
        filter((allow: boolean) => allow),
        concatMap(() =>
          ctx.dispatch(new LastSelectedWebshopUpdated(payload.webshop.uuid))
        )
      );
  }

  @Action(SaveWebshopSettings, { cancelUncompleted: true })
  updateSettings(
    ctx: StateContext<WebshopStateModel>,
    payload: SaveWebshopSettings
  ) {
    const state: WebshopStateModel = ctx.getState();

    const webshop = state.selectedWebshop;

    return this.webshopService
      .updateWebshopSettings(webshop.uuid, payload.settings)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  @Action(SaveWebshopAdvancedCategorization, { cancelUncompleted: true })
  updateAdvancedCategorization(
    ctx: StateContext<WebshopStateModel>,
    payload: SaveWebshopAdvancedCategorization
  ) {
    const state: WebshopStateModel = ctx.getState();

    const webshop = state.selectedWebshop;

    return this.webshopService
      .updateWebshopAdvancedCategorization(webshop.uuid, payload.settings)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  @Action(SaveWebshopSettingsCurrency, { cancelUncompleted: true })
  updateSettingsCurrency(
    ctx: StateContext<WebshopStateModel>,
    payload: SaveWebshopSettingsCurrency
  ) {
    const state: WebshopStateModel = ctx.getState();
    const webshop = state.selectedWebshop;

    return this.webshopService
      .updateWebshopSettings(webshop.uuid, payload.settings)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  @Action(SaveWebshop, { cancelUncompleted: true })
  updateWebshop(ctx: StateContext<WebshopStateModel>, payload: SaveWebshop) {
    const state: WebshopStateModel = ctx.getState();
    const webshop = state.selectedWebshop;

    return this.webshopService
      .updateWebshop(webshop.uuid, payload.properties)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  @Action(PreventWebshopChange, { cancelUncompleted: true })
  preventWebshopChange(ctx: StateContext<WebshopStateModel>) {
    ctx.patchState({ allowWebshopChange: false });
  }

  @Action(AllowWebshopChange, { cancelUncompleted: true })
  allowWebshopChange(ctx: StateContext<WebshopStateModel>) {
    ctx.patchState({ allowWebshopChange: true });
  }
}
