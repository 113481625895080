import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/imports/model/imports.strings';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { LocaleState } from 'src/app/core/states/locale.state';
import {
  CloseImportsDialog,
  UpdateImportList,
} from './actions/import-suppliers-dialog.action';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ImportSuppliersDialogState } from './state/import-suppliers-dialog.state';
import {
  useCSVDarkTheme,
  useCSVLightTheme,
  useCSVcustomText,
} from 'src/app/features/imports/model/imports.model';
import {
  dynamicColumnsWithStatusId,
  dynamicColumnsWithStatusName,
} from './model/import-suppliers-update-dialog.model';
import { ImportSuppliersDialogComponent } from '../../import-suppliers-dialog.component';
import { UsecsvDialogComponent } from '../../../usecsv-dialog/usecsv-dialog.component';

@Component({
  selector: 'app-import-suppliers-update-dialog',
  templateUrl: './import-suppliers-update-dialog.component.html',
  styleUrl: './import-suppliers-update-dialog.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportSuppliersUpdateDialogComponent {
  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata;

  colorTheme = select(AccountSettingsState.colorThemeLowercase);
  language = select(LocaleState.localeId);

  parentForm = new FormGroup({
    type: new FormControl('ID'),
  });

  disableSubmitButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  open: boolean = false;

  radioButtons = {
    id: true,
    name: false,
  };

  dynamicColumnsWithStatus = dynamicColumnsWithStatusId;

  useCSVDarkTheme = useCSVDarkTheme;

  useCSVLightTheme = useCSVLightTheme;

  customText = useCSVcustomText;

  importKey = select(ImportSuppliersDialogState.importKey);

  importData = (data: any) => {
    this.store.dispatch(
      new UpdateImportList(data, 'UPDATE', this.parentForm.get('type').value)
    );
  };

  onClose = () => {
    this.store.dispatch(new CloseImportsDialog());
  };

  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    private dialog: Dialog
  ) {}

  close() {
    this.dialog.open(ImportSuppliersDialogComponent, {
      width: '600px',
      disableClose: true,
    });
    this.dialogRef.close();
  }

  checkRadioType(value: string) {
    this.parentForm.get('type').setValue(value);
    this.updateRadioType();
  }

  updateRadioType() {
    if (this.parentForm.get('type').value === 'ID') {
      this.radioButtons = {
        id: true,
        name: false,
      };
      this.dynamicColumnsWithStatus = dynamicColumnsWithStatusId;
    } else if (this.parentForm.get('type').value === 'NAME') {
      this.radioButtons = {
        id: false,
        name: true,
      };
      this.dynamicColumnsWithStatus = dynamicColumnsWithStatusName;
    }
  }

  openUsecsvDialog(): void {
    this.dialog.open(UsecsvDialogComponent);
  }
}
