import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import {
  EmailStatus as EmailStatusV1,
  EmailTemplates as EmailTemplatesV1,
} from './model/emails.model';
import {
  EmailStatus,
  EmailTemplates,
  FindEmailTemplateParams,
  UpsertTemplatePayload,
} from 'src/app/shared/models/emails/v1/emails.model';
import { EmailTemplatesAdapter } from './adapters/email-templates.adapter';
import { EmailStatusAdapter } from './adapters/email-status.adapter';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class EmailsService {
  private readonly configStore = inject(ConfigStoreService);

  private readonly http = inject(HttpClient);

  private readonly store = inject(Store);

  private _findWebshopTemplates(
    replacements: UrlReplacement[],
    params: FindEmailTemplateParams
  ): Observable<EmailTemplatesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'findWebshopTemplates',
      replacements
    );

    return this.http.get<EmailTemplatesV1>(endpoint, {
      params: {
        ...params,
      },
    });
  }

  private _replaceWebshopTemplate(
    replacements: UrlReplacement[],
    payload: UpsertTemplatePayload
  ) {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'replaceAccountTemplate',
      replacements
    );

    return this.http.patch(endpoint, payload);
  }

  private _findAccountTemplates(
    replacements: UrlReplacement[],
    params: FindEmailTemplateParams
  ): Observable<EmailTemplatesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'findAccountTemplates',
      replacements
    );

    return this.http.get<EmailTemplatesV1>(endpoint, {
      params: {
        ...params,
      },
    });
  }

  private _createOrReplaceAccountTemplate(
    replacements: UrlReplacement[],
    payload: UpsertTemplatePayload
  ) {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'createOrReplaceAccountTemplate',
      replacements
    );

    return this.http.put(endpoint, payload);
  }

  private _findSupplierTemplates(
    replacements: UrlReplacement[]
  ): Observable<EmailTemplatesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'findSupplierTemplates',
      replacements
    );

    return this.http.get<EmailTemplatesV1>(endpoint);
  }

  private _createOrReplaceSupplierTemplate(
    replacements: UrlReplacement[],
    payload: UpsertTemplatePayload
  ) {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'createOrReplaceSupplierTemplate',
      replacements
    );

    return this.http.put(endpoint, payload);
  }

  private _updateDefaultTemplate(
    replacements: UrlReplacement[],
    payload: UpsertTemplatePayload
  ) {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'updateDefaultTemplate',
      replacements
    );

    return this.http.put(endpoint, payload);
  }

  private _findEmailStatus(
    replacements: UrlReplacement[]
  ): Observable<EmailStatusV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'emails',
      'v1',
      'findBuyOrderEmailStatus',
      replacements
    );

    return this.http.get<EmailStatusV1>(endpoint);
  }

  findWebshopTemplates(
    webshopUuid: string,
    params: FindEmailTemplateParams
  ): Observable<EmailTemplates> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findWebshopTemplates(replacements, params).pipe(
      switchMap(data => of(new EmailTemplatesAdapter().transform(data)))
    );
  }

  replaceWebshopTemplate(webshopUuid: string, payload: UpsertTemplatePayload) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._replaceWebshopTemplate(replacements, payload);
  }

  findAccountTemplates(
    webshopUuid: string,
    params: FindEmailTemplateParams
  ): Observable<EmailTemplates> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findAccountTemplates(replacements, params).pipe(
      switchMap(data => of(new EmailTemplatesAdapter().transform(data)))
    );
  }

  createOrReplaceAccountTemplate(
    webshopUuid: string,
    payload: UpsertTemplatePayload
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._createOrReplaceAccountTemplate(replacements, payload);
  }

  findSupplierTemplates(
    webshopUuid: string,
    supplierUuid: string
  ): Observable<EmailTemplates> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findSupplierTemplates(replacements).pipe(
      switchMap(data => of(new EmailTemplatesAdapter().transform(data)))
    );
  }

  createOrReplaceSupplierTemplate(
    webshopUuid: string,
    supplierUuid: string,
    payload: UpsertTemplatePayload
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._createOrReplaceSupplierTemplate(replacements, payload);
  }

  updateDefaultTemplate(webshopUuid: string, payload: UpsertTemplatePayload) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._updateDefaultTemplate(replacements, payload);
  }

  findEmailStatus(
    webshopUuid: string,
    buyOrderUuid: string
  ): Observable<EmailStatus> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._findEmailStatus(replacements).pipe(
      switchMap(data => of(new EmailStatusAdapter(this.store).transform(data)))
    );
  }
}
