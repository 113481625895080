import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { Supplier } from 'src/app/shared/models/suppliers/product-suppliers.model';
import { SupplyChainInformationV2StateQueries } from '../../state/supply-chain-information-v2.queries';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';

@Component({
  selector: 'app-supply-chain-active-supplier-v2',
  templateUrl: './supply-chain-active-supplier-v2.component.html',
  styleUrls: ['./supply-chain-active-supplier-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainActiveSupplierV2Component {
  suppliersPermission = select(
    PermissionQueries.hasPermission(RolesFeatureKeys.SUPPLIERS)
  );
  assembled = select(ProductDetailsV2StateQueries.isAssembled);
  loading = select(SupplyChainInformationV2StateQueries.loading);
  activeSupplier = select(SupplyChainInformationV2StateQueries.activeSupplier);
  productDeliveryTimeByPreferredSupplier = select(
    SupplyChainInformationV2StateQueries.productDeliveryTimeByPreferredSupplier
  );
  readonly METADATA = STRINGS.metadata.supplyChainInformation.activeSupplier;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  constructor(private store: Store) {}

  goToSupplier(supplierUuid: string) {
    this.store.dispatch(
      new NavigateToWithQueryParams(['suppliers', 'details'], {
        supplierUUID: supplierUuid,
      })
    );
  }
}
