<div
  class="overlay-wrapper"
  [appTooltip]="COLUMN_STRINGS.metadata.disabledHideColumn"
  [tooltipDisabled]="!disabled">
  <button
    aria-label="Hide column button"
    appOverlayItem
    [disabled]="disabled"
    (click)="hide()">
    <span class="menu-list-item-icon">
      <mat-icon svgIcon="eye-closed"></mat-icon>
    </span>
    <span>{{ COLUMN_STRINGS.metadata.hideColumn }}</span>
  </button>
</div>
