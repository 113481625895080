import {
  ChangeDetectionStrategy,
  Component,
  computed,
  OnInit,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FilterTypeOptions } from '../../model/filter.model';
import { FilterEqualsStrategy } from '../core/filter-strategies/filter-equals-strategy';
import {
  FILTER,
  FILTER_FORM,
  FilterBase,
  FilterEmitted,
  FilterEqualsGroupV2,
  FilterStrategy,
} from '../../model/filter-v2.model';
import { NgForm } from '@angular/forms';
import { FilterV2Component } from '../../filter-base-v2';

@Component({
  selector: 'app-filter-text',
  exportAs: 'filterText',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filter-text.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: FILTER, useExisting: FilterTextComponent }],
})
export class FilterTextComponent
  extends FilterV2Component
  implements FilterBase, OnInit
{
  filterForm = viewChild<NgForm>(FILTER_FORM);

  filterStrategy: FilterStrategy<FilterEqualsGroupV2> =
    new FilterEqualsStrategy(false);

  isPartialMatch = computed(() => this.filter()?.partialMatch || false);

  ngOnInit(): void {
    if (this._shouldPopulate()) {
      this._populate();
    }
  }

  clear(): void {
    this.filterStrategy.clear();
  }

  cancel(): void {
    this.canceled.emit();
  }

  apply(filtered: FilterEmitted): void {
    if (this._isAllowedToApplyFilter()) {
      this.filtered.emit({
        columnKey: this.filter().columnKey,
        optionSelected: FilterTypeOptions.EQUALS,
        subOperator: this.filter().params.subOperator,
        values: this.filterStrategy.form,
        applyAll: filtered.applyAll,
      });
    }
  }

  private _shouldPopulate(): boolean {
    return !!this.filterValues()?.value;
  }

  private _populate(): void {
    this.filterStrategy.populate(this.filterValues().value);
  }

  private _isAllowedToApplyFilter(): boolean {
    return (
      this.filterStrategy.hasValue() &&
      !this.filterStrategy.hasSameValue(this.filterValues()?.value) &&
      !this.filterForm().invalid &&
      !this.filterDisabled()
    );
  }
}
