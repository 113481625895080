import { InjectionToken, ViewContainerRef } from '@angular/core';
import { ToastStatus } from '../model/toast.model';

export const TOAST_DATA = new InjectionToken('ToastData');

export type ToastHorizontalPosition =
  | 'left'
  | 'start'
  | 'center'
  | 'right'
  | 'end';

export type ToastVerticalPosition = 'top' | 'bottom';

export class ToastConfig<T = any> {
  viewContainerRef?: ViewContainerRef;

  duration?: number = 0;

  horizontalPosition?: ToastHorizontalPosition = 'center';

  verticalPosition?: ToastVerticalPosition = 'top';

  status?: ToastStatus = ToastStatus.DEFAULT;

  data?: T | null = null;

  hasBackdrop?: boolean = true;

  panelClass?: string;

  extraClasses?: string | string[];
}
