export const LIGHT_THEME_PALLETE = {
  ink_1: '#222B36',
  ink_2: '#546B87',
  ink_3: '#98A6B7',
  shade_1: 'white',
  shade_2: '#fafbfd',
  shade_3: '#f7f9fc',
  shade_4: '#f2f5fa',
  shade_5: '#e8edf6',
  shade_6: '#e3eaf4',
  shade_7: '#dee6f2',
  shade_8: '#CCD8EB',
  blue_1: '#E8F0FB',
  blue_2: '#8CB3E9',
  blue_3: '#1966D2',
  blue_4: '#134D9E',
  blue_5: '#09244A',
  green_1: '#E9F5F0',
  green_2: '#93CBB3',
  green_3: '#269767',
  green_4: '#207E56',
  green_5: '#0D3524',
  orange_1: '#FCEFE6',
  orange_2: '#F0AF82',
  orange_3: '#E05F06',
  orange_4: '#AE4A05',
  orange_5: '#773303',
  red_1: '#F9E9E9',
  red_2: '#E29292',
  red_3: '#C62525',
  red_4: '#9E1D1D',
  red_5: '#4A0E0E',
  base_1: '#FFFFFF',
  base_2: '#202a30',

  blue_1h: '#D1E0F6',
  green_1h: '#D4EAE1',
  orange_1h: '#F9DFCD',
  red_1h: '#EECED1',

  shadow_0: 'none',
  shadow_1: '0 0 8px rgba(32, 42, 48, 0.05)',
  shadow_2: '0 0 16px rgba(32, 42, 48, 0.1)',
  shadow_3: '0 0 16px 8px rgba(32, 42, 48, 0.05)',
};

export const DARK_THEME_PALLETE = {
  ink_1: '#cbd2e2',
  ink_2: '#a5b0bd',
  ink_3: '#717983',
  shade_1: '#1b222f',
  shade_2: '#19202d',
  shade_3: '#171e2a',
  shade_4: '#141b28',
  shade_5: '#121926',
  shade_6: '#1e2531',
  shade_7: '#252b37',
  shade_8: '#414751',
  blue_1: '#1B2D4A',
  blue_2: '#3B5578',
  blue_3: '#8AB0E5',
  blue_4: '#91BAF2',
  blue_5: '#BAD4F7',
  green_1: '#1F2F30',
  green_2: '#1c503f',
  green_3: '#2eb77e',
  green_4: '#75caa6',
  green_5: '#c9f9e4',
  orange_1: '#332A2B',
  orange_2: '#7d3e15',
  orange_3: '#fa9047',
  orange_4: '#fbae79',
  orange_5: '#fddcc6',
  red_1: '#342731',
  red_2: '#702024',
  red_3: '#ff6464',
  red_4: '#ff8c8c',
  red_5: '#ffbfbf',

  blue_1h: '#1A3866',
  green_1h: '#213C33',
  orange_1h: '#493127',
  red_1h: '#4D2C34',

  shadow_0: 'none',
  shadow_1: '0 0 8px rgba(0, 0, 0, .1)',
  shadow_2: '0 0 8px rgba(0, 0, 0, 0.1)',
  shadow_3: '0 0 24px 8px rgba(0,0,0,.2)',
};
