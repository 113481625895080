import { ControlValueAccessor } from '@angular/forms';

export class FilterAbstractControl implements ControlValueAccessor {
  disabled: boolean = false;

  value = null;

  onTouched = (_v: any) => {};

  onChanged = (_v: any) => {};

  writeValue(value: unknown): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
