<div class="data-table-v2-container">
  @if (loading()) {
    <!-- Loading -->
    <app-progress-bar />

    <div class="data-table-loading-container">
      {{ loadingMessage() }}
    </div>
  } @else if (
    (!loading() && datasourceSize() === undefined && totalElements() === 0) ||
    (!loading() && datasourceSize() !== null && datasourceSize() === 0)
  ) {
    <!-- No data available warning -->
    <div class="data-table-no-data-container">
      {{ noDataMessage() }}
    </div>
  } @else {
    <!-- Table -->
    <div [ngClass]="tableContainerClasses()">
      <ng-content />
    </div>
  }

  @if (!withoutPagination()) {
    <app-paginator
      [loading]="loadingPagination()"
      [pageIndex]="page()"
      [length]="totalElements()"
      [pageSizeOptions]="pageSizeOptions()"
      [pageSize]="pageSize()"
      (paginationChanged)="paginationChanged.emit($event)"
      showFirstLastButtons />
  }
</div>
