import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
  FilterTypes,
  FilterValueType,
} from '../../data-table-v2/components/filter/model/filter.model';
import { ColumnViewOptionsV2 } from '../components/column-view-v2/model/column-view-v2.model';
import { ColumnSelectionV3 } from '../components/column-view-v3/model/column-view-v3.model';
import { FilterTypesOptionsV2 } from '../components/filter/model/filter-v2.model';
import { PageEventV2 } from '../components/paginator/paginator.component';
import { Sorted, SortedOrder } from '../components/sort/model/sort.model';
import { DatatableStateModel } from '../state/data-table.state';
import {
  PageableV2,
  ServiceRequestInfoV3Operator,
  ServiceRequestInfoV3SortBy,
} from './pageable-v2.model';
import { ColumnSelectionV2 } from './select-all-columns-v2.model';
import { Signal } from '@angular/core';

export const defaultDatatableStateModel: DatatableStateModel = {
  pagination: {
    page: 0,
    pageSizeOptions: [],
    size: 50,
  },
  sortBy: {},
  search: null,
  filterOpened: false,
  columnsGroups: {},
  filtersGroups: {},
  canRefresh: true,
};

export interface RowSelected<T> {
  data: T;
}

export type SelectableColumnType =
  | FilterTypes.FILTER_TEXT
  | FilterTypes.FILTER_NUMBER
  | FilterTypes.FILTER_DATE
  | FilterTypes.FILTER_LIST
  | FilterTypes.FILTER_TIME
  | FilterTypes.FILTER_NOT_EXISTS
  | FilterTypes.FILTER_BOOLEAN
  | FilterTypes.FILTER_NULLABLE;

export interface DatatableColumnV2Groups<T> {
  [groupKey: string]: DatatableColumnV2Group<T>;
}

export interface DatatableColumnV2Group<T> {
  groupKey: string;
  groupName: string;
  columns: {
    [columnKey: string]: T;
  };
}

export interface DatatableColumnV2ColumnGroup extends DatatableColumnV2 {
  groupKey?: string;
}

export interface DatatableColumnV2GroupList<T> {
  groupKey: string;
  groupName: string;
  columns: T[];
}

export interface DatatableColumnV2 extends ColumnViewOptionsV2 {
  type: SelectableColumnType;
  selectable: boolean;
}

export interface DatatableMetadataV2 {
  page: PageableV2;
}

export interface DatatablePagination {
  page: number;
  pageSizeOptions: number[];
  size: number;
}

export interface DatatableDataModel<T> {
  data: T;
  metadata: DatatableMetadataV2;
}

export interface FilterParam {
  key?: string;
  optionSelected: string;
  subOperator: ServiceRequestInfoV3Operator;
  values: any;
}

export interface ColumnFilterParam extends FilterParam {
  columnKey: string;
  applyAll: boolean;
  extra?: boolean;
}

export interface ColumnFilterGroupParam {
  groupKey: string;
  params: ColumnFilterParam;
}

export interface DatatableFilterParam {
  [columnKey: string]: Filter;
}

export interface DatatableSort {
  [columnKey: string]: ServiceRequestInfoV3SortBy;
}

export interface DatatableParam {
  valueType?: FilterValueType;
  operator: FilterTypesOptionsV2;
  subOperator: ServiceRequestInfoV3Operator;
  value: any;
}

export interface ColumnFilterData {
  columnKey: string;
  filter: ColumnFilter;
  filtered: boolean;
  sorted: SortedOrder | null;
}

export interface Search {
  addSearchParam(param: string): void;
  removeSearchParam(param: string): void;

  searchBy: Signal<string[]>;
}

export interface Pagination {
  onPageChange(pagination: PageEventV2): void;
}

export interface ColumnSelection {
  onColumnsSelected(columnSelection: ColumnSelectionV2): void;

  lastDisplayedColumn: Signal<boolean>;
}

export interface Sortable {
  onSort(sort: Sorted): void;
}

export interface ColumnHide {
  onColumnHidden(columnKey: string): void;
}

export interface Selection {
  masterToggle(): void;

  toggleRowSelection(isSelected: boolean, row: any, ...args: any): void;

  clearSelection(): void;

  areAllSelected: Signal<boolean>;

  areSomeSelected: Signal<boolean>;

  isRowSelected: Signal<(uuid: string) => boolean>;

  limitSelection: Signal<boolean>;
}

export interface Filtering {
  toggleFilter(): void;

  clearAllFilters(): void;

  onFiltered(params: ColumnFilterParam | ColumnFilterGroupParam): void;

  filterOpened: Signal<boolean>;

  activeFilters: Signal<Filter[]>;

  filters: Signal<Filter[] | ColumnFilterGroup[]>;
}

export interface SelectableRow<T> {
  onRowSelect(row: T): void;
}

export interface Overlay {
  openOverlay(...args: any): void;
}

export interface ColumnOrder {
  [key: string]: number;
}

export const buildSelectableColumnsList = (
  columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
) => {
  return columns
    .map(group => {
      const columns = {};

      group.columns.forEach(column => {
        columns[column.name] = {
          groupKey: group.groupKey,
          ...column,
        };
      });

      return {
        ...group,
        columns,
      };
    })
    .filter(group => Object.keys(group.columns).length > 0);
};

export const buildDisplayedColumns = (
  columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
): string[] => {
  return columns
    .map(group => group.columns.filter(column => column.checked))
    .flat()
    .map(column => column.name);
};

export const buildGroupsMap = (
  columnsGroups: DatatableColumnV2Groups<DatatableColumnV2 | Filter>
): Map<string, string> => {
  const map = new Map<string, string>();
  for (const groupKey in columnsGroups) {
    const columns = columnsGroups[groupKey].columns;
    for (const columnKey in columns) {
      map.set(columnKey, groupKey);
    }
  }
  return map;
};

export const fixTableColumnsOrder = (
  a: string,
  b: string,
  order: ColumnOrder
): number => {
  return (order[a] || Infinity) - (order[b] || Infinity);
};

export const getNewColumnSelection = (
  columnSelection: DatatableColumnV2Groups<DatatableColumnV2>,
  newSelection: ColumnSelectionV3
): DatatableColumnV2Groups<DatatableColumnV2> => {
  const columnSelectionCopy: DatatableColumnV2Groups<DatatableColumnV2> =
    JSON.parse(JSON.stringify(columnSelection));

  Object.values(newSelection.columnsGroups).forEach(group => {
    if (!columnSelectionCopy.hasOwnProperty(group.groupKey)) return;

    Object.values(group.columns).forEach(column => {
      if (
        !columnSelectionCopy[group.groupKey].columns.hasOwnProperty(column.name)
      )
        return;

      const groupColumn =
        columnSelectionCopy[group.groupKey].columns[column.name];
      const selectedGroupColumn =
        newSelection.columnsGroups[group.groupKey].columns[column.name];

      if (groupColumn.selectable) {
        groupColumn.checked = selectedGroupColumn.checked;
      }
    });
  });

  return columnSelectionCopy;
};
