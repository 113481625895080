import { ProductLink } from 'src/app/shared/components/product-details-v2/components/product-link-dialog/model/product-link.model';
import { ProductSettingsChange } from '../model/product-details-v2.model';
import { DialogRef } from '@angular/cdk/dialog';

export class SetProductUuid {
  static readonly type = '[Product Details V2] Set Product Uuid';

  constructor(public productUuid: string) {}
}

export class InitializeProductDetailsV2State {
  static readonly type = '[Product Details V2] Initialize';

  constructor(
    public productUuid: string,
    public forceInitialization: boolean = false
  ) {}
}

export class InitializeProductDetailsV2StateForOverlay {
  static readonly type = '[Product Details V2] Initialize For Overlay';

  constructor(public productUuid: string) {}
}

export class LoadProduct {
  static readonly type = '[Product Details V2] Load Product';
}

export class LoadProductAvailablePredecessors {
  static readonly type =
    '[Product Details V2] Load Product Available Predecessor';

  constructor(public search: string) {}
}

export class LinkPredecessor {
  static readonly type = '[Product Details V2] Link Predecessor';

  constructor(
    public link: ProductLink,
    public dialogRef: DialogRef<any>
  ) {}
}

export class ResetProductDetails {
  static readonly type = '[Product Details V2] Reset';
}

export class UpdateProductIgnored {
  static readonly type = '[Product Details V2] Update Product Ignored';

  constructor(public change: ProductSettingsChange<boolean>) {}
}

export class UpdateProductMinimumStockLevel {
  static readonly type =
    '[Product Details V2] Update Product Minimum Stock Level';

  constructor(public change: ProductSettingsChange<number>) {}
}

export class UpdateProductMaximumStock {
  static readonly type = '[Product Details V2] Update Product Maximum Stock';

  constructor(public change: ProductSettingsChange<number>) {}
}

export class UpdateProductPhaseOut {
  static readonly type = '[Product Details V2] Update Product Phase Out';

  constructor(public change: ProductSettingsChange<boolean>) {}
}

export class UpdateProductPhaseOutEndDate {
  static readonly type =
    '[Product Details V2] Update Product Phase Out End Date';

  constructor(public change: ProductSettingsChange<boolean>) {}
}

export class UpdateProductResumePurchaseDate {
  static readonly type =
    '[Product Details V2] Update Product Resume Purchase Date';

  constructor(public change: ProductSettingsChange<string>) {}
}

export class UpdateManualServiceLevel {
  static readonly type = '[Product Details V2] Update Manual Service Level';

  constructor(public change: ProductSettingsChange<number>) {}
}

export class CancelProductChanges {
  static readonly type = '[Product Details V2] Cancel Changes';
}

export class SaveProductChanges {
  static readonly type = '[Product Details V2] Save';
}

export class ManageProductCompositionsTab {
  static readonly type = '[Product Details V2] Manage Product Compositions Tab';

  constructor(public payload: { disable: boolean }) {}
}

export class ManageProductPartsTab {
  static readonly type = '[Product Details V2] Manage Product Parts Tab';

  constructor(public payload: { disable: boolean }) {}
}

export class GoToProduct {
  static readonly type = '[Product Details V2] Go to Product';

  constructor(public productUuid: string) {}
}

export class SetProductIgnoredTemplate {
  static readonly type = '[Product Details V2] Set Product Ignored Template';
}

export class UpdateNotes {
  static readonly type = '[Product Details V2] Update Notes';

  constructor(public change: string) {}
}

export class SaveNotes {
  static readonly type = '[Product Details V2] Save Notes';
}

export class CancelNotes {
  static readonly type = '[Product Details V2] Cancel Notes';
}
