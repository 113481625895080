import { WebshopProduct } from 'src/app/shared/models/products/v3/products.model';
import { WebshopProductSettings } from '../../../model/products-v2.model';

export interface ProductSettingsChange<T> {
  value: T;
  valid: boolean;
}

export interface Overview {
  loading: boolean;
  failed: boolean;
  product: WebshopProduct;
}

export const defaultOverview: Overview = {
  loading: false,
  failed: false,
  product: {
    uuid: null,
    id: null,
    category: null,
    name: null,
    sku: null,
    eanCode: null,
    price: null,
    serviceLevel: null,
    supplyTime: null,
    demandPerMonth: null,
    stockOnHand: null,
    minimumStock: null,
    assembled: false,
    ignored: false,
    notBeingBought: false,
    resumingPurchase: null,
    novel: false,
    deadStock: null,
    maximumStock: null,
    articleCode: null,
    manualServiceLevel: null,
    notes: null,
    createdAtRemote: null,
    issues: [],
    xyzCategory: null,
    extras: {
      tags: {
        iconTags: [],
        iconTextTags: [],
        textTags: [],
      },
    },
  },
};

export const defaultWebshopProductSettings: WebshopProductSettings = {
  notBeingBought: false,
  notBeingBoughtHasEndDate: false,
  minPurchaseDate: null,
  resumePurchaseDate: null,
  minimumStockLevel: 0,
  maximumStock: 1,
  ignored: false,
  manualServiceLevel: 0,
  notes: null,
};

export const defaultWebshopProductStockErrorSettings: WebshopProductSettings = {
  notBeingBought: false,
  notBeingBoughtHasEndDate: false,
  minPurchaseDate: null,
  resumePurchaseDate: null,
  minimumStockLevel: 10,
  maximumStock: 5,
  ignored: false,
  manualServiceLevel: 0,
  notes: null,
};
