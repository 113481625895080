import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthClientConfig, AuthService } from '@auth0/auth0-angular';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { concatMap, Observable } from 'rxjs';
import { Account } from 'src/app/shared/models/account/account.model';
import { environment } from 'src/environments/environment';

import {
  AuthenticationUpdated,
  LoginFailed,
  Logout,
} from '../actions/authentication.action';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  authenticated = false;
  user: Account = null;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private auth: AuthService,
    private actions$: Actions,
    private store: Store,
    private authConfig: AuthClientConfig
  ) {
    this.authConfig.set({
      domain: environment.domain,
      clientId: environment.client_id,
      authorizationParams: {
        redirect_uri:
          window.location.origin +
          environment.base_path +
          this.localeId +
          environment.redirect_uri,
        audience: environment.audience,
      },
      useRefreshTokens: true,
      httpInterceptor: { allowedList: environment.allowedList },
    });
    this.auth.error$.subscribe(_error =>
      this.store.dispatch(new LoginFailed())
    );

    this.auth.user$.subscribe(user => {
      if (user === null) {
        this.user = null;
        this.store.dispatch(new AuthenticationUpdated(false));
        return;
      }

      let changePassword = false;
      if (user.sub.split('|')[0] === 'auth0') {
        changePassword = true;
      }

      this.user = {
        email: user.email,
        id: user.sub.split('|')[1],
        name: user.name,
        picture: user.picture,
        changePassword: changePassword,
      };

      this.store.dispatch(new AuthenticationUpdated(true));
    });

    this.auth.user$
      .pipe(concatMap(() => this.auth.isAuthenticated$))
      .subscribe(authenticated => {
        this.authenticated = authenticated;

        this.store.dispatch(new AuthenticationUpdated(authenticated));
      });

    /**
     * Listens for logout actions.
     */
    this.actions$.pipe(ofActionDispatched(Logout)).subscribe(_ => {
      this.logout();
    });
  }

  login(redirectPath: string = 'webshops') {
    this.auth.loginWithRedirect({
      fragment: 'anchor',
      appState: { target: redirectPath },
      authorizationParams: {
        redirect_uri:
          window.location.origin +
          environment.base_path +
          this.localeId +
          environment.redirect_uri,
        audience: environment.audience,
      },
    });
  }

  isAuthenticated(): Observable<boolean> {
    return this.auth.isAuthenticated$;
  }

  isUser(): Observable<any> {
    return this.auth.user$;
  }

  logout() {
    this.auth.logout({
      logoutParams: {
        returnTo:
          window.location.origin +
          environment.base_path +
          this.localeId +
          environment.return_to,
      },
    });
  }
}
