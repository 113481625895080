<div class="export-container">
  <button
    aria-label="Purchase confirmation export csv button"
    data-intercom-target="9c25dfbc-13e9-402c-99b7-384581ebf5ad"
    appButton
    variant="secondary"
    (click)="exportAsCsv()"
    [disabled]="saving$ | async">
    {{ EXPORTS_STRINGS.exportCSV }}
  </button>

  <button
    aria-label="Purchase confirmation export xlsx button"
    data-intercom-target="ce46e664-c875-4bb4-a1e5-52b40bda9c35"
    appButton
    variant="secondary"
    (click)="exportAsXlsx()"
    [disabled]="saving$ | async">
    {{ EXPORTS_STRINGS.exportXLSX }}
  </button>
</div>
