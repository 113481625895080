export class UpdateImportList {
  static readonly type = '[Imports Suppliers] Update Import List';

  constructor(
    public data: any,
    public operation: string,
    public type: string
  ) {}
}

export class ResetImportSuppliersData {
  static readonly type = '[Imports Suppliers] Reset Import Suppliers Data';
}

export class CloseImportsDialog {
  static readonly type = '[Imports Suppliers] Close Imports Dialog';
}
