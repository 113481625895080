import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { OPTION_GROUP } from '../../model/dropdown.model';

@Component({
  selector: 'app-dropdown-option-group',
  templateUrl: './dropdown-option-group.component.html',
  styleUrls: ['./dropdown-option-group.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: OPTION_GROUP, useExisting: DropdownOptionGroupComponent },
  ],
})
export class DropdownOptionGroupComponent {
  @Input()
  disabled: boolean;
}
