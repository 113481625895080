import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Host,
  Input,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';

import { ExpansionPanelJobsComponent } from '../../expansion-panel-jobs.component';
import {
  EXPANSION_PANEL_TOGGLE,
  TogglePosition,
} from '../../model/expansion-panel.model';

@Component({
  selector: 'app-expansion-panel-jobs-header',
  templateUrl: './expansion-panel-jobs-header.component.html',
  styleUrls: ['./expansion-panel-jobs-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionPanelJobsHeaderComponent {
  @ContentChild(EXPANSION_PANEL_TOGGLE)
  customToggle: ElementRef<HTMLElement>;

  @Input({ transform: booleanAttribute })
  hideToggle: boolean = false;

  @Input()
  togglePosition: TogglePosition = 'end';

  constructor(@Host() public panel: ExpansionPanelJobsComponent) {}

  get containerClasses(): string[] {
    const iconAlignment: string =
      this.togglePosition === 'start' || this.togglePosition === 'left'
        ? 'expansion-panel-jobs-header-container-start'
        : 'expansion-panel-jobs-header-container-end';

    return ['expansion-panel-jobs-header-container', iconAlignment];
  }

  get tabIndex(): string {
    return this.panel.disabled ? '-1' : '0';
  }
}
