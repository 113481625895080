import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import {
  HideTopOverview,
  ShowTopOverview,
} from '../top-overview/actions/top-overview.action';

@Component({
  selector: 'app-top-overview-icon',
  templateUrl: './top-overview-icon.component.html',
  styleUrls: ['./top-overview-icon.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopOverviewIconComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  readonly MESSAGES = MESSAGES;

  constructor(
    private store: Store,
    private actions: Actions
  ) {}

  panelOpenState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  ngOnInit(): void {
    this.actions
      .pipe(ofActionDispatched(ShowTopOverview), takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.panelOpenState$.value) {
          this.panelOpenState$.next(false);
        } else {
          this.panelOpenState$.next(true);
        }
      });

    this.actions
      .pipe(ofActionDispatched(HideTopOverview), takeUntil(this.destroy$))
      .subscribe(() => {
        this.panelOpenState$.next(true);
      });
  }

  showTopOverview() {
    this.store.dispatch(new ShowTopOverview());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
