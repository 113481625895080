<app-dialog-container
  ><div appDialogHeader>
    <h1 appDialogTitle>{{ DIALOG_STRINGS.title }}</h1>

    <button
      aria-label="Add new supplier dialog close button"
      (click)="close()"
      class="close-button">
      <mat-icon svgIcon="cross" class="close-icon"></mat-icon>
    </button>
  </div>

  <form #form="ngForm">
    <div appDialogBody>
      <h3 class="sub-title">{{ DIALOG_STRINGS.subtitle }}</h3>

      <div
        class="supplier-input-section"
        data-intercom-target="5df5c80d-0007-4ce8-a09e-d3d835a20356">
        <label for="supplierName" class="label"
          >{{ DIALOG_STRINGS.supplierName }}
          <span appRequiredLabel>*</span></label
        >

        <div class="fields-input-container">
          <input
            appInput
            variant="filled"
            name="supplierName"
            [placeholder]="DIALOG_STRINGS.supplierName"
            [(ngModel)]="supplierName"
            required />
        </div>
      </div>

      <div
        class="supplier-input-section"
        data-intercom-target="eb83829e-5824-40b6-bd8f-e8c444651094">
        <label for="email" class="label">{{ DIALOG_STRINGS.email }}</label>

        <div class="fields-input-container" data-intercom-target="">
          <input
            #emailInput="ngModel"
            appInput
            variant="filled"
            name="email"
            type="email"
            [placeholder]="DIALOG_STRINGS.email"
            [(ngModel)]="email"
            [pattern]="EMAIL_REGEX" />

          @if (emailInput.invalid) {
            <div class="error">
              <mat-icon svgIcon="warning-rounded"></mat-icon>
              <p>{{ DIALOG_STRINGS.invalidEmail }}</p>
            </div>
          }
        </div>
      </div>
    </div>

    <div appDialogActions class="confirmation-buttons">
      <app-checkbox
        data-intercom-target="5507caf1-2a86-4e4b-8a83-d47e379138df"
        name="addAnother"
        [label]="DIALOG_STRINGS.addAnother"
        [(checked)]="addAnother"></app-checkbox>

      <div class="action-buttons-container">
        <button
          data-intercom-target="2f919be3-6ab4-4415-a943-9017bda69320"
          aria-label="Add new supplier dialog cancel button"
          appButton
          variant="tertiary"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          data-intercom-target="88d0795d-0e69-421f-bedd-e3d33641c98a"
          aria-label="Add new supplier dialog confirm button"
          appButton
          variant="primary"
          [disabled]="form.invalid"
          (click)="createSupplier(); form.reset()">
          {{ COMMON_STRINGS.confirm }}
        </button>
      </div>
    </div>
  </form>
</app-dialog-container>
