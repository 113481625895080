import { FormControl } from '@angular/forms';

export interface OrderlineStaticInfo {
  uuid: string;
  name: string;
  productUuid: string;
  proposed: boolean;
  stockPosition: number;
}

export enum OrderlineRelatedTables {
  PROPOSED,
  AVAILABLE,
}

export type OrderlineRelatedTable =
  | OrderlineRelatedTables.PROPOSED
  | OrderlineRelatedTables.AVAILABLE;

export enum OrderlineOrderTypes {
  PROPOSED,
  CONCEPT,
  MANUAL,
}

export type OrderlineOrderType =
  | OrderlineOrderTypes.PROPOSED
  | OrderlineOrderTypes.CONCEPT
  | OrderlineOrderTypes.MANUAL;

export interface OverlayProductSettings {
  notBeingBought: FormControl<boolean>;
  notBeingBoughtIndefinitive: FormControl<boolean>;
  resumePurchaseDate: FormControl<Date>;
  minimumStock: FormControl<number>;
  ignored: FormControl<boolean>;
}
