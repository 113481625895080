import { InjectionToken } from '@angular/core';
import { TabTemplateComponent } from '../components/tab-template/tab-template.component';
import { TabsGroupTemplateComponent } from '../components/tabs-group-template/tabs-group-template.component';

export const TAB_TEMPLATE = new InjectionToken<TabTemplateComponent>(
  'Tab Template'
);

export const TAB_GROUP_TEMPLATE =
  new InjectionToken<TabsGroupTemplateComponent>('Tab Group');
