import { Injectable } from '@angular/core';
import {
  ToastHorizontalPosition,
  ToastVerticalPosition,
} from 'src/app/shared/components/design-system/toast/core/toast-config';
import { ToastStatus } from 'src/app/shared/components/design-system/toast/model/toast.model';
import { Toast } from 'src/app/shared/components/design-system/toast/toast';
import { MESSAGES } from '../constants/strings.constants';

export interface SnackBarConfig {
  duration: number;
  horizontalPosition: ToastHorizontalPosition;
  verticalPosition: ToastVerticalPosition;
  hasBackdrop: boolean;
  panelClass?: string;
}
@Injectable({ providedIn: 'root' })
export class NotificationCenterService {
  private readonly STRINGS = MESSAGES;

  private readonly COMMON_DISMISS_STRING = this.STRINGS.notifications.dismiss;

  private toastBaseConfig: SnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
    hasBackdrop: false,
    panelClass: 'toast-panel',
  };

  constructor(private toast: Toast) {}

  /* Styles warnings */
  showToast(msg: string): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
    });
  }

  showToastInfo(msg: string): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
      status: ToastStatus.INFO,
    });
  }

  showToastWithDuration(msg: string, duration: number = null): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
      duration,
    });
  }

  showToastWarning(msg: string): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
      status: ToastStatus.WARNING,
    });
  }

  showToastError(msg: string): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
      status: ToastStatus.ERROR,
    });
  }

  showToastSuccess(msg: string): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
      status: ToastStatus.SUCCESS,
    });
  }

  showToastCustomClasses(msg: string, classes: string[]): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
      extraClasses: [...classes],
    });
  }

  showToastWithAction(msg: string, action: string): void {
    this.toast.open(msg, action, {
      ...this.toastBaseConfig,
    });
  }

  showToastWithPosition(
    msg: string,
    verticalPosition: ToastVerticalPosition,
    horizontalPosition: ToastHorizontalPosition
  ): void {
    this.toast.open(msg, this.COMMON_DISMISS_STRING, {
      ...this.toastBaseConfig,
      verticalPosition,
      horizontalPosition,
    });
  }
}
