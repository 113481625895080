<app-dialog-container>
  <div appDialogHeader>
    <mat-icon svgIcon="arrow-upload" class="menu-icon"></mat-icon>
    <h1 appDialogTitle>{{ STRINGS.importDialogTitle }}</h1>
    <button
      aria-label="Imports dialog close button"
      (click)="close()"
      class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div appDialogBody>
    <div class="imports-container">
      <div class="imports-input-section">
        <div
          class="input-data-container"
          [formGroup]="parentForm"
          appRadioButtonGroup>
          <div class="import-suppliers-button-container">
            <button
              aria-label="Imports dialog cancel button"
              [class]="
                radioButtons.insert
                  ? ' import-suppliers-button-selected'
                  : 'import-suppliers-button'
              "
              appButton
              variant="tertiary"
              (click)="checkRadioType('INSERT')">
              <div class="import-suppliers-button-content">
                <div class="import-suppliers-button-title-container">
                  <mat-icon
                    [class]="
                      radioButtons.insert
                        ? 'import-suppliers-button-icon-selected'
                        : 'import-suppliers-button-icon'
                    "
                    svgIcon="store"></mat-icon>
                  <span class="import-suppliers-button-title">{{
                    STRINGS.buttons.newSuppliers
                  }}</span>
                </div>

                <span class="import-suppliers-button-description">{{
                  STRINGS.buttons.newSuppliersDescription
                }}</span>
              </div>
            </button>

            <button
              aria-label="Imports dialog cancel button"
              [class]="
                radioButtons.update
                  ? ' import-suppliers-button-selected'
                  : 'import-suppliers-button'
              "
              appButton
              variant="tertiary"
              (click)="checkRadioType('UPDATE')">
              <div class="import-suppliers-button-content">
                <div class="import-suppliers-button-title-container">
                  <mat-icon
                    [class]="
                      radioButtons.update
                        ? 'import-suppliers-button-icon-selected'
                        : 'import-suppliers-button-icon'
                    "
                    svgIcon="clock-arrow-left"></mat-icon>
                  <span class="import-suppliers-button-title">{{
                    STRINGS.buttons.updateSuppliers
                  }}</span>
                </div>
                <span class="import-suppliers-button-description">{{
                  STRINGS.buttons.updateSuppliersDescription
                }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div appDialogActions>
    <div class="action-buttons-container">
      <button
        aria-label="Imports dialog cancel button"
        appButton
        variant="tertiary"
        class="optiply-tertiary-btn"
        (click)="close()">
        {{ COMMON_STRINGS.cancel }}
      </button>

      <button
        *ngIf="radioButtons.update"
        aria-label="Imports dialog import button"
        appButton
        variant="primary"
        class="optiply-primary-btn"
        (click)="openImportsDialog()">
        {{ COMMON_STRINGS.next }}
      </button>

      <usecsv-button
        *ngIf="radioButtons.insert"
        [importerKey]="importKey$ | async"
        [dynamicColumns]="dynamicColumnsWithStatus"
        [onData]="importData"
        [batchSize]="2000"
        [acceptedFileFormats]="['csv']"
        [theme]="
          (colorTheme$ | async) === 'dark' ? useCSVDarkTheme : useCSVLightTheme
        "
        [customText]="{
          importedRows: {
            text: '{{rows_count}} ' + STRINGS.useCSVCustomText
          }
        }"
        [defaultLanguage]="(language$ | async) === 'en-US' ? 'en' : 'nl'"
        [onClose]="onClose"
        importerDisplay="inline">
        <button
          customUsecsvButton
          aria-label="Imports dialog import button"
          appButton
          variant="primary"
          (click)="openUsecsvDialog()">
          {{ COMMON_STRINGS.next }}
        </button></usecsv-button
      >
    </div>
  </div>
</app-dialog-container>
