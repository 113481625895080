import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectionPositionPair,
  HorizontalConnectionPos,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  BehaviorSubject,
  delay,
  filter,
  fromEvent,
  map,
  Subject,
  takeUntil,
} from 'rxjs';
import {
  DEFAULT_OVERLAY_CONFIG,
  OverlayXPosition,
  OverlayYPosition,
} from './model/overlay-container.model';

@Component({
  selector: 'app-overlay-container[relativeElement]',
  templateUrl: './overlay-container.component.html',
  styleUrls: ['./overlay-container.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OverlayContainerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /* Elements refs */
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;

  /* Inputs */
  @Input() relativeElement: CdkOverlayOrigin;

  @Input() minWidth: number = DEFAULT_OVERLAY_CONFIG.minWidth;

  @Input() maxWidth: number = DEFAULT_OVERLAY_CONFIG.maxWidth;

  @Input() minHeight: number = DEFAULT_OVERLAY_CONFIG.minHeight;

  @Input() hasBackdrop: boolean = DEFAULT_OVERLAY_CONFIG.hasBackdrop;

  @Input() hasDarkBackdrop: boolean = DEFAULT_OVERLAY_CONFIG.hasDarkBackdrop;

  @Input() backdropClass: string | string[] =
    DEFAULT_OVERLAY_CONFIG.backdropClasses;

  @Input() overlayContainerClass: string | string[] =
    DEFAULT_OVERLAY_CONFIG.overlayContainerClasses;

  @Input() overlayFlexibleDimensions: boolean =
    DEFAULT_OVERLAY_CONFIG.overlayFlexibleDimensions;

  @Input() disableClose: boolean = DEFAULT_OVERLAY_CONFIG.disableClose;

  @Input() lockPosition: boolean = DEFAULT_OVERLAY_CONFIG.lockPosition;

  @Input() positionX: OverlayXPosition = DEFAULT_OVERLAY_CONFIG.positionX;

  @Input() positionY: OverlayYPosition = DEFAULT_OVERLAY_CONFIG.positionY;

  @Output()
  clickedOutside: EventEmitter<void> = new EventEmitter<void>();

  /* Streams */
  isOpen$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  positions: ConnectionPositionPair[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    if (this.relativeElement === null || this.relativeElement === undefined) {
      throw new Error(
        'A relative element is required to trigger the overlay (cdkOverlayOrigin).'
      );
    }

    this._setPosition();
  }

  ngAfterViewInit(): void {
    fromEvent(this.relativeElement.elementRef.nativeElement, 'click')
      .pipe(delay(100), takeUntil(this.destroy$))
      .subscribe(() => {
        this.openOverlay();
      });

    this.overlay.overlayOutsideClick
      .pipe(
        map(event => event.target),
        filter(this._isNotCalendarNorOverlayEvent),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.outsideClick();

        this.closeOverlay();
      });
  }

  /**
   * Changes the observable value to false (closes overlay).
   */
  closeOverlay(): void {
    this.isOpen$.next(false);
  }

  /**
   * Changes the observable value to true (opens overlay).
   */
  openOverlay(): void {
    this.isOpen$.next(true);
  }

  outsideClick() {
    this.clickedOutside.emit();
  }

  get backdropClasses(): string[] {
    const noDarkBackdrop = !this.hasDarkBackdrop
      ? 'invisible-backdrop'
      : 'showing-backdrop';
    const userBackdropClass = Array.isArray(this.backdropClass)
      ? [...this.backdropClass]
      : [this.backdropClass];

    return [...userBackdropClass, noDarkBackdrop];
  }

  private _setPosition(): void {
    const [
      originX,
      originFallbackX,
      alternativeOriginX,
      alternativeOriginFallbackX,
    ]: HorizontalConnectionPos[] = this._getXPosition();

    const [originY, originFallbackY]: VerticalConnectionPos[] =
      this._getYPosition();

    this.positions = [
      new ConnectionPositionPair(
        { originX, originY },
        { overlayX: originFallbackX, overlayY: originFallbackY }
      ),
      new ConnectionPositionPair(
        { originX: alternativeOriginX, originY },
        {
          overlayX: alternativeOriginFallbackX,
          overlayY: originFallbackY,
        }
      ),
    ];
  }

  private _getXPosition(): HorizontalConnectionPos[] {
    switch (this.positionX) {
      case 'before':
        return ['start', 'start', 'end', 'end'];
      case 'center':
        return ['center', 'end', 'center', 'start'];
      case 'after':
        return ['end', 'end', 'start', 'start'];
      default:
        return ['end', 'end', 'start', 'start'];
    }
  }

  private _getYPosition(): VerticalConnectionPos[] {
    switch (this.positionY) {
      case 'above':
        return ['top', 'bottom'];
      case 'center':
        return ['center', 'center'];
      case 'below':
        return ['bottom', 'top'];
      default:
        return ['bottom', 'top'];
    }
  }

  /**
   * Determines whether its not an event from a calendar nor the overlay itself
   * @param eventElement HTMLElement
   * @returns true if its not calendar event nor the overlay itself
   */
  private _isNotCalendarNorOverlayEvent(eventElement: HTMLElement): boolean {
    if (!eventElement || typeof eventElement.className !== 'string') {
      return true;
    }

    return (
      !eventElement.className.includes('mat-calendar') &&
      !eventElement.className.includes('cdk-overlay-backdrop')
    );
  }

  ngOnDestroy(): void {
    this.isOpen$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }
}
