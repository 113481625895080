<div [class.advanced-categorization]="advancedCategorization()">
  <div class="category-wrapper">
    <span [ngClass]="categoryClasses()">{{ category() | uppercase }}</span>
  </div>

  @if (advancedCategorization()) {
    <div class="category-wrapper">
      <span [ngClass]="advancedCategoryClasses()">{{
        advancedCategory() | uppercase
      }}</span>
    </div>
  }
</div>
