import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  SupplierManualOrder,
  SuppliersManualOrder,
} from 'src/app/shared/models/suppliers/supplier.model';
import { SuppliersManualOrder as SuppliersManualOrderV1 } from '../model/suppliers-manual-order.model';

export class SuppliersManualOrderAdapter
  implements DataAdapter<SuppliersManualOrderV1, SuppliersManualOrder>
{
  transform(data: SuppliersManualOrderV1): SuppliersManualOrder {
    const suppliers: SupplierManualOrder[] = data.suppliers.map(supplier => {
      const newSupplier: SupplierManualOrder = {
        id: supplier.id,
        uuid: supplier.uuid,
        name: supplier.name,
      };
      return newSupplier;
    });
    return { suppliers };
  }
}
