import { Selector } from '@ngxs/store';
import { RESULTS_SELECTION_LIMIT_AMOUNT } from 'src/app/core/constants/global.constants';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { ProductsPromotions } from 'src/app/shared/models/promotion/promotion.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import {
  AddProductsPromotionState,
  AddProductsPromotionStateModel,
} from './add-products-to-promotion.state';
import {
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  buildDisplayedColumns,
  buildSelectableColumnsList,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { COLUMNS_ORDER } from '../../add-products-to-promotion-data-table/model/add-products-to-promotion-data-table.model';

export class AddProductsPromotionStateQueries {
  @Selector([AddProductsPromotionState])
  static loading(state: AddProductsPromotionStateModel): boolean {
    return state.loading;
  }

  @Selector([AddProductsPromotionState.products])
  static products(products: ProductsPromotions[]): ProductsPromotions[] {
    return products;
  }

  @Selector([AddProductsPromotionState])
  static totalElements(state: AddProductsPromotionStateModel): number {
    return state.page.totalElements;
  }

  @Selector([AddProductsPromotionState])
  static featuredColumns(
    state: AddProductsPromotionStateModel
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        return {
          ...group,
          columns: Object.values(group.columns),
        };
      }
    );
  }

  @Selector([AddProductsPromotionStateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([AddProductsPromotionStateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([AddProductsPromotionState])
  static lastDisplayedColumn(_state: AddProductsPromotionStateModel) {
    return (columns: string[]): boolean => columns.length === 1;
  }

  @Selector([AddProductsPromotionState])
  static page(state: AddProductsPromotionStateModel): Pageable {
    return state.page;
  }

  @Selector([AddProductsPromotionState])
  static pageSizeOptions(state: AddProductsPromotionStateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([AddProductsPromotionState])
  static pageSize(state: AddProductsPromotionStateModel): number {
    return state.pagination.size;
  }

  @Selector([AddProductsPromotionState])
  static currentPage(state: AddProductsPromotionStateModel): number {
    return state.pagination.page;
  }

  @Selector([AddProductsPromotionState])
  static search(state: AddProductsPromotionStateModel): string[] {
    return state.search || [];
  }

  @Selector([AddProductsPromotionState.selection])
  static isProductSelected(selection: TableSelection<{ uuid: string }>) {
    const selectedUuids = new Set(Object.keys(selection));

    return (uuid: string) => selectedUuids.has(uuid);
  }

  @Selector([
    AddProductsPromotionState.selection,
    AddProductsPromotionStateQueries.totalElements,
  ])
  static areAllSelected(
    selection: TableSelection<{ uuid: string }>,
    totalElements: number
  ): boolean {
    return !!totalElements && Object.keys(selection).length === totalElements;
  }

  @Selector([
    AddProductsPromotionState.selection,
    AddProductsPromotionStateQueries.totalElements,
  ])
  static areSomeSelected(
    selection: TableSelection<{ uuid: string }>,
    totalElements: number
  ): boolean {
    return (
      !!totalElements &&
      !!Object.keys(selection).length &&
      Object.keys(selection).length < totalElements
    );
  }

  @Selector([AddProductsPromotionState.selection])
  static selectedAmount(selection: TableSelection<{ uuid: string }>): number {
    return Object.keys(selection).length;
  }

  @Selector([AddProductsPromotionStateQueries.selectedAmount])
  static limitSelection(selectedAmount: number): boolean {
    return selectedAmount >= RESULTS_SELECTION_LIMIT_AMOUNT;
  }
}
