import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { Store } from '@ngxs/store';

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/exports-v2/model/exports-v2.strings';
import {
  ExportFileType,
  ExportFileTypes,
} from '../../models/exports/exports-v2.model';
import { ConfirmExport } from './actions/exports-dialog.action';

@Component({
  selector: 'app-exports-dialog',
  templateUrl: './exports-dialog.component.html',
  styleUrls: ['./exports-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportsDialogComponent {
  fileName: string | null = null;

  fileFormat: ExportFileType = ExportFileTypes.CSV;

  exportOnly: 'selected' | 'fullTable' = 'fullTable';

  readonly EXPORTS_FORMATS = ExportFileTypes;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly EXPORTS_DIALOG_STRINGS = STRINGS.metadata.exportsDialog;

  readonly EXPORTS_ACTIONS_STRINGS = STRINGS.metadata.actions;

  readonly EXPORTS_FORMATS_STRINGS = STRINGS.metadata.formats;

  constructor(
    @Inject(DIALOG_DATA) public data,
    private store: Store,
    public dialogRef: DialogRef
  ) {}

  createExport(): void {
    this.store.dispatch(
      new ConfirmExport({
        fileName: this.fileName,
        fileType: this.fileFormat,
        onlySelected: this.exportOnly === 'selected',
      })
    );

    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  get haveSelectedItems(): boolean {
    return this.data?.include?.length > 0 || false;
  }
}
