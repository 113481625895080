<div class="dropdown-list-container">
  <span
    role="presentation"
    class="dropdown-option-group-container"
    [class.dropdown-option-group-disabled]="disabled">
    <ng-content></ng-content>
  </span>

  <ng-content select="app-dropdown-option"></ng-content>
</div>
