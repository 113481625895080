import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Job } from '../model/integrations.model';

export class OptiplyJobsAdapter implements DataAdapter<any, Job[]> {
  transform(jobs: any): Job[] {
    return jobs.map(job => {
      return {
        name: job.job_name,
        status: job.status,
        startDate: job.start_time,
        lastUpdateDate: job.last_updated,
        tenant: job.tenant,
      };
    });
  }
}
