import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, search: string): string {
    const convertedToStr = String(value);
    return convertedToStr.replace(
      new RegExp(
        '(?![^&;]+;)(?!<[^<>]*)(' + search + ')(?![^<>]*>)(?![^&;]+;)',
        'gi'
      ),
      '<strong class="matched-search">$1</strong>'
    );
  }
}
