import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { ProposedBuyOrderlines } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import {
  OrdersOverviewSummaries,
  ProposedBuyOrders,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { ProposedBuyOrderlinesOverviewV2Adapter } from './adapters/proposed-buy-orderlines-overview-v2.adapter';
import { ProposedBuyOrderlinesV2Adapter } from './adapters/proposed-buy-orderlines-v2.adapter';
import { ProposedBuyOrdersV2Adapter } from './adapters/proposed-buy-orders-v2.adapter';
import { ProposedSessionAdapter } from './adapters/proposed-session.adapter';
import { PurchaseAdviceAdapter } from './adapters/purchase-advice.adapter';
import { ProposedBuyOrders as ProposedBuyOrdersV1 } from './model/proposed-buy-order-v2-model';
import {
  ProposedBuyOrderlinesOverview as ProposedBuyOrderlinesOverviewV1,
  ProposedBuyOrderlinesOverviewPayload,
  PurchaseAdvicePayload,
} from './model/proposed-buy-orderlines-overview-v2.model';
import {
  OrderPlacing,
  ProposedBuyOrderlines as ProposedBuyOrderlinesV1,
  ProposedSession,
  PurchaseAdvice as PurchaseAdviceV1,
  UpdateProposedSessionForFactorPayload,
} from './model/proposed-buy-orderlines-v2.model';
import { Issues as IssuesV1 } from '../../issues/model/issues.model';
import {
  BuyOrderExportSettings as BuyOrderExportSettingsV1,
  OrdersOverviewSummaries as OrdersOverviewSummariesV1,
  SaveExportSettingsProperties,
  SendEmailProperties,
  SessionContext,
} from './model/buy-order-v2.model';
import { PlacedOrder } from 'src/app/shared/models/purchase/v2/purchase-v2.model';
import { BuyOrdersV2ExportsSettingsAdapter } from './adapters/buy-orders-v2-exports-settings.adapter';
import { BuyOrderExportSettings } from 'src/app/shared/models/buy-orders/v2/buy-orders-exports-settings.model';
import { BuyOrdersOverviewSummaryAdapter } from './adapters/buy-orders-v2-overview-summary.adapter';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class BuyOrderV2Service {
  constructor(
    private store: Store,
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findAllProposedBuyOrders(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrdersV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findAllProposedBuyOrdersOverviews',
      replacements
    );
    return this.http.post<ProposedBuyOrdersV1>(endpoint, payload);
  }

  private _findAllProposedBuyOrderLines(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrderlinesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findProposedBuyOrderLines',
      replacements
    );
    return this.http.post<ProposedBuyOrderlinesV1>(endpoint, payload);
  }

  private _findProposedBuyOrderAvailableProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrderlinesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findProposedBuyOrderAvailableProducts',
      replacements
    );
    return this.http.post<ProposedBuyOrderlinesV1>(endpoint, payload);
  }

  private _generateSession(
    replacements: UrlReplacement[],
    payload: any
  ): Observable<ProposedSession> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'generateSession',
      replacements
    );

    return this.http.post<ProposedSession>(endpoint, payload);
  }

  private _updateSession(
    payload: UpdateProposedSessionForFactorPayload,
    replacements: UrlReplacement[]
  ): Observable<ProposedSession> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'updateSession',
      replacements
    );

    return this.http.patch<ProposedSession>(endpoint, payload);
  }

  private _findProposedBuyOrderlinesOverview(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrderlinesOverviewV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findProposedBuyOrderOverview',
      replacements
    );

    return this.http.get<ProposedBuyOrderlinesOverviewV1>(endpoint, {
      params: payload,
    });
  }

  private _findConceptLinesOverview(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrderlinesOverviewV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'findBuyOrderConceptOverview',
      replacements
    );

    return this.http.get<ProposedBuyOrderlinesOverviewV1>(endpoint, {
      params: payload,
    });
  }

  private _findConceptLines(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrderlinesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'findConceptOrderLines',
      replacements
    );

    return this.http.post<ProposedBuyOrderlinesV1>(endpoint, payload);
  }

  private _findConceptAvailableProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrderlinesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'findConceptOrderAvailableProducts',
      replacements
    );
    return this.http.post<ProposedBuyOrderlinesV1>(endpoint, payload);
  }

  private _findPurchaseAdvice(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<PurchaseAdviceV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findPurchaseAdvice',
      replacements
    );

    return this.http.get<PurchaseAdviceV1>(endpoint, { params: payload });
  }

  private _placeOrder(
    payload: OrderPlacing,
    replacements: UrlReplacement[]
  ): Observable<PlacedOrder> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'placeOrder',
      replacements
    );

    return this.http.post<PlacedOrder>(endpoint, payload);
  }

  private _placeAndPlanOrder(
    payload: OrderPlacing,
    replacements: UrlReplacement[]
  ): Observable<PlacedOrder> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'placeAndPlanOrder',
      replacements
    );

    return this.http.post<PlacedOrder>(endpoint, payload);
  }

  private _placeManual(
    payload: OrderPlacing,
    replacements: UrlReplacement[]
  ): Observable<PlacedOrder> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'placeManualOrder',
      replacements
    );

    return this.http.post<PlacedOrder>(endpoint, payload);
  }

  private _findPurchaseIssues(
    replacements: UrlReplacement[]
  ): Observable<IssuesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findAllIssues',
      replacements
    );
    return this.http.get<IssuesV1>(endpoint);
  }

  private _sendBuyOrderEmail(
    payload: SendEmailProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderGRPC',
      'v2',
      'sendBuyOrderEmail',
      replacements
    );
    return this.http.post(endpoint, payload);
  }

  private _fetchExportSettings(
    replacements: UrlReplacement[]
  ): Observable<BuyOrderExportSettingsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderGRPC',
      'v2',
      'fetchExportSettings',
      replacements
    );

    return this.http.get<BuyOrderExportSettingsV1>(endpoint);
  }

  private _saveExportSettings(
    payload: SaveExportSettingsProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderGRPC',
      'v2',
      'saveExportSettings',
      replacements
    );
    return this.http.put(endpoint, payload);
  }

  private _findOrdersOverviewSummary(
    replacements: UrlReplacement[]
  ): Observable<OrdersOverviewSummariesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findOrdersOverviewSummary',
      replacements
    );

    return this.http.get<OrdersOverviewSummariesV1>(endpoint);
  }

  findAllProposedBuyOrders(
    webshopUuid: string,
    payload: any
  ): Observable<ProposedBuyOrders> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findAllProposedBuyOrders(payload, replacements).pipe(
      switchMap((data: ProposedBuyOrdersV1) =>
        of(new ProposedBuyOrdersV2Adapter(this.store).transform(data))
      )
    );
  }

  findProposedBuyOrderlines(
    webshopUuid: string,
    supplierUuid: string,
    payload: any
  ): Observable<ProposedBuyOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findAllProposedBuyOrderLines(payload, replacements).pipe(
      switchMap((data: ProposedBuyOrderlinesV1) =>
        of(new ProposedBuyOrderlinesV2Adapter(false).transform(data))
      )
    );
  }

  findProposedBuyOrderAvailableProducts(
    webshopUuid: string,
    supplierUuid: string,
    payload: any
  ): Observable<ProposedBuyOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findProposedBuyOrderAvailableProducts(
      payload,
      replacements
    ).pipe(
      switchMap((data: ProposedBuyOrderlinesV1) =>
        of(new ProposedBuyOrderlinesV2Adapter(true).transform(data))
      )
    );
  }

  generateSession(
    webshopUuid: string,
    sessionContext: SessionContext
  ): Observable<ProposedSession> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._generateSession(replacements, {
      context: sessionContext,
    }).pipe(
      switchMap((data: ProposedSession) => {
        return of(new ProposedSessionAdapter().transform(data));
      })
    );
  }

  updateSession(
    webshopUuid: string,
    sessionUuid: string,
    payload: UpdateProposedSessionForFactorPayload
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: sessionUuid },
    ];

    return this._updateSession(payload, replacements);
  }

  findProposedBuyOrderlinesOverview(
    webshopUuid: string,
    supplierUuid: string,
    payload?: ProposedBuyOrderlinesOverviewPayload
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findProposedBuyOrderlinesOverview(payload, replacements).pipe(
      switchMap((data: ProposedBuyOrderlinesOverviewV1) =>
        of(
          new ProposedBuyOrderlinesOverviewV2Adapter(this.store).transform(data)
        )
      )
    );
  }

  findConceptOrderlines(
    webshopUuid: string,
    uuid: string,
    payload: any
  ): Observable<ProposedBuyOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: uuid },
    ];

    return this._findConceptLines(payload, replacements).pipe(
      switchMap((data: ProposedBuyOrderlinesV1) =>
        of(new ProposedBuyOrderlinesV2Adapter(false).transform(data))
      )
    );
  }

  findConceptAvailableProducts(
    webshopUuid: string,
    uuid: string,
    payload: any
  ): Observable<ProposedBuyOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: uuid },
    ];

    return this._findConceptAvailableProducts(payload, replacements).pipe(
      switchMap((data: ProposedBuyOrderlinesV1) =>
        of(new ProposedBuyOrderlinesV2Adapter(true).transform(data))
      )
    );
  }

  findConceptLinesOverview(
    webshopUuid: string,
    uuid: string,
    payload?: ProposedBuyOrderlinesOverviewPayload
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: uuid },
    ];

    return this._findConceptLinesOverview(payload, replacements).pipe(
      switchMap((data: ProposedBuyOrderlinesOverviewV1) =>
        of(
          new ProposedBuyOrderlinesOverviewV2Adapter(this.store).transform(data)
        )
      )
    );
  }

  findPurchaseAdvice(
    webshopUuid: string,
    webshopProductUuid: string,
    payload?: PurchaseAdvicePayload
  ): Observable<PurchaseAdviceV1> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'webshopProductUuid', value: webshopProductUuid },
    ];

    return this._findPurchaseAdvice(payload, replacements).pipe(
      switchMap((data: PurchaseAdviceV1) =>
        of(new PurchaseAdviceAdapter().transform(data))
      )
    );
  }

  placeOrder(
    webshopUuid: string,
    buyOrderConceptUuid: string,
    orderMomentUuid: string,
    properties: OrderPlacing
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderConceptUuid', value: buyOrderConceptUuid },
      { name: 'orderMomentUuid', value: orderMomentUuid },
    ];

    return this._placeOrder(properties, replacements);
  }

  placeAndPlan(
    webshopUuid: string,
    buyOrderConceptUuid: string,
    properties: OrderPlacing
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderConceptUuid', value: buyOrderConceptUuid },
    ];

    return this._placeAndPlanOrder(properties, replacements);
  }

  placeManual(
    webshopUuid: string,
    buyOrderConceptUuid: string,
    properties: OrderPlacing
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderConceptUuid', value: buyOrderConceptUuid },
    ];

    return this._placeManual(properties, replacements);
  }

  sendBuyOrderEmail(
    webshopUuid: string,
    buyOrderUuid: string,
    properties: SendEmailProperties
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'buyOrderUuid', value: buyOrderUuid },
    ];

    return this._sendBuyOrderEmail(properties, replacements);
  }

  fetchExportSettings(webshopUuid: string): Observable<BuyOrderExportSettings> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._fetchExportSettings(replacements).pipe(
      switchMap((data: BuyOrderExportSettingsV1) =>
        of(new BuyOrdersV2ExportsSettingsAdapter().transform(data))
      )
    );
  }

  saveExportSettings(
    webshopUuid: string,
    properties: SaveExportSettingsProperties
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._saveExportSettings(properties, replacements);
  }

  findOrdersOverviewSummary(
    webshopUuid: string
  ): Observable<OrdersOverviewSummaries> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findOrdersOverviewSummary(replacements).pipe(
      switchMap((data: OrdersOverviewSummariesV1) =>
        of(new BuyOrdersOverviewSummaryAdapter().transform(data))
      )
    );
  }
}
