import { Selector } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { ComposedWebshopProduct } from 'src/app/shared/models/products/v3/products.model';
import {
  ProductPartsV2State,
  ProductPartsV2StateModel,
} from './product-parts-v2.state';
import {
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  buildDisplayedColumns,
  buildSelectableColumnsList,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { COLUMNS_ORDER } from '../model/data-model';

export class ProductPartsV2StateQueries {
  @Selector([ProductPartsV2State])
  static loading(state: ProductPartsV2StateModel): boolean {
    return state.loading;
  }

  @Selector([ProductPartsV2State])
  static parts(state: ProductPartsV2StateModel): ComposedWebshopProduct[] {
    return state.parts;
  }

  @Selector([ProductPartsV2State])
  static totalElements(state: ProductPartsV2StateModel): number {
    return state.page.totalElements;
  }

  @Selector([ProductPartsV2State])
  static featuredColumns(
    state: ProductPartsV2StateModel
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        return {
          ...group,
          columns: Object.values(group.columns),
        };
      }
    );
  }

  @Selector([ProductPartsV2StateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([ProductPartsV2StateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([ProductPartsV2StateQueries.displayedColumns])
  static lastDisplayedColumn(displayedColumns: string[]) {
    return displayedColumns.length === 1;
  }

  @Selector([ProductPartsV2State])
  static page(state: ProductPartsV2StateModel): Pageable {
    return state.page;
  }

  @Selector([ProductPartsV2State])
  static pageSizeOptions(state: ProductPartsV2StateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([ProductPartsV2State])
  static currentPage(state: ProductPartsV2StateModel): number {
    return state.pagination.page;
  }

  @Selector([ProductPartsV2State])
  static pageSize(state: ProductPartsV2StateModel): number {
    return state.pagination.size;
  }

  @Selector([ProductPartsV2State, WebshopState.webshopCurrencySymbol])
  static filters(
    state: ProductPartsV2StateModel,
    currencySymbol: string
  ): ColumnFilterGroup[] {
    return Object.entries(state.filtersGroups).map(([groupKey, group]) => {
      return {
        groupKey,
        groupName: group.groupName,
        filters: Object.entries(group.columns)
          .map(([key, filter]) => {
            if (STRINGS.columns.compositions[filter.key]?.currency) {
              return {
                columnKey: key,
                ...filter,
                unit: currencySymbol,
              };
            }

            return {
              columnKey: key,
              ...filter,
            };
          })
          .filter((filter: Filter) => !filter.disabled),
      };
    });
  }

  @Selector([ProductPartsV2State, WebshopState.webshopCurrencySymbol])
  static activeFilters(
    state: ProductPartsV2StateModel,
    currencySymbol: string
  ): ColumnFilter[] {
    const filters = Object.values(state.filtersGroups).map(
      group => group.columns
    );

    return filters
      .map(filter => {
        return (
          Object.entries(filter)
            .map(([columnKey, filter]) => {
              if (STRINGS.columns.compositions[filter.key]?.currency) {
                return {
                  columnKey,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey,
                ...filter,
              };
            })
            .filter((filter: Filter) => filter.params.value) || []
        );
      })
      .flat();
  }

  @Selector([ProductPartsV2State])
  static search(state: ProductPartsV2StateModel): string[] {
    return state.search || [];
  }

  @Selector([ProductPartsV2State])
  static filterOpened(state: ProductPartsV2StateModel): boolean {
    return state.filterOpened;
  }
}
