import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseConfirmationDialogComponent } from '../base-confirmation-dialog/base-confirmation-dialog.component';
import { BaseConfirmationDialogData } from '../base-confirmation-dialog/model/base-confirmation-data.model';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-simple-confirmation-dialog',
  templateUrl:
    '../base-confirmation-dialog/base-confirmation-dialog.component.html',
  styleUrls: [
    '../base-confirmation-dialog/base-confirmation-dialog.component.sass',
    './simple-confirmation-dialog.component.sass',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleConfirmationDialogComponent
  extends BaseConfirmationDialogComponent
  implements OnInit
{
  constructor(
    @Inject(DIALOG_DATA)
    public data: BaseConfirmationDialogData,
    public store: Store,
    public dialogRef: DialogRef
  ) {
    super(data, store, dialogRef);
  }

  ngOnInit(): void {
    this.buildPropsFromData(this.data);
  }
}
