import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

export class ToggleSharedFilter {
  static readonly type = '[Manual Order Shared] Toggle Shared Filter';
}

export class AddSharedSearchParam {
  static readonly type = '[Manual Order Shared] Add Shared Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSharedSearchParam {
  static readonly type = '[Manual Order Shared] Remove Shared Search Param';

  constructor(public param: string | null) {}
}

export class AddSharedFilterParam {
  static readonly type = '[Manual Order Shared] Add Shared Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class RemoveAllSharedFilters {
  static readonly type = '[Manual Order Shared] Remove All Shared Filters';
}
