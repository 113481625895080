import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Optional,
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  constructor(
    @Optional() dir: Directionality,
    cdr: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>
  ) {
    super(dir, cdr, elementRef);
  }

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
