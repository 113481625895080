import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Pipe({ name: 'filter', pure: false })
export class FilterPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (items.length === 0 || !callback) {
      return items;
    }

    return items.filter(item => callback(item));
  }
}

@Pipe({ name: 'asyncFilter', pure: false })
export class AsyncFilterPipe implements PipeTransform {
  transform(
    items$: Observable<any[]>,
    callback: (item: any) => boolean
  ): Observable<any> {
    return items$.pipe(
      tap(items => {
        if (items.length === 0 || !callback) {
          return items;
        }
        return items.filter(item => callback(item));
      })
    );
  }
}
