<app-dialog-container
  ><div appDialogHeader>
    <h1 appDialogTitle>
      @if (orderPlaced() === false) {
        {{ PURCHASE_STRINGS.title }}
      } @else {
        <span
          aria-label="Purchase confirmation order info"
          data-intercom-target="Purchase confirmation order info"
          i18n="purchase confirmation order placed title">
          Order
          <app-basic-copy-to-clipboard [copyText]="buyOrderId()">{{
            buyOrderId()
          }}</app-basic-copy-to-clipboard>
          - {{ supplierName() }} has been placed!
        </span>
      }
    </h1>

    @if (orderPlaced() === false) {
      <button
        aria-label="Purchase confirmation close button"
        data-intercom-target="Purchase confirmation close button"
        (click)="close()"
        [disabled]="saving()"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    }
  </div>

  <div appDialogBody class="custom-body">
    @if (orderPlaced() === false) {
      <div class="confirmation-container">
        <mat-icon class="info-icon" svgIcon="info-rounded-xs"></mat-icon>
        <p class="sub-title">{{ PURCHASE_STRINGS.info }}</p>
      </div>

      @if (hasAgenda()) {
        <app-purchase-agenda [loading]="loading()" [disabled]="saving()" />
      }

      <p class="body-text">{{ PURCHASE_STRINGS.subtitle }}</p>
    } @else {
      <div class="section-container">
        <h3 class="expected-delivery-title">
          {{ PURCHASE_STRINGS.expectedDeliveryDate }}
          <strong>{{ expectedDeliveryDate() | date }}.</strong>
        </h3>
        <h3 class="bracket-title">{{ COMMON_STRINGS.exports.title }}</h3>

        <app-purchase-exports />
      </div>

      <app-advanced-settings
        [label]="COMMON_STRINGS.exports.advancedExportSettings">
        <app-purchase-exports-settings
          [selectedOptions]="dynamicExportColumns()" />
      </app-advanced-settings>

      @if (hasEmailTemplates()) {
        <div class="section-container">
          <h3 class="bracket-title">{{ COMMON_STRINGS.otherOptions }}</h3>

          <app-purchase-supplier-settings />
        </div>
      }
    }
  </div>

  <div appDialogActions>
    <div class="action-buttons">
      @if (orderPlaced() === false) {
        <button
          aria-label="Purchase confirmation cancel button"
          data-intercom-target="Purchase confirmation cancel button"
          appButton
          variant="tertiary"
          [disabled]="loading() || saving()"
          (click)="close()">
          {{ PURCHASE_STRINGS.actions.cancel }}
        </button>

        <button
          aria-label="Purchase confirmation place order button"
          data-intercom-target="Purchase confirmation place order button"
          appButton
          variant="primary"
          (click)="placeOrder()"
          [disabled]="loading() || saving()">
          @if (saving() && loading() === false) {
            {{ PURCHASE_STRINGS.actions.placingOrder }}
            <app-progress-spinner />
          } @else {
            {{ PURCHASE_STRINGS.actions.placeOrder }}
          }
        </button>
      } @else {
        <button
          aria-label="Purchase confirmation done button"
          data-intercom-target="Purchase confirmation done button"
          appButton
          variant="primary"
          (click)="done()"
          [disabled]="disabledPurchase()">
          @if (useSupplierSettings() && hasEmailTemplates()) {
            @if (saving() === false) {
              {{ PURCHASE_STRINGS.send }}
            } @else {
              {{ PURCHASE_STRINGS.sendingEmails }}
            }
          } @else {
            {{ COMMON_STRINGS.done }}
          }

          @if (saving()) {
            <app-progress-spinner />
          }
        </button>
      }
    </div>
  </div>
</app-dialog-container>
