export function groupBy<T, K = any>(
  list: T[],
  keyGetter: Function
): Map<string, K> {
  const map = new Map();

  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return new Map<string, K>(map);
}
