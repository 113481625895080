<app-datatable-top-bar [expanded]="false">
  <ng-container leftSide>
    <app-data-table-search
      [searchParams]="searchBy()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />
  </ng-container>

  <ng-container rightSide>
    <div class="vertical-separator"></div>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-datatable-container
  size="sm"
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    *ngIf="lastDisplayedColumn() as lastDisplayedColumn"
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    [disableHideColumn]="lastDisplayedColumn(displayedColumns())"
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)">
    <!-- Selection Column -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SELECTION.key">
      <div>
        <th
          data-intercom-target="4332c8d9-8027-42e6-93f9-d61623bec1dc"
          aria-label="Promotion details products table select"
          cdk-header-cell
          *cdkHeaderCellDef
          class="selection-column fit-to-value"
          (click)="$event.stopPropagation()">
          <app-checkbox
            [checked]="areAllSelected()"
            [indeterminate]="areSomeSelected()"
            (checkedChange)="masterToggle()">
          </app-checkbox>
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="selection-container"
          (click)="$event.stopPropagation()">
          <ng-container *ngIf="isProductSelected() as isSelected">
            <app-checkbox
              #checkbox
              [checked]="isSelected(element.uuid)"
              (checkedChange)="toggleRowSelection($event, element.uuid)"
              [disabled]="!isSelected(element.uuid) && limitSelection()"
              [appTooltip]="
                COMMON_STRINGS.selection.warningPrefix +
                RESULTS_SELECTION_LIMIT +
                COMMON_STRINGS.selection.warningPrefix
              "
              i18n-appTooltip="datatable v2 results selection limit warning"
              [tooltipDisabled]="!checkbox.disabled">
            </app-checkbox>
          </ng-container>
        </td>
      </div>
    </ng-container>

    <!-- Category -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.CATEGORY.key">
      <th
        data-intercom-target="90698753-6e9e-47f9-b445-a55ba48a3cf2"
        aria-label="Promotion details products table category"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.CATEGORY.key | filterColumn: RELATED_STATE | async
        "
        class="fit-to-value">
        <app-product-category
          [category]="null"
          [disabled]="true"></app-product-category>
      </th>
      <td cdk-cell *cdkCellDef="let element" class="fit-to-value">
        <app-product-category
          [category]="element.category"></app-product-category>
      </td>
    </ng-container>

    <!-- Name -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.NAME.key">
      <th
        data-intercom-target="292769f6-2576-4d09-8e83-fb154f1ab268"
        aria-label="Promotion details products table name"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.NAME.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.NAME.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-copy-to-clipboard [message]="element.name"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Icons -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.ICONS.key">
      <th
        data-intercom-target="3928afc3-355f-48b6-9fe1-9612860a4b16"
        aria-label="Promotion details products table icons"
        cdk-header-cell
        *cdkHeaderCellDef>
        {{ TABLE_COLUMNS.ICONS.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-tag
          *ngFor="let tag of element.extras.tags.iconTags"
          [color]="tag.color"
          [appTooltip]="tag.tooltip">
          <mat-icon appTagIcon [svgIcon]="tag.icon"></mat-icon>
        </app-tag>
      </td>
    </ng-container>

    <!--Sku -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SKU.key">
      <th
        data-intercom-target="d7427708-d893-4332-9771-84006d0d4155"
        aria-label="Promotion details products table sku"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SKU.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.SKU.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="restricted-column">
        <app-copy-to-clipboard [message]="element.sku"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Stock -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.FREE_STOCK.key">
      <th
        data-intercom-target="d6270ac4-e06d-409c-8e96-19adb37262bd"
        aria-label="Promotion details products table stock"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.FREE_STOCK.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.FREE_STOCK.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="column-strong align-right">
        <ng-container
          *ngIf="
            !element.assembled &&
              !element.ignored &&
              element.stockOnHand !== null;
            else notAvailable
          ">
          {{ element.stockOnHand | customDecimal }}
        </ng-container>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"
      [class.opaque]="row.orderFuture"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
