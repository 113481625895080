import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import { OrderlineRelatedTable } from '../model/purchase-overlay-v3.model';

export class OrderlineInfoWeightChanged {
  static readonly type = '[Purchase Overlay V3 Orderline Info] Weight Changed';

  constructor(
    public relatedTable: OrderlineRelatedTable,
    public orderline: ProposedBuyOrderline,
    public weight: any
  ) {}
}

export class OrderlineInfoVolumeChanged {
  static readonly type = '[Purchase Overlay V3 Orderline Info] Volume Changed';

  constructor(
    public relatedTable: OrderlineRelatedTable,
    public orderline: ProposedBuyOrderline,
    public volume: any
  ) {}
}

export class OrderlineInfoQuantityChanged {
  static readonly type =
    '[Purchase Overlay V3 Orderline Info] Quantity Changed';

  constructor(
    public relatedTable: OrderlineRelatedTable,
    public orderline: ProposedBuyOrderline,
    public quantity: any
  ) {}
}

export class OrderlineInfoPriceChanged {
  static readonly type = '[Purchase Overlay V3 Orderline Info] Price Changed';

  constructor(
    public relatedTable: OrderlineRelatedTable,
    public orderline: ProposedBuyOrderline,
    public price: any
  ) {}
}

export class OrderlineSnooze {
  static readonly type = '[Purchase Overlay V3 Orderline] Snooze';

  constructor(public orderline: ProposedBuyOrderline) {}
}

export class OrderlineAdd {
  static readonly type = '[Purchase Overlay V3 Orderline] Add';

  constructor(public orderline: ProposedBuyOrderline) {}
}

export class OrderlineRemove {
  static readonly type = '[Purchase Overlay V3 Orderline] Remove';

  constructor(public orderline: ProposedBuyOrderline) {}
}
