import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { emailRegexValidationV2 } from '../../utils/email-validation';
import { STRINGS } from 'src/app/features/suppliers-v2/model/suppliers-v2.strings';
import { Store } from '@ngxs/store';
import { CreateSupplier } from 'src/app/features/suppliers-v2/actions/suppliers-v2.actions';

@Component({
  selector: 'app-add-new-supplier-dialog',
  templateUrl: './add-new-supplier-dialog.component.html',
  styleUrls: ['./add-new-supplier-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewSupplierDialogComponent {
  supplierName: string | null = null;

  email: string | null = null;

  addAnother: boolean = false;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly DIALOG_STRINGS = STRINGS.metadata.addSupplierDialog;

  readonly EMAIL_REGEX = emailRegexValidationV2;

  constructor(
    private store: Store,
    private dialogRef: DialogRef
  ) {}

  createSupplier(): void {
    this.store.dispatch(
      new CreateSupplier({ name: this.supplierName, email: this.email })
    );

    if (!this.addAnother) {
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
