<div class="paginator">
  <div class="paginator-length">
    @if (loading) {
      <span><app-skeleton-rect width="100px" /></span>
    } @else if (hasData) {
      <span class="strong">{{ startResultIndex }} - {{ endResultIndex }}</span>
      {{ PAGINATION_STRINGS.of }}
      <span class="strong">{{ length }}</span>
    } @else {
      <span>0 {{ PAGINATION_STRINGS.of }} {{ length }}</span>
    }
  </div>

  <div class="paginator-actions-container">
    <div class="paginator-actions">
      <span
        class="paginator-button-container"
        [appTooltip]="PAGINATION_STRINGS.firstPage"
        tooltipShowDelay="500">
        @if (showFirstLastButtons) {
          <button
            aria-label="Paginator first page button"
            appButton
            appButtonIcon
            variant="link"
            transparent
            class="icon-button"
            [disabled]="!hasPreviousPage || loading"
            (click)="firstPage()">
            <mat-icon svgIcon="back"></mat-icon>
          </button>
        }
      </span>

      <span
        class="paginator-button-container"
        [appTooltip]="PAGINATION_STRINGS.previousPage"
        tooltipShowDelay="500">
        <button
          aria-label="Paginator previous page button"
          appButton
          appButtonIcon
          variant="link"
          transparent
          class="icon-button"
          [disabled]="!hasPreviousPage || loading"
          (click)="previousPage()">
          <mat-icon svgIcon="angle-left"></mat-icon>
        </button>
      </span>
    </div>

    @if (loading) {
      <app-skeleton-rect width="100px" />
    } @else {
      <span class="paginator-info"
        >{{ PAGINATION_STRINGS.page }}
        <span class="strong darker">{{ currentPage }}</span> /
        {{ totalPages }}</span
      >
    }

    <div class="paginator-actions">
      <span
        class="paginator-button-container"
        [appTooltip]="PAGINATION_STRINGS.nextPage"
        tooltipShowDelay="500">
        <button
          aria-label="Paginator next page button"
          appButton
          appButtonIcon
          variant="link"
          transparent
          class="icon-button"
          [disabled]="!hasNextPage || loading"
          (click)="nextPage()">
          <mat-icon svgIcon="angle-right"></mat-icon>
        </button>
      </span>

      <span
        class="paginator-button-container"
        [appTooltip]="PAGINATION_STRINGS.lastPage"
        tooltipShowDelay="500">
        @if (showFirstLastButtons) {
          <button
            aria-label="Paginator last page button"
            appButton
            appButtonIcon
            variant="link"
            transparent
            class="icon-button"
            [disabled]="!hasNextPage || loading"
            (click)="lastPage()">
            <mat-icon svgIcon="forward"></mat-icon>
          </button>
        }
      </span>
    </div>
  </div>

  <div class="paginator-size">
    <span>{{ PAGINATION_STRINGS.itemsPerPage }}</span>

    <div class="custom-select">
      <app-dropdown
        name="pageSize"
        [(ngModel)]="pageSize"
        (ngModelChange)="changePageSize()"
        panelWidth="100"
        type="outline"
        forceOptionsReset
        [disabled]="loading">
        @for (size of pageSizeOptions; track size) {
          <app-dropdown-option [value]="size">
            {{ size }}
          </app-dropdown-option>
        }
      </app-dropdown>
    </div>
  </div>
</div>
