import { Injectable } from '@angular/core';
import {
  Action,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ConnectWebSocket, SendWebSocketMessage } from '@ngxs/websocket-plugin';
import {
  catchError,
  concatMap,
  delay,
  EMPTY,
  mergeMap,
  Observable,
  of,
  tap,
} from 'rxjs';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { BuyOrderManualOrderV2Service } from 'src/app/core/api/buy-order-manual-order/v2/buy-order-manual-order.service';
import { BuyOrderV2Service } from 'src/app/core/api/buy-order/v2/buy-order-v2.service';
import { ProposedSession } from 'src/app/core/api/buy-order/v2/model/proposed-buy-orderlines-v2.model';
import { AuthenticationState } from 'src/app/core/states/authentication.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  BuyOrderlinesOverview,
  BuyOrderlinesOverviewOrder,
} from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2.model';
import {
  ProposedBuyOrderline,
  ProposedBuyOrderlines,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import { OrderlinesSelection } from '../../../model/purchase-v3.model';
import { STRINGS } from '../../../model/purchase-v3.strings';
import {
  SocketStatus,
  SocketType,
} from '../../proposed-orderlines/model/proposed-orderlines.model';
import {
  ColumnsSelected as AvailableColumnsSelected,
  LoadManualOrderV2AvailableProducts,
  OrderLineAdded,
  OrderLinesAdded,
} from '../actions/manual-available-products.actions';
import {
  AddFilterParam,
  AddSearchParam,
  ClearSelection,
  ColumnsSelected,
  CreateConcept,
  GenerateSession,
  HideColumn,
  InitializePurchase,
  LoadManualOrderV2Overview,
  LoadManualOrderV2Products,
  MasterToggleSelection,
  OrderLineChanged,
  OrderlineErrored,
  OrderLineRemoved,
  OrderLinesRemoved,
  Paginate,
  PatchLinePrice,
  PatchLineQuantity,
  PatchLineVolume,
  PatchLineWeight,
  RefreshManualOrderPage,
  ReloadBothTables,
  RemoveAllFilters,
  RemoveProductFromOrder,
  RemoveProductsFromOrder,
  RemoveSearchParam,
  ResetBuyOrder,
  ResetPagination,
  ResetPaginationAndLoadData,
  SaveConcept,
  SocketDisconnection,
  Sort,
  ToggleFilter,
  ToggleRowSelection,
  UpdateConcept,
} from '../actions/manual-order-products.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/manual-order-products-data-table.model';
import { ReconnectWebsocket } from '../../../actions/purchase-v3.actions';
import {
  CreateConceptModel,
  UpdateConceptModel,
} from 'src/app/shared/models/buy-orders/v2/concept-buy-orders-v2.model';
import { ConceptBuyOrderV2Service } from 'src/app/core/api/buy-order-concepts/v2/concept-buy-order-v2.service';
import { DEFAULT_BUY_ORDERS_APPLY_COLUMN_SELECTION_ALL } from 'src/app/core/constants/global.constants';
import { CreateConceptResult } from 'src/app/core/api/buy-order-concepts/v2/model/concept-buy-order-v2-model';
import { environment } from 'src/environments/environment';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { AccountState } from 'src/app/core/states/account.state';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableParam,
  defaultDatatableStateModel,
  getNewColumnSelection,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { FetchSupplierV2 } from 'src/app/features/suppliers-v2/actions/suppliers-v2.actions';
import {
  AddSharedFilterParam,
  AddSharedSearchParam,
  RemoveAllSharedFilters,
  RemoveSharedSearchParam,
  ToggleSharedFilter,
} from '../actions/manual-shared.actionts';
import { SessionContext } from 'src/app/core/api/buy-order/v2/model/buy-order-v2.model';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { InitializeNotes } from '../../purchase-overview/components/purchase-notes/actions/purchase-notes.actions';
import { ReloadDatatable } from '../../proposed-orderlines/actions/proposed-orderlines.actions';

export interface ManualOrderV2StateModel extends DatatableStateModel {
  applySameColumnsToAll: boolean;
  products: ProposedBuyOrderline[];
  page: Pageable;
  loading: boolean;
  overview: BuyOrderlinesOverviewOrder;
  sessionUuid: string;
  supplierUuid: string;
  buyOrderConceptUuid: string | null;
  selection: TableSelection<OrderlinesSelection>;
  shouldReconnect: boolean;
  isDisconnected: boolean;
  isOrderNotSynced: boolean;
  disableActions: boolean;
  disableRefreshButton: boolean;
}

@State<ManualOrderV2StateModel>({
  name: 'manualOrderlinesState',
  defaults: {
    ...defaultDatatableStateModel,
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    applySameColumnsToAll: DEFAULT_BUY_ORDERS_APPLY_COLUMN_SELECTION_ALL,
    sortBy: defaultSort,
    search: [],
    products: [],
    page: { totalElements: 0 },
    loading: true,
    overview: null,
    sessionUuid: '',
    supplierUuid: '',
    filterOpened: false,
    buyOrderConceptUuid: null,
    selection: {},
    shouldReconnect: false,
    isDisconnected: false,
    isOrderNotSynced: false,
    disableActions: false,
    disableRefreshButton: false,
  },
})
@Injectable()
export class ManualOrderV2State extends DatatableState {
  private readonly NOTIFICATIONS = STRINGS.notifications.purchaseEditor;

  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [ManualOrderV2State],
      (state: ManualOrderV2StateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  constructor(
    private store: Store,
    private buyOrderService: BuyOrderV2Service,
    private conceptBuyOrderV2Service: ConceptBuyOrderV2Service,
    private manualBuyOrderService: BuyOrderManualOrderV2Service,
    private notificationService: NotificationCenterService
  ) {
    super();
  }

  @Action(InitializePurchase, { cancelUncompleted: true })
  initializePurchase(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: InitializePurchase
  ) {
    ctx.patchState({
      loading: true,
      supplierUuid: payload.supplierUuid,
      buyOrderConceptUuid: null,
      overview: null,
      disableRefreshButton: false,
    });

    return ctx.dispatch(new GenerateSession()).pipe(
      concatMap(() =>
        ctx.dispatch([
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          ),
          new LoadManualOrderV2Products(payload.supplierUuid),
          new LoadManualOrderV2AvailableProducts(payload.supplierUuid),
          new LoadManualOrderV2Overview(payload.supplierUuid),
          new FetchSupplierV2(payload.supplierUuid),
        ])
      ),
      concatMap(() => ctx.dispatch(new InitializeNotes())),
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  @Action(RefreshManualOrderPage, { cancelUncompleted: true })
  refreshOrderlinesPage(ctx: StateContext<ManualOrderV2StateModel>) {
    ctx.patchState({
      disableRefreshButton: true,
    });
    return ctx.dispatch(new Saving(true, 'Syncing...')).pipe(
      concatMap(() => {
        return ctx.dispatch(new ReloadBothTables());
      }),
      delay(2000),
      concatMap(() => {
        return ctx.dispatch(new SaveSucceed(true, 'Data synced sucessfully!'));
      }),
      delay(10000),
      concatMap(() => {
        ctx.patchState({ disableRefreshButton: false });
        return of(true);
      })
    );
  }

  @Action(LoadManualOrderV2Products, { cancelUncompleted: true })
  loadOrderProducts(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: LoadManualOrderV2Products
  ) {
    ctx.patchState({
      supplierUuid: payload.supplierUuid,
      disableActions: true,
    });

    return this._fetchOrderProducts(ctx, payload.supplierUuid).pipe(
      catchError(() => {
        ctx.patchState({
          loading: false,
          disableActions: false,
          products: [],
          page: { totalElements: 0 },
        });

        return of(false);
      })
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<ManualOrderV2StateModel>) {
    ctx.setState(
      patch({
        loading: true,
      })
    );

    return ctx
      .dispatch(new LoadManualOrderV2Products(ctx.getState().supplierUuid))
      .pipe(
        tap(() => {
          ctx.setState(
            patch({
              loading: false,
            })
          );
        })
      );
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<ManualOrderV2StateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ManualOrderV2StateModel>, payload: Paginate) {
    const state = ctx.getState();

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
      loading: true,
    });

    return this._fetchOrderProducts(ctx, ctx.getState().supplierUuid).pipe(
      catchError(() => {
        ctx.patchState({
          loading: false,
        });

        return of(false);
      })
    );
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(LoadManualOrderV2Overview, { cancelUncompleted: true })
  loadProposedOrderlinesOverview(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: LoadManualOrderV2Overview
  ) {
    return this._fetchOrderOverview(ctx, payload.supplierUuid).pipe(
      tap((overview: BuyOrderlinesOverview) => {
        ctx.patchState({
          overview: overview.order,
        });
      }),
      catchError(() => {
        ctx.patchState({
          overview: null,
        });

        return of(false);
      })
    );
  }

  @Action(GenerateSession, { cancelUncompleted: true })
  generateSession(ctx: StateContext<ManualOrderV2StateModel>) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;
    let sessionContext: SessionContext = {
      orderType: 'MANUAL',
      supplierUuid: ctx.getState().supplierUuid,
    };

    if (environment.production) {
      return this.buyOrderService
        .generateSession(webshopUuid, sessionContext)
        .pipe(
          tap((session: ProposedSession) => {
            ctx.patchState({
              sessionUuid: session.sessionUuid,
              shouldReconnect: true,
            });

            this.store.dispatch(
              new ConnectWebSocket({
                url: `wss://app.optiply.com/api/buy-order/v1/${webshopUuid}/edit/${session.sessionUuid}/manual`,
              })
            );
          }),
          catchError(e => {
            ctx.dispatch(
              new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
            );

            throw new Error(e.message || e);
          })
        );
    } else {
      return this.buyOrderService
        .generateSession(webshopUuid, sessionContext)
        .pipe(
          tap((session: ProposedSession) => {
            ctx.patchState({
              sessionUuid: session.sessionUuid,
              shouldReconnect: true,
            });

            this.store.dispatch(
              new ConnectWebSocket({
                url: `wss://dashboard.acceptance.optiply.com/api/buy-order/edge/${webshopUuid}/edit/${session.sessionUuid}/manual`,
              })
            );
          }),
          catchError(e => {
            ctx.dispatch(
              new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
            );

            throw new Error(e.message || e);
          })
        );
    }
  }

  @Action(ReconnectWebsocket, { cancelUncompleted: true })
  reconnectSocket(ctx: StateContext<ManualOrderV2StateModel>) {
    if (ctx.getState().shouldReconnect) {
      const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

      if (environment.production) {
        this.store.dispatch(
          new ConnectWebSocket({
            url:
              'wss://app.optiply.com/api/buy-order/v1/' +
              webshopUuid +
              '/edit/' +
              ctx.getState().sessionUuid +
              '/manual',
          })
        );
      } else {
        this.store.dispatch(
          new ConnectWebSocket({
            url:
              'wss://dashboard.acceptance.optiply.com/api/buy-order/edge/' +
              webshopUuid +
              '/edit/' +
              ctx.getState().sessionUuid +
              '/manual',
          })
        );
      }
      ctx.patchState({ isDisconnected: false });
    }
  }

  @Action(SocketDisconnection, { cancelUncompleted: true })
  handleSocketDisconnection(ctx: StateContext<ManualOrderV2StateModel>) {
    ctx.patchState({
      isDisconnected: true,
    });
  }

  @Action([
    PatchLinePrice,
    PatchLineQuantity,
    PatchLineVolume,
    PatchLineWeight,
    RemoveProductFromOrder,
  ])
  connectOnDemand(_ctx: StateContext<ManualOrderV2StateModel>) {
    if (_ctx.getState().isDisconnected === true) {
      _ctx.dispatch(new ReconnectWebsocket());
    }
  }

  @Action(PatchLinePrice, { cancelUncompleted: true })
  patchLinePrice(
    _ctx: StateContext<ManualOrderV2StateModel>,
    payload: PatchLinePrice
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          price: parseFloat(payload.changedPrice),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(PatchLineQuantity, { cancelUncompleted: true })
  patchLineQuantity(
    _ctx: StateContext<ManualOrderV2StateModel>,
    payload: PatchLineQuantity
  ) {
    if (parseInt(payload.changedQuantity) === 0) {
      this.store.dispatch(
        new SendWebSocketMessage({
          type: SocketType.SINGLE,
          data: {
            status: SocketStatus.REMOVED,
            supplierProductUuid: payload.orderline.uuid,
          },
          token: this.store.selectSnapshot(AuthenticationState.token),
        })
      );
    } else {
      this.store.dispatch(
        new SendWebSocketMessage({
          type: SocketType.SINGLE,
          data: {
            status: SocketStatus.EXISTING,
            supplierProductUuid: payload.orderline.uuid,
            quantity: parseInt(payload.changedQuantity),
          },
          token: this.store.selectSnapshot(AuthenticationState.token),
        })
      );
    }
  }

  @Action(PatchLineWeight, { cancelUncompleted: true })
  patchLineWeight(
    _ctx: StateContext<ManualOrderV2StateModel>,
    payload: PatchLineWeight
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          weight: parseFloat(payload.changedWeight),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(PatchLineVolume, { cancelUncompleted: true })
  patchLineVolume(
    _ctx: StateContext<ManualOrderV2StateModel>,
    payload: PatchLineVolume
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          volume: parseFloat(payload.changedVolume),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(RemoveProductFromOrder, { cancelUncompleted: true })
  removeProductFromOrder(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: RemoveProductFromOrder
  ) {
    this.notificationService.showToast(this.NOTIFICATIONS.removingProducts);

    this._removeFromSelection(ctx, payload.supplierProductUuid);

    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.REMOVED,
          supplierProductUuid: payload.supplierProductUuid,
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(RemoveProductsFromOrder, { cancelUncompleted: true })
  removeProductsFromOrder(ctx: StateContext<ManualOrderV2StateModel>) {
    this.notificationService.showToast(this.NOTIFICATIONS.removingProducts);

    const supplierProductUuids = Object.values(ctx.getState().selection);

    if (supplierProductUuids.length === 0) return;

    this.clearSelection(ctx);

    if (supplierProductUuids.length === 1) {
      this.store.dispatch(
        new RemoveProductFromOrder(supplierProductUuids[0].supplierProductUuid)
      );

      return;
    }

    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.BULK,
        data: {
          status: SocketStatus.REMOVED,
          orderLines: supplierProductUuids,
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(OrderLineChanged, { cancelUncompleted: true })
  changeLine(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: OrderLineChanged
  ) {
    ctx.setState(
      patch<ManualOrderV2StateModel>({
        products: updateItem<ProposedBuyOrderline>(
          orderline => orderline.uuid === payload.orderLine.uuid,
          {
            ...payload.orderLine,
            supplierProduct: {
              ...payload.orderLine.supplierProduct,
              deliveryTime:
                payload.orderLine.supplierProduct.deliveryTime !== undefined
                  ? payload.orderLine.supplierProduct.deliveryTime
                  : null,
            },
            errored: false,
          }
        ),
      })
    );

    return of(EMPTY).pipe(
      delay(500),
      concatMap(() =>
        ctx.dispatch(new LoadManualOrderV2Overview(ctx.getState().supplierUuid))
      )
    );
  }

  @Action(OrderlineErrored, { cancelUncompleted: true })
  orderlineError(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: OrderlineErrored
  ) {
    ctx.setState(
      patch<ManualOrderV2StateModel>({
        products: updateItem<ProposedBuyOrderline>(
          orderline => orderline.uuid === payload.supplierProductUuid,
          this._handleError(
            ctx,
            payload.supplierProductUuid,
            payload.errorMessage
          )
        ),
      })
    );
    ctx.dispatch(
      new SaveFailed(
        true,
        payload.supplierProductUuid + ': ' + payload.errorMessage
      )
    );
  }

  @Action(SaveConcept, { cancelUncompleted: true })
  saveConcept(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: SaveConcept
  ) {
    return ctx.dispatch(new CreateConcept(payload.properties));
  }

  @Action(CreateConcept, { cancelUncompleted: true })
  createConcept(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: CreateConcept
  ) {
    ctx.patchState({
      disableActions: true,
    });

    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildCreateConceptRequest(state);

    return ctx.dispatch(new Saving(payload.properties.showMessage)).pipe(
      mergeMap(() => this._createConcept(webshopUuid, requestInfo)),
      tap((result: CreateConceptResult) => {
        ctx.patchState({
          buyOrderConceptUuid: result.uuid,
          isOrderNotSynced: this.handlePossibleDesync(result, state),
          disableActions: false,
        });
      }),
      concatMap(() => {
        return ctx.dispatch(new SaveSucceed(payload.properties.showMessage));
      }),
      catchError(() => {
        ctx.patchState({
          disableActions: false,
        });

        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  handlePossibleDesync(
    result: CreateConceptResult,
    state: ManualOrderV2StateModel
  ): boolean {
    if (
      result.numberOfProducts !== state.overview.numberOfProducts ||
      result.totalValue !== state.overview.totalValue
    ) {
      return true;
    }
    return false;
  }

  @Action(UpdateConcept, { cancelUncompleted: true })
  updateConcept(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: UpdateConcept
  ) {
    ctx.patchState({
      disableActions: true,
    });

    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildUpdateConceptRequest(state);

    return ctx.dispatch(new Saving(payload.properties.showMessage)).pipe(
      concatMap(() =>
        this.conceptBuyOrderV2Service
          .updateConcept(webshopUuid, state.buyOrderConceptUuid, requestInfo)
          .pipe(
            catchError(e => {
              ctx.patchState({
                disableActions: false,
              });

              ctx.dispatch(new SaveFailed());

              throw new Error(e.message || e);
            })
          )
      ),
      tap(() => {
        ctx.patchState({
          disableActions: false,
        });
      }),
      concatMap(() => {
        return this._fetchOrderOverview(ctx, state.overview.supplier.uuid).pipe(
          tap((overview: BuyOrderlinesOverview) => {
            ctx.patchState({
              isOrderNotSynced: this.handlePossibleDesync(
                {
                  uuid: overview.order.uuid,
                  totalValue: overview.order.totalValue,
                  numberOfProducts: overview.order.numberOfProducts,
                },
                state
              ),
            });
          })
        );
      }),
      concatMap(() =>
        ctx.dispatch(new SaveSucceed(payload.properties.showMessage))
      ),
      catchError(() => {
        return of(false);
      })
    );
  }

  @Action([
    OrderLineRemoved,
    OrderLinesRemoved,
    OrderLineAdded,
    OrderLinesAdded,
    ReloadBothTables,
  ])
  anyActionApplied(
    ctx: StateContext<ManualOrderV2StateModel>,
    action: ReloadBothTables
  ): void {
    if (action?.payload?.showLoading) {
      ctx.patchState({
        loading: true,
      });
    }

    ctx.dispatch([
      new LoadManualOrderV2Products(ctx.getState().supplierUuid),
      new LoadManualOrderV2AvailableProducts(ctx.getState().supplierUuid),
      new LoadManualOrderV2Overview(ctx.getState().supplierUuid),
    ]);
  }

  @Action([OrderLineRemoved, OrderLinesRemoved])
  notifyRemovedOrderlines(_ctx: StateContext<ManualOrderV2StateModel>): void {
    this.notificationService.showToast(this.NOTIFICATIONS.removedProducts);
  }

  @Action([OrderLineAdded, OrderLinesAdded])
  notifyAddedOrderlines(_ctx: StateContext<ManualOrderV2StateModel>): void {
    this.notificationService.showToast(this.NOTIFICATIONS.addedProducts);
  }

  @Action(ClearSelection, { cancelUncompleted: true })
  clearSelection(ctx: StateContext<ManualOrderV2StateModel>) {
    ctx.patchState({
      selection: {},
    });
  }

  @Action([AddFilterParam, AddSharedFilterParam], { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: AddFilterParam | AddSharedFilterParam
  ) {
    ctx.patchState({
      loading: true,
    });

    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([AddSearchParam, AddSharedSearchParam], { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: AddSearchParam | AddSharedSearchParam
  ) {
    ctx.setState(
      patch<ManualOrderV2StateModel>({
        loading: true,
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([RemoveSearchParam, RemoveSharedSearchParam], {
    cancelUncompleted: true,
  })
  removeSearchParam(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: RemoveSearchParam | RemoveSharedSearchParam
  ) {
    ctx.setState(
      patch<ManualOrderV2StateModel>({
        loading: true,
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: ColumnsSelected
  ) {
    ctx.patchState({
      applySameColumnsToAll: payload.selection.applyAll,
      columnsGroups: getNewColumnSelection(
        ctx.getState().columnsGroups,
        payload.selection
      ),
    });
  }

  @Action(AvailableColumnsSelected, { cancelUncompleted: true })
  availableColumnsSelected(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: AvailableColumnsSelected
  ) {
    if (payload.selection.applyAll) {
      this.columnsSelected(ctx, payload);
      return;
    }

    ctx.patchState({
      applySameColumnsToAll: false,
    });
  }

  @Action([RemoveAllFilters, RemoveAllSharedFilters], {
    cancelUncompleted: true,
  })
  removeAllFilters(ctx: StateContext<ManualOrderV2StateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<ManualOrderV2StateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action([ToggleFilter, ToggleSharedFilter], { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ManualOrderV2StateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ManualOrderV2StateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<ManualOrderV2StateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    ctx.patchState({
      columnsGroups: newColumnSelection,
    });
  }

  @Action(ResetBuyOrder, { cancelUncompleted: true })
  resetBuyOrder(ctx: StateContext<ManualOrderV2StateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      filtersGroups: defaultFiltersV2,
      sortBy: defaultSort,
      search: [],
      pagination: defaultPagination,
      selection: {},
      shouldReconnect: false,
      sessionUuid: null,
      loading: true,
      overview: null,
      products: [],
    });
  }

  @Action(ToggleRowSelection, { cancelUncompleted: true })
  toggleRowSelection(
    ctx: StateContext<ManualOrderV2StateModel>,
    payload: ToggleRowSelection
  ) {
    const selectedCopy = { ...ctx.getState().selection };

    if (!payload.isSelected) {
      delete selectedCopy[payload.rowKey];

      ctx.patchState({
        selection: selectedCopy,
      });

      return;
    }

    ctx.patchState({
      selection: {
        ...ctx.getState().selection,
        [payload.rowKey]: {
          supplierProductUuid: payload.rowKey,
        },
      },
    });
  }

  @Action(MasterToggleSelection, { cancelUncompleted: true })
  masterToggleSelection(ctx: StateContext<ManualOrderV2StateModel>) {
    const lines = ctx.getState().products;

    const existingSelection = ctx.getState().selection;

    const linesUuid = lines.map(line => line.uuid);

    const selectedUids = new Set(Object.keys(existingSelection));

    const allSelected = linesUuid.every(line => selectedUids.has(line));

    const selectionCopy = new Map(Object.entries(existingSelection));

    if (allSelected) {
      lines.forEach(line => {
        selectionCopy.delete(line.uuid);
      });
    } else {
      lines.forEach(line => {
        selectionCopy.set(line.uuid, {
          supplierProductUuid: line.supplierProduct.uuid,
        });
      });
    }

    ctx.patchState({
      selection: {
        ...Object.fromEntries(selectionCopy.entries()),
      },
    });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<ManualOrderV2StateModel>) {
    return ctx.dispatch([
      new ResetPagination(),
      new LoadManualOrderV2Products(ctx.getState().supplierUuid),
    ]);
  }

  override addGroupParam(
    ctx: StateContext<ManualOrderV2StateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ManualOrderV2StateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ManualOrderV2StateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadManualOrderV2Products(ctx.getState().supplierUuid));
  }

  _handleError(
    ctx: StateContext<ManualOrderV2StateModel>,
    orderlineUuid: string,
    _errorMessage: string
  ): ProposedBuyOrderline {
    let tempLine: ProposedBuyOrderline = {
      ...ctx.getState().products.find(line => line.uuid === orderlineUuid),
      errored: true,
    };
    return tempLine;
  }

  private _removeAllFilters(ctx: StateContext<ManualOrderV2StateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      loading: true,
      search: [],
    });
  }

  private _fetchOrderProducts(
    ctx: StateContext<ManualOrderV2StateModel>,
    supplierUuid: string
  ): Observable<void | ProposedBuyOrderlines> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const sessionUuid = ctx.getState().sessionUuid;

    const requestInfo = this._buildRequestInfo(ctx);

    return this.manualBuyOrderService
      .findOrderProducts(webshopUuid, supplierUuid, sessionUuid, requestInfo)
      .pipe(
        tap((orders: ProposedBuyOrderlines) => {
          ctx.patchState({
            products: orders.data,
            page: { totalElements: orders.metadata.page.totalElements },
            loading: false,
            disableActions: false,
          });
        }),
        catchError(() => {
          return ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
          );
        })
      );
  }

  private _buildRequestInfo(
    ctx: StateContext<ManualOrderV2StateModel>
  ): ServiceRequestInfoV3 {
    const state = ctx.getState();

    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _fetchOrderOverview(
    ctx: StateContext<ManualOrderV2StateModel>,
    supplierUuid: string
  ): Observable<BuyOrderlinesOverview> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const sessionUuid = ctx.getState().sessionUuid;

    return this.manualBuyOrderService
      .findBuyOrderlinesOverview(webshopUuid, supplierUuid, sessionUuid)
      .pipe(
        catchError(e => {
          ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedOverviewRetrieval)
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _buildCreateConceptRequest(
    state: ManualOrderV2StateModel
  ): CreateConceptModel {
    return {
      supplierUuid: state.supplierUuid,
      orderType: 'MANUAL',
      planningTrigger: state.overview.planningTrigger,
      sessionUuid: state.sessionUuid,
    };
  }

  private _buildUpdateConceptRequest(
    state: ManualOrderV2StateModel
  ): UpdateConceptModel {
    return {
      supplierUuid: state.supplierUuid,
      orderType: 'MANUAL',
      planningTrigger: state.overview.planningTrigger,
      sessionUuid: state.sessionUuid,
    };
  }

  private _removeFromSelection(
    ctx: StateContext<ManualOrderV2StateModel>,
    key: string
  ): void {
    const selection = { ...ctx.getState().selection };

    delete selection[key];

    ctx.patchState({
      selection: { ...selection },
    });
  }

  private _createConcept(
    webshopUuid: string,
    requestInfo: CreateConceptModel
  ): Observable<any> {
    return this.conceptBuyOrderV2Service
      .createConcept(webshopUuid, requestInfo)
      .pipe(
        catchError(e => {
          throw new Error(e.message || e);
        })
      );
  }
}
