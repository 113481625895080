import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { DROPDOWN_SEARCH } from '../../model/dropdown.model';
import { FormControl } from '@angular/forms';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  startWith,
} from 'rxjs';

@Component({
  selector: 'app-dropdown-search',
  exportAs: 'dropdownSearch',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DROPDOWN_SEARCH, useExisting: DropdownSearchComponent },
  ],
})
export class DropdownSearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true, read: ElementRef })
  searchInput: ElementRef;

  @Input()
  placeholder: string = MESSAGES.common.filter.search;

  @Output()
  searched: EventEmitter<string> = new EventEmitter<string>();

  searchBy: FormControl<string> = new FormControl<string>('');

  searchedBy$: Observable<string> = this.searchBy.valueChanges.pipe(
    startWith(''),
    debounceTime(500),
    distinctUntilChanged()
  );

  searchedBy: Signal<string> = toSignal(this.searchedBy$);

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.searchedBy$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((searchBy: string) => {
        this.searched.emit(searchBy);
      });
  }

  focus() {
    setTimeout(() => {
      this.searchInput?.nativeElement?.focus();
    });
  }

  clearSearch(): void {
    this.searchBy.reset('');
  }
}
