import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { ExportRequestDataV2 } from 'src/app/shared/models/exports/exports-v2.model';
import {
  ComposedWebshopProducts,
  DisconnectedWebshopProducts,
  PredecessorProducts,
  WebshopProduct,
  WebshopProducts,
} from 'src/app/shared/models/products/v3/products.model';
import { PromotionWebshopProducts } from 'src/app/shared/models/promotion-products-v2/promotion-products-v2.model';
import { ProductsV3CompositionsAdapter } from './adapters/product-compositions.adapter';
import { ProductV3Adapter } from './adapters/product.adapter';
import { ProductsV3Adapter } from './adapters/products.adapter';
import { PromotionProductsV2Adapter } from './adapters/promotion-products.adapter';
import {
  ComposedWebshopProducts as ComposedWebshopProductsV1,
  PredecessorProducts as PredecessorProductsV1,
  SingleWebshopProduct as SingleWebshopProductV1,
  UpdateMultipleWebshopProductsProperties,
  UpdateWebshopProductProperties,
  WebshopProducts as WebshopProductsV1,
} from './model/products-v3.model';
import { PromotionWebshopProducts as PromotionWebshopProductsV1 } from './model/promotions-products-v3.model';
import { Issues as IssuesV1 } from '../../issues/model/issues.model';
import { ProductsV3AvailablePredecessorsAdapter } from './adapters/product-available-predecessors.adapter';
import { IssuesV2Adapter } from '../../issues/adapters/issues-v2.adapter';
import { Issue } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.model';
import {
  APIPageCount,
  DataPageableV2,
} from 'src/app/shared/models/datatable/v2/pageable-v2.model';
import { DatatablePaginationCountAdapter } from 'src/app/shared/adapter/datatable/datatable-pagination-count.adapter';
import { DisconnectedProductsV3Adapter } from './adapters/disconnected-products.adapter';

@Injectable({
  providedIn: 'root',
})
export class ProductsV3Service {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findAll(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<WebshopProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findAll',
      replacements
    );
    return this.http.post<WebshopProductsV1>(endpoint, payload);
  }

  private _findAllCount(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<APIPageCount> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findAllCount',
      replacements
    );
    return this.http.post<APIPageCount>(endpoint, payload);
  }

  private _update(
    payload: Partial<UpdateWebshopProductProperties>,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'update',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _multipleUpdate(
    payload: UpdateMultipleWebshopProductsProperties,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'multipleUpdate',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _findOne(
    replacements: UrlReplacement[]
  ): Observable<SingleWebshopProductV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findOne',
      replacements
    );
    return this.http.get<SingleWebshopProductV1>(endpoint);
  }

  private _findAllComposedProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ComposedWebshopProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findAllComposedProducts',
      replacements
    );
    return this.http.post<ComposedWebshopProductsV1>(endpoint, payload);
  }

  private _findAllPartProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ComposedWebshopProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findAllPartProducts',
      replacements
    );
    return this.http.post<ComposedWebshopProductsV1>(endpoint, payload);
  }

  private _exportProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3-exports',
      'export',
      replacements
    );
    return this.http.post(endpoint, payload, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  private _exportPromotionProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3-exports',
      'exportPromotionProducts',
      replacements
    );
    return this.http.post(endpoint, payload, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  private _findAllPromotionProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<PromotionWebshopProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findAllPromotionProducts',
      replacements
    );
    return this.http.post<PromotionWebshopProductsV1>(endpoint, payload);
  }

  private _findDisconnectedProductsInPromotion(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<WebshopProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findDisconnectedProductsInPromotion',
      replacements
    );
    return this.http.post<WebshopProductsV1>(endpoint, payload);
  }

  private _findProductIssues(
    replacements: UrlReplacement[]
  ): Observable<IssuesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findAllIssues',
      replacements
    );
    return this.http.get<IssuesV1>(endpoint);
  }

  private _findProductAvailablePredecessors(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<PredecessorProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'products',
      'v3',
      'findProductAvailablePredecessors',
      replacements
    );
    return this.http.post<PredecessorProductsV1>(endpoint, payload);
  }

  findAll(
    webshopUuid: string,
    payload: any
  ): Observable<Omit<WebshopProducts, 'metadata'>> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findAll(payload, replacements).pipe(
      switchMap((data: WebshopProductsV1) =>
        of(new ProductsV3Adapter().transform(data))
      )
    );
  }

  findAllCount(webshopUuid: string, payload: any): Observable<DataPageableV2> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findAllCount(payload, replacements).pipe(
      switchMap((data: APIPageCount) =>
        of(new DatatablePaginationCountAdapter().transform(data))
      )
    );
  }

  update(
    webshopUuid: string,
    webshopProductUuid: string,
    payload: Partial<UpdateWebshopProductProperties>
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: webshopProductUuid },
    ];

    return this._update(payload, replacements);
  }

  multipleUpdate(
    webshopUuid: string,
    payload: UpdateMultipleWebshopProductsProperties
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._multipleUpdate(payload, replacements);
  }

  findOne(
    webshopUuid: string,
    productUuid: string
  ): Observable<WebshopProduct> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: productUuid },
    ];

    return this._findOne(replacements).pipe(
      switchMap((data: SingleWebshopProductV1) =>
        of(new ProductV3Adapter().transform(data))
      )
    );
  }

  findAllComposedProducts(
    webshopUuid: string,
    productUuid: string,
    payload: any
  ): Observable<ComposedWebshopProducts> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: productUuid },
    ];

    return this._findAllComposedProducts(payload, replacements).pipe(
      switchMap((data: ComposedWebshopProductsV1) =>
        of(new ProductsV3CompositionsAdapter().transform(data))
      )
    );
  }

  findAllPartProducts(
    webshopUuid: string,
    productUuid: string,
    payload: any
  ): Observable<ComposedWebshopProducts> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: productUuid },
    ];

    return this._findAllPartProducts(payload, replacements).pipe(
      switchMap((data: ComposedWebshopProductsV1) =>
        of(new ProductsV3CompositionsAdapter().transform(data))
      )
    );
  }

  exportProducts(
    webshopUuid: string,
    payload: ExportRequestDataV2
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._exportProducts(payload, replacements);
  }

  exportPromotionProducts(
    webshopUuid: string,
    promotionUuid: string,
    payload: ExportRequestDataV2
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'promotionUuid', value: promotionUuid },
    ];

    return this._exportPromotionProducts(payload, replacements);
  }

  findAllPromotionProducts(
    webshopUuid: string,
    promotionUuid: string,
    payload: any
  ): Observable<PromotionWebshopProducts> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'promotionUuid', value: promotionUuid },
    ];

    return this._findAllPromotionProducts(payload, replacements).pipe(
      switchMap((data: PromotionWebshopProductsV1) =>
        of(new PromotionProductsV2Adapter().transform(data))
      )
    );
  }

  findDisconnectedProductsInPromotion(
    webshopUuid: string,
    promotionUuid: string,
    payload: any
  ): Observable<DisconnectedWebshopProducts> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'promotionUuid', value: promotionUuid },
    ];

    return this._findDisconnectedProductsInPromotion(
      payload,
      replacements
    ).pipe(
      switchMap((data: WebshopProductsV1) =>
        of(new DisconnectedProductsV3Adapter().transform(data))
      )
    );
  }

  findProductIssues(webshopUuid: string): Observable<Issue[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findProductIssues(replacements).pipe(
      switchMap(data => of(new IssuesV2Adapter().transform(data)))
    );
  }

  findProductAvailablePredecessors(
    webshopUuid: string,
    webshopProductUuid: string,
    payload: any
  ): Observable<PredecessorProducts> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: webshopProductUuid },
    ];

    return this._findProductAvailablePredecessors(payload, replacements).pipe(
      switchMap(data =>
        of(new ProductsV3AvailablePredecessorsAdapter().transform(data))
      )
    );
  }
}
