import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { SalesGraphOverview } from 'src/app/shared/models/sales/sales.model';

import { SalesGraphOverview as SalesGraphOverviewV1 } from './model/sales.model';

export class OverviewAdapter
  implements DataAdapter<SalesGraphOverviewV1, SalesGraphOverview>
{
  transform(data: SalesGraphOverviewV1): SalesGraphOverview {
    const salesGraph: SalesGraphOverview = {
      totalSales: data.totalSales,
    };

    return salesGraph;
  }
}
