import { ServiceRequestInfoV3QueryData } from '../../components/design-system/data-table-v2/model/pageable-v2.model';

export type ExportFileType = 'CSV' | 'XLSX';

export enum ExportFileTypes {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export interface ExportDataV2 {
  fileName: string;
  fileType: ExportFileType;
  onlySelected: boolean;
}

export type ExportQueryData = Omit<ServiceRequestInfoV3QueryData, 'page'>;

export interface ExportRequestDataV2 {
  visibleColumns: string[];
  fileType: ExportFileType;
  fileName: string;
  queryData: ExportQueryData;
  include: string[];
}
