import { MenuState } from 'src/app/shared/models/account/account-settings.model';

export class SetInitialMenuV2State {
  static readonly type = '[Nav Menu V2] Set Menu V2 Initial State';

  constructor(public menuState: MenuState) {}
}

export class SetMenuV2Options {
  static readonly type = '[Nav Menu V2] Set Menu V2 Options';
}

export class ToggleMenuVisilibity {
  static readonly type = '[Nav Menu V2] Toggle Menu Visilibity';
}

export class ToggleMenuExpansion {
  static readonly type = '[Nav Menu V2] Toggle Menu Expansion';
}
