<app-dialog-container>
  <div>
    <div appDialogHeader>
      <h1 appDialogTitle i18n="add supplier-products dialog title">
        {{ dialogTitle }}
      </h1>
      <button
        aria-label="Connect product to supplier dialog close button"
        (click)="close()"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>

    <div appDialogBody>
      <form #addProductForm="ngForm">
        <h3
          class="supplier-products-container-title"
          i18n="add supplier-products dialog form supplier-product details">
          Product Information
        </h3>

        <div class="supplier-product-name input-field">
          <label
            for="supplierProductName"
            i18n="add supplier-products dialog form supplier-product name label"
            >Select a product</label
          >

          <app-dropdown
            data-intercom-target="83fc6f33-8eb7-4e53-9b84-0a775bd9f30e"
            aria-label="Product add products to supplier"
            name="disconnectedProduct"
            [(ngModel)]="selectedProduct"
            (ngModelChange)="onProductSelected($event)"
            [loading]="loading$ | async"
            [placeholder]="PRODUCT_STRINGS.placeholder"
            panelWidth="400"
            [displayWith]="displayWithName">
            <app-dropdown-search
              (searched)="
                filterDisconnectedProducts($event)
              "></app-dropdown-search>

            <app-dropdown-option
              *ngFor="let product of products$ | async"
              [value]="product"
              >{{ product.name }}</app-dropdown-option
            >
          </app-dropdown>
        </div>

        <div class="divider"></div>

        <h3
          class="supplier-products-container-title"
          i18n="add supplier-products dialog form supplier-product details">
          Supplier Product Information
        </h3>

        <div class="supplier-product-name input-field">
          <label
            for="supplierProductName"
            i18n="add supplier-products dialog form supplier-product name label"
            >Supplier Product Name</label
          >

          <div class="fields-input-container">
            <input
              appInput
              variant="filled"
              id="supplierProductName"
              type="text"
              name="supplierProductName"
              [placeholder]="COMMON_STRINGS.enterText"
              [ngModel]="name$ | async"
              (keydown.enter)="$event.preventDefault()"
              (change)="onNameChanged($event.target.value)"
              [disabled]="(isProductSelected$ | async) === false"
              required />
          </div>
        </div>
        <div class="dates-container">
          <div class="start-date small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product start date
                label
              "
              >Supplier Product SKU</label
            >
            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                id="supplierProductSku"
                type="text"
                name="supplierProductSku"
                [placeholder]="COMMON_STRINGS.enterText"
                [ngModel]="sku$ | async"
                (keydown.enter)="$event.preventDefault()"
                (change)="onSkuChanged($event.target.value)"
                [disabled]="(isProductSelected$ | async) === false" />
            </div>
          </div>

          <div class="ean-code small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product start date
                label
              "
              >Supplier Product EAN</label
            >

            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                id="supplierProductEAN"
                type="text"
                name="supplierProductEAN"
                [placeholder]="COMMON_STRINGS.enterText"
                [ngModel]="eanCode$ | async"
                (keydown.enter)="$event.preventDefault()"
                (change)="onEanChanged($event.target.value)"
                [disabled]="(isProductSelected$ | async) === false" />
            </div>
          </div>
        </div>

        <div class="divider"></div>

        <div class="supplier-products-container">
          <h3
            class="supplier-products-container-title"
            i18n="add supplier-products dialog form supplier-product details">
            Purchase Information
          </h3>

          <div class="dates-container">
            <div class="start-date small-input-field input-field">
              <label
                for="startDate"
                i18n="
                  add supplier-products dialog form supplier-product start date
                  label
                "
                >Purchase Price</label
              >
              <div class="fields-input-container">
                <div appInputWrapper>
                  <span appInputSuffix>{{ currencySymbol$ | async }}</span>
                  <input
                    appInput
                    variant="filled"
                    id="purchasePrice"
                    type="number"
                    name="purchasePrice"
                    placeholder="1"
                    min="0.01"
                    step="0.01"
                    (keydown.enter)="$event.preventDefault()"
                    [ngModel]="price$ | async"
                    (input)="onPriceChanged($event.target.value)"
                    [disabled]="(isProductSelected$ | async) === false"
                    required />
                </div>
              </div>
            </div>

            <div class="free-stock small-input-field input-field">
              <label
                for="startDate"
                i18n="
                  add supplier-products dialog form supplier-product start date
                  label
                "
                >Min. Order Quantity</label
              >

              <div class="fields-input-container">
                <input
                  appInput
                  variant="filled"
                  id="minimumOrderQuantity"
                  type="number"
                  name="minimumOrderQuantity"
                  placeholder="0"
                  min="0"
                  step="1"
                  [ngModel]="moq$ | async"
                  (keydown.enter)="$event.preventDefault()"
                  (input)="onMoQChanged($event.target.value)"
                  [disabled]="(isProductSelected$ | async) === false"
                  required />
              </div>
            </div>

            <div class="free-stock small-input-field input-field">
              <label
                for="startDate"
                i18n="
                  add supplier-products dialog form supplier-product start date
                  label
                "
                >Lot Size</label
              >

              <div class="fields-input-container">
                <input
                  appInput
                  variant="filled"
                  id="lotSize"
                  type="number"
                  name="lotSize"
                  placeholder="0"
                  min="0"
                  step="1"
                  [ngModel]="lotSize$ | async"
                  (keydown.enter)="$event.preventDefault()"
                  (input)="onLotSizeChanged($event.target.value)"
                  [disabled]="(isProductSelected$ | async) === false"
                  required />
              </div>
            </div>
          </div>

          <div class="divider"></div>

          <h3
            class="supplier-products-container-title"
            i18n="add supplier-products dialog form settings">
            Options
          </h3>

          <div class="options-container">
            <div class="availability-checkbox-container">
              <div class="enabled">
                <mat-icon svgIcon="check" class="icon success"></mat-icon>
                <span class="description">
                  <span
                    class="title"
                    i18n="connect product available setting title"
                    >Available</span
                  >
                  <span
                    class="subtitle"
                    i18n="connect product available setting subtitle"
                    >Mark as an available supplier</span
                  >
                </span>
              </div>
              <app-toggle
                [ngModel]="availability$ | async"
                (ngModelChange)="onAvailabilityChanged($event)"
                name="availability">
              </app-toggle>
            </div>

            <div class="preferred-checkbox-container">
              <div class="enabled">
                <mat-icon
                  svgIcon="verified-badge"
                  class="icon primary"></mat-icon>
                <span class="description">
                  <span
                    class="title"
                    i18n="connect product preferred setting title"
                    >Preferred</span
                  >
                  <span
                    class="subtitle"
                    i18n="connect product preferred setting subtitle"
                    >Mark as the preferred supplier</span
                  >
                </span>
              </div>
              <app-toggle
                [ngModel]="preferred$ | async"
                (ngModelChange)="onPreferredChanged($event)"
                name="preferred">
              </app-toggle>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div appDialogActions class="footer">
      <div class="action-add-another">
        <app-checkbox
          [(checked)]="addAnother"
          [label]="COMMON_STRINGS.addAnother"></app-checkbox>
      </div>

      <div class="action-buttons-container">
        <button
          aria-label="Connect product to supplier dialog cancel button"
          appButton
          variant="tertiary"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          appButton
          variant="primary"
          [disabled]="
            addProductForm.invalid ||
            (isProductSelected$ | async) === false ||
            (loading$ | async)
          "
          (click)="save()">
          {{ COMMON_STRINGS.save }}
          <app-progress-spinner *ngIf="saving$ | async"></app-progress-spinner>
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
