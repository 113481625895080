import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { OpenAddSourceDialog } from 'src/app/features/settings/actions/integrations.action';

@Component({
  selector: 'app-no-sources',
  templateUrl: './no-sources.component.html',
  styleUrl: './no-sources.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoSourcesComponent {
  constructor(private store: Store) {}

  /**
   * opens the add source dialog
   */
  openAddSourceDialog(): void {
    this.store.dispatch(new OpenAddSourceDialog());
  }
}
