import { DecimalPipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocaleService } from 'src/app/core/services/locale.service';
import { LocaleState } from 'src/app/core/states/locale.state';
import { Locale } from '../models/locale.model';

/**
 * Custom decimal pipe that takes the locale into consideration.
 * Use this pipe instead of the DecimalPipe.
 */
@Pipe({
  name: 'customDecimal',
})
export class CustomDecimalPipe
  extends DecimalPipe
  implements PipeTransform, OnDestroy
{
  private destroy$ = new Subject<void>();
  currentLocaleState: Locale;

  constructor(
    public localeService: LocaleService,
    private store: Store
  ) {
    super(localeService.locale);

    this.store
      .select(LocaleState.currentLocale)
      .pipe(takeUntil(this.destroy$))
      .subscribe(locale => {
        this.currentLocaleState = locale;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  transform(value: any, digitsInfo?: string): any {
    if (!isNaN(value)) {
      const transformedDecimalValue = super.transform(
        value,
        digitsInfo,
        this.localeService.locale
      );

      if (
        this.currentLocaleState &&
        this.currentLocaleState?.decimalSeparator === 'comma'
      ) {
        return transformedDecimalValue.replace(/\./g, ' ');
      }

      return transformedDecimalValue.replace(/\,/g, ' ');
    }
  }
}
