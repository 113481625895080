import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appToastAction]',
})
export class ToastActionDirective {
  @HostBinding('class')
  class: string = 'toast-action';
}

@Directive({
  selector: '[appToastSecondaryAction]',
})
export class ToastSecondaryActionDirective {
  @HostBinding('class')
  class: string = 'toast-secondary-action';
}
