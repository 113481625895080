import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  locale = 'en-US';

  constructor(@Inject(LOCALE_ID) protected localeId: string) {
    this.locale = localeId;
  }

  redirect(localeId: string): void {
    window.location.href = environment.base_path + localeId + '/';
  }
}
