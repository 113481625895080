import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { catchError, concatMap, delay, mergeMap, Observable, tap } from 'rxjs';
import {
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { MultiSupplierBuilderService } from 'src/app/core/services/multi-supplier-builder.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { LoadProduct } from 'src/app/features/products-v2/components/product-details-v2/actions/product-details-v2.actions';
import {
  BaseLinkedSupplierProductsEditorStateModel,
  EditedLinkedSupplierProduct,
} from 'src/app/shared/models/supplier-product/v2/editor/editor.model';
import { LinkedSupplierProductEditorListener } from 'src/app/shared/models/supplier-product/v2/editor/supplier-product-v2-editor-listener.model';
import { BaseLinkedSupplierProductsEditorState } from 'src/app/shared/models/supplier-product/v2/editor/supplier-product-v2-editor.state';
import {
  SaveLinkedSupplierProductsProperties,
  SupplierProductLink,
} from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import {
  RemoveLinkSupplierProduct,
  SaveLinkedSupplierProductsEditor,
  UpdateProductAvailability,
  UpdateProductAvailabilityDate,
  UpdateProductDeliveryTime,
  UpdateProductEAN,
  UpdateProductLotSize,
  UpdateProductMOQ,
  UpdateProductPreferred,
  UpdateProductPrice,
  UpdateProductSku,
  UpdateProductVolume,
  UpdateProductWeight,
  UpdateSupplierProductName,
} from '../actions/product-suppliers-v2-editor.actions';
import {
  CancelEditWebshopProductSuppliers,
  LoadWebshopProductSuppliers,
} from '../actions/product-suppliers-v2.actions';
import { LoadSupplyChainInformation } from '../../supply-chain-information-v2/actions/supply-chain-information-v2.actions';
import { SuppliersV2Service } from 'src/app/core/api/supply/v2/suppliers-v2.service';

export interface LinkedSupplierProductsEditorStateModel
  extends BaseLinkedSupplierProductsEditorStateModel<EditedLinkedSupplierProduct> {
  saving: boolean;
}

@State<LinkedSupplierProductsEditorStateModel>({
  name: 'webshopProductSuppliersEditorState',
  defaults: {
    saving: false,
    editedLinks: [],
  },
})
@Injectable()
export class LinkedSupplierProductsEditorState extends BaseLinkedSupplierProductsEditorState<SupplierProductLink> {
  constructor(
    private store: Store,
    private suppliersV2Service: SuppliersV2Service,
    private multiSupplierBuilderService: MultiSupplierBuilderService
  ) {
    super(new LinkedSupplierProductEditorListener());
  }

  @Action(CancelEditWebshopProductSuppliers, { cancelUncompleted: true })
  cancelEdit(ctx: StateContext<LinkedSupplierProductsEditorStateModel>) {
    ctx.patchState({
      saving: false,
      editedLinks: [],
    });
  }

  @Action(UpdateProductPreferred, { cancelUncompleted: true })
  updateProductPreferred(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductPreferred
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updatePreferredForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updatePreferredForExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateProductAvailability, { cancelUncompleted: true })
  updateProductAvailability(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductAvailability
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateAvailabilityForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateAvailabilityForExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateProductAvailabilityDate, { cancelUncompleted: true })
  updateProductAvailabilityDate(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductAvailabilityDate
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateAvailabilityDateForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateAvailabilityDateForExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateSupplierProductName, { cancelUncompleted: true })
  updateSupplierProductName(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateSupplierProductName
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateSupplierProductNameForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateSupplierProductNameorExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateProductSku, { cancelUncompleted: true })
  updateProductSKU(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductSku
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateSKUForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateSKUForExistingEditedSupplierProduct(ctx, payload, lines[0]);
    }
  }

  @Action(UpdateProductLotSize, { cancelUncompleted: true })
  updateProductLotSize(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductLotSize
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateLotSizeForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateLotSizeForExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateProductEAN, { cancelUncompleted: true })
  updateProductEAN(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductEAN
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateEANForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateEANForExistingEditedSupplierProduct(ctx, payload, lines[0]);
    }
  }

  @Action(UpdateProductMOQ, { cancelUncompleted: true })
  updateProductMOQ(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductMOQ
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateMOQForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateMOQForExistingEditedSupplierProduct(ctx, payload, lines[0]);
    }
  }

  @Action(UpdateProductDeliveryTime, { cancelUncompleted: true })
  updateProductDeliveryTime(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductDeliveryTime
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateDeliveryTimeForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateDeliveryTimeForExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateProductWeight, { cancelUncompleted: true })
  updateProductWeight(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductWeight
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateWeightForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateWeightForExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateProductVolume, { cancelUncompleted: true })
  updateProductVolume(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductVolume
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updateVolumeForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updateVolumeForExistingEditedSupplierProduct(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateProductPrice, { cancelUncompleted: true })
  updateProductPrice(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductPrice
  ) {
    const state = ctx.getState();

    const lines = this._getSupplierLine(
      payload.data.supplier.id,
      state.editedLinks
    );

    if (lines.length > 1) {
      return this.duplicatedEditedSuppliersError(payload.data.supplier.id);
    }

    if (lines.length === 0) {
      this._updatePriceForNewEditedSupplierProduct(ctx, payload);
    } else if (lines.length === 1) {
      this._updatePriceForExistingEditedSupplierProduct(ctx, payload, lines[0]);
    }
  }

  @Action(SaveLinkedSupplierProductsEditor, { cancelUncompleted: true })
  save(ctx: StateContext<LinkedSupplierProductsEditorStateModel>) {
    ctx.patchState({ saving: true });

    const state = ctx.getState();

    const webshop = this.store.selectSnapshot(WebshopState.selected);

    const editedLinkedSupplierProducts =
      this.multiSupplierBuilderService.buildMultiSupplierFromResult(
        state.editedLinks
      );
    const properties: SaveLinkedSupplierProductsProperties = {
      webshopId: String(webshop.id),
      products: [...editedLinkedSupplierProducts.products],
    };

    window.rudderanalytics.track('Supplier Product Updated', {
      webshopId: String(webshop.id),
      products: [...editedLinkedSupplierProducts.products],
    });

    return this._saveLinkedSupplierProducts(ctx, properties).pipe(
      tap(() => {
        ctx.patchState({
          saving: false,
          editedLinks: [],
        });
      }),
      concatMap(() =>
        ctx.dispatch([
          new CancelEditWebshopProductSuppliers(),
          new LoadWebshopProductSuppliers(),
          new SaveSucceed(),
        ])
      ),
      delay(500),
      mergeMap(() => ctx.dispatch(new LoadSupplyChainInformation()))
    );
  }

  @Action(RemoveLinkSupplierProduct, {
    cancelUncompleted: true,
  })
  removeSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: RemoveLinkSupplierProduct
  ): Observable<any> {
    const webshop = this.store.selectSnapshot(WebshopState.selected);

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._deleteSupplierProduct(ctx, webshop.uuid, payload)),
      concatMap(() =>
        ctx.dispatch([
          new CancelEditWebshopProductSuppliers(),
          new SaveSucceed(
            true,
            MESSAGES.notifications.supplierProduct.deleteSupplierProductSuccess
          ),
        ])
      ),
      mergeMap(() => ctx.dispatch(new LoadProduct())),
      concatMap(() => ctx.dispatch(new LoadWebshopProductSuppliers())),
      delay(500),
      mergeMap(() => ctx.dispatch(new LoadSupplyChainInformation())),
      catchError(e => {
        ctx.dispatch([
          new SaveFailed(
            true,
            MESSAGES.notifications.supplierProduct.deleteSupplierProductFailed
          ),
          new LoadProduct(),
        ]);

        throw new Error(e.message || e);
      })
    );
  }

  /* Preferred */
  private _updatePreferredForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductPreferred
  ) {
    const state = ctx.getState();
    const result = this._buildUpdatePreferredNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updatePreferredForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductPreferred,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdatePreferredExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* Name */
  private _updateSupplierProductNameForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductSku
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateSupplierProductNameNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateSupplierProductNameorExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductSku,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateSupplierProductNameExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* Sku */
  private _updateSKUForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductSku
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateSKUNewSupplier(payload.data, payload.value);

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateSKUForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductSku,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateSKUExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* Availability */
  private _updateAvailabilityForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductAvailability
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateAvailabilityNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateAvailabilityForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductAvailability,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateAvailabilityExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* AvailabilityDate */
  private _updateAvailabilityDateForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductAvailabilityDate
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateAvailabilityDateNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateAvailabilityDateForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductAvailabilityDate,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateAvailabilityDateExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* LotSize */
  private _updateLotSizeForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductLotSize
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateLotSizeNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateLotSizeForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductLotSize,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateLotSizeExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* EANCode */
  private _updateEANForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductEAN
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateEANNewSupplier(payload.data, payload.value);

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateEANForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductEAN,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateEANExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* MOQ */
  private _updateMOQForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductMOQ
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateMOQNewSupplier(payload.data, payload.value);

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateMOQForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductMOQ,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateMOQExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* Delivery Time */
  private _updateDeliveryTimeForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductDeliveryTime
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateDeliveryTimeNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateDeliveryTimeForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductDeliveryTime,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateDeliveryTimeExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* Weight */
  private _updateWeightForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductWeight
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateWeightNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateWeightForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductWeight,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateWeightExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* Volume */
  private _updateVolumeForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductVolume
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateVolumeNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updateVolumeForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductVolume,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateVolumeExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  /* Price */
  private _updatePriceForNewEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductPrice
  ) {
    const state = ctx.getState();
    const result = this._buildUpdatePriceNewSupplier(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...state.editedLinks, ...[result.newEditedSupplierProduct]],
    });
  }

  private _updatePriceForExistingEditedSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: UpdateProductPrice,
    editedLinkedSupplierProduct: EditedLinkedSupplierProduct
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdatePriceExistingEditedSupplier(
      payload.data,
      editedLinkedSupplierProduct,
      state.editedLinks,
      payload.value
    );

    ctx.patchState({
      editedLinks: [...result.newLines, ...[result.newEditedSupplierProduct]],
    });
  }

  private _saveLinkedSupplierProducts(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    payload: SaveLinkedSupplierProductsProperties
  ): Observable<any> {
    return this.suppliersV2Service.updateMultipleSupplierProducts(payload).pipe(
      catchError(e => {
        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _deleteSupplierProduct(
    ctx: StateContext<LinkedSupplierProductsEditorStateModel>,
    webshopUuid: string,
    payload: RemoveLinkSupplierProduct
  ): Observable<any> {
    return this.suppliersV2Service
      .deleteSupplierProduct(webshopUuid, payload.supplierProductUuid)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }
}
