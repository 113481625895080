export class SetOrderEmails {
  static readonly type = '[Email Management] Set Order Emails';

  constructor(public emails: string[]) {}
}

export class SendEmail {
  static readonly type = '[Email Management] Send Email';

  constructor(public orderUuid: string) {}
}
