<app-dialog-container
  ><div appDialogHeader>
    <mat-icon svgIcon="pencil-edit" class="menu-icon"></mat-icon>

    <h1 appDialogTitle i18n="rename export title">Rename</h1>

    <button
      data-intercom-target="4eb580c5-df83-41c4-baae-024a92b8d500"
      aria-label="Rename export dialog close button"
      (click)="close()"
      class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div appDialogBody>
    <form #form="ngForm" class="rename-export-input-section">
      <div class="fields-input-container">
        <input
          appInput
          variant="filled"
          name="name"
          [ngModel]="name()"
          (ngModelChange)="name.set($event)"
          [placeholder]="METADATA_STRINGS.placeholder"
          pattern="^[^.]*$"
          required />
      </div>
    </form>
  </div>

  <div appDialogActions>
    <div class="action-buttons">
      <button
        data-intercom-target="867b9079-8739-421c-8cb1-1c957509bf92"
        aria-label="Rename export dialog cancel button"
        appButton
        variant="tertiary"
        (click)="close()">
        {{ COMMON_STRINGS.cancel }}
      </button>

      <button
        data-intercom-target="4c5fb281-3fa6-4d46-a795-1ebe512b98ca"
        aria-label="Rename export dialog save button"
        appButton
        variant="primary"
        [disabled]="form.invalid || (saving$ | async)"
        (click)="renameExport()">
        {{ COMMON_STRINGS.save }}
      </button>
    </div>
  </div>
</app-dialog-container>
