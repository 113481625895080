import { Injectable } from '@angular/core';
import {
  Action,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { catchError, concatMap, mergeMap, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { SupplierProductV2Service } from 'src/app/core/api/supplier-product/v2/supplier-product-v2.service';

import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AccountState } from 'src/app/core/states/account.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { LoadProduct } from 'src/app/features/products-v2/components/product-details-v2/actions/product-details-v2.actions';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import {
  SaveLinkedSupplierProductProperties,
  SupplierProductLink,
  SupplierProductLinks,
} from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import {
  SupplierManualOrder,
  SuppliersManualOrder,
} from 'src/app/shared/models/suppliers/supplier.model';
import {
  AddFilterParam,
  AddSearchParam,
  CancelEditWebshopProductSuppliers,
  ColumnsSelected,
  EditWebshopProductSuppliers,
  ExportProductSuppliersV2,
  HideColumn,
  InitializeWebshopProductSuppliersState,
  LinkSupplierToAProduct,
  LoadSuppliersToLink,
  LoadWebshopProductSuppliers,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetPagination,
  ResetPaginationAndLoadData,
  Sort,
  ToggleFilter,
} from '../actions/product-suppliers-v2.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/data-table.model';
import {
  ExportDataV2,
  ExportRequestDataV2,
} from 'src/app/shared/models/exports/exports-v2.model';
import { getFileName } from 'src/app/shared/utils/exports';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { LoadSupplyChainInformation } from '../../supply-chain-information-v2/actions/supply-chain-information-v2.actions';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  defaultDatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { SuppliersV2Service } from 'src/app/core/api/supply/v2/suppliers-v2.service';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface WebshopProductSuppliersStateModel extends DatatableStateModel {
  loading: boolean;
  editing: boolean;
  links: SupplierProductLink[];
  availableSuppliers: SupplierManualOrder[];
  page: Pageable;
  editableColumns: string[];
}

@State<WebshopProductSuppliersStateModel>({
  name: 'webshopProductSuppliersState',
  defaults: {
    ...defaultDatatableStateModel,
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    sortBy: defaultSort,
    search: [],
    filterOpened: false,
    loading: false,
    editing: false,
    links: [],
    page: { totalElements: 0 },
    availableSuppliers: [],
    editableColumns: [
      STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_PREFERRED.key,
      STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_AVAILABLE.key,
      STRINGS.columns.productSuppliers.AVAILABLE_DATE.key,
      STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_NAME.key,
      STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_SKU.key,
      STRINGS.columns.productSuppliers.PURCHASE_IN_QUANTITIES_OF.key,
      STRINGS.columns.productSuppliers.SUPPLIER_PRODUCT_EAN_CODE.key,
      STRINGS.columns.productSuppliers.MINIMUM_PURCHASE_QUANTITY.key,
      STRINGS.columns.productSuppliers.LEAD_TIME.key,
      STRINGS.columns.productSuppliers.WEIGHT.key,
      STRINGS.columns.productSuppliers.VOLUME.key,
      STRINGS.columns.productSuppliers.PRICE.key,
      STRINGS.columns.productSuppliers.ACTIONS.key,
    ],
  },
})
@Injectable()
export class WebshopProductSuppliersState extends DatatableState {
  private readonly notExportableColumns: string[] = [
    STRINGS.columns.productSuppliers.ACTIONS.key,
  ];

  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [WebshopProductSuppliersState],
      (state: WebshopProductSuppliersStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  constructor(
    private store: Store,
    private accountSettingsService: AccountSettingsService,
    private supplierProductV2Service: SupplierProductV2Service,
    private suppliersV2Service: SuppliersV2Service,
    private notificationCenter: NotificationCenterService
  ) {
    super();
  }

  @Action(InitializeWebshopProductSuppliersState, { cancelUncompleted: true })
  initialize(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.productInformationSupplierProductsTableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<WebshopProductSuppliersStateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch(new LoadWebshopProductSuppliers());
  }

  @Action(LoadWebshopProductSuppliers, { cancelUncompleted: true })
  loadProductSuppliers(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    ctx.patchState({
      loading: true,
    });

    return this._findLinkedSupplierProducts(ctx).pipe(
      tap((linkedSupplierProducts: SupplierProductLinks) => {
        ctx.patchState({
          loading: false,
          links: linkedSupplierProducts.data,
          page: {
            totalElements: linkedSupplierProducts.metadata.page.totalElements,
          },
        });
      })
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    return ctx.dispatch(new LoadWebshopProductSuppliers());
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: Paginate
  ) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadWebshopProductSuppliers())),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadWebshopProductSuppliers());
  }

  @Action(AddFilterParam, { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(AddSearchParam, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<WebshopProductSuppliersStateModel>({
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(RemoveSearchParam, { cancelUncompleted: true })
  removeSearchParam(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<WebshopProductSuppliersStateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: Sort
  ) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: HideColumn
  ) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(RemoveAllFilters, { cancelUncompleted: true })
  removeAllFilters(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(
    ctx: StateContext<WebshopProductSuppliersStateModel>
  ) {
    this._removeAllFilters(ctx);
  }

  @Action(ToggleFilter, { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(EditWebshopProductSuppliers, { cancelUncompleted: true })
  edit(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    ctx.setState(
      patch<WebshopProductSuppliersStateModel>({
        editing: true,
      })
    );
  }

  @Action(CancelEditWebshopProductSuppliers, { cancelUncompleted: true })
  cancel(ctx: StateContext<WebshopProductSuppliersStateModel>) {
    ctx.setState(
      patch<WebshopProductSuppliersStateModel>({
        editing: false,
      })
    );
  }

  @Action(LoadSuppliersToLink, { cancelUncompleted: true })
  loadMultiSupplierProductsForEditor(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    action: LoadSuppliersToLink
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this._fetchSupplierProducts(ctx, webshopUuid, action.search).pipe(
      tap(supplierData => {
        const state = ctx.getState();

        ctx.patchState({
          ...state,
          availableSuppliers: [
            ...this._filterAvailableSuppliers(
              ctx.getState(),
              supplierData.suppliers
            ),
          ],
        });
      })
    );
  }

  @Action(LinkSupplierToAProduct, { cancelUncompleted: true })
  createProductSuppliers(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: LinkSupplierToAProduct
  ) {
    const properties: SaveLinkedSupplierProductProperties = {
      webshopId: String(this.store.selectSnapshot(WebshopState.selected).id),
      product: payload.properties,
    };

    return ctx
      .dispatch(
        new Saving(
          true,
          MESSAGES.notifications.supplierProduct.addingSupplierProduct
        )
      )
      .pipe(
        mergeMap(() => this._createSupplierProduct(ctx, properties)),
        concatMap(() =>
          ctx.dispatch([
            new LoadProduct(),
            new LoadWebshopProductSuppliers(),
            new SaveSucceed(
              true,
              MESSAGES.notifications.supplierProduct.deleteSupplierProductSuccess
            ),
          ])
        ),
        tap(() => {
          ctx.patchState({
            availableSuppliers: [],
          });
        }),
        catchError(e => {
          ctx.patchState({
            availableSuppliers: [],
          });

          return ctx.dispatch([
            new LoadProduct(),
            new LoadWebshopProductSuppliers(),
            new LoadSupplyChainInformation(),
            new SaveFailed(true, e.message || e),
          ]);
        })
      );
  }

  @Action(ExportProductSuppliersV2, { cancelUncompleted: true })
  exporPromotionProducts(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: ExportProductSuppliersV2
  ) {
    this.notificationCenter.showToast(
      MESSAGES.notifications.exports.exportProductSuppliersDispatched
    );

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    const exportRequestInfo: ExportRequestDataV2 = this._buildExportData(
      ctx,
      payload.exportData
    );

    return this.suppliersV2Service
      .exportProductSuppliers(
        webshopUuid,
        webshopProductUuid,
        exportRequestInfo
      )
      .pipe(
        catchError(e => {
          this.notificationCenter.showToastError(
            MESSAGES.notifications.exports.exportProductSuppliersFailed
          );
          throw new Error(e.message || e);
        })
      )
      .subscribe(response => {
        this.notificationCenter.showToastSuccess(
          MESSAGES.notifications.exports.exportProductSuppliersSuccessful
        );
        const exportData: any = {
          data: response.body,
          fileName: response.headers.get('Content-Disposition'),
          fileType: response.headers.get('content-type'),
        };
        const a = document.createElement('a');
        const blob = new Blob([exportData.data], { type: exportData.fileType });
        a.href = window.URL.createObjectURL(blob);
        a.download = getFileName(exportData.fileName);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
      });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(
    ctx: StateContext<WebshopProductSuppliersStateModel>
  ) {
    return ctx.dispatch([
      new ResetPagination(),
      new LoadWebshopProductSuppliers(),
    ]);
  }

  override addGroupParam(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<WebshopProductSuppliersStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    sort: Sorted
  ): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadWebshopProductSuppliers());
  }

  private _removeAllFilters(
    ctx: StateContext<WebshopProductSuppliersStateModel>
  ) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _findLinkedSupplierProducts(
    ctx: StateContext<WebshopProductSuppliersStateModel>
  ): Observable<SupplierProductLinks | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    const requestInfo = this._buildFindLinkedSupplierProductsRequestInfo(
      ctx.getState()
    );

    return this.supplierProductV2Service
      .findLinkedSupplierProducts(webshopUuid, webshopProductUuid, requestInfo)
      .pipe(
        catchError(() => {
          ctx.patchState({
            loading: false,
            links: [],
            page: { totalElements: 0 },
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildFindLinkedSupplierProductsRequestInfo(
    state: WebshopProductSuppliersStateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      productInformationSupplierProductsTableExcludedColumns: excludedColumns,
      overrideProductInformationSupplierProductsTableExcludedColumns: true,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      productInformationSupplierProductsTablePageSize: newPageSize,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _fetchSupplierProducts(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    webshopUuid: string,
    search: string
  ): Observable<SuppliersManualOrder> {
    return this.suppliersV2Service.fetchSuppliers(webshopUuid, search).pipe(
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _filterAvailableSuppliers(
    state: WebshopProductSuppliersStateModel,
    suppliers: SupplierManualOrder[]
  ): SupplierManualOrder[] {
    const existingSupplierProducts = state.links;

    let availableSuppliers: SupplierManualOrder[] = suppliers;

    existingSupplierProducts.forEach((supplierProduct: SupplierProductLink) => {
      availableSuppliers = availableSuppliers.filter(
        x => String(x.uuid) !== supplierProduct.supplier.uuid
      );
    });

    return availableSuppliers;
  }

  private _createSupplierProduct(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    payload: SaveLinkedSupplierProductProperties
  ): Observable<any> {
    return this.suppliersV2Service.createSupplierProducts(payload).pipe(
      catchError(e => {
        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _buildExportData(
    ctx: StateContext<WebshopProductSuppliersStateModel>,
    exportData: ExportDataV2
  ): ExportRequestDataV2 {
    const filtersWithoutGroups: Filter[] = Object.values(
      ctx.getState().filtersGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const columnsWithoutGroups: DatatableColumnV2[] = Object.values(
      ctx.getState().columnsGroups
    )
      .map(group => Object.values(group.columns))
      .flat();

    const visibleColumns: string[] = columnsWithoutGroups
      .filter(
        (column: DatatableColumnV2) =>
          column.checked && !this.notExportableColumns.includes(column.name)
      )
      .map((column: DatatableColumnV2) => column.name);

    return {
      visibleColumns,
      fileName: exportData.fileName,
      fileType: exportData.fileType,
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(ctx.getState().sortBy),
      },
      include: [],
    };
  }
}
