import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { DateTime } from 'luxon';
import { catchError, concatMap, mergeMap, Observable, of, tap } from 'rxjs';
import { SalesService } from 'src/app/core/api/sales/v1/sales.service';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { WebshopState } from 'src/app/core/states/webshop.state';

import * as echarts from 'echarts/lib/echarts';
import { SalesGraphOverview } from 'src/app/core/api/sales/v1/model/sales.model';

import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import {
  GraphFilter,
  GraphGroupBy,
  SalesGraph,
  SalesGraphData,
  SalesOptions,
} from 'src/app/shared/models/sales/sales-v2.model';
import {
  LoadProductSalesV2,
  ProductSalesV2Refresh,
  ProductSalesV2Reset,
  ProductSalesV2SelectFilter,
  ProductSalesV2SelectGroupBy,
  ProductSalesV2SetInitialState,
} from '../actions/sales-v2.actions';
import {
  defaultSalesV2,
  FetchSalesOverviewProperties,
  FetchSalesProperties,
  SalesV2,
} from '../model/sales-v2.model';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { AccountState } from 'src/app/core/states/account.state';
import { SaveFailed } from 'src/app/core/actions/app.action';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';

export interface ProductSalesV2StateModel extends SalesV2 {}

@State<ProductSalesV2StateModel>({
  name: 'productSalesV2State',
  defaults: defaultSalesV2,
})
@Injectable()
export class ProductSalesV2State {
  constructor(
    private store: Store,
    private salesService: SalesService,
    private accountSettingsService: AccountSettingsService,
    private notificationCenter: NotificationCenterService
  ) {}

  @Action(ProductSalesV2SetInitialState)
  setInitialState(ctx: StateContext<ProductSalesV2StateModel>) {
    const salesGraphConfig = this.store.selectSnapshot(
      AccountSettingsState.productInformationSalesGraph
    );

    ctx.setState(
      patch<ProductSalesV2StateModel>({
        graph: patch<SalesGraph>({
          filter: salesGraphConfig.historyLength,
          groupBy: salesGraphConfig.grouping,
        }),
      })
    );

    return ctx.dispatch(new LoadProductSalesV2());
  }

  @Action(LoadProductSalesV2, { cancelUncompleted: true })
  loadSales(ctx: StateContext<ProductSalesV2StateModel>) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    ctx.setState(
      patch<ProductSalesV2StateModel>({
        loading: true,
      })
    );

    return ctx.dispatch(new ProductSalesV2Reset()).pipe(
      concatMap(() =>
        this._fetchSalesOverview(ctx, {
          webshopProductUuid,
          webshopUuid,
          filter: ctx.getState().graph.filter,
        })
      ),
      tap((sales: SalesGraphOverview) => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            totalSales: sales.totalSales,
          })
        );
      }),
      mergeMap(() =>
        this._fetchSales(ctx, {
          webshopProductUuid,
          webshopUuid,
          groupBy: ctx.getState().graph.groupBy,
          filter: ctx.getState().graph.filter,
        })
      ),
      tap((salesGraph: SalesGraphData) => {
        this._updateSalesOptions(ctx, salesGraph);
      }),
      tap(() => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            loading: false,
          })
        );
      }),
      catchError(() => {
        this._setNoDataErrorMessage(ctx);

        return of(null);
      })
    );
  }

  @Action(ProductSalesV2SelectFilter, { cancelUncompleted: true })
  selectFilter(
    ctx: StateContext<ProductSalesV2StateModel>,
    payload: ProductSalesV2SelectFilter
  ) {
    const state = ctx.getState();

    const groupBy = state.graph.groupBy;

    return this._filterSales(ctx, payload.filter, groupBy);
  }

  @Action(ProductSalesV2SelectGroupBy, { cancelUncompleted: true })
  selectGroupBy(
    ctx: StateContext<ProductSalesV2StateModel>,
    payload: ProductSalesV2SelectGroupBy
  ) {
    const state = ctx.getState();

    const filter = state.graph.filter;

    return this._filterSales(ctx, filter, payload.groupBy);
  }

  @Action(ProductSalesV2Refresh, { cancelUncompleted: true })
  refresh(ctx: StateContext<ProductSalesV2StateModel>) {
    ctx.setState(
      patch<ProductSalesV2StateModel>({
        loading: true,
        totalSales: 0,
      })
    );

    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    const filter = state.graph.filter;
    const groupBy = state.graph.groupBy;

    return ctx.dispatch(new ProductSalesV2Reset()).pipe(
      tap(() => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            graph: patch<SalesGraph>({
              filter,
              groupBy,
            }),
          })
        );
      }),
      concatMap(() =>
        this._fetchSalesOverview(ctx, {
          webshopProductUuid,
          webshopUuid,
          filter,
        })
      ),
      tap((sales: SalesGraphOverview) => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            totalSales: sales.totalSales,
          })
        );
      }),
      concatMap(() =>
        this._fetchSales(ctx, {
          webshopProductUuid,
          webshopUuid,
          groupBy,
          filter,
        })
      ),
      tap((salesGraph: SalesGraphData) => {
        this._updateSalesOptions(ctx, salesGraph);
      }),
      tap(() => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            loading: false,
            graph: patch<SalesGraph>({
              filter,
              groupBy,
            }),
          })
        );
      }),
      catchError(() => {
        this._setNoDataErrorMessage(ctx);

        return of(null);
      })
    );
  }

  @Action(ProductSalesV2Reset, { cancelUncompleted: true })
  reset(ctx: StateContext<ProductSalesV2StateModel>) {
    const salesSettings = this.store.selectSnapshot(
      AccountSettingsState.productInformationSalesGraph
    );

    ctx.patchState({
      graphOptions: defaultSalesV2.graphOptions,
      graph: {
        ...defaultSalesV2.graph,
        filter: salesSettings.historyLength,
        groupBy: salesSettings.grouping,
      },
    });
  }

  private _filterSales(
    ctx: StateContext<ProductSalesV2StateModel>,
    filter: GraphFilter,
    groupBy: GraphGroupBy
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return ctx.dispatch(new ProductSalesV2Reset()).pipe(
      mergeMap(() => this._saveGraphFiltersPersistence(filter, groupBy)),
      tap(() => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            loading: true,
            graph: patch<SalesGraph>({
              filter,
              groupBy,
            }),
          })
        );
      }),
      concatMap(() =>
        this._fetchSalesOverview(ctx, {
          webshopProductUuid,
          webshopUuid,
          filter,
        })
      ),
      tap((sales: SalesGraphOverview) => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            totalSales: sales.totalSales,
          })
        );
      }),
      mergeMap(() =>
        this._fetchSales(ctx, {
          webshopProductUuid,
          webshopUuid,
          filter,
          groupBy,
        })
      ),
      tap((salesGraph: SalesGraphData) => {
        this._updateSalesOptions(ctx, salesGraph);
      }),
      tap(() => {
        ctx.setState(
          patch<ProductSalesV2StateModel>({
            loading: false,
          })
        );

        this._presentSuccessMessage();
      }),
      mergeMap(() => ctx.dispatch(new LoadAccountSettings(userUuid))),
      catchError(() => {
        this._setNoDataErrorMessage(ctx);

        return of(null);
      })
    );
  }

  /**
   * Calls the service to fetch sales
   * @param ctx StateContext(ProductSalesV2StateModel)
   * @param payload FetchSalesProperties
   * @returns Observable(SalesGraphData)
   */
  private _fetchSales(
    ctx: StateContext<ProductSalesV2StateModel>,
    payload: FetchSalesProperties
  ): Observable<SalesGraphData> {
    return this.salesService
      .fetchSales(
        payload.webshopProductUuid,
        payload.webshopUuid,
        payload.groupBy,
        payload.filter
      )
      .pipe(
        catchError(e => {
          ctx.patchState({
            ...ctx.getState(),
            ...defaultSalesV2.graphOptions,
            loading: false,
          });

          throw new Error(e.message || e);
        })
      );
  }

  private _fetchSalesOverview(
    _ctx: StateContext<ProductSalesV2StateModel>,
    payload: FetchSalesOverviewProperties
  ): Observable<SalesGraphOverview> {
    return this.salesService
      .fetchSalesOverview(
        payload.webshopProductUuid,
        payload.webshopUuid,
        payload.filter
      )
      .pipe(
        catchError(e => {
          throw new Error(e.message || e);
        })
      );
  }

  /**
   * Updates sales echart options
   * @param ctx StateContext(ProductSalesV2StateModel)
   * @param sales SalesGraphData | null
   */
  private _updateSalesOptions(
    ctx: StateContext<ProductSalesV2StateModel>,
    sales: SalesGraphData | null
  ): void {
    if (!sales) {
      this._setNoFilterDataErrorMessage(ctx);
      return;
    }

    this._buildGraphData(ctx, sales);
  }

  private _saveGraphFiltersPersistence(
    filter: GraphFilter,
    group: GraphGroupBy
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, {
        productInformationSalesGraphHistoryLength: filter,
        productInformationSalesGraphGrouping: group,
      })
      .pipe(
        catchError(() => {
          return this.store.dispatch(new SaveFailed());
        })
      );
  }

  private _buildGraphData(
    ctx: StateContext<ProductSalesV2StateModel>,
    sales: SalesGraphData
  ) {
    const seriesList: any[] = [];
    const legendList: string[] = [];

    const { data: currentSales, futureData: futureSales, promotions } = sales;

    seriesList.push({
      name: MESSAGES.common.salesGraph.salesHistory,
      smooth: true,
      type: 'bar',
      data: currentSales || [],
      showSymbol: false,
      color: 'rgb(101, 158, 242)',
      lineStyle: {
        width: 2,
      },
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(101, 158, 242, 0.2)',
          },
          {
            offset: 1,
            color: 'rgba(101, 158, 242, 0)',
          },
        ]),
      },
      markLine: {
        data: [
          [
            {
              name: 'Today',
              xAxis: DateTime.local().toFormat('yyyy-MM-dd'),
              yAxis: 0,
            },
            {
              name: 'Today',
              xAxis: DateTime.local().toFormat('yyyy-MM-dd'),
              yAxis: 'max',
            },
          ],
        ],
      },
    });
    legendList.push(MESSAGES.common.salesGraph.salesHistory);

    if (futureSales.length !== 0) {
      seriesList.push({
        name: MESSAGES.common.salesGraph.futureSales,
        smooth: true,
        type: 'bar',
        stack: 'total',
        color: 'rgb(74, 181, 115)',
        data: futureSales || [],
        showSymbol: false,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(74, 181, 115, 0.2)',
            },
            {
              offset: 1,
              color: 'rgba(74, 181, 115, 0)',
            },
          ]),
        },
      });
      legendList.push(MESSAGES.common.salesGraph.futureSales);
    }

    if (promotions.length !== 0) {
      for (let i = 0; i < promotions.length; i++) {
        const promoRgbColor = this._getLineColor(ctx, i, 'rgb');
        seriesList.push({
          name: promotions[i].name,
          smooth: true,
          type: 'bar',
          stack: 'total',
          color: this._getLineColor(ctx, i, 'hex'),
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: `rgba(${promoRgbColor}, 0.2)`,
              },
              {
                offset: 1,
                color: `rgba(${promoRgbColor}, 0)`,
              },
            ]),
          },
          data: promotions[i].data || [],
          showSymbol: false,
        });
        legendList.push(promotions[i].name);
      }
    }

    ctx.setState(
      patch<ProductSalesV2StateModel>({
        graphOptions: patch<SalesOptions>({
          series: seriesList,
          legend: {
            type: 'scroll',
            left: 'center',
            top: 'bottom',
            data: legendList,
          },
          tooltip: {
            trigger: 'axis',
            valueFormatter: value => Number(value).toFixed(2),
          },
          xAxis: [
            {
              ...ctx.getState().graphOptions.xAxis[0],
              min: currentSales[0][0],
            },
          ],
        }),
      })
    );
  }

  /**
   * Builds echart xAxis min from filter
   * @param filter GraphFilter
   * @returns minimum value for echart xAxix
   * DEPRECATED
   */
  private _buildXAxisFromFilter(
    filter: GraphFilter,
    firstDate: string
  ): string {
    switch (filter) {
      case 'ALL_TIME':
        return undefined;
      case 'LAST_6_MONTHS':
        return firstDate;
      case 'LAST_3_MONTHS':
        return firstDate;
      case 'LAST_MONTH':
        return firstDate;
      default:
        return DateTime.local()
          .minus({ year: 1 })
          .startOf('week')
          .toFormat('yyyy-MM-dd');
    }
  }

  /**
   * Sets no filter data error message.
   * This is used when theres no data for the filter/groupBy selected
   * @param ctx StateContext(ProductSalesV2StateModel)
   */
  private _setNoFilterDataErrorMessage(
    ctx: StateContext<ProductSalesV2StateModel>
  ): void {
    ctx.setState(
      patch<ProductSalesV2StateModel>({
        loading: false,
        graphOptions: patch<SalesOptions>({
          title: {
            show: true,
            text: MESSAGES.notifications.salesGraph.noFilteredData,
            subtext: MESSAGES.common.salesGraph.refresh,
            left: 'center',
            top: 'center',
          },
        }),
      })
    );
  }

  /**
   * Sets no data error message
   * This is used when theres no data at all or an error occurs.
   * @param ctx StateContext(ProductSalesV2StateModel)
   */
  private _setNoDataErrorMessage(
    ctx: StateContext<ProductSalesV2StateModel>
  ): void {
    ctx.setState(
      patch<ProductSalesV2StateModel>({
        loading: false,
        graphOptions: patch<SalesOptions>({
          title: {
            show: true,
            text: MESSAGES.notifications.salesGraph.loadFailed,
            subtext: MESSAGES.common.salesGraph.refresh,
            left: 'center',
            top: 'center',
          },
        }),
      })
    );
  }

  /**
   * Gets a color for the current chart line.
   *
   * The returned string can be an hex color or rgb color.
   *
   * Use the rgb color to create the gradient background using, later, the returned string
   * inside a rgba("return color", "opacity").
   * @param ctx StateContext(ProductSalesV2StateModel)
   * @param key number
   * @param type hex / rgb
   * @returns Color from the colors list.
   */
  private _getLineColor(
    ctx: StateContext<ProductSalesV2StateModel>,
    key: number,
    type: 'hex' | 'rgb'
  ): string {
    const colorsList = ctx.getState().graph.colorsList;

    return colorsList[key % colorsList.length][type];
  }

  private _presentSuccessMessage() {
    this.notificationCenter.showToastSuccess(
      MESSAGES.notifications.salesGraph.filtered
    );
  }
}
