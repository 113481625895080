<div
  class="form-field"
  [class.disabled]="disabled"
  (click)="onTouchTargetClick($event)">
  <input
    #input
    type="radio"
    [id]="inputId"
    [checked]="checked"
    [disabled]="disabled"
    [attr.name]="name"
    [attr.value]="value"
    [required]="required"
    (change)="onInputInteraction($event)" />

  <label class="label" [for]="inputId">
    <ng-content></ng-content>
  </label>
</div>
