<app-dialog-container>
  <div appDialogHeader>
    <mat-icon svgIcon="arrow-upload" class="menu-icon"></mat-icon>
    <h1 appDialogTitle i18n="import title">Import products...</h1>
    <button
      aria-label="Imports dialog close button"
      (click)="close()"
      class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div appDialogBody>
    <div class="confirmation-container">
      <mat-icon class="info-icon" svgIcon="info-rounded-xs"></mat-icon>
      <p class="sub-title" i18n="imports dialog download warning">
        Importing will only
      </p>
      <p class="sub-title-bold" i18n="imports dialog download warning">
        update
      </p>
      <p class="sub-title" i18n="imports dialog download warning">
        existing products and won’t create new products.
      </p>
    </div>

    <div class="imports-container">
      <div class="imports-input-section">
        <label for="format" class="label" i18n="import format"
          >Choose the identifier for the products to be updated from the options
          below.</label
        >
        <div
          class="input-data-container"
          [formGroup]="parentForm"
          appRadioButtonGroup>
          <div class="import-row">
            <div
              [class]="
                radioButtons.id
                  ? 'import-radio-selected'
                  : 'import-radio-container'
              "
              (click)="checkRadioType('ID')">
              <app-radio-button
                name="type"
                value="ID"
                [(checked)]="radioButtons.id"
                label="Optiply ID"
                i18n-label="imports optiply id label"></app-radio-button>
              <label class="sub-label" i18n="imports optiply id ref"
                >Use Optiply ID as the identifier. (recommended)</label
              >
            </div>
            <div
              [class]="
                radioButtons.ean
                  ? 'import-radio-selected'
                  : 'import-radio-container'
              "
              (click)="checkRadioType('EAN_CODE')">
              <app-radio-button
                name="type"
                value="EAN_CODE"
                [(checked)]="radioButtons.ean"
                (radioChange)="checkRadioType($event)"
                label="EAN code"
                i18n-label="imports optiply ean code label"></app-radio-button>
              <label class="sub-label" i18n="imports optiply ean code ref"
                >Use product’s EAN code as the identifier.</label
              >
            </div>
          </div>
          <div class="import-row">
            <div
              [class]="
                radioButtons.sku
                  ? 'import-radio-selected'
                  : 'import-radio-container'
              "
              (click)="checkRadioType('SKU_CODE')">
              <app-radio-button
                name="type"
                value="SKU_CODE"
                [(checked)]="radioButtons.sku"
                (radioChange)="checkRadioType($event)"
                label="SKU code"
                i18n-label="imports optiply sku code label"></app-radio-button>
              <label class="sub-label" i18n="imports optiply sku code ref"
                >Use product’s SKU code as the identifier.</label
              >
            </div>
            <div
              [class]="
                radioButtons.article
                  ? 'import-radio-selected'
                  : 'import-radio-container'
              "
              (click)="checkRadioType('ARTICLE_CODE')">
              <app-radio-button
                name="type"
                value="ARTICLE_CODE"
                [(checked)]="radioButtons.article"
                (radioChange)="checkRadioType($event)"
                label="Article code"
                i18n-label="
                  imports optiply article code label
                "></app-radio-button>
              <label class="sub-label" i18n="imports optiply article code ref"
                >Use product’s article code as the identifier.</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div appDialogActions>
    <div class="action-buttons-container">
      <button
        aria-label="Imports dialog cancel button"
        appButton
        variant="tertiary"
        class="optiply-tertiary-btn"
        i18n="import cancel button"
        (click)="close()">
        Cancel
      </button>

      <usecsv-button
        [importerKey]="importKey$ | async"
        [dynamicColumns]="dynamicColumnsWithStatus"
        [onData]="importData"
        [acceptedFileFormats]="['csv']"
        [batchSize]="2000"
        [theme]="
          (colorTheme$ | async) === 'dark' ? useCSVDarkTheme : useCSVLightTheme
        "
        [customText]="{
          importedRows: {
            text: '{{rows_count}}' + STRINGS.useCSVCustomText
          }
        }"
        i18n-customText="imports custom text import start"
        [defaultLanguage]="(language$ | async) === 'en-US' ? 'en' : 'nl'"
        [onClose]="onClose"
        importerDisplay="inline">
        <button
          customUsecsvButton
          aria-label="Imports dialog import button"
          appButton
          variant="primary"
          i18n="import button"
          (click)="openUsecsvDialog()">
          Next
        </button></usecsv-button
      >
    </div>
  </div>
</app-dialog-container>
