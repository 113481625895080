import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  AdviceDetails,
  PurchaseAdvice,
} from 'src/app/shared/models/buy-orders/v2/buy-order-advice.model';
import {
  AdviceDetails as AdviceDetailsV1,
  PurchaseAdvice as PurchaseAdviceV1,
} from '../model/proposed-buy-orderlines-v2.model';

export class PurchaseAdviceAdapter
  implements DataAdapter<PurchaseAdviceV1, PurchaseAdvice>
{
  transform(data: PurchaseAdviceV1): PurchaseAdvice {
    const advices: AdviceDetails[] = data.advices.map(
      (advice: AdviceDetailsV1) => {
        return {
          supplierProductUuid: advice.supplierProductUuid,
          supplierUuid: advice.supplierUuid,
          stockPosition: advice.stockPosition,
          orderUpToLevel: advice.orderUpToLevel,
          demandPerCycle: advice.demandPerCycle,
          safetyStock: advice.safetyStock,
        };
      }
    );

    return {
      advices,
    };
  }
}
