import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { BANNER_DEFAULTS, VariantColors, Variants } from './model/banner.model';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: string | boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }
  private _disabled: boolean = false;

  @Input()
  icon: string;

  @Input()
  get rounded(): boolean {
    return this._rounded;
  }
  set rounded(value: string | boolean) {
    this._rounded = coerceBooleanProperty(value);
  }
  private _rounded: boolean = false;

  @Input()
  get removable(): boolean {
    return this._removable;
  }
  set removable(removable: string | boolean) {
    this._removable = coerceBooleanProperty(removable);
  }
  private _removable: boolean = false;

  @Input()
  maxWidth: number = BANNER_DEFAULTS.maxWidth;

  @Input()
  variant: Variants;

  @Input()
  color: VariantColors;

  @Input()
  iconAlignment: 'start' | 'end' = 'end';

  @Input()
  content: string;

  @Output()
  dismiss: EventEmitter<void> = new EventEmitter<void>();

  readonly COMMON_STRINGS = MESSAGES.common;

  get bannerClasses(): string[] {
    const variant = this.variant ? this.variant : BANNER_DEFAULTS.variant;
    const color = this.color ? this.color : BANNER_DEFAULTS.color;
    const removable = this.removable ? 'tag-removable' : '';
    const rounded = this.rounded ? 'banner-rounded' : 'banner-squared';
    const disabled = this.disabled ? 'banner-disabled' : '';

    return [
      'banner-base',
      `banner-${variant}-${color}`,
      removable,
      rounded,
      disabled,
    ];
  }

  get bannerCellClasses(): string[] {
    return ['banner-cell', `banner-icon-${this.iconAlignment}`];
  }

  dismissBanner(): void {
    if (this.removable && !this.disabled) {
      this.dismiss.emit();
    }
  }
}
