<app-supply-chain-section-container>
  <ng-container title>{{ METADATA.title }}</ng-container>

  <ng-container content>
    <app-supply-chain-section-row
      data-intercom-target="fe441d99-b197-409f-89b0-7ef61b7284d9"
      aria-label="Product details purchase advice forecast">
      <ng-container description>
        <ng-container
          *ngIf="
            (assembled$ | async) === false &&
              (activeSupplier$ | async) !== null;
            else forecastDays
          "
          >{{ METADATA.forecastCycle }}</ng-container
        >
        <ng-template #forecastDays>{{ METADATA.forecastDays }}</ng-template>
      </ng-container>

      <ng-container
        *ngIf="
          (assembled$ | async) === false &&
            (advice$ | async)?.demandPerCycle !== null;
          else notAvailable
        "
        value
        >{{ (advice$ | async).demandPerCycle }}</ng-container
      >
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="8f6dacdf-aaa2-4735-9582-9a87a1657a7b"
      aria-label="Product details purchase advice safety stock">
      <ng-container description
        >{{ METADATA.safetyStock }}
        <mat-icon
          *ngIf="
            (advice$ | async)?.safetyStock !== null &&
            (advice$ | async).safetyStock < 0
          "
          svgIcon="info-rounded-xs"
          class="mat-icon"
          [appTooltip]="TOOLTIPS.safetyStock"></mat-icon
      ></ng-container>
      <ng-container
        *ngIf="
          (assembled$ | async) === false &&
            (advice$ | async)?.safetyStock !== null;
          else notAvailable
        "
        value>
        {{ (advice$ | async).safetyStock | customDecimal }}
      </ng-container>
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="b9f250c8-966f-40fe-bcc8-f6d5ec1f6752"
      aria-label="Product details purchase advice maximum stock">
      <ng-container description>{{ METADATA.maxStock }}</ng-container>
      <ng-container
        *ngIf="
          (assembled$ | async) === false &&
            (advice$ | async)?.orderUpToLevel !== null;
          else notAvailable
        "
        value
        >{{ (advice$ | async).orderUpToLevel | customDecimal }}</ng-container
      >
    </app-supply-chain-section-row>

    <app-supply-chain-section-row
      data-intercom-target="57c083bc-2760-45d7-b809-28a16ea072ec"
      aria-label="Product details purchase advice stock position">
      <ng-container description>
        <span>{{ METADATA.stockPosition }}</span>
      </ng-container>
      <ng-container
        *ngIf="
          (assembled$ | async) === false &&
            (advice$ | async)?.stockPosition !== null;
          else notAvailable
        "
        value
        >{{ (advice$ | async).stockPosition | customDecimal }}</ng-container
      >
    </app-supply-chain-section-row>
  </ng-container>
</app-supply-chain-section-container>

<ng-template #notAvailable>
  <span>{{ NOT_AVAILABLE }}</span>
</ng-template>
