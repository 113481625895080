/**
 * Action that is triggered when the user changes the location.
 */
export class LocaleChanged {
  static readonly type = '[Locale] Changed';

  constructor(public localeId: string) {}
}

export class ChangeLocale {
  static readonly type = '[Locale] Changing';

  constructor(public localeId: string) {}
}
