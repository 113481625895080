<app-dialog-container
  ><div>
    <div appDialogHeader>
      <h1 appDialogTitle>
        {{ dialogTitle }}
      </h1>
      <button
        aria-label="create update promotion dialog close button"
        (click)="close()"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>

    <form #form="ngForm">
      <div appDialogBody>
        <div>
          <h3 class="promotions-container-title">
            {{ DIALOG_STRINGS.promotionDetails.title }}
          </h3>

          <div class="promotion-name input-field">
            <label for="promotionName"
              >{{ DIALOG_STRINGS.promotionDetails.promotionName }}
              <span appRequiredLabel>*</span></label
            >

            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                id="promotionName"
                name="promotionName"
                type="text"
                [(ngModel)]="vm.promotionName"
                [placeholder]="DIALOG_STRINGS.promotionDetails.promotionName"
                required />
            </div>
          </div>

          <div class="dates-container">
            <div
              class="start-date small-input-field input-field"
              (click)="startPicker.open()">
              <label for="startDate"
                >{{ DIALOG_STRINGS.promotionDetails.startDate }}
                <span appRequiredLabel>*</span></label
              >

              <div class="fields-planning-container">
                <div class="optiply-filled-datepicker">
                  <input
                    id="startDate"
                    name="startDate"
                    [matDatepicker]="startPicker"
                    #startDateControl="ngModel"
                    [(ngModel)]="vm.startDate"
                    [placeholder]="
                      DIALOG_STRINGS.promotionDetails.startDatePlaceholder
                    "
                    required />
                  <mat-datepicker-toggle matSuffix [for]="startPicker">
                    <mat-icon
                      matDatepickerToggleIcon
                      svgIcon="calendar"
                      class="datepicker-icon"></mat-icon
                  ></mat-datepicker-toggle>
                  <mat-datepicker #startPicker></mat-datepicker>
                </div>
              </div>
            </div>

            <div
              class="end-date small-input-field input-field"
              (click)="endPicker.open()">
              <label for="endDate"
                >{{ DIALOG_STRINGS.promotionDetails.endDate }}
                <span appRequiredLabel>*</span></label
              >

              <div class="fields-planning-container">
                <div class="optiply-filled-datepicker">
                  <input
                    id="endDate"
                    name="endDate"
                    [min]="startDateControl.value"
                    [matDatepicker]="endPicker"
                    [(ngModel)]="vm.endDate"
                    [placeholder]="
                      DIALOG_STRINGS.promotionDetails.endDatePlaceholder
                    "
                    required />
                  <mat-datepicker-toggle matSuffix [for]="endPicker">
                    <mat-icon
                      matDatepickerToggleIcon
                      svgIcon="calendar"
                      class="datepicker-icon"></mat-icon
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endPicker> </mat-datepicker>
                </div>

                @if (invalidPromotionDatesRange) {
                  <div class="error">
                    <mat-icon svgIcon="warning-rounded"></mat-icon>
                    <p>{{ DIALOG_STRINGS.promotionDetails.endDateError }}</p>
                  </div>
                }
              </div>
            </div>
          </div>

          <h3 class="promotions-container-title">
            {{ DIALOG_STRINGS.uplift.title }}
          </h3>

          <div class="uplift-container">
            <div class="uplift-type small-input-field input-field">
              <label for="upliftType">{{ DIALOG_STRINGS.uplift.uplift }}</label>

              <app-dropdown
                id="upliftType"
                name="upliftType"
                [(ngModel)]="vm.upliftType"
                (ngModelChange)="handleIncreaseDependingOnUpliftType($event)"
                required>
                <app-dropdown-option
                  *ngFor="let type of UPLIFT_TYPES_OPTIONS"
                  [value]="type.value"
                  >{{ type.key }}</app-dropdown-option
                >
              </app-dropdown>
            </div>

            @if (
              vm.upliftType === UPLIFT_TYPES.RELATIVE ||
              vm.upliftType === UPLIFT_TYPES.ABSOLUTE
            ) {
              <div class="expected-uplift small-input-field input-field">
                <label for="increase">{{
                  DIALOG_STRINGS.uplift.expectedUplift
                }}</label>

                <div class="fields-input-container">
                  @if (vm.upliftType === UPLIFT_TYPES.RELATIVE) {
                    <div appInputWrapper>
                      <span appInputSuffix>%</span>
                      <input
                        appInput
                        variant="filled"
                        id="increase"
                        name="increase"
                        type="number"
                        min="1"
                        [(ngModel)]="vm.increase"
                        [placeholder]="NOT_AVAILABLE" />
                    </div>
                  } @else {
                    <div>
                      <input
                        appInput
                        variant="filled"
                        id="increase"
                        name="increase"
                        type="number"
                        min="1"
                        [(ngModel)]="vm.increase"
                        [placeholder]="NOT_AVAILABLE" />
                    </div>
                  }

                  @if (vm.increase === 0) {
                    <div class="error">
                      <mat-icon svgIcon="warning-rounded"></mat-icon>
                      <p>{{ DIALOG_STRINGS.uplift.expectedUpliftError }}</p>
                    </div>
                  }
                </div>
              </div>
            }
          </div>

          <h3 class="promotions-container-title">
            {{ DIALOG_STRINGS.settings.title }}
          </h3>

          <div class="promotions-checkbox-container">
            <div class="enabled">
              <app-toggle
                name="enabled"
                [label]="DIALOG_STRINGS.settings.enabled"
                [(ngModel)]="vm.enabled">
              </app-toggle>
            </div>
          </div>
        </div>
      </div>

      <div appDialogActions class="confirmation-buttons">
        <div class="checkbox">
          <app-checkbox
            name="entireWebshop"
            [(checked)]="vm.entireWebshop"
            [label]="DIALOG_STRINGS.settings.applyEntire">
          </app-checkbox>
        </div>

        <div class="action-buttons-container">
          <button
            aria-label="create update promotion dialog cancel button"
            appButton
            variant="tertiary"
            (click)="close()">
            {{ COMMON_STRINGS.cancel }}
          </button>

          <button
            aria-label="create update promotion dialog save button"
            appButton
            variant="primary"
            [disabled]="form.invalid || (loading$ | async) || (saving$ | async)"
            (click)="save()">
            {{ COMMON_STRINGS.save }}

            @if ((loading$ | async) || (saving$ | async)) {
              <app-progress-spinner />
            }
          </button>
        </div>
      </div>
    </form>
  </div>
</app-dialog-container>
