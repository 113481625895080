import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  @HostListener('keypress', ['$event'])
  onInput(event: KeyboardEvent) {
    return event.charCode >= 48 && event.charCode <= 57;
  }
}
