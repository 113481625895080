import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import { SaveConceptProperties } from '../../proposed-orderlines/model/proposed-orderlines.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

export class InitializePurchase {
  static readonly type = '[Manual Order V2 Products] Initialize Purchase';

  constructor(public supplierUuid: string) {}
}

export class LoadManualOrderV2Products {
  static readonly type = '[Manual Order V2 Products] Load Order Products';

  constructor(public supplierUuid: string) {}
}

export class ReloadDatatable {
  static readonly type = '[Manual Order V2 Products] Reload';
}

export class LoadManualOrderV2Overview {
  static readonly type = '[Manual Order V2] Load Manual Order Overview';

  constructor(public supplierUuid: string) {}
}

export class GenerateSession {
  static readonly type = '[Manual Order V2 Products] Generate Session';
}

export class AddSearchParam {
  static readonly type = '[Manual Order V2 Products] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Manual Order V2 Products] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Manual Order V2 Products] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Manual Order V2 Products] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Manual Order V2 Products] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Manual Order V2 Products] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Manual Order V2 Products] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Manual Order V2 Products] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Manual Order V2 Products] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class PatchLinePrice {
  static readonly type = '[Manual Order V2 Products] Patch Line Price';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedPrice: string
  ) {}
}

export class PatchLineQuantity {
  static readonly type = '[Manual Order V2 Products] Patch Line Quantity';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedQuantity: string
  ) {}
}

export class PatchLineWeight {
  static readonly type = '[Manual Order V2 Products] Patch Line Weight';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedWeight: string
  ) {}
}

export class PatchLineVolume {
  static readonly type = '[Manual Order V2 Products] Patch Line Volume';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedVolume: string
  ) {}
}

export class RemoveProductFromOrder {
  static readonly type = '[Manual Order V2 Products] Remove Product';

  constructor(public supplierProductUuid: string) {}
}

export class RemoveProductsFromOrder {
  static readonly type = '[Manual Order V2 Products] Remove Products';
}

export class OrderLineChanged {
  static readonly type = '[Manual Order Line Updated] order line updated';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderLineRemoved {
  static readonly type = '[Manual Order Line Removed] order line removed';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderLinesRemoved {
  static readonly type =
    '[Manual Order Lines Bulk Removed] order lines removed';
}

export class OrderlineErrored {
  static readonly type = '[Manual Order Line Error] order line error';

  constructor(
    public errorMessage: string,
    public supplierProductUuid: string
  ) {}
}

export class HideColumn {
  static readonly type = '[Manual Order V2 Products] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Manual Order V2 Products] Toggle Filter';
}

export class TriggerPurchaseConfirmation {
  static readonly type =
    '[Manual Order V2 Products] TriggerPurchaseConfirmation';
}

export class SaveConcept {
  static readonly type = '[Manual Order V2 Products] Save Concept';

  constructor(
    public properties: SaveConceptProperties = { showMessage: true }
  ) {}
}

export class PlaceManual {
  static readonly type = '[Manual Order V2 Products] Place manual order';
}

export class CreateConcept {
  static readonly type = '[Manual Order V2 Products] Create Concept';

  constructor(public properties: SaveConceptProperties) {}
}

export class UpdateConcept {
  static readonly type = '[Manual Order V2 Products] Update Concept';

  constructor(public properties: SaveConceptProperties) {}
}

export class ReloadBothTables {
  static readonly type = '[Manual Order V2 Products] Reload Editor Both Tables';

  constructor(
    public payload: { showLoading: boolean } = { showLoading: false }
  ) {}
}

export class ResetBuyOrder {
  static readonly type = '[Manual Order V2 Products] Reset Buy Order';
}

export class ToggleRowSelection {
  static readonly type = '[Manual Order V2 Products] Toggle Row Selection';

  constructor(
    public rowKey: string,
    public rowQuantity: number,
    public isSelected: boolean
  ) {}
}

export class ClearSelection {
  static readonly type = '[Manual Order V2 Products] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type = '[Manual Order V2 Products] Master Toggle Selection';
}

export class SocketDisconnection {
  static readonly type = '[Manual Order V2 Products] Socket Disconnection';
}

export class RefreshManualOrderPage {
  static readonly type = '[Manual Order V2 Products] Refresh Manual Order Page';
}

export class ResetPaginationAndLoadData {
  static readonly type =
    '[Manual Order V2 Products] Reset Pagination And Load Data';
}
