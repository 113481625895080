<ng-container *ngIf="!!value">
  <form #form="ngForm" class="filter-range-option-form">
    <div class="option">
      <label for="from">{{ COMMON_STRINGS.filter.optionTypes.from }}</label>

      <div class="optiply-filled-datepicker">
        <input
          id="from"
          name="from"
          #from="ngModel"
          [min]="min"
          [max]="to.value || max"
          [matDatepicker]="fromPicker"
          (click)="fromPicker.open()"
          [(ngModel)]="value.from"
          placeholder="-"
          readonly />

        <mat-datepicker-toggle matSuffix [for]="fromPicker">
          <mat-icon
            matDatepickerToggleIcon
            svgIcon="calendar"
            class="datepicker-icon"></mat-icon
        ></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </div>
    </div>

    <div class="option">
      <label for="to">{{ COMMON_STRINGS.filter.optionTypes.to }}</label>

      <div class="optiply-filled-datepicker">
        <input
          id="to"
          name="to"
          #to="ngModel"
          [min]="from.value || min"
          [max]="max"
          [matDatepicker]="toPicker"
          (click)="toPicker.open()"
          [(ngModel)]="value.to"
          placeholder="-"
          readonly />

        <mat-datepicker-toggle matSuffix [for]="toPicker">
          <mat-icon
            matDatepickerToggleIcon
            svgIcon="calendar"
            class="datepicker-icon"></mat-icon
        ></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
      </div>
    </div>
  </form>
</ng-container>
