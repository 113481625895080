import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { AdviceDetails } from 'src/app/shared/models/buy-orders/v2/buy-order-advice.model';
import { Supplier } from 'src/app/shared/models/suppliers/product-suppliers.model';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import { SupplyChainInformationV2StateQueries } from '../../state/supply-chain-information-v2.queries';

@Component({
  selector: 'app-supply-chain-purchasing-advice-v2',
  templateUrl: './supply-chain-purchasing-advice-v2.component.html',
  styleUrls: ['./supply-chain-purchasing-advice-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainPurchasingAdviceV2Component {
  @Select(WebshopState.selected)
  webshop$: Observable<Webshop>;

  @Select(ProductDetailsV2StateQueries.isAssembled)
  assembled$: Observable<boolean>;

  @Select(SupplyChainInformationV2StateQueries.activeSupplier)
  activeSupplier$: Observable<Supplier>;

  @Select(SupplyChainInformationV2StateQueries.purchaseAdviceBySupplier)
  advice$: Observable<AdviceDetails>;

  readonly METADATA = STRINGS.metadata.supplyChainInformation.advice;

  readonly TOOLTIPS = STRINGS.tooltips.supplyChainInformation;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  constructor() {
    // intentional
  }
}
