export const STRINGS = {
  columns: {
    purchases: {
      STATUS: $localize`Status`,
      DESCRIPTION: $localize`Description`,
      PRODUCTS: $localize`Products`,
      TOTAL_VALUE: $localize`Total value`,
    },
    deliveries: {
      STATUS: $localize`Status`,
      DESCRIPTION: $localize`Description`,
      TOTAL_ORDERED: $localize`Total ordered`,
      TOTAL_DELIVERED: $localize`Total delivered`,
      TOTAL_VALUE: $localize`Total value`,
    },
    products: {
      STATUS: $localize`Status`,
      DESCRIPTION: $localize`Description`,
    },
    suppliers: {
      STATUS: $localize`Status`,
      DESCRIPTION: $localize`Description`,
    },
  },
  tooltips: {
    FILL_LEAD_TIME: $localize`Missing a lead time`,
    FILL_REPLENISHMENT_PERIOD: $localize`Missing a reorder period`,
    FILL_FIXED_COSTS: $localize`Missing a fixed order cost`,
    CONNECT_TO_SUPPLIER: $localize`Missing a supplier`,
    UPDATE_PURCHASE_PRICE: $localize`Abnormal purchase price`,
    UPDATE_SELLING_PRICE: $localize`Abnormal sales price`,
  },
  metadata: {
    todoList: {
      title: $localize`Open tasks`,
      description: $localize`All the sections needing attention`,
      noIssues: {
        title: $localize`You're up to date!`,
        description: $localize`All tasks complete. Keep it going!`,
      },
      loading: $localize`Loading tasks...`,
      issues: {
        labels: {
          OVERVIEW_SUMMARY_OVERDUE_ORDERS: $localize`Overdue orders`,
          OVERVIEW_SUMMARY_TODAYS_ORDERS: $localize`Order today`,
          OVERVIEW_SUMMARY_UNPLANNED_ORDERS: $localize`Start purchasing`,
          OVERVIEW_SUMMARY_OVERDUE_DELIVERIES: $localize`Overdue deliveries`,
          OVERVIEW_SUMMARY_TODAYS_DELIVERIES: $localize`Expected today`,
        },
        descriptions: {
          subject: {
            OVERVIEW_SUMMARY_OVERDUE_ORDERS: $localize`order moment(s)`,
            OVERVIEW_SUMMARY_TODAYS_ORDERS: $localize`order moment(s)`,
            OVERVIEW_SUMMARY_UNPLANNED_ORDERS: $localize`supplier(s)`,
            OVERVIEW_SUMMARY_OVERDUE_DELIVERIES_SINGLE: $localize`delivery`,
            OVERVIEW_SUMMARY_OVERDUE_DELIVERIES: $localize`deliveries`,
            OVERVIEW_SUMMARY_TODAYS_DELIVERIES_SINGLE: $localize`delivery`,
            OVERVIEW_SUMMARY_TODAYS_DELIVERIES: $localize`deliveries`,
            FILL_LEAD_TIME: $localize`supplier(s)`,
            FILL_REPLENISHMENT_PERIOD: $localize`supplier(s)`,
            FILL_FIXED_COSTS: $localize`supplier(s)`,
            CONNECT_TO_SUPPLIER: $localize`product(s)`,
            UPDATE_PURCHASE_PRICE: $localize`product(s)`,
            UPDATE_SELLING_PRICE: $localize`product(s)`,
          },
          description: {
            OVERVIEW_SUMMARY_OVERDUE_ORDERS: $localize`overdue`,
            OVERVIEW_SUMMARY_TODAYS_ORDERS: $localize`planned for today`,
            OVERVIEW_SUMMARY_UNPLANNED_ORDERS: $localize`ready to start purchasing`,
            OVERVIEW_SUMMARY_OVERDUE_DELIVERIES: $localize`overdue`,
            OVERVIEW_SUMMARY_TODAYS_DELIVERIES: $localize`due today`,
            FILL_LEAD_TIME: $localize`missing a lead time`,
            FILL_REPLENISHMENT_PERIOD: $localize`missing a reorder period`,
            FILL_FIXED_COSTS: $localize`missing a fixed order cost`,
            CONNECT_TO_SUPPLIER: $localize`missing a supplier`,
            UPDATE_PURCHASE_PRICE: $localize`with an abnormal purchase price`,
            UPDATE_SELLING_PRICE: $localize`with an abnormal sales price`,
          },
        },
      },
      purchaseTitle: $localize`Purchases`,
      deliveriesTitle: $localize`Deliveries`,
      suppliersTitle: $localize`Suppliers`,
      productsTitle: $localize`Products`,
    },
  },
};
