import { Selector } from '@ngxs/store';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import {
  PredecessorProduct,
  SuccessorProduct,
  WebshopProduct,
} from 'src/app/shared/models/products/v3/products.model';
import {
  ProductDetailsV2State,
  ProductDetailsV2StateModel,
} from './product-details-v2.state';
import { DynamicTags } from 'src/app/shared/components/design-system/tags/model/tag.model';

export class ProductDetailsV2StateQueries {
  @Selector([ProductDetailsV2State])
  static productUuid(state: ProductDetailsV2StateModel): string | null {
    return state.uuid;
  }

  @Selector([ProductDetailsV2State])
  static overviewLoading(state: ProductDetailsV2StateModel): boolean {
    return state.overview.loading;
  }

  @Selector([ProductDetailsV2State])
  static saving(state: ProductDetailsV2StateModel): boolean {
    return state.saving;
  }

  @Selector([ProductDetailsV2State])
  static overviewFailed(state: ProductDetailsV2StateModel): boolean {
    return state.overview.failed;
  }

  @Selector([ProductDetailsV2State])
  static product(state: ProductDetailsV2StateModel): WebshopProduct {
    return state.overview.product;
  }

  @Selector([ProductDetailsV2State])
  static productOverviewSucceed(state: ProductDetailsV2StateModel): boolean {
    return state.overview.product.uuid !== null;
  }

  @Selector([ProductDetailsV2State])
  static productId(state: ProductDetailsV2StateModel): string | null {
    return state.overview?.product?.id ?? null;
  }

  @Selector([ProductDetailsV2State])
  static productName(state: ProductDetailsV2StateModel): string {
    return state.overview.product.name || NOT_AVAILABLE_VALUE;
  }

  @Selector([ProductDetailsV2State])
  static productIgnored(state: ProductDetailsV2StateModel): boolean {
    return state.overview.product.ignored;
  }

  @Selector([ProductDetailsV2State])
  static isAssembled(state: ProductDetailsV2StateModel): boolean {
    return state.overview.product.assembled;
  }

  @Selector([ProductDetailsV2State])
  static isIgnored(state: ProductDetailsV2StateModel): boolean {
    return state.overview.product.ignored;
  }

  @Selector([ProductDetailsV2State])
  static isPhasedOut(state: ProductDetailsV2StateModel): boolean {
    return state.overview.product.notBeingBought;
  }

  @Selector([ProductDetailsV2State])
  static isNew(state: ProductDetailsV2StateModel): boolean {
    return state.overview.product.novel;
  }

  @Selector([ProductDetailsV2State])
  static tags(state: ProductDetailsV2StateModel): DynamicTags {
    return state.overview.product.extras.tags;
  }

  @Selector([ProductDetailsV2State])
  static settingsIgnored(state: ProductDetailsV2StateModel): boolean {
    return state.settings.ignored;
  }

  @Selector([ProductDetailsV2State])
  static settingsMinimumStock(state: ProductDetailsV2StateModel): number {
    return state.settings.minimumStockLevel;
  }

  @Selector([ProductDetailsV2State])
  static settingsMaximumStock(state: ProductDetailsV2StateModel): number {
    return state.settings.maximumStock;
  }

  @Selector([ProductDetailsV2State])
  static settingsPhasedOut(state: ProductDetailsV2StateModel): boolean {
    return state.settings.notBeingBought;
  }

  @Selector([ProductDetailsV2State])
  static settingsPhasedOutHasEndDate(
    state: ProductDetailsV2StateModel
  ): boolean {
    return state.settings.notBeingBoughtHasEndDate;
  }

  @Selector([ProductDetailsV2State])
  static settingsResumePurchaseDate(
    state: ProductDetailsV2StateModel
  ): string | null {
    return state.settings.resumePurchaseDate;
  }

  @Selector([ProductDetailsV2State])
  static settingsMinResumePurchaseDate(
    state: ProductDetailsV2StateModel
  ): string | null {
    return state.settings.minPurchaseDate;
  }

  @Selector([ProductDetailsV2State])
  static settingsManualServiceLevel(
    state: ProductDetailsV2StateModel
  ): number | null {
    return state.settings.manualServiceLevel;
  }

  @Selector([ProductDetailsV2State])
  static cantResumePurchase(state: ProductDetailsV2StateModel): boolean {
    return (
      state.overview.product.ignored ||
      !state.settings.notBeingBought ||
      !state.settings.notBeingBoughtHasEndDate
    );
  }

  @Selector([ProductDetailsV2State])
  static cantChangeSettings(state: ProductDetailsV2StateModel): boolean {
    return state.overview.product.ignored || state.overview.product.assembled;
  }

  @Selector([ProductDetailsV2State])
  static disableCompositionsTab(state: ProductDetailsV2StateModel): boolean {
    return state.disableCompositionsTab;
  }

  @Selector([ProductDetailsV2State])
  static disablePartsTab(state: ProductDetailsV2StateModel): boolean {
    return state.disablePartsTab;
  }

  @Selector([ProductDetailsV2State])
  static isOverlay(state: ProductDetailsV2StateModel): boolean {
    return state.isOverlay;
  }

  @Selector([ProductDetailsV2State])
  static productIgnoredTemplate(state: ProductDetailsV2StateModel): boolean {
    return state.ignoredTemplate;
  }

  @Selector([ProductDetailsV2State])
  static availablePredecessors(
    state: ProductDetailsV2StateModel
  ): PredecessorProduct[] {
    return state.availablePredecessors;
  }

  @Selector([ProductDetailsV2State])
  static predecessor(
    state: ProductDetailsV2StateModel
  ): PredecessorProduct | null {
    return state.predecessor;
  }

  @Selector([ProductDetailsV2State])
  static successor(state: ProductDetailsV2StateModel): SuccessorProduct | null {
    return state.successor;
  }

  @Selector([ProductDetailsV2State])
  static settingsNotes(state: ProductDetailsV2StateModel): string | null {
    return state.settings.notes;
  }

  @Selector([ProductDetailsV2State])
  static saveEnabled(state: ProductDetailsV2StateModel): boolean {
    return state.saveEnabled;
  }

  @Selector([ProductDetailsV2State])
  static formValid(state: ProductDetailsV2StateModel): boolean {
    return state.formValid;
  }
}
