import { Supplier } from 'src/app/shared/models/suppliers/v2/supplier-v2.model';
import { SupplierCreate } from 'src/app/shared/models/suppliers/supplier.model';
import { ExportSuppliersBody } from '../model/suppiers-v2.model';
import { ExportDataV2 } from 'src/app/shared/models/exports/exports-v2.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

export class InitializeSuppliersState {
  static readonly type = '[Suppliers V2] Initialize';
}

export class LoadSuppliers {
  static readonly type = '[Suppliers V2] Load Suppliers';
}

export class ReloadDatatable {
  static readonly type = '[Suppliers V2] Reload';
}

export class IgnoreMultipleSuppliers {
  static readonly type = '[Suppliers V2] Ignore Multiple Suppliers';
}

export class IgnoreSupplier {
  static readonly type = '[Suppliers V2] Ignore Supplier';

  constructor(public supplier: Supplier) {}
}

export class UnignoreSupplier {
  static readonly type = '[Suppliers V2] Unignore Supplier';

  constructor(public supplier: Supplier) {}
}

export class FetchSupplierV2 {
  static readonly type = '[Supplier V2] Fetch Supplier V2';

  constructor(public supplierUuid: string) {}
}

export class Paginate {
  static readonly type = '[Suppliers V2] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Suppliers V2] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class AddSearchParam {
  static readonly type = '[Suppliers V2] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Suppliers V2] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Suppliers V2] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Suppliers V2] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Suppliers V2] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Suppliers V2] Remove All Filters';
}

export class ColumnsSelected {
  static readonly type = '[Suppliers V2] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Suppliers V2] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Suppliers V2] Toggle Filter';
}

export class ToggleRowSelection {
  static readonly type = '[Suppliers V2] Toggle Row Selection';

  constructor(
    public supplier: Supplier,
    public isSelected: boolean
  ) {}
}

export class ClearSelection {
  static readonly type = '[Suppliers V2] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type = '[Suppliers V2] Master Toggle Selection';
}

export class ExportSuppliers {
  static readonly type = '[Suppliers V2] Export';

  constructor(
    public webshopUUID: string,
    public settings: ExportSuppliersBody
  ) {}
}

export class ResetSuppliers {
  static readonly type = '[Suppliers V2] Reset Suppliers';
}

export class AddFilterParamNoReload {
  static readonly type = '[Suppliers V2] Add Filter Param No Reload';

  constructor(
    public param: ColumnFilterParam[],
    public reload?: boolean
  ) {}
}

export class ExportSuppliersV2 {
  static readonly type = '[Suppliers V2] Export';

  constructor(public exportData: ExportDataV2) {}
}

export class CreateSupplier {
  static readonly type = '[Supplier] Create';

  constructor(public settings: SupplierCreate) {}
}
export class SaveSupplierEmailV2 {
  static readonly type = '[Supplier] Save Supplier Emails V2';

  constructor(
    public email: string,
    public supplierUuid?: string
  ) {}
}
export class ResetCurrentSupplier {
  static readonly type = '[Supplier] Reset Current Supplier';
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Suppliers V2] Reset Pagination And Load Data';
}
