import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DropdownOptionComponent } from '../components/dropdown-option/dropdown-option.component';

@Directive({
  selector: '[appDropdownSearchableOption]',
})
export class DropdownSearchableOptionDirective implements OnChanges {
  /** Searched param */
  @Input()
  searched: string;

  /** A property name is required to be compared with the searched param in case of an object. */
  @Input()
  searchByProperty: string;

  constructor(
    private el: ElementRef,
    private option: DropdownOptionComponent
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.searched &&
      changes.searched.previousValue !== changes.searched.currentValue
    ) {
      this._handleOptionSearch();
    }
  }

  private _handleOptionSearch(): void {
    if (typeof this.option.value === 'object') {
      this._handleObjectOptionSearch();
      return;
    }

    this._handleArrayOptionSearch();
  }

  private _handleObjectOptionSearch(): void {
    if (!this.searchByProperty) {
      throw new Error('No property name was provided to filter the options');
    }

    if (
      !this.option.value[this.searchByProperty]
        .toLowerCase()
        .includes(this.searched.toLowerCase())
    ) {
      this._hideOption();
    } else {
      this._showOption();
    }
  }

  private _handleArrayOptionSearch(): void {
    if (
      !this.option.value?.toLowerCase().includes(this.searched.toLowerCase()) &&
      !this.option.label?.toLowerCase().includes(this.searched.toLowerCase())
    ) {
      this._hideOption();
    } else {
      this._showOption();
    }
  }

  private _showOption(): void {
    this.el.nativeElement.style.display = 'block';
  }

  private _hideOption(): void {
    this.el.nativeElement.style.display = 'none';
  }
}
