import { ExportData } from 'src/app/shared/models/exports/exports.model';

export class ExportOrder {
  static readonly type = '[Deliveries Export] Export Orders';

  constructor(
    public buyOrderUuid: string,
    public fileType: string
  ) {}
}

export class ExportOnlyOrderUndelivered {
  static readonly type = '[Deliveries Export] Export Only Orders Undelivered';

  constructor(
    public buyOrderUuid: string,
    public fileType: string
  ) {}
}

export class OrderExported {
  static readonly type = '[Deliveries Export] Order Exported';

  constructor(public exportData: ExportData) {}
}
