import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  numberAttribute,
} from '@angular/core';
import {
  FILTER_FORM,
  FilterInputsValidation,
} from '../../../../model/filter-v2.model';
import { FilterAbstractControl } from '../../../core/filter-abstract-control';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-filter-number-equals-v2',
  templateUrl: './filter-number-equals-v2.component.html',
  styleUrls: ['./filter-number-equals-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterNumberEqualsV2Component),
      multi: true,
    },
    {
      provide: FILTER_FORM,
      useExisting: FilterNumberEqualsV2Component,
    },
  ],
})
export class FilterNumberEqualsV2Component
  extends FilterAbstractControl
  implements FilterInputsValidation<number>
{
  @ViewChild(NgForm)
  form: NgForm;

  @Input({ transform: numberAttribute })
  step: number;

  @Input({ transform: numberAttribute })
  min: number;

  @Input({ transform: numberAttribute })
  max: number;

  readonly COMMON_STRINGS = MESSAGES.common;

  get invalidMin(): boolean {
    return (
      this.value?.to !== null &&
      this.min !== null &&
      Number(this.value?.to) < this.min
    );
  }

  get invalidMax(): boolean {
    return (
      this.value?.to !== null &&
      this.max !== null &&
      Number(this.value?.to) > this.max
    );
  }
}
