<div class="container">
  <div class="section">
    <span class="bracket-title">{{
      STRINGS.emailManagement.templatesTitle
    }}</span>

    @if (loading()) {
      <app-skeleton-rect height="1.5rem" />
    } @else {
      <app-email-templates-v2-context
        [contextOptions]="
          availableTemplatesContexts() | appTemplatesContextBuilder
        "
        [context]="currentTemplatesContext()"
        (contextChange)="updateTemplatesContext($event)" />
    }
  </div>

  <div class="section">
    <span class="bracket-title">{{
      STRINGS.emailManagement.recipientsTitle
    }}</span>

    @if (loading()) {
      <app-skeleton-rect height="1.5rem" />
    } @else {
      <app-email-selection
        [(emailsAdresses)]="emailsAdresses"
        [emailsAddressesList]="emailsAddressesList()"
        [disabled]="isPanelOpen()" />
    }
  </div>

  <app-email-creation
    #emailCreationPanel="emailCreation"
    [hasEmailsAddresses]="hasEmailAddresses()"
    (createdEmailAddress)="createEmailAddress($event)"
    [saving]="saving()" />

  <div [class.unfocused]="isPanelOpen()">
    @if (loading()) {
      <div class="loading-templates-container">
        <app-skeleton-rect height="1.5rem" />

        <app-skeleton-rect height="1.5rem" />

        <app-skeleton-rect height="9rem" />
      </div>
    } @else {
      <app-email-templates-v2
        [ignoreNotFilledLanguages]="ignoreNotFilledLanguages()"
        ignorePermissions />
    }
  </div>
</div>
