<button
  aria-label="Filters toggle button"
  class="filter-container"
  [class.filter-active]="opened() || hasFilters()"
  [class.filter-show-count]="hasFilters()"
  [class.filter-opened]="opened()"
  (click)="toggle($event)"
  [disabled]="disabled()">
  <span class="filter-wrapper">
    <span class="filter-count">{{ filtersCount() }}</span>
    <mat-icon svgIcon="filter-outline" class="filter-icon-filter" />

    <span class="filter-title">{{ COMMON_STRINGS.filter.toggleTitle }}</span>
  </span>

  <mat-icon svgIcon="angle-down-xs" class="filter-icon" />
</button>
