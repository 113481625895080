import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { EmailStatus as EmailStatusV1 } from '../model/emails.model';
import {
  EmailStatus,
  EmailStatusEvent,
} from 'src/app/shared/models/emails/v1/emails.model';
import { Store } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { formatDate } from '@angular/common';

export class EmailStatusAdapter
  implements DataAdapter<EmailStatusV1, EmailStatus>
{
  timezone: string;

  constructor(store: Store) {
    this.timezone = store.selectSnapshot(
      WebshopState.selected
    ).settings.timeZoneOffset;
  }

  transform(data: EmailStatusV1): EmailStatus {
    const events: EmailStatusEvent[] = data.events.map(event => {
      return {
        eventName: event.event_name,
        processed:
          event.processed &&
          new Date(
            formatDate(
              event.processed,
              'yyyy-MM-dd HH:mm:ss',
              'en-US',
              this.timezone
            )
          ),
      };
    });

    return {
      ccEmails: data.cc_emails,
      events,
    };
  }
}
