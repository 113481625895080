import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, mergeMap, Observable, tap } from 'rxjs';
import { LoadFailed } from 'src/app/core/actions/app.action';
import { BuyOrderV2Service } from 'src/app/core/api/buy-order/v2/buy-order-v2.service';
import { PurchaseAdviceRequestInfo } from 'src/app/core/api/products/v2/model/product.model';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { PurchaseAdvice as PurchaseAdviceV2 } from 'src/app/shared/models/buy-orders/v2/buy-order-advice.model';
import { Supplier } from 'src/app/shared/models/suppliers/product-suppliers.model';
import {
  LoadProductSuppliers,
  LoadPurchaseAdvice,
  LoadSupplyChainInformation,
  ResetSupplyChainInformation,
} from '../actions/supply-chain-information-v2.actions';
import {
  defaultSupplyChain,
  SupplyChain,
} from '../model/supply-chain-information-v2.model';
import { SuppliersV2Service } from 'src/app/core/api/supply/v2/suppliers-v2.service';

export interface SupplyChainInformationV2StateModel extends SupplyChain {}

@State<SupplyChainInformationV2StateModel>({
  name: 'supplyChainInformationV2State',
  defaults: defaultSupplyChain,
})
@Injectable()
export class SupplyChainInformationV2State {
  constructor(
    private store: Store,

    private suppliersV2Service: SuppliersV2Service,
    private buyOrderV2Service: BuyOrderV2Service
  ) {}

  @Action(LoadSupplyChainInformation, { cancelUncompleted: true })
  loadSupplyChainInformation(
    ctx: StateContext<SupplyChainInformationV2StateModel>
  ) {
    ctx.patchState({
      loading: true,
    });

    return ctx.dispatch(new LoadProductSuppliers()).pipe(
      mergeMap(() => ctx.dispatch(new LoadPurchaseAdvice())),
      tap(() => {
        ctx.patchState({
          loading: false,
          failed: false,
        });
      })
    );
  }

  @Action(ResetSupplyChainInformation, { cancelUncompleted: true })
  resetSupplyChainInformation(
    ctx: StateContext<SupplyChainInformationV2StateModel>
  ) {
    ctx.patchState(defaultSupplyChain);
  }

  @Action(LoadProductSuppliers, { cancelUncompleted: true })
  loadProductSuppliers(ctx: StateContext<SupplyChainInformationV2StateModel>) {
    return this._fetchProductSuppliers(ctx).pipe(
      tap((supplier: Supplier) => {
        ctx.setState(
          patch<SupplyChainInformationV2StateModel>({
            activeSupplier: supplier,
          })
        );
      })
    );
  }

  @Action(LoadPurchaseAdvice, { cancelUncompleted: true })
  loadPurchaseAdvice(ctx: StateContext<SupplyChainInformationV2StateModel>) {
    const requestInfo: PurchaseAdviceRequestInfo =
      this._buildFindPurchaseAdviceRequestInfo();

    return this._findPurchaseAdviceV2(ctx, requestInfo).pipe(
      tap((data: PurchaseAdviceV2) => {
        ctx.patchState({
          purchaseAdvice: data.advices,
        });
      })
    );
  }

  private _fetchProductSuppliers(
    ctx: StateContext<SupplyChainInformationV2StateModel>
  ): Observable<Supplier | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    if (webshopProductUuid !== null) {
      return this.suppliersV2Service
        .productDetails(webshopUuid, webshopProductUuid)
        .pipe(
          catchError(() => {
            ctx.patchState({
              loading: false,
              failed: true,
            });
            return ctx.dispatch(new LoadFailed());
          })
        );
    }
  }

  private _findPurchaseAdviceV2(
    ctx: StateContext<SupplyChainInformationV2StateModel>,
    payload: PurchaseAdviceRequestInfo
  ): Observable<PurchaseAdviceV2 | void> {
    return this.buyOrderV2Service
      .findPurchaseAdvice(payload.webshopUuid, payload.webshopProductUuid, {
        orderMomentType: payload.orderMomentType,
      })
      .pipe(
        catchError(() => {
          ctx.patchState({
            loading: false,
            failed: true,
            purchaseAdvice: defaultSupplyChain.purchaseAdvice,
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildFindPurchaseAdviceRequestInfo(): PurchaseAdviceRequestInfo {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = this.store.selectSnapshot(
      ProductDetailsV2StateQueries.productUuid
    );

    return {
      webshopUuid: webshopUuid,
      webshopProductUuid,
      orderMomentType: '',
    };
  }
}
