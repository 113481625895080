<app-dialog-container
  ><div appDialogHeader>
    <mat-icon svgIcon="arrow-download" class="title-icon"></mat-icon>
    <h1 appDialogTitle>{{ EXPORTS_DIALOG_STRINGS.title }}</h1>
    <button
      aria-label="Exports dialog close button"
      (click)="close()"
      class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <form #form="ngForm">
    <div appDialogBody>
      <div class="container">
        <mat-icon class="info-icon" svgIcon="info-rounded-xs"></mat-icon>
        <p class="sub-title">{{ EXPORTS_DIALOG_STRINGS.subtitle }}</p>
      </div>

      <div class="exports-container">
        <div class="exports-input-section">
          <label for="name" class="label">{{
            EXPORTS_DIALOG_STRINGS.fields.fileName
          }}</label>

          <div class="fields-input-container">
            <input
              appInput
              variant="filled"
              name="name"
              [placeholder]="EXPORTS_DIALOG_STRINGS.fields.fileNamePlaceholder"
              [(ngModel)]="fileName"
              pattern="^[^.]*$"
              required />
          </div>
        </div>

        <div class="exports-input-section">
          <label for="format" class="label">{{
            EXPORTS_DIALOG_STRINGS.fields.format
          }}</label>

          <app-radio-button-group-v2
            class="options"
            name="format"
            [(ngModel)]="fileFormat">
            <app-radio-button-v2 [value]="EXPORTS_FORMATS.CSV">{{
              EXPORTS_FORMATS_STRINGS.csv
            }}</app-radio-button-v2>
            <app-radio-button-v2 [value]="EXPORTS_FORMATS.XLSX">{{
              EXPORTS_FORMATS_STRINGS.xlsx
            }}</app-radio-button-v2>
          </app-radio-button-group-v2>
        </div>

        @if (haveSelectedItems) {
          <div class="exports-input-section">
            <label for="format" class="label">{{
              EXPORTS_DIALOG_STRINGS.fields.export
            }}</label>

            <app-radio-button-group-v2
              class="options"
              name="exportOnly"
              [(ngModel)]="exportOnly">
              <app-radio-button-v2 value="selected">{{
                EXPORTS_DIALOG_STRINGS.fields.selected +
                  ' (' +
                  data.include.length +
                  ')'
              }}</app-radio-button-v2>
              <app-radio-button-v2 value="fullTable">{{
                EXPORTS_DIALOG_STRINGS.fields.full
              }}</app-radio-button-v2>
            </app-radio-button-group-v2>
          </div>
        }
      </div>
    </div>

    <div appDialogActions>
      <div class="action-buttons-container">
        <button
          aria-label="Exports dialog cancel button"
          appButton
          variant="tertiary"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          aria-label="Exports dialog export button"
          appButton
          variant="primary"
          [disabled]="form.invalid"
          (click)="createExport()">
          {{ EXPORTS_ACTIONS_STRINGS.export }}
        </button>
      </div>
    </div>
  </form>
</app-dialog-container>
