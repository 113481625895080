import {
  BaseConfirmationDialogSize,
  ComponentConfirmActionsKeys,
} from 'src/app/shared/components/base-confirmation-dialog/model/base-confirmation-data.model';
import { STRINGS } from './purchase-v3.strings';

export const ignoreSupplierDialogConfig = {
  width: BaseConfirmationDialogSize,
  disableClose: true,
  data: {
    title: $localize`Ignore supplier?`,
    body: $localize`This supplier will be ignored and <strong>no longer</strong> appear in the purchase calendar. You can later adjust this setting on the suppliers page.`,
    actionName: $localize`Ignore supplier`,
    actionType: 'danger',
    actionKey: ComponentConfirmActionsKeys.IGNORE_SUPPLIER,
  },
};

export interface OrderlinesSelection {
  supplierProductUuid: string;
  quantity?: number;
}

export interface ProposedOrderlinesSelection extends OrderlinesSelection {
  webshopProductUuid: string;
}

export const enum PurchaseOrderType {
  PROPOSED = 0,
  MANUAL = 1,
  CONCEPT = 2,
}

export enum OrderRedirectTo {
  PROPOSED = 'proposed',
  DRAFT = 'draft',
}

export const relatedProductV3Columns: string[] = [
  STRINGS.columns.purchaseEditor.NOVEL.key,
];
