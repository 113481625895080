import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WarningBannerType } from './model/warning-banner.model';

@Component({
  selector: 'app-warning-banner',
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningBannerComponent {
  @Input({ required: true })
  type: WarningBannerType;

  @Input()
  icon: string;

  @Input({ required: true })
  description: string;

  get containerClass(): string {
    return `warning-container warning-container-${this.type}`;
  }
}
