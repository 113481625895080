<div class="group-container">
  @for (group of columnsGroup; track idx; let idx = $index) {
    <button
      appButton
      variant="link"
      size="sm"
      class="group"
      [class.group-active]="currentGroup().index === idx"
      (click)="selectCurrentGroup(idx, group)"
      [disabled]="group.disabled()">
      <span
        class="group-label"
        [appTooltip]="group.label()"
        tooltipShouldCheckOverflow
        >{{ group.label() }}</span
      >
    </button>
  }
</div>

<div class="group-content">
  <ng-container [ngTemplateOutlet]="currentGroup().template"></ng-container>
</div>
