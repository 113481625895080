import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { catchError, concatMap, Observable, tap } from 'rxjs';
import { SaveFailed, SaveSucceed } from 'src/app/core/actions/app.action';
import { PromotionsService } from 'src/app/core/api/promotions/v1/promotions.service';
import { ProductPromotionsBuilderService } from 'src/app/core/services/product-promotions-builder.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { BaseWebshopProductPromotionsV2EditorState } from 'src/app/shared/models/promotion-editor/product-promotion-v2-editor.state';
import {
  BaseWebshopProductPromotionsEditorStateModel,
  EditedProductPromotion,
} from 'src/app/shared/models/promotion-editor/product-promotions-editor.model';
import { CreatePromotionWebshopProductsProperties } from 'src/app/shared/models/promotion/promotion.model';
import { WebshopProductPromotion } from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import {
  CancelEditWebshopProductPromotions,
  DeleteWebshopProductPromotion,
  LoadWebshopProductPromotions,
  LoadWebshopProductUnassociatedPromotions,
  SaveEditedWebshopProductPromotions,
  UpdateWebshopProductUpliftIncrease,
  UpdateWebshopProductUpliftType,
} from '../actions/product-promotions-v2.actions';
import { DialogRef } from '@angular/cdk/dialog';
import { ProductPromotionsEditorListener } from '../model/product-promotions-editor-listener.model';

export interface WebshopProductPromotionsEditorStateModel
  extends BaseWebshopProductPromotionsEditorStateModel<EditedProductPromotion> {
  saving: boolean;
  editedWebshopProductPromotions: EditedProductPromotion[];
}

@State<WebshopProductPromotionsEditorStateModel>({
  name: 'webshopProductPromotionsEditorState',
  defaults: {
    saving: false,
    editedWebshopProductPromotions: [],
  },
})
@Injectable()
export class WebshopProductPromotionsEditorState extends BaseWebshopProductPromotionsV2EditorState<WebshopProductPromotion> {
  constructor(
    private store: Store,
    private promotionsService: PromotionsService,
    private productPromotionsBuilderService: ProductPromotionsBuilderService
  ) {
    super(new ProductPromotionsEditorListener());
  }

  @Action(CancelEditWebshopProductPromotions, { cancelUncompleted: true })
  cancelEditPromotions(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>
  ) {
    ctx.patchState({
      editedWebshopProductPromotions: [],
    });
  }

  @Action(DeleteWebshopProductPromotion, { cancelUncompleted: true })
  deletePromotionWebshopProduct(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    payload: DeleteWebshopProductPromotion
  ) {
    ctx.patchState({
      saving: true,
    });

    return this._deleteWebshopProductPromotion(
      ctx,
      payload.promotionUUID,
      payload.webshopProductUUID,
      payload.dialogRef
    ).pipe(
      concatMap(() =>
        ctx.dispatch([
          new LoadWebshopProductPromotions({ preventLoading: true }),
          new LoadWebshopProductUnassociatedPromotions(),
          new SaveSucceed(),
        ])
      ),
      tap(() => {
        payload.dialogRef.close();

        ctx.patchState({ saving: false });
      })
    );
  }

  @Action(UpdateWebshopProductUpliftType, { cancelUncompleted: true })
  updateProductPromotionUpliftType(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    payload: UpdateWebshopProductUpliftType
  ) {
    const state = ctx.getState();

    const lines = this._getProductPromotionLine(
      payload.data.promotion.uuid,
      state.editedWebshopProductPromotions
    );

    if (lines.length > 1) {
      return this.duplicatedEditedProductPromotionsError(
        payload.data.promotion.uuid
      );
    }

    if (lines.length === 0) {
      this._updateUpliftTypeForNewEditedProductPromotion(ctx, payload);
    } else if (lines.length === 1) {
      this._updateUpliftTypeForExistingEditedProductPromotion(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(UpdateWebshopProductUpliftIncrease, { cancelUncompleted: true })
  updateProductPromotionUpliftIncrease(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    payload: UpdateWebshopProductUpliftIncrease
  ) {
    const state = ctx.getState();

    const lines = this._getProductPromotionLine(
      payload.data.promotion.uuid,
      state.editedWebshopProductPromotions
    );

    if (lines.length > 1) {
      return this.duplicatedEditedProductPromotionsError(
        payload.data.promotion.uuid
      );
    }

    if (lines.length === 0) {
      this._updateUpliftIncreaseForNewEditedProductPromotion(ctx, payload);
    } else if (lines.length === 1) {
      this._updateUpliftIncreaseForExistingEditedProductPromotion(
        ctx,
        payload,
        lines[0]
      );
    }
  }

  @Action(SaveEditedWebshopProductPromotions, { cancelUncompleted: true })
  saveWebshopProductPromotions(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>
  ) {
    const state = ctx.getState();

    const productPromotions =
      this.productPromotionsBuilderService.buildProductPromotionsUpdateFromResult(
        state.editedWebshopProductPromotions
      );

    const properties: CreatePromotionWebshopProductsProperties = {
      products: [...productPromotions.editedProductPromotions],
    };

    return this._updatePromotionWebshopProducts(ctx, properties).pipe(
      tap(() => {
        ctx.patchState({
          saving: false,
          editedWebshopProductPromotions: [],
        });
      }),
      concatMap(() =>
        ctx.dispatch([
          new CancelEditWebshopProductPromotions(),
          new LoadWebshopProductPromotions(),
          new SaveSucceed(),
        ])
      )
    );
  }

  private _deleteWebshopProductPromotion(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    promotionUuid: string,
    webshopProductUuid: string,
    dialogRef: DialogRef<any>
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.promotionsService
      .deletePromotionWebshopProduct(
        webshopUuid,
        promotionUuid,
        webshopProductUuid
      )
      .pipe(
        catchError(() => {
          ctx.patchState({
            saving: false,
          });

          dialogRef.close();

          return ctx.dispatch(new SaveFailed());
        })
      );
  }

  /* Uplift type */
  private _updateUpliftTypeForNewEditedProductPromotion(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    payload: UpdateWebshopProductUpliftType
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateUpliftTypeNewProductPromotion(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedWebshopProductPromotions: [
        ...state.editedWebshopProductPromotions,
        ...[result.newProductPromotion],
      ],
    });
  }

  private _updateUpliftTypeForExistingEditedProductPromotion(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    payload: UpdateWebshopProductUpliftType,
    editedProductPromotion: EditedProductPromotion
  ): void {
    const state = ctx.getState();
    const result = this._buildUpdateUpliftTypeExistingEditedProductPromotion(
      payload.data,
      editedProductPromotion,
      state.editedWebshopProductPromotions,
      payload.value
    );

    ctx.patchState({
      editedWebshopProductPromotions: [
        ...result.newLines,
        ...[result.newProductPromotion],
      ],
    });
  }

  /* Uplift Increase */
  private _updateUpliftIncreaseForNewEditedProductPromotion(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    payload: UpdateWebshopProductUpliftIncrease
  ) {
    const state = ctx.getState();
    const result = this._buildUpdateUpliftIncreaseNewProductPromotion(
      payload.data,
      payload.value
    );

    ctx.patchState({
      editedWebshopProductPromotions: [
        ...state.editedWebshopProductPromotions,
        ...[result.newProductPromotion],
      ],
    });
  }

  private _updateUpliftIncreaseForExistingEditedProductPromotion(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    payload: UpdateWebshopProductUpliftIncrease,
    editedProductPromotion: EditedProductPromotion
  ): void {
    const state = ctx.getState();
    const result =
      this._buildUpdateUpliftIncreaseExistingEditedProductPromotion(
        payload.data,
        editedProductPromotion,
        state.editedWebshopProductPromotions,
        payload.value
      );

    ctx.patchState({
      editedWebshopProductPromotions: [
        ...result.newLines,
        ...[result.newProductPromotion],
      ],
    });
  }

  private _updatePromotionWebshopProducts(
    ctx: StateContext<WebshopProductPromotionsEditorStateModel>,
    properties: CreatePromotionWebshopProductsProperties
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.promotionsService
      .updatePromotionWebshopProducts(properties, webshopUuid)
      .pipe(
        catchError(() => {
          ctx.patchState({
            saving: false,
          });

          return ctx.dispatch(new SaveFailed());
        })
      );
  }
}
