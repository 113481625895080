import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedSettingsComponent } from './advanced-settings.component';
import { ExpansionPanelModule } from '../design-system/expansion-panel/expansion-panel.module';

@NgModule({
  declarations: [AdvancedSettingsComponent],
  imports: [CommonModule, ExpansionPanelModule],
  exports: [AdvancedSettingsComponent],
})
export class AdvancedSettingsModule {}
