import {
  FilterStrategy,
  FilterTypeOptionsV2,
} from '../../model/filter-v2.model';
import { FilterEqualsStrategy } from './filter-strategies/filter-equals-strategy';
import { FilterNullStrategy } from './filter-strategies/filter-null-strategy';
import { FilterNullableStrategy } from './filter-strategies/filter-nullable-strategy';
import { FilterRangeStrategy } from './filter-strategies/filter-range-strategy';

export class FilterStrategiesHandler {
  getFilterStrategy(
    strategyRef: FilterTypeOptionsV2,
    isDate: boolean = false
  ): FilterStrategy<any> {
    switch (strategyRef) {
      case FilterTypeOptionsV2.EQUALS:
        return new FilterEqualsStrategy(isDate);
      case FilterTypeOptionsV2.RANGED:
        return new FilterRangeStrategy(isDate);
      case FilterTypeOptionsV2.IS_NULL:
      case FilterTypeOptionsV2.NOT_EXISTS:
        return new FilterNullStrategy();
      case FilterTypeOptionsV2.NULLABLE:
        return new FilterNullableStrategy();

      default:
        throw new Error('Please provide a valid filter strategy');
    }
  }
}
