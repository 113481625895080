import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FilterOption } from '../../model/filter.model';

@Component({
  selector: 'app-filter-type-options-v2',
  templateUrl: './filter-type-options-v2.component.html',
  styleUrls: ['./filter-type-options-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTypeOptionsV2Component implements OnInit {
  options = input<FilterOption[]>([]);

  preSelectedOption = input<string | null>(null);

  selectedOption = signal<string | null>(null);

  @Output()
  optionSelected: EventEmitter<string> = new EventEmitter<string>();

  readonly COMMON_STRINGS = MESSAGES.common;

  ngOnInit(): void {
    this._preSelection();
  }

  displayWithKey(option: FilterOption): string {
    return option.key;
  }

  private _preSelection(): void {
    if (this.preSelectedOption()) {
      this._preSelectOption();
      return;
    }

    this._preSelectFirstOption();
  }

  private _preSelectFirstOption(): void {
    if (this.options().length === 0) return;

    this.selectedOption.set(this.options()[0].value);

    this.optionSelected.emit(this.selectedOption());
  }

  private _preSelectOption(): void {
    if (this.options().length === 0) return;

    this.selectedOption.set(this.preSelectedOption());

    this.optionSelected.emit(this.selectedOption());
  }
}
