import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Input,
} from '@angular/core';
import { TabBase } from '../../core/tab-base';

function convertToArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

@Component({
  selector: 'app-tab-dropdown',
  templateUrl: './tab-dropdown.component.html',
  styleUrl: './tab-dropdown.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabDropdownComponent extends TabBase {
  isGroup = true;

  @Input({ required: true })
  override label: string;

  @Input({ transform: booleanAttribute })
  override disabled: boolean;

  navigateTo = input.required<string>();

  customClasses = input([], {
    transform: convertToArray,
  });

  baseDropdownClasses = computed(() => [
    'tab-dropdown',
    ...this.customClasses(),
  ]);
}
