<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <app-data-table-refresh-button
      data-intercom-target="Refresh table data button"
      aria-label="Refresh table data button"
      [loading]="loading()"
      [disabled]="loading() || !canRefresh()"
      (refreshed)="refreshDatatable()" />

    <div class="vertical-separator"></div>

    <button
      *ngIf="exportsEnabled() && isPopout() === false"
      data-intercom-target="6d83181d-0b37-44fc-a24d-9190b98588c3"
      aria-label="Product details promotions table export button"
      appButton
      appButtonIcon
      variant="link"
      transparent
      class="export-button"
      [appTooltip]="COMMON_STRINGS.exportAs"
      (click)="openExportsDialog()">
      <mat-icon svgIcon="arrow-download" class="gray"></mat-icon>
    </button>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="filterOpened()">
  <app-filter-group [filters]="activeFilters()" (filtered)="onFiltered($event)">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"></app-add-filter-button>
  </app-filter-group>
</app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    [disableHideColumn]="lastDisplayedColumn()"
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)"
    (filtered)="onFiltered($event)">
    <!-- Name -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.NAME.key">
      <th
        data-intercom-target="2259e47f-7a93-4cd7-a9e5-3d53daee9f21"
        aria-label="Product details promotions table Name column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.NAME.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.NAME.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <ng-container
          *ngIf="element.promotion.name !== null; else notAvailable">
          <p
            class="deep-linking-text"
            (click)="goToPromotionDetails(element.promotion.uuid)"
            [appTooltip]="element.promotion.name"
            tooltipShouldCheckOverflow>
            <app-copy-to-clipboard
              [message]="element.promotion.name"></app-copy-to-clipboard>
          </p>
        </ng-container>
      </td>
    </ng-container>

    <!-- Start date -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.START_DATE.key">
      <th
        data-intercom-target="b740b2a2-8e1d-4121-8f7b-00370c5301d7"
        aria-label="Product details promotions table Start date column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.START_DATE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.START_DATE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="element.promotion.interval.start !== null; else notAvailable">
          <span>{{ element.promotion.interval.start | date }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- End date -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.END_DATE.key">
      <th
        data-intercom-target="2ce8803c-6e68-4b2a-8ec0-caa13bba6a7c"
        aria-label="Product details promotions table End date column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.END_DATE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.END_DATE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="element.promotion.interval.end !== null; else notAvailable">
          <span>{{ element.promotion.interval.end | date }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Expected uplift -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.EXPECTED_UPLIFT.key">
      <th
        data-intercom-target="543f5db9-af55-437b-84e5-b697366afe35"
        aria-label="Product details promotions table Expected uplift column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.EXPECTED_UPLIFT.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.EXPECTED_UPLIFT.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container
          *ngIf="element.promotion.uplift.increase !== null; else notAvailable">
          <ng-container
            *ngIf="element.promotion.uplift.type === UPLIFT_TYPES.RELATIVE">
            <ng-container
              *ngTemplateOutlet="
                relative;
                context: { $implicit: element.promotion.uplift.increase }
              "></ng-container>
          </ng-container>

          <ng-container
            *ngIf="element.promotion.uplift.type === UPLIFT_TYPES.ABSOLUTE">
            <ng-container
              *ngTemplateOutlet="
                absolute;
                context: { $implicit: element.promotion.uplift.increase }
              "></ng-container>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!-- Product uplift type -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.PRODUCT_UPLIFT_TYPE.key">
      <th
        data-intercom-target="a428bc3a-b250-44aa-bfd8-928e788c1d7b"
        aria-label="Product details promotions table Product uplift type column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.PRODUCT_UPLIFT_TYPE.key
            | filterColumn: RELATED_STATE
            | async
        ">
        {{ TABLE_COLUMNS.PRODUCT_UPLIFT_TYPE.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngIf="
            element.webshopProduct.specificUplift !== null;
            else notAvailable
          ">
          <span>{{ element.extras.formattedUpliftType }}</span>
        </ng-container>
      </td>
    </ng-container>

    <!-- Product uplift increase -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.PRODUCT_UPLIFT.key">
      <th
        data-intercom-target="4c63fba7-4c01-49b0-a2fe-04f80479c4da"
        aria-label="Product details promotions table Product uplift increase column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.PRODUCT_UPLIFT.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.PRODUCT_UPLIFT.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container
          *ngIf="
            element.webshopProduct.specificUplift !== null;
            else notAvailable
          ">
          <ng-container
            *ngIf="
              element.webshopProduct.specificUplift.type ===
              UPLIFT_TYPES.RELATIVE
            ">
            <ng-container
              *ngTemplateOutlet="
                relative;
                context: {
                  $implicit: element.webshopProduct.specificUplift.increase
                }
              "></ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              element.webshopProduct.specificUplift.type ===
              UPLIFT_TYPES.ABSOLUTE
            ">
            <ng-container
              *ngTemplateOutlet="
                absolute;
                context: {
                  $implicit: element.webshopProduct.specificUplift.increase
                }
              "></ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              element.webshopProduct.specificUplift.type !==
                UPLIFT_TYPES.RELATIVE &&
              element.webshopProduct.specificUplift.type !==
                UPLIFT_TYPES.ABSOLUTE
            ">
            <ng-container *ngTemplateOutlet="notAvailable"></ng-container>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!--Number of products -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.NUMBER_OF_PRODUCTS.key">
      <th
        data-intercom-target="ba31ca89-88fb-4277-a090-e1d665f14573"
        aria-label="Product details promotions table Number of products column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.NUMBER_OF_PRODUCTS.key
            | filterColumn: RELATED_STATE
            | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.NUMBER_OF_PRODUCTS.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container
          *ngIf="
            element.promotion.numberOfProducts !== null;
            else notAvailable
          ">
          <span>{{ element.promotion.numberOfProducts | customDecimal }}</span>
        </ng-container>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      data-intercom-target="fc6d5e7c-c7e0-4066-abae-d8b6a65365cb"
      aria-label="Product details promotions table row"
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"
      [class.disabled-promotion]="!row.promotion.settings.enabled"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>

<ng-template #absolute let-upliftIncrease>
  {{ upliftIncrease | customDecimal }}
  <span class="no-percentage">%</span>
</ng-template>

<ng-template #relative let-upliftIncrease>
  {{ upliftIncrease | customDecimal }}
  <span class="gray">%</span>
</ng-template>
