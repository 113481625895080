export const STRINGS = {
  tooltips: {
    defaultLanguage: $localize`This setting is overridden by the Supplier preferred language`,
  },
  metadata: {
    email: $localize`Email`,
    title: $localize`Language`,
    languages: {
      english: $localize`English`,
      dutch: $localize`Dutch`,
      german: $localize`German`,
    },
    subject: {
      title: $localize`Subject`,
      placeholder: $localize`Enter subject`,
      error: $localize`Subject field is required`,
    },
    body: {
      title: $localize`Body`,
      error: $localize`Body field is required`,
    },
    defaultTemplates: {
      en: {
        subject: 'Purchase Order {{orderId}} from {{company.name}}',
        body: `Dear {{supplier.name}},\n\nEnclosed with this e-mail is our purchase order with id: {{orderId}}.\n\nThanks in advance.\n\nBest regards,\n\n{{company.name}}`,
      },
      nl: {
        subject: 'Inkooporder {{orderId}} van {{company.name}}',
        body: `Beste {{supplier.name}},\n\nBij deze e-mail vindt u onze inkooprder met ID: {{orderId}}.\n\nBij voorbaat dank.\n\nMet vriendelijke groet,\n\n{{company.name}}`,
      },
      de: {
        subject: 'Bestellnummer {{orderId}} von {{company.name}}',
        body: `Beste {{supplier.name}},\n\nSehr geehrte Damen und Herren,\n\nIm Anhang dieser E-Mail finden Sie unsere Bestellung mit ID: {{orderId}}.\n\nVielen Dank im Voraus.\n\nMit freundlichen Grüßen,\n\n{{company.name}}
        `,
      },
    },
  },
};
