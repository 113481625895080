import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  model,
} from '@angular/core';
import { DropdownModule } from '../../../design-system/dropdown/dropdown.module';
import { TagsModule } from '../../../design-system/tags/tags.module';
import { STRINGS } from '../../model/email-management.strings';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { TooltipModule } from '../../../design-system/tooltip/tooltip.module';
import { ButtonModule } from '../../../design-system/button/button.module';
import { RequiredLabelDirective } from 'src/app/shared/directives/required-label.directive';

@Component({
  selector: 'app-email-selection',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    TagsModule,
    TooltipModule,
    ButtonModule,
    RequiredLabelDirective,
  ],
  templateUrl: './email-selection.component.html',
  styleUrl: './email-selection.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailSelectionComponent {
  disabled = input<boolean>();

  emailsAddressesList = input.required<string[]>();

  hasEmailsAddressesList = computed(
    () => this.emailsAddressesList().length > 0
  );

  emailsAdresses = model<string[]>();

  remainEmailsAddressesCount = computed(() => {
    if (this.emailsAdresses().length < 2) return '';

    return `+${this.emailsAdresses().length - 2} ${MESSAGES.common.other}`;
  });

  remainEmailsAddresses = computed(() => {
    if (this.emailsAdresses().length < 2) return '';

    return this.emailsAdresses().slice(2).join('\r\n');
  });

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata.emailManagement;

  constructor() {
    effect(
      () => {
        this.emailsAdresses.set(this.emailsAddressesList());
      },
      { allowSignalWrites: true }
    );
  }
}
