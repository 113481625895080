import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import {
  NoProductCategory,
  ProductCategory,
} from './model/product-category.model';

function transformCategory(
  category: ProductCategory | null
): ProductCategory | NoProductCategory {
  return category === null ? NOT_AVAILABLE_VALUE : category;
}

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCategoryComponent {
  /**
   * Category (A, B, C, X, Y, Z) or its default ('-') in case of null
   */
  category = input(NOT_AVAILABLE_VALUE, {
    transform: transformCategory,
  });

  bordered = input(false, { transform: booleanAttribute });

  /**
   * Disable state (only managing the opacity)
   */
  disabled = input(false, { transform: booleanAttribute });

  advancedCategorization = input(false, { transform: booleanAttribute });

  advancedCategory = input(NOT_AVAILABLE_VALUE, {
    transform: transformCategory,
  });

  categoryClasses = computed(() => {
    const categoryKey =
      this.category() && this.category() !== NOT_AVAILABLE_VALUE
        ? `category-${this.category().toLowerCase()}`
        : '';

    const disabled =
      this.disabled() || this.category() === NOT_AVAILABLE_VALUE
        ? 'disabled'
        : '';

    const bordered = this.bordered() ? 'bordered' : '';

    return ['category-circle', categoryKey, disabled, bordered];
  });

  advancedCategoryClasses = computed(() => {
    const categoryKey =
      this.advancedCategory() && this.advancedCategory() !== NOT_AVAILABLE_VALUE
        ? `category-${this.advancedCategory().toLowerCase()}`
        : '';

    const disabled =
      this.disabled() || this.advancedCategory() === NOT_AVAILABLE_VALUE
        ? 'disabled'
        : '';

    const bordered = this.bordered() ? 'bordered' : '';

    return ['category-circle', categoryKey, disabled, bordered];
  });
}
