import { Observable } from 'rxjs';
import { ChartLoadingOptions } from './model/chart.model';

export abstract class Chart<T> {
  /**
   * Chart's color theme.
   *
   * All charts need to have a colorTheme (light/dark) to match the rest of the app.
   *
   * The theme MUST be in lowercase.
   */
  abstract colorTheme$: Observable<string>;

  /**
   * Chart's loading state.
   *
   * This prevents us to call the loading in a imperative way.
   */
  abstract loading$: Observable<boolean>;

  /**
   * Chart's loading options.
   *
   * This prevents us to duplicate loading options each time we would call the showLoading().
   */
  abstract loadingOptions$: Observable<ChartLoadingOptions>;

  /**
   * Chart's options.
   *
   * This contains all the data related to the chart.
   */
  abstract graphOptions$: Observable<T>;
}
