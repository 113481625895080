import {
  GraphFilter,
  GraphGroupBy,
} from 'src/app/shared/models/sales/sales-v2.model';

export class ProductSalesV2SetInitialState {
  static readonly type = '[Product Sales V2] Set Initial State';
}

export class LoadProductSalesV2 {
  static readonly type = '[Product Sales V2] Load Sales';
}

export class ProductSalesV2SelectFilter {
  static readonly type = '[Product Sales V2] Select Filter';

  constructor(public filter: GraphFilter) {}
}

export class ProductSalesV2SelectGroupBy {
  static readonly type = '[Product Sales V2] Select Group By';

  constructor(public groupBy: GraphGroupBy) {}
}

export class ProductSalesV2Refresh {
  static readonly type = '[Product Sales V2] Refresh';
}

export class ProductSalesV2Reset {
  static readonly type = '[Product Sales V2] Reset';
}
