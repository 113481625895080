import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  booleanAttribute,
  Optional,
  Inject,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  TOGGLE_OPTIONS,
  ToggleOptions,
  ToggleStatePosition,
} from './model/toggle.model';

let uniqueId = 0;

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements ControlValueAccessor {
  @Input()
  id: string = String(uniqueId++);

  @Input()
  label: string | null = null;

  @Input({ transform: booleanAttribute })
  showState: boolean = true;

  @Input({ transform: booleanAttribute })
  checked: boolean;

  @Input({ transform: booleanAttribute })
  disabled: boolean;

  @Input({ transform: transformStatePosition })
  statePosition: ToggleStatePosition = 'after';

  @Output()
  checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange = (_v: any) => {};

  onTouch = () => {};

  constructor(
    @Optional() @Inject(TOGGLE_OPTIONS) defaultOptions: ToggleOptions,
    private cdr: ChangeDetectorRef
  ) {
    if (defaultOptions) {
      this._applyDefaultOptions(defaultOptions);
    }
  }

  writeValue(checked: string | boolean): void {
    this.checked = coerceBooleanProperty(checked);

    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setChecked(): void {
    if (this.disabled) return;

    this.writeValue(!this.checked);
    this.onChange(this.checked);
    this.onTouch();
    this.checkedChange.emit(this.checked);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private _applyDefaultOptions(options: ToggleOptions): void {
    this.showState = options.showState;

    if (options.statePosition) {
      this.statePosition = options.statePosition;
    }
  }
}

function transformStatePosition(position: ToggleStatePosition) {
  if (position === 'before' || position === 'left') {
    return 'before';
  } else if (position === 'after' || position === 'right') {
    return 'after';
  }
}
