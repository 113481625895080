import { ExportData } from 'src/app/shared/models/exports/exports.model';

export class ExportOrder {
  static readonly type = '[Purchase] Export as CSV';

  constructor(
    public webshopUUID: string,
    public buyOrderUUID: string,
    public fileType: string
  ) {}
}

export class ExportOrderV2 {
  static readonly type = '[Purchase V3] Export Order';

  constructor(
    public fileType: string,
    public buyOrderUuid?: string
  ) {}
}

export class OrderExported {
  static readonly type = '[Purchase] Order Exported';

  constructor(public exportData: ExportData) {}
}
