import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import {
  OrderlineOrderType,
  OrderlineRelatedTable,
} from '../components/orderline-info-v3/model/purchase-overlay-v3.model';

export class OpenOverlay {
  static readonly type = '[Purchase Overlay V2] Open';

  constructor(
    public orderline: ProposedBuyOrderline,
    public orderlineRelatedTable: OrderlineRelatedTable | null = null,
    public orderlineOrderType: OrderlineOrderType | null = null
  ) {}
}

export class CloseOverlay {
  static readonly type = '[Purchase Overlay V2] Close';
}

export class ResetOverlay {
  static readonly type = '[Purchase Overlay V2] Reset';
}

export class OpenPopout {
  static readonly type = '[Purchase Overlay V2] Open Popout';
}

export class ClosePopout {
  static readonly type = '[Purchase Overlay V2] Close Popout';
}
