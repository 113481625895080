import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  LOCALE_ID,
  OnInit,
  signal,
  computed,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import {
  SnoozeProductData,
  SnoozeProductTypes,
} from './model/snooze-product.model';
import {
  SnoozeProduct,
  SnoozeProducts,
} from 'src/app/features/purchase-v3/components/proposed-orderlines/actions/proposed-orderlines.actions';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-snooze-product-dialog',
  templateUrl: './snooze-product-dialog.component.html',
  styleUrls: ['./snooze-product-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnoozeProductDialogComponent implements OnInit {
  snooze = signal<SnoozeProductTypes>(SnoozeProductTypes.NEXT_ORDER_MOMENT);

  showDate = computed(() => this.snooze() === SnoozeProductTypes.CUSTOM_DATE);

  snoozeUntil = computed(() => this._getSnoozeUntilDate(this.snooze()));

  snoozeUntilInDays = computed(() => {
    if (this.snooze() === SnoozeProductTypes.NEXT_ORDER_MOMENT)
      return this._nextOrderMomentPeriod;

    if (this.snooze() === SnoozeProductTypes.INDEFINITE) return -1;

    return this._calculateNumberDays(this.snoozeUntil());
  });

  resumingPurchaseDate = computed<string | null>(() => {
    if (this.snoozeUntil() === null) return null;

    return formatDate(this.snoozeUntil(), 'yyyy-MM-dd', this.locale);
  });

  customDate = signal<Date | null>(null);

  message = computed(() => {
    if (this.snooze() === SnoozeProductTypes.CUSTOM_DATE)
      return MESSAGES.common.snooze.selectDate;

    if (this.snooze() === SnoozeProductTypes.INDEFINITE)
      return MESSAGES.common.snooze.indefiniteDate;

    return '';
  });

  minCustomDate: Date = DateTime.fromJSDate(new Date())
    .plus({ days: 1 })
    .toJSDate();

  readonly SNOOZE_TYPES = SnoozeProductTypes;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata.snoozeDialog;

  private _nextOrderMomentPeriod: number = 0;

  private _today = new Date();

  constructor(
    @Inject(DIALOG_DATA) public data: SnoozeProductData | null,
    @Inject(LOCALE_ID) public locale: string,
    private store: Store,
    private dialogRef: DialogRef<SnoozeProductDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.effectiveReplenishmentPeriod) {
      this._nextOrderMomentPeriod = this._calculateNextOrderMomentPeriod(
        this.data.effectiveReplenishmentPeriod
      );
    }
  }

  saveSnooze(): void {
    if (this.data.orderline) {
      this.store.dispatch(
        new SnoozeProduct(
          this.data.orderline.supplierProduct.uuid,
          this.resumingPurchaseDate(),
          this.data.orderline.webshopProduct.uuid,
          this.dialogRef
        )
      );
    } else {
      this.store.dispatch(
        new SnoozeProducts(this.resumingPurchaseDate(), this.dialogRef)
      );
    }
  }

  helpLink() {
    window.open(
      'https://help.optiply.com/en/articles/6603812-snooze',
      '_blank'
    );
  }

  close() {
    this.dialogRef.close();
  }

  private _getSnoozeUntilDate(snoozeType: SnoozeProductTypes): Date | null {
    const todaysDateTime = DateTime.fromJSDate(this._today);

    switch (snoozeType) {
      case SnoozeProductTypes.NEXT_ORDER_MOMENT:
        return todaysDateTime
          .plus({
            days: this._nextOrderMomentPeriod,
          })
          .toJSDate();
      case SnoozeProductTypes.ONE_WEEK:
        return todaysDateTime.plus({ weeks: 1 }).toJSDate();
      case SnoozeProductTypes.ONE_MONTH:
        return todaysDateTime.plus({ months: 1 }).toJSDate();
      case SnoozeProductTypes.THREE_MONTH:
        return todaysDateTime.plus({ months: 3 }).toJSDate();
      case SnoozeProductTypes.CUSTOM_DATE:
        return this.customDate();
      default:
        return null;
    }
  }

  private _calculateNumberDays(newDate: Date | null): number {
    if (newDate === null) return null;

    return Math.floor(
      DateTime.fromJSDate(newDate).diff(
        DateTime.fromJSDate(this._today),
        'days'
      ).days
    );
  }

  private _calculateNextOrderMomentPeriod(
    effectiveReplenishmentPeriod: number
  ): number {
    return effectiveReplenishmentPeriod - 1;
  }
}
