import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngxs/store';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/deliveries-v2/model/deliveries-v2.strings';
import { Delivery } from 'src/app/shared/models/delivery/v2/deliveries-v2.model';
import {
  AddFilterParam,
  AddSearchParam,
  ColumnsSelected,
  HideColumn,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  Sort,
  ToggleFilter,
} from '../../../actions/product-deliveries-v2.actions';
import { DeliveriesOverviewsStateQueries } from '../../../state/product-deliveries-v2.queries';
import { DataTableV2Component } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.component';
import {
  ColumnFilterParam,
  Filtering,
  Pagination,
  Search,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { DeliveriesOverviewsState } from '../../../state/product-deliveries-v2.state';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { COLUMNS_ORDER } from '../../../model/data-table.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

@Component({
  selector: 'app-product-deliveries-v2-data-table',
  templateUrl: './product-deliveries-v2-data-table.component.html',
  styleUrls: ['./product-deliveries-v2-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDeliveriesV2DataTableComponent
  extends DataTableV2Component<Delivery[]>
  implements Pagination, Search, Filtering
{
  suppliersPermission = select(
    PermissionQueries.hasPermission(RolesFeatureKeys.SUPPLIERS)
  );
  totalElements = select(DeliveriesOverviewsStateQueries.totalElements);
  pageSizeOptions = select(DeliveriesOverviewsStateQueries.pageSizeOptions);
  pageSize = select(DeliveriesOverviewsStateQueries.pageSize);
  currentPage = select(DeliveriesOverviewsStateQueries.currentPage);
  selectableColumns = select(DeliveriesOverviewsStateQueries.selectableColumns);
  displayedColumns = select(DeliveriesOverviewsStateQueries.displayedColumns);
  lastDisplayedColumn = select(
    DeliveriesOverviewsStateQueries.lastDisplayedColumn
  );
  datasource = select(DeliveriesOverviewsStateQueries.orders);
  loading = select(DeliveriesOverviewsStateQueries.loading);
  filters = select(DeliveriesOverviewsStateQueries.filters);
  activeFilters = select(DeliveriesOverviewsStateQueries.activeFilters);
  searchBy = select(DeliveriesOverviewsStateQueries.search);
  filterOpened = select(DeliveriesOverviewsStateQueries.filterOpened);

  canRefresh = select(DeliveriesOverviewsState.canRefresh());

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_COLUMNS = STRINGS.columns.deliveriesOverviews;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RELATED_STATE = DeliveriesOverviewsState;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  constructor(private store: Store) {
    super();
  }

  refreshDatatable(): void {
    this.store.dispatch(new ReloadDatatable());
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onColumnHidden(columnKey: string) {
    this.store.dispatch(new HideColumn(columnKey));
  }

  onFiltered(params: ColumnFilterParam): void {
    this.store.dispatch(new AddFilterParam(params));
  }

  onSort(sort: Sorted) {
    this.store.dispatch(new Sort(sort));
  }

  toggleFilter(): void {
    this.store.dispatch(new ToggleFilter());
  }

  clearAllFilters(): void {
    this.store.dispatch(new RemoveAllFilters());
  }

  addSearchParam(param: string) {
    this.store.dispatch(new AddSearchParam(param));
  }

  removeSearchParam(param: string) {
    this.store.dispatch(new RemoveSearchParam(param));
  }

  goToSupplier(supplierUuid: string): void {
    this.store.dispatch(
      new NavigateToWithQueryParams(['suppliers', 'details'], {
        supplierUUID: supplierUuid,
      })
    );
  }

  goToDelivery(orderUuid: string): void {
    this.store.dispatch(
      new NavigateToWithQueryParams(['deliveries', 'editor'], {
        orderUUID: orderUuid,
      })
    );
  }
}
