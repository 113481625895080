import {
  EmailTemplate,
  EmailTemplateLanguage,
  EmailTemplateLanguages,
  EmailTemplateType,
} from 'src/app/shared/models/emails/v1/emails.model';
import { STRINGS } from './email-templates-v2.strings';

export const TEMPLATE_LANGUAGES_TRANSLATIONS = {
  [EmailTemplateLanguages.EN]: STRINGS.metadata.languages.english,
  [EmailTemplateLanguages.NL]: STRINGS.metadata.languages.dutch,
  [EmailTemplateLanguages.DE]: STRINGS.metadata.languages.german,
};

export const TEMPLATE_LANGUAGES: EmailTemplateLanguageOption[] = [
  {
    key: STRINGS.metadata.languages.english,
    value: EmailTemplateLanguages.EN,
  },
  {
    key: STRINGS.metadata.languages.dutch,
    value: EmailTemplateLanguages.NL,
  },
  {
    key: STRINGS.metadata.languages.german,
    value: EmailTemplateLanguages.DE,
  },
];

export const DEFAULT_TEMPLATES = {
  EN: STRINGS.metadata.defaultTemplates.en,
  NL: STRINGS.metadata.defaultTemplates.nl,
  DE: STRINGS.metadata.defaultTemplates.de,
};

export interface EmailTemplateLanguageOption {
  key: string;
  value: EmailTemplateLanguage;
}

export interface EmailTemplateChanged {
  type: EmailTemplateType;
  language: string;
  defaultTemplate: boolean;
  subject: string;
  body: string;
  validChanges: boolean;
}

export interface EmailTemplateModel extends EmailTemplate {
  type: EmailTemplateType;
  isDefault: boolean;
}
