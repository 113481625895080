import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import {
  FILTER_FORM,
  FilterInputsValidation,
} from '../../../../model/filter-v2.model';
import { FilterAbstractControl } from '../../../core/filter-abstract-control';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-filter-date-range-v2',
  templateUrl: './filter-date-range-v2.component.html',
  styleUrls: ['./filter-date-range-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDateRangeV2Component),
      multi: true,
    },
    {
      provide: FILTER_FORM,
      useExisting: FilterDateRangeV2Component,
    },
  ],
})
export class FilterDateRangeV2Component
  extends FilterAbstractControl
  implements FilterInputsValidation<Date>
{
  @ViewChild(NgForm)
  form: NgForm;

  @Input()
  min: Date;

  @Input()
  max: Date;

  readonly COMMON_STRINGS = MESSAGES.common;
}
