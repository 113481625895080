import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayContainerV2Component } from './overlay-container-v2.component';
import {
  DEFAULT_OVERLAY_V2_CONFIG,
  OVERLAY_CONFIG,
} from './model/overlay-container-v2.model';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayBaseV2 } from './core/overlay-base-v2';
import { OverlayDirective } from 'src/app/shared/components/design-system/overlay-container-v2/directives/overlay.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverlayItemDirective } from './directives/overlay-item.directive';
import { OverlayIconDirective } from './directives/overlay-icon.directive';
import { OverlayTitleDirective } from './directives/overlay-title.directive';
import { OverlayHeaderDirective } from './directives/overlay-header.directive';

@NgModule({
  declarations: [
    OverlayContainerV2Component,
    OverlayBaseV2,
    OverlayDirective,
    OverlayItemDirective,
    OverlayHeaderDirective,
    OverlayIconDirective,
    OverlayTitleDirective,
  ],
  imports: [CommonModule, PortalModule, OverlayModule],
  exports: [
    PortalModule,
    OverlayContainerV2Component,
    OverlayDirective,
    OverlayItemDirective,
    OverlayHeaderDirective,
    OverlayIconDirective,
    OverlayTitleDirective,
  ],
  providers: [{ provide: OVERLAY_CONFIG, useValue: DEFAULT_OVERLAY_V2_CONFIG }],
})
export class OverlayContainerV2Module {}
