import { Signal } from '@angular/core';
import { TabsGroupTemplateComponent } from '../components/tabs-group-template/tabs-group-template.component';
import { TabTemplateComponent } from '../components/tab-template/tab-template.component';

export class TabBase {
  isGroup: boolean;

  label: string;

  disabled: boolean;

  tabs?: Signal<readonly TabTemplateComponent[]>;
}
