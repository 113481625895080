import { DatatableSort } from '../components/design-system/data-table-v2/model/data-table-v2.model';
import { ServiceRequestInfoV3SortByOrder } from '../components/design-system/data-table-v2/model/pageable-v2.model';

/**
 * Formats sortBy:orderBy
 * @param sortBy string (column name)
 * @param orderBy string (uppercase)
 * @returns sort by formatted
 */
export function formatSortBy(sortBy: string, orderBy: string): string | null {
  if (!sortBy || !orderBy) return null;

  return `${sortBy}:${orderBy.toUpperCase()}`;
}

/**
 * Builds sort object depending on its persistence.
 * If no sortBy is provided, it will return the default sort.
 * @param sortByOrderBy string
 * @returns DatatableSort
 */
export function upsertSort(
  sortBy: string,
  orderBy: ServiceRequestInfoV3SortByOrder,
  defaultSort: DatatableSort
): DatatableSort {
  if (!sortBy) {
    return defaultSort;
  }

  return {
    [sortBy]: {
      field: sortBy,
      order: orderBy,
    },
  };
}
