export const STRINGS = {
  notifications: {
    sending: $localize`Sending email to supplier...`,
  },
  metadata: {
    dialog: {
      title: {
        send: $localize`Send order details to supplier(s) via email`,
        resend: $localize`Resend order details to supplier(s) via email`,
      },
      actions: {
        send: $localize`Send email`,
        resend: $localize`Resend email`,
      },
    },
    emailManagement: {
      description: $localize`Email templates are prioritized in the following order: Supplier Email Template (highest), User Email Template, General Email Template (lowest). The highest available priority is used by default when placing orders.`,
      toggle: $localize`Send order details to supplier(s) (by email)`,
      templatesTitle: $localize`Email template`,
      recipientsTitle: $localize`Recipients`,
      noIntendedEmail: $localize`Can't find the email you're looking for?`,
      noEmails: $localize`The supplier doesn't have any registered emails.`,
      create: $localize`Create`,
      addNew: $localize`Add new email`,
      emailsDropdown: {
        title: $localize`My supplier emails`,
        reset: $localize`Reset`,
        selectAll: $localize`Select all`,
      },
    },
  },
};
