import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

export class CheckProductPartsV2DataExistence {
  static readonly type = '[Product Parts V2] Check Data Existence';
}

export class InitializeProductPartsV2State {
  static readonly type = '[Product Parts V2] Initialize';
}

export class LoadProductPartsV2 {
  static readonly type = '[Product Parts V2] Load';
}

export class ReloadDatatable {
  static readonly type = '[Product Parts V2] Reload';
}

export class AddSearchParam {
  static readonly type = '[Product Parts V2] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Product Parts V2] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Product Parts V2] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Product Parts V2] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Product Parts V2] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Product Parts V2] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Product Parts V2] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Product Parts V2] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Product Parts V2] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Product Parts V2] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Product Parts V2] Toggle Filter';
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Product Parts V2] Reset Pagination And Load Data';
}
