import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductSuppliersV2DataTableComponent } from '../default/product-suppliers-v2-data-table.component';

@Component({
  selector: 'app-product-suppliers-v2-data-table-view-only',
  templateUrl: './product-suppliers-v2-data-table-view-only.component.html',
  styleUrl: '../default/product-suppliers-v2-data-table.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSuppliersV2DataTableViewOnlyComponent extends ProductSuppliersV2DataTableComponent {}
