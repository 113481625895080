import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  booleanAttribute,
  input,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterHeaderComponent {
  filterName = input(MESSAGES.common.filter.defaultFilterName);

  disableClear = input(true, { transform: booleanAttribute });

  allowRemove = input(false, { transform: booleanAttribute });

  disableRemove = input(true, { transform: booleanAttribute });

  @Output()
  clear: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  remove: EventEmitter<void> = new EventEmitter<void>();

  readonly COMMON_STRINGS = MESSAGES.common;
}
