import { Selector } from '@ngxs/store';
import {
  OrderlineOrderType,
  OrderlineRelatedTable,
  OrderlineStaticInfo,
} from '../model/purchase-overlay.model';
import {
  PurchaseOverlayState,
  PurchaseOverlayStateModel,
} from './purchase-overlay.state';

export class PurchaseOverlayStateQueries {
  @Selector([PurchaseOverlayState])
  static opened(state: PurchaseOverlayStateModel): boolean {
    return state.opened;
  }

  @Selector([PurchaseOverlayState])
  static relatedTable(state: PurchaseOverlayStateModel): OrderlineRelatedTable {
    return state.relatedTable;
  }

  @Selector([PurchaseOverlayState])
  static orderType(state: PurchaseOverlayStateModel): OrderlineOrderType {
    return state.orderType;
  }

  @Selector([PurchaseOverlayState])
  static linkedOrderlineUuid(state: PurchaseOverlayStateModel): string {
    return state.staticInfo.uuid;
  }

  @Selector([PurchaseOverlayState])
  static staticInfo(state: PurchaseOverlayStateModel): OrderlineStaticInfo {
    return state.staticInfo;
  }

  @Selector([PurchaseOverlayState])
  static isPopout(state: PurchaseOverlayStateModel): boolean {
    return state.isPopout;
  }
}
