import { InjectionToken } from '@angular/core';
import { TagComponent } from '../tag.component';

export const TAG_DEFAULTS = new InjectionToken<TagDefaults>('Tag Defaults');

export interface TagEvent {
  tag: TagComponent;
  value: unknown;
}

// Remove after DS migration. Variants should be global to every component
export type TagVariant = 'outline' | 'subtle' | 'solid' | 'white-background';

export type TagVariantColors =
  | 'default'
  | 'blue'
  | 'green'
  | 'orange'
  | 'red'
  | 'yellow';

export enum TagVariantColor {
  DEFAULT = 'default',
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  YELLOW = 'yellow',
}

export interface Tag {
  variant: TagVariant;
  color: TagVariantColor;
  rounded: boolean;
  clickable: boolean;
  disabled: boolean;
  removable: boolean;
}

export interface TagDefaults {
  variant: TagVariant;
  color: TagVariantColor;
  rounded: boolean;
}

export const tagDefaults = {
  variant: 'subtle' as TagVariant,
  color: 'default' as TagVariantColor,
  maxWidth: 200,
};

export interface DynamicTag {
  icon: string;
  secondaryIcon?: string;
  color: TagVariantColor;
  tooltip?: string;
  text: string;
  variant?: TagVariant;
  rounded?: boolean;
}

export interface DynamicTags {
  iconTags: Omit<DynamicTag, 'text'>[];
  textTags: Omit<DynamicTag, 'icon' | 'secondaryIcon'>[];
  iconTextTags: DynamicTag[];
}

export interface TagsBuilder<T> {
  /**
   * Builds tags
   * @param base T
   * @returns tags Tags
   */
  buildTags: (base: T) => DynamicTags;
}
