import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';

@Component({
  selector: 'app-skeleton-rect',
  templateUrl: './skeleton-rect.component.html',
  styleUrl: './skeleton-rect.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonRectComponent implements OnInit {
  @Input()
  width: string;

  @Input()
  height: string;

  @Input()
  customClass: string;

  elementRef = inject(ElementRef);

  private readonly defaultWidth: string = '100%';

  private readonly defaultHeight: string = '14px';

  ngOnInit(): void {
    const host = this.elementRef.nativeElement;

    if (this.customClass) {
      host.classList.add(this.customClass);
    }

    host.style.setProperty(
      '--skeleton-rect-width',
      this.width ?? this.defaultWidth
    );
    host.style.setProperty(
      '--skeleton-rect-height',
      this.height ?? this.defaultHeight
    );
  }
}
