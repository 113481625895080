import { Directive, EventEmitter, Input, Output, signal } from '@angular/core';

@Directive()
export class FilterButtonBase {
  /**
   * Overlay state sent from parent component.
   */
  @Input()
  overlayOpened: boolean;

  @Output()
  toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Button toggle state. Can be used without an overlay, that's why the button has its own opened state.
   */
  opened = signal(false);

  /**
   * Button toggle state. Can be used without an overlay, that's why the button has its own removing state.
   */
  removing = signal(false);

  toggle(event: Event): void {
    event.stopPropagation();

    this.opened.update((opened: boolean) => !opened);

    this.toggled.emit(this.opened());
  }
}
