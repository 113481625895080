import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonSize, ButtonVariant } from './model/button.model';

@Component({
  selector: 'app-button, button[appButton]',
  template: `<ng-content></ng-content>`,
  styleUrl: './button.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnChanges {
  @HostBinding('class')
  class: string;

  @Input()
  size: ButtonSize = 'md';

  @Input({ required: true })
  variant: ButtonVariant = 'primary';

  ngOnChanges(): void {
    this._applyClass();
  }

  private _applyClass(): void {
    this.class = `button-base button-${this.size} button-${this.variant}`;
  }
}
