import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';

@Component({
  selector: 'app-filters-datatable-container',
  templateUrl: './filters-datatable-container.component.html',
  styleUrl: './filters-datatable-container.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FiltersDatatableContainerComponent {
  @Input({ required: true, transform: booleanAttribute })
  expanded: boolean;
}
