import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TextOnlyToast, TextOnlyToastData } from '../../model/toast.model';
import { ToastRef } from '../../core/toast-ref';
import { TOAST_DATA } from '../../core/toast-config';

@Component({
  selector: 'app-simple-toast',
  templateUrl: './simple-toast.component.html',
  styleUrls: ['./simple-toast.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleToastComponent implements TextOnlyToast {
  constructor(
    public toastRef: ToastRef<SimpleToastComponent>,
    @Inject(TOAST_DATA) public data: TextOnlyToastData
  ) {}

  action(): void {
    this.toastRef.dismissWithAction();
  }

  get hasAction(): boolean {
    return !!this.data.action;
  }
}
