<app-dialog-container
  ><div>
    <div appDialogHeader>
      <h1 appDialogTitle i18n="add supplier dialog title">Add supplier</h1>
      <button
        aria-label="Add product supplier dialog close button"
        (click)="close()"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>

    <div appDialogBody>
      <form #addSupplierForm="ngForm">
        <h3
          class="supplier-products-container-title"
          i18n="add supplier-products dialog form supplier-product details">
          Supplier information
        </h3>

        <div class="supplier-product-name input-field">
          <label
            for="supplierProductName"
            i18n="add supplier-products dialog form supplier-product name label"
            >Select a supplier</label
          >

          <div class="fields-autocomplete-container">
            <app-search
              variant="filled"
              [searchBy]="searchBy$ | async"
              (searched)="searchBy($event)"
              placeholder="Select a supplier..."
              i18n-placeholder="add dialog select supplier placeholder"
              (optionSelected)="updateSelectedSupplier($event)"
              [autocompleteList]="
                filteredAvailableSuppliers$ | async
              "></app-search>
          </div>
        </div>

        <div class="divider"></div>

        <h3
          class="supplier-products-container-title"
          i18n="add supplier-products dialog form supplier-product details">
          Product Details
        </h3>

        <div class="dates-container">
          <div class="start-date small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product start date
                label
              "
              >SKU</label
            >
            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                id="sku"
                type="text"
                name="sku"
                [ngModel]="sku$ | async"
                (ngModelChange)="updateSku($event)"
                [placeholder]="COMMON_STRINGS.enterText"
                [disabled]="invalidSupplier$ | async" />
            </div>
          </div>

          <div class="ean-code small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product start date
                label
              "
              >EAN</label
            >

            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                id="eanCode"
                type="text"
                [ngModel]="eanCode$ | async"
                (ngModelChange)="updateEan($event)"
                name="eanCode"
                [placeholder]="COMMON_STRINGS.enterText"
                [disabled]="invalidSupplier$ | async" />
            </div>
          </div>
        </div>

        <div class="dates-container">
          <div class="start-date small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product lot size
                label
              "
              >Lot size</label
            >
            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                id="purchaseInQuantitiesOf"
                type="number"
                name="purchaseInQuantitiesOf"
                [ngModel]="purchaseInQuantitiesOf$ | async"
                (ngModelChange)="updateLotSize($event)"
                placeholder="-"
                min="1"
                step="1"
                [disabled]="invalidSupplier$ | async"
                required />
            </div>
          </div>

          <div class="ean-code small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product Minimum order
                quantity label
              "
              >Minimum order quantity</label
            >

            <div class="fields-input-container">
              <input
                appInput
                variant="filled"
                id="minimumPurchaseQuantity"
                type="number"
                [ngModel]="minimumPurchaseQuantity$ | async"
                (ngModelChange)="updateMoq($event)"
                name="minimumPurchaseQuantity"
                placeholder="-"
                min="1"
                step="1"
                [disabled]="invalidSupplier$ | async"
                required />
            </div>
          </div>

          <div class="ean-code small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product Product lead
                time label
              "
              >Product lead time
            </label>

            <div class="fields-input-container">
              <div appInputWrapper>
                <span appInputSuffix>{{ COMMON_STRINGS.daySymbol }}</span>
                <input
                  appInput
                  variant="filled"
                  id="deliveryTime"
                  type="number"
                  [ngModel]="deliveryTime$ | async"
                  (ngModelChange)="updateDeliveryTime($event)"
                  name="deliveryTime"
                  placeholder="-"
                  min="1"
                  step="1"
                  [disabled]="invalidSupplier$ | async" />
              </div>
            </div>
          </div>

          <div class="ean-code small-input-field input-field">
            <label
              for="startDate"
              i18n="
                add supplier-products dialog form supplier-product Purchase
                price label
              "
              >Purchase price</label
            >

            <div class="fields-input-container">
              <div appInputWrapper>
                <span appInputSuffix>{{ currencySymbol$ | async }}</span>
                <input
                  appInput
                  variant="filled"
                  id="price"
                  type="number"
                  [ngModel]="price$ | async"
                  (ngModelChange)="updatePrice($event)"
                  name="price"
                  placeholder="-"
                  min="0"
                  step="0.01"
                  [disabled]="invalidSupplier$ | async"
                  required />
              </div>
            </div>
          </div>
        </div>

        <div class="divider"></div>

        <div>
          <h3
            class="supplier-products-container-title"
            i18n="
              add supplier-products dialog form supplier-product containers
            ">
            Containers
          </h3>

          <div class="dates-container">
            <div class="start-date small-input-field input-field">
              <label
                for="startDate"
                i18n="
                  add supplier-products dialog form supplier-product weight
                  label
                "
                >Weight (g)</label
              >
              <div class="fields-input-container">
                <div appInputWrapper>
                  <span appInputSuffix>g</span>
                  <input
                    appInput
                    variant="filled"
                    id="weight"
                    type="number"
                    [ngModel]="weight$ | async"
                    (ngModelChange)="updateWeight($event)"
                    name="weight"
                    placeholder="-"
                    step="0.01"
                    min="0.01"
                    [disabled]="invalidSupplier$ | async" />
                </div>
              </div>
            </div>

            <div class="free-stock small-input-field input-field">
              <label
                for="startDate"
                i18n="
                  add supplier-products dialog form supplier-product volume
                  label
                "
                >Volume (cm³)</label
              >

              <div class="fields-input-container">
                <div appInputWrapper>
                  <span appInputSuffix>cm³</span>
                  <input
                    appInput
                    variant="filled"
                    id="volume"
                    type="number"
                    [ngModel]="volume$ | async"
                    (ngModelChange)="updateVolume($event)"
                    name="volume"
                    placeholder="-"
                    step="0.01"
                    min="0.01"
                    [disabled]="invalidSupplier$ | async" />
                </div>
              </div>
            </div>
          </div>

          <div class="divider"></div>

          <h3
            class="supplier-products-container-title"
            i18n="add supplier-products dialog form settings">
            Options
          </h3>

          <div class="options-container">
            <div class="availability-checkbox-container">
              <div class="enabled">
                <mat-icon svgIcon="check" class="icon success"></mat-icon>
                <span class="description">
                  <span class="title" i18n="add dialog enabled label"
                    >Available</span
                  >
                  <span
                    class="subtitle"
                    i18n="
                      add product suppliers dialog preferred description
                      subtitle
                    "
                    >Mark as an available supplier</span
                  >
                </span>
              </div>
              <app-toggle
                name="available"
                [ngModel]="availability$ | async"
                (ngModelChange)="updateAvailability($event)"
                [disabled]="invalidSupplier$ | async">
              </app-toggle>
            </div>

            <div
              *ngIf="(availability$ | async) === false"
              class="availabilityDate-group description">
              <label
                for="upliftIncrease"
                i18n="add dialog Available Date"
                class="title"
                >Available Date
              </label>
              <div class="fields-input-container">
                <div class="optiply-filled-input">
                  <div class="availabilityDate-container">
                    <div class="fields-date-container">
                      <div
                        class="optiply-filled-datepicker"
                        (click)="picker.open()">
                        <input
                          [matDatepicker]="picker"
                          name="availabilityDate"
                          [min]="picker.opened ? minAvailabilityDate : null"
                          [ngModel]="availabilityDate$ | async"
                          (dateChange)="
                            updateAvailabilityDate($event.target.value)
                          "
                          placeholder="Choose the date"
                          i18n-placeholder="
                            multi supplier products data table availability date
                            column picker placeholder
                          " />
                        <mat-datepicker-toggle matSuffix [for]="picker">
                          <mat-icon
                            matDatepickerToggleIcon
                            svgIcon="calendar"
                            class="datepicker-icon"></mat-icon
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker> </mat-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="preferred-checkbox-container">
              <div class="enabled">
                <mat-icon
                  svgIcon="verified-badge"
                  class="icon primary"></mat-icon>

                <span class="description">
                  <span class="title" i18n="add dialog enabled label"
                    >Preferred</span
                  >
                  <span
                    class="subtitle"
                    i18n="
                      add product suppliers dialog preferred description
                      subtitle
                    "
                    >Mark as the preferred supplier</span
                  >
                </span>
              </div>
              <app-toggle
                name="preferred"
                [ngModel]="preferred$ | async"
                (ngModelChange)="updatePreferred($event)"
                [disabled]="invalidSupplier$ | async">
              </app-toggle>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div appDialogActions>
      <div class="action-buttons">
        <div class="action-add-another">
          <app-checkbox [(checked)]="addAnother"></app-checkbox>
          <span class="action-add-another-text">{{
            COMMON_STRINGS.addAnother
          }}</span>
        </div>

        <div class="action-buttons-container">
          <button
            aria-label="Add product supplier dialog cancel button"
            appButton
            variant="tertiary"
            i18n="add dialog footer cancel"
            (click)="close()">
            Cancel
          </button>

          <button
            appButton
            variant="primary"
            [disabled]="
              (isSaving$ | async) ||
              (invalidSupplier$ | async) ||
              addSupplierForm.invalid
            "
            (click)="saveAddedSupplier()">
            {{ COMMON_STRINGS.save }}
            <app-progress-spinner
              *ngIf="isSaving$ | async"></app-progress-spinner>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-dialog-container>
