<app-datatable-top-bar [expanded]="filterOpened()">
  <ng-container leftSide>
    <app-filters-toggle
      (toggled)="toggleFilter()"
      [filtersCount]="activeFilters()?.length"></app-filters-toggle>

    <app-data-table-search
      [searchParams]="searchBy()"
      (addedParam)="addSearchParam($event)"
      (removedParam)="removeSearchParam($event)" />

    <app-clear-all-filters-button
      [disabled]="activeFilters()?.length === 0 && searchBy()?.length === 0"
      (cleared)="clearAllFilters()"></app-clear-all-filters-button>
  </ng-container>

  <ng-container rightSide>
    <app-data-table-refresh-button
      data-intercom-target="Refresh table data button"
      aria-label="Refresh table data button"
      [loading]="loading()"
      [disabled]="loading() || !canRefresh()"
      (refreshed)="refreshDatatable()" />

    <div class="vertical-separator"></div>

    <app-column-view-v3
      [columns]="selectableColumns()"
      [columnsOrder]="COLUMNS_ORDER"
      (columnsSelected)="onColumnsSelected($event)" />
  </ng-container>
</app-datatable-top-bar>

<app-filters-datatable-container [expanded]="filterOpened()">
  <app-filter-group [filters]="activeFilters()" (filtered)="onFiltered($event)">
    <app-add-filter-button
      [filters]="filters()"
      (added)="onFiltered($event)"></app-add-filter-button>
  </app-filter-group>
</app-filters-datatable-container>

<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    [disableHideColumn]="lastDisplayedColumn()"
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)"
    (filtered)="onFiltered($event)">
    <!-- Name -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.NAME.key">
      <th
        data-intercom-target="b0e014c4-cb32-4b4f-aea3-8a43931b6176"
        aria-label="Product details parts table Name column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.NAME.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.NAME.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <span
          (click)="goToProductDetails(element.partProductUuid)"
          class="deep-linking-text">
          <app-copy-to-clipboard
            [message]="element.name"></app-copy-to-clipboard>
        </span>
      </td>
    </ng-container>

    <!-- SKU -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.SKU.key">
      <th
        data-intercom-target="33906f96-d5d6-4a0e-b122-b66881659a3a"
        aria-label="Product details parts table SKU column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.SKU.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.SKU.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-copy-to-clipboard [message]="element.sku"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- EAN code -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.EAN_CODE.key">
      <th
        data-intercom-target="dcdb82dc-a81e-4c77-b305-70afff3169a0"
        aria-label="Product details parts table EAN Code column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.EAN_CODE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.EAN_CODE.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-copy-to-clipboard
          [message]="element.eanCode"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Article code -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.ARTICLE_CODE.key">
      <th
        data-intercom-target="0aa7cedd-d3e1-4a20-9372-a704ffdd1b3f"
        aria-label="Product details parts table Article Code column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.ARTICLE_CODE.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.ARTICLE_CODE.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <app-copy-to-clipboard
          [message]="element.articleCode"></app-copy-to-clipboard>
      </td>
    </ng-container>

    <!-- Parts quantity -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.PART_QUANTITY.key">
      <th
        data-intercom-target="a9e4cad5-3e50-41ed-becb-e2ed08c78834"
        aria-label="Product details parts table Parts Quantity column"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.PART_QUANTITY.key | filterColumn: RELATED_STATE | async
        "
        class="align-right">
        {{ TABLE_COLUMNS.PART_QUANTITY.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="align-right">
        <ng-container *ngIf="element.partQuantity !== null; else notAvailable">
          {{ element.partQuantity | customDecimal }}
        </ng-container>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      data-intercom-target="b19a767e-44c0-41c8-8130-1f30276d68c0"
      aria-label="Product details parts table row"
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
