import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ContentChild,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-settings-section',
  standalone: true,
  templateUrl: './settings-section.component.html',
  styleUrls: ['./settings-section.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class SettingsSectionComponent {
  @ContentChild('customTitle')
  customTitle: TemplateRef<any>;

  @Input()
  settingsTitle: string;

  @Input()
  settingsDescription: string;
}
