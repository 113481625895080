import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  booleanAttribute,
  computed,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { OverlayDirective } from 'src/app/shared/components/design-system/overlay-container-v2/directives/overlay.directive';
import { ColumnFilterParam } from '../../../../model/data-table-v2.model';

import {
  ColumnFilter,
  ColumnFilterGroup,
  FilterTypeOptions,
  FilterTypes,
} from '../../model/filter.model';
import { FILTER_SHELL, FilterShell } from '../../model/filter-v2.model';

@Component({
  selector: 'app-add-filter-button',
  templateUrl: './add-filter-button.component.html',
  styleUrls: ['./add-filter-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FILTER_SHELL, useExisting: AddFilterButtonComponent }],
})
export class AddFilterButtonComponent implements FilterShell {
  filters = input.required<ColumnFilterGroup[]>();

  disabled = input(false, {
    transform: booleanAttribute,
  });

  @Output()
  added: EventEmitter<ColumnFilterParam> =
    new EventEmitter<ColumnFilterParam>();

  filter = signal<ColumnFilter | null>(null);

  filterUiModel = computed(() => this.filter()?.uiModel || null);

  readonly FILTER_TYPES = FilterTypes;

  readonly FILTER_TYPE_OPTIONS = FilterTypeOptions;

  readonly COMMON_STRINGS = MESSAGES.common;

  private _overlay = viewChild.required(OverlayDirective);

  clear(): void {
    this.filter.set(null);
  }

  cancel(): void {
    this.clear();
    this._overlay()._closeOverlay();
  }

  apply(params: ColumnFilterParam): void {
    this.added.emit(params);

    this._overlay()._closeOverlay();
    this.clear();
  }
}
