<div class="container">
  <app-dropdown
    [placeholder]="COMMON_STRINGS.filter.defaultFiltersPlaceholder"
    [value]="selectedOption()"
    [displayWith]="displayWithKey"
    (selectionChange)="optionSelected.emit($event)">
    @for (option of options(); track option.key) {
      <app-dropdown-option [value]="option.value">
        {{ option.key }}
      </app-dropdown-option>
    }
  </app-dropdown>
</div>
