import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import {
  WebshopUpdateAdvancedCategorizationProperties,
  WebshopUpdateProperties,
  WebshopUpdateSettingsProperties,
  Webshop,
} from 'src/app/shared/models/webshop/webshop.model';

import { Webshops } from 'src/app/shared/models/webshop/webshops.model';

import { Webshops as WebshopsV1 } from './model/webshops.model';
import { WebshopResponse } from './model/webshop.model';
import { WebshopsAdapter } from './webshops.adapter';
import { WebshopAdapter } from './webshop.adapter';

@Injectable({ providedIn: 'root' })
export class WebshopsService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private fetch(): Observable<WebshopsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'webshopGRPC',
      'v1',
      'findAll',
      []
    );

    return this.http.get<WebshopsV1>(endpoint);
  }

  private update(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'webshop',
      'v1',
      'update',
      replacements
    );
    return this.http.post<any>(endpoint, payload);
  }

  private updateSettings(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'webshop',
      'v1',
      'updateSettings',
      replacements
    );
    return this.http.post<any>(endpoint, payload);
  }

  private _updateAdvancedCategorization(
    payload: Partial<WebshopUpdateAdvancedCategorizationProperties>,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'webshopGRPC',
      'v1',
      'updateAdvancedCategorization',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private fetchOne(
    replacements: UrlReplacement[]
  ): Observable<WebshopResponse> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'webshopGRPC',
      'v1',
      'findOne',
      replacements
    );

    return this.http.get<WebshopResponse>(endpoint);
  }

  fetchWebshops(): Observable<Webshops> {
    return this.fetch().pipe(
      catchError(() => {
        return of({ webshops: [] });
      }),
      switchMap(data => {
        return of(new WebshopsAdapter().transform(data));
      })
    );
  }

  updateWebshop(webshopUUID: string, properties: WebshopUpdateProperties) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this.update(properties, replacements);
  }

  updateWebshopSettings(
    webshopUUID: string,
    properties: WebshopUpdateSettingsProperties
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopId', value: webshopUUID },
    ];

    return this.updateSettings(properties, replacements);
  }

  updateWebshopAdvancedCategorization(
    webshopUuid: string,
    properties: Partial<WebshopUpdateAdvancedCategorizationProperties>
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._updateAdvancedCategorization(properties, replacements);
  }

  fetchWebshop(webshopUuid: string): Observable<Webshop> {
    const replacements: UrlReplacement[] = [
      { name: 'uuid', value: webshopUuid },
    ];

    return this.fetchOne(replacements).pipe(
      switchMap(data => {
        return of(new WebshopAdapter().transform(data));
      })
    );
  }
}
