import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { DropdownOptionComponent } from './components/dropdown-option/dropdown-option.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { DropdownOptionGroupComponent } from './components/dropdown-option-group/dropdown-option-group.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DropdownTriggerDirective } from './directives/dropdown-trigger.directive';
import { DropdownGroupLabelDirective } from './directives/dropdown-group-label.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from '../tooltip/tooltip.module';
import { MatIconModule } from '@angular/material/icon';
import { TagsModule } from '../tags/tags.module';
import { DropdownOptionSearchPipe } from './pipes/dropdown-option-search.pipe';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DropdownSearchableOptionDirective } from './directives/dropdown-option-searched.directive';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [
    DropdownComponent,
    DropdownOptionComponent,
    DropdownOptionGroupComponent,
    DropdownTriggerDirective,
    DropdownGroupLabelDirective,
    DropdownOptionSearchPipe,
    DropdownSearchComponent,
    DropdownSearchableOptionDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    OverlayModule,
    MatTooltipModule,
    TooltipModule,
    MatIconModule,
    TagsModule,
    MatInputModule,
    InputModule,
  ],
  exports: [
    DropdownComponent,
    DropdownOptionComponent,
    DropdownOptionGroupComponent,
    DropdownGroupLabelDirective,
    DropdownOptionSearchPipe,
    DropdownSearchComponent,
    DropdownSearchableOptionDirective,
    DropdownTriggerDirective,
  ],
})
export class DropdownModule {}
