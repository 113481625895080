<div
  #dropdownInput
  role="combobox"
  cdk-overlay-origin
  #fallbackOverlayOrigin="cdkOverlayOrigin"
  (click)="toggle()"
  [ngClass]="dropdownContainerClass"
  [class.dropdown-opened]="panelOpened"
  [class.dropdown-focused]="panelOpened"
  [class.dropdown-disabled]="disabled"
  [class.dropdown-multiple]="multiple"
  [class.dropdown-active]="!empty || hasValue">
  <ng-container [ngSwitch]="empty">
    <span
      class="dropdown-placeholder"
      [class.dropdown-preview]="hasValue && multiple"
      *ngSwitchCase="true">
      <ng-container *ngIf="!hasValue">{{ placeholder }}</ng-container>

      <ng-container *ngIf="hasValue">
        <ng-container *ngIf="!multiple">{{ preDefinedValue[0] }}</ng-container>
      </ng-container>
    </span>

    <span
      *ngSwitchCase="false"
      [ngSwitch]="!!customTrigger"
      class="dropdown-trigger-container"
      [appTooltip]="displayedOption"
      tooltipShouldCheckOverflow>
      <span
        *ngSwitchDefault
        class="dropdown-preview"
        [class.dropdown-preview-multiple]="multiple">
        <ng-container *ngIf="multiple; else simpleValuesPreview">
          <!-- Tags to show multiple values -->
          <ng-container *ngFor="let option of displayedOption">
            <app-tag [value]="option" color="blue">{{ option }}</app-tag>
          </ng-container>
        </ng-container>

        <!-- Simple text -->
        <ng-template #simpleValuesPreview>{{ displayedOption }}</ng-template>
      </span>

      <ng-content
        *ngSwitchCase="true"
        select="[appDropdownTrigger], app-dropdown-trigger"></ng-content>
    </span>
  </ng-container>

  <div class="dropdown-icon">
    <mat-icon svgIcon="angle-down-xs"></mat-icon>
  </div>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="fallbackOverlayOrigin"
  [cdkConnectedOverlayOpen]="panelOpened"
  [cdkConnectedOverlayWidth]="
    panelWidth !== 'auto' ? panelWidth : dropdownInput.offsetWidth
  "
  [cdkConnectedOverlayMinWidth]="panelWidth !== 'auto' ? panelWidth : 200"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayPositions]="positions"
  (backdropClick)="close()"
  (detach)="close()">
  <div
    role="listbox"
    tabindex="-1"
    class="dropdown-container dropdown-list-container">
    <span *ngIf="!!search" class="dropdown-search-container">
      <ng-content select="app-dropdown-search"></ng-content>
    </span>

    <ng-container *ngIf="!loading">
      <ng-content></ng-content>
    </ng-container>

    <div *ngIf="!options?.length || loading" class="dropdown-footer">
      <span>{{ searchableMessage }}</span>
    </div>
  </div>
</ng-template>
