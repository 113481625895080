export interface Coupling {
  id: number;
  uuid: string;
  importEnabled: boolean;
  currentlyImporting: boolean;
  syncingSuppliers: boolean;
  syncingWebshopProducts: boolean;
  syncingSupplierProducts: boolean;
  syncingBuyOrders: boolean;
  syncingSellOrders: boolean;
  syncingItemDeliveries: boolean;
  syncingStocks: boolean;
  disconnected: boolean;
  recordsEventsAfter: string | null;
  couplingType: number;
  name: string;
}

export const COUPLING_TYPE_PICQER = 0;
export const COUPLING_TYPE_LIGHTSPEED = 1;
export const COUPLING_TYPE_FIXED_FORMAT_CSV = 2;
export const COUPLING_TYPE_DOVETAIL = 3;
