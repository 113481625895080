import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import { SaveConceptProperties } from '../../proposed-orderlines/model/proposed-orderlines.model';
import {
  ColumnFilterParam,
  DatatableColumnV2,
  DatatableColumnV2Groups,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

export class InitializeConceptLinesTable {
  static readonly type = '[Concept Lines] Initialize Concept Lines V2';

  constructor(public conceptUuid: string) {}
}

export class LoadConceptLinesV2 {
  static readonly type = '[Concept Lines] Load Concept Lines V2';

  constructor(public conceptUuid: string) {}
}

export class ReloadDatatable {
  static readonly type = '[Concept Lines] Reload';
}

export class LoadConceptLinesOverviewV2 {
  static readonly type = '[Concept Lines] Load Concept Lines Overview';

  constructor(public conceptUuid: string) {}
}

export class HeaderInfoConceptPersistence {
  static readonly type = '[Concept Lines] Header Info OpenClose';

  constructor(public openClose: string[]) {}
}

export class GenerateSession {
  static readonly type = '[Concept Lines] Generate Session';

  constructor(
    public conceptUuid: string,
    public supplierUuid: string,
    public orderMomentUuid: string | null
  ) {}
}

export class PersistSession {
  static readonly type = '[Concept Lines] Persist Session';

  constructor(public sessionUuid: string) {}
}

export class AddSearchParam {
  static readonly type = '[Concept Lines] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Concept Lines] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Concept Lines] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Concept Lines] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Concept Lines] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Concept Lines] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Concept Lines] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Concept Lines] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Concept Lines] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class SetColumnSelection {
  static readonly type = '[Concept Lines] Set Column Selection';

  constructor(
    public columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>,
    public applyAll: boolean
  ) {}
}

export class PatchLinePrice {
  static readonly type = '[Concept Lines] Patch Line Price';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedPrice: string
  ) {}
}

export class PatchLineQuantity {
  static readonly type = '[Concept Lines] Patch Line Quantity';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedQuantity: string
  ) {}
}

export class PatchLineWeight {
  static readonly type = '[Concept Lines] Patch Line Weight';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedWeight: string
  ) {}
}

export class PatchLineVolume {
  static readonly type = '[Concept Lines] Patch Line Volume';

  constructor(
    public orderline: ProposedBuyOrderline,
    public changedVolume: string
  ) {}
}

export class RemoveProductFromOrder {
  static readonly type = '[Concept Lines] Remove Product';

  constructor(public supplierProductUuid: string) {}
}

export class RemoveProductsFromOrder {
  static readonly type = '[Concept Lines] Remove Products';
}

export class OrderLineChanged {
  static readonly type = '[Buy Order Concept Line Updated] order line updated';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderlineErrored {
  static readonly type = '[Buy Order Concept Line Error] order line error';

  constructor(
    public errorMessage: string,
    public supplierProductUuid: string
  ) {}
}

export class OrderLineRemoved {
  static readonly type = '[Buy Order Concept Line Removed] order line removed';

  constructor(public orderLine: ProposedBuyOrderline) {}
}

export class OrderLinesRemoved {
  static readonly type =
    '[Buy Order Concept Lines Bulk Removed] order lines removed';
}

export class InitializePurchase {
  static readonly type = '[Concept Lines] Initialize Purchase';

  constructor(
    public conceptUuid: string,
    public supplierUuid: string,
    public orderMomentUuid: string | null
  ) {}
}

export class HideColumn {
  static readonly type = '[Concept Lines] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Concept Lines] Toggle Filter';
}

export class TriggerPurchaseConfirmation {
  static readonly type = '[Concept Lines] Trigger Purchase Confirmation';
}

export class ConfirmOrder {
  static readonly type = '[Concept Lines] Confirm Order';
}

export class PlaceOrder {
  static readonly type = '[Concept Lines] Place Order';
}

export class PlaceAndPlanOrder {
  static readonly type = '[Concept Lines] Place and plan order';
}

export class PlaceManual {
  static readonly type = '[Concept Lines] Place manual order';
}

export class CreateConcept {
  static readonly type = '[Concept Lines] Create Concept';

  constructor(public properties: SaveConceptProperties) {}
}

export class UpdateConcept {
  static readonly type = '[Concept Lines] Update Concept';

  constructor(public properties: SaveConceptProperties) {}
}

export class ReloadBothTables {
  static readonly type = '[Concept Lines] Reload Editor Both Tables';

  constructor(
    public payload: { showLoading: boolean } = { showLoading: false }
  ) {}
}

export class ResetBuyOrder {
  static readonly type = '[Concept Lines] ResetBuyOrder';
}

export class ToggleRowSelection {
  static readonly type = '[Concept Lines] Toggle Row Selection';

  constructor(
    public rowKey: string,
    public rowQuantity: number,
    public isSelected: boolean
  ) {}
}

export class ClearSelection {
  static readonly type = '[Concept Lines] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type = '[Concept Lines] Master Toggle Selection';
}

export class RefreshConceptLinesPage {
  static readonly type = '[Concept Lines] Refresh Concept Order Page';
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Concept Lines] Reset Pagination And Load Data';
}
