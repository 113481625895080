import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  booleanAttribute,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RADIO_GROUP, RadioChange } from '../model/radio-button-v2.model';
import { RadioButtonV2Component } from '../radio-button-v2.component';

let uniqueId = 0;

@Directive({
  selector: '[appRadioButtonGroupV2], app-radio-button-group-v2',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupV2Directive),
      multi: true,
    },
    {
      provide: RADIO_GROUP,
      useExisting: RadioButtonGroupV2Directive,
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'radiogroup',
  },
})
export class RadioButtonGroupV2Directive
  implements ControlValueAccessor, AfterContentInit
{
  @ContentChildren(forwardRef(() => RadioButtonV2Component), {
    descendants: true,
  })
  radios: QueryList<RadioButtonV2Component>;

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    this._updateRadioButtonNames();
  }
  private _name: string = `radio-group-${++uniqueId}`;

  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (this._value !== newValue) {
      this._value = newValue;

      this._updateSelectedRadioFromValue();
      this.checkSelectedRadioButton();
    }
  }
  private _value: any = null;

  @Input()
  get selected() {
    return this._selected;
  }
  set selected(selected: RadioButtonV2Component | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this.checkSelectedRadioButton();
  }
  private _selected: RadioButtonV2Component | null = null;

  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    this.markRadiosForCheck();
  }
  private _disabled: boolean = false;

  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = value;
    this.markRadiosForCheck();
  }
  private _required: boolean = false;

  @Output()
  readonly changed: EventEmitter<RadioChange> = new EventEmitter<RadioChange>();

  private _isInitialized: boolean = false;

  controlValueAccessorChangeFn: (value: any) => void = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this._isInitialized = true;
  }

  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  checkSelectedRadioButton() {
    if (this._selected && !this._selected?.checked) {
      this._selected.checked = true;
    }
  }

  markRadiosForCheck() {
    if (this.radios) {
      this.radios.forEach(radio => radio.markForCheck());
    }
  }

  emitChangeEvent(): void {
    if (this._isInitialized) {
      this.changed.emit(new RadioChange(this._selected, this._value));
    }
  }

  private _updateRadioButtonNames(): void {
    if (this.radios) {
      this.radios.forEach(radio => {
        radio.name = this.name;
        radio.markForCheck();
      });
    }
  }

  private _updateSelectedRadioFromValue(): void {
    const isAlreadySelected =
      this._selected !== null && this._selected.value === this._value;

    if (this.radios && !isAlreadySelected) {
      this._selected = null;
      this.radios.forEach(radio => {
        radio.checked = this.value === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }
}
