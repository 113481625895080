import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
  booleanAttribute,
  forwardRef,
} from '@angular/core';
import {
  DROPDOWN_CONFIG,
  DROPDOWN_PARENT,
  DROPDOWN_SEARCH,
  DROPDOWN_TRIGGER,
  DropDownConfig,
  DropdownOptionSelected,
  DropdownOptionSelectedSource,
} from './model/dropdown.model';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownOptionComponent } from './components/dropdown-option/dropdown-option.component';
import {
  ConnectedPosition,
  Overlay,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { SelectionModel } from '@angular/cdk/collections';
import { STRINGS } from './model/dropdown.strings';
import { DropdownTriggerDirective } from './directives/dropdown-trigger.directive';
import {
  Observable,
  Subject,
  defer,
  merge,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';

@Component({
  selector: 'app-dropdown',
  exportAs: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DROPDOWN_PARENT, useExisting: DropdownComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent
  implements ControlValueAccessor, OnInit, AfterContentInit, OnDestroy
{
  @ViewChild('dropdownInput', { static: true })
  dropdownInput: ElementRef;

  @ContentChild(DROPDOWN_TRIGGER)
  customTrigger: DropdownTriggerDirective;

  @ContentChild(DROPDOWN_SEARCH)
  search: DropdownSearchComponent;

  @ContentChildren(DropdownOptionComponent, { descendants: true })
  options: QueryList<DropdownOptionComponent>;

  @Input({ transform: booleanAttribute })
  multiple: boolean;

  @Input()
  type: 'filled' | 'outline' = 'filled';

  @Input({ transform: booleanAttribute })
  disabled: boolean;

  @Input({ transform: booleanAttribute })
  loading: boolean;

  @Input()
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    this._compareWith = fn;
  }
  get compareWith() {
    return this._compareWith;
  }

  @Input()
  set value(newValue: any) {
    const hasChanged = this._handleNewValue(newValue);

    if (hasChanged) {
      this.onChange(newValue);
      this.valueChange.emit(newValue);
    }
  }
  get value(): any {
    return this._value;
  }

  @Input()
  placeholder: string = STRINGS.placeholder;

  @Input()
  set panelWidth(value: string | number) {
    if (value) {
      this._panelWidth = coerceNumberProperty(value);
      return;
    }

    this._panelWidth = this._getDefaultPanelWidth();
  }
  get panelWidth(): string | number | null {
    return this._panelWidth;
  }

  /**
   * How the pre-defined value will be displayed.
   *
   * Useful when there aren't provided options and still want to present the values.
   */
  @Input({ transform: booleanAttribute })
  forceOptionsReset: boolean = false;

  @Input()
  displayWith: ((value: any) => string) | null = null;

  @Input()
  searchMessage: string = STRINGS.searchMessage;

  @Input()
  noResultsMessage: string = STRINGS.noResultsMessage;

  @Input()
  loadingMessage: string = STRINGS.searchingResults;

  @Output()
  selectionChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  scrollStrategy: ScrollStrategy;

  selectionModel: SelectionModel<DropdownOptionSelectedSource>;

  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      panelClass: 'dropdown-container-below',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      panelClass: 'dropdown-container-below',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      panelClass: 'dropdown-container-above',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      panelClass: 'dropdown-container-above',
    },
  ];

  readonly optionSelectionChanges$: Observable<DropdownOptionSelected> = defer(
    () => {
      const options = this.options;

      if (options) {
        return options.changes.pipe(
          startWith(options),
          switchMap(() =>
            merge(...options.map(option => option.selectionChange))
          )
        );
      }

      return this.ngZone.onStable.pipe(
        take(1),
        switchMap(() => this.optionSelectionChanges$)
      );
    }
  );

  private _value: any;

  private _panelOpened: boolean = false;

  private _compareWith = (o1: any, o2: any) =>
    JSON.stringify(o1) === JSON.stringify(o2);

  private _panelWidth: string | number | null = this._getDefaultPanelWidth();

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private overlay: Overlay,
    private ngZone: NgZone,
    @Optional() @Inject(DROPDOWN_CONFIG) private defaultConfig?: DropDownConfig
  ) {
    this.scrollStrategy = this.overlay.scrollStrategies.reposition();
  }

  ngOnInit(): void {
    this.selectionModel = new SelectionModel<DropdownOptionSelectedSource>(
      this.multiple,
      [],
      true,
      (o1: DropdownOptionSelectedSource, o2: DropdownOptionSelectedSource) =>
        JSON.stringify(o1.value) === JSON.stringify(o2.value)
    );
  }

  ngAfterContentInit(): void {
    this.options.changes
      .pipe(startWith(null), takeUntil(this._destroy$))
      .subscribe(() => {
        this._resetOptions();
        this._setSelectionByValue(this.value);
      });
  }

  toggle(): void {
    this._panelOpened ? this.close() : this.open();
  }

  open() {
    if (!this._canOpenPanel()) return;

    this._panelOpened = true;

    this._focusSearch();
  }

  close() {
    this._panelOpened = false;

    this.onTouched();

    this.search?.clearSearch();
  }

  onChange: (value: any) => void = () => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this._handleNewValue(value);
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    this.cdr.markForCheck();
  }

  get hasValue(): boolean {
    if (this._value && Array.isArray(this._value)) {
      return !!this._value.length;
    }

    return !!this._value;
  }

  get panelOpened(): boolean {
    return this._panelOpened;
  }

  get selectedOption():
    | DropdownOptionSelectedSource
    | DropdownOptionSelectedSource[] {
    return this.multiple
      ? this.selectionModel?.selected || []
      : this.selectionModel?.selected[0];
  }

  get displayedOption(): string | string[] {
    if (this.empty) {
      return '';
    }

    if (this.multiple) {
      return this.selectionModel.selected.map(option => option.displayedValue);
    }

    if (!!this.options?.length && this.value !== null) {
      return this.selectionModel.selected[0].displayedValue;
    }

    return this.preDefinedValue;
  }

  get empty(): boolean {
    return !this.selectionModel || this.selectionModel.isEmpty();
  }

  get preDefinedValue(): string[] {
    if (this.value === null) {
      return [];
    }

    if (!this.options?.length && this.value !== null && !this.displayWith) {
      throw new Error(
        'Provide displayWith in order to present the values correctly'
      );
    }

    return [this.displayWith(this.value)];
  }

  get dropdownContainerClass(): string[] {
    return ['dropdown', `optiply-${this.type}-dropdown`];
  }

  get searchableMessage(): string {
    if (this.loading) return this.loadingMessage;

    if (!this.panelOpened || !!this.options?.length) return '';

    return this.search?.searchedBy() === ''
      ? this.searchMessage
      : this.noResultsMessage;
  }

  /** Handles new value assigning if it's a different one or an array.
   * Returns whether the vallue has changed.
   */
  private _handleNewValue(newValue: any): boolean {
    if (
      newValue !== this._value ||
      (this.multiple && Array.isArray(newValue))
    ) {
      if (this.options) {
        this._setSelectionByValue(newValue);
      }

      this._value = newValue ?? null;

      if (newValue === null) {
        this.selectionModel?.clear();
      }

      return true;
    }

    return false;
  }

  private _canOpenPanel(): boolean {
    return !this.panelOpened && !this.disabled;
  }

  private _getDefaultPanelWidth(): string | number {
    return this.defaultConfig?.panelWidth
      ? this.defaultConfig.panelWidth
      : 'auto';
  }

  private _setSelectionByValue(value: any): void {
    if (this.forceOptionsReset) {
      this.selectionModel.clear();
      this.options.forEach((option: DropdownOptionComponent) => {
        option.clearOptionSelection();
      });
    }

    if (this.multiple && value) {
      if (!Array.isArray(value)) {
        this._selectOptionByValue(value);
      } else {
        value.forEach((currentValue: any) =>
          this._selectOptionByValue(currentValue)
        );
      }
    } else {
      this._selectOptionByValue(value);
    }

    this.cdr.markForCheck();
  }

  private _selectOptionByValue(value: any): void {
    const correspondingOption = this.options.find(
      (option: DropdownOptionComponent) => {
        if (this.selectionModel.isSelected(option) && this.forceOptionsReset) {
          return false;
        }

        return option.value !== null && this._compareWith(option.value, value);
      }
    );

    if (correspondingOption) {
      correspondingOption.selectOption(true);

      this.selectionModel.select(correspondingOption);
    }
  }

  private _resetOptions(): void {
    const changedOrDestroyed = merge(this.options.changes, this._destroy$);

    this.optionSelectionChanges$
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe(event => {
        this._onSelect(event.source);

        if (!this.multiple && this.panelOpened) {
          this.close();
        }

        if (this.multiple) {
          this._focusSearch();
        }
      });
  }

  private _onSelect(option: DropdownOptionComponent): void {
    const wasSelected = this.selectionModel.isSelected(option);

    if (option.value === null && !this.multiple) {
      option.deselect();

      this.selectionModel.clear();

      if (this.value !== null) {
        this._propagateChanges(option.value);
      }
    } else if (wasSelected !== option.selected) {
      option.selected
        ? this.selectionModel.select(option)
        : this.selectionModel.deselect(option);
    }

    if (wasSelected !== this.selectionModel.isSelected(option)) {
      this.options.forEach((option: DropdownOptionComponent) => {
        if (option.selected !== this.selectionModel.isSelected(option)) {
          option.clearOptionSelection();
        }
      });

      this._propagateChanges(true);
    }
  }

  private _propagateChanges(fallbackValue?: any): void {
    let valueToEmit: any = null;

    if (this.multiple) {
      valueToEmit = (this.selectedOption as DropdownOptionComponent[]).map(
        option => option.value
      );
    } else {
      valueToEmit = this.selectedOption
        ? (this.selectedOption as DropdownOptionComponent).value
        : fallbackValue;
    }

    this._value = valueToEmit;

    this.valueChange.emit(valueToEmit);
    this.onChange(valueToEmit);

    this.selectionChange.emit(valueToEmit);

    this.cdr.markForCheck();
  }

  private _focusSearch(): void {
    this.search?.focus();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
