<app-expansion-panel #panel="expansionPanel" (closed)="form.reset()">
  <app-expansion-panel-header>
    <app-expansion-panel-title class="custom-panel-title">
      <mat-icon
        svgIcon="questionmark-rounded"
        class="custom-panel-icon"></mat-icon>

      @if (hasEmailsAddresses()) {
        <span
          >{{ STRINGS.noIntendedEmail }}
          <span class="create-email-info">{{ STRINGS.addNew }}</span></span
        >
      } @else {
        <span
          >{{ STRINGS.noEmails }}
          <span class="create-email-info">{{ STRINGS.addNew }}</span></span
        >
      }
    </app-expansion-panel-title>
  </app-expansion-panel-header>

  <form
    #form="ngForm"
    (ngSubmit)="createEmailAddress(); form.reset()"
    class="create-email-fields">
    <div class="create-mail-container">
      <input
        aria-label="Email management email addresses create email input"
        data-intercom-target="Email management email addresses create email input"
        appInput
        variant="outline"
        type="email"
        [placeholder]="COMMON_STRINGS.enterEmail"
        name="email"
        [pattern]="EMAIL_REGEX"
        [(ngModel)]="emailAddress"
        required />
    </div>

    <button
      aria-label="Email management email addresses create email button"
      data-intercom-target="Email management email addresses create email button"
      type="submit"
      appButton
      variant="tertiary"
      [disabled]="form.invalid || saving()">
      {{ STRINGS.create }}
    </button>
  </form>
</app-expansion-panel>
