import { Pipe, type PipeTransform } from '@angular/core';
import { TEMPLATE_LANGUAGES } from '../model/email-templates-v2.model';

@Pipe({
  name: 'appLanguageLabel',
  standalone: true,
})
export class LanguageLabelPipe implements PipeTransform {
  transform(language: string): string {
    return (
      TEMPLATE_LANGUAGES.find(
        template => template.value === language
      )?.key?.toLowerCase() || TEMPLATE_LANGUAGES[0].key.toLowerCase()
    );
  }
}
