import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  AccountRole as AccountRoleV1,
  AccountRoles as AccountRolesV1,
} from '../model/account-roles.model';
import {
  AccountRoles,
  MappedPermission,
} from 'src/app/shared/models/account/account-permissions.model';
import { environment } from 'src/environments/environment';
import {
  PROD_ROLES,
  STAGING_ROLES,
} from 'src/app/core/constants/roles.constants';

export class AccountRolesAdapter
  implements DataAdapter<AccountRolesV1, AccountRoles>
{
  private readonly permissionsBase = environment.production
    ? PROD_ROLES
    : STAGING_ROLES;

  transform(data: AccountRolesV1): AccountRoles {
    const permissions = data.roles
      .map((role: AccountRoleV1) => this._extractPermissionFeatureKey(role.id))
      .filter((permission: MappedPermission | null) => !!permission);

    return {
      isShopAdministrator: data.isShopAdministrator,
      permissions,
    };
  }

  private _extractPermissionFeatureKey(
    roleId: string
  ): MappedPermission | null {
    const permission = this.permissionsBase[roleId] ?? null;

    if (!permission) return null;

    return {
      featureKey: permission.featureKey,
      role: permission.role,
    };
  }
}
