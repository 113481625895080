import {
  FilterNullGroupV2,
  FilterStrategy,
} from '../../../model/filter-v2.model';

export class FilterNullStrategy implements FilterStrategy<FilterNullGroupV2> {
  form: FilterNullGroupV2 = {
    to: null,
  };

  populate(..._args: any): void {
    // intentional
  }

  hasValue(): boolean {
    return true;
  }

  hasSameValue(): boolean {
    return false;
  }

  clear(): void {
    // intentional
  }
}
