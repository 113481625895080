import { OverlayRef } from '@angular/cdk/overlay';
import { ToastContainerComponent } from '../components/toast-container/toast-container.component';
import { Observable, Subject } from 'rxjs';
import { ToastDismiss } from '../model/toast.model';

export class ToastRef<T> {
  instance: T;

  containerInstance: ToastContainerComponent;

  private readonly _onAction = new Subject<void>();

  private readonly _afterOpened = new Subject<void>();

  private readonly _afterDismissed = new Subject<ToastDismiss>();

  private _dismissedByAction: boolean = false;

  private _timeoutId: any;

  constructor(
    containerInstance: ToastContainerComponent,
    private overlayRef: OverlayRef
  ) {
    this.containerInstance = containerInstance;
    containerInstance._onExit.subscribe(() => this._cleanupDismiss());
  }

  open(): void {
    if (!this._afterOpened.closed) {
      this._afterOpened.next();
      this._afterOpened.complete();
    }
  }

  dismiss(): void {
    if (!this._afterDismissed.closed) {
      this.containerInstance.exit();
    }

    clearTimeout(this._timeoutId);
  }

  dismissWithAction(): void {
    if (!this._onAction.closed) {
      this._dismissedByAction = true;

      this._onAction.next();
      this._onAction.complete();

      this.dismiss();
    }

    clearTimeout(this._timeoutId);
  }

  dismissAfter(duration: number): void {
    this._timeoutId = setTimeout(
      () => this.dismiss(),
      Math.min(duration, 10000)
    );
  }

  afterDismissed(): Observable<ToastDismiss> {
    return this._afterDismissed;
  }

  afterOpened(): Observable<void> {
    return this.containerInstance._onEnter;
  }

  private _cleanupDismiss(): void {
    this.overlayRef.dispose();

    if (!this._onAction.closed) {
      this._onAction.complete();
    }

    this._afterDismissed.next({ dismissedByAction: this._dismissedByAction });
    this._afterDismissed.complete();

    this._dismissedByAction = false;
  }
}
