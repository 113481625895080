import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleToastComponent } from './components/simple-toast/simple-toast.component';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ToastLabelDirective } from './directives/toast-label.directive';
import { ToastActionsDirective } from './directives/toast-actions.directive';
import {
  ToastActionDirective,
  ToastSecondaryActionDirective,
} from './directives/toast-action.directive';
import { ToastContainerDirective } from './directives/toast-container.directive';
import { MatIconModule } from '@angular/material/icon';
import { ToastLabelIconDirective } from './directives/toast-label-icon.directive';
import { ToastIconDirective } from './directives/toast-icon.directive';

@NgModule({
  declarations: [
    SimpleToastComponent,
    ToastContainerComponent,
    ToastContainerDirective,
    ToastLabelDirective,
    ToastLabelIconDirective,
    ToastIconDirective,
    ToastActionsDirective,
    ToastActionDirective,
    ToastSecondaryActionDirective,
  ],
  imports: [CommonModule, OverlayModule, PortalModule, MatIconModule],
})
export class ToastModule {}
