import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from './model/tooltip.model';
import { TooltipContainerComponent } from './components/tooltip-container/tooltip-container.component';
import { TooltipLabelDirective } from './directives/tooltip-label.directive';
import { TooltipDescriptionDirective } from './directives/tooltip-description.directive';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipContainerDirective } from './directives/tooltip-container.directive';

@NgModule({
  declarations: [
    TooltipComponent,
    TooltipDirective,
    TooltipContainerComponent,
    TooltipContainerDirective,
    TooltipLabelDirective,
    TooltipDescriptionDirective,
  ],
  imports: [CommonModule, PortalModule, OverlayModule],
  exports: [
    TooltipComponent,
    TooltipDirective,
    TooltipContainerComponent,
    TooltipContainerDirective,
    TooltipLabelDirective,
    TooltipDescriptionDirective,
  ],
  providers: [TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class TooltipModule {}
