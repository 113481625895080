import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, APP_INITIALIZER, NgModule, makeEnvironmentProviders } from '@angular/core';
import * as i1 from '@ngxs/store';
import { ofActionDispatched } from '@ngxs/store';
import { getValue } from '@ngxs/store/plugins';
import { Subject, ReplaySubject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const NG_DEV_MODE = typeof ngDevMode !== 'undefined' && ngDevMode;
const NGXS_WEBSOCKET_OPTIONS = new InjectionToken(NG_DEV_MODE ? 'NGXS_WEBSOCKET_OPTIONS' : '');
const USER_OPTIONS = new InjectionToken(NG_DEV_MODE ? 'USER_OPTIONS' : '');
/**
 * Action to connect to the websocket. Optionally pass a URL.
 */
class ConnectWebSocket {
  static {
    this.type = '[WebSocket] Connect';
  }
  constructor(payload) {
    this.payload = payload;
  }
}
/**
 * Action triggered when a error ocurrs
 */
class WebSocketMessageError {
  static {
    this.type = '[WebSocket] Message Error';
  }
  constructor(payload) {
    this.payload = payload;
  }
}
/**
 * Action to disconnect the websocket.
 */
class DisconnectWebSocket {
  static {
    this.type = '[WebSocket] Disconnect';
  }
}
/**
 * Action triggered when websocket is connected
 */
class WebSocketConnected {
  static {
    this.type = '[WebSocket] Connected';
  }
}
/**
 * Action triggered when websocket is disconnected
 */
class WebSocketDisconnected {
  static {
    this.type = '[WebSocket] Disconnected';
  }
}
/**
 * Action to send to the server.
 */
class SendWebSocketMessage {
  static {
    this.type = '[WebSocket] Send Message';
  }
  constructor(payload) {
    this.payload = payload;
  }
}
/**
 * Action dispatched when the user tries to connect if the connection already exists.
 */
class WebSocketConnectionUpdated {
  static {
    this.type = '[WebSocket] Connection Updated';
  }
}
/**
 * This error is thrown where there is no `type` (or custom `typeKey`) property
 * on the message that came from the server side socket
 */
class TypeKeyPropertyMissingError extends Error {
  constructor(typeKey) {
    super(`Property ${typeKey} is missing on the socket message`);
  }
}
class WebSocketHandler {
  constructor(_store, _ngZone, _actions$, _options) {
    this._store = _store;
    this._ngZone = _ngZone;
    this._actions$ = _actions$;
    this._options = _options;
    this._socket = null;
    this._socketClosed$ = new Subject();
    this._typeKey = this._options.typeKey;
    this._destroy$ = new ReplaySubject(1);
    this._setupActionsListeners();
  }
  ngOnDestroy() {
    this._disconnect( /* forcelyCloseSocket */true);
    this._destroy$.next();
  }
  _setupActionsListeners() {
    this._actions$.pipe(ofActionDispatched(ConnectWebSocket), takeUntil(this._destroy$)).subscribe(({
      payload
    }) => {
      this.connect(payload);
    });
    this._actions$.pipe(ofActionDispatched(DisconnectWebSocket), takeUntil(this._destroy$)).subscribe(() => {
      this._disconnect( /* forcelyCloseSocket */true);
    });
    this._actions$.pipe(ofActionDispatched(SendWebSocketMessage), takeUntil(this._destroy$)).subscribe(({
      payload
    }) => {
      this.send(payload);
    });
  }
  connect(options) {
    if (this._socket) {
      this._closeConnection( /* forcelyCloseSocket */true);
      this._store.dispatch(new WebSocketConnectionUpdated());
    }
    // TODO(arturovt): we should not override default config values because this breaks support for having multiple socket connections.
    if (options) {
      if (options.serializer) {
        this._options.serializer = options.serializer;
      }
      if (options.deserializer) {
        this._options.deserializer = options.deserializer;
      }
    }
    this._ngZone.runOutsideAngular(() => {
      // We either use options provided in the `ConnectWebSocket` action
      // or fallback to default config values.
      const url = options?.url || this._options.url;
      const protocol = options?.protocol || this._options.protocol;
      const binaryType = options?.binaryType || this._options.binaryType;
      const socket = this._socket = protocol ? new WebSocket(url, protocol) : new WebSocket(url);
      if (binaryType) {
        socket.binaryType = binaryType;
      }
      fromEvent(socket, 'open').pipe(takeUntil(this._socketClosed$)).subscribe(() => this._store.dispatch(new WebSocketConnected()));
      fromEvent(socket, 'message').pipe(takeUntil(this._socketClosed$)).subscribe(event => {
        const message = this._options.deserializer(event);
        const type = getValue(message, this._typeKey);
        if (!type) {
          throw new TypeKeyPropertyMissingError(this._typeKey);
        }
        this._store.dispatch({
          ...message,
          type
        });
      });
      fromEvent(socket, 'error').pipe(takeUntil(this._socketClosed$)).subscribe(error => {
        // The error event indicates that an error has occurred during the
        // WebSocket communication, and it is often appropriate to close the
        // WebSocket connection when such an error occurs.
        // We need to call `_disconnect()` after the error event has been fired.
        // This ensures that the WebSocket connection is properly closed to prevent
        // potential resource leaks.
        this._disconnect( /* forcelyCloseSocket */true);
        this._store.dispatch(new WebSocketMessageError(error));
      });
      fromEvent(socket, 'close').pipe(takeUntil(this._socketClosed$)).subscribe(event => {
        if (event.wasClean) {
          // It is not necessary to call `socket.close()` after the `close` event
          // has been fired. In fact, calling `socket.close()` within the `close`
          // event handler or immediately after the event has been fired can lead
          // to unexpected behavior.
          this._disconnect( /* forcelyCloseSocket */false);
        } else {
          // If the WebSocket `close` event has been fired and its `wasClean`
          // property is falsy, it indicates that the WebSocket connection was
          // closed in an unexpected or abnormal manner.
          // We should call `socket.close()` in this scenario, we can ensure that
          // the WebSocket connection is properly closed.
          this._disconnect( /* forcelyCloseSocket */true);
          this._store.dispatch(new WebSocketMessageError(event));
        }
      });
    });
  }
  _disconnect(forcelyCloseSocket) {
    if (this._socket) {
      this._closeConnection(forcelyCloseSocket);
      this._store.dispatch(new WebSocketDisconnected());
    }
  }
  send(data) {
    if (!this._socket) {
      throw new Error('You must connect to the socket before sending any data');
    }
    try {
      this._socket.send(this._options.serializer(data));
    } catch (error) {
      this._store.dispatch(new WebSocketMessageError(error));
    }
  }
  _closeConnection(forcelyCloseSocket) {
    if (forcelyCloseSocket) {
      this._socket?.close();
    }
    this._socket = null;
    this._socketClosed$.next();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function WebSocketHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WebSocketHandler)(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.Actions), i0.ɵɵinject(NGXS_WEBSOCKET_OPTIONS));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WebSocketHandler,
      factory: WebSocketHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WebSocketHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Store
  }, {
    type: i0.NgZone
  }, {
    type: i1.Actions
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGXS_WEBSOCKET_OPTIONS]
    }]
  }], null);
})();
function ɵwebsocketOptionsFactory(options) {
  return {
    reconnectInterval: 5000,
    reconnectAttempts: 10,
    typeKey: 'type',
    deserializer(e) {
      return JSON.parse(e.data);
    },
    serializer(value) {
      return JSON.stringify(value);
    },
    ...options
  };
}
function ɵgetProviders(options) {
  return [{
    provide: USER_OPTIONS,
    useValue: options
  }, {
    provide: NGXS_WEBSOCKET_OPTIONS,
    useFactory: ɵwebsocketOptionsFactory,
    deps: [USER_OPTIONS]
  }, {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [WebSocketHandler],
    multi: true
  }];
}
class NgxsWebSocketPluginModule {
  static forRoot(options) {
    return {
      ngModule: NgxsWebSocketPluginModule,
      providers: ɵgetProviders(options)
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsWebSocketPluginModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxsWebSocketPluginModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxsWebSocketPluginModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsWebSocketPluginModule, [{
    type: NgModule
  }], null, null);
})();
function withNgxsWebSocketPlugin(options) {
  return makeEnvironmentProviders(ɵgetProviders(options));
}

/**
 * The public api for consumers of @ngxs/websocket-plugin
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ConnectWebSocket, DisconnectWebSocket, NgxsWebSocketPluginModule, SendWebSocketMessage, WebSocketConnected, WebSocketConnectionUpdated, WebSocketDisconnected, WebSocketMessageError, withNgxsWebSocketPlugin, NGXS_WEBSOCKET_OPTIONS as ɵNGXS_WEBSOCKET_OPTIONS };
