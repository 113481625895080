<label
  (click)="!disabled ? handleClick() : null"
  (keydown.enter)="!disabled ? handleClick() : null"
  class="radio-button-container"
  [class.container-disabled]="disabled">
  <div class="radio-button" [class.disabled]="disabled">
    <div
      *ngIf="!checked"
      class="radio radio-unchecked"
      [class.radio-unchecked-disabled]="disabled"></div>
    <div
      *ngIf="checked"
      class="radio radio-checked"
      [class.radio-checked-disabled]="disabled"></div>
  </div>
  <div *ngIf="icon" class="radio-logo-container">
    <img class="radio-logo" [src]="icon" />
  </div>

  <span
    class="radio-button-label"
    [class.radio-button-label-big]="bigLabel"
    [class.label-disabled]="disabled"
    >{{ label }}</span
  >
</label>
