<ng-container *ngIf="!!value">
  <form #form="ngForm" class="filter-equals-option-form">
    <label for="to">{{ COMMON_STRINGS.filter.optionTypes.equals }}</label>

    <input
      appInput
      variant="filled"
      id="to"
      [(ngModel)]="value.to"
      name="to"
      type="number"
      [step]="step"
      [min]="min"
      [max]="max"
      placeholder="-"
      autocomplete="off"
      (input)="onChanged(value)" />

    <div *ngIf="form.invalid" class="warning-container">
      <mat-icon svgIcon="warning-rounded" class="icon"></mat-icon>

      <span>
        <p
          *ngIf="invalidMin"
          class="text-warning"
          i18n="validations invalid min">
          The value must be greater than or equals to {{ min }}
        </p>

        <p
          *ngIf="invalidMax"
          class="text-warning"
          i18n="validations invalid max">
          The value must be lower than or equals to {{ max }}
        </p>
      </span>
    </div>
  </form>
</ng-container>
