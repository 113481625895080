import {
  AnimationTriggerMetadata,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const tooltipAnimation: {
  readonly tooltipState: AnimationTriggerMetadata;
} = {
  tooltipState: trigger('state', [
    state(
      'void, hidden',
      style({
        opacity: 0,
        transform: 'scale(0.8)',
      })
    ),
    state(
      'visible',
      style({
        opacity: 1,
        transform: 'scale(1)',
      })
    ),
    transition('* => visible', animate('150ms cubic-bezier(0, 0, 0.2, 1)')),
    transition(
      '* => void, * => hidden',
      animate('75ms cubic-bezier(0.4, 0, 1, 1)')
    ),
  ]),
};
