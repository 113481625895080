import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import {
  ExportSuppliersBody,
  SupplierUpdateMultiple,
  SupplierUpdateProperties,
} from 'src/app/features/suppliers-v2/model/suppiers-v2.model';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { Suppliers } from 'src/app/shared/models/suppliers/v2/suppliers-v2.model';
import { SuppliersV2Adapter } from './adapters/suppliers-v2.adapter';
import {
  SupplierCreateProperties,
  SupplierDetails,
  Suppliers as SuppliersV1,
} from './model/suppliers-v2.model';
import {
  ExportProductSuppliersBody,
  Supplier,
} from 'src/app/shared/models/suppliers/product-suppliers.model';
import { Issues as IssuesV1 } from '../../issues/model/issues.model';
import { ProductDetailsAdapter } from './adapters/product-details.adapter';
import { SupplierV2Adapter } from './adapters/supplier-v2.adapter';
import { SupplierDetails as SupplierDetailsV1 } from './model/product-details.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { SuppliersManualOrder } from './model/suppliers-manual-order.model';
import { SuppliersManualOrderAdapter } from './adapters/suppliers-manual-order.adapter';
import {
  SaveMultiSupplierProductsBody,
  SaveSupplierProductsBody,
} from 'src/app/shared/models/multi-supplier-product/multi-supplier-products.model';
import { CreateSupplierProductRequestBody } from 'src/app/shared/models/multi-supplier-product-editor/create-supplier-product.model';
import {
  SaveLinkedSupplierProductProperties,
  SupplierProductLinks,
} from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import { ConnectProductToSupplierAdapter } from '../../order-moment/supply/v1/connect-product-to-supplier.adapter';
import { DisconnectedProducts } from 'src/app/shared/models/suppliers/disconnected-products.model';
import { SupplierProductLinksV2Adapter } from '../../supplier-product/v2/adapters/supplier-product-links-v2.adapter';
import { SupplierProductLinks as SupplierProductLinksV1 } from '../../supplier-product/v2/model/supplier-product-v2.model';
import { SupplierManagerAdapter } from './adapters/supplier-manager.adapter';
import { SupplierManagers } from './model/supplier-manager.model';
import { SupplierManager } from 'src/app/shared/models/suppliers/v2/supplier-v2.model';
import { IssuesV2Adapter } from '../../issues/adapters/issues-v2.adapter';
import { Issue } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.model';
import { ExportSupplierProductsBody } from 'src/app/features/suppliers-v2/component/supplier-details/components/supplier-products/model/supplier-products.model';
import { Store } from '@ngxs/store';

export interface SearchFilter {
  search: string;
}

@Injectable({
  providedIn: 'root',
})
export class SuppliersV2Service {
  constructor(
    private store: Store,
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findAllSuppliers(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<SuppliersV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'findAll',
      replacements
    );
    return this.http.post<SuppliersV1>(endpoint, payload);
  }

  private _updateMultipleSupplier(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'updateMultipleSuppliers',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _updateSupplierGRPC(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'update',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _exportSuppliers(
    payload: ExportSuppliersBody,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supply',
      'v1',
      'exportSuppliers',
      replacements
    );
    return this.http.post(endpoint, payload, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  private _findSupplierIssues(
    replacements: UrlReplacement[]
  ): Observable<IssuesV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'findAllIssues',
      replacements
    );
    return this.http.get<IssuesV1>(endpoint);
  }

  private _productDetails(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'fetchSupplierDetails',
      replacements
    );

    return this.http.get<SupplierDetailsV1>(endpoint);
  }

  private _findOne(replacements: UrlReplacement[]) {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'fetchSupplier',
      replacements
    );

    return this.http.get<SupplierDetails>(endpoint);
  }

  private _createSupplier(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'createSupplier',
      replacements
    );
    return this.http.post(endpoint, payload);
  }

  private _fetchSuppliers(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'fetchSuppliers',
      replacements
    );
    return this.http.get<SuppliersManualOrder>(endpoint, { params: payload });
  }

  private _updateMultipleSupplierProducts(payload: any): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'updateMultiple',
      []
    );
    return this.http.post(endpoint, payload);
  }

  private _createSupplierProducts(payload: any): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'createSupplierProduct',
      []
    );
    return this.http.post(endpoint, payload);
  }

  private _findDisconnectedProducts(
    replacements: UrlReplacement[],
    payload: any
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'disconnectedWebshopProducts',
      replacements
    );
    return this.http.get<DisconnectedProducts>(endpoint, { params: payload });
  }

  private _deleteSupplierProduct(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v1',
      'deleteSupplierProduct',
      replacements
    );
    return this.http.delete(endpoint, { params: payload });
  }

  private _exportProductSuppliers(
    payload: ExportProductSuppliersBody,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supply',
      'v1',
      'exportProductSuppliers',
      replacements
    );
    return this.http.post(endpoint, payload, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  private _fetchSupplierProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<SupplierProductLinksV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'fetchSupplierProducts',
      replacements
    );
    return this.http.post<SupplierProductLinksV1>(endpoint, payload);
  }

  private _findSupplierManager(payload: any, replacements: UrlReplacement[]) {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'fetchSupplierManager',
      replacements
    );

    return this.http.post<SupplierManagers>(endpoint, payload);
  }

  private _updateSupplierManager(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'updateSupplierManager',
      replacements
    );
    return this.http.put(endpoint, payload);
  }

  private _exportSupplierProducts(
    payload: ExportSupplierProductsBody,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supply',
      'v1',
      'exportSupplierProducts',
      replacements
    );
    return this.http.post(endpoint, payload, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  findAllSuppliers(
    webshopUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<Suppliers> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findAllSuppliers(payload, replacements).pipe(
      switchMap(data => of(new SuppliersV2Adapter(this.store).transform(data)))
    );
  }

  updateMultipleSupplier(
    webshopUuid: string,
    settings: SupplierUpdateMultiple
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._updateMultipleSupplier(settings, replacements);
  }

  updateSupplierGRPC(
    webshopUuid: string,
    supplierUuid: string,
    settings: SupplierUpdateProperties
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'uuid', value: supplierUuid },
    ];

    return this._updateSupplierGRPC(settings, replacements);
  }

  exportSuppliers(
    webshopUUID: string,
    settings: ExportSuppliersBody
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];
    return this._exportSuppliers(settings, replacements);
  }

  findSupplierIssues(webshopUuid: string): Observable<Issue[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
    ];

    return this._findSupplierIssues(replacements).pipe(
      switchMap(data => of(new IssuesV2Adapter().transform(data)))
    );
  }

  productDetails(
    webshopUuid: string,
    webshopProductUuid: string
  ): Observable<Supplier> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'webshopProductUuid', value: webshopProductUuid },
    ];

    return this._productDetails(replacements).pipe(
      switchMap(data => of(new ProductDetailsAdapter().transform(data)))
    );
  }

  fetchSupplier(webshopUuid: string, supplierUuid: string) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findOne(replacements).pipe(
      switchMap(data => of(new SupplierV2Adapter(this.store).transform(data)))
    );
  }

  createSupplier(
    webshopUUID: string,
    settings: SupplierCreateProperties
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];
    return this._createSupplier(settings, replacements);
  }

  fetchSuppliers(
    webshopUUID: string,
    search: string
  ): Observable<SuppliersManualOrder> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    const payload: SearchFilter = { search };

    return this._fetchSuppliers(payload, replacements).pipe(
      switchMap(data => of(new SuppliersManualOrderAdapter().transform(data)))
    );
  }

  updateMultipleSupplierProducts(
    properties: SaveMultiSupplierProductsBody
  ): Observable<any> {
    return this._updateMultipleSupplierProducts(properties);
  }

  createSupplierProducts(
    properties: SaveSupplierProductsBody | SaveLinkedSupplierProductProperties
  ): Observable<any> {
    return this._createSupplierProducts(properties);
  }

  createSupplierProduct(
    properties: CreateSupplierProductRequestBody
  ): Observable<any> {
    return this._createSupplierProducts(properties);
  }

  findDisconnected(webshopUuid: string, supplierUuid: string, payload) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findDisconnectedProducts(replacements, payload).pipe(
      switchMap(data =>
        of(new ConnectProductToSupplierAdapter().transform(data))
      )
    );
  }

  deleteSupplierProduct(requestInfo: any, supplierProductUUID: string) {
    const replacements: UrlReplacement[] = [
      { name: 'supplierProductUUID', value: supplierProductUUID },
    ];
    const payload = {
      webshopUUID: requestInfo,
    };

    return this._deleteSupplierProduct(payload, replacements);
  }

  exportProductSuppliers(
    webshopUUID: string,
    webshopProductUUID: string,
    settings: ExportProductSuppliersBody
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'webshopProductUUID', value: webshopProductUUID },
    ];
    return this._exportProductSuppliers(settings, replacements);
  }

  fetchSupplierProducts(
    webshopUuid: string,
    supplierUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<SupplierProductLinks> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._fetchSupplierProducts(payload, replacements).pipe(
      switchMap(data => of(new SupplierProductLinksV2Adapter().transform(data)))
    );
  }

  fetchSupplierManager(
    webshopUuid: string,
    supplierUuid: string,
    payload: ServiceRequestInfoV3
  ) {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findSupplierManager(payload, replacements).pipe(
      switchMap(data => of(new SupplierManagerAdapter().transform(data)))
    );
  }

  updateSupplierManager(
    webshopUuid: string,
    supplierUuid: string,
    settings: SupplierManager
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._updateSupplierManager(settings, replacements);
  }

  exportSupplierProducts(
    webshopUUID: string,
    supplierUuid: string,
    settings: ExportSupplierProductsBody
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'supplierUuid', value: supplierUuid },
    ];
    return this._exportSupplierProducts(settings, replacements);
  }
}
