import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  Subject,
  takeUntil,
} from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { TextOverflowService } from 'src/app/core/services/text-overflow.service';
import { SearchAutoComplete } from './model/search.model';
import { InputVariant } from '../design-system/input/model/input.model';

let uniqueId = 0;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input()
  id: string = String(uniqueId++);

  @Input()
  variant: InputVariant = 'outline';

  @Input()
  autocompleteList: SearchAutoComplete[];

  outputList: string[];

  @ViewChild(NgModel, { static: true })
  searchInput: NgModel;

  readonly COMMON_STRINGS = MESSAGES.common;

  @Input()
  searchBy: string;

  @Input()
  placeholder: string = this.COMMON_STRINGS.filter.search;

  @Input()
  disabled: boolean;

  @Output()
  searched: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  optionSelected: EventEmitter<SearchAutoComplete> =
    new EventEmitter<SearchAutoComplete>();

  private _destroy$: Subject<void> = new Subject<void>();

  public showAutoCompleteList$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  @ViewChild('searchDiv') searchDiv: ElementRef;

  constructor(
    private renderer: Renderer2,
    private textOverflow: TextOverflowService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.searchDiv.nativeElement) {
        this.showAutoCompleteList$.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.searchInput.valueChanges
      .pipe(
        skip(1),
        debounceTime(500),
        filter((value: string) => value !== this.searchBy),
        distinctUntilChanged(),
        map((value: string) => this._formatValues(value)),
        takeUntil(this._destroy$)
      )
      .subscribe((values: string[]) => {
        this.apply(values);
      });
  }

  trackById(_index: number, item: SearchAutoComplete): number {
    return item.id;
  }

  apply(values: string[]): void {
    this.searched.emit(values);
  }

  /**
   * Formats values to create an array by splitting by ";" and trimming each value
   * @param values string
   * @returns values string[]
   */
  private _formatValues(values: string): string[] {
    if (this._noEmptyString(values)) {
      const keywordsToArray = values?.split(';');

      if (keywordsToArray.length > 1) {
        return keywordsToArray
          .filter(this._noEmptyString)
          .map((keyword: string) => keyword.trim());
      }

      return keywordsToArray;
    } else {
      this.showAutoCompleteList$.next(false);
      return [];
    }
  }

  /**
   * Checks if it isn't and empty string
   * @param value string
   * @returns true if not empty string
   */
  private _noEmptyString(value: string): boolean {
    return !!value && value !== ' ';
  }

  showAutoComplete() {
    if (this.autocompleteList) {
      this.showAutoCompleteList$.next(true);
    }
  }

  onselectSearch(searchedObject) {
    this.searchBy = searchedObject.name;
    this.showAutoCompleteList$.next(false);
    this.optionSelected.emit(searchedObject);
  }

  checkOverflow(element) {
    return this.textOverflow.checkOverflow(element);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
