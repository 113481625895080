import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { ProposedBuyOrderlines } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { ProposedBuyOrderlinesOverviewV2Adapter } from '../../buy-order/v2/adapters/proposed-buy-orderlines-overview-v2.adapter';
import { ProposedBuyOrderlinesOverview as ProposedBuyOrderlinesOverviewV1 } from '../../buy-order/v2/model/proposed-buy-orderlines-overview-v2.model';
import { ManualOrderlinesV2Adapter } from './adapters/buy-order-manual-order.adapter';
import { ManualBuyOrderProducts as ManualBuyOrderProductsV1 } from './model/buy-order-manual-order.model';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class BuyOrderManualOrderV2Service {
  constructor(
    private store: Store,
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findAllOrderSessionProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ManualBuyOrderProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findManualOrderSessionProducts',
      replacements
    );
    return this.http.post<ManualBuyOrderProductsV1>(endpoint, payload);
  }

  private _findAvailableProducts(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ManualBuyOrderProductsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findManualAvailableProducts',
      replacements
    );
    return this.http.post<ManualBuyOrderProductsV1>(endpoint, payload);
  }

  private _findBuyOrderlinesOverview(
    replacements: UrlReplacement[]
  ): Observable<ProposedBuyOrderlinesOverviewV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'proposedBuyOrdersGRPC',
      'v2',
      'findManualOrderOverview',
      replacements
    );

    return this.http.get<ProposedBuyOrderlinesOverviewV1>(endpoint);
  }

  findOrderProducts(
    webshopUuid: string,
    supplierUuid: string,
    sessionUuid: string,
    payload: any
  ): Observable<ProposedBuyOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
      { name: 'sessionUuid', value: sessionUuid },
    ];

    return this._findAllOrderSessionProducts(payload, replacements).pipe(
      switchMap((data: ManualBuyOrderProductsV1) =>
        of(new ManualOrderlinesV2Adapter(false).transform(data))
      )
    );
  }

  findAvailableProducts(
    webshopUuid: string,
    supplierUuid: string,
    payload: any
  ): Observable<ProposedBuyOrderlines> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
    ];

    return this._findAvailableProducts(payload, replacements).pipe(
      switchMap((data: ManualBuyOrderProductsV1) =>
        of(new ManualOrderlinesV2Adapter(true).transform(data))
      )
    );
  }

  findBuyOrderlinesOverview(
    webshopUuid: string,
    supplierUuid: string,
    sessionUuid: string
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'supplierUuid', value: supplierUuid },
      { name: 'sessionUuid', value: sessionUuid },
    ];

    return this._findBuyOrderlinesOverview(replacements).pipe(
      switchMap((data: ProposedBuyOrderlinesOverviewV1) =>
        of(
          new ProposedBuyOrderlinesOverviewV2Adapter(this.store).transform(data)
        )
      )
    );
  }
}
