import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { DropdownModule } from '../../../design-system/dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';
import { EmailTemplateContext } from 'src/app/shared/models/emails/v1/emails.model';

@Component({
  standalone: true,
  selector: 'app-email-templates-v2-context',
  templateUrl: './email-templates-v2-context.component.html',
  styleUrl: './email-templates-v2-context.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DropdownModule, FormsModule],
})
export class EmailTemplatesV2ContextComponent {
  contextOptions = input.required<EmailTemplateContext[]>();

  disabled = input(false, { transform: booleanAttribute });

  context = input.required<string>();

  contextChange = output<string>();

  displayWithKey(context: EmailTemplateContext): string {
    return context.key;
  }
}
