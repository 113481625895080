<div class="container">
  <div class="filter-base filter-history">
    <button
      data-intercom-target="d2630369-ed3d-45a9-bbd7-fc81e5955bfb"
      aria-label="Product details sales filter last month"
      class="filter-button"
      [class.filter-button-focused]="
        (graphFilter$ | async) === GRAPH_FILTER_OPTIONS.LAST_MONTH
      "
      (click)="selectFilter(GRAPH_FILTER_OPTIONS.LAST_MONTH)"
      [appTooltip]="TOOLTIPS.lastMonth"
      [disabled]="loading$ | async">
      1M
    </button>

    <button
      data-intercom-target="03a42689-988f-4a39-9a76-b0bd4d6cfba9"
      aria-label="Product details sales filter last three months"
      class="filter-button"
      [class.filter-button-focused]="
        (graphFilter$ | async) === GRAPH_FILTER_OPTIONS.LAST_3_MONTHS
      "
      (click)="selectFilter(GRAPH_FILTER_OPTIONS.LAST_3_MONTHS)"
      [appTooltip]="TOOLTIPS.lastThreeMonths"
      [disabled]="loading$ | async">
      3M
    </button>

    <button
      data-intercom-target="ae82a659-0069-4252-84a9-f21b3d1599ca"
      aria-label="Product details sales filter last six month"
      class="filter-button"
      [class.filter-button-focused]="
        (graphFilter$ | async) === GRAPH_FILTER_OPTIONS.LAST_6_MONTHS
      "
      (click)="selectFilter(GRAPH_FILTER_OPTIONS.LAST_6_MONTHS)"
      [appTooltip]="TOOLTIPS.lastSixMonths"
      [disabled]="loading$ | async">
      6M
    </button>

    <button
      data-intercom-target="1963167b-ebd6-4ad0-b470-4c6572fcbb56"
      aria-label="Product details sales filter last year"
      class="filter-button"
      [class.filter-button-focused]="
        (graphFilter$ | async) === GRAPH_FILTER_OPTIONS.LAST_YEAR
      "
      (click)="selectFilter(GRAPH_FILTER_OPTIONS.LAST_YEAR)"
      [appTooltip]="TOOLTIPS.lastYear"
      [disabled]="loading$ | async">
      Y
    </button>

    <button
      data-intercom-target="63e7bacd-c152-4281-973c-f25038d2bcd9"
      aria-label="Product details sales filter all time"
      class="filter-button"
      [class.filter-button-focused]="
        (graphFilter$ | async) === GRAPH_FILTER_OPTIONS.ALL_TIME
      "
      (click)="selectFilter(GRAPH_FILTER_OPTIONS.ALL_TIME)"
      [appTooltip]="TOOLTIPS.last5Years"
      [disabled]="loading$ | async">
      5Y
    </button>
  </div>

  <div class="filter-base filter-group">
    <button
      data-intercom-target="420ad36b-34b0-45fd-8b19-95889dfb70c7"
      aria-label="Product details sales group daily"
      class="filter-button"
      [class.filter-button-focused]="
        (graphGroupBy$ | async) === GRAPH_GROUPBY_OPTIONS.DAILY
      "
      (click)="selectGroupBy(GRAPH_GROUPBY_OPTIONS.DAILY)"
      [appTooltip]="TOOLTIPS.groupedDaily"
      [disabled]="loading$ | async">
      D
    </button>

    <button
      data-intercom-target="4cae92bf-3dbe-4f77-8610-3f0b70fa2d4c"
      aria-label="Product details sales group weekly"
      class="filter-button"
      [class.filter-button-focused]="
        (graphGroupBy$ | async) === GRAPH_GROUPBY_OPTIONS.WEEKLY
      "
      (click)="selectGroupBy(GRAPH_GROUPBY_OPTIONS.WEEKLY)"
      [appTooltip]="TOOLTIPS.groupedWeekly"
      [disabled]="loading$ | async">
      W
    </button>

    <button
      data-intercom-target="cdd89a22-3637-47be-9e5a-9eed7a2c3e27"
      aria-label="Product details sales group monthly"
      class="filter-button"
      [class.filter-button-focused]="
        (graphGroupBy$ | async) === GRAPH_GROUPBY_OPTIONS.MONTHLY
      "
      (click)="selectGroupBy(GRAPH_GROUPBY_OPTIONS.MONTHLY)"
      i18n="product details product information sales m"
      [appTooltip]="TOOLTIPS.groupedMonthly"
      [disabled]="loading$ | async">
      M
    </button>
  </div>

  <button
    data-intercom-target="181cf1e0-cb7e-4244-8675-3aaacd298a1f"
    aria-label="Product details sales refresh button"
    class="optiply-tertiary-btn refresh-icon"
    (click)="refresh()"
    [appTooltip]="TOOLTIPS.refresh"
    [disabled]="loading$ | async">
    <mat-icon svgIcon="rotating"></mat-icon>
  </button>
</div>
