import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { TooltipBase } from './core/tooltip-base';

@Component({
  selector: 'app-tooltip-template',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent extends TooltipBase implements OnDestroy {
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
