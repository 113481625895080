<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="relativeElement"
  [cdkConnectedOverlayOpen]="isOpen$ | async"
  [cdkConnectedOverlayMinWidth]="minWidth"
  [cdkConnectedOverlayMinHeight]="minHeight"
  [cdkConnectedOverlayFlexibleDimensions]="overlayFlexibleDimensions"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
  [cdkConnectedOverlayBackdropClass]="backdropClasses"
  [cdkConnectedOverlayDisableClose]="disableClose"
  [cdkConnectedOverlayLockPosition]="lockPosition"
  [cdkConnectedOverlayPositions]="positions">
  <div
    [ngStyle]="{ 'maxWidth.px': maxWidth }"
    class="overlay-container"
    [ngClass]="overlayContainerClass"
    (click)="closeOverlay()">
    <ng-content></ng-content>
  </div>
</ng-template>
