import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import {
  ConceptBuyOrders,
  CreateConceptModel,
  UpdateConceptModel,
} from 'src/app/shared/models/buy-orders/v2/concept-buy-orders-v2.model';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { ConceptBuyOrdersV2Adapter } from './adapters/concept-buy-orders-v2.adapter';
import { ConceptCreateAdapter } from './adapters/concept-create.adapter';
import { ConceptBuyOrders as ConceptBuyOrdersV1 } from './model/concept-buy-order-v2-model';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class ConceptBuyOrderV2Service {
  constructor(
    private store: Store,
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findAllBuyOrderConceptOverviews(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ConceptBuyOrdersV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'findAllBuyOrdersConceptOverviews',
      replacements
    );
    return this.http.post<ConceptBuyOrdersV1>(endpoint, payload);
  }

  private _createConcept(
    replacements: UrlReplacement[],
    payload: CreateConceptModel
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'create',
      replacements
    );
    return this.http.post(endpoint, payload);
  }

  private _updateConcept(
    replacements: UrlReplacement[],
    payload: UpdateConceptModel
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'update',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _deleteConcept(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'delete',
      replacements
    );
    return this.http.delete(endpoint);
  }

  findAllBuyOrderConceptOverviews(
    webshopUUID: string,
    payload: any
  ): Observable<ConceptBuyOrders> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
    ];

    return this._findAllBuyOrderConceptOverviews(payload, replacements).pipe(
      switchMap((data: ConceptBuyOrdersV1) =>
        of(new ConceptBuyOrdersV2Adapter(this.store).transform(data))
      )
    );
  }

  deleteConcept(
    webshopUUID: string,
    conceptOrderUUID: string
  ): Observable<ConceptBuyOrders> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
      { name: 'uuid', value: conceptOrderUUID },
    ];

    return this._deleteConcept(replacements);
  }

  createConcept(
    webshopUUID: string,
    payload: CreateConceptModel
  ): Observable<{ uuid: string }> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
    ];
    return this._createConcept(replacements, payload).pipe(
      switchMap(data => of(new ConceptCreateAdapter().transform(data)))
    );
  }

  updateConcept(
    webshopUUID: string,
    uuid: string,
    payload: UpdateConceptModel
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
      { name: 'uuid', value: uuid },
    ];

    return this._updateConcept(replacements, payload);
  }
}
