import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  AccountSettings,
  ChatStates,
  ColorThemes,
  MenuStates,
  ProductInformationSalesGraphGroups,
  ProductInformationSalesGraphHistoryLengths,
} from 'src/app/shared/models/account/account-settings.model';
import { AccountSettings as AccountSettingsV1 } from '../model/account-settings.model';

export class AccountSettingsAdapter
  implements DataAdapter<AccountSettingsV1, AccountSettings>
{
  transform(data: AccountSettingsV1): AccountSettings {
    const settings: AccountSettings = {
      colorTheme: ColorThemes.LIGHT,
      lastWebshop: null,
      language: {
        localeId: 'en-US',
        countryCode: 'us',
        codeAlpha2: 'en',
        codeAlpha3: 'eng',
      },
      preferredEmailLanguage: 'en',
      chatState: ChatStates.OPENED,
      serviceLevelGraphExcludedCategories: [],
      generalMenuVisibility: MenuStates.COLLAPSED,
      productInformationSalesGraphSettings: {
        historyLength: ProductInformationSalesGraphHistoryLengths.LAST_MONTH,
        grouping: ProductInformationSalesGraphGroups.DAILY,
      },
      overdueDeliveriesTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      futureDeliveriesTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      completedDeliveriesTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      deliveryEditorUndeliveredProductsTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      deliveryEditorDeliveredProductsTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      productInformationDeliveriesTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      promotionsEditorTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      productInformationPromotionsTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      plannedOrdersV2TableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      draftOrdersV2TableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      proposedOrderEditorProposedV2TableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      proposedOrderEditorAvailableV2TableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      draftOrderEditorProposedV2TableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      draftOrderEditorAvailableV2TableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      productsEditorTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      productInformationCompositionsTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      productInformationPartsTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      productInformationSupplierProductsTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      promotionWebshopProductsEditorTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      suppliersEditorTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      supplierInformationSupplierProductsTableSettings: {
        excludedColumns: [],
        sortBy: null,
        orderBy: null,
        pageSize: 25,
      },
      draftOrderEditorHeaderDropdownSelection: [],
      proposedOrderEditorHeaderDropdownSelection: [],
      purchaseCheckoutDoNotAskAgain: false,
    };

    if (data?.settings !== null) {
      settings.colorTheme = data.settings.colorTheme;
      settings.lastWebshop = data.settings.lastWebshopUUID;
      settings.language.localeId = data.settings.language.localeId;
      settings.preferredEmailLanguage =
        data.settings.preferredEmailLanguage.toLowerCase();
      settings.chatState = data.settings.chatState;
      settings.serviceLevelGraphExcludedCategories =
        data.settings.serviceLevelGraphExcludedCategories;
      settings.generalMenuVisibility = data.settings.generalMenuVisibility;
      settings.productInformationSalesGraphSettings =
        data.settings.productInformationSalesGraphSettings;
      settings.overdueDeliveriesTableSettings =
        data.settings.overdueDeliveriesTableSettings;
      settings.futureDeliveriesTableSettings =
        data.settings.futureDeliveriesTableSettings;
      settings.completedDeliveriesTableSettings =
        data.settings.completedDeliveriesTableSettings;
      settings.deliveryEditorUndeliveredProductsTableSettings =
        data.settings.deliveryEditorUndeliveredProductsTableSettings;
      settings.deliveryEditorDeliveredProductsTableSettings =
        data.settings.deliveryEditorDeliveredProductsTableSettings;
      settings.productInformationDeliveriesTableSettings =
        data.settings.productInformationDeliveriesTableSettings;
      settings.promotionsEditorTableSettings =
        data.settings.promotionsEditorTableSettings;
      settings.productInformationPromotionsTableSettings =
        data.settings.productInformationPromotionsTableSettings;
      settings.plannedOrdersV2TableSettings =
        data.settings.plannedOrdersV2TableSettings;
      settings.draftOrdersV2TableSettings =
        data.settings.draftOrdersV2TableSettings;
      settings.proposedOrderEditorProposedV2TableSettings =
        data.settings.proposedOrderEditorProposedV2TableSettings;
      settings.proposedOrderEditorAvailableV2TableSettings =
        data.settings.proposedOrderEditorAvailableV2TableSettings;
      settings.draftOrderEditorProposedV2TableSettings =
        data.settings.draftOrderEditorProposedV2TableSettings;
      settings.draftOrderEditorAvailableV2TableSettings =
        data.settings.draftOrderEditorAvailableV2TableSettings;
      settings.productsEditorTableSettings =
        data.settings.productsEditorTableSettings;
      settings.productInformationCompositionsTableSettings =
        data.settings.productInformationCompositionsTableSettings;
      settings.productInformationPartsTableSettings =
        data.settings.productInformationPartsTableSettings;
      settings.productInformationSupplierProductsTableSettings =
        data.settings.productInformationSupplierProductsTableSettings;
      settings.promotionWebshopProductsEditorTableSettings =
        data.settings.promotionWebshopProductsEditorTableSettings;
      settings.suppliersEditorTableSettings =
        data.settings.suppliersEditorTableSettings;
      settings.supplierInformationSupplierProductsTableSettings =
        data.settings.supplierInformationSupplierProductsTableSettings;
      settings.draftOrderEditorHeaderDropdownSelection =
        data.settings.draftOrderEditorHeaderDropdownSelection;
      settings.proposedOrderEditorHeaderDropdownSelection =
        data.settings.proposedOrderEditorHeaderDropdownSelection;
      settings.purchaseCheckoutDoNotAskAgain =
        data.settings.purchaseCheckoutDoNotAskAgain;
    }

    return settings;
  }
}
