<div
  [ngClass]="containerClasses"
  [class.expansion-panel-header-disabled]="panel.disabled"
  [class.expansion-panel-header-expanded]="
    !panel.disabled && panel.panelExpanded()
  "
  (click)="panel.toggle()"
  (keydown.enter)="panel.toggle()"
  [tabindex]="tabIndex">
  <ng-content
    select="[appExpansionPanelTitle], app-expansion-panel-title"></ng-content>

  <span
    class="expansion-panel-header-toggle-container"
    [class.expansion-panel-header-toggle-container-start]="
      togglePosition === 'start'
    ">
    <ng-container [ngSwitch]="!!customToggle">
      <ng-container *ngIf="!hideToggle">
        <app-expansion-panel-toggle *ngSwitchCase="false">
          <mat-icon
            class="expansion-panel-header-toggle-icon"
            [class.expansion-panel-header-toggle-icon-expanded]="
              !panel.disabled && panel.panelExpanded()
            "
            svgIcon="angle-down-xs"></mat-icon>
        </app-expansion-panel-toggle>

        <ng-content
          *ngSwitchCase="true"
          select="[appExpansionPanelToggle], app-expansion-panel-toggle"></ng-content>
      </ng-container>
    </ng-container>
  </span>
</div>
