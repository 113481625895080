import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { CURRENCIES } from 'src/app/core/constants/global.constants';
import { CurrencyService } from 'src/app/core/services/currency.service';

@Directive({
  selector: '[appCurrencyStyle]',
})
export class CurrencyStyleDirective implements AfterViewInit {
  private readonly CURRENCIES = CURRENCIES;
  private usedCurrency: string;

  constructor(
    private element: ElementRef<any>,
    private currencyService: CurrencyService
  ) {
    this.usedCurrency = this.CURRENCIES.find(
      currency => currency.value === this.currencyService.currencyCode()
    ).symbol;
  }

  ngAfterViewInit(): void {
    this.addStyle();
  }

  /**
   * It appends some html boiler plate (with class) to the current currency text content.
   */
  private addStyle() {
    if (this.element.nativeElement.innerHTML.includes(this.usedCurrency)) {
      const base = `<span class="currency-symbol">${this.usedCurrency}</span>`;

      this.element.nativeElement.innerHTML =
        this.element.nativeElement.innerHTML.replace(this.usedCurrency, base);
    }
  }
}
