import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ExportOrderV2 } from 'src/app/core/actions/export-order.action';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { AppState } from 'src/app/core/states/app.state';

@Component({
  selector: 'app-purchase-exports',
  templateUrl: './purchase-exports.component.html',
  styleUrl: './purchase-exports.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseExportsComponent {
  @Select(AppState.isSaving)
  saving$: Observable<boolean>;

  readonly EXPORTS_STRINGS = MESSAGES.common.exports;

  constructor(private store: Store) {}

  exportAsCsv(): void {
    this.store.dispatch(new ExportOrderV2('CSV'));
  }

  exportAsXlsx(): void {
    this.store.dispatch(new ExportOrderV2('XLSX'));
  }
}
