import { InjectionToken } from '@angular/core';
import { ExpansionPanelTitleDirective } from '../directives/expansion-panel-title.directive';
import { ExpansionPanelToggleDirective } from '../directives/expansion-panel-toggle.directive';
import { ExpansionPanelComponent } from '../expansion-panel.component';
import { AccordionComponent } from '../core/accordion/accordion.component';

/* Tokens */
export const ACCORDION = new InjectionToken<AccordionComponent>('Accordion');

export const EXPANSION_PANEL = new InjectionToken<ExpansionPanelComponent>(
  'ExpansionPanel'
);

export const EXPANSION_PANEL_TITLE =
  new InjectionToken<ExpansionPanelTitleDirective>('ExpansionPanelTitle');

export const EXPANSION_PANEL_TOGGLE =
  new InjectionToken<ExpansionPanelToggleDirective>('ExpansionPanelToggle');

export const EXPANSION_PANEL_DEFAULT_OPTIONS =
  new InjectionToken<ExpansionPanelOptions>('ExpansionPanelOptions');

export interface ExpansionPanelOptions {
  togglePosition: TogglePosition;
}

export type TogglePosition = 'start' | 'left' | 'end' | 'right';
