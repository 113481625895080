import { PendingChangesKeys } from 'src/app/shared/models/navigation/pending-changes.model';

export class SetInitialData {
  static readonly type = '[Pending Changes] Set Initial Data';

  constructor(
    public component: string,
    public state: any
  ) {}
}

export class SetDataTableInitialData {
  static readonly type = '[Pending Changes] Set DataTable Initial Data';

  constructor(
    public component: string,
    public state: any
  ) {}
}

export class UnsetInitialData {
  static readonly type = '[Pending Changes] Unset Initial Data';

  constructor(public component?: string) {}
}

export class ResetAllInitialData {
  static readonly type = '[Pending Changes] Reset All Initial Data';
}

export class SetPendingChanges {
  static readonly type = '[Pending Changes] Set Pending Changes';

  constructor(
    public component: PendingChangesKeys,
    public validChanges: boolean = true,
    public changes?: any
  ) {}
}

export class SetPendingDataTableChanges {
  static readonly type = '[Pending Changes] Set Pending Data Table Changes';

  constructor(
    public component: PendingChangesKeys,
    public validChanges: boolean = true,
    public changes?: any
  ) {}
}

export class UnsetPendingChanges {
  static readonly type = '[Pending Changes] Cancel Pending Changes';

  constructor(public component: PendingChangesKeys) {}
}

export class UnsetAllPendingChanges {
  static readonly type = '[Pending Changes] Cancel All Pending Changes';
}

export class PreventUnsavedChanges {
  static readonly type = '[Pending Changes] Prevent Unsaved Changes';
}

export class ReDispatchActionsChanges {
  static readonly type = '[Pending Changes] Redispatch Actions Changes';
}
