export const STRINGS = {
  metadata: {
    title: $localize`User settings`,
    changeUsername: {
      label: $localize`User Name`,
      description: $localize`Change the user name to display`,
      placeholder: $localize`Enter your user name`,
    },
    changePassword: {
      label: $localize`Password`,
      description: $localize`Change or recover your password`,
      changePasswordAction: $localize`Change password...`,
      dialog: {
        title: $localize`Change password`,
        description: $localize`Choose a unique password to protect your account.`,
        newPassword: $localize`New password`,
        passwordValidations: {
          title: $localize`Choose a unique password that contains:`,
          minimumCharacters: $localize`At least 8 characters`,
          atLeast: $localize`At least 3 of the following:`,
          lowerCase: $localize`Lower case letters (a-z)`,
          upperCase: $localize`Upper case letters (A-Z)`,
          numbers: $localize`Numbers (0-9)`,
          special: $localize`Special characters (e.g. !@#$%^*)`,
        },
        confirmNewPassword: $localize`Confirm new password`,
        passwordMatchError: $localize`The confirmation password doesn’t match with the new password.`,
        changePasswordAction: $localize`Change password`,
      },
    },
  },
};
