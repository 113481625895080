import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, distinctUntilKeyChanged, filter, first, skip } from 'rxjs';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';
import { ProductDetails } from 'src/app/shared/models/product/product.model';
import {
  DataTableRowForm,
  DataTableRowFormSource,
} from 'src/app/shared/models/promotion-editor/product-promotions-data-table-row-form';
import {
  InitializeWebshopProductPromotionsState,
  RemoveAllFilters,
  ResetProductPromotions,
} from './actions/product-promotions-v2.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getRedirectedProductUuid } from 'src/app/features/products-v2/model/products-v2.model';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { LoadWebshopProductUnassociatedPromotions } from './components/add-product-promotion/actions/add-product-promotion.actions';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';

@Component({
  selector: 'app-product-promotions-v2',
  templateUrl: './product-promotions-v2.component.html',
  styleUrls: ['./product-promotions-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPromotionsV2Component implements OnInit, OnDestroy {
  @Input()
  productDetails: ProductDetails;

  @Input()
  readOnly: boolean;

  @Input()
  isOverlay: boolean = false;

  @Select(PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.PROMOTIONS))
  viewOnly$: Observable<boolean>;

  redirectedProductUuid$: Observable<string> = getRedirectedProductUuid();

  dataForm: DataTableRowForm = null;

  readonly PROMOTIONS_METADATA = STRINGS.metadata;

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private destroyRef: DestroyRef
  ) {
    this.dataForm = new DataTableRowForm(
      this.formBuilder,
      new DataTableRowFormSource(formBuilder)
    );
  }

  ngOnInit(): void {
    this.store
      .select(ProductDetailsV2StateQueries.productOverviewSucceed)
      .pipe(filter(Boolean), first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.store.dispatch([
          new InitializeWebshopProductPromotionsState(),
          new LoadWebshopProductUnassociatedPromotions(),
        ])
      );

    this.store
      .select(WebshopState.selected)
      .pipe(
        skip(1),
        distinctUntilKeyChanged('uuid'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.store.dispatch(new RemoveAllFilters()));

    this.redirectedProductUuid$.subscribe(() =>
      this.store.dispatch([
        new InitializeWebshopProductPromotionsState(),
        new LoadWebshopProductUnassociatedPromotions(),
      ])
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetProductPromotions());
  }
}
