import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import {
  ResetImportData,
  UpdateImportList,
} from '../actions/imports-dialog.action';
import { UploadData } from 'src/app/features/imports/components/imports-data-table/actions/imports.actions';

export interface ImportsDialogStateModel {
  importList: FormData;
  importKey: string;
}

const editorDefaults: ImportsDialogStateModel = {
  importList: new FormData(),
  importKey: '9a8e024d-0e35-47ec-a5fe-6cdca649e45e',
};

@State<ImportsDialogStateModel>({
  name: 'importsDialogState',
  defaults: editorDefaults,
})
@Injectable()
export class ImportsDialogState {
  @Selector()
  static importKey(state: ImportsDialogStateModel): string {
    return state.importKey;
  }

  @Action(UpdateImportList, { cancelUncompleted: true })
  updateImportList(ctx: StateContext<ImportsDialogStateModel>, payload: any) {
    const state = ctx.getState();

    state.importList.append('data', JSON.stringify(payload.data));

    if (payload.data.batch.index === payload.data.batch.count) {
      ctx.dispatch(new UploadData(state.importList, payload.type));
    }

    return of(true);
  }

  @Action(ResetImportData, { cancelUncompleted: true })
  reset(ctx: StateContext<ImportsDialogStateModel>) {
    ctx.patchState({
      importList: new FormData(),
    });
  }
}
