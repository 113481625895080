<button
  aria-label="Add filter button"
  appOverlay
  #overlay="overlay"
  class="filter-container"
  [class.filter-disabled]="disabled()"
  [disabled]="disabled()"
  (outsideClicked)="cancel()">
  <span class="icon">
    <mat-icon svgIcon="plus-xs" />
  </span>

  <span class="title">{{ COMMON_STRINGS.filter.add }}</span>

  <ng-template cdk-portal>
    <app-overlay-container-v2>
      <div class="container">
        <app-filter-header
          [filterName]="COMMON_STRINGS.filter.filterBy"
          (clear)="clear()"
          [disableClear]="filter() === null" />

        <div class="body">
          <app-dropdown
            [ngModel]="filter()"
            (ngModelChange)="filter.set($event)"
            [placeholder]="COMMON_STRINGS.filter.columnFiltersPlaceholder">
            @for (group of filters(); track group.groupKey) {
              <app-dropdown-option-group>
                <span appDropdownGroupLabel>{{ group.groupName }}</span>

                @for (filterOption of group.filters; track i; let i = $index) {
                  <app-dropdown-option
                    [disabled]="filterOption.disabled"
                    [value]="filterOption"
                    [label]="
                      !!filterOption.unit
                        ? filterOption.name + ' (' + filterOption.unit + ')'
                        : filterOption.name
                    " />
                }
              </app-dropdown-option-group>
            }
          </app-dropdown>

          @if (filter(); as currentFilter) {
            <div class="filter-column-container">
              @switch (filterUiModel()) {
                @case (FILTER_TYPES.FILTER_NUMBER) {
                  <app-filter-number
                    [filter]="filter()"
                    (filtered)="apply($event)"
                    (canceled)="cancel()" />
                }

                @case (FILTER_TYPES.FILTER_DATE) {
                  <app-filter-date
                    [filter]="filter()"
                    (filtered)="apply($event)"
                    (canceled)="cancel()" />
                }

                @case (FILTER_TYPES.FILTER_LIST) {
                  <app-filter-restricted
                    [filter]="filter()"
                    (filtered)="apply($event)"
                    (canceled)="cancel()" />
                }

                @case (FILTER_TYPES.FILTER_BOOLEAN) {
                  <app-filter-boolean
                    [filter]="filter()"
                    (filtered)="apply($event)"
                    (canceled)="cancel()" />
                }

                @case (FILTER_TYPES.FILTER_NOT_EXISTS) {
                  <app-filter-not-exists
                    [filter]="filter()"
                    (filtered)="apply($event)"
                    (canceled)="cancel()" />
                }

                @case (FILTER_TYPES.FILTER_NULLABLE) {
                  <app-filter-nullable
                    [filter]="filter()"
                    (filtered)="apply($event)"
                    (canceled)="cancel()" />
                }

                @default {
                  <app-filter-text
                    [filter]="filter()"
                    (filtered)="apply($event)"
                    (canceled)="cancel()" />
                }
              }
            </div>
          }
        </div>
      </div>
    </app-overlay-container-v2>
  </ng-template>
</button>
