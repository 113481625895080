import { Directive } from '@angular/core';

@Directive({
  selector: '[appOverlayTitle]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'overlay-v2-title',
  },
})
export class OverlayTitleDirective {}
