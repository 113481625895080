import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Validators } from '@angular/forms';

import {
  Actions,
  ofActionDispatched,
  ofActionSuccessful,
  select,
  Store,
} from '@ngxs/store';
import { filter, map } from 'rxjs';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { ExportMetadata } from 'src/app/features/promotions-v2/model/promotions-v2.model';
import { STRINGS } from 'src/app/features/promotions-v2/model/promotions-v2.strings';
import { ConfirmActions } from 'src/app/shared/components/base-confirmation-dialog/actions/base-confirmation.actions';
import {
  BaseConfirmationDialogSize,
  ComponentConfirmActionsKeys,
} from 'src/app/shared/components/base-confirmation-dialog/model/base-confirmation-data.model';
import { ConfirmSave } from 'src/app/shared/components/confirmation-dialog/actions/confirmation.actions';
import { ExportsDialogComponent } from 'src/app/shared/components/exports-dialog/exports-dialog.component';
import { SimpleConfirmationDialogComponent } from 'src/app/shared/components/simple-confirmation-dialog/simple-confirmation-dialog.component';
import { DataTableRowForm } from 'src/app/shared/models/promotion-editor/product-promotions-data-table-row-form';
import {
  promotionUpliftOptions,
  PromotionUpliftTypes,
  WebshopProductPromotion,
} from 'src/app/shared/models/promotion/v2/promotion-v2.model';
import {
  AddFilterParam,
  AddSearchParam,
  CancelEditWebshopProductPromotions,
  ColumnsSelected,
  DeleteWebshopProductPromotion,
  EditWebshopProductPromotions,
  ExportProductPromotionsV2,
  HideColumn,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  SaveEditedWebshopProductPromotions,
  Sort,
  ToggleFilter,
  UpdateWebshopProductUpliftIncrease,
  UpdateWebshopProductUpliftType,
} from '../../../actions/product-promotions-v2.actions';
import { WebshopProductPromotionsEditorStateQueries } from '../../../state/product-promotions-v2-editor.queries';
import { WebshopProductPromotionsStateQueries } from '../../../state/product-promotions-v2.queries';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ConfirmExport } from 'src/app/shared/components/exports-dialog/actions/exports-dialog.action';
import { AddProductPromotionsStateQueries } from '../../add-product-promotion/state/add-product-promotion.queries';
import { AddProductPromotionDialogComponent } from '../../add-product-promotion/add-product-promotion-dialog.component';
import { DataTableV2Component } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.component';
import {
  ColumnFilterParam,
  ColumnHide,
  Filtering,
  Pagination,
  Search,
  Sortable,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { WebshopProductPromotionsState } from '../../../state/product-promotions-v2.state';
import { PurchaseOverlayStateQueries } from 'src/app/features/purchase-v3/components/purchase-overlay-v2/state/purchase-overlay.queries';
import { COLUMNS_ORDER } from '../../../model/data-table.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-product-promotions-v2-data-table',
  templateUrl: './product-promotions-v2-data-table.component.html',
  styleUrls: ['./product-promotions-v2-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPromotionsV2DataTableComponent
  extends DataTableV2Component<WebshopProductPromotion[]>
  implements
    Pagination,
    Search,
    Filtering,
    ColumnHide,
    Sortable,
    OnInit,
    OnDestroy
{
  @Input()
  webshopProductUuid: string;

  @Input()
  dataForm: DataTableRowForm;

  @Input()
  readOnly: boolean;

  isPopout = select(PurchaseOverlayStateQueries.isPopout);
  exportsEnabled = select(WebshopState.exportsEnabled);
  saving = select(WebshopProductPromotionsEditorStateQueries.saving);
  loading = select(WebshopProductPromotionsStateQueries.loading);
  editing = select(WebshopProductPromotionsStateQueries.editing);
  currentPage = select(WebshopProductPromotionsStateQueries.currentPage);
  totalElements = select(
    WebshopProductPromotionsStateQueries.promotionsTotalElements
  );
  pageSizeOptions = select(
    WebshopProductPromotionsStateQueries.pageSizeOptions
  );
  pageSize = select(WebshopProductPromotionsStateQueries.pageSize);
  selectableColumns = select(
    WebshopProductPromotionsStateQueries.selectableColumns
  );
  lastDisplayedColumn = select(
    WebshopProductPromotionsStateQueries.lastDisplayedColumn
  );
  displayedColumns = select(
    WebshopProductPromotionsStateQueries.displayedColumns
  );
  datasource = select(WebshopProductPromotionsStateQueries.promotions);
  filterOpened = select(WebshopProductPromotionsStateQueries.filterOpened);
  activeFilters = select(WebshopProductPromotionsStateQueries.activeFilters);
  filters = select(WebshopProductPromotionsStateQueries.filters);
  searchBy = select(WebshopProductPromotionsStateQueries.search);
  havePromotions = select(WebshopProductPromotionsStateQueries.havePromotions);
  haveUnassociatedPromotions = select(
    AddProductPromotionsStateQueries.haveUnassociatedPromotions
  );
  haveEditedPromotions = select(
    WebshopProductPromotionsEditorStateQueries.haveEditedPromotions
  );

  canRefresh = select(WebshopProductPromotionsState.canRefresh());

  exportMetadata: ExportMetadata;

  readonly UPLIFT_TYPES_OPTIONS = promotionUpliftOptions;

  readonly UPLIFT_TYPES = PromotionUpliftTypes;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_COLUMNS = STRINGS.columns.webshopProductPromotions;

  readonly TABLE_TOOLTIPS = STRINGS.tooltips;

  readonly TABLE_METADATA = STRINGS.metadata.promotions;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RELATED_STATE = WebshopProductPromotionsState;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  private _haveEditedPromotions: boolean;

  private _removeProductPromotionDialogRef: DialogRef<any>;

  constructor(
    private store: Store,
    private actions: Actions,
    private dialog: Dialog,
    private destroyRef: DestroyRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(WebshopProductPromotionsStateQueries.exportMetadata)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((metadata: ExportMetadata) => {
        this.exportMetadata = metadata;
      });

    this.actions
      .pipe(
        ofActionDispatched(ConfirmActions),
        filter(
          (action: ConfirmActions) =>
            action.actionKey ===
            ComponentConfirmActionsKeys.REMOVE_PRODUCT_PROMOTION
        ),
        map((actions: ConfirmActions) => actions.actionData),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        (data: { promotionUuid: string; webshopProductUuid: string }) => {
          this.store.dispatch(
            new DeleteWebshopProductPromotion(
              data.promotionUuid,
              data.webshopProductUuid,
              this._removeProductPromotionDialogRef
            )
          );
        }
      );

    this._haveEditedPromotions = this.haveEditedPromotions();

    this.actions
      .pipe(
        ofActionSuccessful(ConfirmSave),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        if (this._haveEditedPromotions) {
          this.saveEditedPromotions();
        } else {
          this.cancelEditPromotions();
        }
      });

    this.actions
      .pipe(
        ofActionSuccessful(ConfirmExport),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ exportData }) => {
        this.store.dispatch(new ExportProductPromotionsV2(exportData));
      });
  }

  refreshDatatable(): void {
    this.store.dispatch(new ReloadDatatable());
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onSort(sort: Sorted): void {
    this.store.dispatch(new Sort(sort));
  }

  onColumnHidden(columnKey: string) {
    this.store.dispatch(new HideColumn(columnKey));
  }

  addSearchParam(param: string) {
    this.store.dispatch(new AddSearchParam(param));
  }

  removeSearchParam(param: string) {
    this.store.dispatch(new RemoveSearchParam(param));
  }

  toggleFilter(): void {
    this.store.dispatch(new ToggleFilter());
  }

  clearAllFilters(): void {
    this.store.dispatch(new RemoveAllFilters());
  }

  onFiltered(params: ColumnFilterParam): void {
    this.store.dispatch(new AddFilterParam(params));
  }

  openExportsDialog(): void {
    this.dialog.open(ExportsDialogComponent, {
      width: '500px',
      data: {
        include: [],
      },
      disableClose: true,
    });
  }

  goToPromotionDetails(promotionUuid: string): void {
    this.store.dispatch(
      new NavigateToWithQueryParams(['promotions', 'details'], {
        promotionUUID: promotionUuid,
      })
    );
  }

  editPromotions(): void {
    this.store.dispatch(new EditWebshopProductPromotions());

    if (!this.datasource()?.length) return;

    this.datasource().forEach(promotion => {
      const webshopProductPromotion: WebshopProductPromotion = promotion;

      if (webshopProductPromotion.webshopProduct.specificUplift === null) {
        this.dataForm.addShared({
          uuid: webshopProductPromotion.promotion.uuid,
          uplift: {
            increase: null,
            type: PromotionUpliftTypes.NO_UPLIFT,
          },
        });

        return;
      }

      this.dataForm.addShared({
        uuid: webshopProductPromotion.promotion.uuid,
        uplift: {
          increase:
            webshopProductPromotion.webshopProduct.specificUplift?.increase,
          type: webshopProductPromotion.webshopProduct.specificUplift?.type,
        },
      });
    });
  }

  updateWebshopProductUpliftType(
    promotion: WebshopProductPromotion,
    value: string
  ): void {
    this.store.dispatch(new UpdateWebshopProductUpliftType(promotion, value));

    if (
      [PromotionUpliftTypes.RELATIVE, PromotionUpliftTypes.ABSOLUTE].includes(
        value as PromotionUpliftTypes
      )
    ) {
      this.dataForm.formSource.shared
        .get(promotion.promotion.uuid)
        .get('specificUpliftIncrease')
        .addValidators([Validators.required]);
    } else {
      this.dataForm.formSource.shared
        .get(promotion.promotion.uuid)
        .get('specificUpliftIncrease')
        .removeValidators([Validators.required]);
    }

    this.dataForm.formSource.shared
      .get(promotion.promotion.uuid)
      .get('specificUpliftIncrease')
      .updateValueAndValidity();
  }

  updateWebshopProductUpliftIncrease(
    promotion: WebshopProductPromotion,
    value: string
  ): void {
    this.store.dispatch(
      new UpdateWebshopProductUpliftIncrease(promotion, value)
    );
  }

  addPromotion(): void {
    this.dialog.open(AddProductPromotionDialogComponent, {
      width: '700px',
      disableClose: true,
      data: {
        webshopProductUuid: this.webshopProductUuid,
      },
    });
  }

  removePromotion(promotionUuid: string, webshopProductUuid: string): void {
    this._removeProductPromotionDialogRef = this.dialog.open(
      SimpleConfirmationDialogComponent,
      {
        width: BaseConfirmationDialogSize,
        disableClose: true,
        data: {
          title: $localize`Remove promotion?`,
          body: $localize`Are you sure you want to <strong>remove this promotion</strong>?<br>This action cannot be undone.`,
          actionName: $localize`Remove promotion`,
          actionType: 'danger',
          actionKey: ComponentConfirmActionsKeys.REMOVE_PRODUCT_PROMOTION,
          actionData: {
            promotionUuid,
            webshopProductUuid,
          },
        },
      }
    );
  }

  cancelEditPromotions(): void {
    this.dataForm.reset();

    this.store.dispatch(new CancelEditWebshopProductPromotions());
  }

  saveEditedPromotions(): void {
    this.store.dispatch(new SaveEditedWebshopProductPromotions());
  }

  ngOnDestroy(): void {
    this.store.dispatch(new CancelEditWebshopProductPromotions());
  }
}
