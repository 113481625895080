import { InjectionToken } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DatatableParam,
  SelectableColumnType,
} from '../../../model/data-table-v2.model';
import { ServiceRequestInfoV3FilteringType } from '../../../model/pageable-v2.model';
import { FilterContainerTemplateComponent } from '../components/filter-container-template/filter-container-template.component';
import { FilterOptionV2 } from './filter-v2.model';

export const FILTER_COMPONENT = new InjectionToken('Filter');

export const FILTER_CONTAINER =
  new InjectionToken<FilterContainerTemplateComponent>('Filter Container');

/**
 * Filter validations.
 *
 * Only has a single min/max validation useful for equals and range.
 *
 * If needed, we separate them into independent validations to
 * more complex validations.
 */
export interface FilterValidations {
  min: number | string | Date | null;
  max: number | string | Date | null;
  step: number | null;
}

export interface FilterOption {
  key: string;
  value: any;
}

export interface Filter {
  name: string | null;
  key: string;
  unit?: string;
  uiModel: SelectableColumnType;
  validations: FilterValidations;
  options?: FilterOptionV2[];
  params: DatatableParam;
  disabled: boolean;
  nullable?: boolean;
  sharedFilter?: boolean;
  partialMatch?: boolean;
}

export interface ColumnFilterGroup {
  groupKey: string;
  groupName: string;
  filters: ColumnFilter[];
}

export interface ColumnFilter extends Filter {
  columnKey: string;
}

export enum FilterTypes {
  FILTER_TEXT = 'text',
  FILTER_NUMBER = 'number',
  FILTER_DATE = 'date',
  FILTER_LIST = 'list',
  FILTER_NOT_EXISTS = 'not_exists',
  FILTER_IS_NULL = 'is_null',
  FILTER_BOOLEAN = 'boolean',
  FILTER_TIME = 'time',
  FILTER_NULLABLE = 'nullable',
}

export type FilterValueType =
  | ServiceRequestInfoV3FilteringType.STRING
  | ServiceRequestInfoV3FilteringType.BOOLEAN
  | ServiceRequestInfoV3FilteringType.INTEGER
  | ServiceRequestInfoV3FilteringType.FLOAT
  | ServiceRequestInfoV3FilteringType.DATETIME
  | ServiceRequestInfoV3FilteringType.TIME
  | ServiceRequestInfoV3FilteringType.NOT_EXISTS
  | ServiceRequestInfoV3FilteringType.IS_NULL
  | ServiceRequestInfoV3FilteringType.NULLABLE;

export enum FilterTypeOptions {
  EQUALS = 'equals',
  RANGED = 'ranged',
  NOT_EXISTS = 'not_exists',
  IS_NULL = 'is_null',
  NULLABLE = 'nullable',
}

export type FilterTypesOptions =
  | FilterTypeOptions.EQUALS
  | FilterTypeOptions.RANGED
  | FilterTypeOptions.NOT_EXISTS
  | FilterTypeOptions.IS_NULL
  | FilterTypeOptions.NULLABLE;

export interface FilterEqualsGroup {
  to: FormControl<string>;
}

export interface FilterRangeGroup {
  from: FormControl<string>;
  to: FormControl<string>;
}

export interface FilterOptionsForm {
  equals: FormGroup<FilterEqualsGroup>;
  ranged: FormGroup<FilterRangeGroup>;
}
