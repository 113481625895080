<ng-container *ngIf="!!value">
  <form #form="ngForm" class="filter-equals-option-form">
    <label for="to">{{ COMMON_STRINGS.filter.optionTypes.equals }}</label>

    <div class="optiply-filled-datepicker">
      <input
        id="to"
        name="to"
        [min]="min"
        [max]="max"
        [matDatepicker]="picker1"
        (click)="picker1.open()"
        [(ngModel)]="value.to"
        placeholder="-"
        autocomplete="off"
        readonly />

      <mat-datepicker-toggle matSuffix [for]="picker1">
        <mat-icon
          matDatepickerToggleIcon
          svgIcon="calendar"
          class="datepicker-icon"></mat-icon
      ></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </div>
  </form>
</ng-container>
