import {
  ConceptOrderlinesState,
  ConceptOrderLinesStateModel,
} from './concept-orderlines.state';
import { Selector } from '@ngxs/store';
import { RESULTS_SELECTION_LIMIT_AMOUNT } from 'src/app/core/constants/global.constants';
import {
  WebshopState,
  WebshopStateModel,
} from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { BuyOrderlinesOverviewOrderUI } from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2-ui.model';
import { BuyOrderlinesOverviewOrder } from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2.model';
import { ProposedBuyOrderline } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import { OrderlinesSelection } from '../../../model/purchase-v3.model';
import { STRINGS } from '../../../model/purchase-v3.strings';
import {
  buildDisplayedColumns,
  buildSelectableColumnsList,
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { DateTime } from 'luxon';
import { COLUMNS_ORDER } from '../model/concept-orderlines-data-table.model';

export class ConceptOrderlinesStateQueries {
  @Selector([ConceptOrderlinesState])
  static sessionUuid(state: ConceptOrderLinesStateModel): string {
    return state.sessionUuid;
  }

  @Selector([ConceptOrderlinesState])
  static loading(state: ConceptOrderLinesStateModel): boolean {
    return state.loading;
  }

  @Selector([ConceptOrderlinesState])
  static refreshState(state: ConceptOrderLinesStateModel): boolean {
    return state.disableRefreshButton;
  }
  @Selector([ConceptOrderlinesState])
  static orderLines(
    state: ConceptOrderLinesStateModel
  ): ProposedBuyOrderline[] {
    return state.orderLines;
  }

  @Selector([ConceptOrderlinesState])
  static totalElements(state: ConceptOrderLinesStateModel): number {
    return state.page.totalElements;
  }

  @Selector([ConceptOrderlinesState])
  static overview(
    state: ConceptOrderLinesStateModel
  ): BuyOrderlinesOverviewOrder {
    return state.overview;
  }

  @Selector([
    ConceptOrderlinesState,
    WebshopState,
    PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.PURCHASE),
  ])
  static featuredColumns(
    state: ConceptOrderLinesStateModel,
    webshopState: WebshopStateModel,
    viewOnly: boolean
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    const containersEnabled =
      webshopState.selectedWebshop.settings.enableContainers;

    const enableProductMaximumStockLevel =
      webshopState.selectedWebshop.settings.enableProductMaximumStockLevel;

    const promotionsEnabled =
      webshopState.selectedWebshop.settings.enablePromotions;

    const enabledAdvancedCategorization =
      webshopState.selectedWebshop.settings.enableAdvancedCategorization;

    const viewOnlyExcludeColumns = [
      STRINGS.columns.purchaseEditor.SELECTION.key,
      STRINGS.columns.purchaseEditor.ACTIONS.key,
    ];

    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        return {
          ...group,
          columns: Object.values(group.columns)
            .filter((column: DatatableColumnV2) =>
              !containersEnabled
                ? !STRINGS.columns.purchaseEditor[column.name]?.containers
                : column
            )
            .filter((column: DatatableColumnV2) =>
              !promotionsEnabled
                ? !STRINGS.columns.purchaseEditor[column.name]?.promotions
                : column
            )
            .filter((column: DatatableColumnV2) =>
              !enableProductMaximumStockLevel
                ? !STRINGS.columns.purchaseEditor[column.name]?.maximumStock
                : column
            )
            .filter((column: DatatableColumnV2) =>
              !enabledAdvancedCategorization
                ? STRINGS.columns.purchaseEditor[column.name].key !==
                  STRINGS.columns.purchaseEditor.XYZ_CATEGORY.key
                : column
            )
            .filter((column: DatatableColumnV2) =>
              viewOnly ? !viewOnlyExcludeColumns.includes(column.name) : column
            ),
        };
      }
    );
  }

  @Selector([ConceptOrderlinesStateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([ConceptOrderlinesStateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([ConceptOrderlinesState])
  static applyColumnSelectionToAll(
    state: ConceptOrderLinesStateModel
  ): boolean {
    return state.applySameColumnsToAll;
  }

  @Selector([ConceptOrderlinesStateQueries.displayedColumns])
  static lastDisplayedColumn(displayedColumns: string[]) {
    return (
      displayedColumns.filter(
        (column: string) =>
          ![
            STRINGS.columns.purchaseEditor.SELECTION.key,
            STRINGS.columns.purchaseEditor.ACTIONS.key,
          ].includes(column)
      ).length === 1
    );
  }

  @Selector([ConceptOrderlinesState])
  static page(state: ConceptOrderLinesStateModel): Pageable {
    return state.page;
  }

  @Selector([ConceptOrderlinesState])
  static pageSizeOptions(state: ConceptOrderLinesStateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([ConceptOrderlinesState])
  static pageSize(state: ConceptOrderLinesStateModel): number {
    return state.pagination.size;
  }

  @Selector([ConceptOrderlinesState])
  static currentPage(state: ConceptOrderLinesStateModel): number {
    return state.pagination.page;
  }

  @Selector([ConceptOrderlinesState, WebshopState])
  static excludedFeaturedFiltersKeys(
    state: ConceptOrderLinesStateModel,
    webshopState: WebshopStateModel
  ): string[] {
    const containersEnabled =
      webshopState.selectedWebshop.settings.enableContainers;

    const promotionsEnabled =
      webshopState.selectedWebshop.settings.enablePromotions;

    const enableProductMaximumStockLevel =
      webshopState.selectedWebshop.settings.enableProductMaximumStockLevel;

    const enabledAdvancedCategorization =
      webshopState.selectedWebshop.settings.enableAdvancedCategorization;

    return Object.values(state.filtersGroups)
      .map(group =>
        Object.keys(group.columns).map(columnKey => {
          if (
            !containersEnabled &&
            STRINGS.columns.purchaseEditor[columnKey]?.containers
          ) {
            return columnKey;
          }

          if (
            !promotionsEnabled &&
            STRINGS.columns.purchaseEditor[columnKey]?.promotions
          ) {
            return columnKey;
          }

          if (
            !enableProductMaximumStockLevel &&
            STRINGS.columns.purchaseEditor[columnKey]?.maximumStock
          ) {
            return columnKey;
          }

          if (
            !enabledAdvancedCategorization &&
            columnKey === STRINGS.columns.purchaseEditor.XYZ_CATEGORY.key
          ) {
            return columnKey;
          }

          return null;
        })
      )
      .flat()
      .filter(key => !!key);
  }

  @Selector([
    ConceptOrderlinesState,
    WebshopState.webshopCurrencySymbol,
    ConceptOrderlinesStateQueries.excludedFeaturedFiltersKeys,
  ])
  static filters(
    state: ConceptOrderLinesStateModel,
    currencySymbol: string,
    excludedFeaturedFiltersKeys: string[]
  ): ColumnFilterGroup[] {
    return Object.entries(state.filtersGroups)
      .map(([groupKey, group]) => {
        return {
          groupKey,
          groupName: group.groupName,
          filters: Object.entries(group.columns)
            .filter(([key]) => !excludedFeaturedFiltersKeys.includes(key))
            .map(([key, filter]) => {
              if (STRINGS.columns.purchaseEditor[filter.key]?.currency) {
                return {
                  columnKey: key,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey: key,
                ...filter,
              };
            })
            .filter((filter: Filter) => !filter.disabled),
        };
      })
      .filter(group => !!group.filters.length);
  }

  @Selector([ConceptOrderlinesState, WebshopState.webshopCurrencySymbol])
  static activeFilters(
    state: ConceptOrderLinesStateModel,
    currencySymbol: string
  ): ColumnFilter[] {
    const filters = Object.values(state.filtersGroups).map(
      group => group.columns
    );

    return filters
      .map(filter => {
        return (
          Object.entries(filter)
            .map(([columnKey, filter]) => {
              if (STRINGS.columns.purchaseEditor[filter.key]?.currency) {
                return {
                  columnKey,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey,
                ...filter,
              };
            })
            .filter((filter: Filter) => filter.params.value) || []
        );
      })
      .flat();
  }

  @Selector([ConceptOrderlinesState])
  static search(state: ConceptOrderLinesStateModel): string[] {
    return state.search || [];
  }

  @Selector([ConceptOrderlinesState])
  static filterOpened(state: ConceptOrderLinesStateModel): boolean {
    return state.filterOpened;
  }

  @Selector([ConceptOrderlinesState])
  static orderlineOverview(
    state: ConceptOrderLinesStateModel
  ): BuyOrderlinesOverviewOrderUI | null {
    if (!state.overview) return null;

    return {
      supplierInfo: {
        supplierId: Number(state.overview.supplier.id),
        supplierUuid: state.overview.supplier.uuid,
        supplierName: state.overview.supplier.name,
        orderMomentDate: state.overview.orderMoment?.date ?? null,
        orderMomentUuid: state.overview.orderMoment?.uuid ?? null,
        orderMomentType: state.overview.orderMoment?.type ?? null,
        orderMomentId: state.overview.orderMoment
          ? Number(state.overview.orderMoment?.id)
          : null,
        orderMomentTriggeredValue:
          state.overview.orderMoment?.triggeredValue ?? null,
        minimalOrderValue: state.overview.supplier.minimalOrderValue,
        planningTrigger: state.overview.planningTrigger,
        deliveryTime: state.overview.supplier.deliveryTime,
        leadTimeMeanActual: state.overview.supplier.leadTimeMeanActual,
        effectiveReplenishmentPeriod:
          state.overview.supplier.effectiveReplenishmentPeriod,
      },
      containerInfo: {
        totalWeight:
          state.overview.container?.totalWeight !== null
            ? Number(state.overview.container?.totalWeight.toFixed(2))
            : null,
        containerTotalWeight:
          state.overview.supplier.containerWeightCapacity !== null
            ? Number(state.overview.supplier.containerWeightCapacity.toFixed(2))
            : null,
        totalVolume:
          state.overview.container?.totalVolume !== null
            ? Number(state.overview.container?.totalVolume.toFixed(2))
            : null,
        containerTotalVolume:
          state.overview.supplier.containerVolumeCapacity !== null
            ? Number(state.overview.supplier.containerVolumeCapacity.toFixed(2))
            : null,
        containersTotal: state.overview.container?.numberOfContainers ?? null,
      },
      totalsInfo: {
        minimalOrderValue: state.overview.supplier.minimalOrderValue,
        totalValue: state.overview.totalValue,
        totalProducts: state.overview.numberOfProducts,
      },
    };
  }

  @Selector([ConceptOrderlinesState])
  static supplierId(state: ConceptOrderLinesStateModel): string {
    return state.overview.supplier.id;
  }

  @Selector([ConceptOrderlinesState])
  static supplierUuid(state: ConceptOrderLinesStateModel): string {
    return state.overview.supplier.uuid;
  }

  @Selector([ConceptOrderlinesState])
  static invalidBuyOrder(state: ConceptOrderLinesStateModel): boolean {
    return state.orderLines.length === 0;
  }

  @Selector([ConceptOrderlinesState])
  static selection(
    state: ConceptOrderLinesStateModel
  ): TableSelection<OrderlinesSelection> {
    return state.selection;
  }

  @Selector([ConceptOrderlinesState])
  static isOrderlineSelected(state: ConceptOrderLinesStateModel) {
    const selectedUuids = new Set(Object.keys(state.selection));

    return (uuid: string) => selectedUuids.has(uuid);
  }

  @Selector([
    ConceptOrderlinesState,
    ConceptOrderlinesStateQueries.totalElements,
  ])
  static areAllSelected(
    state: ConceptOrderLinesStateModel,
    totalElements: number
  ): boolean {
    return (
      !!totalElements && Object.keys(state.selection).length === totalElements
    );
  }

  @Selector([
    ConceptOrderlinesState,
    ConceptOrderlinesStateQueries.totalElements,
  ])
  static areSomeSelected(
    state: ConceptOrderLinesStateModel,
    totalElements: number
  ): boolean {
    return (
      !!totalElements &&
      !!Object.keys(state.selection).length &&
      Object.keys(state.selection).length < totalElements
    );
  }

  @Selector([ConceptOrderlinesStateQueries.selection])
  static selectedAmount(
    selection: TableSelection<OrderlinesSelection>
  ): number {
    return Object.keys(selection).length;
  }

  @Selector([ConceptOrderlinesStateQueries.selectedAmount])
  static limitSelection(selectedAmount: number): boolean {
    return selectedAmount >= RESULTS_SELECTION_LIMIT_AMOUNT;
  }

  @Selector([ConceptOrderlinesState])
  static disableActions(state: ConceptOrderLinesStateModel): boolean {
    return state.disableActions;
  }

  @Selector([ConceptOrderlinesState])
  static expectedDeliveryDateCalc(state: ConceptOrderLinesStateModel): Date {
    return DateTime.fromISO(new Date().toISOString())
      .plus({ days: state.overview.supplier.deliveryTime })
      .toJSDate();
  }
}
