import { Selector } from '@ngxs/store';
import {
  EmailManagementState,
  EmailManagementStateModel,
} from './email-management.state';

export class EmailManagementStateQueries {
  @Selector([EmailManagementState])
  static orderEmails(state: EmailManagementStateModel): string[] {
    return state.orderEmails ?? [];
  }
}
