import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Supplier } from 'src/app/shared/models/suppliers/product-suppliers.model';

import { SupplierDetails } from '../model/product-details.model';

export class ProductDetailsAdapter
  implements DataAdapter<SupplierDetails, Supplier>
{
  transform(data: SupplierDetails): Supplier {
    if (!data.supplier) return null;

    const newSupplier: Supplier = {
      supplierId: data.supplier.id,
      supplierUuid: data.supplier.uuid,
      deliveryTime: data.supplier.deliveryTime,
      effectiveReplenishmentPeriod: data.supplier.effectiveReplenishmentPeriod,
      name: data.supplier.name,
      cycle: data.supplier.cycle,
      deliveryTimeDeviation: data.supplier.deliveryTimeDeviation,
      safetyStock: data.supplier.safetyStock,
      supplierProduct: null,
    };

    if (data.supplier.supplierProduct != null) {
      newSupplier.supplierProduct = {
        id: data.supplier.supplierProduct.id,
        supplierProductUuid: data.supplier.supplierProduct.uuid,
        weight: data.supplier.supplierProduct.weight,
        volume: data.supplier.supplierProduct.volume,
        price: data.supplier.supplierProduct.price,
        articleCode: data.supplier.supplierProduct.articleCode,
        minimumPurchaseQuantity:
          data.supplier.supplierProduct.minimumPurchaseQuantity,
        preferred: data.supplier.supplierProduct.preferred,
        active: data.supplier.supplierProduct.active,
        lotSize: data.supplier.supplierProduct.lotSize,
        webshopProduct: null,
        prediction: null,
        deliveryTime: data.supplier.supplierProduct.deliveryTime,
      };

      if (data.supplier.supplierProduct.webshopProduct != null) {
        newSupplier.supplierProduct.webshopProduct = {
          priceMargin: data.supplier.supplierProduct.webshopProduct.priceMargin,
        };
      }

      if (data.supplier.supplierProduct.productSLevel != null) {
        newSupplier.supplierProduct.prediction = {
          orderUpToLevel:
            data.supplier.supplierProduct.productSLevel.orderUpToLevel,
        };
      }
    }

    return newSupplier;
  }
}
