import { ChangeDetectionStrategy, Component } from '@angular/core';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import {
  CancelNotes,
  SaveNotes,
  UpdateNotes,
} from 'src/app/features/products-v2/components/product-details-v2/actions/product-details-v2.actions';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';

@Component({
  selector: 'app-product-details-overview-notes',
  templateUrl: './product-details-overview-notes.component.html',
  styleUrl: './product-details-overview-notes.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsOverviewNotesComponent {
  @Select(PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.PRODUCTS))
  viewOnly$: Observable<string>;

  @Select(ProductDetailsV2StateQueries.settingsNotes)
  notes$: Observable<string>;

  @Select(ProductDetailsV2StateQueries.saveEnabled)
  saveEnabled$: Observable<string>;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly METADATA = STRINGS.metadata.overview.notesInfo;

  readonly COMMON_STRINGS = MESSAGES;

  constructor(private store: Store) {}

  updateNotes(value: string): void {
    this.store.dispatch(new UpdateNotes(value));
  }

  saveNotes(): void {
    this.store.dispatch(new SaveNotes());
  }

  cancelNotes(): void {
    this.store.dispatch(new CancelNotes());
  }
}
