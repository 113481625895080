<div
  class="container"
  (click)="$event.stopPropagation()"
  (keydown.enter)="onApply()">
  <ng-container
    [ngTemplateOutlet]="headerTemplate() || defaultHeaderTemplate" />

  <div class="body">
    <ng-content />
  </div>

  <ng-container
    [ngTemplateOutlet]="footerTemplate() || defaultFooterTemplate" />
</div>

<ng-template #defaultHeaderTemplate>
  <app-filter-header
    [filterName]="filterName()"
    (clear)="clear.emit()"
    [disableClear]="disableClear()" />
</ng-template>

<ng-template #defaultFooterTemplate>
  <app-filter-footer
    [showApplyAll]="showApplyAll()"
    [(applyAll)]="applyAll"
    (cancel)="cancel.emit()"
    (apply)="onApply()"
    [disableApply]="disableApply()" />
</ng-template>
