import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import {
  DefaultOverlayV2Config,
  OVERLAY_CONFIG,
  OverlayAlignment,
} from '../model/overlay-container-v2.model';

@Directive({ selector: '[appOverlayBaseV2]' })
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class OverlayBaseV2 implements OnDestroy {
  @Input()
  alignment: OverlayAlignment;

  @Input({ transform: numberAttribute })
  width: number;

  @Input({ transform: numberAttribute })
  minWidth: number;

  @Input({ transform: numberAttribute })
  maxWidth: number;

  @Input({ transform: numberAttribute })
  height: number;

  @Input({ transform: numberAttribute })
  minHeight: number;

  @Input({ transform: numberAttribute })
  maxHeight: number;

  /**
   * If overlay has backdrop (always true to be able to close the overlay without additional logic).
   *
   * ONLY change if really needed for a specific use-case.
   */
  @Input({ transform: booleanAttribute })
  hasBackdrop: boolean;

  /**
   * Overlay's backdrop class (used if hasBackdrop = true)
   */
  @Input()
  backdropClass: string;

  /**
   * Overlay's panel's class.
   *
   * Use the global styles to style it.
   */
  @Input()
  panelClass: string | string[];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'overlayDisabled', transform: booleanAttribute })
  disabled: boolean;

  @Output()
  outsideClicked: EventEmitter<void> = new EventEmitter<void>();

  protected overlayRef: OverlayRef;

  private _destroyRef: Subject<void> = new Subject<void>();

  constructor(
    @Inject(OVERLAY_CONFIG)
    public defaultConfig: DefaultOverlayV2Config,
    public el: ElementRef,
    public overlay: Overlay
  ) {
    if (this.defaultConfig) {
      this._applyDefaultConfig();
    }

    fromEvent(this.el.nativeElement, 'click')
      .pipe(takeUntil(this._destroyRef))
      .subscribe(() => {
        this._handleClick();
      });
  }

  _createOverlay(): OverlayRef {
    return this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.el)
        .withViewportMargin(8)
        .withPositions([
          {
            originX: this.alignment,
            originY: 'bottom',
            overlayX: this.alignment,
            overlayY: 'top',
          },
          {
            originX: this.alignment,
            originY: 'top',
            overlayX: this.alignment,
            overlayY: 'bottom',
          },
        ])
        .withFlexibleDimensions(true),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      height: this.height,
      minHeight: this.minHeight,
      maxHeight:
        this.minHeight > this.maxHeight ? this.minHeight : this.maxHeight,
      width: this.width,
      minWidth: this.minWidth,
      maxWidth: this.minWidth > this.maxWidth ? this.minWidth : this.maxWidth,
      hasBackdrop: this.hasBackdrop,
      backdropClass: this.backdropClass,
      panelClass: this.panelClass,
    });
  }

  _handleClick(): void {
    this.overlayRef = this._createOverlay();

    this.overlayRef
      ?.backdropClick()
      .pipe(takeUntil(this._destroyRef))
      .subscribe(() => {
        this.outsideClicked.emit();

        this._closeOverlay();
      });
  }

  _closeOverlay(): void {
    if (!this.overlayRef) return;

    this.overlayRef.detach();
  }

  /**
   * Updates overlay position.
   */
  _updatePosition() {
    this.overlayRef.updatePosition();
  }

  private _applyDefaultConfig(): void {
    this.hasBackdrop = this.defaultConfig.hasBackdrop;
    this.backdropClass = this.defaultConfig.backdropClass;
    this.minWidth = this.defaultConfig.minWidth;
    this.maxWidth = this.defaultConfig.maxWidth;
    this.alignment = this.defaultConfig.alignment;

    if (this.width) {
      this.width = this.defaultConfig.width;
    }

    if (this.height) {
      this.height = this.defaultConfig.height;
    }

    if (this.minHeight) {
      this.minHeight = this.defaultConfig.minHeight;
    }

    if (this.maxHeight) {
      this.maxHeight = this.defaultConfig.maxHeight;
    }

    if (this.panelClass) {
      this.panelClass = this.defaultConfig.panelClass;
    }
  }

  ngOnDestroy(): void {
    this._destroyRef.next();
    this._destroyRef.complete();
  }
}
