import {
  booleanAttribute,
  Directive,
  EventEmitter,
  input,
  Output,
} from '@angular/core';
import { Sorted } from '../../sort/model/sort.model';
import { ColumnFilterParam } from '../../../model/data-table-v2.model';
import { FilterableV2 } from '../model/filter-v2.model';

@Directive({
  selector: '[appFilterableV2]',
})
export class FilterableV2Directive {
  /**
   * Disables sort for every column
   */
  disableSort = input(false, {
    transform: booleanAttribute,
  });

  /**
   * Hides sort for every column
   */
  hideSort = input(false, {
    transform: booleanAttribute,
  });

  /**
   * Disables hide column for every displayed column
   */
  disableHideColumn = input(false, {
    transform: booleanAttribute,
  });

  /**
   * Hides hide column for every displayed column
   */
  hideHideColumn = input(false, {
    transform: booleanAttribute,
  });

  /**
   * Hides filter for every displayed column
   */
  hideFilter = input(false, {
    transform: booleanAttribute,
  });

  @Output()
  sorted: EventEmitter<Sorted> = new EventEmitter<Sorted>();

  @Output()
  hidden: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  filtered: EventEmitter<ColumnFilterParam> =
    new EventEmitter<ColumnFilterParam>();

  columns = new Map<string, FilterableV2>();

  /**
   * Registers column to be filtered
   * @param filterable
   */
  register(filterable: FilterableV2): void {
    if (filterable === undefined) {
      throw new Error(`Undefined column cannot be registered to be filtered`);
    }

    if (this.columns.has(filterable.key())) {
      throw new Error(
        `Column ${filterable.key()} was already registered to be filtered`
      );
    }

    this.columns.set(filterable.key(), filterable);
  }

  /**
   * Removes column from being filtered (eg. when component's destroyed or column not visible)
   * @param filterable
   */
  deregister(filterable: FilterableV2): void {
    this.columns.delete(filterable.key());
  }

  sort(sorted: Sorted): void {
    this.sorted.emit(sorted);
  }

  filter(filtered: ColumnFilterParam): void {
    this.filtered.emit(filtered);
  }

  hide(columnKey: string): void {
    this.hidden.emit(columnKey);
  }
}
