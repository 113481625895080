export interface InitialData {
  [key: string]: any;
}

export interface HistoryData {
  history: HistoryChangeData[];
}

export interface HistoryChangeData {
  data: InitialData;
  active: boolean;
}

export enum PendingChangesKeys {
  ORDER = 'ORDER',
  BUY_ORDER = 'BUY_ORDER',
  CONCEPT_ORDER = 'CONCEPT_ORDER',
  MANUAL_ORDER = 'MANUAL_ORDER',
  OVERLAY_SUPPLIERS = 'OVERLAY_SUPPLIERS',
  OVERLAY_SUPPLIERS_GENERAL = 'OVERLAY_SUPPLIERS_GENERAL',
  OVERLAY_SUPPLIERS_PLANNING = 'OVERLAY_SUPPLIERS_PLANNING',
  OVERLAY_SUPPLIERS_OTHER = 'OVERLAY_SUPPLIERS_OTHER',
  OVERLAY_PRODUCTS = 'OVERLAY_PRODUCTS',
  DELIVERIES = 'DELIVERIES',
  SETTINGS = 'SETTINGS',
  SETTINGS_CLASSIFICATION = 'SETTINGS_CLASSIFICATION',
  SETTINGS_ADVANCED_CATEGORIZATION = 'SETTINGS_ADVANCED_CATEGORIZATION',
  SETTINGS_ALGORITHM = 'SETTINGS_ALGORITHM',
  SETTINGS_GENERAL = 'SETTINGS_GENERAL',
  SETTINGS_USER_MANAGEMENT = 'SETTINGS_USER_MANAGEMENT',
  SETTINGS_EMAIL = 'SETTINGS_EMAIL',
  SETTINGS_EMAIL_TEMPLATES = 'SETTINGS_EMAIL_TEMPLATES',
  PROMOTIONS_DETAILS = 'PROMOTIONS_DETAILS',
  DELIVERIES_UNDELIVERED = 'DELIVERIES_UNDELIVERED',
  DELIVERIES_DELIVERED = 'DELIVERIES_DELIVERED',
  PROMOTION = 'PROMOTION',
}

/* Not in use yet */
export enum PendingChangesComponentPriority {
  ORDER = 1,
  BUY_ORDER = 1,
  CONCEPT_ORDER = 1,
  MANUAL_ORDER = 1,
  OVERLAY_SUPPLIERS = 2,
  OVERLAY_PRODUCTS = 2,
  DELIVERIES = 1,
  SETTINGS = 1,
  SETTINGS_CLASSIFICATION = 1,
  SETTINGS_ALGORITHM = 1,
  SETTINGS_DYNAMIC_PLANNING = 1,
  SETTINGS_GENERAL = 1,
}
