import {
  EditedLinkedSupplierProduct,
  LinkedSupplierProductsEditorListener,
} from './editor.model';

export abstract class BaseLinkedSupplierProductsEditorState<T> {
  constructor(
    private linkedSupplierProductsListener: LinkedSupplierProductsEditorListener<T>
  ) {}

  /**
   * Removes a supplier from the editor .
   * @param supplierId the supplier id to filter out from the array.
   * @param linkedSupplierProducts the edited links products immutable array.
   */
  protected _filterSupplierOut(
    supplierId: number,
    linkedSupplierProducts: EditedLinkedSupplierProduct[]
  ): EditedLinkedSupplierProduct[] {
    return linkedSupplierProducts.filter(
      link => link.supplierId !== supplierId
    );
  }

  /**
   * Removes a linked supplier product from the editor .
   * @param supplierId the supplier id to filter out from the array.
   * @param linkedSupplierProducts the linked supplier products immutable array.
   */
  protected _getLinkIdx(
    supplierId: number,
    linkedSupplierProducts: EditedLinkedSupplierProduct[]
  ): number {
    return linkedSupplierProducts.findIndex(
      supplierProduct => supplierProduct.supplierId === supplierId
    );
  }

  /**
   * Returns the edited linked supplier products for a specific supplier.
   * @param supplierId the supplier id to search for.
   * @param editedLinkedSupplierProducts the array of edited linked supplier products.
   * @returns one or more edited linked suppliers products. More than one line is considered an error.
   */
  protected _getSupplierLine(
    supplierId: number,
    editedLinkedSupplierProducts: EditedLinkedSupplierProduct[]
  ): EditedLinkedSupplierProduct[] {
    return editedLinkedSupplierProducts.filter(
      supplierProduct => supplierProduct.supplierId === supplierId
    );
  }

  /* Preferred */
  protected _buildUpdatePreferredNewSupplier(
    supplierProduct: T,
    newPreferred: boolean
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      preferred: newPreferred,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdatePreferredExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newPreferred: boolean
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      preferred: newPreferred,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* Availability */
  protected _buildUpdateAvailabilityNewSupplier(
    supplierProduct: T,
    newAvailability: boolean
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      availability: newAvailability,
      availabilityDate: null,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateAvailabilityExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newAvailability: boolean
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      availability: newAvailability,
      availabilityDate: null,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* AvailabilityDate */
  protected _buildUpdateAvailabilityDateNewSupplier(
    supplierProduct: T,
    newAvailabilityDate: string
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      availabilityDate: newAvailabilityDate,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateAvailabilityDateExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newAvailabilityDate: string
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      availabilityDate: newAvailabilityDate,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* Name */
  protected _buildUpdateSupplierProductNameNewSupplier(
    supplierProduct: T,
    newName: string
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      name: newName,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateSupplierProductNameExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newName: string
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      name: newName,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* SKU */
  protected _buildUpdateSKUNewSupplier(supplierProduct: T, newSKU: string) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      sku: newSKU,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateSKUExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newSKU: string
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      sku: newSKU,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* LotSize */
  protected _buildUpdateLotSizeNewSupplier(
    supplierProduct: T,
    newLotSize: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      purchaseInQuantitiesOf: newLotSize,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateLotSizeExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newLotSize: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      purchaseInQuantitiesOf: newLotSize,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* EanCode */
  protected _buildUpdateEANNewSupplier(supplierProduct: T, newEAN: string) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      eanCode: newEAN,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateEANExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newEAN: string
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      eanCode: newEAN,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* MOQ */
  protected _buildUpdateMOQNewSupplier(supplierProduct: T, newMOQ: number) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      minimumPurchaseQuantity: newMOQ,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateMOQExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newMOQ: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      minimumPurchaseQuantity: newMOQ,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* Delivery Time */
  protected _buildUpdateDeliveryTimeNewSupplier(
    supplierProduct: T,
    newDeliveryTime: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      deliveryTime: newDeliveryTime,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateDeliveryTimeExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newDeliveryTime: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      deliveryTime: newDeliveryTime,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* Weight */
  protected _buildUpdateWeightNewSupplier(
    supplierProduct: T,
    newWeight: number | null
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      weight: newWeight,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateWeightExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newWeight: number | null
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      weight: newWeight,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* Volume */
  protected _buildUpdateVolumeNewSupplier(
    supplierProduct: T,
    newVolume: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      volume: newVolume,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdateVolumeExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newVolume: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      volume: newVolume,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  /* Price */
  protected _buildUpdatePriceNewSupplier(supplierProduct: T, newPrice: number) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildBase(supplierProduct),
      price: newPrice,
    };

    return { newEditedSupplierProduct };
  }

  protected _buildUpdatePriceExistingEditedSupplier(
    supplierProduct: T,
    editedSupplierProduct: EditedLinkedSupplierProduct,
    editedSupplierProducts: EditedLinkedSupplierProduct[],
    newPrice: number
  ) {
    const newEditedSupplierProduct: EditedLinkedSupplierProduct = {
      ...this._buildEditedBase(editedSupplierProduct),
      price: newPrice,
    };

    const newLines = this._filterSupplierOut(
      this.linkedSupplierProductsListener.supplierId(supplierProduct),
      editedSupplierProducts
    );

    return { newEditedSupplierProduct, newLines };
  }

  public duplicatedEditedSuppliersError(supplierId: number): Error {
    throw new Error(
      `Internal error: Edited supplier products cannot be duplicated. Affected Supplier Id ${supplierId}`
    );
  }

  private _buildBase(supplierProduct: T): EditedLinkedSupplierProduct {
    return {
      webshopProductId:
        this.linkedSupplierProductsListener.webshopProductId(supplierProduct),
      name: this.linkedSupplierProductsListener.name(supplierProduct),
      sku: this.linkedSupplierProductsListener.sku(supplierProduct),
      eanCode: this.linkedSupplierProductsListener.eanCode(supplierProduct),
      weight: this.linkedSupplierProductsListener.weight(supplierProduct),
      volume: this.linkedSupplierProductsListener.volume(supplierProduct),
      price: this.linkedSupplierProductsListener.price(supplierProduct),
      deliveryTime:
        this.linkedSupplierProductsListener.deliveryTime(supplierProduct),
      minimumPurchaseQuantity:
        this.linkedSupplierProductsListener.minimumPurchaseQuantity(
          supplierProduct
        ),
      purchaseInQuantitiesOf:
        this.linkedSupplierProductsListener.purchaseInQuantitiesOf(
          supplierProduct
        ),
      supplierId:
        this.linkedSupplierProductsListener.supplierId(supplierProduct),
      availability:
        this.linkedSupplierProductsListener.availability(supplierProduct),
      availabilityDate:
        this.linkedSupplierProductsListener.availabilityDate(supplierProduct),
      preferred: this.linkedSupplierProductsListener.preferred(supplierProduct),
      currentStockOnHand:
        this.linkedSupplierProductsListener.currentStockOnHand(supplierProduct),
      status: this.linkedSupplierProductsListener.status(supplierProduct),
    };
  }

  private _buildEditedBase(
    editedSupplierProduct: EditedLinkedSupplierProduct
  ): EditedLinkedSupplierProduct {
    return {
      webshopProductId: editedSupplierProduct.webshopProductId,
      name: editedSupplierProduct.name,
      sku: editedSupplierProduct.sku,
      eanCode: editedSupplierProduct.eanCode,
      weight: editedSupplierProduct.weight,
      volume: editedSupplierProduct.volume,
      price: editedSupplierProduct.price,
      deliveryTime: editedSupplierProduct.deliveryTime,
      minimumPurchaseQuantity: editedSupplierProduct.minimumPurchaseQuantity,
      purchaseInQuantitiesOf: editedSupplierProduct.purchaseInQuantitiesOf,
      supplierId: editedSupplierProduct.supplierId,
      availability: editedSupplierProduct.availability,
      availabilityDate: editedSupplierProduct.availabilityDate,
      preferred: editedSupplierProduct.preferred,
      currentStockOnHand: editedSupplierProduct.currentStockOnHand,
      status: editedSupplierProduct.status,
    };
  }
}
