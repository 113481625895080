import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Webshops } from 'src/app/shared/models/webshop/webshops.model';
import { COUPLING_TYPE_PICQER } from './model/couplings.model';
import {
  Webshop,
  WebshopAdvancedCategorization as WebshopAdvancedCategorizationV1,
} from './model/webshop.model';

import {
  WebshopAdvancedCategorization,
  WebshopPermissions,
} from 'src/app/shared/models/webshop/webshop.model';
import { Webshops as WebshopsV1 } from './model/webshops.model';

export class WebshopsAdapter implements DataAdapter<WebshopsV1, Webshops> {
  transform(data: WebshopsV1): Webshops {
    const webshops: Webshops = { webshops: [] };

    webshops.webshops = data.webshops.map(webshop => {
      const doesNotHavePicqerCouplings =
        this._doesNotHavePicqerCouplings(webshop);

      return {
        name: webshop.handle,
        uuid: webshop.uuid,
        id: webshop.id,
        url: webshop.url,
        handle: webshop.handle,
        defaultReceiptQuantity: webshop.defaultReceiptQuantity,
        globalLocationNumber: webshop.globalLocationNumber
          ? webshop.globalLocationNumber
          : '',
        billingEmail: webshop.billingEmail ? webshop.billingEmail : '',
        contactEmail: webshop.contactEmail ? webshop.contactEmail : '',
        settings: {
          enableMultiSupplier: webshop.settings.enableMultiSupplier,
          usingJavaEndpoint: webshop.settings.usingJavaEndpoint,
          usingBuyModule: webshop.settings.usingBuyModule,
          usingOrderPlanning: webshop.settings.usingOrderPlanning,
          canEditLotSize: doesNotHavePicqerCouplings,
          canEditMoq: doesNotHavePicqerCouplings,
          reactingToLostSales: webshop.settings.reactingToLostSales,
          backorders: webshop.settings.backorders,
          currency: webshop.settings.currency,
          wholesale: webshop.settings.wholesale,
          enableSupplierEditor: webshop.settings.enableSupplierEditor,
          enablePromotions: webshop.settings.enablePromotions,
          enableContainers: webshop.settings.enableContainers,
          enableTimeDependentStockPosition:
            webshop.settings.enableTimeDependentStockPosition,
          productCategoryHistoryLength:
            webshop.settings.productCategoryHistoryLength,
          productCategoryInputData: webshop.settings.productCategoryInputData,
          yearlySeasonalityForecastAdjustment:
            webshop.settings.yearlySeasonalityForecastAdjustment,
          outlierFiltering: webshop.settings.outlierFiltering,
          enableExports: webshop.settings.enableExports,
          enableImports: webshop.settings.enableImports,
          enablePurchasePageV2: webshop.settings.enablePurchasePageV2,
          enableProductPageV3: webshop.settings.enableProductPageV3,
          enableProductMaximumStockLevel:
            webshop.settings.enableProductMaximumStockLevel,
          enableAnalyticsPage: webshop.settings.enableAnalyticsPage,
          numberOfIntegrations: webshop.settings.numberOfIntegrations,
          timeZoneIdentifier: webshop.settings.timeZoneIdentifier,
          timeZoneOffset: this._buildTimeZone(
            webshop.settings.timeZoneIdentifier
          ),
          enableAdvancedCategorization:
            webshop.settings.enableAdvancedCategorization,
          xyzProductCategoryInputData:
            webshop.settings.xyzProductCategoryInputData,
        },
        margin: {
          a: webshop.marginCatA,
          b: webshop.marginCatB,
          c: webshop.marginCatC,
        },
        serviceLevel: {
          a: webshop.serviceLevelCatA,
          b: webshop.serviceLevelCatB,
          c: webshop.serviceLevelCatC,
        },
        stockCosts: webshop.stockCosts,
        warehouseCapacity: webshop.warehouseCapacity,
        permissions: this._buildPermissions(webshop),
        permissionsOptions: {},
        advancedCategorization: this._buildAdvancedCategorization(
          webshop.advancedCategorization
        ),
        metadata: {
          lastAdvancedCategorizationServiceLevelsPatch: parseInt(
            webshop.metadata.lastAdvancedCategorizationServiceLevelsPatch
          ),
        },
      };
    });
    return webshops;
  }

  private _doesNotHavePicqerCouplings(webshop: Webshop): boolean {
    if (webshop.couplings === null) {
      return true;
    }

    const picqerCouplings = webshop.couplings.filter(
      coupling => coupling.couplingType === COUPLING_TYPE_PICQER
    );
    return picqerCouplings.length === 0;
  }

  private _buildPermissions(webshop: Webshop): WebshopPermissions {
    const permissions: WebshopPermissions = {};

    permissions['promotions'] = webshop.settings.enablePromotions;

    permissions['exports'] = webshop.settings.enableExports;

    permissions['imports'] = webshop.settings.enableImports;

    return permissions;
  }

  private _buildTimeZone(timeZoneIdentifier: string): string {
    return Intl.DateTimeFormat('en', {
      timeZoneName: 'short',
      timeZone: timeZoneIdentifier,
    })
      .formatToParts()
      .find(i => i.type === 'timeZoneName').value;
  }

  private _buildAdvancedCategorization(
    advancedCategorization: WebshopAdvancedCategorizationV1 | undefined
  ): WebshopAdvancedCategorization | null {
    if (!advancedCategorization) {
      return null;
    }

    return {
      serviceLevelCatAX: advancedCategorization.serviceLevelCatAX,
      serviceLevelCatAY: advancedCategorization.serviceLevelCatAY,
      serviceLevelCatAZ: advancedCategorization.serviceLevelCatAZ,
      serviceLevelCatBX: advancedCategorization.serviceLevelCatBX,
      serviceLevelCatBY: advancedCategorization.serviceLevelCatBY,
      serviceLevelCatBZ: advancedCategorization.serviceLevelCatBZ,
      serviceLevelCatCX: advancedCategorization.serviceLevelCatCX,
      serviceLevelCatCY: advancedCategorization.serviceLevelCatCY,
      serviceLevelCatCZ: advancedCategorization.serviceLevelCatCZ,
      marginCatX: advancedCategorization.marginCatX,
      marginCatY: advancedCategorization.marginCatY,
      marginCatZ: advancedCategorization.marginCatZ,
    };
  }
}
