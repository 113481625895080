import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingButtonComponent implements OnInit, OnDestroy {
  @Input() title: string;

  @Input() loading: Observable<boolean>;

  @Input() state:
    | 'primary'
    | 'secondary'
    | 'secondary-outline'
    | 'tertiary'
    | 'danger'
    | 'danger-outline'
    | 'warning'
    | 'warning-outline'
    | 'success'
    | 'success-outline' = 'secondary';

  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';

  @Input() disabled = true;

  @Output() event = new EventEmitter<any>();

  loading$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.loading.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      this.loading$.next(loading);
    });
  }

  ngOnDestroy(): void {
    this.loading$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }

  eventHandler(fn: any): void {
    this.event.emit(fn);
  }

  /**
   * It builds the class depending on the inputs
   * eg. state: primary / size: lg
   * returns: optiply-primary-btn-lg
   */
  public get stateClasses(): string {
    const btnSize = this.size !== 'md' ? `btn-${this.size}` : 'btn';
    return `optiply-${this.state}-${btnSize}`;
  }
}
