import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
  computed,
  input,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FilterButtonBase } from '../../filter-button-base';

@Component({
  selector: 'app-filters-toggle',
  templateUrl: './filters-toggle.component.html',
  styleUrls: ['./filters-toggle.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersToggleComponent extends FilterButtonBase {
  readonly COMMON_STRINGS = MESSAGES.common;

  @Input({ transform: booleanAttribute })
  set isOpen(value: boolean) {
    this.opened.set(value);
  }

  filtersCount = input(0);

  disabled = input(false, {
    transform: booleanAttribute,
  });

  hasFilters = computed(() => this.filtersCount() > 0);
}
