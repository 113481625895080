<span [ngClass]="bannerClasses">
  <span [ngClass]="bannerCellClasses">
    <div class="banner-label">
      <span class="banner-value">{{ content }}</span>
    </div>

    <span class="banner-icon">
      <mat-icon [svgIcon]="icon" class="icon"></mat-icon>
    </span>
  </span>

  <span *ngIf="removable" class="tag-remove">
    <button
      aria-label="Banner close button"
      class="banner-remove-btn optiply-icon-btn"
      [appTooltip]="COMMON_STRINGS.dismiss"
      (click)="dismissBanner()">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </span>
</span>
