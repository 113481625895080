export interface SupportedSource {
  tap: string;
  label: string;
  icon: string;
  type: string;
  connectedUiParams: any[];
  tapUrl?: string;
  callbackUrl?: string;
  redirectUriParams?: string;
  isOptiplyIntegration?: boolean;
  category: string;
  external?: boolean;
}

export interface SupportedSourceResponse {
  supportedSources: SupportedSource[];
  categories: string[];
}

export interface LinkedSource {
  tap: string;
  label: string;
  icon: string;
  connectedUiParams: any[];
  jobs: Job[];
  exportJobs: Job[];
  loadingJobs: boolean;
  isOptiplyIntegration?: boolean;
  tenant: number | null;
  auth_url?: string;
  bo_external_link?: string;
  current_division?: string;
}

export interface LinkedTarget {
  target: string;
  tenant: number | null;
}

export interface Job {
  name: string;
  status: string;
  startDate: string;
  lastUpdateDate: string;
  metrics?: object;
  errors?: JobError[];
  successes?: JobError[];
  totalRows?: number;
  hotglue?: boolean;
  tenant: number;
}

export interface JobError {
  buyOrderId: string;
  info: string;
}

export interface JobMetrics {
  name: string;
  value: any;
}

export interface CreateLinkedSourceProperties {
  supportedSource: SupportedSource;
  config: any;
  exactExtraInfo?: any;
}

export interface Source {
  tap: string;
  config: any;
}

export interface CreateLinkedSourcePayload {
  source: Source;
}

export interface UpdateLinkedSourcePayload {
  source: Source;
}

export interface RetrieveJobsPerTapQueryParameters {
  tenant: number;
  taps: string;
  count: number;
}

export interface RetrieveExportJobsPerTapQueryParameters {
  tenant: number;
  targets: string;
  count: number;
}

export enum JobStatus {
  EXPORT_FAILED = 'failed',
  ETL_FAILED = 'failed',
  SYNC_FAILED = 'failed',
  DISCOVER_FAILED = 'failed',
  JOB_CREATED = 'running',
  DISCOVER_STARTED = 'running',
  SYNC_STARTED = 'running',
  SYNC_SUCCESS = 'running',
  ETL_STARTED = 'running',
  ETL_SUCCESS = 'running',
  EXPORT_STARTED = 'running',
  JOB_COMPLETED = 'complete',
  finished = 'complete',
  error = 'failed',
  queued = 'queued',
}

export const manualSources: SupportedSource[] = [
  {
    tap: 'ecwid',
    label: 'Ecwid',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/ecwid.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'prestashop',
    label: 'PrestaShop',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/prestashop.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'wix',
    label: 'Wix',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/wix.svg',
    type: 'oauth',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'cin7',
    label: 'Cin7',
    type: 'api',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/cin7.svg',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'fishbowl',
    label: 'Fishbowl Inventory',
    type: 'api',
    icon: 'assets/images/integrations-logos/fishbowl.svg',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'fulfil',
    label: 'Fulfil',
    icon: 'assets/images/integrations-logos/fulfil.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'abicart',
    label: 'Abicart',
    icon: 'assets/images/integrations-logos/abicart.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'fortnox',
    label: 'Fortnox',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/fortnox.jpeg',
    type: 'oauth',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'mailship',
    label: 'Mailstep',
    type: 'api',
    icon: 'assets/images/integrations-logos/mailstep.svg',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'gambio',
    label: 'Gambio',
    type: 'api',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/gambio.png',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'shoplazza',
    label: 'SHOPLAZZA',
    type: 'api',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/shoplazza.svg',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'salesforce-commerce',
    label: 'Salesforce Commerce Cloud',
    icon: 'assets/images/integrations-logos/salesforce.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'skuvault',
    label: 'SkuVault',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/skuvault.webp',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'quickbutik',
    label: 'Quickbutik',
    icon: 'assets/images/integrations-logos/quickbutik.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'shopware',
    label: 'Shopware',
    icon: 'assets/images/integrations-logos/shopware.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'productFlow',
    label: 'ProductFlow',
    icon: 'assets/images/integrations-logos/productFlow.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'effectConnect',
    label: 'EffectConnect',
    icon: 'assets/images/integrations-logos/effectConnect.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'maropost',
    label: 'Maropost',
    icon: 'assets/images/integrations-logos/maropost.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'CMS & Webshop systems',
    external: true,
  },
  {
    tap: 'AFASSoftware',
    icon: 'assets/images/integrations-logos/afas.svg',
    label: 'AFAS Software',
    type: 'oauth',
    connectedUiParams: [],
    category: 'ERP & CRM',
    external: true,
  },
  {
    tap: 'exactGlobe',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/exact.svg',
    label: 'Exact Globe',
    type: 'oauth',
    connectedUiParams: [],
    category: 'ERP & CRM',
    external: true,
  },
  {
    tap: 'dynamics',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/microsoft_dynamics_365.png',
    label: 'Microsoft Dynamics 365',
    type: 'oauth',
    connectedUiParams: [],
    category: 'ERP & CRM',
    external: true,
  },
  {
    tap: 'unit4',
    icon: 'assets/images/integrations-logos/unit4.svg',
    label: 'Unit4',
    type: 'oauth',
    connectedUiParams: [],
    category: 'ERP & CRM',
    external: true,
  },
  {
    tap: 'bexio',
    label: 'Bexio',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/bexio.png',
    type: 'oauth',
    connectedUiParams: [],
    category: 'ERP & CRM',
    external: true,
  },
  {
    tap: 'dearsystems',
    label: 'DearSystems',
    type: 'api',
    icon: 'assets/images/integrations-logos/dear.svg',
    connectedUiParams: [],
    category: 'ERP & CRM',
    external: true,
  },
  {
    tap: 'bizBloqs',
    label: 'BizBloqs',
    type: 'api',
    icon: 'assets/images/integrations-logos/bizbloqs.svg',
    connectedUiParams: [],
    category: 'WMS & Fulfilment',
    external: true,
  },
  {
    tap: 'goedgepickt',
    label: 'Goedgepickt',
    type: 'api',
    icon: 'assets/images/integrations-logos/goedgepickt.svg',
    connectedUiParams: [],
    category: 'WMS & Fulfilment',
    external: true,
  },
  {
    tap: 'shipbob',
    label: 'ShipBob',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/shipbob.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'WMS & Fulfilment',
    external: true,
  },
  {
    tap: 'ordoro',
    label: 'Ordoro',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/ordoro.png',
    type: 'api',
    connectedUiParams: [],
    category: 'WMS & Fulfilment',
    external: true,
  },
  {
    tap: 'shiphero',
    label: 'ShipHero',
    icon: 'https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/shiphero.svg',
    type: 'api',
    connectedUiParams: [],
    category: 'WMS & Fulfilment',
    external: true,
  },
];
