/* eslint-disable @angular-eslint/no-input-rename */
import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  inject,
  numberAttribute,
} from '@angular/core';
import { SkeletonRectComponent } from '../skeleton-rect.component';

@Directive({
  selector: '[appSkeleton]',
})
export class SkeletonDirective implements OnChanges {
  @Input({ alias: 'appSkeleton' })
  loading = false;

  @Input({ alias: 'appSkeletonRepeat', transform: numberAttribute })
  repeat = 1;

  @Input({ alias: 'appSkeletonWidth' })
  width: string;

  @Input({ alias: 'appSkeletonHeight' })
  height: string;

  private tpl: TemplateRef<any> = inject(TemplateRef);

  private vcr: ViewContainerRef = inject(ViewContainerRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      this.vcr.clear();

      if (changes.loading.currentValue) {
        for (let i = 0; i < this.repeat; i++) {
          const ref = this.vcr.createComponent(SkeletonRectComponent);

          Object.assign(ref.instance, {
            width: this.width ?? `${this._randomWidth(30, 90)}%`,
            height: this.height,
            customClass: 'skeleton-list-item',
          });
        }
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }

  /**
   * Generates random number between min and max for the skeleton width
   * @param min number
   * @param max number
   * @returns random number
   */
  private _randomWidth(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
