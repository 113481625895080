<span [class.disabled]="disabled">
  <ng-container *ngIf="!disabled; else disabledTab">
    <a [routerLink]="navigateTo" routerLinkActive="tab-active" class="tab">{{
      label
    }}</a>
  </ng-container>

  <ng-template #disabledTab>
    <span class="tab tab-disabled">{{ label }}</span>
  </ng-template>
</span>
