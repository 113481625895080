import { InjectionToken } from '@angular/core';
import { DropdownComponent } from '../dropdown.component';
import { DropdownOptionGroupComponent } from '../components/dropdown-option-group/dropdown-option-group.component';
import { DropdownTriggerDirective } from '../directives/dropdown-trigger.directive';
import { DropdownOptionComponent } from '../components/dropdown-option/dropdown-option.component';
import { DropdownSearchComponent } from '../components/dropdown-search/dropdown-search.component';

export const DROPDOWN_CONFIG = new InjectionToken<DropDownConfig>(
  'DropdownConfig'
);

export const DROPDOWN_PARENT = new InjectionToken<DropdownComponent>(
  'Dropdown'
);

export const DROPDOWN_TRIGGER = new InjectionToken<DropdownTriggerDirective>(
  'DropdownTrigger'
);

export const DROPDOWN_SEARCH = new InjectionToken<DropdownSearchComponent>(
  'DropdownSearch'
);

export const OPTION_GROUP = new InjectionToken<DropdownOptionGroupComponent>(
  'OptionGroup'
);

export interface DropDownConfig {
  minWidth?: string | number | null;
  panelWidth?: string | number | null;
}

export interface DropdownOptionSelectedSource {
  value: string;
  displayedValue: string;
  selected: boolean;
}

export class DropdownOptionSelected<T = any> {
  constructor(public source: DropdownOptionComponent<T>) {}
}
