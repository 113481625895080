import { ServicePageableV3 } from 'src/app/shared/models/datatable/v2/pageable-v2.model';

export interface PromotionInterval {
  start: string;
  end: string;
}

export interface PromotionUplift {
  increase: number;
  type: string;
}

export interface PromotionSettings {
  enabled: boolean;
}

export enum PromotionStatuses {
  ENDED = 'ENDED',
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  DISABLED = 'DISABLED',
}

export interface PromotionStatus {
  label: PromotionStatuses;
  color: 'default' | 'orange' | 'green' | 'blue';
}

export interface Promotion {
  uuid: string;
  webshopUUID: string;
  name: string;
  interval: PromotionInterval;
  uplift: PromotionUplift;
  numberOfProducts: number;
  settings: PromotionSettings;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status?: PromotionStatus;
  id: number;
  notes?: string;
  extras?: {
    formattedUpliftType: string;
  };
}

export interface Promotions {
  promotions: Promotion[];
  meta: ServicePageableV3;
}

export interface WebshopProduct {
  uuid: string;
  promotionUUID: string;
  specificUplift: PromotionUplift;
  effectiveUplift: PromotionUplift;
  createdAt: string;
  updatedAt: string;
}

export interface WebshopProductPromotion {
  promotion: Promotion;
  webshopProduct: WebshopProduct;
}

export interface WebshopProductPromotions {
  webshopProducts: WebshopProductPromotion[];
  meta: ServicePageableV3;
}
