import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { InputVariant } from '../../../input/model/input.model';

@Component({
  selector: 'app-data-table-search',
  templateUrl: './data-table-search.component.html',
  styleUrl: './data-table-search.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableSearchComponent {
  @Input()
  variant: InputVariant = 'outline';

  @Input()
  searchParams: string[] | null;

  @Input()
  disabled: boolean;

  @Output()
  addedParam: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  removedParam: EventEmitter<string> = new EventEmitter<string>();

  focus = signal(false);

  readonly COMMON_STRINGS = MESSAGES.common;
}
