import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { WebshopProduct } from 'src/app/shared/models/products/v3/products.model';
import { Supplier } from 'src/app/shared/models/suppliers/product-suppliers.model';
import { SupplyChainInformationV2StateQueries } from '../../state/supply-chain-information-v2.queries';

@Component({
  selector: 'app-supply-chain-product-v2',
  templateUrl: './supply-chain-product-v2.component.html',
  styleUrls: ['./supply-chain-product-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainProductV2Component {
  @Select(ProductDetailsV2StateQueries.isAssembled)
  assembled$: Observable<boolean>;

  @Select(ProductDetailsV2StateQueries.product)
  product$: Observable<WebshopProduct>;

  @Select(SupplyChainInformationV2StateQueries.activeSupplier)
  activeSupplier$: Observable<Supplier>;

  readonly METADATA = STRINGS.metadata.supplyChainInformation.product;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  constructor() {
    // intentional
  }
}
