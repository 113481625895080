import {
  ServiceRequestInfoV2,
  ServiceRequestInfoV3,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';

export interface LoadProposedOrdersRequestInfo extends ServiceRequestInfoV2 {}

/**
 * Load proposed orders request info v3.
 *
 * Use this interface when dealing with requests supporting the new filtering system.
 */
export interface LoadProposedOrdersRequestInfoV3 extends ServiceRequestInfoV3 {}

export enum ProposedOrdersStatus {
  OVERDUE = 'OVERDUE',
  TODAY = 'TODAY',
  FUTURE = 'FUTURE',
}
