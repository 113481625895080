import { DataAdapter } from 'src/app/shared/adapter/adapter';

import { Account as AccountV1 } from '../model/account.model';
import { UserAccountInformation } from 'src/app/shared/models/account/account.model';

export class AccountAdapter
  implements DataAdapter<AccountV1, UserAccountInformation>
{
  transform(data: AccountV1): UserAccountInformation {
    return {
      uuid: data.account.uuid,
      name: data.account.name,
      email: data.account.email,
      ccEmails: data.account.ccEmails,
      isIntegration: data.account.isIntegration,
    };
  }
}
