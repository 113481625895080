import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PromotionUpliftTypes } from '../promotion/v2/promotion-v2.model';

interface PromotionUpliftForm {
  increase: string | number;
  type: string;
}

export interface RowProperties {
  uuid: string;
  uplift: PromotionUpliftForm;
}

export class DataTableRowFormSource {
  public group: UntypedFormGroup;
  public shared: UntypedFormGroup;

  constructor(protected formBuilder: UntypedFormBuilder) {
    this.group = this.formBuilder.group({});
    this.shared = this.formBuilder.group({});

    this.group.addControl('shared', this.shared);
  }
}

/**
 * Controls the forms of a delivery orderline data table.
 */
export class DataTableRowForm {
  public formSource: DataTableRowFormSource;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    formSource: DataTableRowFormSource
  ) {
    this.formSource = formSource;
  }

  addShared(row: RowProperties) {
    if (this.formSource.shared.get(String(row.uuid)) !== null) {
      return;
    }

    const promotionsGroup = this.formBuilder.group({});

    const upliftIncreaseInitialValidators =
      this._buildUpliftIncreaseInitialValidators(row.uplift.type);

    promotionsGroup.addControl(
      'specificUpliftIncrease',
      this.formBuilder.control(
        row.uplift.increase,
        upliftIncreaseInitialValidators
      )
    );

    promotionsGroup.addControl(
      'specificUpliftType',
      this.formBuilder.control(row.uplift.type, [Validators.required])
    );

    this.formSource.shared.addControl(String(row.uuid), promotionsGroup);
  }

  reset(): void {
    Object.keys(this.formSource.shared.controls).forEach(control => {
      this.formSource.shared.removeControl(control);
    });
  }

  private _buildUpliftIncreaseInitialValidators(upliftType: string) {
    if (
      [PromotionUpliftTypes.RELATIVE, PromotionUpliftTypes.ABSOLUTE].includes(
        upliftType as PromotionUpliftTypes
      )
    ) {
      return [Validators.required];
    }

    return [];
  }
}
