import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { select, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import {
  PredecessorProduct,
  SuccessorProduct,
  WebshopProduct,
} from 'src/app/shared/models/products/v3/products.model';
import { SupplyChainInformationV2StateQueries } from '../supply-chain-information-v2/state/supply-chain-information-v2.queries';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';

@Component({
  selector: 'app-product-overview-v2',
  templateUrl: './product-overview-v2.component.html',
  styleUrls: ['./product-overview-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProductOverviewV2Component {
  @Input()
  isOverlay: boolean;

  enabledAdvancedCategorization = select(
    WebshopState.enabledAdvancedCategorization
  );

  overview = select<WebshopProduct>(ProductDetailsV2StateQueries.product);

  predecessorProduct = select<PredecessorProduct>(
    ProductDetailsV2StateQueries.predecessor
  );

  successorProduct = select<SuccessorProduct>(
    ProductDetailsV2StateQueries.successor
  );

  activeSupplier = select(SupplyChainInformationV2StateQueries.activeSupplier);

  suppliersPermission = select(
    PermissionQueries.hasPermission(RolesFeatureKeys.SUPPLIERS)
  );

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly METADATA = STRINGS.metadata.overview;

  /* Reuse expected demand table column label */
  readonly EXPECTED_DEMAND_COLUMN_LABEL =
    STRINGS.columns.products.EXPECTED_DEMAND.name;

  constructor(private store: Store) {}

  goToPredecessor(productUuid: string): void {
    this.store.dispatch(
      new NavigateToWithQueryParams(['products', 'details'], {
        productUuid,
        predecessor: true,
      })
    );
  }

  goToSuccessor(productUuid: string): void {
    this.store.dispatch(
      new NavigateToWithQueryParams(['products', 'details'], {
        productUuid,
        successor: true,
      })
    );
  }
}
