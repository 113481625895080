import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Webshop } from '../../models/webshop/webshop.model';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { STRINGS } from 'src/app/features/webshops/model/webshops.strings';

@Component({
  selector: 'app-webshop-data-table',
  templateUrl: './webshop-data-table.component.html',
  styleUrls: ['./webshop-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebshopDataTableComponent implements OnInit, OnDestroy {
  displayedColumns = ['Name', 'Handle', 'ID'];

  @Input() selectedWebshop: Webshop | null;

  @Input() dataSource: Observable<Webshop[]>;

  @Input() loading: Observable<boolean>;

  @Input() filter: Observable<string>;

  /**
   * Automatically selects the first row.
   * Optional field.
   */
  @Input() selectFirst? = false;

  @Output()
  selectedWebshopChanged: EventEmitter<Webshop> = new EventEmitter();

  /**
   * Stores the current user filter text input.
   */
  filteredText = '';

  /**
   * Used as a way to trigger OnPush change to render
   * the template ngFor section when the data changes, otherwise Angular
   * will not detect any changes from the datasource because the reference
   * never changes.
   * This is done to improve performance by making the data immutable and
   * removing the burden of Angular having to do a deep array check for data
   * changes.
   */
  dataSourceSubject$: Subject<Webshop[]> = new ReplaySubject<Webshop[]>();

  selection = new SelectionModel<Webshop>(false, []);

  webshops: Webshop[] = [];

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  private destroy$ = new Subject<void>();

  constructor(private store: Store) {
    this.filterWebshops = this.filterWebshops.bind(this);
  }
  ngOnInit(): void {
    this.filterPipe().pipe(takeUntil(this.destroy$)).subscribe();

    /**
     * Filtering is ignored here when the datasource changes.
     * Should not be a problem.
     */
    this.dataSource.pipe(takeUntil(this.destroy$)).subscribe(webshops => {
      this.webshops = webshops;
      this.dataSourceSubject$.next(webshops);
      this.selectFirstRow(webshops);
    });
  }

  ngOnDestroy(): void {
    this.dataSourceSubject$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Filter a webshop based on user's input.
   * @see this.filteredTex
   * @param webshop the webshop to be filtered.
   */
  filterWebshops(webshop: Webshop | null): boolean {
    if (this.filteredText.length === 0) {
      return true;
    }

    if (webshop) {
      return webshop.handle.includes(this.filteredText, 0);
    }
    return false;
  }

  /**
   * Pipe that filters data based on user input.
   */
  filterPipe(): Observable<string> {
    return this.filter.pipe(
      tap(input => {
        this.filteredText = input;
      }),
      switchMap(() => {
        return of(this.webshops).pipe(
          map(webshops => {
            return webshops.filter(this.filterWebshops);
          }),
          tap(webshops => this.dataSourceSubject$.next(webshops))
        );
      }),
      map(() => this.filteredText)
    );
  }

  /**
   * Triggered when the user selects a row from the datatable.
   * @param row the selected row.
   */
  onRowSelected(row: Webshop): void {
    if (this.selectedWebshop && this.selectedWebshop.uuid === row.uuid) return;

    this.selection.toggle(row);
    if (this.selection.hasValue()) {
      this.selectedWebshopChanged.emit(row);
    }
  }

  /**
   * Selects the first row of the provided datasource.
   * *
   * @param dataSource the datasource.
   */
  selectFirstRow(dataSource: Webshop[]): void {
    if (this.selectFirst && dataSource.length > 0) {
      this.onRowSelected(dataSource[0]);
    }
  }
}
