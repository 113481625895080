export class LoadStockLevelHistory {
  static readonly type = '[Stock Level History] Load';
}

export class ResetStockLevelHistory {
  static readonly type = '[Stock Level History] Reset';
}

export class StockLevelLegendSelectedChanged {
  static readonly type = '[Stock Level History] Legend Selected Changed';

  constructor(public event: any) {}
}
