import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { catchError, concatMap, mergeMap, tap } from 'rxjs/operators';
import {
  AddEmailToList,
  LoadAccount,
  LoadIntercomData,
  RemoveEmailFromList,
  UpdateAccount,
  UpdateAccountCcEmails,
  UpdateUserName,
} from '../actions/account.action';
import { LoadFailed, SaveFailed } from '../actions/app.action';
import { AccountService } from '../api/account/v2/account.service';
import {
  UserAccountUpdate,
  UserIntercomData,
} from '../api/account/v2/model/account.model';
import { UpdateAuthAccount } from '../actions/authentication.action';
import { UserAccountInformation } from 'src/app/shared/models/account/account.model';

export interface AccountStateModel {
  account: {
    uuid: string;
    name: string;
    email: string;
    ccEmails: string[];
  };
  ccEmailsModel: string[];
  intercomData: UserIntercomData;
}

@State<AccountStateModel>({
  name: 'accountState',
  defaults: {
    account: {
      uuid: null,
      name: null,
      email: null,
      ccEmails: [],
    },
    ccEmailsModel: [],
    intercomData: { email: null, userHash: null },
  },
})
@Injectable()
export class AccountState {
  constructor(
    private store: Store,
    private accountService: AccountService
  ) {}

  @Selector()
  static userUuid(state: AccountStateModel): string {
    return state.account.uuid;
  }

  @Selector()
  static ccEmails(state: AccountStateModel): string[] {
    return state.account.ccEmails;
  }

  @Selector()
  static ccEmailsModel(state: AccountStateModel): string[] {
    return state.ccEmailsModel;
  }

  @Selector()
  static intercomData(state: AccountStateModel): UserIntercomData {
    return state.intercomData;
  }

  @Action(LoadAccount, { cancelUncompleted: true })
  loadAccount(ctx: StateContext<AccountStateModel>, payload: LoadAccount) {
    const state = ctx.getState();

    const userEmail = payload.userEmail
      ? payload.userEmail
      : state.account.email;

    return this.accountService.fetchAccount(userEmail).pipe(
      tap((account: UserAccountInformation) => {
        ctx.patchState({
          account: {
            uuid: account.uuid,
            name: account.name,
            email: account.email,
            ccEmails: account.ccEmails,
          },
          ccEmailsModel: account.ccEmails,
        });
      }),
      mergeMap((account: UserAccountInformation) => {
        return this.store.dispatch(new LoadIntercomData(account.uuid));
      }),
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  @Action(LoadIntercomData, { cancelUncompleted: true })
  loadIntercomData(
    ctx: StateContext<AccountStateModel>,
    payload: LoadIntercomData
  ) {
    return this.accountService.fetchIntercomToken(payload.userUUID).pipe(
      tap((account: UserIntercomData) => {
        account;
        ctx.patchState({
          intercomData: { email: account.email, userHash: account.userHash },
        });
      }),
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  @Action(UpdateAccount, { cancelUncompleted: true })
  updateAccount(ctx: StateContext<AccountStateModel>) {
    const state = ctx.getState();

    const userUUID = state.account.uuid;

    const requestProperties: UserAccountUpdate = {
      ccEmails: {
        ccEmails: state.ccEmailsModel,
      },
      name: state.account.name,
    };

    return this.accountService.updateAccount(userUUID, requestProperties).pipe(
      concatMap(() => ctx.dispatch(new UpdateAuthAccount(state.account.name))),
      catchError(e => {
        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }

  @Action(AddEmailToList, { cancelUncompleted: true })
  addEmailToList(
    ctx: StateContext<AccountStateModel>,
    payload: AddEmailToList
  ) {
    ctx.setState(
      patch<AccountStateModel>({
        ccEmailsModel: append<string>([payload.email]),
      })
    );
  }

  @Action(RemoveEmailFromList, { cancelUncompleted: true })
  removeEmailFromList(
    ctx: StateContext<AccountStateModel>,
    payload: RemoveEmailFromList
  ) {
    ctx.setState(
      patch<AccountStateModel>({
        ccEmailsModel: removeItem<string>(email => email === payload.email),
      })
    );
  }

  @Action(UpdateUserName, { cancelUncompleted: true })
  UpdateUserName(
    ctx: StateContext<AccountStateModel>,
    payload: UpdateUserName
  ) {
    const state = ctx.getState();

    ctx.patchState({
      account: { ...state.account, name: payload.userName },
    });
  }

  @Action(UpdateAccountCcEmails, { cancelUncompleted: true })
  updateAccountCcEmails(
    ctx: StateContext<AccountStateModel>,
    payload: UpdateAccountCcEmails
  ) {
    const state = ctx.getState();

    const userUUID = state.account.uuid;

    const requestProperties: UserAccountUpdate = {
      ccEmails: {
        ccEmails: payload.emails,
      },
    };

    return this.accountService.updateAccount(userUUID, requestProperties).pipe(
      catchError(e => {
        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }
}
