<app-dialog-container
  ><div class="confirmation-dialog">
    <div appDialogHeader>
      <mat-icon #dialogIcon svgIcon="warning-rounded" class="icon"></mat-icon>
      <h1
        *ngIf="(hasMultiPendingChanges$ | async) === false"
        appDialogTitle
        i18n="confirmation dialog title single changes">
        You have unsaved changes
      </h1>

      <h1
        *ngIf="hasMultiPendingChanges$ | async"
        appDialogTitle
        i18n="confirmation dialog title multiple changes">
        You have multiple unsaved changes
      </h1>

      <button
        aria-label="Confirmation dialog close button"
        (click)="close()"
        [disabled]="loading$ | async"
        class="close-button">
        <mat-icon svgIcon="cross"></mat-icon>
      </button>
    </div>

    <div appDialogBody>
      <ng-container
        *ngIf="
          (hasMultiPendingChanges$ | async) === false;
          else multipleChanges
        ">
        <p i18n="confirmation dialog title single change description">
          Your changes will be lost if you don't save them.
        </p>
        <p i18n="confirmation dialog title single change question">
          Do you wish to save or discard your current changes?
        </p>
      </ng-container>

      <ng-template #multipleChanges>
        <p i18n="confirmation dialog title single change description">
          Your changes will be lost if you don't save them.
        </p>
        <p i18n="confirmation dialog title single change question">
          Do you wish to discard your current changes?
        </p>
      </ng-template>
    </div>

    <div appDialogActions>
      <div class="footer">
        <button
          aria-label="Confirmation dialog cancel button"
          appButton
          variant="tertiary"
          [disabled]="loading$ | async"
          (click)="close()">
          {{ COMMON_STRINGS.cancel }}
        </button>

        <button
          aria-label="Confirmation dialog discard button"
          appButton
          variant="secondary"
          i18n="confirmation dialog footer discard button"
          [disabled]="loading$ | async"
          (click)="discard()">
          Discard changes
        </button>

        <button
          *ngIf="
            (hasMultiPendingChanges$ | async) === false &&
            (currentPendingChange$ | async) !== PENDING_CHANGES_KEYS.ORDER
          "
          appButton
          variant="primary"
          i18n="confirmation dialog footer save button"
          appTooltip="Your changes are not valid"
          i18n-appTooltip="confirmation dialog no valid changes warning"
          [tooltipDisabled]="showInvalidChangesWarning$ | async"
          [disabled]="(disableSubmitButton$ | async) || (loading$ | async)"
          (click)="save()">
          Save changes
          <app-progress-spinner *ngIf="loading$ | async"></app-progress-spinner>
        </button>

        <button
          *ngIf="
            (hasMultiPendingChanges$ | async) === false &&
            (currentPendingChange$ | async) === PENDING_CHANGES_KEYS.ORDER
          "
          appButton
          variant="secondary"
          i18n="confirmation dialog footer save concept button"
          appTooltip="Your changes are not valid"
          i18n-appTooltip="confirmation dialog no valid changes warning"
          [tooltipDisabled]="
            (showInvalidChangesWarning$ | async) || (loading$ | async)
          "
          [disabled]="(disableSubmitButton$ | async) || (loading$ | async)"
          (click)="save()">
          Save Concept
          <app-progress-spinner *ngIf="loading$ | async"></app-progress-spinner>
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
