import { DataAdapter } from 'src/app/shared/adapter/adapter';

import { SupplierDetails } from '../model/suppliers-v2.model';

import {
  Supplier,
  SupplierCommunication,
} from 'src/app/shared/models/suppliers/v2/supplier-v2.model';

import { MESSAGES } from 'src/app/core/constants/strings.constants';
import {
  DynamicTags,
  TagVariantColor,
} from 'src/app/shared/components/design-system/tags/model/tag.model';
import { STRINGS } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.strings';
import { Store } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { DateTime } from 'luxon';
import { formatDateWithCutoffToWebshopTimezone } from 'src/app/shared/utils/dates.utils';

export class SupplierV2Adapter
  implements DataAdapter<SupplierDetails, Supplier>
{
  readonly STRINGS = STRINGS.tooltips;
  private readonly MESSAGES = MESSAGES;

  constructor(private store: Store) {}

  transform(data: SupplierDetails): Supplier {
    let newDate = null;

    if (data.supplier.cutoff) {
      newDate = this._buildTimeZoneDate(data.supplier.cutoff);
    }

    let cutoffArray = data.supplier.cutoff ? newDate.split(':') : [];

    const newSupplier: Supplier = {
      uuid: data.supplier.uuid,
      id: Number(data.supplier.id),
      name: data.supplier.name,
      fixedCosts: data.supplier.fixedCosts,
      deliveryTime: data.supplier.deliveryTime,
      minimumOrderValue: data.supplier.minimumOrderValue,
      defaultWarehouse: data.supplier.defaultWarehouse,
      leadTimeMeanActual: data.supplier.leadTimeMeanActual,
      leadTimeSdActual: data.supplier.leadTimeSdActual,
      leadTimeSdPct: data.supplier.leadTimeSdPct,
      ignored: data.supplier.ignored,
      backorders: data.supplier.backorders,
      reactingToLostSales: data.supplier.reactingToLostSales,
      backorderThreshold: data.supplier.backorderThreshold,
      lostSalesReaction: data.supplier.lostSalesReaction,
      backordersReaction: data.supplier.backordersReaction,
      agendaManagedManually: data.supplier.agendaManagedManually,
      agendaManagedData: data.supplier.agendaManagedData,
      lostSalesMovReaction: data.supplier.lostSalesMovReaction,
      exportQuantityAsLots: data.supplier.exportQuantityAsLots,
      excludePriceFromExports: data.supplier.excludePriceFromExports,
      effectiveReplenishmentPeriod: data.supplier.effectiveReplenishmentPeriod,
      replenishmentPeriod: data.supplier.replenishmentPeriod,
      userReplenishmentPeriod: data.supplier.userReplenishmentPeriod,
      maxLoadCapacity: data.supplier.maxLoadCapacity,
      containerVolume: data.supplier.containerVolume,
      globalLocationNumber: data.supplier.globalLocationNumber,
      emails: data.supplier.emails,
      issues: data.supplier.issues,
      preferredEmailLanguage:
        data.supplier.preferredEmailLanguage.toLowerCase(),
      notes: data.supplier.notes,
      communicationMethod: data.supplier
        .communicationMethod as SupplierCommunication,
      cutoffHH: cutoffArray[0] ? cutoffArray[0] : null,
      cutoffMM: cutoffArray[1] ? cutoffArray[1] : null,
      extras: {
        tags: this.buildTags(data.supplier),
      },
    };

    return newSupplier;
  }

  buildTags(base: Supplier): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (
      base.issues?.includes('FILL_LEAD_TIME') ||
      base.issues?.includes('FILL_REPLENISHMENT_PERIOD')
    ) {
      extras.iconTextTags.push({
        icon: 'warning-rounded-xs',
        color: TagVariantColor.RED,
        tooltip:
          base.issues?.includes('FILL_LEAD_TIME') &&
          base.issues?.includes('FILL_REPLENISHMENT_PERIOD')
            ? this.STRINGS.FILL_LEAD_TIME +
              '\n' +
              this.STRINGS.FILL_REPLENISHMENT_PERIOD
            : base.issues?.includes('FILL_REPLENISHMENT_PERIOD')
              ? this.STRINGS.FILL_REPLENISHMENT_PERIOD
              : this.STRINGS.FILL_LEAD_TIME,
        text:
          (base.userReplenishmentPeriod === null ||
            base.userReplenishmentPeriod === 0) &&
          (base.deliveryTime === null || base.deliveryTime === 0)
            ? '2'
            : '',
      });
    }

    if (base.issues?.includes('FILL_FIXED_COSTS')) {
      extras.iconTextTags.push({
        icon: 'warning-triangle',
        color: TagVariantColor.ORANGE,
        tooltip: this.STRINGS.FILL_FIXED_COSTS,
        text: '',
      });
    }

    if (base.ignored) {
      extras.iconTags.push({
        color: TagVariantColor.DEFAULT,
        icon: 'eye-closed',
        tooltip: this.MESSAGES.common.ignored,
      });
    }

    return extras;
  }

  private _buildTimeZoneDate(cutoff: string): string {
    const timeZoneIdentifier = this.store.selectSnapshot(
      WebshopState.timeZoneIdentifier
    );
    const todayDateWebshopTimeZone = formatDateWithCutoffToWebshopTimezone(
      timeZoneIdentifier,
      cutoff
    );

    return DateTime.fromJSDate(new Date(todayDateWebshopTimeZone)).toFormat(
      'HH:mm:ss'
    );
  }
}
