<app-product-container>
  <span [class.opaque]="ignored()" header
    >{{ METADATA.title }}:
    <span class="supplier-name">
      @if (activeSupplier()) {
        <span
          class="deep-linking-text"
          (click)="goToSupplier(activeSupplier().supplierUuid)">
          {{ activeSupplier().name }}
        </span>
      } @else {
        <span class="no-supplier">{{
          METADATA.activeSupplier.noSupplier
        }}</span>
      }
    </span></span
  >

  <div class="content">
    <app-supply-chain-active-supplier-v2
      *ngIf="assembled() === false"
      class="active-supplier"
      [class.opaque]="ignored()"></app-supply-chain-active-supplier-v2>

    <app-supply-chain-purchasing-advice-v2
      class="advice"
      [class.opaque]="ignored()"></app-supply-chain-purchasing-advice-v2>

    <app-supply-chain-product-v2
      class="product"
      [class.opaque]="ignored()"></app-supply-chain-product-v2>
  </div>
</app-product-container>
