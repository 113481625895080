import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { ProductLinkData, ProductLink } from './model/product-link.model';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'src/app/core/states/app.state';
import { Observable } from 'rxjs';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import {
  LinkPredecessor,
  LoadProductAvailablePredecessors,
} from 'src/app/features/products-v2/components/product-details-v2/actions/product-details-v2.actions';
import { PredecessorProduct } from 'src/app/shared/models/products/v3/products.model';

@Component({
  selector: 'app-product-link-dialog',
  templateUrl: './product-link-dialog.component.html',
  styleUrls: ['./product-link-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductLinkDialogComponent implements OnInit {
  @Select(AppState.isLoading)
  loading$: Observable<boolean>;

  @Select(AppState.isSaving)
  saving$: Observable<boolean>;

  @Select(ProductDetailsV2StateQueries.availablePredecessors)
  availablePredecessors$: Observable<PredecessorProduct>;

  productLink: ProductLink = {
    predecessor: null,
    notBeingBought: true,
  };

  hasProductLinked: boolean = false;

  readonly DIALOG_STRINGS = STRINGS.metadata.dialogs.linkPredecessor;

  readonly TOOLTIPS_STRINGS = STRINGS.tooltips.linkProduct;

  readonly COMMON_STRINGS = MESSAGES.common;

  constructor(
    @Inject(DIALOG_DATA) public data: ProductLinkData,
    public dialogRef: DialogRef<ProductLinkDialogComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {
    this._checkDialogData();
  }

  displayWithName(product: PredecessorProduct): string {
    return product.name;
  }

  filterProducts(search: string): void {
    this.store.dispatch(new LoadProductAvailablePredecessors(search));
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.store.dispatch(new LinkPredecessor(this.productLink, this.dialogRef));
  }

  deletePredecessor() {
    this.store.dispatch(new LinkPredecessor(null, this.dialogRef));
  }

  private _checkDialogData(): void {
    if (!!this.data?.predecessor) {
      this.productLink = {
        predecessor: this.data.predecessor,
        notBeingBought: this.data.predecessor.notBeingBought,
      };

      this.hasProductLinked = true;
    }
  }
}
