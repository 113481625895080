import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ProgressBarMode } from './model/progress-bar.model';

export function clamp(v: number, min = 0, max = 100) {
  return Math.max(min, Math.min(max, v)) || 0;
}

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'progressbar',
    'aria-valuemin': '0',
    'aria-valuemax': '100',
    '[attr.aria-valuenow]': 'isIndeterminate() ? null : value',
    '[attr.mode]': 'mode',
    class: 'progress-bar',
    tabindex: '-1',
  },
})
export class ProgressBarComponent {
  @Input({ transform: clamp })
  value: number = 0;

  @Input()
  mode: ProgressBarMode = 'indeterminate';

  isIndeterminate(): boolean {
    return this.mode === 'indeterminate';
  }

  getElementTransform(): string {
    return `translateX(0%) scaleX(${
      this.isIndeterminate() ? 1 : this.value / 100
    })`;
  }
}
