import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';
import { TabBase } from '../../core/tab-base';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '(click)': '$event.stopPropagation()',
  },
})
export class TabComponent extends TabBase {
  @Input()
  override label: string;

  @Input({ transform: booleanAttribute })
  override disabled: boolean;

  @Input()
  navigateTo: string;
}
