import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  signal,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ConfirmActions } from './actions/base-confirmation.actions';
import {
  BaseConfirmationDialogData,
  BaseConfirmationDialogDefaults,
} from './model/base-confirmation-data.model';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';

@Component({
  selector: 'app-base-confirmation-dialog',
  templateUrl: './base-confirmation-dialog.component.html',
  styleUrls: ['./base-confirmation-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseConfirmationDialogComponent {
  props: BaseConfirmationDialogData = { ...BaseConfirmationDialogDefaults };

  loading = signal(false);

  readonly COMMON_STRINGS = MESSAGES.common;

  private _linkRegex =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/=]*)/;

  constructor(
    @Inject(DIALOG_DATA)
    public data: BaseConfirmationDialogData,
    public store: Store,
    public dialogRef: DialogRef
  ) {}

  /**
   * Dispatches to confirm the intended action.
   *
   * If an action is provided, the specific action is executed instead of the default ConfirmActions.
   */
  confirm(): void {
    this.loading.set(true);

    this.store.dispatch(
      new ConfirmActions(this.props.actionKey, this.props.actionData)
    );
  }

  cancel() {
    if (this.props.cancelAction) {
      this.props.cancelAction();

      return;
    }

    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  /**
   * Checks if the help's link was provided and opens it in a new tab.
   */
  goToHelp(): void {
    if (this.props.helpLink) {
      window.open(this.props.helpLink, '_blank');
    }
  }

  /**
   * Builds the component properties from the provided dialog data.
   *
   * If no data is provided, the default state remains (ONLY for developers).
   * @param data BaseConfirmationDialogData
   */
  protected buildPropsFromData(data: BaseConfirmationDialogData): void {
    if (data) {
      this.props = {
        ...this.props,
        ...data,
      };
    }
  }

  get noHelpLinkProvided(): boolean {
    return !this.props.helpLink;
  }

  get invalidHelpLink(): boolean {
    return !this._linkRegex.test(this.props.helpLink);
  }
}
