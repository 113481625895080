import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';

import {
  CreateUpdatePromotionForm,
  PromotionsDialogProperties,
} from './model/dialog-properties.model';
import {
  PromotionUpliftTypes,
  promotionUpliftOptions,
} from '../../models/promotion/v2/promotion-v2.model';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from './model/create-update-promotions-dialog.strings';
import { AppState } from 'src/app/core/states/app.state';
import { DateTime } from 'luxon';
import {
  CreatePromotion,
  EditPromotion,
} from 'src/app/features/promotions-v2/actions/promotions-v2.actions';
import {
  CreatePromotionFormProperties,
  UpdatePromotionFormProperties,
} from 'src/app/features/promotions-v2/model/promotions-v2.model';
import { NULL_VALUE } from '../../models/core.model';

@Component({
  selector: 'app-create-update-promotion-dialog',
  templateUrl: './create-update-promotion-dialog.component.html',
  styleUrls: ['./create-update-promotion-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUpdatePromotionDialogComponent implements OnInit {
  @Select(AppState.isLoading)
  loading$: Observable<boolean>;

  @Select(AppState.isSaving)
  saving$: Observable<boolean>;

  vm: CreateUpdatePromotionForm = {
    promotionName: null,
    startDate: null,
    endDate: null,
    upliftType: PromotionUpliftTypes.NO_UPLIFT,
    increase: null,
    enabled: true,
    entireWebshop: false,
  };

  dialogTitle = this.data.dialogTitle;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly UPLIFT_TYPES_OPTIONS = promotionUpliftOptions;

  readonly UPLIFT_TYPES = PromotionUpliftTypes;

  readonly DIALOG_STRINGS = STRINGS.metadata;

  readonly COMMON_STRINGS = MESSAGES.common;

  constructor(
    @Inject(DIALOG_DATA) public data: PromotionsDialogProperties,
    private store: Store,
    public dialogRef: DialogRef
  ) {}

  ngOnInit(): void {
    if (this.data.promotion) {
      this._patchForm();
    }
  }

  handleIncreaseDependingOnUpliftType(upliftType: PromotionUpliftTypes): void {
    if (upliftType === this.UPLIFT_TYPES.CLOSE_OUT) {
      this.vm.increase = 0;
    } else {
      this.vm.increase = null;
    }
  }

  save(): void {
    if (!this.data.newPromotion) {
      this.editPromotion();
    } else {
      this.createPromotion();
    }
  }

  createPromotion(): void {
    const promotion: CreatePromotionFormProperties = {
      name: this.vm.promotionName,
      startDate: this._convertDate(this.vm.startDate),
      endDate: this._convertDate(this.vm.endDate),
      settings: {
        enabled: this.vm.enabled,
        entireShop: this.vm.entireWebshop,
      },
    };

    if (this.vm.upliftType !== this.UPLIFT_TYPES.NO_UPLIFT) {
      promotion.uplift = {
        type: this.vm.upliftType,
        increase: this.vm.increase,
      };
    }

    this.store.dispatch(new CreatePromotion(promotion, this.dialogRef));
  }

  editPromotion(): void {
    const promotion: UpdatePromotionFormProperties = {
      name: this.vm.promotionName,
      startDate: this._convertDate(this.vm.startDate),
      endDate: this._convertDate(this.vm.endDate),
      enabled: this.vm.enabled,
      entireShop: this.vm.entireWebshop,
      promotionUuid: this.data.promotion.promotionUUID,
    };

    if (this.vm.upliftType === this.UPLIFT_TYPES.NO_UPLIFT) {
      promotion.uplift = {
        type: this.UPLIFT_TYPES.ABSOLUTE,
        increase: { null: NULL_VALUE },
      };
    } else {
      promotion.uplift = {
        type: this.vm.upliftType,
        increase: { value: this.vm.increase },
      };
    }

    this.store.dispatch(new EditPromotion(promotion, this.dialogRef));
  }

  close() {
    this.dialogRef.close();
  }

  get invalidPromotionDatesRange(): boolean {
    if (this.vm.startDate === null || this.vm.endDate === null) return false;

    return (
      DateTime.fromJSDate(new Date(this.vm.endDate)).diff(
        DateTime.fromJSDate(new Date(this.vm.startDate)),
        'days'
      ).days < 0
    );
  }

  // WARN: DATE IS SET TO 12 HOURS TO MATCH TIMEZONE DIFF
  private _convertDate(date: Date): string {
    const newDate = new Date(date.setHours(12));
    return newDate.toISOString().split('T')[0];
  }

  private _patchForm(): void {
    this.vm = {
      promotionName: this.data.promotion.name,
      startDate: new Date(this.data.promotion.startDate),
      endDate: new Date(this.data.promotion.endDate),
      upliftType: this.data.promotion.upliftType as PromotionUpliftTypes,
      increase: this.data.promotion.increase,
      enabled: this.data.promotion.enabled,
      entireWebshop: this.data.promotion.entireShop,
    };
  }
}
