import { Overlay } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import {
  ContentChild,
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { OverlayBaseV2 } from '../core/overlay-base-v2';
import {
  DefaultOverlayV2Config,
  OVERLAY_CONFIG,
} from '../model/overlay-container-v2.model';

@Directive({
  selector: '[appOverlay]',
  exportAs: 'overlay',
})
export class OverlayDirective extends OverlayBaseV2 implements OnDestroy {
  @ContentChild(CdkPortal)
  portal: CdkPortal;

  constructor(
    @Inject(OVERLAY_CONFIG)
    public defaultConfig: DefaultOverlayV2Config,
    public el: ElementRef,
    public overlay: Overlay
  ) {
    super(defaultConfig, el, overlay);
  }

  _handleClick(): void {
    if (this.disabled) return;

    super._handleClick();

    this.overlayRef.attach(this.portal);
  }

  _closeOverlay(): void {
    super._closeOverlay();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
