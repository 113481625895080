<app-dialog-container>
  <div appDialogHeader>
    <div class="header-title">
      <mat-icon
        #dialogIcon
        svgIcon="questionmark-rounded"
        class="icon"></mat-icon>
      <h1 appDialogTitle>
        {{ DIALOG_STRINGS.title }}
      </h1>
    </div>
    <button
      aria-label="Exit confirmation close button"
      (click)="close()"
      class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div appDialogBody>
    <p>{{ DIALOG_STRINGS.description }}</p>
    <p>{{ DIALOG_STRINGS.subDescription }}</p>
  </div>

  <div appDialogActions>
    <div class="footer">
      <button
        aria-label="Exit confirmation cancel button"
        appButton
        variant="tertiary"
        (click)="close()">
        {{ COMMON_STRINGS.cancel }}
      </button>

      <button
        aria-label="Exit confirmation action button"
        appButton
        variant="primary"
        class="action-btn"
        (click)="confirmExit()">
        {{ DIALOG_STRINGS.action }}
      </button>
    </div>
  </div>
</app-dialog-container>
