<app-dialog-container
  ><div class="promotions-dialog">
    <div appDialogHeader>
      <h1 appDialogTitle>
        {{ DIALOG_STRINGS.title }}
      </h1>
      <button
        aria-label="Add product promotion dialog close button"
        class="close-button"
        (click)="close()">
        <mat-icon svgIcon="cross" class="close-icon"></mat-icon>
      </button>
    </div>

    <div appDialogBody>
      <form #form="ngForm">
        <h3 class="promotions-container-title">
          {{ DIALOG_STRINGS.promotion.title }}
        </h3>

        <div class="fields-autocomplete-container">
          <app-search
            [searchBy]="searchBy$ | async"
            (searched)="searchBy($event)"
            [placeholder]="DIALOG_STRINGS.promotion.selectPromotion"
            (optionSelected)="updateSelectedPromotion($event)"
            [autocompleteList]="filteredPromotions$ | async"></app-search>
        </div>

        <h3 class="promotions-container-title">
          {{ DIALOG_STRINGS.promotionDetails.title }}
        </h3>

        <div class="container-content-col input-field container-padding">
          <label>{{ DIALOG_STRINGS.promotionDetails.name }}</label>
          <p *ngIf="promotion$ | async as promotion; else notAvailable">
            {{ promotion.name }}
          </p>
        </div>

        <div class="container-content-row">
          <div class="container-content-col flex-50 input-field">
            <label>{{ DIALOG_STRINGS.promotionDetails.startDate }}</label>

            <p *ngIf="promotion$ | async as promotion; else notAvailable">
              {{ promotion.interval.start | date }}
            </p>
          </div>
          <div class="container-content-col flex-50 input-field">
            <label>{{ DIALOG_STRINGS.promotionDetails.endDate }}</label>

            <p *ngIf="promotion$ | async as promotion; else notAvailable">
              {{ promotion.interval.end | date }}
            </p>
          </div>
        </div>

        <!-- Promotion Uplift -->
        <h3 class="promotions-container-title">
          {{ DIALOG_STRINGS.uplift.title }}
        </h3>
        <div class="container-content-row">
          <div class="container-content-col flex-50 input-field">
            <label>{{ DIALOG_STRINGS.uplift.type }}</label>
            <p
              *ngIf="
                (promotion$ | async)?.uplift.type as promotionUpliftType;
                else notAvailable
              ">
              {{ promotionUpliftType }}
            </p>
          </div>

          <div class="container-content-col flex-50 input-field">
            <label>
              {{ DIALOG_STRINGS.uplift.increase }}
              <span
                *ngIf="
                  (promotion$ | async)?.uplift.type === UPLIFT_TYPES.RELATIVE
                "
                >%</span
              >
            </label>
            <p
              *ngIf="
                (promotion$ | async)?.uplift
                  .increase as promotionUpliftIncrease;
                else notAvailable
              ">
              {{ promotionUpliftIncrease }}

              <span *ngIf="promotionUpliftIncrease === UPLIFT_TYPES.RELATIVE"
                >(%)</span
              >
            </p>
          </div>
        </div>

        <h3 class="promotions-container-title">
          {{ DIALOG_STRINGS.specificUplift.title }}
        </h3>
        <div class="container-content-row">
          <div class="container-content-col flex-50 input-field">
            <label for="upliftType">{{ DIALOG_STRINGS.uplift.type }}</label>
            <app-dropdown
              id="upliftType"
              name="upliftType"
              [ngModel]="upliftType$ | async"
              (ngModelChange)="updateSpecificUpliftType($event)"
              [placeholder]="DIALOG_STRINGS.uplift.selectUplift">
              <app-dropdown-option
                *ngFor="let upliftType of UPLIFT_TYPES_OPTIONS"
                [value]="upliftType.value"
                >{{ upliftType.key }}</app-dropdown-option
              >
            </app-dropdown>
          </div>
          <div class="container-content-col flex-50 input-field">
            <label for="upliftIncrease"
              >{{ DIALOG_STRINGS.uplift.increase }}
              <span *ngIf="(upliftType$ | async) === UPLIFT_TYPES.RELATIVE"
                >(%)</span
              ></label
            >

            <ng-container
              *ngIf="canEditUpliftIncrease$ | async; else notAvailable">
              <div class="fields-input-container">
                <div class="optiply-filled-input">
                  <input
                    id="upliftIncrease"
                    type="number"
                    name="specificUpliftIncrease"
                    [ngModel]="upliftIncrease$ | async"
                    (ngModelChange)="updateSpecificUpliftIncrease($event)"
                    [placeholder]="NOT_AVAILABLE"
                    required />
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <h3 class="promotions-container-title">
          {{ DIALOG_STRINGS.settings.title }}
        </h3>
        <div class="container-content-row">
          <app-toggle
            [label]="DIALOG_STRINGS.settings.enabled"
            [checked]="promotionEnabled$ | async"
            [disabled]="true">
          </app-toggle>
        </div>
      </form>
    </div>

    <div appDialogActions>
      <div class="action-buttons">
        <button
          appButton
          variant="tertiary"
          aria-label="Add product promotion dialog cancel button"
          (click)="close()"
          class="optiply-tertiary-btn">
          {{ COMMON_STRINGS.cancel }}
        </button>
        <button
          appButton
          variant="primary"
          aria-label="Add product promotion dialog save button"
          class="optiply-primary-btn"
          [disabled]="
            (saving$ | async) || (promotion$ | async) === null || form.invalid
          "
          (click)="saveAddPromotion()">
          {{ COMMON_STRINGS.save }}
        </button>
      </div>
    </div>
  </div>

  <ng-template #notAvailable>
    <p>{{ NOT_AVAILABLE }}</p>
  </ng-template>
</app-dialog-container>
