export class LoadDisconnectedProducts {
  static readonly type =
    '[Connect Product to Supplier] Load Disconnected Products';

  constructor(
    public search: string = '',
    public supplierUuid: string
  ) {}
}

export class FilterNotConnectedProducts {
  static readonly type = '[Connect Product to Supplier] Filter Products';
  constructor(public productName: string) {}
}

export class SelectDisconnectedProduct {
  static readonly type = '[Connect Product to Supplier] Select Product';

  constructor(public productId: string) {}
}

export class ResetSelectedDisconnectedProduct {
  static readonly type = '[Connect Product to Supplier] Reset Selected Product';
}

export class SkuChange {
  static readonly type = '[Connect Product to Supplier] SKU Change';
  constructor(public sku: string) {}
}

export class NameChange {
  static readonly type = '[Connect Product to Supplier] Name Change';
  constructor(public name: string) {}
}

export class EanChange {
  static readonly type = '[Connect Product to Supplier] Ean Change';
  constructor(public ean: string) {}
}

export class PriceChange {
  static readonly type = '[Connect Product to Supplier] Price Change';
  constructor(public price: string) {}
}
export class MOQChange {
  static readonly type = '[Connect Product to Supplier] MOQ Change';
  constructor(public moq: string) {}
}

export class LotSizeChange {
  static readonly type = '[Connect Product to Supplier] Lot Size Change';
  constructor(public lotSize: string) {}
}

export class AvailabilityChange {
  static readonly type = '[Connect Product to Supplier] Availability Change';
  constructor(public availability: boolean) {}
}

export class PreferredChange {
  static readonly type = '[Connect Product to Supplier] Preferred Change';
  constructor(public preferred: boolean) {}
}

export class SaveSupplierProduct {
  static readonly type = '[Connect Product to Supplier] Save Supplier Product';

  constructor(
    public supplierId: string,
    public closeDialog: boolean = true
  ) {}
}

export class ResetConnectProduct {
  static readonly type = '[Connect Product to Supplier] Reset';
}
