import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({ providedIn: 'root' })
export class GravatarService {
  private md5: Md5 = new Md5();

  generate(input: string, size: number): Observable<string> {
    return of(input).pipe(
      map(value => {
        const hash = this.md5.start().appendStr(value).end();
        const url = `//www.gravatar.com/avatar/${hash}?size=${size}`;
        return url;
      })
    );
  }
}
