<div class="footer">
  @if (showApplyAll()) {
    <div class="apply-all-container">
      <app-checkbox
        [label]="COMMON_STRINGS.filter.applyAllTables"
        [(checked)]="applyAll" />
    </div>
  }

  <div class="footer-actions">
    <button
      aria-label="filter footer cancel button"
      appButton
      variant="tertiary"
      (click)="cancel.emit()">
      {{ COMMON_STRINGS.filter.cancel }}
    </button>

    <button
      aria-label="filter footer apply button"
      appButton
      variant="primary"
      (click)="apply.emit()"
      [disabled]="disableApply()">
      {{ COMMON_STRINGS.filter.apply }}
    </button>
  </div>
</div>
