import { AdviceDetails } from 'src/app/shared/models/buy-orders/v2/buy-order-advice.model';
import { Supplier } from 'src/app/shared/models/suppliers/product-suppliers.model';

export interface SupplyChain {
  loading: boolean;
  failed: boolean;
  activeSupplier: Supplier | null;
  purchaseAdvice: AdviceDetails[];
}

export const defaultSupplyChain: SupplyChain = {
  loading: true,
  failed: false,
  activeSupplier: null,
  purchaseAdvice: [],
};
