<div class="container">
  <ng-content />

  @if (filters().length) {
    <div class="container-separator"></div>

    <div class="filters">
      @if (shouldBeAbleToScroll()) {
        <button
          aria-label="filter group backward button"
          appButton
          appButtonIcon
          variant="link"
          class="custom-button-icon"
          (click)="backward()"
          [disabled]="canScrollPrevious() === false">
          <mat-icon svgIcon="angle-left" />
        </button>
      }

      <div
        class="scroll-container"
        #scrollContainer
        (scroll)="checkScroll($event)">
        @for (filter of filters(); track i; let i = $index) {
          <app-filter-button
            appOverlay
            #overlay="overlay"
            [filter]="filter"
            (removed)="toRemoveFilter($event)"
            [disabled]="disabled()">
            <ng-template cdk-portal>
              <app-overlay-container-v2>
                @switch (filter.uiModel) {
                  @case (FILTER_TYPES.FILTER_LIST) {
                    <app-filter-restricted
                      [filter]="filter"
                      (filtered)="toFilter($event); overlay._closeOverlay()"
                      (canceled)="overlay._closeOverlay()" />
                  }

                  @case (FILTER_TYPES.FILTER_BOOLEAN) {
                    <app-filter-boolean
                      [filter]="filter"
                      (filtered)="toFilter($event); overlay._closeOverlay()"
                      (canceled)="overlay._closeOverlay()" />
                  }

                  @case (FILTER_TYPES.FILTER_NUMBER) {
                    <app-filter-number
                      [filter]="filter"
                      (filtered)="toFilter($event); overlay._closeOverlay()"
                      (canceled)="overlay._closeOverlay()" />
                  }

                  @case (FILTER_TYPES.FILTER_DATE) {
                    <app-filter-date
                      [filter]="filter"
                      (filtered)="toFilter($event); overlay._closeOverlay()"
                      (canceled)="overlay._closeOverlay()" />
                  }

                  @case (FILTER_TYPES.FILTER_NOT_EXISTS) {
                    <app-filter-not-exists
                      [filter]="filter"
                      (filtered)="toFilter($event); overlay._closeOverlay()"
                      (canceled)="overlay._closeOverlay()" />
                  }

                  @case (FILTER_TYPES.FILTER_NULLABLE) {
                    <app-filter-nullable
                      [filter]="filter"
                      (filtered)="toFilter($event); overlay._closeOverlay()"
                      (canceled)="overlay._closeOverlay()" />
                  }

                  @default {
                    <app-filter-text
                      [filter]="filter"
                      (filtered)="toFilter($event); overlay._closeOverlay()"
                      (canceled)="overlay._closeOverlay()" />
                  }
                }
              </app-overlay-container-v2>
            </ng-template>
          </app-filter-button>
        }
      </div>

      @if (shouldBeAbleToScroll()) {
        <button
          aria-label="filter group forward button"
          appButton
          appButtonIcon
          variant="link"
          class="custom-button-icon"
          (click)="forward()"
          [disabled]="canScrollNext() === false">
          <mat-icon svgIcon="angle-right" />
        </button>
      }
    </div>
  }
</div>
