import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { catchError, concatMap, delay, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  LoadSucceed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { PromotionsService } from 'src/app/core/api/promotions/v1/promotions.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  AddOrUpdateProperties,
  ProductsPromotion,
  ProductsPromotions,
} from 'src/app/shared/models/promotion/promotion.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../../add-products-to-promotion-data-table/model/add-products-to-promotion-data-table.model';
import {
  AddProductsToAPromotion,
  AddSearchParam,
  ClearSelection,
  ColumnsSelected,
  HideColumn,
  Initialize,
  LoadDisconnectedProducts,
  MasterToggleSelection,
  Paginate,
  RemoveSearchParam,
  Reset,
  ResetPagination,
  ResetPaginationAndLoadData,
  Sort,
  ToggleRowSelection,
} from '../actions/add-products-to-promotion.actions';
import { ProductsV3Service } from 'src/app/core/api/products/v3/products-v3.service';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface AddProductsPromotionStateModel extends DatatableStateModel {
  loading: boolean;
  page: Pageable;
  promotionUuid: string;
  products: ProductsPromotions[];
  selection: TableSelection<{ uuid: string }>;
}

@State<AddProductsPromotionStateModel>({
  name: 'addProductsPromotionState',
  defaults: {
    page: { totalElements: 0 },
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    filterOpened: false,
    sortBy: defaultSort,
    search: [],
    loading: false,
    products: [],
    promotionUuid: null,
    selection: {},
  },
})
@Injectable()
export class AddProductsPromotionState extends DatatableState {
  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [AddProductsPromotionState],
      (state: AddProductsPromotionStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static selection(
    state: AddProductsPromotionStateModel
  ): TableSelection<{ uuid: string }> {
    return state.selection;
  }

  @Selector()
  static products(state: AddProductsPromotionStateModel): ProductsPromotions[] {
    return state.products;
  }

  constructor(
    private store: Store,
    private productV3Service: ProductsV3Service,
    private promotionsService: PromotionsService
  ) {
    super();
  }

  @Action(Initialize, { cancelUncompleted: true })
  initializePurchase(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: Initialize
  ) {
    ctx.patchState({
      loading: true,
      promotionUuid: payload.promotionUuid,
    });

    return ctx
      .dispatch(new LoadDisconnectedProducts(payload.promotionUuid))
      .pipe(concatMap(() => ctx.dispatch(new LoadSucceed(false))));
  }

  @Action(LoadDisconnectedProducts, { cancelUncompleted: true })
  loadDisconnectedProducts(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: LoadDisconnectedProducts
  ) {
    ctx.patchState({
      loading: true,
    });

    return this._fetchDisconnectedProductsV2(ctx, payload.promotionUuid);
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: Paginate
  ) {
    const state = ctx.getState();

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
      loading: true,
    });

    return ctx.dispatch(
      new LoadDisconnectedProducts(ctx.getState().promotionUuid)
    );
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(AddSearchParam, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<AddProductsPromotionStateModel>({
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(RemoveSearchParam, { cancelUncompleted: true })
  removeSearchParam(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<AddProductsPromotionStateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: ColumnsSelected
  ) {
    ctx.setState(
      patch({
        columnsGroups: {
          ...ctx.getState().columnsGroups,
          ...payload.selection.columnsGroups,
        },
      })
    );
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<AddProductsPromotionStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: HideColumn
  ) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    ctx.patchState({
      columnsGroups: newColumnSelection,
    });
  }

  @Action(ToggleRowSelection, { cancelUncompleted: true })
  toggleRowSelection(
    ctx: StateContext<AddProductsPromotionStateModel>,
    payload: ToggleRowSelection
  ) {
    const selectedCopy = { ...ctx.getState().selection };

    if (!payload.isSelected) {
      delete selectedCopy[payload.rowKey];

      ctx.patchState({
        selection: selectedCopy,
      });

      return;
    }

    ctx.patchState({
      selection: {
        ...ctx.getState().selection,
        [payload.rowKey]: {
          uuid: payload.rowKey,
        },
      },
    });
  }

  @Action(MasterToggleSelection, { cancelUncompleted: true })
  masterToggleSelection(ctx: StateContext<AddProductsPromotionStateModel>) {
    const products = ctx.getState().products;

    const existingSelection = ctx.getState().selection;

    const productsUuid = products.map(line => line.uuid);

    const selectedUids = new Set(Object.keys(existingSelection));

    const allSelected = productsUuid.every(line => selectedUids.has(line));

    const selectionCopy = new Map(Object.entries(existingSelection));

    if (allSelected) {
      products.forEach(product => {
        selectionCopy.delete(product.uuid);
      });
    } else {
      products.forEach(product => {
        selectionCopy.set(product.uuid, {
          uuid: product.uuid,
        });
      });
    }

    ctx.patchState({
      selection: {
        ...Object.fromEntries(selectionCopy.entries()),
      },
    });
  }

  @Action(ClearSelection, { cancelUncompleted: true })
  clearSelection(ctx: StateContext<AddProductsPromotionStateModel>) {
    ctx.patchState({
      selection: {},
    });
  }

  @Action(AddProductsToAPromotion)
  addProductsToPromotion(ctx: StateContext<AddProductsPromotionStateModel>) {
    const productsToBeAdded: AddOrUpdateProperties[] = Object.values(
      ctx.getState().selection
    );

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._addProductsToPromotion(ctx, productsToBeAdded)),
      delay(1000),
      concatMap(() => ctx.dispatch(new SaveSucceed()))
    );
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(
    ctx: StateContext<AddProductsPromotionStateModel>
  ) {
    return ctx.dispatch([
      new ResetPagination(),
      new LoadDisconnectedProducts(ctx.getState().promotionUuid),
    ]);
  }

  sort(ctx: StateContext<AddProductsPromotionStateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadDisconnectedProducts(ctx.getState().promotionUuid));
  }

  @Action(Reset, { cancelUncompleted: true })
  resetBuyOrder(ctx: StateContext<AddProductsPromotionStateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      page: { totalElements: 0 },
      pagination: defaultPagination,
      columnsGroups: defaultColumnsV2,
      filtersGroups: defaultFiltersV2,
      filterOpened: false,
      sortBy: defaultSort,
      search: [],
      loading: false,
      products: [],
      promotionUuid: null,
      selection: {},
    });
  }

  private _fetchDisconnectedProductsV2(
    ctx: StateContext<AddProductsPromotionStateModel>,
    promotionUuid: string
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildRequestInfoV2(ctx.getState());

    return this.productV3Service
      .findDisconnectedProductsInPromotion(
        webshopUuid,
        promotionUuid,
        requestInfo
      )
      .pipe(
        tap((productsPromotion: ProductsPromotion) => {
          ctx.patchState({
            loading: false,
            products: productsPromotion.data,
            page: {
              totalElements: productsPromotion.metadata.page.totalElements,
            },
          });
        }),
        catchError(e => {
          ctx.patchState({
            loading: false,
          });

          ctx.dispatch(new LoadFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildRequestInfoV2(
    state: AddProductsPromotionStateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _buildTempSearch(keyword: string): string {
    if (!keyword) return '';

    return `sku:${keyword},sku%${keyword},category:${keyword.toUpperCase()},demand:${keyword},id:${keyword},name%${keyword},serviceLevel:${keyword},notBeingBought:${keyword.toLocaleUpperCase()},promotionName:${keyword},promotionName%${keyword},promotionStartDate:${keyword},promotionStartDate>${keyword},promotionStartDate<${keyword},promotionEndDate:${keyword},promotionEndDate>${keyword},promotionEndDate<${keyword}`;
  }

  private _addProductsToPromotion(
    ctx: StateContext<AddProductsPromotionStateModel>,
    products: AddOrUpdateProperties[]
  ): Observable<any> {
    return this.promotionsService
      .updateWebshopProductInPromotions(
        { addOrUpdate: products, remove: [] },
        ctx.getState().promotionUuid
      )
      .pipe(
        catchError(() => {
          ctx.patchState({
            loading: false,
          });

          return ctx.dispatch(new SaveFailed());
        })
      );
  }
}
