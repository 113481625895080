import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  numberAttribute,
} from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'progressbar',
    tabindex: '-1',
    class: 'progress-spinner',
    '[style.width.px]': 'diameter',
    '[style.height.px]': 'diameter',
    '[attr.aria-valuemin]': '0',
    '[attr.aria-valuemax]': '100',
    '[attr.aria-valuenow]': 'null',
    '[attr.mode]': 'mode',
  },
})
export class ProgressSpinnerComponent {
  private readonly BASE_SIZE = 20;

  @Input({ transform: numberAttribute })
  get diameter(): number {
    return this._diameter;
  }
  set diameter(size: number) {
    this._diameter = size ?? 0;
  }
  private _diameter = this.BASE_SIZE;

  strokeWidth(): number {
    return this.diameter / 10;
  }
}
