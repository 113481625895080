export const STRINGS = {
  columns: {
    exports: {
      name: {
        key: 'name',
        name: $localize`Name`,
        filterKey: 'name',
        currency: false,
        containers: false,
      },
      fileType: {
        key: 'fileType',
        name: $localize`File type`,
        filterKey: 'fileType',
        currency: false,
        containers: false,
      },
      source: {
        key: 'source',
        name: $localize`Source`,
        filterKey: 'source',
        currency: false,
        containers: false,
      },
      createdAt: {
        key: 'createdAt',
        name: $localize`Created`,
        filterKey: 'createdAt',
        currency: false,
        containers: false,
      },
      updatedAt: {
        key: 'updatedAt',
        name: '',
        filterKey: 'updatedAt',
        currency: false,
        containers: false,
      },
      status: {
        key: 'status',
        name: $localize`Status`,
        filterKey: 'status',
        currency: true,
        containers: false,
      },
      actions: {
        key: 'actions',
        name: '',
        currency: false,
        containers: false,
      },
    },
  },
  metadata: {
    title: $localize`Exports`,
    renameExportDialog: {
      placeholder: $localize`Enter file name`,
    },
    actions: {
      download: $localize`Download`,
      rename: $localize`Rename`,
      export: $localize`Export`,
    },
    states: {
      loading: $localize`Loading contents...`,
      noData: $localize`There is no data available.`,
    },
    exportsDialog: {
      title: $localize`Export as...`,
      subtitle: $localize`Tables with larges amounts of data might take some time to export`,
      fields: {
        fileName: $localize`File name`,
        fileNamePlaceholder: $localize`Enter file name`,
        format: $localize`Format`,
        export: $localize`Export`,
        selected: $localize`Only selected rows`,
        full: $localize`Full table`,
      },
    },
    formats: {
      csv: $localize`.csv (Comma separated values)`,
      xlsx: $localize`.xlsx (Microsoft Excel spreadsheet)`,
    },
  },
};
