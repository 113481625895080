<div class="section input-data-section">
  <div>
    <h3 class="section-subtitle">
      @if (!!customTitle) {
        <ng-container [ngTemplateOutlet]="customTitle" />
      } @else {
        {{ settingsTitle }}
      }
    </h3>
    <p class="section-description">
      {{ settingsDescription }}
    </p>
  </div>

  <div class="section-description-container">
    <ng-content></ng-content>
  </div>
</div>
