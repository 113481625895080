<div
  [appTooltip]="
    (panelOpenState$ | async)
      ? MESSAGES.common.overview.show
      : MESSAGES.common.overview.hide
  "
  tooltipPosition="below"
  [tooltipShowDelay]="300">
  <mat-icon
    class="top-overview-icon-container"
    (click)="showTopOverview()"
    svgIcon="info-rounded"
    [class]="
      (panelOpenState$ | async) ? 'tooltip-icon' : 'tooltip-icon-selected'
    "></mat-icon>
</div>
