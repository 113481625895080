import { Observable } from 'rxjs';
import {
  EditedProductPromotion,
  WebshopProductPromotionsEditorListener,
} from './product-promotions-editor.model';

export abstract class BaseWebshopProductPromotionsV2EditorState<T> {
  constructor(
    private productPromotionsListener: WebshopProductPromotionsEditorListener<T>
  ) {}

  /**
   * Removes a product promotion from the editor .
   * @param promotionUUID the promotion uuid to filter out from the array.
   * @param editedProductPromotions the edited product promotions immutable array.
   */
  protected _filterProductPromotionOut(
    promotionUUID: string,
    editedProductPromotions: EditedProductPromotion[]
  ): EditedProductPromotion[] {
    return editedProductPromotions.filter(
      productPromotion => productPromotion.promotionUUID !== promotionUUID
    );
  }

  /**
   * Returns the edited product promotions for a specific promotion.
   * @param promotionUUID the promotion uuid to search for.
   * @param editedProductPromotions the array of edited product promotions.
   * @returns one or more edited product promotions. More than one line is considered an error.
   */
  protected _getProductPromotionLine(
    promotionUUID: string,
    editedProductPromotions: EditedProductPromotion[]
  ): EditedProductPromotion[] {
    return editedProductPromotions.filter(
      productPromotion => productPromotion.promotionUUID === promotionUUID
    );
  }

  /* Specific Uplift Type */
  protected _buildUpdateUpliftTypeNewProductPromotion(
    productPromotion: T,
    newType: string
  ) {
    const newProductPromotion: EditedProductPromotion = {
      promotionUUID:
        this.productPromotionsListener.promotionUUID(productPromotion),
      uuid: this.productPromotionsListener.uuid(productPromotion),
      uplift: {
        increase:
          this.productPromotionsListener.upliftIncrease(productPromotion),
        type: newType,
      },
    };

    return { newProductPromotion };
  }

  protected _buildUpdateUpliftTypeExistingEditedProductPromotion(
    productPromotion: T,
    editedProductPromotion: EditedProductPromotion,
    editedProductPromotions: EditedProductPromotion[],
    newType: string
  ) {
    const newProductPromotion: EditedProductPromotion = {
      promotionUUID: editedProductPromotion.promotionUUID,
      uuid: editedProductPromotion.uuid,
      uplift: {
        increase: editedProductPromotion.uplift.increase,
        type: newType,
      },
    };

    const newLines = this._filterProductPromotionOut(
      this.productPromotionsListener.promotionUUID(productPromotion),
      editedProductPromotions
    );

    return { newProductPromotion, newLines };
  }

  /* Specific Uplift Increase */
  protected _buildUpdateUpliftIncreaseNewProductPromotion(
    productPromotion: T,
    newIncrease: string
  ) {
    const newProductPromotion: EditedProductPromotion = {
      promotionUUID:
        this.productPromotionsListener.promotionUUID(productPromotion),
      uuid: this.productPromotionsListener.uuid(productPromotion),
      uplift: {
        increase: Number(newIncrease),
        type: this.productPromotionsListener.upliftType(productPromotion),
      },
    };

    return { newProductPromotion };
  }

  protected _buildUpdateUpliftIncreaseExistingEditedProductPromotion(
    productPromotion: T,
    editedProductPromotion: EditedProductPromotion,
    editedProductPromotions: EditedProductPromotion[],
    newIncrease: string
  ) {
    const newProductPromotion: EditedProductPromotion = {
      promotionUUID: editedProductPromotion.promotionUUID,
      uuid: editedProductPromotion.uuid,
      uplift: {
        increase: Number(newIncrease),
        type: editedProductPromotion.uplift.type,
      },
    };

    const newLines = this._filterProductPromotionOut(
      this.productPromotionsListener.promotionUUID(productPromotion),
      editedProductPromotions
    );

    return { newProductPromotion, newLines };
  }

  public duplicatedEditedProductPromotionsError(
    promotionUUID: string
  ): Observable<never> {
    throw new Error(
      `Internal error: Edited product promotions cannot be duplicated. Affected Promotion UUID ${promotionUUID}`
    );
  }
}
