<div
  class="expansion-panel"
  [class.expansion-panel-disabled]="disabled"
  [class.expansion-panel-expanded]="!disabled && panelExpanded()">
  <ng-content select="app-expansion-panel-header"></ng-content>

  <div
    role="region"
    class="expansion-panel-content"
    [@panelExpansion]="panelExpansionState()">
    <div class="expansion-panel-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
