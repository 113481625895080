import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ConnectWebSocket, SendWebSocketMessage } from '@ngxs/websocket-plugin';
import {
  catchError,
  concatMap,
  delay,
  EMPTY,
  mergeMap,
  Observable,
  of,
  tap,
} from 'rxjs';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { ConceptBuyOrderV2Service } from 'src/app/core/api/buy-order-concepts/v2/concept-buy-order-v2.service';
import { CreateConceptResult } from 'src/app/core/api/buy-order-concepts/v2/model/concept-buy-order-v2-model';
import { BuyOrderV2Service } from 'src/app/core/api/buy-order/v2/buy-order-v2.service';
import { ProposedBuyOrderlinesOverviewPayload } from 'src/app/core/api/buy-order/v2/model/proposed-buy-orderlines-overview-v2.model';
import {
  OrderPlacing,
  ProposedSession,
} from 'src/app/core/api/buy-order/v2/model/proposed-buy-orderlines-v2.model';
import { DEFAULT_BUY_ORDERS_APPLY_COLUMN_SELECTION_ALL } from 'src/app/core/constants/global.constants';
import { AuthenticationState } from 'src/app/core/states/authentication.state';
import { WebshopState } from 'src/app/core/states/webshop.state';

import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  HandlePlaceOrder,
  InitializePurchaseConfirmation,
  SetOrderPlaced,
} from 'src/app/shared/components/purchase-confirmation-dialog-v2/actions/purchase-confirmation-dialog-v2.actions';
import {
  PurchaseConfirmationRudderData,
  PurchaseConfirmationV2Overview,
} from 'src/app/shared/components/purchase-confirmation-dialog-v2/model/purchase-confirmation-v2.model';
import { PurchaseConfirmationQueries } from 'src/app/shared/components/purchase-confirmation-dialog-v2/state/purchase-confirmation-dialog-v2.queries';
import {
  BuyOrderlinesOverview,
  BuyOrderlinesOverviewOrder,
} from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2.model';
import {
  CreateConceptModel,
  UpdateConceptModel,
} from 'src/app/shared/models/buy-orders/v2/concept-buy-orders-v2.model';

import {
  ProposedBuyOrderline,
  ProposedBuyOrderlines,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';

import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import { ReconnectWebsocket } from '../../../actions/purchase-v3.actions';
import { OrderlinesSelection } from '../../../model/purchase-v3.model';
import { STRINGS } from '../../../model/purchase-v3.strings';
import { SocketHandlingService } from '../../../services/socket-handling.service';
import { SocketDisconnection } from '../../proposed-orderlines/actions/proposed-orderlines.actions';
import {
  SocketStatus,
  SocketType,
} from '../../proposed-orderlines/model/proposed-orderlines.model';
import {
  InitializeConceptsAvailableProductsTable,
  LoadAvailableProductsV2,
  OrderLineAdded,
  OrderLinesAdded,
} from '../actions/available-products.actions';
import {
  AddFilterParam,
  AddSearchParam,
  ClearSelection,
  ConfirmOrder,
  CreateConcept,
  GenerateSession,
  HeaderInfoConceptPersistence,
  HideColumn,
  InitializeConceptLinesTable,
  InitializePurchase,
  LoadConceptLinesOverviewV2,
  LoadConceptLinesV2,
  MasterToggleSelection,
  OrderLineChanged,
  OrderlineErrored,
  OrderLineRemoved,
  OrderLinesRemoved,
  Paginate,
  PatchLinePrice,
  PatchLineQuantity,
  PatchLineVolume,
  PatchLineWeight,
  PersistSession,
  PlaceAndPlanOrder,
  PlaceManual,
  PlaceOrder,
  RefreshConceptLinesPage,
  ReloadBothTables,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveProductFromOrder,
  RemoveProductsFromOrder,
  RemoveSearchParam,
  ResetBuyOrder,
  ResetPagination,
  ResetPaginationAndLoadData,
  SetColumnSelection,
  Sort,
  ToggleFilter,
  ToggleRowSelection,
  UpdateConcept,
} from '../actions/concept-orderlines.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/concept-orderlines-data-table.model';
import { ServiceRequestInfoV3ConceptOrderlines } from '../model/concept-orderlines.model';
import { environment } from 'src/environments/environment';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { defaultFactor } from '../../proposed-orderlines/model/proposed-orderlines-data-table.model';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import { AccountState } from 'src/app/core/states/account.state';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  defaultDatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { PlacedOrder } from 'src/app/shared/models/purchase/v2/purchase-v2.model';
import { upsertSort } from 'src/app/shared/utils/sortby-persistence';
import { FetchSupplierV2 } from 'src/app/features/suppliers-v2/actions/suppliers-v2.actions';
import {
  AddSharedFilterParam,
  AddSharedSearchParam,
  RemoveAllSharedFilters,
  RemoveSharedSearchParam,
  ToggleSharedFilter,
} from '../actions/concept-shared.actions';
import { SessionContext } from 'src/app/core/api/buy-order/v2/model/buy-order-v2.model';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { InitializeNotes } from '../../purchase-overview/components/purchase-notes/actions/purchase-notes.actions';

export interface ConceptOrderLinesStateModel extends DatatableStateModel {
  applySameColumnsToAll: boolean;
  orderLines: ProposedBuyOrderline[];
  page: Pageable;
  loading: boolean;
  overview: BuyOrderlinesOverviewOrder;
  sessionUuid: string | null;
  factor: {
    value: number;
    hasChanged: boolean;
  };
  supplierUuid: string;
  buyOrderConceptUuid: string | null;
  orderMomentUuid: string | null;
  selection: TableSelection<OrderlinesSelection>;
  shouldReconnect: boolean;
  isDisconnected: boolean;
  isOrderNotSynced: boolean;
  disableActions: boolean;
  disableRefreshButton: boolean;
}

@State<ConceptOrderLinesStateModel>({
  name: 'conceptOrderLinesState',
  defaults: {
    ...defaultDatatableStateModel,
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    applySameColumnsToAll: DEFAULT_BUY_ORDERS_APPLY_COLUMN_SELECTION_ALL,
    sortBy: defaultSort,
    search: [],
    orderLines: [],
    page: { totalElements: 0 },
    loading: true,
    overview: null,
    sessionUuid: null,
    supplierUuid: '',
    filterOpened: false,
    factor: defaultFactor,
    buyOrderConceptUuid: null,
    orderMomentUuid: null,
    selection: {},
    shouldReconnect: false,
    isDisconnected: false,
    isOrderNotSynced: false,
    disableActions: false,
    disableRefreshButton: false,
  },
})
@Injectable()
export class ConceptOrderlinesState extends DatatableState {
  private readonly NOTIFICATIONS = STRINGS.notifications.purchaseEditor;

  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [ConceptOrderlinesState],
      (state: ConceptOrderLinesStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static columns(
    state: ConceptOrderLinesStateModel
  ): DatatableColumnV2Groups<DatatableColumnV2> {
    return state.columnsGroups;
  }

  constructor(
    private store: Store,
    private buyOrderV2Service: BuyOrderV2Service,
    private conceptBuyOrderV2Service: ConceptBuyOrderV2Service,
    private socketHandlingService: SocketHandlingService,
    private notificationService: NotificationCenterService,
    private accountSettingsService: AccountSettingsService
  ) {
    super();
  }

  @Action(PersistSession, { cancelUncompleted: true })
  persistSession(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: PersistSession
  ) {
    ctx.patchState({
      sessionUuid: payload.sessionUuid,
    });
  }

  @Action(GenerateSession, { cancelUncompleted: true })
  generateSession(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: GenerateSession
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    let sessionContext: SessionContext = {
      orderType: 'CONCEPT',
      supplierUuid: payload.supplierUuid,
      buyOrderConceptUuid: payload.conceptUuid,
      orderMomentUuid: payload.orderMomentUuid,
    };
    ctx.patchState({
      shouldReconnect: false,
    });

    if (!!ctx.getState().sessionUuid) {
      const socketUrl = environment.production
        ? this._getProdSocketUrl(
            webshopUuid,
            ctx.getState().sessionUuid,
            ctx.getState().buyOrderConceptUuid
          )
        : this._getDefaultSocketUrl(
            webshopUuid,
            ctx.getState().sessionUuid,
            ctx.getState().buyOrderConceptUuid
          );

      ctx.patchState({
        shouldReconnect: true,
      });

      return ctx.dispatch(
        new ConnectWebSocket({
          url: socketUrl,
        })
      );
    }

    return this.buyOrderV2Service
      .generateSession(webshopUuid, sessionContext)
      .pipe(
        tap((session: ProposedSession) => {
          const socketUrl = environment.production
            ? this._getProdSocketUrl(
                webshopUuid,
                session.sessionUuid,
                ctx.getState().buyOrderConceptUuid
              )
            : this._getDefaultSocketUrl(
                webshopUuid,
                session.sessionUuid,
                ctx.getState().buyOrderConceptUuid
              );

          ctx.patchState({
            sessionUuid: session.sessionUuid,
            shouldReconnect: true,
          });

          this.store.dispatch(
            new ConnectWebSocket({
              url: socketUrl,
            })
          );
        }),
        catchError(e => {
          ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
          );

          throw new Error(e.message || e);
        })
      );
  }

  @Action(ReconnectWebsocket, { cancelUncompleted: true })
  reconnectSocket(ctx: StateContext<ConceptOrderLinesStateModel>) {
    if (!ctx.getState().shouldReconnect) return;

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const socketUrl = environment.production
      ? this._getProdSocketUrl(
          webshopUuid,
          ctx.getState().sessionUuid,
          ctx.getState().buyOrderConceptUuid
        )
      : this._getDefaultSocketUrl(
          webshopUuid,
          ctx.getState().sessionUuid,
          ctx.getState().buyOrderConceptUuid
        );

    ctx.patchState({ isDisconnected: false });

    return this.store.dispatch(
      new ConnectWebSocket({
        url: socketUrl,
      })
    );
  }

  @Action(SocketDisconnection, { cancelUncompleted: true })
  handleSocketDisconnection(_ctx: StateContext<ConceptOrderLinesStateModel>) {
    _ctx.patchState({
      isDisconnected: true,
    });
  }

  @Action([
    PatchLinePrice,
    PatchLineQuantity,
    PatchLineVolume,
    PatchLineWeight,
    RemoveProductFromOrder,
  ])
  connectOnDemand(_ctx: StateContext<ConceptOrderLinesStateModel>) {
    if (_ctx.getState().isDisconnected === true) {
      _ctx.dispatch(new ReconnectWebsocket());
    }
  }

  @Action(InitializePurchase, { cancelUncompleted: true })
  initializePurchase(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: InitializePurchase
  ) {
    ctx.patchState({
      loading: true,
      buyOrderConceptUuid: payload.conceptUuid,
      supplierUuid: payload.supplierUuid,
      orderMomentUuid: payload.orderMomentUuid,
      overview: null,
      disableRefreshButton: false,
    });

    return ctx
      .dispatch(
        new GenerateSession(
          payload.conceptUuid,
          payload.supplierUuid,
          payload.orderMomentUuid
        )
      )
      .pipe(
        concatMap(() =>
          ctx.dispatch([
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            ),
            new InitializeConceptLinesTable(payload.conceptUuid),
            new InitializeConceptsAvailableProductsTable(payload.conceptUuid),
            new LoadConceptLinesOverviewV2(payload.conceptUuid),
          ])
        ),
        concatMap(() =>
          ctx.dispatch(new FetchSupplierV2(payload.supplierUuid))
        ),
        concatMap(() => ctx.dispatch(new InitializeNotes())),
        catchError(e => {
          ctx.dispatch(new LoadFailed());

          throw new Error(e.message || e);
        })
      );
  }

  @Action(InitializeConceptLinesTable, { cancelUncompleted: true })
  initializeConceptLinesTable(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: InitializeConceptLinesTable
  ) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.draftOrderEditorProposedV2TableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    const sortBy = upsertSort(
      STRINGS.columns.purchaseEditor[tableSettings.sortBy]?.filterKey,
      tableSettings.orderBy,
      ctx.getState().sortBy
    );

    ctx.setState(
      patch<ConceptOrderLinesStateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
        sortBy,
      })
    );

    return ctx.dispatch(new LoadConceptLinesV2(payload.conceptUuid));
  }

  @Action(LoadConceptLinesV2, { cancelUncompleted: true })
  loadConceptOrderlines(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: LoadConceptLinesV2
  ) {
    ctx.patchState({
      disableActions: true,
    });

    return this._fetchConceptOrderlines(ctx, payload.conceptUuid).pipe(
      catchError(() => {
        ctx.patchState({
          loading: false,
          disableActions: false,
          orderLines: [],
          page: { totalElements: 0 },
        });

        return of(false);
      })
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<ConceptOrderLinesStateModel>) {
    ctx.setState(
      patch({
        loading: true,
      })
    );

    return ctx
      .dispatch(new LoadConceptLinesV2(ctx.getState().buyOrderConceptUuid))
      .pipe(
        tap(() => {
          ctx.setState(
            patch({
              loading: false,
            })
          );
        })
      );
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<ConceptOrderLinesStateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(HeaderInfoConceptPersistence, { cancelUncompleted: true })
  headerInfoConceptPersistence(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: HeaderInfoConceptPersistence
  ) {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    const requestInfo: AccountSettingsUpdate =
      this._buildSaveHeaderInfoPersistanceRequestInfo(payload.openClose);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ConceptOrderLinesStateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() =>
          ctx.dispatch(
            new LoadConceptLinesV2(ctx.getState().buyOrderConceptUuid)
          )
        ),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(
      new LoadConceptLinesV2(ctx.getState().buyOrderConceptUuid)
    );
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(LoadConceptLinesOverviewV2, { cancelUncompleted: true })
  loadProposedOrderlinesOverview(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: LoadConceptLinesOverviewV2
  ) {
    return this._fetchConceptOrderlinesOverview(ctx, payload.conceptUuid).pipe(
      tap((overview: BuyOrderlinesOverview) => {
        ctx.patchState({
          overview: overview.order,
        });
      }),
      catchError(() => {
        ctx.patchState({
          overview: null,
        });

        return of(false);
      })
    );
  }

  @Action(RefreshConceptLinesPage, { cancelUncompleted: true })
  refreshOrderlinesPage(ctx: StateContext<ConceptOrderLinesStateModel>) {
    ctx.patchState({
      disableRefreshButton: true,
    });
    return ctx.dispatch(new Saving(true, 'Syncing...')).pipe(
      concatMap(() => {
        return ctx.dispatch(new ReloadBothTables());
      }),
      delay(2000),
      concatMap(() => {
        return ctx.dispatch(new SaveSucceed(true, 'Data synced sucessfully!'));
      }),
      delay(10000),
      concatMap(() => {
        ctx.patchState({ disableRefreshButton: false });
        return of(true);
      })
    );
  }

  @Action(PatchLinePrice, { cancelUncompleted: true })
  patchLinePrice(
    _ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: PatchLinePrice
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          price: parseFloat(payload.changedPrice),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(PatchLineQuantity, { cancelUncompleted: true })
  patchLineQuantity(
    _ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: PatchLineQuantity
  ) {
    if (parseInt(payload.changedQuantity) === 0) {
      this.store.dispatch(
        new SendWebSocketMessage({
          type: SocketType.SINGLE,
          data: {
            status: SocketStatus.REMOVED,
            supplierProductUuid: payload.orderline.uuid,
          },
          token: this.store.selectSnapshot(AuthenticationState.token),
        })
      );
    } else {
      this.store.dispatch(
        new SendWebSocketMessage({
          type: SocketType.SINGLE,
          data: {
            status: SocketStatus.EXISTING,
            supplierProductUuid: payload.orderline.uuid,
            quantity: parseInt(payload.changedQuantity),
          },
          token: this.store.selectSnapshot(AuthenticationState.token),
        })
      );
    }
  }

  @Action(PatchLineWeight, { cancelUncompleted: true })
  patchLineWeight(
    _ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: PatchLineWeight
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          weight: parseFloat(payload.changedWeight),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(PatchLineVolume, { cancelUncompleted: true })
  patchLineVolume(
    _ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: PatchLineVolume
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          volume: parseFloat(payload.changedVolume),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(RemoveProductFromOrder, { cancelUncompleted: true })
  removeProductFromOrder(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: RemoveProductFromOrder
  ) {
    this.notificationService.showToast(this.NOTIFICATIONS.removingProducts);

    this._removeFromSelection(ctx, payload.supplierProductUuid);

    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.REMOVED,
          supplierProductUuid: payload.supplierProductUuid,
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(RemoveProductsFromOrder, { cancelUncompleted: true })
  removeProductsFromOrder(ctx: StateContext<ConceptOrderLinesStateModel>) {
    this.notificationService.showToast(this.NOTIFICATIONS.removingProducts);

    const supplierProductUuids = Object.values(ctx.getState().selection);

    if (supplierProductUuids.length === 0) return;

    this.clearSelection(ctx);

    if (supplierProductUuids.length === 1) {
      this.store.dispatch(
        new RemoveProductFromOrder(supplierProductUuids[0].supplierProductUuid)
      );

      return;
    }

    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.BULK,
        data: {
          status: SocketStatus.REMOVED,
          orderLines: supplierProductUuids,
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(OrderLineChanged, { cancelUncompleted: true })
  changeLine(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: OrderLineChanged
  ) {
    ctx.setState(
      patch<ConceptOrderLinesStateModel>({
        orderLines: updateItem<ProposedBuyOrderline>(
          orderline => orderline.uuid === payload.orderLine.uuid,
          {
            ...payload.orderLine,
            supplierProduct: {
              ...payload.orderLine.supplierProduct,
              deliveryTime:
                payload.orderLine.supplierProduct.deliveryTime !== undefined
                  ? payload.orderLine.supplierProduct.deliveryTime
                  : null,
            },
            errored: false,
          }
        ),
      })
    );

    return of(EMPTY).pipe(
      delay(500),
      concatMap(() =>
        ctx.dispatch(
          new LoadConceptLinesOverviewV2(ctx.getState().buyOrderConceptUuid)
        )
      )
    );
  }

  @Action(OrderlineErrored, { cancelUncompleted: true })
  orderlineError(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: OrderlineErrored
  ) {
    ctx.setState(
      patch<ConceptOrderLinesStateModel>({
        orderLines: updateItem<ProposedBuyOrderline>(
          orderline => orderline.uuid === payload.supplierProductUuid,
          this._handleError(
            ctx,
            payload.supplierProductUuid,
            payload.errorMessage
          )
        ),
      })
    );
    ctx.dispatch(
      new SaveFailed(
        true,
        payload.supplierProductUuid + ': ' + payload.errorMessage
      )
    );
  }

  @Action([
    OrderLineRemoved,
    OrderLinesRemoved,
    OrderLineAdded,
    OrderLinesAdded,
    ReloadBothTables,
  ])
  removeLine(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    action: ReloadBothTables
  ): void {
    if (action?.payload?.showLoading) {
      ctx.patchState({
        loading: true,
      });
    }

    ctx.dispatch([
      new LoadConceptLinesV2(ctx.getState().buyOrderConceptUuid),
      new LoadAvailableProductsV2(ctx.getState().buyOrderConceptUuid),
      new LoadConceptLinesOverviewV2(ctx.getState().buyOrderConceptUuid),
    ]);
  }

  @Action([OrderLineRemoved, OrderLinesRemoved])
  notifyRemovedOrderlines(
    _ctx: StateContext<ConceptOrderLinesStateModel>
  ): void {
    this.notificationService.showToast(this.NOTIFICATIONS.removedProducts);
  }

  @Action([OrderLineAdded, OrderLinesAdded])
  notifyAddedOrderlines(_ctx: StateContext<ConceptOrderLinesStateModel>): void {
    this.notificationService.showToast(this.NOTIFICATIONS.addedProducts);
  }

  @Action(UpdateConcept, { cancelUncompleted: true })
  updateConcept(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: UpdateConcept
  ) {
    ctx.patchState({
      disableActions: true,
    });

    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildUpdateConceptRequest(state);

    return ctx.dispatch(new Saving(payload.properties.showMessage)).pipe(
      concatMap(() =>
        this.conceptBuyOrderV2Service
          .updateConcept(webshopUuid, state.buyOrderConceptUuid, requestInfo)
          .pipe(
            catchError(e => {
              ctx.patchState({
                disableActions: false,
              });

              ctx.dispatch(new SaveFailed());

              throw new Error(e.message || e);
            })
          )
      ),
      delay(500),
      tap(() => {
        ctx.patchState({
          disableActions: false,
        });
      }),
      concatMap(() =>
        ctx.dispatch(new SaveSucceed(payload.properties.showMessage))
      ),
      catchError(() => {
        return of(false);
      })
    );
  }

  handlePossibleDesync(
    result: CreateConceptResult,
    state: ConceptOrderLinesStateModel
  ): boolean {
    if (
      result.numberOfProducts !== state.overview.numberOfProducts ||
      result.totalValue !== state.overview.totalValue
    ) {
      return true;
    }
    return false;
  }

  @Action(HandlePlaceOrder, { cancelUncompleted: true })
  handlePlaceOrder(ctx: StateContext<ConceptOrderLinesStateModel>) {
    const state = ctx.getState();

    const isNewOrder = this.store.selectSnapshot(
      PurchaseConfirmationQueries.isNewOrder
    );

    if (isNewOrder) {
      return ctx.dispatch(new PlaceManual());
    }

    if (state.overview.planningTrigger) {
      return ctx.dispatch(new PlaceAndPlanOrder());
    }

    return ctx.dispatch(new PlaceOrder());
  }

  @Action(PlaceOrder, { cancelUncompleted: true })
  placeOrder(ctx: StateContext<ConceptOrderLinesStateModel>) {
    window.rudderanalytics.track('Buy Order Placed', {
      data: this._buildRudderData(ctx.getState()),
    });

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._placeOrder(ctx)),
      delay(500),
      concatMap((placedOrder: PlacedOrder) =>
        ctx.dispatch([new SaveSucceed(), new SetOrderPlaced(placedOrder)])
      )
    );
  }

  @Action(PlaceAndPlanOrder, { cancelUncompleted: true })
  placeAndPlanOrder(ctx: StateContext<ConceptOrderLinesStateModel>) {
    /* window.rudderanalytics.track('Buy Order Placed and Planned', {
      data: this._buildRudderData(ctx.getState()),
    }); */

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._placeAndPlanOrder(ctx)),
      delay(500),
      concatMap((placedOrder: PlacedOrder) =>
        ctx.dispatch([new SaveSucceed(), new SetOrderPlaced(placedOrder)])
      )
    );
  }

  @Action(PlaceManual, { cancelUncompleted: true })
  placeManual(ctx: StateContext<ConceptOrderLinesStateModel>) {
    /* window.rudderanalytics.track('Manual Buy Order Placed', {
      data: this._buildRudderData(ctx.getState()),
    }); */

    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._placeManualOrder(ctx)),
      delay(500),
      concatMap((placedOrder: PlacedOrder) =>
        ctx.dispatch([new SaveSucceed(), new SetOrderPlaced(placedOrder)])
      )
    );
  }

  @Action([AddFilterParam, AddSharedFilterParam], { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: AddFilterParam
  ) {
    ctx.patchState({
      loading: true,
    });

    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([AddSearchParam, AddSharedSearchParam], { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: AddSearchParam | AddSharedSearchParam
  ) {
    ctx.setState(
      patch<ConceptOrderLinesStateModel>({
        loading: true,
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([RemoveSearchParam, RemoveSharedSearchParam], {
    cancelUncompleted: true,
  })
  removeSearchParam(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: RemoveSearchParam | RemoveSharedSearchParam
  ) {
    ctx.setState(
      patch<ConceptOrderLinesStateModel>({
        loading: true,
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(SetColumnSelection, { cancelUncompleted: true })
  setColumnSelection(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: SetColumnSelection
  ) {
    ctx.patchState({
      columnsGroups: payload.columnsGroups,
      applySameColumnsToAll: payload.applyAll,
    });
  }

  @Action([RemoveAllFilters, RemoveAllSharedFilters], {
    cancelUncompleted: true,
  })
  removeAllFilters(ctx: StateContext<ConceptOrderLinesStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(
    ctx: StateContext<ConceptOrderLinesStateModel>
  ) {
    this._removeAllFilters(ctx);
  }

  @Action([ToggleFilter, ToggleSharedFilter], { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ConceptOrderLinesStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ConceptOrderLinesStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: HideColumn
  ) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(ResetBuyOrder, { cancelUncompleted: true })
  resetBuyOrder(ctx: StateContext<ConceptOrderLinesStateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      filtersGroups: defaultFiltersV2,
      sortBy: defaultSort,
      search: [],
      pagination: defaultPagination,
      buyOrderConceptUuid: null,
      selection: {},
      shouldReconnect: false,
      sessionUuid: null,
      loading: true,
      orderLines: [],
      overview: null,
    });
  }

  @Action(ToggleRowSelection, { cancelUncompleted: true })
  toggleRowSelection(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: ToggleRowSelection
  ) {
    const selectedCopy = { ...ctx.getState().selection };

    if (!payload.isSelected) {
      delete selectedCopy[payload.rowKey];

      ctx.patchState({
        selection: selectedCopy,
      });

      return;
    }

    ctx.patchState({
      selection: {
        ...ctx.getState().selection,
        [payload.rowKey]: {
          supplierProductUuid: payload.rowKey,
        },
      },
    });
  }

  @Action(MasterToggleSelection, { cancelUncompleted: true })
  masterToggleSelection(ctx: StateContext<ConceptOrderLinesStateModel>) {
    const lines = ctx.getState().orderLines;

    const existingSelection = ctx.getState().selection;

    const linesUuid = lines.map(line => line.uuid);

    const selectedUids = new Set(Object.keys(existingSelection));

    const allSelected = linesUuid.every(line => selectedUids.has(line));

    const selectionCopy = new Map(Object.entries(existingSelection));

    if (allSelected) {
      lines.forEach(line => {
        selectionCopy.delete(line.uuid);
      });
    } else {
      lines.forEach(line => {
        selectionCopy.set(line.uuid, {
          supplierProductUuid: line.supplierProduct.uuid,
        });
      });
    }

    ctx.patchState({
      selection: {
        ...Object.fromEntries(selectionCopy.entries()),
      },
    });
  }

  @Action(ClearSelection, { cancelUncompleted: true })
  clearSelection(ctx: StateContext<ConceptOrderLinesStateModel>) {
    ctx.patchState({
      selection: {},
    });
  }

  @Action(ConfirmOrder, { cancelUncompleted: true })
  confirmOrder(ctx: StateContext<ConceptOrderLinesStateModel>) {
    return ctx
      .dispatch(new UpdateConcept({ showMessage: false }))
      .pipe(
        concatMap(() =>
          ctx.dispatch(
            new InitializePurchaseConfirmation(
              this._buildPurchaseOverview(ctx.getState())
            )
          )
        )
      );
  }

  @Action(CreateConcept, { cancelUncompleted: true })
  createConcept(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    payload: CreateConcept
  ) {
    ctx.patchState({
      disableActions: true,
    });

    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildCreateConceptRequest(state);

    return ctx.dispatch(new Saving(payload.properties.showMessage)).pipe(
      concatMap(() => {
        return this.conceptBuyOrderV2Service
          .createConcept(webshopUuid, requestInfo)
          .pipe(
            tap((result: CreateConceptResult) => {
              ctx.patchState({
                buyOrderConceptUuid: result.uuid,
                isOrderNotSynced: this.handlePossibleDesync(result, state),
                disableActions: false,
              });
            })
          );
      }),
      concatMap(() => {
        return ctx.dispatch(new SaveSucceed(payload.properties.showMessage));
      }),
      catchError(() => {
        ctx.patchState({
          disableActions: false,
        });

        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<ConceptOrderLinesStateModel>) {
    return ctx.dispatch([
      new ResetPagination(),
      new LoadConceptLinesV2(ctx.getState().buyOrderConceptUuid),
    ]);
  }

  override addGroupParam(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ConceptOrderLinesStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ConceptOrderLinesStateModel>, sort: Sorted) {
    if (this._isSortingLineType(sort.key)) {
      ctx.patchState({
        sortBy: {
          [STRINGS.columns.purchaseEditor.EDITED.key]: {
            field: STRINGS.columns.purchaseEditor.EDITED.filterKey,
            order: sort.order,
          },
          [sort.key]: {
            field: sort.key,
            order: sort.order,
          },
        },
      });
    } else {
      ctx.patchState({
        sortBy: {
          [sort.key]: {
            field: sort.key,
            order: sort.order,
          },
        },
      });
    }

    return ctx.dispatch(
      new LoadConceptLinesV2(ctx.getState().buyOrderConceptUuid)
    );
  }

  private _removeAllFilters(ctx: StateContext<ConceptOrderLinesStateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      loading: true,
      search: [],
    });
  }

  private _handleError(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    orderlineUuid: string,
    _errorMessage: string
  ): ProposedBuyOrderline {
    let tempLine: ProposedBuyOrderline = {
      ...ctx.getState().orderLines.find(line => line.uuid === orderlineUuid),
      errored: true,
    };
    return tempLine;
  }

  private _fetchConceptOrderlines(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    conceptUuid: string
  ): Observable<ProposedBuyOrderlines> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildConceptOrderLinesRequestInfo(ctx);

    return this.buyOrderV2Service
      .findConceptOrderlines(webshopUuid, conceptUuid, requestInfo)
      .pipe(
        tap((orderLines: ProposedBuyOrderlines) => {
          ctx.patchState({
            orderLines: orderLines.data,
            page: { totalElements: orderLines.metadata.page.totalElements },
            loading: false,
            disableActions: false,
          });
        }),
        catchError(e => {
          ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _buildConceptOrderLinesRequestInfo(
    ctx: StateContext<ConceptOrderLinesStateModel>
  ): ServiceRequestInfoV3ConceptOrderlines {
    const state = ctx.getState();

    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3ConceptOrderlines = {
      sessionUuid: state.sessionUuid,
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _fetchConceptOrderlinesOverview(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    conceptUuid: string
  ): Observable<BuyOrderlinesOverview> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildProposedOrderlinesOverviewRequestInfo(ctx);

    return this.buyOrderV2Service
      .findConceptLinesOverview(webshopUuid, conceptUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedOverviewRetrieval)
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _buildProposedOrderlinesOverviewRequestInfo(
    ctx: StateContext<ConceptOrderLinesStateModel>
  ): ProposedBuyOrderlinesOverviewPayload {
    const state = ctx.getState();

    return {
      sessionUuid: state.sessionUuid,
    };
  }

  private _buildPurchaseOverview(
    state: ConceptOrderLinesStateModel
  ): PurchaseConfirmationV2Overview {
    return {
      orderUuid: state.buyOrderConceptUuid
        ? state.buyOrderConceptUuid
        : state.overview.uuid,
      orderMomentUuid: state.overview?.orderMoment?.uuid ?? null,
      orderMomentType: state.overview?.orderMoment?.type ?? null,
      orderMomentDate: state.overview?.orderMoment?.date ?? null,
      planningTrigger: state.overview.planningTrigger,
      containerTotalWeight:
        state.overview?.container?.totalContainerWeightCapacity ?? null,
      containerTotalVolume:
        state.overview?.container?.totalContainerVolumeCapacity ?? null,
      supplier: state.overview?.supplier,
      isOrderNotSynced: state.isOrderNotSynced,
    };
  }

  private _buildPlaceOrderRequest(
    ctx: ConceptOrderLinesStateModel
  ): OrderPlacing {
    let saveSupplierProductChanges = this.store.selectSnapshot(
      PurchaseConfirmationQueries.shouldSaveSupplierProductChanges
    );

    let saveWebshopProductChanges = this.store.selectSnapshot(
      PurchaseConfirmationQueries.shouldSaveWebshopProductChanges
    );

    let result = {
      supplierUuid: ctx.overview.supplier.uuid,
      purchaseSettings: {
        sessionUuid: ctx.sessionUuid,
        saveSupplierProductChanges,
        saveWebshopProductChanges,
      },
    };

    const replanEnabled = this.store.selectSnapshot(
      PurchaseConfirmationQueries.replanEnabled
    );

    const planFromDate = this.store
      .selectSnapshot(PurchaseConfirmationQueries.planningDate)
      .toISOString()
      .split('T')[0];

    if (replanEnabled && !!planFromDate) {
      result['planFromDate'] = planFromDate;
    }

    return result;
  }

  private _buildUpdateConceptRequest(
    state: ConceptOrderLinesStateModel
  ): UpdateConceptModel {
    return {
      supplierUuid: state.overview.supplier.uuid,
      orderType: 'CONCEPT',
      planningTrigger: state.overview.planningTrigger,
      sessionUuid: state.sessionUuid,
    };
  }

  private _buildRudderData(
    state: ConceptOrderLinesStateModel
  ): PurchaseConfirmationRudderData {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const order = this.store.selectSnapshot(PurchaseConfirmationQueries.state);

    return {
      webshopUUID: webshopUuid,
      buyOrderConceptUUID: state.buyOrderConceptUuid,
      orderMomentUUID: order.orderMomentUUID,
      orderMomentType: order.orderMomentType,
      orderMomentDate: order.orderMomentDate,
      supplierUUID: order.supplier.uuid,
      planningTrigger: state.overview.planningTrigger,
      newOrder: order.newOrder,
    };
  }

  private _placeOrder(
    ctx: StateContext<ConceptOrderLinesStateModel>
  ): Observable<PlacedOrder> {
    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildPlaceOrderRequest(state);

    return this.buyOrderV2Service
      .placeOrder(
        webshopUuid,
        state.buyOrderConceptUuid,
        state.overview.orderMoment.uuid,
        requestInfo
      )
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _placeAndPlanOrder(
    ctx: StateContext<ConceptOrderLinesStateModel>
  ): Observable<PlacedOrder> {
    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildPlaceOrderRequest(state);

    return this.buyOrderV2Service
      .placeAndPlan(webshopUuid, state.buyOrderConceptUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _placeManualOrder(
    ctx: StateContext<ConceptOrderLinesStateModel>
  ): Observable<PlacedOrder> {
    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildPlaceOrderRequest(state);

    return this.buyOrderV2Service
      .placeManual(webshopUuid, state.buyOrderConceptUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _removeFromSelection(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    key: string
  ): void {
    const selection = { ...ctx.getState().selection };

    delete selection[key];

    ctx.patchState({
      selection: { ...selection },
    });
  }

  private _buildCreateConceptRequest(
    state: ConceptOrderLinesStateModel
  ): CreateConceptModel {
    if (state.overview.orderMoment !== null) {
      return {
        supplierUuid: state.overview.supplier.uuid,
        factor: state.factor.value,
        orderMomentUuid: state.overview.orderMoment.uuid,
        orderType: 'PROPOSED',
        planningTrigger: state.overview.planningTrigger,
        sessionUuid: state.sessionUuid,
      };
    }

    return {
      supplierUuid: state.overview.supplier.uuid,
      factor: state.factor.value,
      orderType: 'PROPOSED',
      planningTrigger: state.overview.planningTrigger,
      sessionUuid: state.sessionUuid,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      draftOrderEditorProposedV2TablePageSize: newPageSize,
    };
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      draftOrderEditorProposedV2TableExcludedColumns: excludedColumns,
      overrideDraftOrderEditorProposedV2TableExcludedColumns: true,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<ConceptOrderLinesStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _isSortingLineType(columnKey: string): boolean {
    return columnKey === STRINGS.columns.purchaseEditor.PROPOSED.key;
  }

  private _getProdSocketUrl(
    webshopUuid: string,
    sessionUuid: string,
    buyOrderConceptUuid: string
  ): string {
    return `wss://app.optiply.com/api/buy-order/v1/${webshopUuid}/edit/${sessionUuid}/concept/${buyOrderConceptUuid}`;
  }

  private _getDefaultSocketUrl(
    webshopUuid: string,
    sessionUuid: string,
    buyOrderConceptUuid: string
  ): string {
    return `wss://dashboard.acceptance.optiply.com/api/buy-order/edge/${webshopUuid}/edit/${sessionUuid}/concept/${buyOrderConceptUuid}`;
  }

  private _buildSaveHeaderInfoPersistanceRequestInfo(
    openClose: string[]
  ): AccountSettingsUpdate {
    return {
      draftOrderEditorHeaderDropdownSelection: openClose,
      overrideDraftOrderEditorHeaderDropdownSelection: true,
    };
  }
}
