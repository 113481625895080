import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { SupplierProductLinks } from 'src/app/shared/models/supplier-product/v2/product-suppliers.model';
import { SupplierProductLinksV2Adapter } from './adapters/supplier-product-links-v2.adapter';
import { SupplierProductLinks as SupplierProductLinksV1 } from './model/supplier-product-v2.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';

@Injectable({
  providedIn: 'root',
})
export class SupplierProductV2Service {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findLinkedSupplierProducts(
    replacements: UrlReplacement[],
    payload: ServiceRequestInfoV3
  ): Observable<SupplierProductLinksV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'supplyGRPC',
      'v2',
      'findLinkedSupplierProducts',
      replacements
    );
    return this.http.post<SupplierProductLinksV1>(endpoint, payload);
  }

  findLinkedSupplierProducts(
    webshopUuid: string,
    webshopProductUuid: string,
    payload: ServiceRequestInfoV3
  ): Observable<SupplierProductLinks> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUuid },
      { name: 'webshopProductUuid', value: webshopProductUuid },
    ];

    return this._findLinkedSupplierProducts(replacements, payload).pipe(
      switchMap(data => of(new SupplierProductLinksV2Adapter().transform(data)))
    );
  }
}
