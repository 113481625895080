import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  inject,
  signal,
} from '@angular/core';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FilterOption, FilterTypeOptions } from '../../model/filter.model';
import {
  FILTER,
  FILTER_SHELL,
  FilterBase,
  FilterEmitted,
  FilterNullGroupV2,
  FilterShell,
  FilterStrategy,
  FilterTypeOptionsV2,
  FilteringStrategies,
} from '../../model/filter-v2.model';
import { FilterStrategiesHandler } from '../core/filter-strategies-handler';
import { FilterV2Component } from '../../filter-base-v2';

@Component({
  selector: 'app-filter-not-exists',
  exportAs: 'filterNotExists',
  templateUrl: './filter-not-exists.component.html',
  styleUrls: ['./filter-not-exists.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: FILTER, useExisting: FilterNotExistsComponent }],
})
export class FilterNotExistsComponent
  extends FilterV2Component
  implements FilterBase, FilteringStrategies<FilterNullGroupV2>
{
  filterStrategiesHandler = new FilterStrategiesHandler();

  filterStrategy: FilterStrategy<FilterNullGroupV2>;

  selectedOption = signal<FilterTypeOptionsV2 | null>(null);

  filterOptions = signal<FilterOption[]>([
    {
      key: MESSAGES.common.filter.optionTypes.notExists,
      value: FilterTypeOptions.NOT_EXISTS,
    },
  ]);

  readonly FILTER_TYPE_OPTIONS = FilterTypeOptions;

  selectOption(option: FilterTypeOptionsV2): void {
    this.selectedOption.set(option);

    this.filterStrategy =
      this.filterStrategiesHandler.getFilterStrategy(option);

    this.filterStrategy?.clear();

    if (this._shouldPopulate()) {
      this._populate();
    }
  }

  clear() {
    this.filterStrategy.clear();
  }

  cancel(): void {
    this.canceled.emit();
  }

  apply(filtered: FilterEmitted): void {
    if (this._isAllowedToApplyFilter()) {
      this.filtered.emit({
        columnKey: this.filter().columnKey,
        optionSelected: this.selectedOption(),
        subOperator: this.filterValues().subOperator,
        values: this.filterStrategy.form,
        applyAll: filtered.applyAll,
      });
    }
  }

  private _isAllowedToApplyFilter(): boolean {
    return this.filterStrategy.hasValue() && !this.filterDisabled();
  }

  private _shouldPopulate(): boolean {
    return this.filterValues()?.operator === this.selectedOption();
  }

  private _populate(): void {
    this.filterStrategy.populate(this.filterValues()?.value);
  }
}
