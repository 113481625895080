<app-dialog-container
  ><div appDialogHeader>
    <h1 appDialogTitle>
      <span
        aria-label="Deliveries order info"
        data-intercom-target="7c6fd697-3acf-4243-97b0-6bde56c2ecdb"
        i18n="deliveries order title">
        Export order
        <app-basic-copy-to-clipboard [copyText]="data.orderId">{{
          data.orderId
        }}</app-basic-copy-to-clipboard>
        - {{ data.supplierName }}
      </span>
    </h1>

    <button
      aria-label="Export close button"
      data-intercom-target="763d5192-0d5b-4c50-811c-3605cb98adae"
      (click)="close()"
      [disabled]="saving$ | async"
      class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div appDialogBody class="custom-body">
    <div class="section-container">
      <h3 class="bracket-title">{{ COMMON_STRINGS.exports.exportOptions }}</h3>

      <app-radio-button-group-v2
        class="options"
        name="format"
        [ngModel]="excludeReceived$ | async"
        (ngModelChange)="updateExcludeReceived($event)">
        <div class="radio-container">
          <app-radio-button-v2 [value]="false">{{
            DELIVERIES_STRINGS.exportOrder
          }}</app-radio-button-v2>

          <app-radio-button-v2 [value]="true">{{
            DELIVERIES_STRINGS.exportDeliveries
          }}</app-radio-button-v2>
        </div>
      </app-radio-button-group-v2>
    </div>

    <div class="section-container">
      <h3 class="bracket-title">
        {{ COMMON_STRINGS.exports.deliveriesTitle }}
      </h3>

      <div class="formats">
        <button
          aria-label="Export as csv button"
          data-intercom-target="fe90a9bc-0ce8-41de-b8d1-2153ba15bef5"
          appButton
          variant="secondary"
          (click)="export('CSV')"
          [disabled]="saving$ | async">
          {{ COMMON_STRINGS.exports.exportCSV }}
        </button>

        <button
          aria-label="Export as xlsx button"
          data-intercom-target="33bbabb0-7314-4ac8-ae56-331e496957b4"
          appButton
          variant="secondary"
          (click)="export('XLSX')"
          [disabled]="saving$ | async">
          {{ COMMON_STRINGS.exports.exportXLSX }}
        </button>

        <button
          aria-label="Export as pdf button"
          data-intercom-target="b5450fef-c2d4-42ab-83eb-a13497a96348"
          appButton
          variant="secondary"
          (click)="export('PDF')"
          [disabled]="saving$ | async">
          {{ COMMON_STRINGS.exports.exportPDF }}
        </button>
      </div>
    </div>

    <app-advanced-settings
      [label]="COMMON_STRINGS.exports.advancedExportSettings">
      <app-purchase-exports-settings
        [selectedOptions]="dynamicExportColumns$ | async" />
    </app-advanced-settings>
  </div>

  <div appDialogActions>
    <button
      data-intercom-target="df643b3f-6ecf-4a0a-89cf-a180d937d9ed"
      aria-label="Export done button"
      appButton
      variant="primary"
      (click)="close()"
      [disabled]="saving$ | async">
      {{ COMMON_STRINGS.done }}

      @if (saving$ | async) {
        <app-progress-spinner />
      }
    </button>
  </div>
</app-dialog-container>
