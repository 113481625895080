import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProductDetailsV2StateQueries } from 'src/app/features/products-v2/components/product-details-v2/state/product-details-v2.queries';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { SupplyChainInformationV2StateQueries } from './state/supply-chain-information-v2.queries';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';

@Component({
  selector: 'app-supply-chain-information-v2',
  templateUrl: './supply-chain-information-v2.component.html',
  styleUrls: ['./supply-chain-information-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainInformationV2Component {
  ignored = select(ProductDetailsV2StateQueries.isIgnored);

  assembled = select(ProductDetailsV2StateQueries.isAssembled);

  activeSupplier = select(SupplyChainInformationV2StateQueries.activeSupplier);

  readonly METADATA = STRINGS.metadata.supplyChainInformation;

  constructor(private store: Store) {
    // intentional
  }

  goToSupplier(supplierUuid: string) {
    this.store.dispatch(
      new NavigateToWithQueryParams(['suppliers', 'details'], {
        supplierUUID: supplierUuid,
      })
    );
  }
}
