import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { UserIntercomData as UserIntercomDataV1 } from 'src/app/shared/models/account/account.model';

import { UserIntercomData } from './model/account.model';

export class AccountIntercomAdapter
  implements DataAdapter<UserIntercomDataV1, UserIntercomData>
{
  transform(data: UserIntercomDataV1): UserIntercomData {
    return {
      email: data.email,
      userHash: data.userHash,
    };
  }
}
