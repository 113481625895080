import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { DateTime } from 'luxon';
import { delay, mergeMap, tap } from 'rxjs';

import { LinkSupplierToAProduct } from 'src/app/shared/components/product-details-v2/components/product-suppliers-v2/actions/product-suppliers-v2.actions';

import { MultiSupplierUpdateProperties } from 'src/app/shared/models/multi-supplier-product-editor/multi-supplier-editor-update.model';
import {
  InitializeAddProductSuppliers,
  ResetAddProductSuppliersState,
  ResetConnectProduct,
  SaveAddedSupplier,
  UpdateAvailability,
  UpdateAvailabilityDate,
  UpdateDeliveryTime,
  UpdateEAN,
  UpdateLotSize,
  UpdateMOQ,
  UpdatePreferred,
  UpdatePrice,
  UpdateSelectedSupplier,
  UpdateSKU,
  UpdateVolume,
  UpdateWeight,
} from '../actions/add-product-supplier-dialog.actions';
import { defaults } from '../model/add-product-supplier-dialog.model';
import { LoadSupplyChainInformation } from '../../../../supply-chain-information-v2/actions/supply-chain-information-v2.actions';

export interface AddProductSupplierDialogStateModel {
  supplierInfo: MultiSupplierUpdateProperties;
}

@State<AddProductSupplierDialogStateModel>({
  name: 'addProductSupplierDialogState',
  defaults: defaults,
})
@Injectable()
export class AddProductSupplierDialogState {
  constructor(private store: Store) {}

  @Selector()
  static invalidSupplier(state: AddProductSupplierDialogStateModel): boolean {
    return state.supplierInfo.supplierId === null;
  }

  @Selector()
  static sku(state: AddProductSupplierDialogStateModel): string | null {
    return state.supplierInfo.sku;
  }

  @Selector()
  static eanCode(state: AddProductSupplierDialogStateModel): string | null {
    return state.supplierInfo.eanCode;
  }

  @Selector()
  static weight(state: AddProductSupplierDialogStateModel): number {
    return state.supplierInfo.weight;
  }

  @Selector()
  static volume(state: AddProductSupplierDialogStateModel): number {
    return state.supplierInfo.volume;
  }

  @Selector()
  static price(state: AddProductSupplierDialogStateModel): number {
    return state.supplierInfo.price;
  }

  @Selector()
  static purchaseInQuantitiesOf(
    state: AddProductSupplierDialogStateModel
  ): number {
    return state.supplierInfo.purchaseInQuantitiesOf;
  }

  @Selector()
  static minimumPurchaseQuantity(
    state: AddProductSupplierDialogStateModel
  ): number {
    return state.supplierInfo.minimumPurchaseQuantity;
  }

  @Selector()
  static deliveryTime(state: AddProductSupplierDialogStateModel): number {
    return state.supplierInfo.deliveryTime;
  }

  @Selector()
  static availability(state: AddProductSupplierDialogStateModel): boolean {
    return state.supplierInfo.availability;
  }

  @Selector()
  static availabilityDate(
    state: AddProductSupplierDialogStateModel
  ): Date | null {
    return state.supplierInfo.availabilityDate
      ? new Date(state.supplierInfo.availabilityDate)
      : null;
  }

  @Selector()
  static preferred(state: AddProductSupplierDialogStateModel): boolean {
    return state.supplierInfo.preferred;
  }

  @Action(InitializeAddProductSuppliers, { cancelUncompleted: true })
  initialize(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: InitializeAddProductSuppliers
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          name: action.payload.productDetails.name,
          webshopProductId: String(action.payload.productDetails.id),
          currentStockOnHand: action.payload.productDetails.stockOnHand,
        }),
      })
    );
  }

  @Action(UpdateSelectedSupplier, { cancelUncompleted: true })
  updateSelectedSupplier(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateSelectedSupplier
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          supplierId: action.selectedSupplierId
            ? String(action.selectedSupplierId)
            : null,
        }),
      })
    );
  }

  @Action(UpdateSKU, { cancelUncompleted: true })
  updateSku(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateSKU
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          sku: action.sku,
        }),
      })
    );
  }

  @Action(UpdateEAN, { cancelUncompleted: true })
  updateEan(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateEAN
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          eanCode: action.ean,
        }),
      })
    );
  }

  @Action(UpdateWeight, { cancelUncompleted: true })
  updateWeight(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateWeight
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          weight: action.weight,
        }),
      })
    );
  }

  @Action(UpdateVolume, { cancelUncompleted: true })
  updateVolume(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateVolume
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          volume: action.volume,
        }),
      })
    );
  }

  @Action(UpdatePrice, { cancelUncompleted: true })
  updatePrice(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdatePrice
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          price: action.price,
        }),
      })
    );
  }

  @Action(UpdateDeliveryTime, { cancelUncompleted: true })
  updateDeliveryTime(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateDeliveryTime
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          deliveryTime: action.deliveryTime,
        }),
      })
    );
  }

  @Action(UpdateLotSize, { cancelUncompleted: true })
  updateLotSize(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateLotSize
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          purchaseInQuantitiesOf: action.lotSize,
        }),
      })
    );
  }

  @Action(UpdateMOQ, { cancelUncompleted: true })
  updateMoq(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateMOQ
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          minimumPurchaseQuantity: action.moq,
        }),
      })
    );
  }

  @Action(UpdateAvailability, { cancelUncompleted: true })
  updateAvailability(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateAvailability
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          availability: action.availability,
          availabilityDate: !action.availability
            ? null
            : ctx.getState().supplierInfo.availabilityDate,
        }),
      })
    );
  }

  @Action(UpdateAvailabilityDate, { cancelUncompleted: true })
  updateAvailabilityDate(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdateAvailabilityDate
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          availabilityDate: DateTime.fromJSDate(
            action.availabilityDate
          ).toISODate(),
        }),
      })
    );
  }

  @Action(UpdatePreferred, { cancelUncompleted: true })
  updatePreferred(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: UpdatePreferred
  ) {
    ctx.setState(
      patch<AddProductSupplierDialogStateModel>({
        supplierInfo: patch<MultiSupplierUpdateProperties>({
          preferred: action.preferred,
        }),
      })
    );
  }

  @Action(SaveAddedSupplier)
  saveAddedSupplier(
    ctx: StateContext<AddProductSupplierDialogStateModel>,
    action: SaveAddedSupplier
  ) {
    return ctx
      .dispatch(new LinkSupplierToAProduct(ctx.getState().supplierInfo))
      .pipe(
        tap(() => {
          if (action.closeDialog) {
            action.dialogRef.close();
            return;
          }

          return this.store.dispatch(new ResetConnectProduct());
        }),
        delay(500),
        mergeMap(() => ctx.dispatch(new LoadSupplyChainInformation()))
      );
  }

  @Action(ResetConnectProduct, { cancelUncompleted: true })
  reset(ctx: StateContext<AddProductSupplierDialogStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...defaults,
      supplierInfo: {
        ...defaults.supplierInfo,
        name: state.supplierInfo.name,
        webshopProductId: String(state.supplierInfo.webshopProductId),
        currentStockOnHand: state.supplierInfo.currentStockOnHand,
      },
    });
  }

  @Action(ResetAddProductSuppliersState, { cancelUncompleted: true })
  resetState(ctx: StateContext<AddProductSupplierDialogStateModel>) {
    ctx.patchState(defaults);
  }
}
