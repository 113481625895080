import {
  APIPageCount,
  DataPageableV2,
} from '../../models/datatable/v2/pageable-v2.model';
import { DataAdapter } from '../adapter';

export class DatatablePaginationCountAdapter
  implements DataAdapter<APIPageCount, DataPageableV2>
{
  transform(data: APIPageCount): DataPageableV2 {
    return {
      totalElements: data.count ?? 0,
    };
  }
}
