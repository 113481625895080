export class ShowTopOverview {
  static readonly type = '[Top Overview] show';

  constructor() {}
}

export class HideTopOverview {
  static readonly type = '[Top Overview] hide';

  constructor() {}
}
