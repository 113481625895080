import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-overlay-container-v2',
  templateUrl: './overlay-container-v2.component.html',
  styleUrls: ['./overlay-container-v2.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'overlay-container-v2-host',
  },
})
export class OverlayContainerV2Component {}
