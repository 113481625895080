import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Account } from 'src/app/shared/models/account/account.model';
import {
  AuthenticationUpdated,
  LoginSucessful,
  Logout,
  UpdateAuthAccount,
} from '../actions/authentication.action';
import { AuthenticationService } from '../services/authentication.service';
import { NotificationCenterService } from '../services/notification-center.service';
import { patch } from '@ngxs/store/operators';

export interface AuthenticationStateModel {
  /**
   * Current account associated with this authentication state.
   */
  account: Account;

  /**
   * Stores the authentication state.
   */
  authenticated: boolean;

  token: string;
}

/**
 * Authetication state store.
 * Keeps the current authentication state across all aplication.
 */
@State<AuthenticationStateModel>({
  name: 'authenticationState',
  defaults: { account: null, authenticated: false, token: '' },
})
@Injectable()
export class AuthenticationState {
  constructor(
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private notificationsService: NotificationCenterService
  ) {}

  @Selector()
  static account(state: AuthenticationStateModel): Account {
    return state.account;
  }
  @Selector()
  static token(state: AuthenticationStateModel): string {
    return state.token;
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthenticationStateModel>, _action: Logout) {
    ctx.patchState({ account: null, authenticated: false });
  }

  @Action(AuthenticationUpdated)
  authenticationUpdate(
    ctx: StateContext<AuthenticationStateModel>,
    action: AuthenticationUpdated
  ) {
    const account = this.authenticationService.user;

    if (action.authenticated) {
      ctx.dispatch(new LoginSucessful());
      this.authService
        .getAccessTokenSilently()
        .subscribe(acessToken => ctx.patchState({ token: acessToken }));
    }

    ctx.patchState({ authenticated: action.authenticated, account });
  }

  @Action(UpdateAuthAccount)
  updateAuthAccount(
    ctx: StateContext<AuthenticationStateModel>,
    payload: UpdateAuthAccount
  ) {
    ctx.setState(
      patch({
        account: patch({
          name: payload.userName,
        }),
      })
    );
  }
}
