<app-dialog-container
  ><div class="confirmation-dialog">
    <div appDialogHeader>
      <mat-icon
        #dialogIcon
        *ngIf="props.icon"
        [svgIcon]="props.icon"
        class="header-icon"></mat-icon>
      <h1 appDialogTitle>
        {{ props.title }}
      </h1>

      @if (!props.hideCloseButton) {
        <button
          aria-label="Base confirmation close button"
          (click)="close()"
          class="close-button">
          <mat-icon svgIcon="cross"></mat-icon>
        </button>
      }
    </div>

    <div appDialogBody>
      <span [innerHTML]="props.body"></span>
    </div>

    <div appDialogActions [class.footer-space-around]="!noHelpLinkProvided">
      @if (!noHelpLinkProvided) {
        <div class="footer-help">
          <span
            appTooltip="The link provided is invalid"
            i18n-appTooltip="base confirmation dialog link invalid"
            [tooltipDisabled]="!noHelpLinkProvided && !invalidHelpLink">
            <button
              class="optiply-tertiary-btn"
              (click)="goToHelp()"
              [disabled]="noHelpLinkProvided || invalidHelpLink">
              <mat-icon
                svgIcon="questionmark-rounded"
                class="help-button-icon"></mat-icon>
              <span>{{ COMMON_STRINGS.help }}</span>
            </button>
          </span>
        </div>
      }

      <div class="footer-actions">
        <button
          appButton
          variant="tertiary"
          aria-label="Base confirmation cancel button"
          (click)="cancel()">
          {{ props.cancelName }}
        </button>

        <button appButton [variant]="props.actionType" (click)="confirm()">
          {{ props.actionName }}

          @if (loading()) {
            <app-progress-spinner />
          }
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
