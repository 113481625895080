<div class="data-table-v2-wrapper">
  <div class="data-table-v2-wrapper-header">
    <div class="data-table-v2-title-container">
      <div class="data-table-v2-title">
        {{ title() }}
      </div>
      <div class="data-table-v2-subtitle">
        {{ subTitle() }}
      </div>
    </div>
  </div>

  <div class="data-table-v2-content-container">
    <ng-content />
  </div>
</div>
