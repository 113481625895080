import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Job, JobError } from '../model/integrations.model';

export class ExportJobsAdapter implements DataAdapter<any, Job[]> {
  transform(result: any): Job[] {
    if (result.jobs) {
      return result.jobs.map(job => {
        return {
          env_id: 'dev.hotglue.optiply.nl',
          name: job.job_name,
          status: job.status,
          startDate: job.start_time,
          lastUpdateDate: job.last_updated,
          metrics: job.metrics
            ? this.buildMetrics(job.metrics.exportSummary.BuyOrders)
            : null,
          errors: job.metrics
            ? this.buildErrors(job.metrics.exportDetails.BuyOrders)
            : null,
          successes: job.metrics
            ? this.buildSuccess(job.metrics.exportDetails.BuyOrders)
            : null,
          totalRows: job.metrics
            ? Object.keys(job.metrics.exportSummary.BuyOrders).length / 4
            : 0,
          hotglue: true,
          tenant: job.tenant,
        };
      });
    }
  }

  buildErrors(buyOrders: any[]): JobError[] {
    let errors: JobError[] = [];
    buyOrders.forEach(buyOrder => {
      if (buyOrder.success === false) {
        errors.push({
          buyOrderId: buyOrder.externalId,
          info: buyOrder.error,
        });
      }
    });

    return errors;
  }

  buildSuccess(buyOrders: any[]): JobError[] {
    let successes: JobError[] = [];
    buyOrders.forEach(buyOrder => {
      if (buyOrder.success) {
        successes.push({
          buyOrderId: buyOrder.externalId,
          info: buyOrder.id,
        });
      }
    });

    return successes;
  }

  buildMetrics(buyOrder: any): any {
    return {
      success: buyOrder.success,
      fail: buyOrder.fail,
    };
  }
}
