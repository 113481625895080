import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-supply-chain-section-container',
  templateUrl: './supply-chain-section-container.component.html',
  styleUrls: ['./supply-chain-section-container.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainSectionContainerComponent {
  constructor() {
    // intentional
  }
}
