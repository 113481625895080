import { Injectable } from '@angular/core';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  ProposedBuyOrderline,
  ProposedBuyOrderlines,
} from 'src/app/shared/models/buy-orders/v2/proposed-buy-orderlines-v2.model';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFactor,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/proposed-orderlines-data-table.model';
import { BuyOrderV2Service } from 'src/app/core/api/buy-order/v2/buy-order-v2.service';
import {
  AddFilterParam,
  AddSearchParam,
  ClearSelection,
  CreateConcept,
  GenerateSession,
  HeaderInfoProposedPersistence,
  HideColumn,
  InitializeProposedOrderlinesTable,
  InitializePurchase,
  LoadProposedOrderlinesOverviewV2,
  LoadProposedOrderlinesV2,
  LoseFocus,
  MasterToggleSelection,
  OrderLineChanged,
  OrderlineErrored,
  OrderLineRemoved,
  OrderLineSnoozed,
  OrderLinesRemoved,
  OrderLinesSnoozed,
  Paginate,
  PatchLinePrice,
  PatchLineQuantity,
  PatchLineVolume,
  PatchLineWeight,
  RefreshOrderlinesPage,
  ReloadBothTables,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveProductFromOrder,
  RemoveProductsFromOrder,
  RemoveSearchParam,
  ResetBuyOrder,
  ResetPagination,
  ResetPaginationAndLoadData,
  SaveConcept,
  SetColumnSelection,
  SnoozeProduct,
  SnoozeProducts,
  SocketDisconnection,
  Sort,
  ToggleFilter,
  ToggleRowSelection,
  UpdateConcept,
  UpdateSessionForFactor,
} from '../actions/proposed-orderlines.actions';
import {
  catchError,
  concatMap,
  delay,
  EMPTY,
  mergeMap,
  Observable,
  of,
  tap,
} from 'rxjs';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import {
  ServiceRequestInfoV3ProposedOrderlines,
  SocketStatus,
  SocketType,
} from '../model/proposed-orderlines.model';
import {
  ProposedSession,
  UpdateProposedSessionForFactorPayload,
} from 'src/app/core/api/buy-order/v2/model/proposed-buy-orderlines-v2.model';
import { AuthenticationState } from 'src/app/core/states/authentication.state';
import { ConnectWebSocket, SendWebSocketMessage } from '@ngxs/websocket-plugin';
import {
  InitializeAvailableProductsTable,
  LoadAvailableProductsV2,
  OrderLineAdded,
  OrderLinesAdded,
} from '../actions/available-products.actions';
import { STRINGS } from '../../../model/purchase-v3.strings';
import { ProposedBuyOrderlinesOverviewPayload } from 'src/app/core/api/buy-order/v2/model/proposed-buy-orderlines-overview-v2.model';
import { ConceptBuyOrderV2Service } from 'src/app/core/api/buy-order-concepts/v2/concept-buy-order-v2.service';
import {
  CreateConceptModel,
  UpdateConceptModel,
} from 'src/app/shared/models/buy-orders/v2/concept-buy-orders-v2.model';

import {
  BuyOrderlinesOverview,
  BuyOrderlinesOverviewOrder,
} from 'src/app/shared/models/buy-orders/v2/buy-orderlines-overview-v2.model';
import {
  CheckFactor,
  EnlargeOrderSize,
} from 'src/app/shared/components/order-enlarger-v2/actions/order-enlarger-v2.actions';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import {
  OrderlinesSelection,
  ProposedOrderlinesSelection,
} from '../../../model/purchase-v3.model';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ErrorGeneral } from 'src/app/shared/models/error/error.model';
import { ReconnectWebsocket } from '../../../actions/purchase-v3.actions';
import { DEFAULT_BUY_ORDERS_APPLY_COLUMN_SELECTION_ALL } from 'src/app/core/constants/global.constants';
import { CreateConceptResult } from 'src/app/core/api/buy-order-concepts/v2/model/concept-buy-order-v2-model';
import { environment } from 'src/environments/environment';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import { AccountState } from 'src/app/core/states/account.state';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import {
  UpdateMultipleWebshopProductsProperties,
  UpdateWebshopProductProperties,
} from 'src/app/core/api/products/v3/model/products-v3.model';
import { ProductsV3Service } from 'src/app/core/api/products/v3/products-v3.service';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { upsertSort } from 'src/app/shared/utils/sortby-persistence';
import { FetchSupplierV2 } from 'src/app/features/suppliers-v2/actions/suppliers-v2.actions';
import {
  AddSharedFilterParam,
  AddSharedSearchParam,
  RemoveAllSharedFilters,
  RemoveSharedSearchParam,
  ToggleSharedFilter,
} from '../actions/proposed-shared.actions';
import { SessionContext } from 'src/app/core/api/buy-order/v2/model/buy-order-v2.model';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { InitializeNotes } from '../../purchase-overview/components/purchase-notes/actions/purchase-notes.actions';

export interface ProposedOrderlinesStateModel extends DatatableStateModel {
  applySameColumnsToAll: boolean;
  proposedOrderlines: ProposedBuyOrderline[];
  page: Pageable;
  loading: boolean;
  overview: BuyOrderlinesOverviewOrder;
  sessionUuid: string;
  supplierUuid: string;
  factor: {
    value: number;
    hasChanged: boolean;
  };
  buyOrderConceptUuid: string | null;
  orderMomentUuid: string;
  orderMomentType: string;
  selection: TableSelection<ProposedOrderlinesSelection>;
  shouldReconnect: boolean;
  isDisconnected: boolean;
  isOrderNotSynced: boolean;
  future: boolean;
  disableActions: boolean;
  disableRefreshButton: boolean;
  isPageOnFocus: boolean;
  proposedOrderHeaderSelection: string[];
}

@State<ProposedOrderlinesStateModel>({
  name: 'proposedOrderlinesState',
  defaults: {
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    applySameColumnsToAll: DEFAULT_BUY_ORDERS_APPLY_COLUMN_SELECTION_ALL,
    sortBy: defaultSort,
    search: [],
    proposedOrderlines: [],
    page: { totalElements: 0 },
    loading: true,
    overview: null,
    sessionUuid: '',
    supplierUuid: '',
    filterOpened: false,
    factor: defaultFactor,
    buyOrderConceptUuid: null,
    orderMomentUuid: null,
    orderMomentType: '',
    selection: {},
    shouldReconnect: false,
    isDisconnected: false,
    isOrderNotSynced: false,
    future: false,
    disableActions: false,
    disableRefreshButton: false,
    isPageOnFocus: false,
    proposedOrderHeaderSelection: [],
  },
})
@Injectable()
export class ProposedOrderlinesState extends DatatableState {
  private readonly NOTIFICATIONS = STRINGS.notifications.purchaseEditor;

  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [ProposedOrderlinesState],
      (state: ProposedOrderlinesStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static orderlines(
    state: ProposedOrderlinesStateModel
  ): ProposedBuyOrderline[] {
    return state.proposedOrderlines;
  }

  @Selector()
  static columns(
    state: ProposedOrderlinesStateModel
  ): DatatableColumnV2Groups<DatatableColumnV2> {
    return state.columnsGroups;
  }

  constructor(
    private store: Store,
    private buyOrderService: BuyOrderV2Service,
    private conceptBuyOrderV2Service: ConceptBuyOrderV2Service,
    private notificationService: NotificationCenterService,
    private accountSettingsService: AccountSettingsService,
    private productsService: ProductsV3Service
  ) {
    super();
  }

  @Action(InitializePurchase, { cancelUncompleted: true })
  initializePurchase(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: InitializePurchase
  ) {
    ctx.patchState({
      loading: true,
      supplierUuid: payload.supplierUuid,
      orderMomentUuid: payload.orderMomentUuid,
      orderMomentType: payload.orderMomentType,
      overview: null,
      future: payload.future === 'true',
      disableRefreshButton: false,
      isPageOnFocus: true,
    });

    return ctx.dispatch(new GenerateSession()).pipe(
      concatMap(() =>
        ctx.dispatch([
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          ),
          new InitializeProposedOrderlinesTable(payload.supplierUuid),
          new InitializeAvailableProductsTable(payload.supplierUuid),
          new LoadProposedOrderlinesOverviewV2(
            payload.supplierUuid,
            payload.orderMomentUuid
          ),
          new FetchSupplierV2(payload.supplierUuid),
        ])
      ),
      concatMap(() => ctx.dispatch(new InitializeNotes())),
      delay(180000),
      concatMap(() => ctx.dispatch(new RefreshOrderlinesPage())),
      catchError(() => {
        return ctx.dispatch(new LoadFailed());
      })
    );
  }

  @Action(InitializeProposedOrderlinesTable, { cancelUncompleted: true })
  initializeProposedOrderlinesTable(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: InitializeProposedOrderlinesTable
  ) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.proposedOrderEditorProposedV2TableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    const sortBy = upsertSort(
      STRINGS.columns.purchaseEditor[tableSettings.sortBy]?.filterKey,
      tableSettings.orderBy,
      ctx.getState().sortBy
    );

    ctx.setState(
      patch<ProposedOrderlinesStateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
        sortBy,
      })
    );

    return ctx.dispatch(new LoadProposedOrderlinesV2(payload.supplierUuid));
  }

  @Action(RefreshOrderlinesPage, { cancelUncompleted: true })
  refreshOrderlinesPage(ctx: StateContext<ProposedOrderlinesStateModel>) {
    if (!ctx.getState().isPageOnFocus) {
      return of(null);
    }

    ctx.patchState({
      disableRefreshButton: true,
    });

    return ctx.dispatch(new ReloadBothTables()).pipe(
      delay(1000),
      concatMap(() => {
        ctx.patchState({ disableRefreshButton: false });
        return of(true);
      }),
      delay(180000),
      concatMap(() => ctx.dispatch(new RefreshOrderlinesPage()))
    );
  }

  @Action(LoseFocus, { cancelUncompleted: true })
  loseFocus(ctx: StateContext<ProposedOrderlinesStateModel>) {
    ctx.patchState({
      isPageOnFocus: false,
    });
  }

  @Action(LoadProposedOrderlinesV2, { cancelUncompleted: true })
  loadProposedOrderlines(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: LoadProposedOrderlinesV2
  ) {
    ctx.patchState({
      supplierUuid: payload.supplierUuid,
      disableActions: true,
    });

    return this._fetchProposedOrderlines(ctx, payload.supplierUuid).pipe(
      catchError(() => {
        ctx.patchState({
          loading: false,
          disableActions: false,
          proposedOrderlines: [],
          page: { totalElements: 0 },
        });

        return of(false);
      })
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<ProposedOrderlinesStateModel>) {
    ctx.setState(patch({ loading: true }));

    return ctx
      .dispatch(new LoadProposedOrderlinesV2(ctx.getState().supplierUuid))
      .pipe(
        tap(() => {
          ctx.setState(patch({ loading: false }));
        })
      );
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<ProposedOrderlinesStateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(HeaderInfoProposedPersistence, { cancelUncompleted: true })
  headerInfoProposedPersistence(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: HeaderInfoProposedPersistence
  ) {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    const requestInfo: AccountSettingsUpdate =
      this._buildSaveHeaderInfoPersistanceRequestInfo(payload.openClose);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ProposedOrderlinesStateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() =>
          ctx.dispatch(
            new LoadProposedOrderlinesV2(ctx.getState().supplierUuid)
          )
        ),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(
      new LoadProposedOrderlinesV2(ctx.getState().supplierUuid)
    );
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(LoadProposedOrderlinesOverviewV2, { cancelUncompleted: true })
  loadProposedOrderlinesOverview(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: LoadProposedOrderlinesOverviewV2
  ) {
    return this._fetchProposedOrderlinesOverview(
      ctx,
      payload.supplierUuid
    ).pipe(
      tap((overview: BuyOrderlinesOverview) => {
        ctx.patchState({
          overview: overview.order,
        });
      }),
      catchError(() => {
        ctx.patchState({
          overview: null,
        });

        return of(false);
      })
    );
  }

  @Action(ReconnectWebsocket, { cancelUncompleted: true })
  reconectSocket(ctx: StateContext<ProposedOrderlinesStateModel>) {
    if (ctx.getState().shouldReconnect) {
      const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;
      if (environment.production) {
        this.store.dispatch(
          new ConnectWebSocket({
            url:
              'wss://app.optiply.com/api/buy-order/v1/' +
              webshopUuid +
              '/edit/' +
              ctx.getState().sessionUuid +
              '/proposed',
          })
        );
      } else {
        this.store.dispatch(
          new ConnectWebSocket({
            url:
              'wss://dashboard.acceptance.optiply.com/api/buy-order/edge/' +
              webshopUuid +
              '/edit/' +
              ctx.getState().sessionUuid +
              '/proposed',
          })
        );
      }
      ctx.patchState({ isDisconnected: false });
    }
  }

  @Action(GenerateSession, { cancelUncompleted: true })
  generateSession(ctx: StateContext<ProposedOrderlinesStateModel>) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;
    let sessionContext: SessionContext = {
      orderType: 'PROPOSED',
      supplierUuid: ctx.getState().supplierUuid,
      orderMomentUuid: ctx.getState().orderMomentUuid,
    };
    if (environment.production) {
      return this.buyOrderService
        .generateSession(webshopUuid, sessionContext)
        .pipe(
          tap((session: ProposedSession) => {
            ctx.patchState({
              sessionUuid: session.sessionUuid,
              shouldReconnect: true,
            });

            this.store.dispatch(
              new ConnectWebSocket({
                url: `wss://app.optiply.com/api/buy-order/v1/${webshopUuid}/edit/${session.sessionUuid}/proposed`,
              })
            );
          }),
          catchError(e => {
            ctx.dispatch(
              new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
            );

            throw new Error(e.message || e);
          })
        );
    } else {
      return this.buyOrderService
        .generateSession(webshopUuid, sessionContext)
        .pipe(
          tap((session: ProposedSession) => {
            ctx.patchState({
              sessionUuid: session.sessionUuid,
              shouldReconnect: true,
            });

            this.store.dispatch(
              new ConnectWebSocket({
                url: `wss://dashboard.acceptance.optiply.com/api/buy-order/edge/${webshopUuid}/edit/${session.sessionUuid}/proposed`,
              })
            );
          }),
          catchError(e => {
            ctx.dispatch(
              new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
            );

            throw new Error(e.message || e);
          })
        );
    }
  }

  @Action(UpdateSessionForFactor, { cancelUncompleted: true })
  updateSessionForFactor(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payloadData: UpdateSessionForFactor
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;
    const sessionUuid = ctx.getState().sessionUuid;

    let payload: UpdateProposedSessionForFactorPayload = {
      resetAllLinesProposedFields: true,
      resetAllLinesTypeFields: true,
      factor: payloadData.factor,
    };

    return this.buyOrderService
      .updateSession(webshopUuid, sessionUuid, payload)
      .pipe(
        catchError((e: ErrorGeneral) => {
          return ctx.dispatch(
            new SaveFailed(
              true,
              this.NOTIFICATIONS.sessionUpdateFailed + e.error.message
            )
          );
        })
      );
  }

  @Action(SocketDisconnection, { cancelUncompleted: true })
  handleSocketDisconnection(_ctx: StateContext<ProposedOrderlinesStateModel>) {
    _ctx.patchState({
      isDisconnected: true,
    });
  }

  @Action([
    PatchLinePrice,
    PatchLineQuantity,
    PatchLineVolume,
    PatchLineWeight,
    RemoveProductFromOrder,
  ])
  connectOnDemand(_ctx: StateContext<ProposedOrderlinesStateModel>) {
    if (_ctx.getState().isDisconnected === true) {
      _ctx.dispatch(new ReconnectWebsocket());
    }
  }

  @Action(PatchLinePrice, { cancelUncompleted: true })
  patchLinePrice(
    _ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: PatchLinePrice
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          price: parseFloat(payload.changedPrice),
          factor: parseFloat(_ctx.getState().factor.value as unknown as string),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(PatchLineQuantity, { cancelUncompleted: true })
  patchLineQuantity(
    _ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: PatchLineQuantity
  ) {
    if (parseInt(payload.changedQuantity) === 0) {
      this.store.dispatch(
        new SendWebSocketMessage({
          type: SocketType.SINGLE,
          data: {
            status: SocketStatus.REMOVED,
            supplierProductUuid: payload.orderline.uuid,
            factor: parseFloat(
              _ctx.getState().factor.value as unknown as string
            ),
          },
          token: this.store.selectSnapshot(AuthenticationState.token),
        })
      );
    } else {
      this.store.dispatch(
        new SendWebSocketMessage({
          type: SocketType.SINGLE,
          data: {
            status: SocketStatus.EXISTING,
            supplierProductUuid: payload.orderline.uuid,
            quantity: parseInt(payload.changedQuantity),
            factor: parseFloat(
              _ctx.getState().factor.value as unknown as string
            ),
          },
          token: this.store.selectSnapshot(AuthenticationState.token),
        })
      );
    }
  }

  @Action(PatchLineWeight, { cancelUncompleted: true })
  patchLineWeight(
    _ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: PatchLineWeight
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          weight: parseFloat(payload.changedWeight),
          factor: parseFloat(_ctx.getState().factor.value as unknown as string),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(PatchLineVolume, { cancelUncompleted: true })
  patchLineVolume(
    _ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: PatchLineVolume
  ) {
    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.orderline.uuid,
          volume: parseFloat(payload.changedVolume),
          factor: parseFloat(_ctx.getState().factor.value as unknown as string),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(RemoveProductFromOrder, { cancelUncompleted: true })
  removeProductFromOrder(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: RemoveProductFromOrder
  ) {
    this.notificationService.showToast(this.NOTIFICATIONS.removingProducts);

    this._removeFromSelection(ctx, payload.supplierProductUuid);

    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.SINGLE,
        data: {
          status: SocketStatus.REMOVED,
          supplierProductUuid: payload.supplierProductUuid,
          factor: parseFloat(ctx.getState().factor.value as unknown as string),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(RemoveProductsFromOrder, { cancelUncompleted: true })
  removeProductsFromOrder(ctx: StateContext<ProposedOrderlinesStateModel>) {
    this.notificationService.showToast(this.NOTIFICATIONS.removingProducts);

    const supplierProductUuids = Object.values(ctx.getState().selection);

    if (supplierProductUuids.length === 0) return;

    this.clearSelection(ctx);

    if (supplierProductUuids.length === 1) {
      this.store.dispatch(
        new RemoveProductFromOrder(supplierProductUuids[0].supplierProductUuid)
      );

      return;
    }

    this.store.dispatch(
      new SendWebSocketMessage({
        type: SocketType.BULK,
        data: {
          status: SocketStatus.REMOVED,
          orderLines: supplierProductUuids.map(spUuids => ({
            supplierProductUuid: spUuids.supplierProductUuid,
          })),
        },
        token: this.store.selectSnapshot(AuthenticationState.token),
      })
    );
  }

  @Action(SnoozeProduct, { cancelUncompleted: true })
  snoozeProduct(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: SnoozeProduct
  ) {
    this.notificationService.showToast(this.NOTIFICATIONS.snoozingProducts);

    this.clearSelection(ctx);

    const resumingPurchase = payload.resumingPurchase || null;

    const properties: Partial<UpdateWebshopProductProperties> = {
      notBeingBought: true,
      resumingPurchase:
        resumingPurchase === null
          ? {
              null: 'NULL_VALUE',
            }
          : {
              value: resumingPurchase,
            },
    };

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this._updateProduct(
      ctx,
      webshopUuid,
      payload.webshopProductUuid,
      properties
    ).pipe(
      mergeMap(() => {
        const data = {
          status: SocketStatus.EXISTING,
          supplierProductUuid: payload.supplierProductUuid,
          notBeingBought: true,
          resumingPurchase,
          factor: parseFloat(ctx.getState().factor.value as unknown as string),
        };

        return this.store.dispatch(
          new SendWebSocketMessage({
            type: SocketType.SINGLE,
            data: data,
            token: this.store.selectSnapshot(AuthenticationState.token),
          })
        );
      }),
      tap(() => {
        payload.dialogRef?.close();
      })
    );
  }

  @Action(SnoozeProducts, { cancelUncompleted: true })
  snoozeProducts(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: SnoozeProducts
  ) {
    this.notificationService.showToast(this.NOTIFICATIONS.snoozingProducts);

    const supplierProductUuids = Object.values(ctx.getState().selection);

    if (supplierProductUuids.length === 0) return;

    this.clearSelection(ctx);

    const resumingPurchase = payload.resumingPurchase || null;

    if (supplierProductUuids.length === 1) {
      return this.store.dispatch(
        new SnoozeProduct(
          supplierProductUuids[0].supplierProductUuid,
          resumingPurchase,
          supplierProductUuids[0].webshopProductUuid
        )
      );
    }

    const properties = this._buildSnoozeMultiUpdateProperties(
      supplierProductUuids,
      resumingPurchase
    );

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this._updateMultipleProducts(ctx, webshopUuid, properties).pipe(
      mergeMap(() => {
        const socketData = {
          status: SocketStatus.EXISTING,
          orderLines: supplierProductUuids.map(spUuids => ({
            supplierProductUuid: spUuids.supplierProductUuid,
          })),
          notBeingBought: true,
          resumingPurchase,
        };

        return this.store.dispatch(
          new SendWebSocketMessage({
            type: SocketType.BULK,
            data: socketData,
            token: this.store.selectSnapshot(AuthenticationState.token),
          })
        );
      }),
      tap(() => {
        payload.dialogRef?.close();
      })
    );
  }

  @Action(OrderLineChanged, { cancelUncompleted: true })
  changeLine(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: OrderLineChanged
  ) {
    ctx.setState(
      patch<ProposedOrderlinesStateModel>({
        proposedOrderlines: updateItem<ProposedBuyOrderline>(
          orderline => orderline.uuid === payload.orderLine.uuid,
          {
            ...payload.orderLine,
            supplierProduct: {
              ...payload.orderLine.supplierProduct,
              deliveryTime:
                payload.orderLine.supplierProduct.deliveryTime !== undefined
                  ? payload.orderLine.supplierProduct.deliveryTime
                  : null,
            },
            errored: false,
          }
        ),
      })
    );

    return of(EMPTY).pipe(
      delay(500),
      concatMap(() =>
        ctx.dispatch(
          new LoadProposedOrderlinesOverviewV2(
            ctx.getState().supplierUuid,
            ctx.getState().orderMomentUuid
          )
        )
      )
    );
  }

  @Action(OrderlineErrored, { cancelUncompleted: true })
  orderlineError(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: OrderlineErrored
  ) {
    ctx.setState(
      patch<ProposedOrderlinesStateModel>({
        proposedOrderlines: updateItem<ProposedBuyOrderline>(
          orderline => orderline.uuid === payload.supplierProductUuid,
          this._handleError(
            ctx,
            payload.supplierProductUuid,
            payload.errorMessage
          )
        ),
      })
    );
    ctx.dispatch(
      new SaveFailed(
        true,
        payload.supplierProductUuid + ': ' + payload.errorMessage
      )
    );
  }

  _handleError(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    orderlineUuid: string,
    _errorMessage: string
  ): ProposedBuyOrderline {
    let tempLine: ProposedBuyOrderline = {
      ...ctx
        .getState()
        .proposedOrderlines.find(line => line.uuid === orderlineUuid),
      errored: true,
    };
    return tempLine;
  }

  @Action([
    OrderLineRemoved,
    OrderLinesRemoved,
    OrderLineAdded,
    OrderLinesAdded,
    ReloadBothTables,
    OrderLineSnoozed,
    OrderLinesSnoozed,
  ])
  removeLine(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    action: ReloadBothTables
  ): void {
    if (action?.payload?.showLoading) {
      ctx.patchState({
        loading: true,
      });
    }

    ctx.dispatch([
      new LoadProposedOrderlinesV2(ctx.getState().supplierUuid),
      new LoadAvailableProductsV2(ctx.getState().supplierUuid),
      new LoadProposedOrderlinesOverviewV2(
        ctx.getState().supplierUuid,
        ctx.getState().orderMomentUuid
      ),
    ]);
  }

  @Action([OrderLineRemoved, OrderLinesRemoved])
  notifyRemovedOrderlines(
    _ctx: StateContext<ProposedOrderlinesStateModel>
  ): void {
    this.notificationService.showToast(this.NOTIFICATIONS.removedProducts);
  }

  @Action([OrderLineAdded, OrderLinesAdded])
  notifyAddedOrderlines(
    _ctx: StateContext<ProposedOrderlinesStateModel>
  ): void {
    this.notificationService.showToast(this.NOTIFICATIONS.addedProducts);
  }

  @Action([OrderLineSnoozed, OrderLinesSnoozed])
  notifySnoozedOrderlines(
    _ctx: StateContext<ProposedOrderlinesStateModel>
  ): void {
    this.notificationService.showToast(this.NOTIFICATIONS.snoozedProducts);
  }

  @Action([AddFilterParam, AddSharedFilterParam], { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: AddFilterParam | AddSharedFilterParam
  ) {
    ctx.patchState({
      loading: true,
    });

    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([AddSearchParam, AddSharedSearchParam], { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: AddSearchParam | AddSharedSearchParam
  ) {
    ctx.setState(
      patch<ProposedOrderlinesStateModel>({
        loading: true,
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([RemoveSearchParam, RemoveSharedSearchParam], {
    cancelUncompleted: true,
  })
  removeSearchParam(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: RemoveSearchParam | RemoveSharedSearchParam
  ) {
    ctx.setState(
      patch<ProposedOrderlinesStateModel>({
        loading: true,
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(SetColumnSelection, { cancelUncompleted: true })
  setColumnSelection(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: SetColumnSelection
  ) {
    ctx.patchState({
      columnsGroups: payload.columnsGroups,
      applySameColumnsToAll: payload.applyAll,
    });
  }

  @Action([RemoveAllFilters, RemoveAllSharedFilters], {
    cancelUncompleted: true,
  })
  removeAllFilters(ctx: StateContext<ProposedOrderlinesStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(
    ctx: StateContext<ProposedOrderlinesStateModel>
  ) {
    this._removeAllFilters(ctx);
  }

  @Action([ToggleFilter, ToggleSharedFilter], { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ProposedOrderlinesStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ProposedOrderlinesStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: HideColumn
  ) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(SaveConcept, { cancelUncompleted: true })
  saveConcept(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: SaveConcept
  ) {
    return ctx.dispatch(new CreateConcept(payload.properties));
  }

  @Action(CreateConcept, { cancelUncompleted: true })
  createConcept(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: CreateConcept
  ) {
    ctx.patchState({
      disableActions: true,
    });

    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildCreateConceptRequest(state);

    return ctx.dispatch(new Saving(payload.properties.showMessage)).pipe(
      mergeMap(() => this._createConcept(webshopUuid, requestInfo)),
      tap((result: CreateConceptResult) => {
        ctx.patchState({
          buyOrderConceptUuid: result.uuid,
          isOrderNotSynced: this.handlePossibleDesync(result, state),
          disableActions: false,
        });
      }),
      concatMap(() =>
        ctx.dispatch(new SaveSucceed(payload.properties.showMessage))
      ),
      catchError(() => {
        ctx.patchState({
          disableActions: false,
        });

        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  handlePossibleDesync(
    result: CreateConceptResult,
    state: ProposedOrderlinesStateModel
  ): boolean {
    if (
      result.numberOfProducts !== state.overview.numberOfProducts ||
      result.totalValue !== state.overview.totalValue
    ) {
      return true;
    }
    return false;
  }

  @Action(UpdateConcept, { cancelUncompleted: true })
  updateConcept(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: UpdateConcept
  ) {
    ctx.patchState({
      disableActions: true,
    });

    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildUpdateConceptRequest(state);

    return ctx.dispatch(new Saving(payload.properties.showMessage)).pipe(
      concatMap(() =>
        this.conceptBuyOrderV2Service
          .updateConcept(webshopUuid, state.buyOrderConceptUuid, requestInfo)
          .pipe(
            catchError(e => {
              ctx.patchState({
                disableActions: false,
              });

              ctx.dispatch(new SaveFailed());

              throw new Error(e.message || e);
            })
          )
      ),
      tap(() => {
        ctx.patchState({
          disableActions: true,
        });
      }),
      concatMap(() => {
        return this._fetchProposedOrderlinesOverview(
          ctx,
          state.overview.supplier.uuid
        ).pipe(
          tap((overview: BuyOrderlinesOverview) => {
            ctx.patchState({
              isOrderNotSynced: this.handlePossibleDesync(
                {
                  uuid: overview.order.uuid,
                  totalValue: overview.order.totalValue,
                  numberOfProducts: overview.order.numberOfProducts,
                },
                state
              ),
            });
          })
        );
      }),
      concatMap(() =>
        ctx.dispatch(new SaveSucceed(payload.properties.showMessage))
      ),
      catchError(() => {
        return of(false);
      })
    );
  }

  @Action(CheckFactor, { cancelUncompleted: true })
  checkFactor(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: CheckFactor
  ) {
    const state = ctx.getState();

    const previousFactor = state.factor.value;

    ctx.patchState({
      factor: {
        ...state.factor,
        hasChanged: Number(payload.factor) !== previousFactor,
      },
    });
  }

  @Action(EnlargeOrderSize, { cancelUncompleted: true })
  adjustOrderSize(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: EnlargeOrderSize
  ) {
    return ctx.dispatch(new UpdateSessionForFactor(payload.factor)).pipe(
      concatMap(_ => {
        ctx.patchState({
          factor: {
            value: payload.factor,
            hasChanged: false,
          },
        });
        return of(null);
      }),
      concatMap(_ => {
        return ctx.dispatch(new ReloadBothTables({ showLoading: true }));
      })
    );
  }

  @Action(ResetBuyOrder, { cancelUncompleted: true })
  resetBuyOrder(ctx: StateContext<ProposedOrderlinesStateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      filtersGroups: defaultFiltersV2,
      sortBy: defaultSort,
      search: [],
      factor: defaultFactor,
      pagination: defaultPagination,
      selection: {},
      shouldReconnect: false,
      sessionUuid: null,
      loading: true,
      overview: null,
      proposedOrderlines: [],
    });
  }

  @Action(ToggleRowSelection, { cancelUncompleted: true })
  toggleRowSelection(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    payload: ToggleRowSelection
  ) {
    const selectedCopy = { ...ctx.getState().selection };

    if (!payload.isSelected) {
      delete selectedCopy[payload.row.uuid];

      ctx.patchState({
        selection: selectedCopy,
      });

      return;
    }

    ctx.patchState({
      selection: {
        ...ctx.getState().selection,
        [payload.row.uuid]: {
          supplierProductUuid: payload.row.supplierProduct.uuid,
          webshopProductUuid: payload.row.webshopProduct.uuid,
        },
      },
    });
  }

  @Action(MasterToggleSelection, { cancelUncompleted: true })
  masterToggleSelection(ctx: StateContext<ProposedOrderlinesStateModel>) {
    const lines = ctx.getState().proposedOrderlines;

    const existingSelection = ctx.getState().selection;

    const linesUuid = lines.map(line => line.uuid);

    const selectedUids = new Set(Object.keys(existingSelection));

    const allSelected = linesUuid.every(line => selectedUids.has(line));

    const selectionCopy = new Map(Object.entries(existingSelection));

    if (allSelected) {
      lines.forEach(line => {
        selectionCopy.delete(line.uuid);
      });
    } else {
      lines.forEach(line => {
        selectionCopy.set(line.uuid, {
          supplierProductUuid: line.supplierProduct.uuid,
          webshopProductUuid: line.webshopProduct.uuid,
        });
      });
    }

    ctx.patchState({
      selection: {
        ...Object.fromEntries(selectionCopy.entries()),
      },
    });
  }

  @Action(ClearSelection, { cancelUncompleted: true })
  clearSelection(ctx: StateContext<ProposedOrderlinesStateModel>) {
    ctx.patchState({
      selection: {},
    });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<ProposedOrderlinesStateModel>) {
    return ctx.dispatch([
      new ResetPagination(),
      new LoadProposedOrderlinesV2(ctx.getState().supplierUuid),
    ]);
  }

  override addGroupParam(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ProposedOrderlinesStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ProposedOrderlinesStateModel>, sort: Sorted) {
    if (this._isSortingLineType(sort.key)) {
      ctx.patchState({
        sortBy: {
          [STRINGS.columns.purchaseEditor.EDITED.key]: {
            field: STRINGS.columns.purchaseEditor.EDITED.filterKey,
            order: sort.order,
          },
          [sort.key]: {
            field: sort.key,
            order: sort.order,
          },
        },
      });
    } else {
      ctx.patchState({
        sortBy: {
          [sort.key]: {
            field: sort.key,
            order: sort.order,
          },
        },
      });
    }

    return ctx.dispatch(
      new LoadProposedOrderlinesV2(ctx.getState().supplierUuid)
    );
  }

  private _removeAllFilters(ctx: StateContext<ProposedOrderlinesStateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      filtersGroups: defaultFiltersV2,
      loading: true,
      search: [],
    });
  }

  private _fetchProposedOrderlines(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    supplierUuid: string
  ): Observable<ProposedBuyOrderlines> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildProposedOrdersRequestInfo(ctx);

    return this.buyOrderService
      .findProposedBuyOrderlines(webshopUuid, supplierUuid, requestInfo)
      .pipe(
        delay(1000),
        tap((orders: ProposedBuyOrderlines) => {
          ctx.patchState({
            proposedOrderlines: orders.data,
            page: { totalElements: orders.metadata.page.totalElements },
            loading: false,
            disableActions: false,
          });
        }),
        catchError(e => {
          ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedRetrieval)
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _buildProposedOrdersRequestInfo(
    ctx: StateContext<ProposedOrderlinesStateModel>
  ): ServiceRequestInfoV3ProposedOrderlines {
    const state = ctx.getState();

    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3ProposedOrderlines = {
      factor: state.factor.value,
      sessionUuid: state.sessionUuid,
      orderMomentType: state.orderMomentType,
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _fetchProposedOrderlinesOverview(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    supplierUuid: string
  ): Observable<BuyOrderlinesOverview> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildProposedOrderlinesOverviewRequestInfo(ctx);

    return this.buyOrderService
      .findProposedBuyOrderlinesOverview(webshopUuid, supplierUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(
            new LoadFailed(true, this.NOTIFICATIONS.failedOverviewRetrieval)
          );

          throw new Error(e.message || e);
        })
      );
  }

  private _buildProposedOrderlinesOverviewRequestInfo(
    ctx: StateContext<ProposedOrderlinesStateModel>
  ): ProposedBuyOrderlinesOverviewPayload {
    const state = ctx.getState();

    const payload = {
      factor: state.factor.value,
      sessionUuid: state.sessionUuid,
    };

    if (state.orderMomentUuid) {
      payload['orderMomentUuid'] = state.orderMomentUuid;
    }

    return payload;
  }

  private _buildCreateConceptRequest(
    state: ProposedOrderlinesStateModel
  ): CreateConceptModel {
    if (state.overview.orderMoment !== null) {
      return {
        supplierUuid: state.supplierUuid,
        factor: state.factor.value,
        orderMomentUuid: state.overview.orderMoment.uuid,
        orderType: 'PROPOSED',
        planningTrigger: state.overview.planningTrigger,
        sessionUuid: state.sessionUuid,
      };
    }

    return {
      supplierUuid: state.supplierUuid,
      factor: state.factor.value,
      orderType: 'PROPOSED',
      planningTrigger: state.overview.planningTrigger,
      sessionUuid: state.sessionUuid,
    };
  }

  private _buildUpdateConceptRequest(
    state: ProposedOrderlinesStateModel
  ): UpdateConceptModel {
    return {
      supplierUuid: state.supplierUuid,
      factor: state.factor.value,
      orderType: 'PROPOSED',
      planningTrigger: state.overview.planningTrigger,
      sessionUuid: state.sessionUuid,
    };
  }

  private _removeFromSelection(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    key: string
  ): void {
    const selection = { ...ctx.getState().selection };

    delete selection[key];

    ctx.patchState({
      selection: { ...selection },
    });
  }

  private _saveTableSizePersistance(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      proposedOrderEditorProposedV2TablePageSize: newPageSize,
    };
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    columns: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columns);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columns: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columns);

    return {
      proposedOrderEditorProposedV2TableExcludedColumns: excludedColumns,
      overrideProposedOrderEditorProposedV2TableExcludedColumns: true,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _isSortingLineType(columnKey: string): boolean {
    return columnKey === STRINGS.columns.purchaseEditor.PROPOSED.key;
  }

  private _updateProduct(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    webshopUuid: string,
    webshopProductUuid: string,
    properties: Partial<UpdateWebshopProductProperties>
  ): Observable<any> {
    return this.productsService
      .update(webshopUuid, webshopProductUuid, properties)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildSnoozeMultiUpdateProperties(
    products: ProposedOrderlinesSelection[],
    resumingPurchase: string | null
  ): UpdateMultipleWebshopProductsProperties {
    return {
      webshopProducts: products.map((product: ProposedOrderlinesSelection) => {
        return {
          uuid: product.webshopProductUuid,
          notBeingBought: true,
          resumingPurchase:
            resumingPurchase === null
              ? {
                  null: 'NULL_VALUE',
                }
              : {
                  value: resumingPurchase,
                },
        };
      }),
    };
  }

  private _updateMultipleProducts(
    ctx: StateContext<ProposedOrderlinesStateModel>,
    webshopUuid: string,
    properties: UpdateMultipleWebshopProductsProperties
  ): Observable<any> {
    return this.productsService.multipleUpdate(webshopUuid, properties).pipe(
      catchError(e => {
        ctx.dispatch(new SaveFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _buildSaveHeaderInfoPersistanceRequestInfo(
    openClose: string[]
  ): AccountSettingsUpdate {
    return {
      proposedOrderEditorHeaderDropdownSelection: openClose,
      overrideProposedOrderEditorHeaderDropdownSelection: true,
    };
  }

  private _createConcept(
    webshopUuid: string,
    requestInfo: CreateConceptModel
  ): Observable<any> {
    return this.conceptBuyOrderV2Service
      .createConcept(webshopUuid, requestInfo)
      .pipe(
        catchError(e => {
          throw new Error(e.message || e);
        })
      );
  }
}
