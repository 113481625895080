import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { CreateConceptResult } from '../model/concept-buy-order-v2-model';

export class ConceptCreateAdapter
  implements DataAdapter<CreateConceptResult, CreateConceptResult>
{
  transform(data: CreateConceptResult): CreateConceptResult {
    return {
      uuid: data.uuid,
      numberOfProducts: data.numberOfProducts,
      totalValue: data.totalValue,
    };
  }
}
