import {
  AnimationTriggerMetadata,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const panelExpansion: AnimationTriggerMetadata = trigger(
  'panelExpansion',
  [
    state(
      'collapsed, void',
      style({ height: '0px', opacity: 0, visibility: 'hidden' })
    ),
    state('expanded', style({ height: '*', opacity: 1, visibility: '' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
    ),
  ]
);
