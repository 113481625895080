import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FilterBooleanStrategy } from '../core/filter-strategies/filter-boolean-strategy';
import {
  FILTER,
  FilterBase,
  FilterBooleanGroupV2,
  FilterEmitted,
  FilterStrategy,
} from '../../model/filter-v2.model';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { FilterTypeOptions } from '../../model/filter.model';
import { FilterV2Component } from '../../filter-base-v2';

@Component({
  selector: 'app-filter-boolean',
  exportAs: 'filterBoolean',
  templateUrl: './filter-boolean.component.html',
  styleUrls: ['./filter-boolean.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FILTER, useExisting: FilterBooleanComponent }],
})
export class FilterBooleanComponent
  extends FilterV2Component
  implements FilterBase, OnInit
{
  filterStrategy: FilterStrategy<FilterBooleanGroupV2> =
    new FilterBooleanStrategy();

  readonly COMMON_STRINGS = MESSAGES.common;

  ngOnInit(): void {
    if (this._shouldPopulate()) {
      this._populate();
    }
  }

  clear(): void {
    this.filterStrategy.clear();
  }

  cancel(): void {
    this.canceled.emit();
  }

  apply(filtered: FilterEmitted): void {
    if (this._isAllowedToApplyFilter()) {
      this.filtered.emit({
        columnKey: this.filter().columnKey,
        optionSelected: FilterTypeOptions.EQUALS,
        subOperator: this.filterValues().subOperator,
        values: this.filterStrategy.form,
        applyAll: filtered.applyAll,
      });
    }
  }

  private _shouldPopulate(): boolean {
    return this.filterValues()?.value !== null;
  }

  private _populate(): void {
    this.filterStrategy.populate(this.filterValues()?.value);
  }

  private _isAllowedToApplyFilter(): boolean {
    return (
      this.filterStrategy.hasValue() &&
      !this.filterStrategy.hasSameValue(this.filterValues()?.value) &&
      !this.filterDisabled()
    );
  }
}
