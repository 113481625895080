<div
  class="selected-option"
  [class.selected-option-disabled]="column().disabled"
  [appTooltip]="COLUMN_VIEW_STRINGS.requiredColumns"
  [tooltipDisabled]="!column().disabled">
  <span
    class="selected-option-content"
    [appTooltip]="column().displayName"
    tooltipShouldCheckOverflow
    >{{ column().displayName }}</span
  >

  @if (!column().disabled) {
    <button
      appButton
      variant="danger"
      size="sm"
      appButtonIcon
      [appTooltip]="COMMON_STRINGS.remove"
      class="selected-option-delete"
      [disabled]="column().disabled"
      (click)="removeColumn()">
      <mat-icon svgIcon="minus" />
    </button>
  }
</div>
