import { DateTime } from 'luxon';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  DynamicTags,
  TagVariantColor,
  TagsBuilder,
} from 'src/app/shared/components/design-system/tags/model/tag.model';
import {
  DisconnectedWebshopProducts,
  WebshopProduct,
  WebshopProducts,
} from 'src/app/shared/models/products/v3/products.model';
import {
  WebshopProduct as WebshopProductV1,
  WebshopProducts as WebshopProductsV1,
} from '../model/products-v3.model';
import { STRINGS } from 'src/app/features/dashboard/components/todo-list-v2/model/todo-list-v2.strings';

export class DisconnectedProductsV3Adapter
  implements
    DataAdapter<WebshopProductsV1, DisconnectedWebshopProducts>,
    TagsBuilder<WebshopProductV1>
{
  private readonly MESSAGES = MESSAGES;

  readonly STRINGS = STRINGS.tooltips;

  transform(data: WebshopProductsV1): DisconnectedWebshopProducts {
    const products: WebshopProduct[] = data.products.map(
      (product: WebshopProductV1) => {
        return {
          uuid: product.uuid,
          id: product.id,
          category: product.category,
          name: product.name,
          sku: product.sku,
          eanCode: product.eanCode,
          price: product.price,
          serviceLevel: product.serviceLevel,
          supplyTime: product.supplyTime,
          demandPerMonth: product.demandPerMonth,
          stockOnHand: product.stockOnHand,
          minimumStock: product.minimumStock,
          assembled: product.assembled,
          ignored: product.ignored,
          notBeingBought: product.notBeingBought,
          resumingPurchase: product.resumingPurchase,
          novel: product.novel,
          issues: product.issues,
          deadStock: product.deadStock,
          maximumStock: product.maximumStock,
          articleCode: product.articleCode,
          manualServiceLevel: product.manualServiceLevel,
          notes: product.notes,
          createdAtRemote: product.createdAtRemote,
          xyzCategory: product.xyzCategory,
          extras: {
            tags: this.buildTags(product),
          },
        };
      }
    );

    return {
      data: products,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  buildTags(base: WebshopProductV1): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (base.issues.includes('CONNECT_TO_SUPPLIER')) {
      extras.iconTextTags.push({
        icon: 'warning-rounded-xs',
        color: TagVariantColor.RED,
        tooltip: this.STRINGS.CONNECT_TO_SUPPLIER,
        text: '',
      });
    }

    if (
      base.issues.includes('UPDATE_SELLING_PRICE') ||
      base.issues.includes('UPDATE_PURCHASE_PRICE')
    ) {
      extras.iconTextTags.push({
        icon: 'warning-triangle',
        color: TagVariantColor.ORANGE,
        tooltip:
          base.issues.includes('UPDATE_SELLING_PRICE') &&
          base.issues.includes('UPDATE_PURCHASE_PRICE')
            ? this.STRINGS.UPDATE_SELLING_PRICE +
              '\n' +
              this.STRINGS.UPDATE_PURCHASE_PRICE
            : base.issues.includes('UPDATE_SELLING_PRICE')
              ? this.STRINGS.UPDATE_SELLING_PRICE
              : this.STRINGS.UPDATE_PURCHASE_PRICE,
        text:
          base.issues.includes('UPDATE_SELLING_PRICE') &&
          base.issues.includes('UPDATE_PURCHASE_PRICE')
            ? '2'
            : '',
      });
    }

    if (base.ignored) {
      extras.iconTags.push({
        color: TagVariantColor.DEFAULT,
        icon: 'eye-closed',
        tooltip: this.MESSAGES.common.ignored,
      });
    }

    if (base.assembled) {
      extras.iconTags.push({
        color: TagVariantColor.DEFAULT,
        icon: 'puzzle',
        tooltip: this.MESSAGES.common.assembled,
      });
    }

    if (base.notBeingBought && !base.resumingPurchase) {
      extras.iconTags.push({
        color: TagVariantColor.DEFAULT,
        icon: 'arrow-decrease-down',
        tooltip: this.MESSAGES.common.phasedOut,
      });
    }

    if (base.notBeingBought && base.resumingPurchase) {
      const formattedDate = DateTime.fromJSDate(
        new Date(base.resumingPurchase)
      ).toFormat('LLL dd, yyyy');

      extras.iconTags.push({
        color: TagVariantColor.DEFAULT,
        icon: 'arrow-decrease-down',
        secondaryIcon: 'calendar',
        tooltip: `${this.MESSAGES.common.phasedOutUntil} ${formattedDate}`,
      });
    }

    return extras;
  }
}
