import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngxs/store';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from 'src/app/features/products-v2/model/products-v2.strings';
import { ComposedWebshopProduct } from 'src/app/shared/models/products/v3/products.model';
import {
  AddFilterParam,
  AddSearchParam,
  ColumnsSelected,
  HideColumn,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  Sort,
  ToggleFilter,
} from '../../actions/product-compositions-v2.actions';
import { ProductCompositionsV2StateQueries } from '../../state/product-compositions-v2.queries';
import { DataTableV2Component } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.component';
import {
  ColumnFilterParam,
  Filtering,
  Pagination,
  Search,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ProductCompositionsV2State } from '../../state/product-compositions-v2.state';
import { GoToProduct } from 'src/app/features/products-v2/components/product-details-v2/actions/product-details-v2.actions';
import { COLUMNS_ORDER } from '../../model/data-table.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

@Component({
  selector: 'app-product-composition-v2-data-table',
  templateUrl: './product-composition-v2-data-table.component.html',
  styleUrls: ['./product-composition-v2-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCompositionV2DataTableComponent
  extends DataTableV2Component<ComposedWebshopProduct[]>
  implements Pagination, Search, Filtering
{
  totalElements = select(ProductCompositionsV2StateQueries.totalElements);
  pageSizeOptions = select(ProductCompositionsV2StateQueries.pageSizeOptions);
  pageSize = select(ProductCompositionsV2StateQueries.pageSize);
  currentPage = select(ProductCompositionsV2StateQueries.currentPage);
  selectableColumns = select(
    ProductCompositionsV2StateQueries.selectableColumns
  );
  displayedColumns = select(ProductCompositionsV2StateQueries.displayedColumns);
  lastDisplayedColumn = select(
    ProductCompositionsV2StateQueries.lastDisplayedColumn
  );
  datasource = select(ProductCompositionsV2StateQueries.compositions);
  loading = select(ProductCompositionsV2StateQueries.loading);
  filters = select(ProductCompositionsV2StateQueries.filters);
  activeFilters = select(ProductCompositionsV2StateQueries.activeFilters);
  searchBy = select(ProductCompositionsV2StateQueries.search);
  filterOpened = select(ProductCompositionsV2StateQueries.filterOpened);

  canRefresh = select(ProductCompositionsV2State.canRefresh());

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly TABLE_COLUMNS = STRINGS.columns.compositions;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RELATED_STATE = ProductCompositionsV2State;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  constructor(private store: Store) {
    super();
  }

  refreshDatatable(): void {
    this.store.dispatch(new ReloadDatatable());
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onColumnHidden(columnKey: string) {
    this.store.dispatch(new HideColumn(columnKey));
  }

  onFiltered(params: ColumnFilterParam): void {
    this.store.dispatch(new AddFilterParam(params));
  }

  onSort(sort: Sorted) {
    this.store.dispatch(new Sort(sort));
  }

  toggleFilter(): void {
    this.store.dispatch(new ToggleFilter());
  }

  clearAllFilters(): void {
    this.store.dispatch(new RemoveAllFilters());
  }

  addSearchParam(param: string) {
    this.store.dispatch(new AddSearchParam(param));
  }

  removeSearchParam(param: string) {
    this.store.dispatch(new RemoveSearchParam(param));
  }

  goToProductDetails(productUuid: string): void {
    this.store.dispatch(new GoToProduct(productUuid));
  }
}
