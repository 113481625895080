import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';
import { MenuComponent } from './menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuItemShortcutComponent } from './components/menu-item-shortcut/menu-item-shortcut.component';

@NgModule({
  declarations: [MenuComponent, MenuItemComponent, MenuItemShortcutComponent],
  imports: [CommonModule, CdkMenuModule],
  exports: [
    MenuComponent,
    MenuItemComponent,
    MenuItemShortcutComponent,
    CdkMenuModule,
  ],
})
export class MenuModule {}
