<section class="container">
  <div class="step-navigation-bar">
    <button
      aria-label="Stepper button"
      *ngFor="let step of steps; let i = index"
      class="step-button"
      [class.active]="selectedIndex === i"
      [class.completed]="step.completed && selectedIndex > i"
      (click)="selectStepByIndex(i)"
      [disabled]="step.disabled">
      <div class="step-circle">{{ i + 1 }}</div>
      <p>{{ step.label }}</p>
    </button>
  </div>

  <div class="step-content">
    <ng-container
      [ngTemplateOutlet]="selected ? selected.content : null"></ng-container>
  </div>
</section>
