import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { STRINGS } from 'src/app/features/purchase-v3/model/purchase-v3.strings';
import { PurchaseConfirmationDataV2 } from '../purchase-confirmation-dialog-v2/model/purchase-confirmation-v2.model';
import {
  CheckoutOrder,
  ResetCheckout,
  SkipCheckout,
} from './actions/purchase-checkout-dialog.component.actions';
import { PurchaseCheckoutState } from './state/purchase-checkout-dialog.component.state';
import { AllowExit } from 'src/app/core/actions/app.action';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-purchase-checkout-dialog',
  templateUrl: './purchase-checkout-dialog.component.html',
  styleUrls: ['./purchase-checkout-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseCheckoutDialogComponent implements OnDestroy {
  @Select(PurchaseCheckoutState.isSaving)
  saving$: Observable<boolean>;

  @Select(PurchaseCheckoutState.skipCheckout)
  skipCheckout$: Observable<boolean>;

  expectedDeliveryDate: Date | null = null;

  readonly STRINGS = STRINGS.metadata.checkoutDialog;

  constructor(
    @Inject(DIALOG_DATA) public data: PurchaseConfirmationDataV2,
    private store: Store,
    public dialogRef: DialogRef<PurchaseCheckoutDialogComponent>
  ) {
    this.expectedDeliveryDate = this._calculateExpectedDeliveryDate(
      this.data.leadTime
    );
  }

  placeOrder() {
    this.store.dispatch([
      new AllowExit(),
      new CheckoutOrder(this.data, this.dialogRef),
    ]);
  }

  close() {
    this.dialogRef.close();
  }

  skipCheckout(skip: boolean): void {
    this.store.dispatch(new SkipCheckout(skip));
  }

  private _calculateExpectedDeliveryDate(leadTime: number): Date {
    return DateTime.fromISO(new Date().toISOString())
      .plus({ days: leadTime })
      .toJSDate();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetCheckout());
  }
}
