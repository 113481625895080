export class ResetIssues {
  static readonly type = '[Todo List V2] Reset Issues';
}

export class LoadOrdersIssues {
  static readonly type = '[Todo List V2] Load Orders Issues';
}

export class LoadDeliveriesIssues {
  static readonly type = '[Todo List V2] Load Deliveries Issues';
}

export class LoadProductsIssues {
  static readonly type = '[Todo List V2] Load Products Issues';
}

export class LoadSuppliersIssues {
  static readonly type = '[Todo List V2] Load Suppliers Issues';
}
